import { Col, PageWrapper, Row } from '@qonsoll/react-design'
import { HeaderBreadcrumbs, Spinner } from 'components'
import { useHistory, useParams } from 'react-router-dom'

import { FlavorAdvancedForm } from 'domains/Flavor/components'
import { useGetFlavorInitialValues } from 'domains/Flavor/hooks'
import { useTranslations } from 'contexts/Translation'

const FlavorEditPage = (props) => {
  /* Getting translations instance */
  const { t } = useTranslations()
  /* Getting history instance */
  const history = useHistory()
  /* Getting params from router */
  const params = useParams()
  /* Getting id from params */
  const { flavorId } = params

  /* Fetching flavor initial values */
  const [initialValues, loading] = useGetFlavorInitialValues(flavorId)

  /* Configuring page header */
  const headingProps = {
    title: t('Flavor edit'),
    titleSize: 3,
    marginBottom: 3,
    textAlign: 'left'
  }

  /* Function to handle back click */
  const handleBack = () => history.goBack()

  return (
    <PageWrapper
      onBack={handleBack}
      breadcrumbs={<HeaderBreadcrumbs />}
      headingProps={headingProps}>
      <Row noGutters>
        {loading ? (
          <Col>
            <Spinner height="70vh" text={t('Flavor loading')} />
          </Col>
        ) : (
          <Col cw={[12, 12, 8, 6]}>
            <FlavorAdvancedForm initialData={initialValues} />
          </Col>
        )}
      </Row>
    </PageWrapper>
  )
}

export default FlavorEditPage
