import { Box, Title } from '@qonsoll/react-design'
import {
  useActionsOrderAdvancedForm,
  useInitializeOrderAdvancedForm
} from './hooks'

import { ButtonAccent } from 'components'
import { Form } from 'antd'
import { KEYS } from '__constants__'
import { OrderSimpleForm } from 'domains/Order/components'
import { OrderStatusSimpleForm } from 'domains/OrderStatus/components'
import { OrderedItemSelectableList } from 'domains/OrderedItem/components'
import PropTypes from 'prop-types'
import { RestaurantAdvancedForm } from 'domains/Restaurant/components'
import { useTranslations } from 'contexts/Translation'

const OrderAdvancedForm = (props) => {
  const { initialData, group, showTitle } = props

  /* Getting translations instance */
  const { t } = useTranslations()
  /* Getting form instance from hook */
  const { form } = useInitializeOrderAdvancedForm(initialData)

  /* Handling enter key press */
  const handleKeyPress = (e) => {
    if (e.key === KEYS.ENTER) {
      e.preventDefault()
      onFinish()
    }
  }

  /* Getting loading state and actions from hook */
  const { loading, onFinish, onReset } = useActionsOrderAdvancedForm({
    initialData,
    form
  })

  return group ? (
    <>
      {showTitle && (
        <Title level={4} mb={2}>
          {t('Order')}
        </Title>
      )}
      <OrderSimpleForm showTitle={false} group={[...group, 'order']} />
      <OrderStatusSimpleForm group={[...group, 'orderStatus']} showTitle />
      <RestaurantAdvancedForm group={[...group, 'restaurant']} showTitle />
      <Form.Item name={[group, 'orderedItems']}>
        <OrderedItemSelectableList />
      </Form.Item>
    </>
  ) : (
    <Form
      scrollToFirstError
      onKeyPress={handleKeyPress}
      onFinish={onFinish}
      layout="vertical"
      autoComplete="off"
      form={form}>
      {showTitle && (
        <Title level={4} mb={2}>
          {t('Order')}
        </Title>
      )}
      <OrderSimpleForm group={['order']} />
      <OrderStatusSimpleForm group={['status']} showTitle />
      <RestaurantAdvancedForm group={['restaurant']} showTitle />
      <Form.Item name={['orderedItems']}>
        <OrderedItemSelectableList />
      </Form.Item>
      <Box display="flex">
        <ButtonAccent
          mr={2}
          htmlType="button"
          onClick={onReset}
          mb={4}
          type="secondary">
          {t('Cancel')}
        </ButtonAccent>
        <ButtonAccent
          type="primary"
          loading={loading}
          onClick={() => form.submit()}>
          {t('Save')}
        </ButtonAccent>
      </Box>
    </Form>
  )
}

OrderAdvancedForm.propTypes = {
  initialData: PropTypes.object,
  group: PropTypes.array,
  showTitle: PropTypes.bool
}

export default OrderAdvancedForm
