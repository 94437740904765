import { CardDropdown, SelectableCard } from 'components'
import { Col, Divider, Img, Row, Text, Title } from '@qonsoll/react-design'

import PropTypes from 'prop-types'
import { TYPE_OF_PLACE } from '__constants__'
import notFound from 'assets/Error-01.png'
import styles from './RestaurantSimpleView.styles'
import { useRestaurantBaseActions } from 'domains/Restaurant/hooks'
import { useTranslations } from 'contexts/Translation'

const RestaurantSimpleView = (props) => {
  const {
    restaurant,
    index,
    isSelected,
    actions,
    children,
    fullDesc,
    fullTitle,
    noActions
  } = props

  /* Getting translations instance */
  const { t } = useTranslations()
  /* Getting base actions */
  const { onOpen } = useRestaurantBaseActions({
    restaurantId: restaurant?._id,
    actions
  })

  return (
    <CardDropdown
      documentTitle={restaurant?.name}
      onClick={!noActions && onOpen}
      document={restaurant}
      index={index}
      height="100%"
      data-testid="restaurantListItem">
      <SelectableCard
        isSelected={isSelected}
        style={{ height: 'inherit' }}
        height={'100%'}>
        <Row noGutters {...styles.fieldRowProps}>
          <Col cw={12} mb={2}>
            <Img
              alt="Restaurant logo"
              style={styles.restaurantImg}
              src={restaurant?.logoUrl ?? notFound}
            />
          </Col>
        </Row>
        <Row noGutters {...styles.fieldRowProps}>
          <Col cw={12}>
            <Title
              level={4}
              {...(fullTitle ? {} : styles.fieldTitleProps)}
              mb={2}>
              {restaurant?.name}
            </Title>
            {/* Now Ann did`nt know exactly how it should works  */}
            {/* {restaurant?.welcomeMessage && (
              <>
                <Text type="secondary">{t('Welcome message')}</Text>
                <Text>{restaurant?.welcomeMessage}</Text>
              </>
            )} */}
          </Col>
        </Row>

        {children}
        <Row noGutters {...styles.fieldRowProps}>
          <Col cw={12} mb={2} flexDirection="row" v="between">
            <Text type="secondary">{t('Venue type')}:</Text>
            <Text {...styles.fieldTitleProps}>
              {TYPE_OF_PLACE[restaurant?.typeOfPlace] || 'n/a'}
            </Text>
          </Col>
          <Col cw={12}>
            <Divider mb={3} />
            {restaurant?.description ? (
              <Text {...(fullDesc ? {} : styles.clamp)}>
                {restaurant?.description}
              </Text>
            ) : (
              <Text type="secondary" variant="overline">
                {t('No description')}
              </Text>
            )}
          </Col>
        </Row>
      </SelectableCard>
    </CardDropdown>
  )
}

RestaurantSimpleView.propTypes = {
  restaurant: PropTypes.object,
  onSelect: PropTypes.func,
  index: PropTypes.number,
  isSelected: PropTypes.bool,
  actions: PropTypes.object,
  children: PropTypes.any,
  fullDesc: PropTypes.bool,
  fullTitle: PropTypes.bool,
  noActions: PropTypes.bool
}

export default RestaurantSimpleView
