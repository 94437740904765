import { deleteDocument, updateDocument } from 'services/api/firebase'
import { generatePath, useHistory, useParams } from 'react-router-dom'

import { COLLECTIONS } from '__constants__'
import PATHS from 'pages/paths'
import { message } from 'antd'
import { useEvent } from 'hooks'
import { useMemo } from 'react'
import { useTranslations } from 'contexts/Translation'

/* Path's */
const createPath = PATHS.AUTHENTICATED.LIST_ITEM_CREATE
const editPath = PATHS.AUTHENTICATED.LIST_ITEM_EDIT
const showPath = PATHS.AUTHENTICATED.LIST_ITEM_SHOW

const useListItemBaseActions = ({ listItemId, list, actions } = {}) => {
  /* Getting translations instance */
  const { t } = useTranslations()
  /* Getting history instance */
  const history = useHistory()
  const { listId } = useParams()

  /* Function to handle back click */
  const handleBack = useEvent(() => history.goBack())

  /* Handling opening of the show page */
  const handleOpen = useEvent(() => {
    const pathParams = { listId, listItemId }
    const path = generatePath(showPath, pathParams)
    history.push(path)
  })

  /* Handling opening of the create page */
  const handleCreate = useEvent(() => {
    const pathParams = { listId }
    const path = generatePath(createPath, pathParams)
    history.push(path)
  })

  /* Handling opening of the edit page or change view to edit mode */
  const handleEdit = useEvent(() => {
    const pathParams = { listId, listItemId }
    const path = generatePath(editPath, pathParams)
    history.push(path)
  })

  const handleDelete = useEvent(async () => {
    try {
      history.goBack()
      await deleteDocument(COLLECTIONS.LIST_ITEMS, listItemId)
      const updatedListsListItems =
        list &&
        Object.keys(list?.listItems)
          ?.filter((itemId) => itemId !== listItemId)
          ?.reduce((acc, listItem) => {
            acc[listItem] = true
            return acc
          }, {})

      const updatedListData = { ...list, listItems: updatedListsListItems }
      await updateDocument(COLLECTIONS.LISTS, listId, updatedListData)
      message.success(t('List item successfully deleted'))
    } catch (error) {
      message.error(error.message)
    }
  })

  return useMemo(
    () => ({
      onBack: handleBack,
      onCreate: handleCreate,
      onEdit: handleEdit,
      onDelete: handleDelete,
      onOpen: handleOpen,
      ...(actions || {}),
      createPath,
      editPath,
      showPath
    }),
    [actions, handleBack, handleCreate, handleDelete, handleEdit, handleOpen]
  )
}

export default useListItemBaseActions
