import { useHistory, generatePath } from 'react-router-dom'
import PATHS from 'pages/paths'
import { COLLECTIONS } from '__constants__'
import { useTranslations } from 'contexts/Translation'
import {
  deleteDocument,
  updateParentHasManyRelationship
} from 'services/api/firebase'
import { message } from 'antd'
import { useEvent } from 'hooks'
import { useMemo } from 'react'

/* Path's */
const createPath = PATHS.AUTHENTICATED.TABLE_CREATE
const editPath = PATHS.AUTHENTICATED.TABLE_EDIT

const useTableBaseActions = ({
  tableId,
  actions,
  zoneId,
  refresh,
  table
} = {}) => {
  /* Getting translations instance */
  const { t } = useTranslations()
  /* Getting history instance */
  const history = useHistory()

  /* Function to handle back click */
  const handleBack = useEvent(() => history.goBack())

  /* Handling opening of the create page */
  const handleCreate = useEvent(() => {
    history.push(createPath)
  })

  /* Handling opening of the edit page or change view to edit mode */
  const handleEdit = useEvent(() => {
    const pathParams = { tableId }
    const path = generatePath(editPath, pathParams)
    history.push(path)
  })

  const handleDelete = useEvent(async () => {
    try {
      table?.qRCodes?.forEach((qRCodeId) =>
        deleteDocument(COLLECTIONS.Q_R_CODES, qRCodeId)
      )
      await updateParentHasManyRelationship(
        zoneId,
        COLLECTIONS.ZONES,
        'tables',
        tableId,
        true
      )
      await deleteDocument(COLLECTIONS.TABLES, tableId)
      message.success(t('Table successfully deleted'))
      refresh?.()
    } catch (error) {
      message.error(error.message)
    }
  })

  return useMemo(
    () => ({
      onBack: handleBack,
      onCreate: handleCreate,
      onEdit: handleEdit,
      onDelete: handleDelete,
      ...(actions || {}),
      createPath,
      editPath
    }),
    [actions, handleBack, handleCreate, handleDelete, handleEdit]
  )
}

export default useTableBaseActions
