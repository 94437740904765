import { Col, Row, Text } from '@qonsoll/react-design'

import { CardFocused } from './AddItemCard.styles'
import { Icon } from '@qonsoll/icons'
import PropTypes from 'prop-types'
import { useTranslations } from 'contexts/Translation'

const AddItemCard = (props) => {
  const {
    height = '100%',
    width,
    onClick,
    message,
    withMessage = true,
    minHeight
  } = props

  const { t } = useTranslations()

  return (
    <CardFocused
      height={height}
      width={width}
      onClick={onClick}
      minHeight={minHeight}>
      <Row v="center" noGutters>
        <Col v="center" h="center">
          <Icon
            name="Plus1Outlined"
            mb={2}
            fill="var(--ql-text-accent1)"
            size="28px"
          />
          {withMessage && (
            <Text variant="body1" color="var(--ql-text-accent1)">
              {message || t('Add')}
            </Text>
          )}
        </Col>
      </Row>
    </CardFocused>
  )
}
AddItemCard.propTypes = {
  message: PropTypes.string,
  onClick: PropTypes.func,
  height: PropTypes.string,
  width: PropTypes.string,
  withMessage: PropTypes.bool,
  minHeight: PropTypes.string
}

export default AddItemCard
