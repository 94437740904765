import { useTranslations } from '@qonsoll/translation'
import { COLLECTIONS } from '__constants__'
import { message } from 'antd'
import { doc, getDoc } from 'firebase/firestore'
import { transformArrayToObject } from 'helpers'
import { useState } from 'react'
import { updateDocument } from 'services/api/firebase/helpers'
import { firestore } from 'services/firebase'

const useMenuItemActions = () => {
  const [loading, setLoading] = useState(false)

  const { t } = useTranslations()

  const removeMenuItemFromMenu = async (categoryId, menuItemId) => {
    try {
      setLoading(true)

      const categorySnapshot = await getDoc(
        doc(firestore, COLLECTIONS.MENU_CATEGORIES, categoryId)
      )

      const categoryData = await categorySnapshot.data()

      const newMenuItemsOrder = categoryData?.itemsOrder?.filter(
        (itemId) => itemId !== menuItemId
      )

      const newItems = transformArrayToObject(newMenuItemsOrder)

      await updateDocument(COLLECTIONS.MENU_CATEGORIES, categoryId, {
        items: newItems,
        itemsOrder: newMenuItemsOrder
      })

      message.success(t('Menu item successfully removing from category'))
    } catch (error) {
      message.error(
        `${t(
          'Something went wrong during removing menu item from category'
        )}, ${error?.message}`
      )
    } finally {
      setLoading(false)
    }
  }

  return { removeMenuItemFromMenu, loading }
}

export default useMenuItemActions
