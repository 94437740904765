import { Option } from '@qonsoll/react-design'
import PropTypes from 'prop-types'
import { SelectWithFixedDropdown } from 'components'
import { handleFilterOptions } from 'helpers'
import { useCustomerContext } from 'contexts/CustomerContext'
import { useMemo } from 'react'

const CustomerSelect = (props) => {
  const { width, disabled } = props
  const {
    customer,
    loading,
    onCurrentCustomerChange,
    allCustomers,
    isDisabledSelect
  } = useCustomerContext()

  const options = useMemo(
    () =>
      allCustomers
        ?.filter(({ name }) => name !== customer?.name)
        ?.map(({ name, _id }) => ({ name, _id })),
    [customer, allCustomers]
  )

  const defaultCompany = useMemo(
    () => allCustomers?.find(({ name }) => name === customer?.name),
    [customer, allCustomers]
  )

  return (
    <SelectWithFixedDropdown
      disabled={isDisabledSelect || disabled}
      pl={0}
      width={width}
      loading={loading}
      aria-label="Select company"
      value={defaultCompany?.name}
      onChange={onCurrentCustomerChange}
      filterOption={handleFilterOptions}
      aria-describedby="company-select-description">
      {options?.map((item, index) => (
        <Option value={item._id} key={item?._id}>
          {item?.name}
        </Option>
      ))}
    </SelectWithFixedDropdown>
  )
}

CustomerSelect.propTypes = {
  width: PropTypes.string,
  disabled: PropTypes.bool
}

export default CustomerSelect
