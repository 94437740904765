import { Modal, message } from 'antd'
import {
  deleteDocument,
  getDocument,
  updateDocument
} from 'services/api/firebase'

import { COLLECTIONS } from '__constants__'
import { ExclamationCircleOutlined } from '@ant-design/icons'
import PATHS from 'pages/paths'
import { arrayRemove } from 'firebase/firestore'
import firebase from 'firebase/compat/app'
import { useCallback } from 'react'
import { useHistory } from 'react-router-dom'
import { useTranslations } from 'contexts/Translation'

const useDeleteCompany = ({ customer }) => {
  const { t } = useTranslations()
  const history = useHistory()

  return useCallback(
    (companyId, userId) => {
      const deleteCompany = async (successCallback) => {
        try {
          const userData = await getDocument(COLLECTIONS.USERS, userId)
          if (userData?._companies?.length > 1) {
            const companiesArray = userData?._companies?.filter(
              (id) => id !== companyId
            )

            await updateDocument(COLLECTIONS.USERS, userId, {
              _companies: arrayRemove(companyId),
              currentCompanyId: companiesArray[0]
            })
          } else {
            await firebase.auth().signOut()
            history.push(PATHS.CONFIG.AFTER_LOGOUT)
          }
          await deleteDocument(COLLECTIONS.CUSTOMERS, companyId)
          await deleteDocument(COLLECTIONS.ADDRESSES, customer?.address)
        } catch (err) {
          // eslint-disable-next-line
          console.error('Error during company delete:', err)
          message.error(
            t(
              'Error occurred during company delete. Please contact administrator for help.'
            )
          )
        }
      }

      Modal.confirm({
        title: t('Confirm Delete'),
        centered: true,
        icon: <ExclamationCircleOutlined />,
        content: `${t('Are you sure you want to delete your company')}? ${t(
          'The company cannot be restored'
        )}`,
        okText: t('Delete'),
        onOk: async () => deleteCompany(),
        cancelText: t('Cancel')
      })
    },
    [t, customer?.address, history]
  )
}

export default useDeleteCompany
