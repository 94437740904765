import { MENU_ACTIVITIES_KEYS, MENU_AVAILABILITIES_KEYS } from '__constants__'

import { Form } from 'antd'
import { MENU_TYPES_KEYS } from '__constants__'
import { useEffect } from 'react'

const useInitializeMenuAdvancedForm = (initialData) => {
  /* Getting form instance */
  const [form] = Form.useForm()

  /* Setting initial data */
  useEffect(() => {
    if (initialData) {
      /* Getting initial values */
      const { menu, menuType, mediaObjects, cocktails, categories } =
        initialData
      // This is needed to make old values expressed as YES/NO and new value as true/false to work
      const menuPrivacyWithOldData =
        menu?.['privacy'] === MENU_AVAILABILITIES_KEYS.PRIVATE ? true : false
      const menuStatusWithOldData =
        menu?.['status'] === MENU_ACTIVITIES_KEYS.ACTIVE ? true : false
      const menuPrivacy =
        typeof menu?.['privacy'] === 'boolean'
          ? menu?.['privacy']
          : menuPrivacyWithOldData
      const menuStatus =
        typeof menu?.['status'] === 'boolean'
          ? menu?.['status']
          : menuStatusWithOldData
      /* Formatting date fields */
      const formValues = {
        menu: {
          privacy: menuPrivacy ?? null,
          company: menu?.['company'] ?? null,
          name: menu?.['name'] ?? null,
          description: menu?.['description'] ?? null,
          cocktailsAvailabilities: menu?.['cocktailsAvailabilities'] ?? null,
          status: menuStatus ?? null,
          menuType: menu?.['menuType'] ?? null,
          categories: categories ?? []
        },
        menuType: menuType ?? null,
        mediaObjects: mediaObjects ?? null,
        cocktails: cocktails?.map((e) => e?._id).filter((e) => e) ?? []
      }

      /* Setting initial values to form instance */
      form.setFieldsValue(formValues)
    } else {
      form.setFieldsValue({
        menu: { menuType: MENU_TYPES_KEYS.DRINK_TYPE }
      })
    }
  }, [initialData, form])

  return { form }
}

export default useInitializeMenuAdvancedForm
