import { useState } from 'react'
import { useHistory } from 'react-router-dom'
import {
  collection,
  doc,
  setDoc,
  serverTimestamp,
  updateDoc,
  deleteDoc
} from 'firebase/firestore'
import { firestore } from 'services/firebase'
import {
  COLLECTIONS,
  OBJECT_RELATION_TYPES,
  OBJECT_RELATION_TYPES_WITH_REFERENCE,
  TITLE_FIELD_NAMES
} from '__constants__'
import { message } from 'antd'
import { useTranslations } from '@qonsoll/translation'
import { parseItemTypeFieldReference } from 'domains/ItemTypeField/helpers'
import differenceIds from 'modules/admin-module/helpers/differenceIds'

const useActionsItemTypeAdvancedForm = (initialData) => {
  const [loading, setLoading] = useState(false)

  const history = useHistory()
  const { t } = useTranslations()

  const validateForm = (values) => {
    const isAnyFieldHasTitleName = Object.values(values?.fields)?.some(
      ({ name }) => TITLE_FIELD_NAMES.includes(name)
    )

    if (!isAnyFieldHasTitleName) {
      message.error(
        t(
          'Please add at least one field with title name for example "name" or "title"'
        )
      )
      return false
    }

    return true
  }

  // This function does not need to be wrapped in a try/catch block because it is used in the try/catch block in the onFinish function
  const prepareValues = async (values) => {
    const { itemType, fields } = values

    const itemTypeId =
      initialData?._id || doc(collection(firestore, COLLECTIONS.ITEM_TYPES)).id

    const transformedFields = Object.values(fields)
      ?.sort((a, b) => a?.order - b?.order)
      .map((field) => {
        const _id =
          field?._id ||
          doc(collection(firestore, COLLECTIONS.ITEM_TYPES_FIELDS)).id

        const fieldRelationType = field?.relationType

        const isLocalizationPossible =
          fieldRelationType === OBJECT_RELATION_TYPES.STRING

        const isReferencePossible =
          OBJECT_RELATION_TYPES_WITH_REFERENCE.includes(fieldRelationType)

        const reference = isReferencePossible
          ? parseItemTypeFieldReference(field?.reference)
          : { object: null, list: null, enum: null }

        delete field.uid
        delete field.isDisabledChanges
        delete field.order

        return {
          ...field,
          _id,
          itemTypeId,
          isLocalizationEnabled:
            field?.isLocalizationEnabled && isLocalizationPossible,
          isEditable: field?.isEditable ?? false,
          label:
            field?.isLocalizationEnabled && isLocalizationPossible
              ? field?.label
              : {
                  en: field?.label?.en,
                  no: null
                },
          ...reference
        }
      })

    const [removedIds] = differenceIds(
      transformedFields,
      initialData?.fieldsOrder
    )

    removedIds?.forEach((id) => {
      deleteDoc(doc(firestore, COLLECTIONS.ITEM_TYPES_FIELDS, id))
    })
    transformedFields?.forEach((objectField) => {
      setDoc(
        doc(firestore, COLLECTIONS.ITEM_TYPES_FIELDS, objectField?._id),
        objectField
      )
    })

    const fieldsOrder = transformedFields?.map((fieldId) => fieldId?._id)

    const listIds = fieldsOrder?.reduce(
      (acc, id) => ({
        ...acc,
        [id]: true
      }),
      {}
    )

    const serviceFieldName = initialData ? '_updatedAt' : '_createdAt'

    return {
      ...itemType,
      [serviceFieldName]: serverTimestamp(),
      _id: itemTypeId,
      fields: listIds,
      menuItems: initialData?.menuItems ?? null,
      fieldsOrder
    }
  }

  const onFinish = async (values) => {
    try {
      setLoading(true)

      if (!validateForm(values)) return

      const preparedValues = await prepareValues(values)

      if (initialData) {
        await updateDoc(
          doc(firestore, COLLECTIONS.ITEM_TYPES, preparedValues._id),
          preparedValues
        )
      } else {
        await setDoc(
          doc(firestore, COLLECTIONS.ITEM_TYPES, preparedValues._id),
          preparedValues
        )
      }
      message.success(t('Item type saved successfully'))
      history.goBack()
    } catch (error) {
      message.error(
        `${t('Something went wrong during saving item type')}, ${
          error?.message
        }`
      )
    } finally {
      setLoading(false)
    }
  }

  const onReset = () => {
    history.goBack()
  }

  return { onFinish, onReset, loading }
}

export default useActionsItemTypeAdvancedForm
