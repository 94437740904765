import { Col, Container, Row, Title } from '@qonsoll/react-design'
import {
  useGetZoneQRCodes,
  useGetZoneTitleAndRibbonColor
} from 'domains/Zone/hooks'

import PropTypes from 'prop-types'
import { Tag } from 'antd'
import { ZoneQRCodes } from 'domains/Zone/components'
import { useTranslations } from 'contexts/Translation'

const ZoneAdvancedView = (props) => {
  const { zone, restaurant } = props

  /* Getting translations instance */
  const { t } = useTranslations()

  const { ribbonColor, zoneTitle } = useGetZoneTitleAndRibbonColor(
    zone?.available
  )
  // eslint-disable-next-line no-unused-vars
  const [zoneQRCodes, QRCodeLoading, QRCodeError, QRCodesRefresh] =
    useGetZoneQRCodes(zone)

  return (
    <Container>
      <Row mx="var(--ql-grid-gutter-negative)">
        <Col cw="auto" mb={3}>
          <Tag color={ribbonColor}>{zoneTitle}</Tag>
        </Col>

        <Col cw={12} mb={3}>
          {QRCodeLoading ? (
            <Title level={4}>{t('Zone is loading')}...</Title>
          ) : (
            <ZoneQRCodes
              zone={zone}
              restaurant={restaurant}
              refresh={QRCodesRefresh}
              zoneQRCodes={zoneQRCodes}
            />
          )}
        </Col>
      </Row>
    </Container>
  )
}
ZoneAdvancedView.propTypes = {
  zone: PropTypes.object,
  emptyProps: PropTypes.object,
  restaurant: PropTypes.object
}

export default ZoneAdvancedView
