import {
  Box,
  Container,
  Divider,
  Img,
  Text,
  Title
} from '@qonsoll/react-design'

import { ButtonAccent } from 'components'
import { COLLECTIONS } from '__constants__'
import { Icon } from '@qonsoll/icons'
import PropTypes from 'prop-types'
import { doc } from 'firebase/firestore'
import { firestore } from 'services/firebase'
import { imageStyles } from './CustomerInfo.styles'
import pluralize from 'pluralize'
import { useDocumentDataOnce } from 'react-firebase-hooks/firestore'
import { useGetCustomerAdditionalInfo } from 'domains/Customer/hooks'
import { useTranslations } from 'contexts/Translation'

const CustomerInfo = (props) => {
  const { customer } = props
  const {
    logoUrl,
    name,
    country,
    additionalLanguage,
    mainLanguage,
    currency,
    typeOfLicense,
    contactPerson,
    address
  } = customer
  const { t } = useTranslations()
  const { phoneNumber, email } = useGetCustomerAdditionalInfo(customer)

  // get company address
  const [companyAddressInfo] = useDocumentDataOnce(
    address && doc(firestore, COLLECTIONS.ADDRESSES, address)
  )
  // second condition is for the case when address is not set via google places API
  const companyAddress = companyAddressInfo?.description || country

  const displayLicense =
    customer?.typeOfLicense === 'Corporation' ? 'flex' : 'none'

  const languageFieldLabel = t(
    additionalLanguage ? pluralize('Language') : 'Language'
  )
  const languagesComputed = additionalLanguage
    ? `${mainLanguage}, ${additionalLanguage}`
    : mainLanguage

  return (
    <Container width="100%">
      <Img
        className="ant-card-bordered"
        style={imageStyles}
        mb={3}
        src={logoUrl}
      />
      <Title mb={3} level>
        {name ?? t('n/a')}
      </Title>
      <Box display="flex" mb={2} flex={1} justifyContent="space-between">
        <Text type="secondary">{`${t('Address')}:`}</Text>
        <Text textAlign="end">{companyAddress ?? t('n/a')}</Text>
      </Box>
      <Box display="flex" mb={2} flex={1} justifyContent="space-between">
        <Text type="secondary">{languageFieldLabel}</Text>
        <Text>{languagesComputed}</Text>
      </Box>
      <Box display="flex" mb={2} flex={1} justifyContent="space-between">
        <Text type="secondary">{`${t('Currency')}:`}</Text>
        <Text>{currency ?? t('n/a')}</Text>
      </Box>
      <Box
        display={displayLicense}
        mb={3}
        flex={1}
        justifyContent="space-between">
        <Text type="secondary">{`${t('License type')}:`}</Text>
        <Text>{typeOfLicense ?? t('n/a')}</Text>
      </Box>

      <Title mb={1} level={3}>
        {t('Contact person')}
      </Title>
      <Divider mb={2} />
      <Box display="flex" mb={2} flex={1} justifyContent="space-between">
        <Text type="secondary">{`${t('Name')}:`}</Text>
        <Text>{contactPerson ?? t('n/a')}</Text>
      </Box>
      <Box display="flex" mb={2} flex={1} justifyContent="space-between">
        <Text type="secondary">{`${t('Phone')}:`}</Text>
        <Box display="flex" alignItems="center">
          <Text>{phoneNumber ?? t('n/a')}</Text>

          {phoneNumber && (
            <ButtonAccent
              size="small"
              ml={2}
              type="text"
              icon={<Icon name="PhoneOutlined" size={16} />}
              href={`tel:${phoneNumber}`}
            />
          )}
        </Box>
      </Box>
      <Box display="flex" mb={4} flex={1} justifyContent="space-between">
        <Text type="secondary">{`${t('Email')}:`}</Text>
        <Box display="flex" alignItems="center" maxWidth="100%">
          <Text wordBreak="break-word">{email ?? t('n/a')}</Text>

          {email && (
            <ButtonAccent
              ml={2}
              size="small"
              icon={<Icon name="Mail1Outlined" size={16} />}
              type="text"
              href={`mailto:${email}`}
            />
          )}
        </Box>
      </Box>
    </Container>
  )
}

CustomerInfo.propTypes = {
  customer: PropTypes.object
}

export default CustomerInfo
