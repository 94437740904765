import { Checkbox } from 'components'
import PropTypes from 'prop-types'
import { StyledDetails } from '../MenuCategoryFilter.styles'
import { Text } from '@qonsoll/react-design'
import { useGetCocktails } from 'domains/Cocktail/hooks'
import { useSubfilterActions } from 'hooks'
import { useTranslations } from 'contexts/Translation'

const CocktailFilter = ({ filterData, setFilterData }) => {
  // [ADDITIONAL_HOOKS]
  const [cocktails] = useGetCocktails()
  const { t } = useTranslations()

  // [COMPUTED_PROPERTIES]
  const summaryStyles = { marginBottom: '8px' }

  const { onToggleDetails, onChange, checkIsEnabled } = useSubfilterActions({
    filterData,
    setFilterData,
    fieldName: 'cocktails',
    operand: 'array-contains'
  })

  return (
    <StyledDetails
      open={localStorage.getItem(`cocktail-details-visibility`) === 'opened'}>
      <summary
        style={summaryStyles}
        onClick={() => onToggleDetails('cocktail')}>
        {t('Cocktail')}
      </summary>
      {cocktails?.map((cocktail, index) => (
        <div key={`status-${index}`}>
          <Checkbox
            key={`status-${index}`}
            checked={checkIsEnabled(cocktail)}
            onChange={() => onChange(cocktail)}>
            <Text wordBreak="break-word">
              {cocktail?.title || t('Unnamed filter')}
            </Text>
          </Checkbox>
        </div>
      ))}
    </StyledDetails>
  )
}

CocktailFilter.propTypes = {
  filterData: PropTypes.object,
  setFilterData: PropTypes.func
}

export default CocktailFilter
