import { useMemo, useState, useEffect } from 'react'
import { useTranslations } from '@qonsoll/translation'
import { Form } from 'antd'
import { collection, orderBy, query, where } from 'firebase/firestore'
import { useCollectionData } from 'react-firebase-hooks/firestore'
import { firestore } from 'services/firebase'

import { COLLECTIONS } from '__constants__'
import { useLoading } from 'hooks'

const useInitializeInteractiveMenuAdvancedForm = (initialData) => {
  const [form] = Form.useForm()

  const { language } = useTranslations()

  const [selectedItemTypeId, setSelectedMenuItemTypeId] = useState(null)

  const [itemTypesFields, itemTypesFieldsLoading] = useCollectionData(
    selectedItemTypeId &&
      query(
        collection(firestore, COLLECTIONS.ITEM_TYPES_FIELDS),
        where('itemTypeId', '==', selectedItemTypeId)
      )
  )

  const [itemTypes, itemTypesLoading] = useCollectionData(
    query(
      collection(firestore, COLLECTIONS.ITEM_TYPES),
      orderBy('_createdAt', 'desc')
    )
  )

  const dataLoading = useLoading([itemTypesLoading])

  const transformedMenuItemTypeOptions = useMemo(() => {
    if (Array.isArray(itemTypes)) {
      const options = itemTypes?.map(({ label, name, _id }) => ({
        label: label?.[language] || label?.en || name,
        value: _id
      }))

      const optionsSorted =
        options?.sort((a, b) => {
          const labelA = a?.label?.toLowerCase()
          const labelB = b?.label?.toLowerCase()

          if (labelA < labelB) {
            return -1
          }
          if (labelA > labelB) {
            return 1
          }
          return 0
        }) ?? []

      return optionsSorted
    }

    return []
  }, [itemTypes, language])

  const changeSelectedItemType = (key) => {
    setSelectedMenuItemTypeId(key)
    form.setFieldsValue({ questions: null }) // Resets the 'questions' field to an empty object
  }

  useEffect(() => {
    if (initialData) {
      const questionsData = initialData?.interactiveMenuQuestions?.reduce(
        (acc, item) => ({ ...acc, [item?._id]: { ...item } }),
        {}
      )

      const initialValues = {
        questions: questionsData ?? {},
        name: initialData?.interactiveMenuData?.name ?? '',
        itemType: initialData?.interactiveMenuData?.itemType ?? null
      }

      setSelectedMenuItemTypeId(initialData?.interactiveMenuData?.itemType)

      form.setFieldsValue(initialValues)
    }
  }, [form, initialData])

  return {
    form,
    selectedItemType: selectedItemTypeId,
    dataLoading,
    itemTypes,
    transformedMenuItemTypeOptions,
    itemTypesFields,
    itemTypesFieldsLoading,
    changeSelectedItemType
  }
}

export default useInitializeInteractiveMenuAdvancedForm
