import { useCallback, useEffect, useState } from 'react'

import { COLLECTIONS } from '__constants__'
import { getDocument } from 'services/api/firebase'

const useGetZoneQRCodes = (zone) => {
  /* State for data */
  const [zoneQRCodes, setZoneQRCodes] = useState([])
  /* State for loading */
  const [loading, setLoading] = useState(!!zone?.qRCodes?.length)
  /* State for error */
  const [error, setError] = useState(null)

  /* Fetching data */
  const getZoneQRCodes = useCallback(async () => {
    try {
      /* Setting loading state to true */
      setLoading(true)

      /* Getting menuCategories promises*/
      const promises = zone?.qRCodes?.map((qrCode) =>
        getDocument(COLLECTIONS.Q_R_CODES, qrCode)
      )

      /* Getting menuCategories data */
      const data = await Promise?.all(promises)

      /* Setting menuCategories data */
      setZoneQRCodes(data?.filter((qRCode) => qRCode))

      /* Setting loading state to false */
    } catch (error) {
      setError(error)
    }
    setLoading(false)
  }, [zone])

  useEffect(() => {
    zone?.qRCodes?.length && getZoneQRCodes()
  }, [zone, getZoneQRCodes])

  return [zoneQRCodes, loading, error, getZoneQRCodes]
}

export default useGetZoneQRCodes
