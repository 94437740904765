import { Box, Col, PageWrapper, Row } from '@qonsoll/react-design'
import {
  EmptySearch,
  HeaderBreadcrumbs,
  PageActions,
  SimpleSearchInput,
  Spinner
} from 'components'
import {
  TrainingMaterialFilter,
  TrainingMaterialList,
  TrainingMaterialSortMenu
} from 'domains/TrainingMaterial/components'
import { useFilter, useSimpleSearch } from 'hooks'
import {
  useGetTrainingMaterials,
  useTrainingMaterialBaseActions
} from 'domains/TrainingMaterial/hooks'

import Fuse from 'fuse.js'
import PropTypes from 'prop-types'
import { TRAINING_MATERIALS_AVAILABILITIES } from '__constants__/enums'
import { useCustomerContext } from 'contexts/CustomerContext'
import { useMemo } from 'react'
import { useTranslations } from 'contexts/Translation'

const TrainingMaterialsAllPage = (props) => {
  const { isListWithCreate, withHeader = true } = props

  const { customer } = useCustomerContext()

  /* Getting translations instance */
  const { t } = useTranslations()
  /* Getting filter params */
  const filterParams = useFilter('trainingMaterial')
  /* Destructuring filter params */
  const { filterVisibility, filterData, setFilterData } = filterParams
  /* Building filter query */
  const ref = useMemo(() => filterData, [filterData])
  /* Fetching trainingMaterials */
  const [trainingMaterials, loading] = useGetTrainingMaterials(ref)

  /* Getting base actions */
  const listActions = useTrainingMaterialBaseActions()

  // viible training materials for customer(either available or extra but with access for current customer)
  const visibleMaterialsForCustomer = useMemo(
    () =>
      trainingMaterials?.filter(
        (item) =>
          item?.availability ===
            TRAINING_MATERIALS_AVAILABILITIES.AVAILABLE.toUpperCase() ||
          item?.availability === true ||
          item?.company?.includes(customer?._id)
      ),
    [customer?._id, trainingMaterials]
  )

  // object for search
  const fuseObject = useMemo(
    () =>
      visibleMaterialsForCustomer
        ? new Fuse(visibleMaterialsForCustomer, {
            // At what point does the match algorithm give up. 0 - max, 1 - min
            threshold: 0.2,
            keys: ['title', 'contentType']
          })
        : null,
    [visibleMaterialsForCustomer]
  )
  /* Setup search for companies */
  const { onSearchInputChange, searchRef, searchResult, searchLoading } =
    useSimpleSearch(visibleMaterialsForCustomer, fuseObject)

  /* Configuring page header */
  const headingProps = {
    title: t('Tips and tricks'),
    marginBottom: 3,
    textAlign: 'left'
  }

  /* Configuring page actions */
  const actions = {
    sort: true
  }

  /* Extracting base actions */
  const { onBack, onCreate } = listActions

  return (
    <PageWrapper
      onBack={withHeader ? onBack : null}
      breadcrumbs={withHeader ? <HeaderBreadcrumbs /> : null}
      headingProps={withHeader ? headingProps : null}>
      <Row noGutters>
        {filterVisibility && (
          <Col mr={filterVisibility ? 4 : undefined} cw="auto">
            <TrainingMaterialFilter {...filterParams} />
          </Col>
        )}
        {loading || searchLoading ? (
          <Col>
            <Spinner height="70vh" text={t('Training material loading')} />
          </Col>
        ) : (
          <>
            <Col cw={12} mb={4}>
              <Box display="flex" flex={1}>
                <Box display="flex" flex={1} mr={3}>
                  <SimpleSearchInput
                    searchItems={onSearchInputChange}
                    searchRef={searchRef}
                    placeholder={t(
                      'Search tips & tricks by title, content type'
                    )}
                  />
                </Box>
                <PageActions
                  actions={actions}
                  sortMenu={
                    <TrainingMaterialSortMenu
                      filteredData={filterData}
                      setFilteredData={setFilterData}
                    />
                  }
                />
              </Box>
            </Col>
            {searchResult?.length ? (
              <Col cw={!filterVisibility && 12}>
                <TrainingMaterialList
                  isListWithCreate={isListWithCreate}
                  actions={{ onCreate }}
                  trainingMaterials={searchResult}
                  cw={[12, 6, 4]}
                />
              </Col>
            ) : (
              <EmptySearch />
            )}
          </>
        )}
      </Row>
    </PageWrapper>
  )
}

TrainingMaterialsAllPage.propTypes = {
  isListWithCreate: PropTypes.bool,
  withHeader: PropTypes.bool
}

export default TrainingMaterialsAllPage
