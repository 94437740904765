import { Aside } from '@qonsoll/react-design'

import styled from 'styled-components'

export const StyledAside = styled(Aside)`
  width: ${({ collapsed }) =>
    collapsed
      ? 'var(--ql-menu-width-collapsed)'
      : 'var(--ql-menu-width-default)'};
  border-radius: 0px;
  margin: 0px;
  transition: 1s;
  background: var(--ql-menu-bg);
  box-shadow: var(--ql-menu-box-shadow);
  z-index: 1000;
  position: ${({ collapsed, lg }) =>
    !collapsed && !lg ? 'fixed' : 'relative'};
  overflow: initial;
  padding: 24px 0;
  min-width: ${({ collapsed }) =>
    collapsed
      ? 'var(--ql-menu-width-collapsed)'
      : 'var(--ql-menu-width-default)'};
  flex-basis: unset;

  transition: all var(--ql-animation-duration-fast)
    var(--ql-animation-cubic-ease-in-out);
`

export const CollapseButton = styled.div`
  width: 18px;
  height: 28px;
  position: absolute;
  right: -18px;
  top: 60px;
  z-index: 1000;
  display: flex;
  align-items: center;
  cursor: pointer;
  border-top-right-radius: var(--ql-menu-collapse-btn-border-radius);
  border-bottom-right-radius: var(--ql-menu-collapse-btn-border-radius);
  border: var(--ql-menu-collapse-btn-border-default);
  border-left: 1px solid var(--ql-menu-collapse-btn-border-left);
  background: var(--ql-menu-collapse-btn-bg);
`
