import { Checkbox, Form } from 'antd'
import { Box, Col, Input } from '@qonsoll/react-design'

import styled from 'styled-components'

const StyledCheckboxWrapper = styled(Box)`
  display: flex;
`

const StyledCheckBox = styled(Checkbox)`
  .ant-checkbox-inner {
    border-radius: var(--checkbox-border-radius-xs);
  }
  .ant-checkbox-checked::after {
    border-radius: var(--checkbox-border-radius-xs);
  }
`
const StyledInput = styled(Input)`
  .ant-input-group-addon:last-child {
    border-top-right-radius: var(--ql-input-border-radius) !important;
    border-bottom-right-radius: var(--ql-input-border-radius) !important;
  }
`
const StyledFormItem = styled(Form.Item)`
  margin-bottom: 0;
  display: flex;
  .ant-form-item-control-input-content {
    display: flex;
    align-items: center;
  }
`
const StyledCol = styled(Col)`
  flex-direction: row;
  align-items: flex-start;
`

const styles = {
  iconStyles: {
    position: 'absolute',
    left: [-16, -16, -16, -24, -24],
    // 8px - padding from label to input, 2px - borders from input
    top: 'calc((var(--ql-form-item-label-line-height-base) + 8px + var(--ql-typography-line-height-md) + var(--input-padding-vertical-base) * 2 + 2px + 10px) / 2 )',
    alignItems: 'center',
    zIndex: 1,
    name: 'DraggerOutlined',
    size: 16,
    fill: 'var(--ql-text-secondary)'
  }
}
export {
  StyledCheckBox,
  StyledInput,
  styles,
  StyledFormItem,
  StyledCol,
  StyledCheckboxWrapper
}
