import { Box } from '@qonsoll/react-design'
import styled from 'styled-components'

export const CardFocused = styled(Box).attrs({ tabIndex: 0 })`
  background-image: url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' rx='20' ry='20' stroke='hsla(0, 0%, 6.5%, 0.32)' stroke-width='1' stroke-dasharray='18' stroke-dashoffset='15' stroke-linecap='round'/%3e%3c/svg%3e");

  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  background-color: var(--card-background);
  border-radius: var(--card-radius);
  &:focus-visible {
    outline: 2px solid var(--ql-color-white);
    box-shadow: 0 0 0 4px var(--ql-color-accent2);
  }
  &:hover {
    transition: 0.3s;
    background-image: url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='hsla(0, 0%, 6.5%, 0.05)' rx='20' ry='20' stroke='hsla(0, 0%, 6.5%, 0.32)' stroke-width='1' stroke-dasharray='18' stroke-dashoffset='15' stroke-linecap='round'/%3e%3c/svg%3e");
  }
`
