import { useTranslations } from '@qonsoll/translation'
import { COLLECTIONS, LIST_FIELD_RELATION_DATA_TYPES } from '__constants__'
import { message } from 'antd'
import { doc, updateDoc } from 'firebase/firestore'
import { useEffect, useState, useMemo, useCallback } from 'react'
import { useParams } from 'react-router-dom'
import { firestore } from 'services/firebase'

const useActionsMenuItemField = (columnData, menuItemId) => {
  const [value, setValue] = useState(columnData?.fieldValue)

  const { menuId } = useParams()

  const { fieldValue, relationType, fieldName } = columnData || {}

  const { t } = useTranslations()

  useEffect(() => {
    fieldValue && setValue(fieldValue)
  }, [fieldValue])

  const onInputValueChange = (value) => {
    setValue(value)
  }

  const onSelectValueChange = useCallback(
    async (key) => {
      try {
        setValue(key)

        await updateDoc(doc(firestore, COLLECTIONS.MENU_ITEMS, menuItemId), {
          [`rewrites.${menuId}.${fieldName}`]: key
        })
      } catch (error) {
        message.error(
          `${t('Something went wrong during updating field data')}, ${t(
            error?.message
          )}`
        )
      }
    },
    [fieldName, menuId, menuItemId, t]
  )

  const onInputOnBlur = useCallback(async () => {
    try {
      await updateDoc(doc(firestore, COLLECTIONS.MENU_ITEMS, menuItemId), {
        [`rewrites.${menuId}.${fieldName}`]: value
      })
    } catch (error) {
      message.error(
        `${t('Something went wrong during updating field data')}, ${t(
          error?.message
        )}`
      )
    }
  }, [fieldName, menuId, menuItemId, t, value])

  const isRelationship = LIST_FIELD_RELATION_DATA_TYPES.includes(relationType)

  const onChangeAction = useMemo(
    () => (isRelationship ? onSelectValueChange : onInputValueChange),
    [isRelationship, onSelectValueChange]
  )

  const onBlurAction = useMemo(
    () => (isRelationship ? () => {} : onInputOnBlur),
    [isRelationship, onInputOnBlur]
  )

  return {
    value,
    onChangeAction,
    onBlurAction
  }
}

export default useActionsMenuItemField
