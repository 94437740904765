import { Col, Row, Text, Title } from '@qonsoll/react-design'

import PropTypes from 'prop-types'
import { Switch } from 'components'
import { useTranslations } from 'contexts/Translation'

const CheckboxFormItem = (props) => {
  const { title, description, onChange, value, onChangeCallback } = props
  const { t } = useTranslations()

  const onCheckboxSwitcherChange = (value) => {
    onChange?.(value)
    onChangeCallback?.(value)
  }

  return (
    <Row noOuterGutters>
      <Col>
        <Title level={4} mb={2}>
          {title || t('Visibility')}
        </Title>
        <Text variant="body1">
          {description ||
            t(
              'Determines whether or not this item will be visible to visitors in the menu'
            )}
        </Text>
      </Col>
      <Col cw="auto" v="center">
        <Switch onChange={onCheckboxSwitcherChange} checked={value} />
      </Col>
    </Row>
  )
}

CheckboxFormItem.propTypes = {
  title: PropTypes.string,
  description: PropTypes.string,
  onChange: PropTypes.func,
  value: PropTypes.bool,
  onChangeCallback: PropTypes.string
}

export default CheckboxFormItem
