import { Col, Row, Text } from '@qonsoll/react-design'

import { AddItemCard } from 'components'
import { ORDER_STATUSES } from '__constants__'
import { OrderSimpleView } from 'domains/Order/components'
import PropTypes from 'prop-types'
import { useMemo } from 'react'
import { useTranslations } from 'contexts/Translation'

const OrderList = (props) => {
  const {
    orders,
    isListWithCreate,
    listView,
    emptyProps,
    actions,
    currency = 'NOK',
    cw = [12, 6]
  } = props

  /* Getting translations instance */
  const { t } = useTranslations()

  /* Checking if list is empty */
  const isEmpty = !orders?.length
  /* Computing item width */
  const itemWidth = listView ? 12 : cw

  const ordersDone = useMemo(
    () =>
      orders
        ?.filter((order) => order?.orderStatus === ORDER_STATUSES.DONE)
        ?.reverse(),
    [orders]
  )
  const ordersInProgress = useMemo(
    () =>
      orders
        ?.filter((order) => order?.orderStatus === ORDER_STATUSES.IN_PROGRESS)
        ?.reverse(),
    [orders]
  )

  return (
    <Row negativeBlockMargin px={2}>
      {isListWithCreate && actions?.onCreate && (
        <Col mb={4} cw={itemWidth}>
          <AddItemCard message={t('Add order')} onClick={actions.onCreate} />
        </Col>
      )}
      {ordersInProgress.map((order, index) => (
        <Col mb={4} key={order?._id || index} cw={itemWidth}>
          <OrderSimpleView
            currency={currency}
            actions={actions}
            index={index}
            order={order}
          />
        </Col>
      ))}
      {ordersDone.map((order, index) => (
        <Col mb={4} key={order?._id || index} cw={itemWidth}>
          <OrderSimpleView
            currency={currency}
            actions={actions}
            index={index}
            order={order}
          />
        </Col>
      ))}
      {isEmpty && (
        <Col cw={12}>
          <Text variant="overline" color="var(--ql-state-disabled-color)">
            {emptyProps?.message || t('No orders have been created yet')}
          </Text>
        </Col>
      )}
    </Row>
  )
}

OrderList.propTypes = {
  orders: PropTypes.array,
  isListWithCreate: PropTypes.bool,
  listView: PropTypes.bool,
  emptyProps: PropTypes.object,
  actions: PropTypes.any,
  cw: PropTypes.any,
  currency: PropTypes.string
}

export default OrderList
