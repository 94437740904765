import 'firebase/auth'

import { Box, Text } from '@qonsoll/react-design'
import { generatePath, useHistory, useLocation } from 'react-router-dom'

import PATHS from './paths'
import PropTypes from 'prop-types'
import { Spinner } from 'components'
import firebase from 'firebase/compat/app'
import { useAuthState } from 'react-firebase-hooks/auth'
import { useEffect } from 'react'
import { useHandleError } from 'hooks'
import { useTranslations } from 'contexts/Translation'
import { useUser } from 'domains/User/context'

const unauthenticatedPaths = Object.values(PATHS.UNAUTHENTICATED)

const RoutesRedirect = ({ children }) => {
  const { t } = useTranslations()
  const history = useHistory()
  const location = useLocation()
  const handleError = useHandleError()
  const [user, loading, error] = useAuthState(firebase.auth())

  const { user: userData } = useUser()

  // Making decision how to redirect
  useEffect(() => {
    /* This code is checking if the current path is in the unauthenticatedPaths array. */
    const isUnauthenticatedPath = unauthenticatedPaths.includes(
      location.pathname
    )

    /* If the user is logged in, and the user's email is verified, then the user is logged in. */
    const isEmailNotVerified = user && !user.emailVerified
    const isLoggedIn =
      isUnauthenticatedPath && user && !loading && !isEmailNotVerified

    /* If the user is logged in, redirect to the config page. If the user is logged out, redirect to
    the logout page. If the user's email is not verified, redirect to the email confirmation page.
    */
    isLoggedIn &&
      history.push(
        userData?._id &&
          generatePath(PATHS.CONFIG.AFTER_LOGIN, {
            profileId: userData?._id
          })
      )

    isEmailNotVerified && history.push(PATHS.UNAUTHENTICATED.CONFIRM_EMAIL)

    /* This code is checking if the user is not logged in and the current path is not the login page. */
    const isNotLoggedIn =
      !user && !loading && location.pathname !== PATHS.UNAUTHENTICATED.LOGIN

    if (
      isNotLoggedIn &&
      location.pathname?.includes(PATHS.UNAUTHENTICATED.LOGIN)
    )
      return
    /* If the user is not logged in and the current path is not the login page, redirect to the login page. */
    isNotLoggedIn && history.push(PATHS.UNAUTHENTICATED.LOGIN)
  }, [history, user, loading, location.pathname, userData])

  // Session fetching error handling
  useEffect(() => {
    error && handleError(error)
  }, [error, handleError])

  return loading ? (
    <Box
      height="100%"
      display="flex"
      alignItems="center"
      justifyContent="center">
      <Box display="flex" alignItems="center">
        <Spinner />
        <Text type="secondary" pb="2">
          {t('Loading', 'Loading...')}
        </Text>
      </Box>
    </Box>
  ) : (
    children
  )
}

RoutesRedirect.propTypes = {
  children: PropTypes.element
}

export default RoutesRedirect
