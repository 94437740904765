import { Col, Container, Row, Text, Card } from '@qonsoll/react-design'

import PropTypes from 'prop-types'
import { useMemo } from 'react'
import { useTranslations } from 'contexts/Translation'

const UserAdvancedView = (props) => {
  const { user } = props

  /* Getting translations instance */
  const { t } = useTranslations()
  const userName = useMemo(
    () =>
      user?.firstName || user?.lastName
        ? `${user?.firstName} ${user?.lastName}`
        : 'n/a',
    [user?.firstName, user?.lastName]
  )
  return (
    <Container>
      <Row mx="var(--ql-grid-gutter-negative)">
        <Col cw={[4, 12]}>
          <Card style={{ paddingTop: '5px', paddingBottom: '5px' }}>
            <Row noGutters mb={2} v="center">
              <Col cw="auto" h="left" mr={2}>
                <Text variant="secondary" type="secondary">
                  {t('Full name')}
                </Text>
                <Text>{userName}</Text>
              </Col>
            </Row>
            <Row noGutters v="center" mb={2}>
              <Col cw="auto" h="left" mr={2}>
                <Text variant="secondary" type="secondary">
                  {t('Phone')}
                </Text>
                <Text>{user?.phone || 'n/a'}</Text>
              </Col>
            </Row>
            <Row noGutters v="center" mb={2}>
              <Col cw="auto" h="left" mr={2}>
                <Text variant="secondary" type="secondary">
                  {t('Email')}
                </Text>
                <Text wordBreak="break-word">{user?.email}</Text>
              </Col>
            </Row>
          </Card>
        </Col>
      </Row>
    </Container>
  )
}

UserAdvancedView.propTypes = {
  user: PropTypes.object
}

export default UserAdvancedView
