import { ButtonAccent } from 'components'
import { Popconfirm } from 'antd'
import PropTypes from 'prop-types'
import { useTranslations } from 'contexts/Translation'

const ButtonWithPopconfirm = (props) => {
  const { documentTitle = '', onReset, withPopconfirm } = props

  const { t } = useTranslations()

  return withPopconfirm ? (
    <Popconfirm
      title={`${t(
        'Are you sure you want to discard the changes you made'
      )} ${documentTitle}?`}
      okText={t('Yes')}
      onConfirm={onReset}
      cancelText={t('No')}>
      <ButtonAccent type="secondary" mr={2} htmlType="button" mb={4}>
        {t('Cancel')}
      </ButtonAccent>
    </Popconfirm>
  ) : (
    <ButtonAccent
      type="secondary"
      mr={2}
      htmlType="button"
      onClick={onReset}
      mb={4}>
      {t('Cancel')}
    </ButtonAccent>
  )
}
ButtonWithPopconfirm.propTypes = {
  documentTitle: PropTypes.string,
  onReset: PropTypes.func,
  withPopconfirm: PropTypes.bool
}
export default ButtonWithPopconfirm
