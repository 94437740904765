import { useCallback, useEffect, useMemo, useState } from 'react'
import Fuse from 'fuse.js'
import { useHistory } from 'react-router-dom'

import PATHS from 'pages/paths'
import { useTranslations } from 'contexts/Translation'
import { useCustomerContext } from 'contexts/CustomerContext'
import { useFilter, useSimpleSearch, useLoading } from 'hooks'

import { useGetInteractiveMenus } from 'domains/InteractiveMenu/hooks'

const useInteractiveMenuAllBaseActions = () => {
  const history = useHistory()

  /* Sort loading */
  const [sortLoading, setSortLoading] = useState(true)
  /* Getting translations instance */
  const { t } = useTranslations()
  const { customer } = useCustomerContext()
  /* Getting filter params */
  const { filterVisibility, filterData, setFilterData } =
    useFilter('interactive-menus')
  /* Building filter query */
  const ref = useMemo(() => filterData, [filterData])
  const whereQuery = customer?._id
    ? [`access.companies.${customer?._id}`, '==', true]
    : []
  /* Fetching lists */
  const [interactiveMenus, interactiveMenusLoading] = useGetInteractiveMenus({
    orderBy: ref?.orderBy,
    where: whereQuery
  })

  // object for search
  const fuseObject = useMemo(
    () =>
      interactiveMenus
        ? new Fuse(interactiveMenus, {
            // At what point does the match algorithm give up. 0 - max, 1 - min
            threshold: 0.1,
            keys: ['name']
          })
        : null,
    [interactiveMenus]
  )

  /* Setup search for companies */
  const { onSearchInputChange, searchRef, searchResult, searchLoading } =
    useSimpleSearch(interactiveMenus, fuseObject)

  const loading = useLoading([
    interactiveMenusLoading,
    searchLoading,
    sortLoading
  ])

  /* First time when come on the page, sorted items by _createdAt */
  useEffect(() => {
    setFilterData({
      ...filterData,
      orderBy: ['_createdAt', 'desc']
    })
    setSortLoading(false)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  /* Configuring page actions */
  const actions = useMemo(
    () => ({
      sort: true,
      create: false
    }),
    []
  )
  /* Configuring page header */
  const headingProps = useMemo(
    () => ({
      title: t('Interactive menus'),
      marginBottom: 3,
      textAlign: 'left'
    }),
    [t]
  )
  /* Checking if list is empty */
  const isEmpty = !interactiveMenus?.length
  /* Checking if there are no search results and if the list is not empty. */
  const isNoRelatedSearchResults = !searchResult?.length && !isEmpty
  /* Checking if there are no search results and if the list is not empty. */
  const isRelatedSearchResults = searchResult?.length && !isEmpty

  const onBack = useCallback(() => history.goBack(), [history])
  const onCreate = useCallback(
    () => history.push(PATHS.AUTHENTICATED.INTERACTIVE_MENU_CREATE),
    [history]
  )

  return useMemo(
    () => ({
      isNoRelatedSearchResults,
      isRelatedSearchResults,
      isEmpty,
      headingProps,
      actions,
      loading,
      onSearchInputChange,
      searchRef,
      filterVisibility,
      onBack,
      onCreate,
      interactiveMenus,
      filterData,
      setFilterData,
      searchResult
    }),
    [
      actions,
      filterData,
      filterVisibility,
      headingProps,
      interactiveMenus,
      isEmpty,
      isNoRelatedSearchResults,
      isRelatedSearchResults,
      loading,
      onBack,
      onCreate,
      onSearchInputChange,
      searchRef,
      searchResult,
      setFilterData
    ]
  )
}

export default useInteractiveMenuAllBaseActions
