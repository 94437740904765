import { Container, Dropdown, Menu } from '@qonsoll/react-design'

import CardDropdownWrapper from './CardDropdown.styled'
import { Icon } from '@qonsoll/icons'
import { MenuItem } from 'components'
import { Popconfirm } from 'antd'
import PropTypes from 'prop-types'
import { useMemo } from 'react'
import { useTranslations } from 'contexts/Translation'

/**
 * It renders a box with a dropdown menu that can be used to edit or delete the document
 * @param props - The props that are passed to the component.
 * @returns A CardDropdown component that is a wrapper for the children passed in.
 */
const CardDropdown = (props) => {
  const {
    onEdit,
    onDelete,
    children,
    onDoubleClick,
    onClick,
    document,
    index,
    documentTitle,
    deleteText,
    height,
    ...rest
  } = props

  // [ADDITIONAL_HOOKS]
  const { t } = useTranslations()

  // [HANDLERS]
  const handleEdit = (e) => {
    e.domEvent?.stopPropagation()
    onEdit?.(document, index)
  }
  const handleDelete = (e) => {
    e.domEvent?.stopPropagation()
    onDelete?.(document, index)
  }

  // [COMPUTED_PROPERTIES]
  const hasDropdownActions = useMemo(
    () => onEdit || onDelete,
    [onEdit, onDelete]
  )
  const Wrapper = useMemo(
    () => (hasDropdownActions ? Dropdown : Container),
    [hasDropdownActions]
  )
  const cursorComputed = useMemo(
    () => (onDoubleClick || onClick ? 'pointer' : 'default'),
    [onDoubleClick, onClick]
  )
  const wrapperProps = hasDropdownActions
    ? {
        trigger: ['contextMenu'],
        overlay: (
          <Menu>
            {onEdit && (
              <MenuItem
                key="edit"
                icon={<Icon name="Edit3Outlined" size="20px" pr={2} />}
                onClick={handleEdit}>
                {t('Edit')}
              </MenuItem>
            )}
            {onDelete && (
              <Popconfirm
                title={`${t('Are you sure you want to remove')} ${
                  documentTitle || deleteText
                }?`}
                okText={t('Yes')}
                onConfirm={handleDelete}
                cancelText={t('No')}>
                <MenuItem
                  key="delete"
                  icon={<Icon name="DeleteOutlined" size="20px" pr={2} />}
                  danger>
                  {t('Delete')}
                </MenuItem>
              </Popconfirm>
            )}
          </Menu>
        )
      }
    : {}

  return (
    <Wrapper {...wrapperProps} height={height}>
      <CardDropdownWrapper
        focusable={onClick || onDoubleClick}
        height={height}
        cursor={cursorComputed}
        onDoubleClick={onDoubleClick}
        onClick={onClick}
        {...rest}>
        {children}
      </CardDropdownWrapper>
    </Wrapper>
  )
}

CardDropdown.propTypes = {
  onEdit: PropTypes.func,
  onDelete: PropTypes.func,
  onDoubleClick: PropTypes.func,
  onClick: PropTypes.func,
  children: PropTypes.node.isRequired,
  document: PropTypes.object.isRequired,
  index: PropTypes.number,
  deleteText: PropTypes.string,
  documentTitle: PropTypes.string,
  height: PropTypes.string
}

export default CardDropdown
