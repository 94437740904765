import { useTranslations } from '@qonsoll/translation'
import { message } from 'antd'
import { doc, getDoc } from 'firebase/firestore'
import { checkIsObject, getRelationshipCollectionName } from 'helpers'
import { useEffect, useState, useCallback } from 'react'
import { getDocument } from 'services/api/firebase/helpers'
import { firestore } from 'services/firebase'

const useGetMenuItemColumnData = (columnData) => {
  const [columnValue, setColumnValue] = useState('n/a')
  const [loading, setLoading] = useState(false)

  const { t, language } = useTranslations()

  const getRelationshipData = useCallback(
    async (relationshipCollectionName) => {
      try {
        if (relationshipCollectionName && columnData?.fieldValue) {
          setLoading(true)
          const { fieldValue } = columnData

          if (Array.isArray(fieldValue) || checkIsObject(fieldValue)) {
            const targetDocuments = Array.isArray(fieldValue)
              ? fieldValue
              : Object.values(fieldValue)

            const documentsPromises = targetDocuments?.map((id) =>
              getDocument(relationshipCollectionName, id)
            )

            const documentsData = await Promise.all(documentsPromises)

            const documentsNames = documentsData.map((item) =>
              checkIsObject(item?.name)
                ? item?.name?.[language] || item?.name?.en
                : item?.name
            )

            const columnValue = documentsNames.length
              ? documentsNames.join(', ')
              : 'n/a'

            setColumnValue(columnValue)
          } else {
            const documentSnapshot = await getDoc(
              doc(firestore, relationshipCollectionName, columnData?.fieldValue)
            )

            const documentValue = documentSnapshot?.data()

            setColumnValue(
              documentValue?.name?.[language] || documentValue?.name?.en
            )
          }
        }
      } catch (error) {
        message.error(
          t('Something went wrong during getting relationship value')
        )
      } finally {
        setLoading(false)
      }
    },
    [columnData, language, t]
  )

  useEffect(() => {
    const [relationshipCollectionName] =
      getRelationshipCollectionName(columnData)

    if (relationshipCollectionName) {
      getRelationshipData(relationshipCollectionName)
    } else {
      setColumnValue(columnData?.fieldValue || 'n/a')
    }
  }, [columnData, getRelationshipData])

  return [columnValue, loading]
}

export default useGetMenuItemColumnData
