import { useCallback, useEffect, useState } from 'react'

const useDefineIfScrollable = (ref, minWidth) => {
  const [width, setWidth] = useState(ref?.current?.offsetWidth)
  const [isScrollable, setIsScrollable] = useState(
    parseInt(width) <= parseInt(minWidth)
  )
  const handleWindowResize = useCallback(() => {
    setWidth(ref?.current?.offsetWidth)

    // <=, because the row has min-width, so it will be scrollable if width and minWidth are equal
    setIsScrollable(parseInt(width) <= parseInt(minWidth))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [minWidth, ref, width, ref?.current?.offsetWidth])

  useEffect(() => {
    handleWindowResize()
    window.addEventListener('resize', handleWindowResize)

    return () => {
      window.removeEventListener('resize', handleWindowResize)
    }
  }, [handleWindowResize, minWidth, ref, width, ref?.current?.offsetWidth])
  return isScrollable
}

export default useDefineIfScrollable
