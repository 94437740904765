import { useMemo } from 'react'
import { useLoading } from 'hooks'
import { useDocument } from 'services/api/firebase'
import { COLLECTIONS } from '__constants__'
import {} from 'domains/GlassType/hooks'

const useGetGlassTypeInitialValues = (id) => {
  /* Fetching data using useDocument hook */
  const [glassType, glassTypeLoading] = useDocument({
    ref: id ? COLLECTIONS.GLASS_TYPES + '/' + id : null
  })

  /* Merge all fetched data */
  const initialValues = useMemo(
    () => ({
      glassType
    }),
    [glassType]
  )

  /* Merge all loading states */
  const loadings = useMemo(
    () => (id ? [!glassType, glassTypeLoading] : []),
    [id, glassType, glassTypeLoading]
  )

  /* Getting loading state using merged loadings */
  const loading = useLoading(loadings)

  return [initialValues, loading]
}

export default useGetGlassTypeInitialValues
