import { Box, Col, Row } from '@qonsoll/react-design'

import {
  ItemWrapper,
  ScrollableCol,
  ScrollableRow,
  staticColStyle
} from 'components/Table/Table.styled'

import {
  useGetItemTypeData,
  useGetItemTypeFieldData
} from 'domains/ItemType/hooks'

import { ButtonAccent } from 'components'
import { CONSTANT_NAME_FIELD } from '__constants__'
import { Icon } from '@qonsoll/icons'
import { MenuItemColumn } from 'domains/Menu/components/MenuAdvancedTable/components'
import MenuListItemColumn from './components/MenuListItemColumn'
import { Popconfirm, Skeleton } from 'antd'
import PropTypes from 'prop-types'
import { ScrollSyncPane } from 'react-scroll-sync'
import { useDefineIfScrollable } from 'components/Table/hooks'
import { useGetCategoryData } from 'domains/Category/hooks'
import { useMenuItemActions } from 'domains/MenuItem/hooks'
import { useRef } from 'react'
import { useTranslations } from 'contexts/Translation'
import { useLoading } from 'hooks'
import { useParams } from 'react-router-dom'

const MenuListItem = (props) => {
  const {
    _id,
    title,
    description,
    tableProps,
    editCocktail,
    draggableProps,
    name,
    categoryId,
    rewrites
  } = props

  const [categoryData, categoryLoading] = useGetCategoryData(categoryId)
  const [itemTypeData, itemTypeLoading] = useGetItemTypeData(
    categoryData?.itemTypeId
  )

  const params = useParams()

  const { menuId } = params

  const [itemTypeFieldsData, itemTypeFieldsLoading, error] =
    useGetItemTypeFieldData(itemTypeData)

  const loading = useLoading([
    categoryLoading,
    itemTypeLoading,
    itemTypeFieldsLoading
  ])

  const { removeMenuItemFromMenu } = useMenuItemActions()

  const newCombinedColumnData = () => {
    if (!itemTypeFieldsLoading && !error) {
      const rewritesObject = rewrites?.[menuId]

      return itemTypeFieldsData
        .filter((field) => field.name !== CONSTANT_NAME_FIELD)
        .map((field) => {
          const { list, object, enum: enumValue, isEditable } = field || {}

          const fieldName = field.name
          const rewriterValue = rewritesObject?.[fieldName]

          const fieldValue = rewriterValue || props[fieldName]
          const relationType = field?.relationType

          return {
            fieldValue: fieldValue,
            relationType: relationType,
            fieldName,
            list,
            object,
            enumValue,
            isEditable
          }
        })
    }

    return []
  }

  const combinedColumnData = newCombinedColumnData()

  const {
    scrollableColumnWidth,
    scrollableContainerMinWidth,
    fixedColCw,
    scrollableColCw
  } = tableProps

  const { t } = useTranslations()

  // whether col is scrollable and should have a shadow or not
  const ref = useRef()
  const isScrollable = useDefineIfScrollable(ref, scrollableContainerMinWidth)
  const colShadow = isScrollable ? 'var(--table-static-col-shadow)' : 'none'

  const onRemoveMenuItem = () => {
    removeMenuItemFromMenu(categoryId, _id)
  }

  const onEditMenuItem = () => {
    editCocktail(_id)
  }

  return (
    <ItemWrapper>
      <Row overflow="hidden" noGutters>
        {loading ? (
          <Skeleton loading={loading} active={true} paragraph={{ rows: 1 }} />
        ) : (
          <>
            <Col
              cw={fixedColCw}
              {...staticColStyle}
              alignSelf="stretch"
              flexDirection="row"
              boxShadow={colShadow}>
              <MenuItemColumn
                draggableProps={draggableProps}
                dragable
                item={{ name, description }}
                rewrites={rewrites}
              />
            </Col>
            <ScrollSyncPane group="horizontal">
              <ScrollableCol cw={scrollableColCw} padding={3}>
                <ScrollableRow
                  ref={ref}
                  minWidth={scrollableContainerMinWidth}
                  v="center"
                  height="100%">
                  {combinedColumnData?.map((columnData, index) => {
                    return (
                      <Col
                        key={index}
                        cw={11 / combinedColumnData?.length}
                        v="center">
                        <MenuListItemColumn
                          menuItemId={_id}
                          columnData={columnData}
                        />
                      </Col>
                    )
                  })}
                  <Col
                    style={{ flex: 'auto' }}
                    cw={scrollableColumnWidth[5]}
                    v="center"
                    h="right">
                    <Box display="flex">
                      <ButtonAccent
                        type="text"
                        icon={
                          <Box>
                            <Icon name="Edit3Outlined" />
                          </Box>
                        }
                        onClick={onEditMenuItem}
                      />
                      <Popconfirm
                        title={`${t(
                          'Are you sure you want to remove'
                        )} ${title}?`}
                        okText={t('Yes')}
                        cancelText={t('No')}
                        onConfirm={onRemoveMenuItem}>
                        <ButtonAccent
                          type="text"
                          danger
                          icon={<Icon name="Trash4Outlined" />}
                        />
                      </Popconfirm>
                    </Box>
                  </Col>
                </ScrollableRow>
              </ScrollableCol>
            </ScrollSyncPane>
          </>
        )}
      </Row>
    </ItemWrapper>
  )
}

MenuListItem.propTypes = {
  _id: PropTypes.string,
  index: PropTypes.number,
  title: PropTypes.string,
  price: PropTypes.number,
  glassType: PropTypes.string,
  ingredients: PropTypes.array,
  availability: PropTypes.string,
  description: PropTypes.string,
  methods: PropTypes.oneOfType([PropTypes.array, PropTypes.string]),
  mainSpiritType: PropTypes.oneOfType([PropTypes.array, PropTypes.string]),
  flavor: PropTypes.oneOfType([PropTypes.array, PropTypes.string]),
  tableProps: PropTypes.object,
  onPriceChange: PropTypes.func,
  deleteCocktail: PropTypes.func,
  editCocktail: PropTypes.func,
  onEditCocktailAvailability: PropTypes.func,
  draggableProps: PropTypes.object,
  name: PropTypes.oneOf([PropTypes.string, PropTypes.object]),
  categoryId: PropTypes.string,
  rewrites: PropTypes.object
}

export default MenuListItem
