import { Col, Divider, Input, Row, Text } from '@qonsoll/react-design'
import { useTranslations } from '@qonsoll/translation'
import { Form, Popconfirm } from 'antd'
import PropTypes from 'prop-types'

import { ButtonAccent, SelectWithFixedDropdown } from 'components'

import { InteractiveMenuQuestionsForm } from 'domains/InteractiveMenu/components'
import {
  useActionsInteractiveMenuAdvancedForm,
  useInitializeInteractiveMenuAdvancedForm
} from 'domains/InteractiveMenu/hooks'

const InteractiveMenuAdvancedForm = (props) => {
  const { initialData } = props
  const { t } = useTranslations()

  const {
    form,
    selectedItemType,
    transformedMenuItemTypeOptions,
    changeSelectedItemType,
    dataLoading,
    itemTypesFields,
    itemTypesFieldsLoading
  } = useInitializeInteractiveMenuAdvancedForm(initialData)

  const { onFinish, onReset, loading } =
    useActionsInteractiveMenuAdvancedForm(initialData)

  const handleFilterOptions = (input, option) =>
    option?.label?.toLowerCase()?.indexOf(input?.toLowerCase()) === 0

  return (
    <Form onFinish={onFinish} form={form} layout="vertical">
      <Form.Item
        rules={[
          {
            required: true,
            message: t('Please, enter interactive menu name')
          }
        ]}
        name="name"
        label={t('Enter interactive menu name')}>
        <Input placeholder={t('Please, enter interactive menu name')} />
      </Form.Item>
      <Form.Item
        rules={[
          {
            required: true,
            message: t('Please, select item type')
          }
        ]}
        name="itemType"
        label={t('Select item type')}>
        <SelectWithFixedDropdown
          showSearch
          filterOption={handleFilterOptions}
          loading={dataLoading}
          value={selectedItemType}
          onChange={changeSelectedItemType}
          options={transformedMenuItemTypeOptions}
          placeholder={t('Please, select item type')}
        />
      </Form.Item>
      <Divider>
        <Text>{t('Questions')}</Text>
      </Divider>
      <Form.Item name="questions">
        <InteractiveMenuQuestionsForm
          selectedItemType={selectedItemType}
          itemTypesFields={itemTypesFields}
          itemTypesFieldsLoading={itemTypesFieldsLoading}
        />
      </Form.Item>
      <Row noGutters>
        <Col cw="auto">
          <Popconfirm
            title={`${t(
              'Are you sure you want to discard the changes you have made'
            )}?`}
            okText={t('Yes')}
            onConfirm={onReset}
            cancelText={t('No')}>
            <ButtonAccent
              onClick={onReset}
              type="secondary"
              mr={2}
              htmlType="button">
              {t('Cancel')}
            </ButtonAccent>
          </Popconfirm>
        </Col>
        <Col cw="auto">
          <ButtonAccent loading={loading} type="primary" htmlType="submit">
            {t('Save')}
          </ButtonAccent>
        </Col>
      </Row>
    </Form>
  )
}
InteractiveMenuAdvancedForm.propTypes = {
  initialData: PropTypes.object
}

export default InteractiveMenuAdvancedForm
