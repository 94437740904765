import { MENU_AVAILABILITIES } from '__constants__/enums'
import { checkMenuStatus } from 'helpers'
import { useMemo } from 'react'
import { useTranslations } from 'contexts/Translation'
const useGetMenuInfo = (menu) => {
  const { t } = useTranslations()
  // This is needed to make old values expressed as YES/NO and new value as true/false to work
  const ribbonColor = useMemo(
    () =>
      menu?.privacy === MENU_AVAILABILITIES.PRIVATE.toUpperCase() ||
      menu?.privacy === true
        ? 'var(--ribbon-disabled-bg)'
        : (menu?.privacy === MENU_AVAILABILITIES.PUBLIC.toUpperCase() ||
            menu?.privacy === false) &&
          'var(--ribbon-active-bg)',
    [menu?.privacy]
  )
  // This is needed to make old values expressed as YES/NO and new value as true/false to work
  const menuTitle = useMemo(
    () =>
      menu?.privacy === MENU_AVAILABILITIES.PRIVATE.toUpperCase() ||
      menu?.privacy === true
        ? MENU_AVAILABILITIES.PRIVATE
        : (menu?.privacy === MENU_AVAILABILITIES.PUBLIC.toUpperCase() ||
            menu?.privacy === false) &&
          MENU_AVAILABILITIES.PUBLIC,
    [menu?.privacy]
  )

  const activenessColor =
    checkMenuStatus(menu) === 'Not active'
      ? 'var(--ql-text-secondary)'
      : 'var(--ql-text-success)'

  const menuInformation = useMemo(
    () => [
      {
        key: 'Privacy',
        data: menuTitle,
        emptyText: t('No privacy status'),
        color: ribbonColor
      },
      {
        key: 'Activeness',
        data: checkMenuStatus(menu),
        emptyText: t('No activeness status'),
        color: activenessColor
      }
    ],
    [menuTitle, t, ribbonColor, menu, activenessColor]
  )

  return menuInformation
}

export default useGetMenuInfo
