import { useEffect, useState, useCallback } from 'react'
import { getDocument } from 'services/api/firebase'
import { COLLECTIONS } from '__constants__'

/**
 * It fetches a menus documents from Firestore and returns it
 * @param restaurant - The restaurant object that we're getting the menus for.
 * @returns An array with two values. The first value is the menus object, and the second value is a
 * boolean that indicates whether the menus is loading.
 */
const useGetRestaurantZones = (restaurant) => {
  /* State for data */
  const [zones, setZones] = useState([])
  /* State for loading */
  const [loading, setLoading] = useState(!!restaurant?.zones?.length)
  /* State for error */
  const [error, setError] = useState(null)

  /* Fetching data */
  const getZones = useCallback(async () => {
    try {
      /* Setting loading state to true */
      setLoading(true)

      /* Getting menus promises*/
      const promises = restaurant?.zones?.map((zone) =>
        getDocument(COLLECTIONS.ZONES, zone)
      )

      /* Getting menus data */
      const data = await Promise.all(promises)

      /* Setting menus data */
      setZones(data)

      /* Setting loading state to false */
    } catch (error) {
      setError(error)
    }
    setLoading(false)
  }, [restaurant])

  useEffect(() => {
    restaurant?.zones?.length && getZones()
  }, [restaurant, getZones])

  return [zones, loading, error, getZones]
}

export default useGetRestaurantZones
