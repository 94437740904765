import login from './login'

const changePassword = async (uid, email, password, mailId, onError) => {
  try {
    const request = await fetch(
      `${process.env.REACT_APP_FIREBASE_FUNCTIONS_URL}/users/change-password`,
      {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ uid, password, mailId })
      }
    )
    if (request?.ok) await login({ credentials: { email, password } })
    else {
      const json = await request.json()
      throw new Error(json.error)
    }
  } catch (error) {
    //eslint-disable-next-line
    console.error('Error during password change operation:', error)
    onError?.(error)
  }
}

export default changePassword
