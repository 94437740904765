import { generatePath, useHistory, useParams } from 'react-router-dom'

import { COLLECTIONS } from '__constants__'
import { Container } from '@qonsoll/react-design'
import { MenuList } from 'domains/Menu/components'
import PropTypes from 'prop-types'
import { updateDocument } from 'services/api/firebase'
import { useGetRestaurantMenus } from 'domains/Restaurant/hooks'
import { useMenuBaseActions } from 'domains/Menu/hooks'

export default function RestaurantMenus(props) {
  const { restaurant, actions = {} } = props
  /* Getting relationship data */
  // eslint-disable-next-line no-unused-vars
  const [menus, loading, error, refresh] = useGetRestaurantMenus(restaurant)

  /* Getting history instance */
  const history = useHistory()
  const { companyId, restaurantId } = useParams()

  /* Getting Menu actions */
  const { createPath } = useMenuBaseActions()

  const handleDelete = async (doc, index) => {
    restaurant?.menus?.splice(index, 1)
    await updateDocument(COLLECTIONS.RESTAURANTS, restaurant?._id, {
      menus: restaurant?.menus
    })
    refresh?.()
  }

  const handleCreate = () => {
    const pathParams = { profileId: companyId, restaurantId }
    history.push(generatePath(createPath, pathParams), {
      parent: {
        id: restaurant?._id,
        collection: COLLECTIONS.RESTAURANTS,
        field: 'menus',
        type: 'updateParentHasManyRelationship'
      }
    })
  }

  /* Actions */
  const computedActions = {
    ...actions,
    onDelete: handleDelete,
    onCreate: createPath ? handleCreate : null
  }

  return (
    <Container>
      <MenuList
        px={2}
        cw={[12, 12, 6, 6]}
        isListWithCreate={!!createPath}
        actions={computedActions}
        menus={menus}
      />
      {/* <DishMenusList
        cw={[12, 6, 4]}
        isListWithCreate={!!createPath}
        actions={computedActions}
        menus={menus}
      /> */}
    </Container>
  )
}

RestaurantMenus.propTypes = {
  restaurant: PropTypes.object.isRequired,
  actions: PropTypes.object
}
