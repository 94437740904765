import { useCallback, useEffect, useState } from 'react'

import { COLLECTIONS } from '__constants__'
import { getDocument } from 'services/api/firebase'

const useGetTableQRCodes = (table) => {
  /* State for data */
  const [tableQRCodes, setTableQRCodes] = useState([])
  /* State for loading */
  const [loading, setLoading] = useState(!!table?.qRCodes?.length)
  /* State for error */
  const [error, setError] = useState(null)

  /* Fetching data */
  const getTableQRCodes = useCallback(async () => {
    try {
      /* Setting loading state to true */
      setLoading(true)

      /* Getting menuCategories promises*/
      const promises = table?.qRCodes?.map((qrCode) =>
        getDocument(COLLECTIONS.Q_R_CODES, qrCode)
      )

      /* Getting menuCategories data */
      const data = await Promise?.all(promises)

      /* Setting menuCategories data */
      setTableQRCodes(data?.filter((qRCode) => qRCode))

      /* Setting loading state to false */
    } catch (error) {
      setError(error)
    }
    setLoading(false)
  }, [table])

  useEffect(() => {
    table?.qRCodes?.length && getTableQRCodes()
  }, [table, getTableQRCodes])

  return [tableQRCodes, loading, error, getTableQRCodes]
}

export default useGetTableQRCodes
