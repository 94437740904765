import { Box } from '@qonsoll/react-design'
import styled from 'styled-components'

const ListHeader = styled(Box)`
  border-bottom: var(--border-width-default) var(--border-style-default)
    var(--ql-color-dark-t-lighten5);
  color: var(--table-header-color);
  font-weight: 500;
  text-align: left;
  background: var(--table-header-bg);
  padding: 16px;
  border-radius: var(--ql-border-radius-default) var(--ql-border-radius-default)
    0 0;
`

export default ListHeader
