import { useCallback, useEffect, useState } from 'react'

import { COLLECTIONS } from '__constants__'
import { getDocument } from 'services/api/firebase'

/**
 * It fetches a cocktails documents from Firestore and returns it
 * @param menu - The menu object that we're getting the cocktails for.
 * @returns An array with two values. The first value is the cocktails object, and the second value is a
 * boolean that indicates whether the cocktails is loading.
 */
const useGetMenuCocktails = (menu) => {
  /* State for data */
  const [cocktails, setCocktails] = useState([])
  /* State for loading */
  const [loading, setLoading] = useState(!!menu?.cocktails?.length)
  /* State for error */
  const [error, setError] = useState(null)

  /* Fetching data */
  const getCocktails = useCallback(async () => {
    try {
      /* Setting loading state to true */
      setLoading(true)

      /* Getting cocktails promises*/
      const promises = menu.cocktails.map((cocktail) =>
        getDocument(COLLECTIONS.COCKTAILS_NEW, cocktail)
      )

      /* Getting cocktails data */
      const data = await Promise.all(promises)

      /* Setting cocktails data */
      setCocktails(
        data?.map(({ _id, price, ...rest }) => ({
          ...rest,
          _id,
          price: menu?.cocktailsAvailabilities?.[_id]?.price || price
        }))
      )

      /* Setting loading state to false */
    } catch (error) {
      setError(error)
    }
    setLoading(false)
  }, [menu?.cocktails, menu?.cocktailsAvailabilities])

  useEffect(() => {
    menu?.cocktails?.length && getCocktails()
  }, [menu?.cocktails, getCocktails])

  return [cocktails, loading, error, getCocktails]
}

export default useGetMenuCocktails
