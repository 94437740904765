import { Form } from 'antd'
import { ZONE_AVAILABILITIES_KEYS } from '__constants__'
import { useEffect } from 'react'

const useInitializeZoneAdvancedForm = (initialData) => {
  /* Getting form instance */
  const [form] = Form.useForm()

  /* Setting initial data */
  useEffect(() => {
    if (initialData) {
      /* Getting initial values */
      const { zone } = initialData
      // This is needed to make old values expressed as сonstants and new value as true/false to work
      const zoneAvailabilityWithOldData =
        zone?.['available'] === ZONE_AVAILABILITIES_KEYS.AVAILABLE
          ? true
          : false
      const zoneAvailability =
        typeof zone?.['available'] === 'boolean'
          ? zone?.['available']
          : zoneAvailabilityWithOldData
      /* Formatting date fields */
      const formValues = {
        zone: {
          title: zone?.['title'] ?? null,
          available: zoneAvailability ?? null
        }
      }

      /* Setting initial values to form instance */
      form.setFieldsValue(formValues)
    } else {
      const formValues = {
        zone: {
          title: null,
          available: true
        }
      }

      /* Setting initial values to form instance */
      form.setFieldsValue(formValues)
    }
  }, [initialData, form])

  return { form }
}

export default useInitializeZoneAdvancedForm
