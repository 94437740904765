import { Col, Container, Option, Row, Title } from '@qonsoll/react-design'

import PropTypes from 'prop-types'
import { SelectWithFixedDropdown } from 'components'
import { useGetGlassTypes } from 'domains/GlassType/hooks'
import { useMemo } from 'react'
import { useTranslations } from 'contexts/Translation'

// Takes a value of type object and onChange function
// Returns selected option object
const GlassTypeSelect = (props) => {
  const {
    value,
    onChange,
    showTitle = true,
    allowClear = true,
    exclude = [],
    mode,
    ...rest
  } = props

  /* Getting all possibility variants for select */
  const [allGlassTypes = [], loading] = useGetGlassTypes({ orderBy: 'name' })
  /* Getting translation instance */
  const { t } = useTranslations()

  /* Checking if mode is multiple */
  const isMultiple = useMemo(() => mode === 'multiple', [mode])
  /* Handling case when db record not added yet but value already exists and ready to be used */
  const valueComputed = useMemo(() => {
    if (isMultiple) return value
    return value?._id || null
  }, [value, isMultiple])

  /* Filtering out excluded values */
  const optionsComputed = useMemo(() => {
    const _options = allGlassTypes
    const filteredOptions = _options.filter((o) => !exclude.includes(o._id))

    return filteredOptions
  }, [allGlassTypes, exclude])

  /* Handling change selected option */
  const handleChange = (value) => {
    if (isMultiple) {
      const _options = optionsComputed.filter((o) => value.includes(o._id))
      onChange?.(_options || null, value)
    } else {
      const _option = optionsComputed.find(({ _id: id }) => id === value)

      onChange?.(_option || null, value)
    }
  }

  return (
    <Container {...rest}>
      {showTitle && (
        <Title mb={2} level={4}>
          {t('Glass type')}
        </Title>
      )}
      <Row noGutters>
        <Col>
          <SelectWithFixedDropdown
            aria-label="Select a glass type"
            aria-describedby="select-description"
            mode={mode}
            allowClear={allowClear}
            loading={loading}
            placeholder={t('Please, select glass type')}
            value={valueComputed}
            onChange={handleChange}>
            {optionsComputed.map((glassType) => (
              <Option key={glassType?._id} value={glassType?._id}>
                {glassType?.name}
              </Option>
            ))}
          </SelectWithFixedDropdown>
        </Col>
      </Row>
    </Container>
  )
}

GlassTypeSelect.propTypes = {
  value: PropTypes.object,
  onChange: PropTypes.func,
  showTitle: PropTypes.bool,
  allowClear: PropTypes.bool,
  exclude: PropTypes.array,
  mode: PropTypes.string
}

export default GlassTypeSelect
