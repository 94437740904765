import { Col, PageWrapper, Row } from '@qonsoll/react-design'
import { HeaderBreadcrumbs, Spinner } from 'components'
import { UserList, UserSortMenu } from 'domains/User/components'
import { useFilter, useListView } from 'hooks'

import PATHS from 'pages/paths'
import { PageActions } from 'components'
import PropTypes from 'prop-types'
import { useGetUsers } from 'domains/User/hooks'
import { useMemo } from 'react'
import { useTranslations } from 'contexts/Translation'
import { useUserBaseActions } from 'domains/User/hooks'

const UsersAllPage = (props) => {
  const { isListWithCreate, withHeader = true } = props

  /* Getting translations instance */
  const { t } = useTranslations()
  /* State for controlling list view with localStorage remembering */
  const [listView, setListView] = useListView('user')
  /* Getting filter params */
  const filterParams = useFilter('user')
  /* Destructuring filter params */
  const { filterVisibility, filterData, setFilterData } = filterParams
  /* Building filter query */
  const ref = useMemo(() => filterData, [filterData])
  /* Fetching users */
  const [users, loading] = useGetUsers(ref)
  /* Getting base actions */
  const listActions = useUserBaseActions()

  /* Configuring page header */
  const headingProps = {
    title: t('Users'),
    titleSize: 3,
    marginBottom: 3,
    textAlign: 'left'
  }

  /* Configuring page actions */
  const actions = {
    filter: false,
    sort: true,
    listView: true,
    create: Boolean(
      PATHS.AUTHENTICATED.USER_CREATE || PATHS.AUTHENTICATED.USER_WIZARD
    )
  }

  /* Extracting base actions */
  const { onBack, onCreate } = listActions

  return (
    <PageWrapper
      action={
        withHeader ? (
          <PageActions
            {...filterParams}
            listView={listView}
            setListView={setListView}
            onCreate={onCreate}
            actions={actions}
            createText={t('Create user')}
            sortMenu={
              <UserSortMenu
                filteredData={filterData}
                setFilteredData={setFilterData}
              />
            }
          />
        ) : null
      }
      onBack={withHeader ? onBack : null}
      breadcrumbs={withHeader ? <HeaderBreadcrumbs /> : null}
      headingProps={withHeader ? headingProps : null}>
      <Row noGutters>
        {loading ? (
          <Col>
            <Spinner height="70vh" text={t('User loading')} />
          </Col>
        ) : (
          <Col cw={!filterVisibility && 12}>
            <UserList
              listView={listView}
              isListWithCreate={isListWithCreate}
              actions={{ onCreate }}
              users={users}
            />
          </Col>
        )}
      </Row>
    </PageWrapper>
  )
}

UsersAllPage.propTypes = {
  isListWithCreate: PropTypes.bool,
  withHeader: PropTypes.bool
}

export default UsersAllPage
