import { Box, Col, Row, Title } from '@qonsoll/react-design'

import PropTypes from 'prop-types'

const AllergenView = (props) => {
  const { allergen } = props

  return (
    <Box cursor="pointer">
      {allergen?.name && (
        <Row noGutters justifyContent="center" alignItems="center" py="4px">
          <Col cw={12}>
            <Title
              level={5}
              overflow="hidden"
              textOverflow="ellipsis"
              whiteSpace="nowrap">
              {allergen?.name}
            </Title>
          </Col>
        </Row>
      )}
    </Box>
  )
}

AllergenView.propTypes = {
  allergen: PropTypes.object
}

export default AllergenView
