import { Col, Row, Text } from '@qonsoll/react-design'
import { generatePath, useHistory } from 'react-router-dom'
import PropTypes from 'prop-types'

import { useCustomerContext } from 'contexts/CustomerContext'
import { useTranslations } from 'contexts/Translation'
import { AddItemCard } from 'components'
import PATHS from 'pages/paths'
import { USER_ROLES_KEYS } from '__constants__'

import { AdminSimpleView } from 'domains/Admin/components'

const AdminList = (props) => {
  const { admins, listView, cw = [12, 6, 4], user } = props

  /* Getting translations instance */
  const { t } = useTranslations()
  /* Getting history instance */
  const history = useHistory()

  const { currentCustomerId } = useCustomerContext()

  /* Checking if list is empty */
  const isEmpty = !admins?.length
  /* Computing item width */
  const itemWidth = listView ? 12 : cw
  /* Check if the current user is owner of the company */
  const isUserOwner =
    user?.companyRoles?.[currentCustomerId] === USER_ROLES_KEYS.COMPANY_OWNER

  const handleCustomerAdminInvite = () => {
    const pathParams = { companyId: currentCustomerId }
    const path = generatePath(
      PATHS.AUTHENTICATED.COMPANY_ADMIN_INVITE,
      pathParams
    )
    history.push(path)
  }

  return (
    <Row negativeBlockMargin pr={2}>
      {isUserOwner ? (
        <Col mb={4} cw={itemWidth}>
          <AddItemCard
            minHeight="140px"
            message={t('Add admin')}
            onClick={handleCustomerAdminInvite}
          />
        </Col>
      ) : null}

      {!isEmpty &&
        admins?.map((admin, index) => (
          <Col mb={4} key={admin?._id || index} cw={itemWidth}>
            <AdminSimpleView
              index={index}
              admin={admin}
              isUserOwner={isUserOwner}
              user={user}
            />
          </Col>
        ))}
      {isEmpty && (
        <Col cw={12}>
          <Text variant="overline" color="var(--ql-state-disabled-color)">
            {t('No admins have been added yet')}
          </Text>
        </Col>
      )}
    </Row>
  )
}

AdminList.propTypes = {
  admins: PropTypes.array,
  listView: PropTypes.bool,
  withCreate: PropTypes.bool,
  isAdminInvitedOrActive: PropTypes.string,
  cw: PropTypes.any,
  adminsInvited: PropTypes.array,
  user: PropTypes.object
}

export default AdminList
