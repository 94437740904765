import { Box, Title } from '@qonsoll/react-design'
import { ButtonAccent, ButtonWithPopconfirm } from 'components'
import {
  useActionsZoneAdvancedForm,
  useInitializeZoneAdvancedForm
} from './hooks'

import { Form } from 'antd'
import { KEYS } from '__constants__'
import PropTypes from 'prop-types'
import { ZoneSimpleForm } from 'domains/Zone/components'
import { useState } from 'react'
import { useTranslations } from 'contexts/Translation'

const ZoneAdvancedForm = (props) => {
  const { initialData, group, showTitle } = props

  const [isFormChanged, setIsFormChanged] = useState(false)
  /* Getting translations instance */
  const { t } = useTranslations()
  /* Getting form instance from hook */
  const { form } = useInitializeZoneAdvancedForm(initialData)
  /* Getting loading state and actions from hook */
  const { loading, onFinish, onReset } = useActionsZoneAdvancedForm({
    initialData,
    form
  })
  /* Handling enter key press */
  const handleKeyPress = (e) => {
    if (e.key === KEYS.ENTER) {
      e.preventDefault()
      onFinish()
    }
  }
  const onFormValuesChange = () => setIsFormChanged(true)
  return group ? (
    <>
      {showTitle && (
        <Title level={4} mb={2}>
          {t('Zone')}
        </Title>
      )}
      <ZoneSimpleForm showTitle={false} group={[...group, 'zone']} />
    </>
  ) : (
    <>
      <Form
        scrollToFirstError
        onValuesChange={onFormValuesChange}
        onKeyPress={handleKeyPress}
        onFinish={onFinish}
        layout="vertical"
        autoComplete="off"
        form={form}>
        {showTitle && (
          <Title level={4} mb={2}>
            {t('Table')}
          </Title>
        )}
        <ZoneSimpleForm group={['zone']} />
        <Box display="flex">
          <ButtonWithPopconfirm
            onReset={onReset}
            withPopconfirm={isFormChanged}
            documentTitle={initialData?.zone?.title}
          />
          <ButtonAccent
            type="primary"
            loading={loading}
            onClick={() => form.submit()}>
            {t('Save')}
          </ButtonAccent>
        </Box>
      </Form>
    </>
  )
}
ZoneAdvancedForm.propTypes = {
  initialData: PropTypes.object,
  group: PropTypes.array,
  showTitle: PropTypes.bool
}

export default ZoneAdvancedForm
