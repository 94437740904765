import {
  collection,
  query,
  orderBy as orderByQuery,
  where
} from 'firebase/firestore'
import { useCollectionData } from 'react-firebase-hooks/firestore'
import { firestore } from 'services/firebase'
import { COLLECTIONS } from '__constants__'

const useGetInteractiveMenus = (props) => {
  const { orderBy: orderByProps = [], where: whereByProps = [] } = props
  const itemTypesRef = collection(firestore, COLLECTIONS.INTERACTIVE_MENUS)

  const whereFilters =
    whereByProps && whereByProps.length ? [where(...whereByProps)] : []

  const orderByFilters = orderByProps?.length
    ? [orderByQuery(...props.orderBy)]
    : []

  const queryData = [itemTypesRef, ...orderByFilters, ...whereFilters]

  return useCollectionData(query(...queryData))
}

export default useGetInteractiveMenus
