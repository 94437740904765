import { Box, Col, Row, Title } from '@qonsoll/react-design'
import { ButtonAccent, ButtonWithPopconfirm } from 'components'
import {
  useActionsMenuCategoryAdvancedForm,
  useInitializeMenuCategoryAdvancedForm
} from './hooks'

import { CocktailsReviewTable } from 'domains/Cocktail/components'
import { Form } from 'antd'
import { KEYS } from '__constants__'
import { MenuCategorySimpleForm } from 'domains/MenuCategory/components'
import PropTypes from 'prop-types'
import { useState } from 'react'
import { useTranslations } from 'contexts/Translation'

const MenuCategoryAdvancedForm = (props) => {
  const { initialData, group, showTitle } = props
  const [isFormChanged, setIsFormChanged] = useState(false)
  /* Getting translations instance */
  const { t } = useTranslations()
  /* Getting form instance from hook */
  const { form } = useInitializeMenuCategoryAdvancedForm(initialData)

  /* Handling enter key press */
  const handleKeyPress = (e) => {
    if (e.key === KEYS.ENTER) {
      e.preventDefault()
      onFinish()
    }
  }

  /* Getting loading state and actions from hook */
  const { loading, onFinish, onReset } = useActionsMenuCategoryAdvancedForm({
    initialData,
    form
  })
  const onFormValuesChange = () => setIsFormChanged(true)

  return group ? (
    <>
      {showTitle && (
        <Title level={4} mb={2}>
          {t('Menu category')}
        </Title>
      )}
      <MenuCategorySimpleForm
        showTitle={false}
        group={[...group, 'menuCategory']}
      />
      <Row noGutters mb={2}>
        <Col>
          <Title level={4}>{t('Drinks')}</Title>
        </Col>
      </Row>
      <Form.Item
        name={[group, 'menuCategory', 'cocktailsAvailabilities']}
        label={t('Menu category drinks')}>
        <CocktailsReviewTable allUnselected />
      </Form.Item>
    </>
  ) : (
    <Form
      scrollToFirstError
      onValuesChange={onFormValuesChange}
      onKeyPress={handleKeyPress}
      onFinish={onFinish}
      layout="vertical"
      autoComplete="off"
      form={form}>
      {showTitle && (
        <Title level={4} mb={2}>
          {t('Menu category')}
        </Title>
      )}
      <MenuCategorySimpleForm group={['menuCategory']} />
      <Row noGutters mb={2}>
        <Col>
          <Title level={4}>{t('Cocktails')}</Title>
        </Col>
      </Row>
      <Form.Item
        name={['menuCategory', 'cocktailsAvailabilities']}
        label={t('Menu category drinks')}>
        <CocktailsReviewTable allUnselected />
      </Form.Item>
      <Box display="flex">
        <ButtonWithPopconfirm
          onReset={onReset}
          withPopconfirm={isFormChanged}
          documentTitle={initialData?.menuCategory?.name}
        />
        <ButtonAccent
          type="primary"
          loading={loading}
          onClick={() => form.submit()}>
          {t('Save')}
        </ButtonAccent>
      </Box>
    </Form>
  )
}
MenuCategoryAdvancedForm.propTypes = {
  initialData: PropTypes.object,
  group: PropTypes.array,
  showTitle: PropTypes.bool
}

export default MenuCategoryAdvancedForm
