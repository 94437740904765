import { Col, Row, Text } from '@qonsoll/react-design'

import { AddItemCard } from 'components'
import { MenuItemSimpleView } from 'domains/MenuItem/components'
import PropTypes from 'prop-types'
import { useTranslations } from '@qonsoll/translation'

const MenuItemList = (props) => {
  const {
    isListWithCreate,
    menuItems,
    actions,
    emptyProps,
    cw = [12, 6, 6, 4]
  } = props

  /* Getting translations instance */
  const { t } = useTranslations()
  /* Checking if list is empty */
  const isEmpty = !menuItems?.length
  const showCreate = isListWithCreate && actions?.onCreate

  const emptyText =
    emptyProps?.message || t('No menu items have been created yet')

  return (
    <Row negativeBlockMargin>
      {!showCreate && (
        <Col mb={4} cw={cw}>
          <AddItemCard
            minHeight="72px"
            message={t('Add menu item')}
            onClick={actions?.onCreate}
          />
        </Col>
      )}
      {isEmpty ? (
        <Col cw={12}>
          <Text variant="overline" color="var(--ql-state-disabled-color)">
            {emptyText}
          </Text>
        </Col>
      ) : (
        menuItems?.map((menuItem, index) => (
          <Col mb={4} key={`menuItem-${menuItem?._id || index}`} cw={cw}>
            <MenuItemSimpleView menuItem={menuItem} index={index} />
          </Col>
        ))
      )}
    </Row>
  )
}

MenuItemList.propTypes = {
  menuItems: PropTypes.arrayOf(PropTypes.object),
  actions: PropTypes.object,
  emptyProps: PropTypes.string,
  cw: PropTypes.array,
  isListWithCreate: PropTypes.bool
}

export default MenuItemList
