import { Box, Col, PageWrapper, Row } from '@qonsoll/react-design'
import { HeaderBreadcrumbs, Spinner } from 'components'
import { useHistory, useParams } from 'react-router-dom'

import { AcceptOrDeclineTermsAndConditionsPopconfirm } from 'domains/App/components'
import { ButtonAccent } from 'components'
import { COLLECTIONS } from '__constants__'
import PATHS from 'pages/paths'
import { PageActions } from 'components'
import { ProfileAdvancedView } from 'domains/Profile/components'
import { doc } from 'firebase/firestore'
import firestore from 'services/firebase/firestore'
import { generatePath } from 'react-router-dom'
import { useDeleteUser } from 'domains/User/hooks'
import { useDocument } from 'services/api/firebase'
import { useDocumentDataOnce } from 'react-firebase-hooks/firestore'
import { useTranslations } from 'contexts/Translation'
import { useUser } from 'domains/User/context'

const UserShowPage = (props) => {
  /* Getting translations instance */
  const { t } = useTranslations()
  /* Getting history instance */
  const history = useHistory()
  /* Getting params from router */
  const params = useParams()
  /* Fetching document data from useDocument hook */
  const [user, loading] = useDocument({
    ref: COLLECTIONS.USERS + '/' + params.userId
  })
  const { user: currentUser, loading: currentUserLoading } = useUser()
  /* Getting customer data from context */
  const [customer, customerLoading] = useDocumentDataOnce(
    doc(firestore, COLLECTIONS.CUSTOMERS + '/' + user?._context?.customerId)
  )
  /* Extracting delete modal confirm functions */
  const deleteProfile = useDeleteUser()

  /* Configuring page header */
  const headingProps = {
    marginBottom: 3
  }

  /* Getting id from params */
  const { userId } = params

  /* Configuring page actions */
  const actions = { edit: true }

  /* Function to handle back click */
  const handleBack = () => history.goBack()
  /* Handling opening of the edit page */
  const handleEdit = () => {
    const pathParams = { userId }
    const path = generatePath(PATHS.AUTHENTICATED.USER_EDIT, pathParams)
    history.push(path)
  }

  return (
    <PageWrapper
      action={
        <Box display="flex" alignItems="center">
          {currentUser?._id === user?._id && (
            <Box mr={1}>
              <AcceptOrDeclineTermsAndConditionsPopconfirm />
            </Box>
          )}
          <PageActions
            actions={actions}
            editText={t('Edit Profile')}
            onEdit={handleEdit}
          />
          {currentUser?._id === user?._id && (
            <Box ml={1}>
              <ButtonAccent
                type="secondary"
                onClick={() => deleteProfile(currentUser?._context?.customerId)}
                danger>
                {t('Delete Profile')}
              </ButtonAccent>
            </Box>
          )}
        </Box>
      }
      onBack={handleBack}
      breadcrumbs={<HeaderBreadcrumbs />}
      headingProps={headingProps}>
      <Row noGutters>
        {loading || currentUserLoading || customerLoading ? (
          <Col>
            <Spinner height="70vh" text={t('User loading')} />
          </Col>
        ) : (
          <Col cw={12}>
            <ProfileAdvancedView user={user} customer={customer} />
          </Col>
        )}
      </Row>
    </PageWrapper>
  )
}

export default UserShowPage
