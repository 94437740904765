import { useEffect } from 'react'
import { Form } from 'antd'

const useInitializeAddressAdvancedForm = (initialData) => {
  /* Getting form instance */
  const [form] = Form.useForm()

  /* Setting initial data */
  useEffect(() => {
    if (initialData) {
      /* Getting initial values */
      const { address } = initialData

      /* Formatting date fields */
      const formValues = {
        address: {
          city: address?.['city'] ?? null,
          country: address?.['country'] ?? null,
          zipCode: address?.['zipCode'] ?? null,
          streetName: address?.['streetName'] ?? null,
          streetNumber: address?.['streetNumber'] ?? null
        }
      }

      /* Setting initial values to form instance */
      form.setFieldsValue(formValues)
    }
  }, [initialData, form])

  return { form }
}

export default useInitializeAddressAdvancedForm
