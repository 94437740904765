import { useMemo } from 'react'
import { useLoading } from 'hooks'
import { useDocument } from 'services/api/firebase'
import { COLLECTIONS } from '__constants__'

const useGetZoneInitialValues = (id) => {
  /* Fetching data using useDocument hook */
  const [zone, tableLoading] = useDocument({
    ref: id ? COLLECTIONS.ZONES + '/' + id : null
  })

  /* Merge all fetched data */
  const initialValues = useMemo(
    () => ({
      zone
    }),
    [zone]
  )

  /* Merge all loading states */
  const loadings = useMemo(
    () => (id ? [!zone, tableLoading] : []),
    [id, zone, tableLoading]
  )

  /* Getting loading state using merged loadings */
  const loading = useLoading(loadings)

  return [initialValues, loading]
}

export default useGetZoneInitialValues
