import { Box, Col, PageWrapper, Row } from '@qonsoll/react-design'
import {
  ButtonAccent,
  HeaderBreadcrumbs,
  PageActions,
  Spinner
} from 'components'
import {
  COCKTAIL_AVAILABILITIES_KEYS,
  COLLECTIONS,
  MENU_TYPES_KEYS
} from '__constants__'
import { collection, query, where } from 'firebase/firestore'
import {
  useCollectionData,
  useDocumentData
} from 'react-firebase-hooks/firestore'
import { useConfirmDiscardChanges, useLoading } from 'hooks'
import { useGetMenuItems, useMenuActions } from 'domains/Menu/hooks'
import { useHistory, useParams } from 'react-router-dom'

import { Icon } from '@qonsoll/icons'
import { MenuAdvancedView } from 'domains/Menu/components'
import { doc } from 'firebase/firestore'
import { firestore } from 'services/firebase'
import { useGetRestaurants } from 'domains/Restaurant/hooks'
import { useTranslations } from 'contexts/Translation'
import { MenuDragAndDropProvider } from 'domains/Menu/contexts'

const MenuShowPage = (props) => {
  /* Getting translations instance */
  const { t, language } = useTranslations()
  /* Getting history instance */
  const history = useHistory()
  /* Getting params from router */
  const { companyId, restaurantId, menuId } = useParams()
  /* Fetching document data from useDocument hook */
  const [menu, menuLoading] = useDocumentData(
    menuId && doc(firestore, COLLECTIONS.MENUS + '/' + menuId)
  )
  const [restaurants, restaurantsLoading] = useGetRestaurants()
  const [menuItems, menuItemsLoading] = useGetMenuItems(menuId)
  const [itemsAvailability, itemsAvailabilityLoading] = useCollectionData(
    menuId && menu?.menuType === MENU_TYPES_KEYS.DRINK_TYPE
      ? query(
          collection(
            firestore,
            `${COLLECTIONS.MENUS}/${menuId}/${COLLECTIONS.COCKTAILS}`
          ),
          where('availability', '!=', COCKTAIL_AVAILABILITIES_KEYS.NO)
        )
      : query(
          collection(
            firestore,
            `${COLLECTIONS.MENUS}/${menuId}/${COLLECTIONS.DISHES}`
          ),
          where('visibility', '==', true)
        )
  )

  const { handleCancel, loading: menuDeletionLoading } =
    useConfirmDiscardChanges({
      message: `${t('Are you sure you want to delete this menu')}? ${t(
        'This will also delete all items that are in this menu'
      )}`
    })

  const loading = useLoading([
    menuLoading,
    menuItemsLoading,
    menuDeletionLoading,
    restaurantsLoading,
    itemsAvailabilityLoading
  ])

  /* Configuring page header */
  const headingProps = {
    title: menu?.name?.[language] ?? menu?.name?.en ?? t('Menu show'),
    marginBottom: 3,
    textAlign: 'left'
  }

  /* Configuring page actions */
  const actions = { edit: true }
  /* Function to handle back click */
  const handleBack = () => history.goBack()
  /* Handling opening of the edit page */
  const { handleDelete, handleEdit } = useMenuActions({
    restaurants,
    companyId,
    restaurantId,
    menuId
  })

  return (
    <PageWrapper
      action={
        <Box display="flex" alignItems="center">
          <PageActions actions={actions} onEdit={handleEdit} />
          <ButtonAccent
            ml={2}
            type="secondary"
            danger
            icon={<Icon name="Trash4Outlined" />}
            onClick={() => handleCancel(handleDelete)}
          />
        </Box>
      }
      onBack={handleBack}
      headingProps={headingProps}
      breadcrumbs={<HeaderBreadcrumbs />}>
      <Row noGutters>
        {loading ? (
          <Col>
            <Spinner height="70vh" text={t('Menu loading')} />
          </Col>
        ) : (
          <Col cw={12}>
            <MenuDragAndDropProvider>
              <MenuAdvancedView
                numberOfAvailableItems={itemsAvailability?.length}
                menu={menu}
                menuItems={menuItems}
              />
            </MenuDragAndDropProvider>
          </Col>
        )}
      </Row>
    </PageWrapper>
  )
}

export default MenuShowPage
