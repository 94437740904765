const TYPE_OF_PLACE = {
  BAR: 'Bar',
  RESTAURANT: 'Restaurant'
}

const TYPE_OF_PLACE_KEYS = {
  BAR: 'BAR',
  RESTAURANT: 'RESTAURANT'
}

const TYPE_OF_PLACE_OPTIONS = Object.keys(TYPE_OF_PLACE).map((key) => ({
  label: TYPE_OF_PLACE[key],
  value: key
}))

export { TYPE_OF_PLACE, TYPE_OF_PLACE_KEYS, TYPE_OF_PLACE_OPTIONS }
