const ADMIN_TYPES = {
  INVITED: 'Invited',
  ACTIVE: 'Active'
}

const ADMIN_TYPES_KEYS = {
  INVITED: 'INVITED',
  ACTIVE: 'ACTIVE'
}

export { ADMIN_TYPES, ADMIN_TYPES_KEYS }
