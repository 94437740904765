import { Box, Img, PageWrapper, Text } from '@qonsoll/react-design'
import { generatePath, useHistory } from 'react-router-dom'

import { ButtonAccent } from 'components'
import IllustrationConfirmEmail from 'assets/ConfirmEmail.svg'
import PATHS from 'pages/paths'
import firebase from 'firebase/compat/app'
import { message } from 'antd'
import { useAuthState } from 'react-firebase-hooks/auth'
import { useEffect } from 'react'
import { useHandleError } from 'hooks'
import { useSessionActions } from 'domains/Session/hooks'
import { useTranslations } from 'contexts/Translation'
import { useUser } from 'domains/User/context'

const ConfirmEmail = () => {
  const { t } = useTranslations()
  const history = useHistory()
  const onError = useHandleError()
  const { sendEmailVerification } = useSessionActions()
  const [user] = useAuthState(firebase.auth())

  const { user: userData } = useUser()

  useEffect(() => {
    const interval =
      user &&
      setInterval(() => {
        user.reload()
        if (user?.emailVerified) {
          message.success(t('Email has been verified'))
          history.push(
            userData?._id &&
              generatePath(PATHS.CONFIG.AFTER_SIGNUP, {
                profileId: userData?._id
              })
          )
        }
      }, 1000)

    return () => clearInterval(interval)
  }, [history, user, t, userData])

  return (
    <PageWrapper
      alignMiddle
      height="100%"
      headingProps={{
        title: t('Confirm your email'),
        titleVariant: ['h3', 'h1'],
        titleMarginBottom: [2, 3],
        subTitle: t(
          'confirmEmailSubtitle',
          `We've sent you email. Please, check your mailbox and follow instructions inside.`
        ),
        textAlign: 'center',
        marginBottom: [3, 24]
      }}
      contentWidth={['100%', '100%', 480]}>
      <Box display="flex" justifyContent="center">
        <Text code mb={[3, 40]}>
          {user?.email}
        </Text>
      </Box>
      <Img
        src={IllustrationConfirmEmail}
        alt="Confirm email"
        width={['100%', 400, 480]}
        mb={4}
      />
      <Box display="flex" justifyContent="center">
        <Text textAlign="center" mb={[12, 3]}>
          {t('dontReceiveEmail', `Don't receive the email?`)}
        </Text>
      </Box>
      <ButtonAccent
        mb={2}
        block
        onClick={() => {
          sendEmailVerification(onError)
        }}>
        {t('Resend email')}
      </ButtonAccent>
    </PageWrapper>
  )
}

export default ConfirmEmail
