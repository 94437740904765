import { useMemo } from 'react'
import { useLoading } from 'hooks'
import { useDocument } from 'services/api/firebase'
import { COLLECTIONS } from '__constants__'
import {} from 'domains/DecorationType/hooks'

const useGetDecorationTypeInitialValues = (id) => {
  /* Fetching data using useDocument hook */
  const [decorationType, decorationTypeLoading] = useDocument({
    ref: id ? COLLECTIONS.DECORATION_TYPES + '/' + id : null
  })

  /* Merge all fetched data */
  const initialValues = useMemo(
    () => ({
      decorationType
    }),
    [decorationType]
  )

  /* Merge all loading states */
  const loadings = useMemo(
    () => (id ? [!decorationType, decorationTypeLoading] : []),
    [id, decorationType, decorationTypeLoading]
  )

  /* Getting loading state using merged loadings */
  const loading = useLoading(loadings)

  return [initialValues, loading]
}

export default useGetDecorationTypeInitialValues
