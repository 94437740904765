import { Col, Row, Text } from '@qonsoll/react-design'

import { ButtonAccent } from 'components'
import { Icon } from '@qonsoll/icons'
import PropTypes from 'prop-types'
import { RecipeSimpleForm } from 'domains/Recipe/components'
import useRecipeBaseActions from 'domains/Recipe/hooks/actions/useRecipeBaseActions'
import { useTranslations } from 'contexts/Translation'

const RecipeListWithCreate = (props) => {
  const { value, onChange, emptyText } = props
  const { t } = useTranslations()

  const {
    addNewFieldToRecipe,
    removeFieldOfRecipe,
    onChangeState,
    isExistRecipe
  } = useRecipeBaseActions(value, onChange)

  const helperMarginWrapper = (index) => value?.length - 1 !== index

  return (
    <>
      {isExistRecipe ? (
        value?.map((exampleFutureRecipe, index) => (
          <RecipeSimpleForm
            onChange={onChangeState}
            onRemove={removeFieldOfRecipe}
            key={`exampleFutureRecipe-${exampleFutureRecipe?._id ?? index}`}
            value={exampleFutureRecipe}
            index={index}
            mbWrapper={helperMarginWrapper(index)}
          />
        ))
      ) : (
        <Row negativeBlockMargin v="center" h="center">
          <Col cw={12}>
            <Text variant="overline" color="var(--ql-state-disabled-color)">
              {emptyText ?? t('No recipes have been created yet')}
            </Text>
          </Col>
        </Row>
      )}
      <Row negativeBlockMargin v="center" h="center">
        <Col cw={12} h="right" mt={isExistRecipe ? 3 : -4}>
          <ButtonAccent
            type="secondary"
            onClick={addNewFieldToRecipe}
            icon={<Icon name="Plus1Outlined" pr={1} />}>
            {t('Add')}
          </ButtonAccent>
        </Col>
      </Row>
    </>
  )
}
RecipeListWithCreate.propTypes = {
  value: PropTypes.array,
  onChange: PropTypes.func,
  emptyText: PropTypes.string
}
export default RecipeListWithCreate
