import { Collapse } from 'antd'
import { OrderedItemList } from 'domains/OrderedItem/components'
import PropTypes from 'prop-types'
import { Text } from '@qonsoll/react-design'
import { useMemo } from 'react'
import { useOrderedItemBaseActions } from 'domains/OrderedItem/hooks'
import { useTranslations } from 'contexts/Translation'

const { Panel } = Collapse

export default function OrderOrderedItems(props) {
  const { order, currency = 'NOK', actions = {} } = props

  /* Getting translations instance */
  const { t } = useTranslations()
  /* Getting OrderedItem actions */
  const { createPath } = useOrderedItemBaseActions()

  const panelHeader = (index) =>
    t('Order') + ` ${orderDataArray?.length - index}`

  const orderDataArray = useMemo(
    () =>
      Array?.isArray(order?.orderData)
        ? order?.orderData?.map((item) => Object?.values(item))?.reverse()
        : null,
    [order]
  )

  return orderDataArray?.length ? (
    <Collapse>
      {orderDataArray?.map((orderData, index) => (
        <Panel header={panelHeader(index)} key={index}>
          <OrderedItemList
            cw={[12, 6, 4]}
            isListWithCreate={!!createPath}
            order={order}
            listView
            orderedItems={orderData}
            currency={currency}
            actions={actions}
          />
        </Panel>
      ))}
    </Collapse>
  ) : (
    <Text variant="overline" color="var(--ql-state-disabled-color)">
      {t('No orders have been created yet')}
    </Text>
  )
}

OrderOrderedItems.propTypes = {
  order: PropTypes.object.isRequired,
  actions: PropTypes.object,
  currency: PropTypes.string
}
