import * as yup from 'yup'

import { attr, hasMany, hasManyWithCreate, hasOne, model } from 'services/model'

const validationSchema = yup.object().shape({
  menuCategories: yup
    .array()
    .typeError('Field menuCategories has wrong type')
    .default(null)
    .nullable(),
  menuType: yup
    .string()
    .typeError('Field menuType has wrong type')
    .default(null)
    .nullable(),

  mediaObjects: yup
    .array()
    .typeError('Field mediaObjects has wrong type')
    .default(null)
    .nullable(),
  cocktails: yup
    .array()
    .typeError('Field cocktails has wrong type')
    .default(null)
    .nullable()
})

const Menu = model(
  'menu',
  {
    menuCategories: hasMany('menuCategory'),
    menuType: hasOne('menuType'),
    description: attr('string'),
    mediaObjects: hasManyWithCreate('mediaObject'),
    cocktails: hasMany('cocktail')
  },
  validationSchema
)

export default Menu
