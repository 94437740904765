import { Modal, message } from 'antd'

import { COLLECTIONS } from '__constants__'
import { ExclamationCircleOutlined } from '@ant-design/icons'
import PATHS from 'pages/paths'
import { deleteDocument } from 'services/api/firebase'
import firebase from 'firebase/compat/app'
import { useCallback } from 'react'
import { useHistory } from 'react-router-dom'
import { useTranslations } from 'contexts/Translation'

const useDeleteUser = () => {
  const { t } = useTranslations()
  const history = useHistory()

  return useCallback(
    (userId) => {
      const successCallback = async () => {
        try {
          await firebase.auth().signOut()
          message.success(t('Successfully deleted Profile'))
          history.push(PATHS.CONFIG.AFTER_LOGOUT)
          await deleteDocument(COLLECTIONS.USERS, userId)
        } catch (err) {
          //eslint-disable-next-line
          console.error('Error:', err)
        }
      }

      const deleteUserRequest = async (successCallback) => {
        try {
          const request = await fetch(
            `${process.env.REACT_APP_FIREBASE_FUNCTIONS_URL}/users/delete-admin/${userId}`,
            {
              method: 'DELETE'
            }
          )
          if (request.ok) await successCallback?.()
        } catch (err) {
          // eslint-disable-next-line
          console.error('Error during profile delete:', err)
          message.error(
            t(
              'Error occurred during profile delete. Please contact administrator for help.'
            )
          )
        }
      }

      Modal.confirm({
        title: t('Confirm Delete'),
        centered: true,
        icon: <ExclamationCircleOutlined />,
        content: `${t('Are you sure you want to delete your Profile')}? ${t(
          'The Profile cannot be restored'
        )}`,
        okText: t('Delete'),
        // okButtonProps: { loading },
        onOk: async () => deleteUserRequest(successCallback),
        cancelText: t('Cancel')
      })
    },
    [t, history]
  )
}

export default useDeleteUser
