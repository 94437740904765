const TABLE_AVAILABILITIES = {
  ACTIVE: 'Active',
  INACTIVE: 'Inactive'
}

const TABLE_AVAILABILITIES_KEYS = {
  ACTIVE: 'ACTIVE',
  INACTIVE: 'INACTIVE'
}

const TABLE_AVAILABILITIES_OPTIONS = Object.keys(TABLE_AVAILABILITIES).map(
  (key) => ({ label: TABLE_AVAILABILITIES[key], value: key })
)

export {
  TABLE_AVAILABILITIES_OPTIONS,
  TABLE_AVAILABILITIES,
  TABLE_AVAILABILITIES_KEYS
}
