import { COLLECTIONS } from '__constants__'
import { collection, doc, query, where } from 'firebase/firestore'
import { useLoading } from 'hooks'
import { useMemo } from 'react'
import {
  useCollectionData,
  useDocumentData
} from 'react-firebase-hooks/firestore'
import { firestore } from 'services/firebase'

const useGetItemTypeViewData = (itemTypeId) => {
  const [itemType, itemTypeLoading] = useDocumentData(
    itemTypeId && doc(firestore, COLLECTIONS.ITEM_TYPES + '/' + itemTypeId)
  )

  const [menuCategoriesFromCurrentItemType, menuCategoriesLoading] =
    useCollectionData(
      itemTypeId &&
        query(
          collection(firestore, COLLECTIONS.MENU_CATEGORIES),
          where('itemTypeId', '==', itemTypeId)
        )
    )

  const [interactiveMenusFromCurrentItemType, interactiveMenusLoading] =
    useCollectionData(
      itemTypeId &&
        query(
          collection(firestore, COLLECTIONS.INTERACTIVE_MENUS),
          where('itemType', '==', itemTypeId)
        )
    )

  const existingItemTypesReferences = useMemo(
    () => ({
      menuCategories: menuCategoriesFromCurrentItemType,
      interactiveMenus: interactiveMenusFromCurrentItemType
    }),
    [interactiveMenusFromCurrentItemType, menuCategoriesFromCurrentItemType]
  )

  const loading = useLoading([
    itemTypeLoading,
    menuCategoriesLoading,
    interactiveMenusLoading
  ])

  return [itemType, loading, existingItemTypesReferences]
}

export default useGetItemTypeViewData
