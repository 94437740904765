import {
  CURRENT_APP,
  DEFAULT_LANGUAGE,
  LANGUAGES,
  TRANSLATION_LOADING_CONFIG
} from 'domains/Translation/__constants__'
import Provider, { TranslationContext } from '@qonsoll/translation'

import { Box } from '@qonsoll/react-design'
import PropTypes from 'prop-types'
import { Spinner } from 'components'
import firebase from 'firebase/compat/app'
import { useMemo } from 'react'
import { ref, update, onValue } from 'firebase/database'
import { database } from 'services/firebase'

const TranslationsProvider = (props) => {
  const { children } = props

  // [COMPUTED PROPERTIES]
  const db = useMemo(() => firebase.database(), [])
  const loadingText = useMemo(
    () => TRANSLATION_LOADING_CONFIG?.[localStorage.getItem('language')],
    []
  )

  const handleRead = ({ ref: path, setTranslations, options }) => {
    // try/catch block is over handleRead function
    onValue(
      ref(database, path),
      (snapshot) => setTranslations(snapshot?.val() || {}),
      options
    )
  }

  // Function is used to save translation or errors
  const handleWrite = async ({ ref: reference, value }) => {
    // try/catch block is over handleWrite function
    await update(ref(database, reference), value)
  }

  return (
    <Provider
      languages={LANGUAGES}
      defaultLanguage={DEFAULT_LANGUAGE}
      currentApp={CURRENT_APP}
      db={db}
      onRead={handleRead}
      onWrite={handleWrite}>
      <TranslationContext.Consumer>
        {({ loaded }) => (
          <Box width="inherit" height="inherit">
            {!loaded ? (
              <Box
                position="fixed"
                bg="var(--ql-layout-background)"
                width="100vw"
                height="100vh"
                zIndex={1000}>
                <Spinner text={loadingText} />
              </Box>
            ) : (
              children
            )}
          </Box>
        )}
      </TranslationContext.Consumer>
    </Provider>
  )
}

TranslationsProvider.propTypes = {
  children: PropTypes.any
}

export default TranslationsProvider
