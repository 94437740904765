import {
  BILLING_TYPES_OPTIONS,
  KEYS,
  MAX_LENGTH_DESCRIPTION,
  MAX_LENGTH_FIELD,
  TYPE_LICENSE_OPTIONS,
  TYPE_OF_PLACE_OPTIONS
} from '__constants__'
import { ButtonAccent, EnumSelect, ImageUploader } from 'components'
import { Form, Input } from 'antd'

import PropTypes from 'prop-types'
import RestaurantCurrencySelect from 'domains/Restaurant/components/RestaurantSimpleForm/components/RestaurantCurrencySelect'
import { Title } from '@qonsoll/react-design'
import { useCustomerContext } from 'contexts/CustomerContext'
import { useFetchCountries } from 'hooks'
import { useTranslations } from 'contexts/Translation'
import { useValidateInput } from 'hooks'

const RestaurantSimpleForm = (props) => {
  const { initialValues, onSubmit, onCancel, showTitle, form, group } = props

  /* Fetch all countries */
  const { listCurrencies } = useFetchCountries()

  const { customer } = useCustomerContext()
  const isPossibleSelectLicense = customer?.typeOfLicense !== 'Corporation'
  /* Getting translations instance */
  const { t } = useTranslations()
  /* Getting new form instance from Form.useForm hook */
  const [baseForm] = Form.useForm()
  /* A custom hook that is used to validate input fields. */
  const validateInput = useValidateInput()
  /* Getting used form from props or using base form */
  const usedForm = form || baseForm

  /* Resetting form on cancel */
  const handleCancel = () => {
    usedForm.resetFields()
    onCancel?.()
  }
  /* Submitting form on enter key press */
  const handleKeyPress = (e) => {
    if (e.key === KEYS.ENTER) {
      e.preventDefault()
      e.stopPropagation()
      usedForm?.submit()
    }
  }

  const customerCurrency = customer?.currency
  return group ? (
    <>
      {showTitle && (
        <Title level={4} mb={2}>
          {t('Restaurant')}
        </Title>
      )}
      <Form.Item
        label={t('Logo')}
        name={[...group, 'logoUrl']}
        rules={[
          {
            required: false,
            message: t('Please enter your venue logo!')
          }
        ]}>
        <ImageUploader placeholder={t('Please enter your venue logo')} />
      </Form.Item>
      <Form.Item
        label={t('Name')}
        name={[...group, 'name']}
        rules={[
          {
            required: true,
            message: t('Please enter your venue name!')
          },
          { max: MAX_LENGTH_FIELD, message: t('venue name is too long!') },
          { validator: validateInput }
        ]}>
        <Input placeholder={t('Please enter your venue name')} />
      </Form.Item>
      <Form.Item
        label={t('Description')}
        name={[...group, 'description']}
        rules={[
          {
            max: MAX_LENGTH_DESCRIPTION,
            message: t('venue description is too long!')
          }
        ]}>
        <Input.TextArea
          placeholder={t('Please enter your venue description')}
        />
      </Form.Item>
      {/* Now Ann did`nt know exactly how it should works  */}
      {/* <Form.Item
        label={t('Welcome message')}
        name={[...group, 'welcomeMessage']}
        rules={[
          {
            required: false,
            message: t('Please enter your restaurant welcome message!')
          },
          {
            max: MAX_LENGTH_FIELD,
            message: t('restaurant welcome message is too long!')
          }
        ]}>
        <Input
          placeholder={t('Please enter your restaurant welcome message')}
        />
      </Form.Item> */}

      {isPossibleSelectLicense ? (
        <Form.Item
          display="none"
          label={t('Type of license')}
          name={[...group, 'typeOfLicense']}>
          <EnumSelect
            defaultValue={TYPE_LICENSE_OPTIONS?.at(1)?.value}
            options={TYPE_LICENSE_OPTIONS}
            ariaLabelledby="Type of license"
          />
        </Form.Item>
      ) : null}

      <Form.Item label={t('Billing type')} name={[...group, 'billingType']}>
        <EnumSelect
          defaultValue={BILLING_TYPES_OPTIONS?.at(1)?.value}
          options={BILLING_TYPES_OPTIONS}
          ariaLabelledby="Type of license"
        />
      </Form.Item>
      <Form.Item label={t('Venue type')} name={[...group, 'typeOfPlace']}>
        <EnumSelect
          defaultValue={TYPE_OF_PLACE_OPTIONS?.at(0)?.value}
          options={TYPE_OF_PLACE_OPTIONS}
          ariaLabelledby="Venue type"
        />
      </Form.Item>
      <Form.Item
        label={t('Currency')}
        name={[...group, 'currency']}
        rules={[
          {
            required: true,
            message: t('Please select your venue currency!')
          }
        ]}>
        <RestaurantCurrencySelect
          customerCurrency={customerCurrency}
          listCurrencies={listCurrencies}
        />
      </Form.Item>
    </>
  ) : (
    <Form
      scrollToFirstError
      form={usedForm}
      initialValues={initialValues}
      onFinish={onSubmit}
      layout="vertical"
      onKeyPress={handleKeyPress}
      autoComplete="off">
      {showTitle && (
        <Title level={4} mb={2}>
          {t('Restaurant')}
        </Title>
      )}
      <Form.Item
        label={t('Logo')}
        name="logoUrl"
        rules={[
          {
            required: false,
            message: t('Please enter your restaurant logo!')
          }
        ]}>
        <ImageUploader placeholder={t('Please enter your restaurant logo')} />
      </Form.Item>
      <Form.Item
        label={t('Name')}
        name="name"
        rules={[
          {
            required: true,
            message: t('Please enter your restaurant name!')
          },
          { max: MAX_LENGTH_FIELD, message: t('restaurant name is too long!') },
          { validator: validateInput }
        ]}>
        <Input placeholder={t('Please enter your restaurant name')} />
      </Form.Item>
      <Form.Item
        label={t('Description')}
        name="description"
        rules={[
          {
            max: MAX_LENGTH_DESCRIPTION,
            message: t('restaurant description is too long!')
          }
        ]}>
        <Input.TextArea
          placeholder={t('Please enter your restaurant description')}
        />
      </Form.Item>
      <Form.Item
        label={t('Currency')}
        name={[...group, 'currency']}
        rules={[
          {
            required: true,
            message: t('Please select your restaurant currency!')
          }
        ]}>
        <RestaurantCurrencySelect
          customerCurrency={customerCurrency}
          listCurrencies={listCurrencies}
        />
      </Form.Item>
      {/* Now Ann did`nt know exactly how it should works  */}
      {/* <Form.Item
        label={t('Welcome message')}
        name="welcomeMessage"
        rules={[
          {
            required: false,
            message: t('Please enter your restaurant welcome message!')
          },
          {
            max: MAX_LENGTH_FIELD,
            message: t('restaurant welcome message is too long!')
          }
        ]}>
        <Input
          placeholder={t('Please enter your restaurant welcome message')}
        />
      </Form.Item> */}
      {!form && (
        <>
          <ButtonAccent
            mr={2}
            htmlType="button"
            onClick={handleCancel}
            type="secondary">
            {t('Cancel')}
          </ButtonAccent>
          <ButtonAccent type="primary" onClick={() => usedForm.submit()}>
            {t('Save')}
          </ButtonAccent>
        </>
      )}
    </Form>
  )
}

RestaurantSimpleForm.propTypes = {
  initialValues: PropTypes.object,
  onSubmit: PropTypes.func,
  onCancel: PropTypes.func,
  showTitle: PropTypes.bool,
  form: PropTypes.any,

  group: PropTypes.array
}

export default RestaurantSimpleForm
