import { useTranslations } from 'contexts/Translation'

const useValidateInput = () => {
  const { t } = useTranslations()

  /**
   * It returns a Promise that rejects if the input is empty or contains only spaces, and resolves
   * otherwise
   * @param rule - The rule object that is being validated.
   * @param value - The value of the input.
   * @returns A function that takes in two parameters, rule and value.
   */
  const validateInput = (rule, value) => {
    if (value && value?.trim()?.length === 0) {
      return Promise.reject(
        t('This field cannot be empty or contain only spaces!')
      )
    }
    return Promise.resolve()
  }

  return validateInput
}

export default useValidateInput
