import { Box, Text } from '@qonsoll/react-design'
import { Icon } from '@qonsoll/icons'
import { useTranslations } from '@qonsoll/translation'
import { Tag } from 'antd'
import _ from 'lodash'

import { LIST_FIELD_DATA_TYPES } from '__constants__'
import { StyledImg } from 'components'
import { handleLanguage } from 'helpers'

import { ObjectInstanceSimpleView } from 'domains/ObjectInstance/components'
import { useCallback, useMemo } from 'react'

const useShowDataTypes = (props) => {
  const { t, language } = useTranslations()

  const label = useCallback(
    (data) => _.upperFirst(data?.label?.[language] || data?.label?.en),
    [language]
  )
  const nameLanguage = useCallback(
    (data) => {
      const text = data?.value?.[language] || data?.value?.en || data?.value
      return text?.length ? text : 'n/a'
    },
    [language]
  )

  const fieldComponents = useMemo(
    () => ({
      [LIST_FIELD_DATA_TYPES.IMAGE]: (data) => (
        <StyledImg width="140px" src={data?.value} alt={data?.label?.en} />
      ),
      [LIST_FIELD_DATA_TYPES.BOOLEAN]: (data) => (
        <Box display="flex" alignItems="center">
          <Text type="secondary">{label(data)}</Text>:
          <Box display="flex" ml={2} alignItems="center">
            <Text>{data?.value === false ? t('No') : t('Yes')}</Text>
            <Icon
              name={data?.value === false ? 'CloseFilled' : 'CheckmarkFilled'}
            />
          </Box>
        </Box>
      ),
      [LIST_FIELD_DATA_TYPES.STRING]: (data) => (
        <>
          <Text type="secondary">{label(data)}</Text>:
          <Text ml={2}>{nameLanguage(data)}</Text>
        </>
      ),
      [LIST_FIELD_DATA_TYPES.NUMBER]: (data) => (
        <>
          <Text type="secondary">{label(data)}</Text>:
          <Text ml={2}>{data?.value}</Text>
        </>
      ),
      [LIST_FIELD_DATA_TYPES.HAS_ONE]: (data) =>
        data?.isObject ? (
          <>
            <Text type="secondary">{label(data)}</Text>:
            <ObjectInstanceSimpleView objectInstanceData={data?.value?.[0]} />
          </>
        ) : (
          <>
            <Text type="secondary">{label(data)}</Text>:{' '}
            <Tag>{nameLanguage(data)}</Tag>
          </>
        ),
      [LIST_FIELD_DATA_TYPES.HAS_MANY]: (data) => {
        const addingSpace = (index) => (data?.value?.length === index ? 0 : 2)
        return data?.isObject ? (
          <>
            <Text type="secondary">{label(data)}</Text>:
            {data?.value?.map((item, index) => (
              <Box
                key={`${index}-object-instance-item`}
                mb={addingSpace(index)}>
                <ObjectInstanceSimpleView
                  data={item}
                  index={index}
                  objectInstanceData={item}
                />
              </Box>
            ))}
          </>
        ) : (
          <>
            <Text type="secondary">{label(data)}</Text>:{' '}
            {data?.value?.map(
              (item, index) =>
                item && (
                  <Tag key={`hasMany-${index}`}>
                    {handleLanguage(item, language)}
                  </Tag>
                )
            )}
          </>
        )
      },
      Empty: (data) => <Text type="secondary">{label(data)}: n/a</Text>
    }),
    [label, language, nameLanguage, t]
  )

  const renderField = useCallback(
    (data) => {
      const dataOrRelationType = data?.dataType ?? data?.relationType
      return data?.value && dataOrRelationType
        ? fieldComponents[dataOrRelationType]?.(data)
        : fieldComponents.Empty(data)
    },
    [fieldComponents]
  )

  return useMemo(() => ({ renderField }), [renderField])
}

export default useShowDataTypes
