import { useMemo } from 'react'
import { useLoading } from 'hooks'
import { useDocument } from 'services/api/firebase'
import { COLLECTIONS } from '__constants__'
import {} from 'domains/OrderedItem/hooks'
import { useGetCocktailInitialValues } from 'domains/Cocktail/hooks'

const useGetOrderedItemInitialValues = (id) => {
  /* Fetching data using useDocument hook */
  const [orderedItem, orderedItemLoading] = useDocument({
    ref: id ? COLLECTIONS.ORDERED_ITEMS + '/' + id : null
  })

  /* Fetching cocktail data */
  const [item, cocktailLoading] = useGetCocktailInitialValues(
    orderedItem?.['item']
  )

  /* Merge all fetched data */
  const initialValues = useMemo(
    () => ({
      orderedItem,
      item
    }),
    [orderedItem, item]
  )

  /* Merge all loading states */
  const loadings = useMemo(
    () => (id ? [!orderedItem, orderedItemLoading, cocktailLoading] : []),
    [id, orderedItem, orderedItemLoading, cocktailLoading]
  )

  /* Getting loading state using merged loadings */
  const loading = useLoading(loadings)

  return [initialValues, loading]
}

export default useGetOrderedItemInitialValues
