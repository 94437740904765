import { Badge } from 'antd'
import PropTypes from 'prop-types'
import { Box, Text } from '@qonsoll/react-design'

/* The component displays the number of items within each tab, 
    providing a visual representation of the contents of each tab. */
const TabCount = (props) => {
  const { value, text } = props

  return value?.length ? (
    <Box display="flex" alignItems="center">
      <Text mr={2}>{text}</Text>
      <Badge count={value?.length} />
    </Box>
  ) : (
    text
  )
}
TabCount.propTypes = {
  value: PropTypes.object,
  text: PropTypes.string.isRequired
}
export default TabCount
