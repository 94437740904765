import {
  LAST_SESSION_PROVIDERS,
  LS,
  COLLECTIONS,
  USER_ROLES_KEYS
} from '__constants__'
import { collection, getDocs, query, where } from 'firebase/firestore'
import { firestore } from 'services/firebase'

import createUserWithEmailAndPassword from './createUserWithEmailAndPassword'
import saveUserToDB from './saveUserToDB'
import sendEmailVerification from './sendEmailVerification'

/**
 * `signup` creates a new user with the given credentials, and then sends an email verification.
 * @returns A promise that resolves to the user object.
 */
const signup = ({ credentials, onError, onInvitationAccept, gdpr }) => {
  return createUserWithEmailAndPassword(credentials, onError).then(
    async (auth) => {
      const invitationData = await onInvitationAccept?.(credentials?.email)
      const computedQuery = query(
        collection(firestore, COLLECTIONS.USERS),
        where('_companies', 'array-contains', invitationData?.customerId)
      )
      const querySnapshot = await getDocs(computedQuery)
      const users = querySnapshot?.docs?.map((item) => item.data())
      const userCompanies = {}
      if (users?.length) {
        userCompanies[invitationData?.customerId] =
          USER_ROLES_KEYS.COMPANY_ADMIN
      } else {
        userCompanies[invitationData?.customerId] =
          USER_ROLES_KEYS.COMPANY_OWNER
      }
      let userObject = {
        id: auth?.user?.uid,
        email: credentials.email,
        avatarUrl: null,
        agreement: true,
        gdpr,

        firstName: credentials?.firstName ?? null,
        lastName: credentials?.lastName ?? null,

        companyRoles: userCompanies
      }
      if (invitationData)
        userObject = {
          ...userObject,
          _companies: [invitationData?.customerId],
          currentCompanyId: invitationData?.customerId
        }
      await saveUserToDB(userObject)
      localStorage.setItem(
        LS.LAST_SESSION_PROVIDER,
        LAST_SESSION_PROVIDERS.EMAIL
      )

      return sendEmailVerification(onError)
    }
  )
}

export default signup
