import { useMemo } from 'react'
import { useLoading } from 'hooks'
import { useDocument } from 'services/api/firebase'
import { COLLECTIONS } from '__constants__'
import { useGetMenuCategoryCocktails } from 'domains/MenuCategory/hooks'

const useGetMenuCategoryInitialValues = (id) => {
  /* Fetching data using useDocument hook */
  const [menuCategory, menuCategoryLoading] = useDocument({
    ref: id ? COLLECTIONS.MENU_CATEGORIES + '/' + id : null
  })

  /* Fetching cocktail data */
  const [items, cocktailLoading] = useGetMenuCategoryCocktails(menuCategory)

  /* Merge all fetched data */
  const initialValues = useMemo(
    () => ({
      menuCategory,
      items
    }),
    [menuCategory, items]
  )

  /* Merge all loading states */
  const loadings = useMemo(
    () => (id ? [!menuCategory, menuCategoryLoading, cocktailLoading] : []),
    [id, menuCategory, menuCategoryLoading, cocktailLoading]
  )

  /* Getting loading state using merged loadings */
  const loading = useLoading(loadings)

  return [initialValues, loading]
}

export default useGetMenuCategoryInitialValues
