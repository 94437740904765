import { collection, orderBy, query } from 'firebase/firestore'

import { COLLECTIONS } from '__constants__'
import { Option } from '@qonsoll/react-design'
import PropTypes from 'prop-types'
import { SelectWithFixedDropdown } from 'components'
import { firestore } from 'services/firebase'
import { handleFilterOptions } from 'helpers'
import { useCollectionDataOnce } from 'react-firebase-hooks/firestore'
import { useTranslations } from 'contexts/Translation'

// Takes a value of type object and onChange function
// Returns selected option object
const MeasurementTypeSelect = (props) => {
  const { value, onChange, placeholder } = props

  /* Fetching measurements data */
  const [allMeasurementTypes = [], loading] = useCollectionDataOnce(
    query(
      collection(firestore, COLLECTIONS.MEASUREMENT_TYPES),
      orderBy('name', 'asc')
    )
  )

  /* Getting translation instance */
  const { t } = useTranslations()

  /* Handling change selected option */
  const handleChange = (value) => {
    onChange?.(value || null)
  }

  return (
    <SelectWithFixedDropdown
      showSearch
      allowClear
      value={value}
      loading={loading}
      onChange={handleChange}
      aria-label="Select measurement"
      filterOption={handleFilterOptions}
      aria-describedby="measurement-description"
      placeholder={placeholder ?? t('Please, select measurement')}>
      {allMeasurementTypes.map((measurementType) => (
        <Option key={measurementType?._id} value={measurementType?._id}>
          {measurementType?.name}
        </Option>
      ))}
    </SelectWithFixedDropdown>
  )
}

MeasurementTypeSelect.propTypes = {
  value: PropTypes.object,
  onChange: PropTypes.func,
  placeholder: PropTypes.string
}

export default MeasurementTypeSelect
