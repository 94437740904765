import { model, attr } from 'services/model'
import * as yup from 'yup'

const validationSchema = yup.object().shape({
  url: yup
    .string()
    .typeError('Field url has wrong type')
    .required('Field url is required'),
  size: yup
    .string()
    .typeError('Field size has wrong type')
    .default(null)
    .nullable(),
  description: yup
    .string()
    .typeError('Field description has wrong type')
    .required('Field description is required'),
  format: yup
    .string()
    .typeError('Field format has wrong type')
    .default(null)
    .nullable()
})

const MediaObject = model(
  'mediaObject',
  {
    url: attr('string'),
    size: attr('string'),
    description: attr('string'),
    format: attr('string')
  },
  validationSchema
)

export default MediaObject
