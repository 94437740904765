import { useMemo } from 'react'
import PropTypes from 'prop-types'
import { Select } from 'antd'
import { collection, query, where } from 'firebase/firestore'
import { useCollectionData } from 'react-firebase-hooks/firestore'
import { firestore } from 'services/firebase'

import { COLLECTIONS, OBJECT_RELATION_TYPES } from '__constants__'
import { useLoading } from 'hooks'

const EnumSelect = (props) => {
  const {
    item,
    value = [],
    onChange,
    selectedLanguage,
    allowClear = true
  } = props

  const dataType = item?.dataType || item?.relationType
  const enumId = item?.enum || item?.reference?.split('-')[1]

  const [enumInstances, enumInstancesLoading] = useCollectionData(
    query(
      collection(firestore, COLLECTIONS.ENUM_INSTANCES),
      where('enumId', '==', enumId)
    )
  )

  const loading = useLoading([enumInstancesLoading])
  const selectOptions = useMemo(() => {
    if (enumInstances) {
      return (
        enumInstances?.map((enumInstanceItem) => ({
          label:
            enumInstanceItem?.name?.[selectedLanguage] ??
            enumInstanceItem?.name?.en ??
            enumInstanceItem?.name,
          value: enumInstanceItem?._id
        })) ?? []
      )
    }
    return []
  }, [enumInstances, selectedLanguage])

  return (
    <Select
      allowClear={allowClear}
      value={!loading && (value ?? [])}
      onChange={onChange}
      loading={loading}
      options={selectOptions}
      mode={dataType === OBJECT_RELATION_TYPES.HAS_MANY && 'multiple'}
    />
  )
}
EnumSelect.propTypes = {
  item: PropTypes.object,
  value: PropTypes.any,
  onChange: PropTypes.func,
  allowClear: PropTypes.bool,
  selectedLanguage: PropTypes.string
}

export default EnumSelect
