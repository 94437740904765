import { Col, Row, Text } from '@qonsoll/react-design'

import { COLLECTIONS } from '__constants__'
import { DragableList } from 'components'
import { MenuCategoryItem } from 'domains/MenuCategory/components'
import PropTypes from 'prop-types'
import { updateDocument } from 'services/api/firebase'
import { useTranslations } from 'contexts/Translation'

const MenuCategoryList = (props) => {
  const { menuCategories, emptyProps, menu, onUpdate, tableProps } = props

  /* Getting translations instance */
  const { t } = useTranslations()

  /* Checking if list is empty */
  const isEmpty = !menuCategories?.length

  const onPriceChange = async (cocktailId, { price }) => {
    const copyMenuCategories = menuCategories?.map((item, index) =>
      item?.cocktailId === cocktailId
        ? { ...menu?.cocktailsAvailabilities?.[index], price: Number(price) }
        : item
    )
    await updateDocument(COLLECTIONS.MENUS, menu?._id, {
      ...menu,
      cocktailsAvailabilities: copyMenuCategories
    })
  }
  const onAvailabilityChange = async (cocktailId, { availability }) => {
    const copyMenuCategories = menuCategories?.map((item, index) =>
      item?.cocktailId === cocktailId
        ? { ...menu?.cocktailsAvailabilities?.[index], availability }
        : item
    )
    await updateDocument(COLLECTIONS.MENUS, menu?._id, {
      ...menu,
      cocktailsAvailabilities: copyMenuCategories
    })
  }

  return (
    <Row noGutters>
      <Col cw={12}>
        <DragableList
          onUpdate={onUpdate}
          listItems={menuCategories}
          renderItem={(othersItem, index) => (
            <MenuCategoryItem
              key={othersItem?._id}
              {...othersItem}
              onPriceChange={onPriceChange}
              onAvailabilityChange={onAvailabilityChange}
              tableProps={tableProps}
            />
          )}
        />
      </Col>
      {isEmpty && (
        <Col cw={12}>
          <Text variant="overline" color="var(--ql-state-disabled-color)">
            {emptyProps?.message ||
              t('No menu categories have been created yet')}
          </Text>
        </Col>
      )}
    </Row>
  )
}

MenuCategoryList.propTypes = {
  menuCategories: PropTypes.array,
  emptyProps: PropTypes.object,
  menu: PropTypes.object,
  onUpdate: PropTypes.func,
  tableProps: PropTypes.object
}

export default MenuCategoryList
