import { COLLECTIONS } from '__constants__'
import { useCallback, useEffect, useState } from 'react'
import { getDocument } from 'services/api/firebase'
import { message } from 'antd'
import { useTranslations } from 'contexts/Translation'

const useGetInteractiveMenu = (interactiveMenuId) => {
  const [initialData, setInitialData] = useState({})
  const [initialDataLoading, setInitialDataLoading] = useState(false)
  /* Getting translations instance */
  const { t } = useTranslations()

  const fetchData = useCallback(async () => {
    const getItemData = async (collection, id) => {
      const itemData = await getDocument(collection, id)
      if (!itemData) return null
      return itemData
    }

    const errorMessage = `${t('Something went wrong during fetching data')}:`

    try {
      setInitialDataLoading(true)
      // Fetching 'Interactive Menu'
      const interactiveMenuData = await getItemData(
        COLLECTIONS.INTERACTIVE_MENUS,
        interactiveMenuId
      )
      // Fetching 'Item type'
      const itemTypeData = await getItemData(
        COLLECTIONS.ITEM_TYPES,
        interactiveMenuData?.itemType
      )
      // Fetching 'Interactive Menu - Questions and their reference'
      const interactiveMenuQuestions = await Promise.all(
        interactiveMenuData?.questions?.map(async (questionId) => {
          const questionData = await getItemData(
            COLLECTIONS.INTERACTIVE_MENU_QUESTIONS,
            questionId
          )
          const optionSourceData = await getItemData(
            COLLECTIONS.ITEM_TYPES_FIELDS,
            questionData?.optionSource
          )
          return {
            ...questionData,
            optionSourceData: optionSourceData
          }
        })
      )
      // Save all data which were fetched
      setInitialData({
        interactiveMenuData,
        itemTypeData,
        interactiveMenuQuestions
      })
    } catch (error) {
      // Show error message
      message.error(`${errorMessage} ${error?.message}`)
    } finally {
      // Change state of Loading
      setInitialDataLoading(false)
    }
  }, [interactiveMenuId, t])

  useEffect(() => {
    interactiveMenuId && fetchData()
  }, [fetchData, interactiveMenuId, t])

  return [initialData, initialDataLoading]
}

export default useGetInteractiveMenu
