import { CardDropdown, SelectableCard } from 'components'

import PropTypes from 'prop-types'
import { useMenuItemBaseActions } from 'domains/MenuItem/hooks'
import { useTranslations } from 'contexts/Translation'

const MenuItemSimpleView = (props) => {
  const { menuItem, index } = props
  const { language } = useTranslations()
  const menuItemId = menuItem?._id
  const { onOpen } = useMenuItemBaseActions({ menuItemId })
  const menuItemName =
    menuItem?.name?.[language] ?? menuItem?.name?.en ?? menuItem?.name ?? ''
  return (
    <CardDropdown
      height="100%"
      index={index}
      document={menuItem}
      onClick={onOpen}
      data-testid="menuItem">
      <SelectableCard height="100%">{menuItemName}</SelectableCard>
    </CardDropdown>
  )
}
MenuItemSimpleView.propTypes = {
  menuItem: PropTypes.object,
  index: PropTypes.number
}

export default MenuItemSimpleView
