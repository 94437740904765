import {
  ZONE_AVAILABILITIES,
  ZONE_AVAILABILITIES_KEYS
} from '__constants__/enums'

import { useMemo } from 'react'

const useGetZoneTitleAndRibbonColor = (zoneAvailability) => {
  // This is needed to make old values expressed as YES/NO and new value as true/false to work
  const ribbonColor = useMemo(
    () =>
      zoneAvailability === ZONE_AVAILABILITIES_KEYS.NOT_AVAILABLE ||
      zoneAvailability === false
        ? 'var(--ribbon-disabled-bg)'
        : (zoneAvailability === ZONE_AVAILABILITIES_KEYS.AVAILABLE ||
            zoneAvailability === true) &&
          'var(--ribbon-active-bg)',
    [zoneAvailability]
  )
  // This is needed to make old values expressed as YES/NO and new value as true/false to work
  const zoneTitle = useMemo(
    () =>
      zoneAvailability === ZONE_AVAILABILITIES_KEYS.NOT_AVAILABLE ||
      zoneAvailability === false
        ? ZONE_AVAILABILITIES.NOT_AVAILABLE
        : (zoneAvailability === ZONE_AVAILABILITIES_KEYS.AVAILABLE ||
            zoneAvailability === true) &&
          ZONE_AVAILABILITIES.AVAILABLE,
    [zoneAvailability]
  )

  return { ribbonColor, zoneTitle }
}

export default useGetZoneTitleAndRibbonColor
