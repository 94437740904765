import { model, attr } from 'services/model'
import * as yup from 'yup'

const validationSchema = yup.object().shape({
  title: yup
    .string()
    .typeError('Field title has wrong type')
    .required('Field title is required')
})

const Table = model(
  'table',
  {
    title: attr('string')
  },
  validationSchema
)

export default Table
