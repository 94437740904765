import { Col, Row, Text } from '@qonsoll/react-design'

import { AddItemCard } from 'components'
import PropTypes from 'prop-types'
import { ZoneSimpleView } from 'domains/Zone/components'
import { useTranslations } from 'contexts/Translation'

const ZoneList = (props) => {
  const {
    zones,
    isListWithCreate,
    listView,
    emptyProps,
    actions,
    cw = [12, 6, 4, 3]
  } = props

  /* Getting translations instance */
  const { t } = useTranslations()

  /* Checking if list is empty */
  const isEmpty = !zones?.length
  /* Computing item width */
  const itemWidth = listView ? 12 : cw

  return (
    <Row negativeBlockMargin px={2}>
      {isListWithCreate && actions?.onCreate && (
        <Col mb={4} cw={itemWidth}>
          <AddItemCard
            message={t('Add zone')}
            onClick={actions.onCreate}
            minHeight="98px"
          />
        </Col>
      )}
      {!isEmpty &&
        zones.map((zone, index) => (
          <Col mb={4} key={zone?._id || index} cw={itemWidth}>
            <ZoneSimpleView actions={actions} index={index} zone={zone} />
          </Col>
        ))}
      {isEmpty && (
        <Col cw={12}>
          <Text variant="overline" color="var(--ql-state-disabled-color)">
            {emptyProps?.message || t('No zones have been created yet')}
          </Text>
        </Col>
      )}
    </Row>
  )
}

ZoneList.propTypes = {
  zones: PropTypes.array,
  isListWithCreate: PropTypes.bool,
  listView: PropTypes.bool,
  emptyProps: PropTypes.object,
  actions: PropTypes.any,
  cw: PropTypes.any
}

export default ZoneList
