import { useMemo } from 'react'
import { Icon } from '@qonsoll/icons'
import { Col, Container, Row, Text } from '@qonsoll/react-design'
import { useTranslations } from '@qonsoll/translation'
import PropTypes from 'prop-types'
import { reorder } from 'helpers'
import { Empty } from 'antd'
import { DragDropContext, Droppable } from 'react-beautiful-dnd'

import { ButtonAccent } from 'components'

import { generateNewQuestion } from 'domains/InteractiveMenuQuestion/helpers'
import { InteractiveMenuQuestionSimpleForm } from 'domains/InteractiveMenuQuestion/components'

const InteractiveMenuQuestionsForm = (props) => {
  const {
    value = {},
    onChange,
    itemTypesFields,
    itemTypesFieldsLoading,
    selectedItemType
  } = props

  const { t, language } = useTranslations()

  const valuesArray = Object.values(value || {})

  const onAddNewQuestion = () => {
    const newQuestion = generateNewQuestion()
    const order = valuesArray.length

    onChange({ ...value, [newQuestion.uid]: { ...newQuestion, order } })
  }

  const fieldsOptions = useMemo(() => {
    const options = itemTypesFields?.map(({ _id, label, name }) => ({
      value: _id,
      label: label?.[language] || label?.en || name
    }))

    const optionsSorted =
      options?.sort((a, b) => {
        const labelA = a?.label?.toLowerCase()
        const labelB = b?.label?.toLowerCase()

        if (labelA < labelB) {
          return -1
        }
        if (labelA > labelB) {
          return 1
        }
        return 0
      }) ?? []

    return optionsSorted
  }, [itemTypesFields, language])

  const onRemoveQuestion = (uid) => () => {
    const valueCopy = { ...value }

    delete valueCopy[uid]

    onChange(valueCopy)
  }

  const onDragEnd = (result) => {
    // dropped outside the list
    if (!result.destination) {
      return
    }

    const items = reorder(
      Object.values(value || {}),
      result.source.index,
      result.destination.index
    )
    const reorderedItems = items?.reduce((acc, item) => {
      // reorder field on create and on edit with its id-s
      acc[item?.uid || item?._id] = item
      return acc
    }, {})

    onChange?.(reorderedItems)
  }

  return (
    <DragDropContext onDragEnd={onDragEnd}>
      <Container>
        <Droppable droppableId="droppable">
          {(provided) => (
            <Row {...provided.droppableProps} ref={provided.innerRef} noGutters>
              <Col cw={12}>
                {valuesArray.length ? (
                  valuesArray.map((question, index) => (
                    <InteractiveMenuQuestionSimpleForm
                      key={question?.uid || index}
                      question={question}
                      index={index}
                      itemTypesFieldsLoading={itemTypesFieldsLoading}
                      fieldsOptions={fieldsOptions}
                      selectedItemType={selectedItemType}
                      onRemoveQuestion={onRemoveQuestion}
                    />
                  ))
                ) : (
                  <Empty
                    description={t('You have not added any questions yet')}
                  />
                )}
              </Col>
              {provided.placeholder}
            </Row>
          )}
        </Droppable>
        <Row mt={3} h="right" noGutters>
          <Col cw={[3, 4]}>
            <ButtonAccent
              type="secondary"
              onClick={onAddNewQuestion}
              icon={<Icon name="Plus1Outlined" mr={2} />}>
              <Text>{t('Add new question')}</Text>
            </ButtonAccent>
          </Col>
        </Row>
      </Container>
    </DragDropContext>
  )
}

InteractiveMenuQuestionsForm.propTypes = {
  value: PropTypes.arrayOf(PropTypes.object),
  onChange: PropTypes.func,
  itemTypesFields: PropTypes.arrayOf(PropTypes.object),
  itemTypesFieldsLoading: PropTypes.bool,
  selectedItemType: PropTypes.object
}

export default InteractiveMenuQuestionsForm
