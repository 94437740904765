import { generatePath, useHistory, useParams } from 'react-router-dom'

import { COLLECTIONS } from '__constants__'
import PATHS from 'pages/paths'
import { deleteDocument } from 'services/api/firebase'
import { message } from 'antd'
import { useEvent } from 'hooks'
import { useMemo } from 'react'
import { useTranslations } from 'contexts/Translation'

/* Path's */
const createPath = PATHS.AUTHENTICATED.MENU_CREATE
const editPath = PATHS.AUTHENTICATED.MENU_EDIT
const showPath = PATHS.AUTHENTICATED.MENU_SHOW
const createPathWithRestaurant = PATHS.AUTHENTICATED.RESTAURANT_MENU_CREATE
const showPathWithRestaurant = PATHS.AUTHENTICATED.RESTAURANT_MENU_SHOW
const createPathWithCompanyAndRestaurant =
  PATHS.AUTHENTICATED.COMPANY_RESTAURANT_MENU_CREATE
const showPathWithCompanyAndRestaurant =
  PATHS.AUTHENTICATED.COMPANY_RESTAURANT_MENU_SHOW

const useMenuBaseActions = ({ menuId, actions } = {}) => {
  /* Getting translations instance */
  const { t } = useTranslations()
  /* Getting history instance */
  const history = useHistory()
  const { restaurantId, companyId } = useParams()

  /* Function to handle back click */
  const handleBack = useEvent(() => history.goBack())

  /* Handling opening of the create page */
  const handleCreate = useEvent(() => {
    if (companyId && restaurantId) {
      history.push(
        generatePath(createPathWithCompanyAndRestaurant, {
          companyId,
          restaurantId
        })
      )
    } else if (restaurantId)
      history.push(generatePath(createPathWithRestaurant, { restaurantId }))
    else history.push(createPathWithCompanyAndRestaurant)
  })

  /* Handling opening of the show page */
  const handleOpen = useEvent(() => {
    if (companyId)
      history.push(
        generatePath(showPathWithCompanyAndRestaurant, {
          companyId,
          restaurantId,
          menuId: menuId
        })
      )
    else if (restaurantId)
      history.push(
        generatePath(showPathWithRestaurant, {
          restaurantId,
          menuId: menuId
        })
      )
    else history.push(generatePath(showPath, { menuId: menuId }))
  })

  /* Handling opening of the edit page or change view to edit mode */
  const handleEdit = useEvent(() => {
    if (restaurantId) {
      const pathParams = { restaurantId, menuId }
      const path = generatePath(
        PATHS.AUTHENTICATED.RESTAURANT_MENU_EDIT,
        pathParams
      )
      history.push(path)
    } else {
      const pathParams = { menuId }
      const path = generatePath(PATHS.AUTHENTICATED.MENU_EDIT, pathParams)
      history.push(path)
    }
  })

  const handleDelete = useEvent(async () => {
    try {
      await deleteDocument(COLLECTIONS.MENUS, menuId)
      message.success(t('Menu successfully deleted'))
    } catch (error) {
      message.error(error.message)
    }
  })

  return useMemo(
    () => ({
      onBack: handleBack,
      onCreate: handleCreate,
      onOpen: handleOpen,
      onEdit: handleEdit,
      onDelete: handleDelete,
      ...(actions || {}),
      createPath,
      editPath,
      showPath,
      createPathWithRestaurant,
      createPathWithCompanyAndRestaurant
    }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [
      actions?.onBack,
      actions?.onCreate,
      actions?.onDelete,
      actions?.onEdit,
      actions?.onOpen,
      handleBack,
      handleCreate,
      handleDelete,
      handleEdit,
      handleOpen
    ]
  )
}

export default useMenuBaseActions
