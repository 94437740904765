import { AddressEdit, AddressShow } from './Address'
import { AlcoholTasteTypeEdit, AlcoholTasteTypeShow } from './AlcoholTasteType'
import { AllergenEdit, AllergenShow } from './Allergen'
import {
  CustomerAdminInvite,
  CustomerCreate,
  CustomerEdit,
  CustomerShow,
  CustomerWizardPage
} from './Customer'
import { DecorationTypeEdit, DecorationTypeShow } from './DecorationType'
import { FlavorEdit, FlavorShow } from './Flavor'
import { GlassTypeEdit, GlassTypeShow } from './GlassType'
import {
  InteractiveMenuCreate,
  InteractiveMenusAll,
  InteractiveMenuShow,
  InteractiveMenuEdit
} from './InteractiveMenu'
import { MainSpiritTypeEdit, MainSpiritTypeShow } from './MainSpiritType'
import { MeasurementTypeEdit, MeasurementTypeShow } from './MeasurementType'
import {
  MediaObjectCreate,
  MediaObjectEdit,
  MediaObjectShow
} from './MediaObject'
import {
  MenuCategoriesAll,
  MenuCategoryCreate,
  MenuCategoryEdit,
  MenuCategoryShow
} from './MenuCategory'
import { MenuCreate, MenuEdit, MenuShow, MenusAll } from './Menu'
import {
  MenuTypeCreate,
  MenuTypeEdit,
  MenuTypeShow,
  MenuTypesAll
} from './MenuType'
import { MethodEdit, MethodShow } from './Method'
import { OrderEdit, OrderShow } from './Order'
import { OrderStatusEdit, OrderStatusShow } from './OrderStatus'
import { OrderedItemEdit, OrderedItemShow } from './OrderedItem'
import {
  RestaurantCreate,
  RestaurantEdit,
  RestaurantShow,
  RestaurantsAll
} from './Restaurant'
import { TableCreate, TableEdit, TableShow, TablesAll } from './Table'
import {
  TrainingMaterialCreate,
  TrainingMaterialShow,
  TrainingMaterialsAll
} from './TrainingMaterial'
import { UserCreate, UserEdit, UsersAll } from './User'
import { ZoneCreate, ZoneEdit, ZoneShow } from './Zone'
import {
  ItemTypeCreate,
  ItemTypeEdit,
  ItemTypeShow,
  ItemTypesAll
} from './ItemType'
import { ItemTypeInstanceCreate } from './ItemTypeInstance'
import { MenuItemEdit, MenuItemShow } from './MenuItem'

import Dashboard from './Dashboard/Dashboard'
import PATHS from '../paths'
import { ProfileEdit } from './Profile'
import { QRCodeCreate } from './QRCode'
import { Settings } from './Settings'
import { Statistics } from './Statistics'
import { TipAndTrickCreate } from './TipAndTrick'
import Translations from 'modules/admin-module/pages/Translations'
import { UserShow } from './User'

const {
  DASHBOARD,
  USER_SHOW,
  TRANSLATIONS,
  SETTINGS,
  STATISTICS,
  SETTINGS_MENU_TYPES_ALL,
  RESTAURANTS_ALL,
  RESTAURANT_SHOW,
  RESTAURANT_EDIT,
  RESTAURANT_CREATE,
  ADDRESS_SHOW,
  ADDRESS_EDIT,
  MEDIA_OBJECT_SHOW,
  MEDIA_OBJECT_EDIT,
  MEDIA_OBJECT_CREATE,
  MEASUREMENT_TYPE_SHOW,
  MEASUREMENT_TYPE_EDIT,
  ALLERGEN_SHOW,
  ALLERGEN_EDIT,
  METHOD_SHOW,
  METHOD_EDIT,
  ALCOHOL_TASTE_TYPE_SHOW,
  ALCOHOL_TASTE_TYPE_EDIT,
  MAIN_SPIRIT_TYPE_SHOW,
  MAIN_SPIRIT_TYPE_EDIT,
  FLAVOR_SHOW,
  FLAVOR_EDIT,
  GLASS_TYPE_SHOW,
  GLASS_TYPE_EDIT,
  DECORATION_TYPE_SHOW,
  DECORATION_TYPE_EDIT,

  ORDER_EDIT,
  ORDER_STATUS_SHOW,
  ORDER_STATUS_EDIT,
  ORDERED_ITEM_SHOW,
  ORDERED_ITEM_EDIT,
  USERS_ALL,
  USER_CREATE,
  USER_EDIT,
  COMPANY_SHOW,
  COMPANY_EDIT,
  CUSTOMER_WIZARD,
  CUSTOMER_CREATE,
  TIP_AND_TRICKS_ALL,
  TIP_AND_TRICK_SHOW,
  TIP_AND_TRICK_CREATE,
  TRAINING_MATERIAL_CREATE,
  MENUS_ALL,
  MENU_CATEGORY_SHOW,
  MENU_CATEGORY_EDIT,
  MENU_CATEGORIES_ALL,
  MENU_CATEGORY_CREATE,
  MENU_TYPE_SHOW,
  MENU_TYPE_EDIT,
  MENU_TYPES_ALL,
  MENU_TYPE_CREATE,
  PROFILE_EDIT,
  PROFILE_SHOW,
  TABLES_ALL,
  TABLE_SHOW,
  TABLE_CREATE,
  RESTAURANT_MENU_SHOW,
  RESTAURANT_MENU_CREATE,
  RESTAURANT_MENU_EDIT,
  MENU_CREATE,
  MENU_SHOW,
  MENU_EDIT,

  RESTAURANT_TABLE_CREATE,
  RESTAURANT_TABLE_SHOW,
  RESTAURANT_ZONE_EDIT,
  RESTAURANT_ZONE_SHOW,
  RESTAURANT_ZONE_CREATE,
  TABLE_EDIT,
  RESTAURANT_ZONE_CREATE_Q_R_CODE_CREATE,
  RESTAURANT_ORDER_SHOW,
  COMPANY_ADMIN_INVITE,
  INTERACTIVE_MENUS_ALL,
  INTERACTIVE_MENU_CREATE,
  INTERACTIVE_MENU_SHOW,
  INTERACTIVE_MENU_EDIT,
  ITEM_TYPES_ALL,
  ITEM_TYPE_CREATE,
  ITEM_TYPE_SHOW,
  ITEM_TYPE_EDIT,
  ITEM_TYPE_INSTANCE_CREATE,
  ITEM_TYPE_INSTANCE_SHOW,
  ITEM_TYPE_INSTANCE_EDIT
} = PATHS.AUTHENTICATED

const MAIN_ROUTES = [
  { key: 'DASHBOARD', path: DASHBOARD, component: Dashboard, exact: true },
  { key: 'USER_SHOW', path: USER_SHOW, component: UserShow, exact: true },

  {
    key: 'TRANSLATIONS',
    path: TRANSLATIONS,
    component: Translations,
    exact: true
  },

  {
    key: 'SETTINGS',
    path: SETTINGS,
    component: Settings,
    exact: false
  },
  {
    key: 'STATISTICS',
    path: STATISTICS,
    component: Statistics,
    exact: false
  },
  {
    key: 'RESTAURANT_EDIT',
    path: RESTAURANT_EDIT,
    component: RestaurantEdit,
    exact: true
  },
  {
    key: 'ADDRESS_EDIT',
    path: ADDRESS_EDIT,
    component: AddressEdit,
    exact: true
  },
  {
    key: 'MEDIA_OBJECT_EDIT',
    path: MEDIA_OBJECT_EDIT,
    component: MediaObjectEdit,
    exact: true
  },
  {
    key: 'COMPANY_ADMIN_INVITE',
    path: COMPANY_ADMIN_INVITE,
    component: CustomerAdminInvite,
    exact: true
  },
  {
    key: 'MEASUREMENT_TYPE_EDIT',
    path: MEASUREMENT_TYPE_EDIT,
    component: MeasurementTypeEdit,
    exact: true
  },
  {
    key: 'ALLERGEN_EDIT',
    path: ALLERGEN_EDIT,
    component: AllergenEdit,
    exact: true
  },
  { key: 'METHOD_EDIT', path: METHOD_EDIT, component: MethodEdit, exact: true },
  {
    key: 'ALCOHOL_TASTE_TYPE_EDIT',
    path: ALCOHOL_TASTE_TYPE_EDIT,
    component: AlcoholTasteTypeEdit,
    exact: true
  },
  {
    key: 'MAIN_SPIRIT_TYPE_EDIT',
    path: MAIN_SPIRIT_TYPE_EDIT,
    component: MainSpiritTypeEdit,
    exact: true
  },

  { key: 'FLAVOR_EDIT', path: FLAVOR_EDIT, component: FlavorEdit, exact: true },
  {
    key: 'GLASS_TYPE_EDIT',
    path: GLASS_TYPE_EDIT,
    component: GlassTypeEdit,
    exact: true
  },
  {
    key: 'DECORATION_TYPE_EDIT',
    path: DECORATION_TYPE_EDIT,
    component: DecorationTypeEdit,
    exact: true
  },
  { key: 'ORDER_EDIT', path: ORDER_EDIT, component: OrderEdit, exact: true },
  {
    key: 'ORDER_STATUS_EDIT',
    path: ORDER_STATUS_EDIT,
    component: OrderStatusEdit,
    exact: true
  },
  {
    key: 'ORDERED_ITEM_EDIT',
    path: ORDERED_ITEM_EDIT,
    component: OrderedItemEdit,
    exact: true
  },

  { key: 'USER_EDIT', path: USER_EDIT, component: UserEdit, exact: true },
  {
    key: 'COMPANY_EDIT',
    path: COMPANY_EDIT,
    component: CustomerEdit,
    exact: true
  },
  { key: 'MENU_EDIT', path: MENU_EDIT, component: MenuEdit, exact: true },
  {
    key: 'TABLE_EDIT',
    path: TABLE_EDIT,
    component: TableEdit,
    exact: true
  },
  {
    key: 'RESTAURANT_MENU_EDIT',
    path: RESTAURANT_MENU_EDIT,
    component: MenuEdit,
    exact: true
  },
  {
    key: 'MENU_EDIT',
    path: MENU_EDIT,
    component: MenuEdit,
    exact: true
  },
  {
    key: 'MENU_CATEGORY_EDIT',
    path: MENU_CATEGORY_EDIT,
    component: MenuCategoryEdit,
    exact: true
  },
  {
    key: 'MENU_TYPE_EDIT',
    path: MENU_TYPE_EDIT,
    component: MenuTypeEdit,
    exact: true
  },
  {
    key: 'RESTAURANT_ZONE_EDIT',
    path: RESTAURANT_ZONE_EDIT,
    component: ZoneEdit,
    expect: true
  },
  {
    key: 'RESTAURANT_CREATE',
    path: RESTAURANT_CREATE,
    component: RestaurantCreate,
    exact: true
  },
  {
    key: 'RESTAURANTS_ALL',
    path: RESTAURANTS_ALL,
    component: RestaurantsAll,
    exact: true
  },
  {
    key: 'RESTAURANT_SHOW',
    path: RESTAURANT_SHOW,
    component: RestaurantShow,
    exact: true
  },

  {
    key: 'ADDRESS_SHOW',
    path: ADDRESS_SHOW,
    component: AddressShow,
    exact: true
  },
  {
    key: 'MEDIA_OBJECT_SHOW',
    path: MEDIA_OBJECT_SHOW,
    component: MediaObjectShow,
    exact: true
  },
  {
    key: 'MEDIA_OBJECT_CREATE',
    path: MEDIA_OBJECT_CREATE,
    component: MediaObjectCreate,
    exact: true
  },
  {
    key: 'MEASUREMENT_TYPE_SHOW',
    path: MEASUREMENT_TYPE_SHOW,
    component: MeasurementTypeShow,
    exact: true
  },
  {
    key: 'ALLERGEN_SHOW',
    path: ALLERGEN_SHOW,
    component: AllergenShow,
    exact: true
  },
  { key: 'METHOD_SHOW', path: METHOD_SHOW, component: MethodShow, exact: true },
  {
    key: 'ALCOHOL_TASTE_TYPE_SHOW',
    path: ALCOHOL_TASTE_TYPE_SHOW,
    component: AlcoholTasteTypeShow,
    exact: true
  },
  {
    key: 'MAIN_SPIRIT_TYPE_SHOW',
    path: MAIN_SPIRIT_TYPE_SHOW,
    component: MainSpiritTypeShow,
    exact: true
  },
  { key: 'FLAVOR_SHOW', path: FLAVOR_SHOW, component: FlavorShow, exact: true },
  {
    key: 'GLASS_TYPE_SHOW',
    path: GLASS_TYPE_SHOW,
    component: GlassTypeShow,
    exact: true
  },
  {
    key: 'DECORATION_TYPE_SHOW',
    path: DECORATION_TYPE_SHOW,
    component: DecorationTypeShow,
    exact: true
  },

  {
    key: 'ORDER_STATUS_SHOW',
    path: ORDER_STATUS_SHOW,
    component: OrderStatusShow,
    exact: true
  },
  {
    key: 'ORDERED_ITEM_SHOW',
    path: ORDERED_ITEM_SHOW,
    component: OrderedItemShow,
    exact: true
  },
  {
    key: 'RESTAURANT_ZONE_CREATE_Q_R_CODE_CREATE',
    path: RESTAURANT_ZONE_CREATE_Q_R_CODE_CREATE,
    component: QRCodeCreate,
    exact: true
  },
  { key: 'USERS_ALL', path: USERS_ALL, component: UsersAll, exact: true },
  { key: 'USER_SHOW', path: USER_SHOW, component: UserShow, exact: true },
  { key: 'USER_CREATE', path: USER_CREATE, component: UserCreate, exact: true },
  {
    key: 'CUSTOMER_WIZARD',
    path: CUSTOMER_WIZARD,
    component: CustomerWizardPage,
    exact: true
  },
  {
    key: 'CUSTOMER_CREATE',
    path: CUSTOMER_CREATE,
    component: CustomerCreate,
    exact: true
  },
  {
    key: 'COMPANY_SHOW',
    path: COMPANY_SHOW,
    component: CustomerShow,
    exact: true
  },
  {
    key: 'TIP_AND_TRICK_CREATE',
    path: TIP_AND_TRICK_CREATE,
    component: TipAndTrickCreate,
    exact: true
  },
  {
    key: 'TIP_AND_TRICKS_ALL',
    path: TIP_AND_TRICKS_ALL,
    component: TrainingMaterialsAll,
    exact: true
  },
  {
    key: 'TIP_AND_TRICK_SHOW',
    path: TIP_AND_TRICK_SHOW,
    component: TrainingMaterialShow,
    exact: true
  },
  {
    key: 'TRAINING_MATERIAL_CREATE',
    path: TRAINING_MATERIAL_CREATE,
    component: TrainingMaterialCreate,
    exact: true
  },
  { key: 'MENUS_ALL', path: MENUS_ALL, component: MenusAll, exact: true },
  { key: 'MENU_SHOW', path: MENU_SHOW, component: MenuShow, exact: true },
  {
    key: 'MENU_CREATE',
    path: MENU_CREATE,
    component: MenuCreate,
    exact: true
  },
  { key: 'TABLES_ALL', path: TABLES_ALL, component: TablesAll, exact: true },
  { key: 'TABLE_SHOW', path: TABLE_SHOW, component: TableShow, exact: true },
  {
    key: 'RESTAURANT_MENU_CREATE',
    path: RESTAURANT_MENU_CREATE,
    component: MenuCreate,
    exact: true
  },

  {
    key: 'TABLE_CREATE',
    path: TABLE_CREATE,
    component: TableCreate,
    exact: true
  },
  {
    key: 'RESTAURANT_TABLE_CREATE',
    path: RESTAURANT_TABLE_CREATE,
    component: TableCreate,
    exact: true
  },
  {
    key: 'RESTAURANT_TABLE_SHOW',
    path: RESTAURANT_TABLE_SHOW,
    component: TableShow,
    exact: true
  },
  {
    key: 'RESTAURANT_ZONE_SHOW',
    path: RESTAURANT_ZONE_SHOW,
    component: ZoneShow,
    exact: true
  },

  {
    key: 'RESTAURANT_MENU_SHOW',
    path: RESTAURANT_MENU_SHOW,
    component: MenuShow,
    exact: true
  },
  {
    key: 'RESTAURANT_ZONE_CREATE',
    path: RESTAURANT_ZONE_CREATE,
    component: ZoneCreate,
    exact: true
  },
  {
    key: 'MENU_SHOW',
    path: MENU_SHOW,
    component: MenuShow,
    exact: true
  },
  {
    key: 'MENU_CREATE',
    path: MENU_CREATE,
    component: MenuCreate,
    exact: true
  },
  {
    key: 'MENU_CATEGORY_SHOW',
    path: MENU_CATEGORY_SHOW,
    component: MenuCategoryShow,
    exact: true
  },
  {
    key: 'MENU_CATEGORIES_ALL',
    path: MENU_CATEGORIES_ALL,
    component: MenuCategoriesAll,
    exact: true
  },
  {
    key: 'MENU_CATEGORY_CREATE',
    path: MENU_CATEGORY_CREATE,
    component: MenuCategoryCreate,
    exact: true
  },
  {
    key: 'MENU_TYPE_SHOW',
    path: MENU_TYPE_SHOW,
    component: MenuTypeShow,
    exact: true
  },
  {
    key: 'MENU_TYPES_ALL',
    path: MENU_TYPES_ALL,
    component: MenuTypesAll,
    exact: true
  },
  {
    key: 'MENU_TYPE_CREATE',
    path: MENU_TYPE_CREATE,
    component: MenuTypeCreate,
    exact: true
  },
  {
    key: 'PROFILE_SHOW',
    path: PROFILE_SHOW,
    component: Dashboard,
    exact: true
  },
  {
    key: 'PROFILE_EDIT',
    path: PROFILE_EDIT,
    component: ProfileEdit,
    exact: true
  },
  {
    key: 'RESTAURANT_ORDER_SHOW',
    path: RESTAURANT_ORDER_SHOW,
    component: OrderShow,
    exact: true
  },
  {
    key: 'INTERACTIVE_MENUS_ALL',
    path: INTERACTIVE_MENUS_ALL,
    component: InteractiveMenusAll,
    exact: true
  },
  {
    key: 'INTERACTIVE_MENU_CREATE',
    path: INTERACTIVE_MENU_CREATE,
    component: InteractiveMenuCreate,
    exact: true
  },
  {
    key: 'INTERACTIVE_MENU_SHOW',
    path: INTERACTIVE_MENU_SHOW,
    component: InteractiveMenuShow,
    exact: true
  },
  {
    key: 'INTERACTIVE_MENU_EDIT',
    path: INTERACTIVE_MENU_EDIT,
    component: InteractiveMenuEdit,
    exact: true
  },
  {
    key: 'ITEM_TYPES_ALL',
    path: ITEM_TYPES_ALL,
    component: ItemTypesAll,
    exact: true
  },
  {
    key: 'ITEM_TYPE_CREATE',
    path: ITEM_TYPE_CREATE,
    component: ItemTypeCreate,
    exact: true
  },
  {
    key: 'ITEM_TYPE_SHOW',
    path: ITEM_TYPE_SHOW,
    component: ItemTypeShow,
    exact: true
  },
  {
    key: 'ITEM_TYPE_EDIT',
    path: ITEM_TYPE_EDIT,
    component: ItemTypeEdit,
    exact: true
  },
  {
    key: 'ITEM_TYPE_INSTANCE_CREATE',
    path: ITEM_TYPE_INSTANCE_CREATE,
    component: ItemTypeInstanceCreate,
    exact: true
  },
  {
    key: 'ITEM_TYPE_INSTANCE_SHOW',
    path: ITEM_TYPE_INSTANCE_SHOW,
    component: MenuItemShow,
    exact: true
  },
  {
    key: 'ITEM_TYPE_INSTANCE_EDIT',
    path: ITEM_TYPE_INSTANCE_EDIT,
    component: MenuItemEdit,
    exact: true
  }
]

/* Config for settings pages */
const settingsPagesProps = { withHeader: false, isListWithCreate: true }

const SETTINGS_ROUTES = [
  // { key: 'SETTINGS_MENU_TYPES_ALL', path: SETTINGS_MENU_TYPES_ALL, component: MenuTypesAll, exact: true }
  {
    key: 'SETTINGS_MENU_TYPES_ALL',
    path: SETTINGS_MENU_TYPES_ALL,
    component: () => <MenuTypesAll {...settingsPagesProps} />,
    exact: true
  }
]

const STATISTICS_ROUTES = []

export { MAIN_ROUTES, SETTINGS_ROUTES, STATISTICS_ROUTES }
export default MAIN_ROUTES
