import { Paragraph } from '@qonsoll/react-design'

const TermAndConditionsOfUseSimpleView = (props) => {
  return (
    <Paragraph>
      Lorem ipsum dolor sit amet, consectetur adipiscing elit. Phasellus
      faucibus varius vestibulum. Nullam sit amet bibendum orci, venenatis
      scelerisque eros. Nulla nec facilisis sapien. Fusce vulputate, augue quis
      semper mollis, leo erat blandit ante, sit amet faucibus eros neque vel
      massa. Integer venenatis nisi pretium dolor commodo, a lacinia turpis
      pellentesque. Sed consequat vehicula ullamcorper. Donec sit amet lacinia
      neque. Curabitur finibus lobortis dolor. Vestibulum non commodo ante, sed
      dapibus ex. Suspendisse potenti. Praesent interdum iaculis libero
      vulputate gravida. Nunc vel pretium justo. In hendrerit aliquam aliquam.
      Pellentesque congue pharetra commodo. Vivamus viverra dolor justo, vel
      viverra tellus dictum vitae. Duis congue, erat at tempor vestibulum,
      lectus massa tempus sapien, ac luctus lacus sapien vel velit. Duis sed
      malesuada augue. Sed nisl diam, efficitur nec metus eu, faucibus faucibus
      magna. Pellentesque habitant morbi tristique senectus et netus et
      malesuada fames ac turpis egestas. Suspendisse vel lectus sit amet magna
      dapibus molestie. Vivamus bibendum velit eu arcu tristique placerat.
      Pellentesque habitant morbi tristique senectus et netus et malesuada fames
      ac turpis egestas. Etiam vehicula lorem ligula, ut bibendum dui pretium
      nec. Curabitur sed magna cursus, sollicitudin ante eget, venenatis velit.
      Morbi accumsan purus ut lectus rutrum, rhoncus facilisis quam varius.
      Curabitur tristique eleifend purus, in mattis risus semper eget. Nam
      ligula felis, aliquam quis neque sit amet, viverra varius est. Sed id arcu
      suscipit, ultricies neque in, euismod sapien. Sed eget arcu quis orci
      iaculis sodales nec sed mi. Maecenas quis lacinia odio. Quisque sed
      tristique ligula, rutrum vestibulum urna. In in diam ex. Mauris pharetra
      pulvinar luctus. Integer enim orci, vulputate eget rutrum quis, vehicula
      id orci. Interdum et malesuada fames ac ante ipsum primis in faucibus.
      Pellentesque non facilisis arcu. Curabitur iaculis mi non sem viverra
      gravida. Cras dignissim est in malesuada tristique. In hac habitasse
      platea dictumst. Vivamus justo augue, rhoncus id est eget, scelerisque
      fringilla nisi. Maecenas fringilla ligula eget leo egestas dapibus.
      Vestibulum non dignissim magna. Aenean congue ut urna a lobortis. Proin
      laoreet molestie justo, id imperdiet ex tincidunt a. Maecenas ultrices,
      odio vehicula aliquam vulputate, lectus magna fringilla tortor, id
      molestie dui nisl in mauris. Mauris tempor erat nec tellus euismod
      lobortis. Sed posuere turpis sit amet elit gravida venenatis. Donec
      dapibus suscipit maximus. Etiam interdum molestie mi tristique rutrum.
      Aliquam ac libero ac lectus vestibulum cursus. Donec placerat neque
      vehicula consectetur sagittis. Integer hendrerit, lacus sed gravida
      congue, est elit efficitur eros, at posuere nunc lorem dictum tellus.
      Phasellus fermentum aliquet velit, sagittis hendrerit felis. Pellentesque
      pulvinar est eu purus maximus tristique. Vivamus ipsum quam, hendrerit in
      mattis eget, iaculis eu sem. Quisque a mollis risus. Sed at malesuada
      purus. Nulla imperdiet, enim ac aliquam dictum, augue dolor elementum
      erat, non volutpat est erat euismod elit. Phasellus viverra efficitur
      nunc, id sagittis felis euismod ut. Suspendisse nunc felis, aliquam sit
      amet ante quis, tempor accumsan quam. Donec nec ipsum ac est condimentum
      fringilla. Nam pellentesque, dui non mattis commodo, nisi libero tincidunt
      lectus, vitae dapibus nisl nibh nec nisi. Vestibulum quis pretium felis.
      Maecenas convallis ex ac odio euismod condimentum. Nulla egestas purus
      est. Donec eu auctor nibh, quis ullamcorper tellus. Proin libero purus,
      tincidunt ac nulla et, sagittis facilisis ligula. Nam et faucibus nisl.
      Sed neque nisi, ornare et justo eget, finibus laoreet eros. Vivamus
      tristique elit vel porta lacinia. Donec ut mauris dictum, tempor nulla ac,
      mattis lacus. Quisque condimentum velit ac orci fringilla, a vulputate
      nibh lacinia. Nulla sed blandit eros, ac maximus nunc. Pellentesque
      habitant morbi tristique senectus et netus et malesuada fames ac turpis
      egestas. Praesent condimentum ipsum ac elit gravida, eget consectetur nisl
      vehicula. Morbi semper diam vitae cursus luctus. Phasellus pulvinar justo
      eu nisl feugiat, quis tempor dui eleifend. Vivamus condimentum pharetra
      urna, a dapibus lectus feugiat sit amet. Vestibulum mauris lectus, auctor
      nec vulputate vel, interdum in felis. Donec elementum, lacus imperdiet
      eleifend vehicula, nulla dolor consectetur risus, quis elementum sapien
      mauris non ligula. Phasellus molestie, elit eu varius vulputate, nisi
      lectus feugiat sem, et euismod lorem nunc imperdiet ligula. Quisque
      blandit ligula non nibh facilisis vulputate id in mi. Sed dui tortor,
      tempus ut vehicula id, porta at nunc. Nam suscipit nisl tincidunt,
      elementum metus eget, semper lacus. Vestibulum nec tristique libero, vel
      efficitur magna. Vestibulum elementum eu ligula ut cursus. Nullam eros
      nulla, mattis vitae porta nec, tempor sed nibh. Suspendisse suscipit
      consectetur urna. Praesent rhoncus viverra ligula vitae porttitor. Fusce
      lobortis nisl eget purus tincidunt, eu iaculis odio fringilla. Sed
      tincidunt venenatis ipsum, et bibendum erat ultricies vitae. Nullam a
      vestibulum diam. Etiam tristique eget felis laoreet blandit. Sed vulputate
      nisi eros, et efficitur sem elementum mattis. Aliquam erat volutpat. Morbi
      eget elit venenatis, imperdiet diam id, sollicitudin arcu. Donec a dui
      sapien. Fusce et augue elementum tortor posuere tempor. Orci varius
      natoque penatibus et magnis dis parturient montes, nascetur ridiculus mus.
      Nunc et enim sapien. Pellentesque lacus diam, tristique eget vulputate eu,
      molestie dapibus est. Duis molestie dictum vulputate. Nunc vitae semper
      felis. Phasellus condimentum vel purus eget maximus. Vivamus eu consequat
      massa. Aliquam velit ligula, volutpat id pretium et, eleifend ac urna.
      Pellentesque pharetra non lorem eu tincidunt. Nulla facilisi. Aliquam eu
      laoreet augue, viverra imperdiet tellus. Donec eu lorem id urna accumsan
      bibendum. Pellentesque ex sem, sollicitudin a ante mattis, venenatis
      feugiat mauris. Etiam venenatis lacinia felis, vel laoreet augue egestas
      et. Fusce imperdiet enim sed risus tempor mattis vitae sit amet justo. Nam
      condimentum enim nulla, vel luctus dui tempus eu. Proin at auctor tortor,
      vel tempor enim. Morbi accumsan massa sit amet sem fringilla, non bibendum
      quam aliquet. Proin tincidunt ante eget elit scelerisque luctus. Mauris
      fermentum mauris velit, consectetur rutrum ipsum maximus nec. Aliquam erat
      volutpat. Suspendisse fringilla eros et lorem placerat, fermentum iaculis
      metus congue.
    </Paragraph>
  )
}

export default TermAndConditionsOfUseSimpleView
