import { Col, Container, Img, Row, Spin } from '@qonsoll/react-design'
import { getBase64, uploadImage } from 'helpers'
import { useEffect, useState } from 'react'

import { AddItemCard } from 'components'
import CustomerAvatarUploadStyled from './CustomerAvatarUploader.styled'
import PropTypes from 'prop-types'

const CustomerAvatarUploader = (props) => {
  const { value, onChange, withDirectUpload = false, h = 'center' } = props

  const [loading] = useState(false)
  const [file, setFile] = useState(null)

  // [HANDLER_FUNCTIONS]

  const handleUpload = async ({ onSuccess, file }) => {
    if (withDirectUpload) {
      const url = await uploadImage(file)

      onChange(url)
    } else {
      onChange?.(file)
      getBase64(file, (result) => setFile(result))
    }
    onSuccess()
  }

  // [LIFECYCLE]
  useEffect(
    () => value && getBase64(value, (result) => setFile(result)),
    [value]
  )

  return (
    <Container>
      <Row h={h} noGutters>
        <Col cw="auto">
          <CustomerAvatarUploadStyled
            fileList={null}
            listType="picture-card"
            showUploadList={false}
            customRequest={handleUpload}>
            {file && (
              <Img
                className="img-avatar"
                src={file || value}
                alt="Avatar img"
              />
            )}
            {!file &&
              (loading ? (
                <Spin />
              ) : (
                <AddItemCard message="Click to add logo" />
              ))}
          </CustomerAvatarUploadStyled>
        </Col>
      </Row>
    </Container>
  )
}

CustomerAvatarUploader.propTypes = {
  value: PropTypes.string,
  h: PropTypes.string,
  onChange: PropTypes.func,
  withDirectUpload: PropTypes.bool
}

export default CustomerAvatarUploader
