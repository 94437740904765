import { Col, Container, Row } from '@qonsoll/react-design'
import {
  CustomerAdmins,
  CustomerRestaurants
} from 'domains/Customer/components'
import { Spinner, TabCount } from 'components'
import { collection, query, where } from 'firebase/firestore'
import { useHistory, useParams } from 'react-router-dom'
import { useLoading, useStateWithStorage } from 'hooks'

import { COLLECTIONS } from '__constants__'
import { CustomerInfo } from 'domains/Customer/components/CustomerAdvancedView/components'
import PropTypes from 'prop-types'
import { Tabs } from 'antd'
import { firestore } from 'services/firebase'
import { useCheckInvitedOrActiveAdmin } from 'domains/Customer/hooks'
import { useCollectionData } from 'react-firebase-hooks/firestore'
import { useTranslations } from 'contexts/Translation'
import { useMemo } from 'react'

const CustomerAdvancedView = (props) => {
  const { customer, user } = props

  /* Check admin status */
  const [isAdminInvitedOrActive] = useCheckInvitedOrActiveAdmin(customer?._id)
  /* State for active tab in AdvancedView with saving it to storage */
  const [activeTab, setActiveTab] = useStateWithStorage(
    null,
    'customer-advanced-view-active-tab'
  )

  const [adminsActivated, adminsActivatedLoading] = useCollectionData(
    customer?._id &&
      query(
        collection(firestore, COLLECTIONS.USERS),
        where('_companies', 'array-contains', customer?._id)
      )
  )
  const [adminsInvited, adminsInvitedLoading] = useCollectionData(
    customer?._id &&
      query(
        collection(firestore, COLLECTIONS.MAILS),
        where('customerId', '==', customer?._id)
      )
  )

  const admins = useMemo(
    () => [...(adminsActivated || []), ...(adminsInvited || [])],
    [adminsActivated, adminsInvited]
  )
  /* Getting translations instance */
  const { t } = useTranslations()
  /* Getting history instance from useHistory hook for redirecting */
  const history = useHistory()
  /* Getting params from useParams hook for getting url params */
  const params = useParams()
  /* Getting id from params */
  const { customerId } = params
  /* Getting TabPane component from Tabs */
  const { TabPane } = Tabs
  /* Handling change tab */
  const changeTab = (key) => {
    if (params && customerId && key.includes(`customers/${customerId}`)) {
      history.push(key)
    }
    setActiveTab(key)
  }

  const isLoading = useLoading([adminsActivatedLoading, adminsInvitedLoading])

  return isLoading ? (
    <Spinner height="70vh" text={t('Company loading')} />
  ) : (
    <Container>
      <Row negativeBlockMargin>
        <Col cw={4}>
          <CustomerInfo customer={customer} />
        </Col>
        <Col cw={8}>
          <Tabs defaultActiveKey={activeTab} onChange={changeTab}>
            <TabPane
              tab={
                <TabCount value={customer?.restaurants} text={t('Venues')} />
              }
              key="#venues">
              <CustomerRestaurants customer={customer} />
            </TabPane>
            <TabPane
              tab={<TabCount value={admins} text={t('Admins')} />}
              key="#admins">
              <CustomerAdmins
                adminsInvited={adminsInvited}
                isAdminInvitedOrActive={isAdminInvitedOrActive}
                admins={admins}
                user={user}
              />
            </TabPane>
          </Tabs>
        </Col>
      </Row>
    </Container>
  )
}
CustomerAdvancedView.propTypes = {
  customer: PropTypes.object,
  user: PropTypes.object
}
export default CustomerAdvancedView
