import { generatePath, useHistory } from 'react-router-dom'

import { COLLECTIONS } from '__constants__'
import PATHS from 'pages/paths'
import { message } from 'antd'
import { updateParentHasManyRelationship } from 'services/api/firebase'
import { useTranslations } from 'contexts/Translation'

const useMenuActions = ({ restaurants, companyId, restaurantId, menuId }) => {
  const { t } = useTranslations()
  const history = useHistory()

  const handleEdit = () => {
    if (companyId && restaurantId && menuId) {
      const pathParams = { companyId, restaurantId, menuId }

      const path = generatePath(
        PATHS.AUTHENTICATED.COMPANY_RESTAURANT_MENU_EDIT,
        pathParams
      )
      history.push(path)
    } else if (restaurantId) {
      const pathParams = { restaurantId, menuId }
      const path = generatePath(
        PATHS.AUTHENTICATED.RESTAURANT_MENU_EDIT,
        pathParams
      )
      history.push(path)
    } else {
      const pathParams = { menuId }
      const path = generatePath(PATHS.AUTHENTICATED.MENU_EDIT, pathParams)
      history.push(path)
    }
  }

  const handleDelete = async () => {
    try {
      const request = await fetch(
        `${process.env.REACT_APP_FIREBASE_FUNCTIONS_URL}/menus/${menuId}`,
        { method: 'DELETE', headers: { 'Content-Type': 'application/json' } }
      )

      if (request.ok) {
        const updatingRestaurantsPromises = restaurants?.map((venue) =>
          updateParentHasManyRelationship(
            venue?._id,
            COLLECTIONS.RESTAURANTS,
            'menus',
            menuId,
            true
          )
        )

        const updatingResult = await Promise.allSettled(
          updatingRestaurantsPromises
        )

        const updatingResultErrors = updatingResult.filter(
          (result) => result.status === 'rejected'
        )

        if (updatingResultErrors.length) {
          throw new Error(
            `Something went wrong during updating restaurants menus, ${updatingResultErrors
              ?.map((error) => error.reason)
              .join(', ')}`
          )
        }

        message.success(t('Successfully deleted menu with all items'))
        history.goBack()
      }
    } catch (err) {
      message.error(
        `${t('Something went wrong during menu delete')}. ${t(
          'Check console for more information'
        )}, ${err?.message}`
      )
      throw err
    }
  }

  return { handleEdit, handleDelete }
}

export default useMenuActions
