import { Col, Container, Option, Row, Select } from '@qonsoll/react-design'
import { useCallback, useEffect, useMemo } from 'react'

import { PropTypes } from 'prop-types'
import { useGetAddressInfo } from 'domains/Address/hooks'
import usePlacesAutocomplete from 'use-places-autocomplete'
import { useTranslations } from '@qonsoll/translation'

const PlacesAutocomplete = ({
  value: formValue,
  onChange,
  description,
  placeholder,
  editedFromProfile,
  setMainLanguage,
  setMainCurrency,
  countriesList,
  listSortingCountries
}) => {
  const { t } = useTranslations()
  const getAddressInfo = useGetAddressInfo()
  const {
    value,
    setValue,
    suggestions: { status, data },
    clearSuggestions
  } = usePlacesAutocomplete({
    debounce: 300,
    requestOptions: {
      language: 'en'
    }
  })

  useEffect(() => {
    if (!value && formValue && description) {
      setValue(description)
    }
  }, [value, formValue, setValue, description])
  const handleInput = useCallback((value) => setValue(value), [setValue])

  const handleSelect = useCallback(
    async (placeId) => {
      // Find the searchValue object from the data array using the placeId.
      const searchValue = data?.find((item) => item?.place_id === placeId)
      // Destructure the description and place_id properties from the searchValue object.
      const { place_id: locationId } = searchValue
      // Set the value of the input to the description property of the searchValue object.
      setValue(searchValue?.description)
      // Clear the suggestions list.
      clearSuggestions()

      onChange({ locationId, description: searchValue?.description })
      if (editedFromProfile) {
        const addressInfo = await getAddressInfo(locationId)
        const country = addressInfo?.country
        // defined what country we select
        const selectedCountry =
          countriesList &&
          countriesList?.find((item) => item?.internationalName === country)

        // set main language due to selected country
        setMainLanguage(
          selectedCountry?.languages
            ? selectedCountry?.languages[0]?.name
            : 'English'
        )
        // set main currency due to selected country
        setMainCurrency &&
          setMainCurrency(
            selectedCountry?.currency
              ? selectedCountry?.currency[0]?.code
              : 'USD'
          )
      }
    },
    [
      clearSuggestions,
      countriesList,
      data,
      editedFromProfile,
      getAddressInfo,
      onChange,
      setMainCurrency,
      setMainLanguage,
      setValue
    ]
  )

  const computedOptions = useMemo(
    () =>
      status === 'OK' &&
      data?.map(({ place_id, description }) => (
        <Option key={place_id} value={place_id}>
          {description}
        </Option>
      )),
    [data, status]
  )

  const selectProps = {
    style: { width: '100%' },
    onSearch: handleInput,
    onSelect: handleSelect,
    filterOption: false,
    placeholder: placeholder || t('Please enter location'),
    value: value || null,
    showSearch: true
  }

  return (
    <Container>
      <Row noGutters>
        <Col cw={12}>
          <Select {...selectProps}>{computedOptions}</Select>
        </Col>
      </Row>
    </Container>
  )
}

PlacesAutocomplete.propTypes = {
  onChange: PropTypes.func,
  value: PropTypes.object,
  description: PropTypes.string,
  editedFromProfile: PropTypes.bool,
  countriesList: PropTypes.array,
  listSortingCountries: PropTypes.array,
  setMainCurrency: PropTypes.func,
  setMainLanguage: PropTypes.func,
  placeholder: PropTypes.string
}

export default PlacesAutocomplete
