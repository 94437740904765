const VARIANT_TYPES = {
  COMPANY: 'Company',
  VENUE: 'Venue'
}

const VARIANT_TYPES_KEYS = {
  COMPANY: 'COMPANY',
  VENUE: 'VENUE'
}

const VARIANT_TYPES_OPTIONS = Object.keys(VARIANT_TYPES).map((key) => ({
  label: VARIANT_TYPES[key],
  value: key
}))

export { VARIANT_TYPES, VARIANT_TYPES_KEYS, VARIANT_TYPES_OPTIONS }
