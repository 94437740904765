import { Col, Container, Row, Title } from '@qonsoll/react-design'
import { VARIANT_TYPES_KEYS, VARIANT_TYPES_OPTIONS } from '__constants__'

import { EnumSelect } from 'components'
import PropTypes from 'prop-types'
import { useTranslations } from 'contexts/Translation'

const VariantItemStep = (props) => {
  const { value, setValue, stepNumber } = props

  /* Getting translations instance */
  const { t } = useTranslations()

  /* Handling changes */
  const handleChange = (value) =>
    setValue((prev) => ({ ...prev, variantType: value }))

  const titleTextComputed = `${t('Step')} ${stepNumber}. ${t(
    'What do you want to create ?'
  )}`

  return (
    <Container mb="24px">
      <Title textAlign="center" variant="h4" mb="24px">
        {titleTextComputed}
      </Title>
      <Row noGutters h="center">
        <Col cw={12}>
          <EnumSelect
            defaultValue={VARIANT_TYPES_KEYS.VENUE}
            onChange={handleChange}
            value={value?.variantType}
            options={VARIANT_TYPES_OPTIONS}
            ariaLabelledby="Type of variant"
          />
        </Col>
      </Row>
    </Container>
  )
}

VariantItemStep.propTypes = {
  value: PropTypes.object,
  setValue: PropTypes.func,
  stepNumber: PropTypes.number
}

export default VariantItemStep
