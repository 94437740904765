import { Col, Row, Text } from '@qonsoll/react-design'

import { AddItemCard } from 'components'
import PropTypes from 'prop-types'
import { RestaurantSimpleView } from 'domains/Restaurant/components'
import { useTranslations } from 'contexts/Translation'

const RestaurantList = (props) => {
  const {
    restaurants,
    isListWithCreate,
    listView,
    emptyProps,
    actions,
    cw = [12, 6, 4, 3]
  } = props

  /* Getting translations instance */
  const { t } = useTranslations()

  /* Checking if list is empty */
  const isEmpty = !restaurants?.length
  /* Computing item width */
  const itemWidth = listView ? 12 : cw

  return (
    <Row negativeBlockMargin px={1}>
      {isListWithCreate && actions?.onCreate && (
        <Col mb={4} cw={itemWidth}>
          <AddItemCard onClick={actions.onCreate} />
        </Col>
      )}
      {!isEmpty &&
        restaurants.map((restaurant, index) => (
          <Col mb={4} key={restaurant?._id || index} cw={itemWidth}>
            <RestaurantSimpleView
              actions={actions}
              index={index}
              restaurant={restaurant}
            />
          </Col>
        ))}
      {isEmpty && (
        <Col cw={12}>
          <Text variant="overline" color="var(--ql-state-disabled-color)">
            {emptyProps?.message || t('No restaurants have been created yet')}
          </Text>
        </Col>
      )}
    </Row>
  )
}

RestaurantList.propTypes = {
  restaurants: PropTypes.array,
  isListWithCreate: PropTypes.bool,
  listView: PropTypes.bool,
  emptyProps: PropTypes.object,
  actions: PropTypes.any,
  cw: PropTypes.any
}

export default RestaurantList
