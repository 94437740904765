import PropTypes from 'prop-types'
import { Row, Col } from '@qonsoll/react-design'
import { Icon } from '@qonsoll/icons'
import { InputNumber } from 'antd'

import { ButtonAccent } from 'components'
import { IngredientSelect } from 'domains/Ingredient/components'
import { MeasurementTypeSelect } from 'domains/MeasurementType/components'
import { useTranslations } from 'contexts/Translation'
import { onTextAreaEnterPress } from 'helpers'

import { FormStyled } from './RecipeSimpleForm.styled'

const RecipeSimpleForm = (props) => {
  const { value, onChange, mbWrapper, index, onRemove } = props

  /* Getting translations instance */
  const { t } = useTranslations()

  /* Update amount of ingredient */
  const onUpdateAmount = (item) => onChange(item, index, 'amount')
  /* Update measurement of ingredient */
  const onUpdateMeasurement = (item) => onChange(item, index, 'measurement')
  /* Updated ingredient and another fields if ingredient has initial value */
  const onUpdateIngredient = (item) => {
    onChange(item?._id, index, 'ingredient')
    item?.measurement && onUpdateMeasurement(item?.measurement)
  }
  /* Handle delete ingredient from recipe */
  const handleDelete = () => onRemove(index)
  const validateStatus = (value) => (value ? 'success' : 'error')
  const formHelp = (value, text) => (value ? '' : text)

  return (
    <Row mb={mbWrapper ? 2 : 0} noGutters h="between">
      <Col cw={6}>
        <FormStyled
          validateStatus={validateStatus(value?.ingredient)}
          help={formHelp(value?.ingredient, t('Please select ingredient!'))}>
          <IngredientSelect
            oneOption
            showSearch
            showTitle={false}
            value={value?.ingredient}
            onChange={onUpdateIngredient}
            placeholder={t('Ingredient')}
          />
        </FormStyled>
      </Col>
      <Col cw={2}>
        <FormStyled
          validateStatus={validateStatus(value?.amount)}
          help={formHelp(value?.amount, t('Please write amt!'))}>
          <InputNumber
            decimalSeparator=","
            onPressEnter={onTextAreaEnterPress}
            min={0}
            value={value?.amount}
            onChange={onUpdateAmount}
            placeholder={t('Amt')}
          />
        </FormStyled>
      </Col>
      <Col cw={2}>
        <FormStyled
          validateStatus={validateStatus(value?.measurement)}
          help={formHelp(value?.measurement, t('Please select meas!'))}>
          <MeasurementTypeSelect
            value={value?.measurement}
            onChange={onUpdateMeasurement}
            placeholder={t('Meas')}
          />
        </FormStyled>
      </Col>

      <Col cw="auto">
        <ButtonAccent
          onClick={handleDelete}
          type="secondary"
          danger
          icon={<Icon name="Trash4Outlined" />}
        />
      </Col>
    </Row>
  )
}
RecipeSimpleForm.propTypes = {
  value: PropTypes.array,
  onChange: PropTypes.func,
  mbWrapper: PropTypes.bool,
  index: PropTypes.number,
  onRemove: PropTypes.func
}

export default RecipeSimpleForm
