import { BTN_VARIANTS } from './ButtonAccent.styled'
import { Button } from '@qonsoll/react-design'
import styled from 'styled-components'
import PropTypes from 'prop-types'

const defineBtnVariant = (type, danger, disabled) => {
  const typeComputed = type || 'primary'
  const variantComputed = disabled
    ? `${typeComputed}-disabled`
    : danger
    ? `${typeComputed}-danger`
    : typeComputed

  return BTN_VARIANTS[variantComputed]
}

// accepts prop {type}:primary, secondary, text
// accepts props {danger}
// accepts props {disabled}
const ButtonAccent = styled(Button)`
  font-weight: var(--ql-font-weight-semibold);
  ${({ type, danger, disabled }) => defineBtnVariant(type, danger, disabled)}
  border-radius: ${({ shape }) =>
    shape === 'circle' ? 'var(--ql-border-radius-full)' : null};
`

const ButtonWrapper = (props) => (
  <ButtonAccent
    {...props}
    btnText={props.children}
    btnIcon={props.icon}
    shape={props.shape}
  />
)

ButtonWrapper.propTypes = {
  children: PropTypes.string,
  icon: PropTypes.object,
  shape: PropTypes.string
}

export default ButtonWrapper
