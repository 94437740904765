import { Col, PageWrapper, Row } from '@qonsoll/react-design'

import { HeaderBreadcrumbs } from 'components'
import { MediaObjectAdvancedForm } from 'domains/MediaObject/components'
import { useHistory } from 'react-router-dom'

const MediaObjectCreatePage = (props) => {
  /* Getting history instance */
  const history = useHistory()

  /* Configuring page header */
  const headingProps = {
    marginBottom: -3
  }

  /* Function to handle back click */
  const handleBack = () => history.goBack()

  return (
    <PageWrapper
      onBack={handleBack}
      breadcrumbs={<HeaderBreadcrumbs />}
      headingProps={headingProps}>
      <Row noGutters>
        <Col cw={[12, 12, 8, 6]}>
          <MediaObjectAdvancedForm />
        </Col>
      </Row>
    </PageWrapper>
  )
}

export default MediaObjectCreatePage
