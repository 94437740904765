import {
  EnumSelectWrapper,
  SelectableCol,
  enumTextStyles,
  selectStyles
} from './EnumSelect.styled'
import { Select, Text } from '@qonsoll/react-design'
import { useEffect, useMemo } from 'react'

import { AriaGroup } from 'components'
import PropTypes from 'prop-types'
import { useTranslations } from 'contexts/Translation'

/**
 * It renders a selectable row if there are less than 4 options, otherwise it renders a select
 * @param value - The current value of the field
 * @param onChange - function that will be called when the value changes
 * @param options - An array of objects with the following shape:
 * @param placeholder - The placeholder text to show when no value is selected
 * @returns A function that returns a component
 */
const EnumSelect = (props) => {
  const {
    defaultValue,
    value,
    onChange,
    options,
    placeholder,
    ariaLabelledby,
    onSelectCallback,
    minWidth
  } = props

  /* Getting translations instance */
  const { t } = useTranslations()

  let selectedValue = useMemo(
    () => (value === undefined ? defaultValue : value),
    [defaultValue, value]
  )

  useEffect(() => {
    if (defaultValue && !value) {
      onChange?.(defaultValue)
    }
  }, [defaultValue, onChange, value])

  const handleChange = (value) => () => {
    onChange?.(value)
    onSelectCallback?.(value)
  }

  if (options?.length < 4) {
    return (
      <EnumSelectWrapper noGutters minWidth={minWidth}>
        <AriaGroup ariaLabelledby={ariaLabelledby} style={selectStyles}>
          {options?.map(({ label, value }, index) => (
            <SelectableCol
              key={label}
              h="center"
              onClick={handleChange(value)}
              selected={value === selectedValue}
              isLast={index === options.length - 1}>
              <Text
                color={
                  value === selectedValue
                    ? enumTextStyles.selected
                    : enumTextStyles.default
                }>
                {t(label)}
              </Text>
            </SelectableCol>
          ))}
        </AriaGroup>
      </EnumSelectWrapper>
    )
  }

  return (
    <Select
      value={value}
      onChange={onChange}
      placeholder={placeholder}
      options={options?.map((o) => ({ ...o, label: t(o.label) }))}
      aria-label="Select an option"
      aria-describedby="select-description"
    />
  )
}

EnumSelect.propTypes = {
  value: PropTypes.string,
  onChange: PropTypes.func,
  options: PropTypes.array,
  placeholder: PropTypes.string,
  defaultValue: PropTypes.string,
  ariaLabelledby: PropTypes.string,
  minWidth: PropTypes.string,
  onSelectCallback: PropTypes.func
}

export default EnumSelect
