import { ButtonAccent } from 'components'
import { Icon } from '@qonsoll/icons'
import PATHS from 'pages/paths'
import firebase from 'firebase/compat/app'
import { useBackButtonVisibility } from './hooks'
import { useHistory } from 'react-router-dom'

const Back = () => {
  const history = useHistory()
  const isButtonVisible = useBackButtonVisibility()

  const goBack = async (e) => {
    e.preventDefault()
    await firebase.auth().signOut()

    history.push(PATHS.UNAUTHENTICATED.LOGIN)
  }

  return isButtonVisible ? (
    <ButtonAccent
      type="text"
      shape="circle"
      icon={<Icon name="ArrowLeftOutlined" />}
      onClick={goBack}
    />
  ) : null
}

export default Back
