import { Col, Container, Row } from '@qonsoll/react-design'

import { CustomerAdvancedView } from 'domains/Customer/components'
import { ProfileNavigator } from './components'
import PropTypes from 'prop-types'
import { UserAdvancedView } from 'domains/User/components'

const ProfileAdvancedView = (props) => {
  const { user, customer } = props

  return (
    <Container>
      <Row negativeBlockMargin mb={3}>
        <Col cw={[12, 12, 12, 4]} mb={[3, 3, 3, 0]}>
          <UserAdvancedView user={user} />
        </Col>
        <Col cw={[12, 12, 12, 8]}>
          <ProfileNavigator />
        </Col>
      </Row>
      <Row noGutters>
        <Col cw={12}>
          <CustomerAdvancedView customer={customer} />
        </Col>
      </Row>
    </Container>
  )
}

ProfileAdvancedView.propTypes = {
  user: PropTypes.object,
  customer: PropTypes.object
}

export default ProfileAdvancedView
