import {
  Box,
  Col,
  Divider,
  Option,
  Row,
  Select,
  Text,
  Title
} from '@qonsoll/react-design'
import PropTypes from 'prop-types'

import { ButtonAccent } from 'components'
import { handleFilterOptions } from 'helpers'

import { useTranslations } from 'contexts/Translation'

import { ItemTypeInstanceAdvancedForm } from 'domains/ItemTypeInstance/components'
import {
  DrawerStyled,
  Overlay
} from 'domains/Dish/components/DishesListWithCreate/components/DishItemFormDrawer/DishItemFormDrawer.styled'
import { useGetCategoryDrawerInitialValues } from 'domains/MenuCategory/hooks'
import { useMemo } from 'react'

const MenuDrawer = (props) => {
  const { value, initialCategoryId } = props
  const {
    isAdding,
    editedCocktailInitialData,
    itemSelected,
    onClearCategory,
    onChangeCategory,
    categories,
    initialData,
    customOnCancel,
    customSaveNewMenuItem,
    handleSelectOptions,
    menuItems,
    menuItemsSelected,
    handleMenuItemsSelectChange,
    editedMenuItemInitialData,
    toggleAdd
  } = value || {}

  const { t, language } = useTranslations()

  const { possibleOptions, itemTypeData, itemTypeFields } =
    useGetCategoryDrawerInitialValues(initialCategoryId)

  const usedMenuItems = useMemo(
    () => (initialCategoryId ? possibleOptions : menuItems),
    [initialCategoryId, menuItems, possibleOptions]
  )

  const existMenuData = {
    itemType: itemTypeData,
    itemTypeFieldsSorted: itemTypeFields,
    menuItem: editedMenuItemInitialData
  }

  const formValue = initialCategoryId ? existMenuData : initialData

  return (
    <>
      <Overlay showForm={isAdding} />

      {/* Drawer with select (itemType), select or create instance (menuItem)  */}
      <DrawerStyled showForm={isAdding}>
        {!editedCocktailInitialData ? (
          <>
            {!initialCategoryId && (
              <>
                <Text variant="body1">{t('Category')}</Text>
                <Select
                  mt={1}
                  mb={2}
                  showSearch
                  allowClear={true}
                  value={itemSelected}
                  aria-label={t('Menu category selection')}
                  onClear={onClearCategory}
                  onChange={onChangeCategory}
                  filterOption={handleFilterOptions}
                  placeholder={t('Select menu category')}
                  aria-describedby={t('Menu category description')}>
                  {categories?.map((item, index) => (
                    <Option key={item?._id || index} value={item?._id}>
                      {item?.name}
                    </Option>
                  ))}
                </Select>
              </>
            )}

            {/* select instances (menu items) */}
            {itemSelected || initialCategoryId ? (
              <Row noGutters>
                <Col cw={12}>
                  <Title level={4} mb={2}>
                    {t('Choose existing menu items')}
                  </Title>
                  <Select
                    mb={4}
                    showSearch
                    mode="multiple"
                    allowClear={true}
                    value={menuItemsSelected}
                    aria-label="Menu item selection"
                    filterOption={handleFilterOptions}
                    onChange={handleMenuItemsSelectChange}
                    placeholder={t('Select existing menu items')}>
                    {usedMenuItems?.map((item, index) => (
                      <Option key={item?._id || index} value={item?._id}>
                        {item?.name?.[language] || item?.name?.en}
                      </Option>
                    ))}
                  </Select>

                  <ButtonAccent
                    disabled={!menuItemsSelected?.length}
                    onClick={handleSelectOptions}>
                    {t('Choose selected options')}
                  </ButtonAccent>

                  <Divider>{t('Or')}</Divider>

                  <Title level={4} mb={2}>
                    {t('Create new menu item for category')}
                  </Title>
                  <ItemTypeInstanceAdvancedForm
                    cw={12}
                    isGoBack={false}
                    initialData={formValue}
                    customCancel={customOnCancel}
                    customSave={customSaveNewMenuItem}
                    toggleAdd={toggleAdd}
                    editMode={editedMenuItemInitialData}
                  />
                </Col>
              </Row>
            ) : (
              <Box display="flex" flexDirection="column">
                <Title level={4} mb={2}>
                  {t(
                    'Please, select item type to choose existing menu item or create new one'
                  )}
                </Title>
                <Box>
                  <ButtonAccent onClick={customOnCancel}>
                    {t('Close')}
                  </ButtonAccent>
                </Box>
              </Box>
            )}
          </>
        ) : null}
      </DrawerStyled>
    </>
  )
}
MenuDrawer.propTypes = {
  value: PropTypes.object,
  initialCategoryId: PropTypes.string
}

export default MenuDrawer
