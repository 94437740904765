import { collection, query, where } from 'firebase/firestore'

import { COLLECTIONS } from '__constants__'
import { firestore } from 'services/firebase'
import { useCollectionData } from 'react-firebase-hooks/firestore'
import { useMemo } from 'react'

const useGetCustomerAdditionalInfo = (customer) => {
  const [adminsOfCurrentCompany] = useCollectionData(
    customer &&
      query(
        collection(firestore, COLLECTIONS.USERS),
        where('_companies', 'array-contains', customer?._id)
      )
  )

  /* Phone number */
  const phoneNumber = useMemo(
    () => customer?.phoneNumber ?? 'n/a',
    [customer?.phoneNumber]
  )

  /* Person name of company */
  const email = useMemo(() => customer?.email ?? 'n/a', [customer?.email])

  const numberOfVenues = useMemo(
    () => customer?.restaurants?.length || 0,
    [customer?.restaurants]
  )

  const numberOfAdmins = useMemo(
    () => adminsOfCurrentCompany?.length || 0,
    [adminsOfCurrentCompany?.length]
  )

  return { phoneNumber, email, numberOfVenues, numberOfAdmins }
}

export default useGetCustomerAdditionalInfo
