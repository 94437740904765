import {
  Box,
  Img,
  Layout,
  LayoutSystemProvider,
  Title
} from '@qonsoll/react-design'

import { ButtonAccent } from 'components'
import IllustrationNotFound from 'assets/Error-01.png'
import { ThemeProvider } from 'styled-components'
import breakpoints from 'styles/breakpoints'
import logoFull from 'assets/logoFull.svg'
import { useHistory } from 'react-router-dom'
import { useTranslations } from 'contexts/Translation'

const NotFound = () => {
  const { t } = useTranslations()
  const history = useHistory()
  const layoutConfig = {}

  const goBack = () => history.goBack()

  return (
    <ThemeProvider theme={breakpoints}>
      <LayoutSystemProvider {...layoutConfig}>
        <Layout>
          <Img
            src={logoFull}
            alt="Pick your pour"
            height={40}
            position="absolute"
            top={16}
            left={24}
          />
          <Box
            height="100%"
            display="flex"
            flexDirection="column"
            alignItems={['stretch', 'center']}
            justifyContent={['stretch', 'center']}>
            <Img
              src={IllustrationNotFound}
              alt="Not found"
              width={['100%', 400, 480]}
              mt={['auto', 0]}
              mb={[3, 4]}
            />
            <Title
              fontFamily="var(--ql-font-family-headings)"
              variant={['h3', 'h1']}
              textAlign="center"
              mb={4}>
              {t('Page not found', 'Oops, Page not found')}
            </Title>
            <Box display="flex" flexDirection={['column', 'row']}>
              <ButtonAccent type="primary" size="large" block onClick={goBack}>
                {t('Go back')}
              </ButtonAccent>
            </Box>
          </Box>
        </Layout>
      </LayoutSystemProvider>
    </ThemeProvider>
  )
}

export default NotFound
