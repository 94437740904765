import { COLLECTIONS } from '__constants__'

const REPLACED_DATA = {
  USER_PROFILE: {
    collectionName: COLLECTIONS.USERS,
    dataBaseField: 'email'
  },
  [COLLECTIONS.PROFILES]: {
    collectionName: COLLECTIONS.CUSTOMERS,
    dataBaseField: 'name'
  },
  [COLLECTIONS.COMPANIES]: {
    dataBaseField: 'name',
    collectionName: 'customers'
  },
  [COLLECTIONS.COCKTAILS]: {
    dataBaseField: 'title',
    collectionName: 'cocktailsNew'
  },
  [COLLECTIONS.TIP_AND_TRICKS]: {
    dataBaseField: 'title',
    collectionName: 'trainingMaterials'
  },

  [COLLECTIONS.RESTAURANTS]: {
    dataBaseField: 'name'
  },
  [COLLECTIONS.ZONES]: { dataBaseField: 'title' },
  [COLLECTIONS.MENUS_DISHES]: { dataBaseField: 'title' },

  [COLLECTIONS.ORDERS]: {
    dataBaseField: 'orderNumber',
    disabled: true
  },
  [COLLECTIONS.COCKTAILS_NEW]: {
    dataBaseField: 'title',
    disabled: true
  },
  [COLLECTIONS.MENUS]: {
    dataBaseField: 'name',
    disabled: true
  },
  [COLLECTIONS.DISHES]: {
    dataBaseField: 'title'
  },
  [COLLECTIONS.DRINKS]: {
    dataBaseField: 'title',
    collectionName: 'cocktailsNew'
  },
  [COLLECTIONS.VENUES]: {
    dataBaseField: 'name',
    collectionName: COLLECTIONS.RESTAURANTS
  },
  [COLLECTIONS.LISTS]: {
    dataBaseField: 'name'
  },
  [COLLECTIONS.ITEM_TYPES]: {
    dataBaseField: 'name'
  },
  [COLLECTIONS.OBJECTS]: {
    dataBaseField: 'name'
  },
  [COLLECTIONS.MENU_ITEMS]: {
    dataBaseField: 'name',
    collectionName: COLLECTIONS.MENU_ITEMS
  },
  [COLLECTIONS.LIST_ITEMS]: {
    dataBaseField: 'name'
  },
  [COLLECTIONS.ENUMS]: {
    dataBaseField: 'name'
  },
  [COLLECTIONS.ENUM_INSTANCES]: {
    dataBaseField: 'name'
  },
  [COLLECTIONS.INTERACTIVE_MENUS]: {
    dataBaseField: 'name'
  }
}

export default REPLACED_DATA
