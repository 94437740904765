const MAX_LENGTH_FIELD = 256
const MAX_LENGTH_DESCRIPTION = 10000
const MAX_LENGTH_CUSTOMER_NAME = 50
const MAX_LENGTH_CONTACT_PERSON_NAME = 50
const MAX_LENGTH_CONTACT_PHONE_NUMBER = 15
const MAX_LENGTH_CONTACT_LICENSE_NUMBER = 12

export {
  MAX_LENGTH_FIELD,
  MAX_LENGTH_DESCRIPTION,
  MAX_LENGTH_CUSTOMER_NAME,
  MAX_LENGTH_CONTACT_PERSON_NAME,
  MAX_LENGTH_CONTACT_PHONE_NUMBER,
  MAX_LENGTH_CONTACT_LICENSE_NUMBER
}
