import { useCallback } from 'react'

const useFormItemName = ({ selectedLanguage }) => {
  return useCallback(
    (parentField, item, index, fieldName) => {
      return (item?.dataType === 'String' || item?.relationType === 'String') &&
        item?.isLocalizationEnabled
        ? [parentField, index, item?.fieldName || fieldName, selectedLanguage]
        : [parentField, index, item?.fieldName || fieldName]
    },
    [selectedLanguage]
  )
}

export default useFormItemName
