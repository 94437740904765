import { CardDropdown, SelectableCard } from 'components'
import { Col, Ribbon, Row, Text, Title } from '@qonsoll/react-design'

import { CONTENT_TYPES_LABELS } from '__constants__'
import { Icon } from '@qonsoll/icons'
import PropTypes from 'prop-types'
import { capitalize } from 'helpers'
import { useGetMaterialTitleAndRibbonColor } from 'domains/TrainingMaterial/hooks'
import { useTrainingMaterialBaseActions } from 'domains/TrainingMaterial/hooks'

const TrainingMaterialSimpleView = (props) => {
  const {
    trainingMaterial,
    index,
    isSelected,
    actions,
    withAvailability = false
  } = props
  const { contentType, title, availability, _id } = trainingMaterial

  // [ADDITIONAL HOOKS]
  const { ribbonColor, materialTitle } =
    useGetMaterialTitleAndRibbonColor(availability)

  /* Getting base actions */
  const { onOpen } = useTrainingMaterialBaseActions({
    tipAndTrickId: _id,
    actions,
    trainingMaterial
  })

  return (
    <CardDropdown
      onClick={onOpen}
      document={trainingMaterial}
      index={index}
      data-testid="trainingMaterialListItem"
      {...actions}
      height="100%">
      {withAvailability && <Ribbon bg={ribbonColor} text={materialTitle} />}
      <SelectableCard
        isSelected={isSelected}
        height="100%"
        style={{ height: '100%' }}>
        <Row negativeBlockMargin v="top" mt={withAvailability && 2}>
          <Col cw="auto">
            <Icon
              size="36px"
              name={
                contentType === CONTENT_TYPES_LABELS.VIDEO
                  ? 'VideoRecorderOutlined'
                  : 'FileAttachment3Outlined'
              }
              fill="var(--ql-typography-text-color-primary)"
            />
          </Col>
          <Col>
            <Title level={4}>{title}</Title>
            {/* capitalize type label: Video or Presentation */}
            <Text>{capitalize(contentType)}</Text>
          </Col>
        </Row>
      </SelectableCard>
    </CardDropdown>
  )
}

TrainingMaterialSimpleView.propTypes = {
  trainingMaterial: PropTypes.object,
  onSelect: PropTypes.func,
  index: PropTypes.number,
  isSelected: PropTypes.bool,
  withAvailability: PropTypes.bool,
  actions: PropTypes.object
}

export default TrainingMaterialSimpleView
