import { useEffect, useState, useCallback } from 'react'
import { getDocument } from 'services/api/firebase'
import { COLLECTIONS } from '__constants__'

/**
 * It fetches a restaurants documents from Firestore and returns it
 * @param customer - The customer object that we're getting the restaurants for.
 * @returns An array with two values. The first value is the restaurants object, and the second value is a
 * boolean that indicates whether the restaurants is loading.
 */
const useGetCustomerRestaurants = (customer) => {
  /* State for data */
  const [restaurants, setRestaurants] = useState([])
  /* State for loading */
  const [loading, setLoading] = useState(!!customer?.restaurants?.length)
  /* State for error */
  const [error, setError] = useState(null)

  /* Fetching data */
  const getRestaurants = useCallback(async () => {
    try {
      /* Setting loading state to true */
      setLoading(true)

      /* Getting restaurants promises*/
      const promises = customer.restaurants.map((restaurant) =>
        getDocument(COLLECTIONS.RESTAURANTS, restaurant)
      )

      /* Getting restaurants data */
      const data = await Promise.all(promises)

      /* Setting restaurants data */
      setRestaurants(data)

      /* Setting loading state to false */
    } catch (error) {
      setError(error)
    }
    setLoading(false)
  }, [customer?.restaurants])

  useEffect(() => {
    customer?.restaurants?.length && getRestaurants()
  }, [customer?.restaurants, getRestaurants])

  return [restaurants, loading, error, getRestaurants]
}

export default useGetCustomerRestaurants
