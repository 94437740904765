import { ButtonAccent, CheckboxFormItem } from 'components'
import { Form, Input } from 'antd'
import { KEYS, MAX_LENGTH_FIELD } from '__constants__'

import PropTypes from 'prop-types'
import { Title } from '@qonsoll/react-design'
import { useTranslations } from 'contexts/Translation'
import { useValidateInput } from 'hooks'

const ZoneSimpleForm = (props) => {
  const { initialValues, onSubmit, onCancel, showTitle, form, group } = props
  /* Getting translations instance */
  const { t } = useTranslations()
  /* Getting new form instance from Form.useForm hook */
  const [baseForm] = Form.useForm()
  /* A custom hook that is used to validate input fields. */
  const validateInput = useValidateInput()
  /* Getting used form from props or using base form */
  const usedForm = form || baseForm
  /* Resetting form on cancel */
  const handleCancel = () => {
    usedForm.resetFields()
    onCancel?.()
  }
  /* Submitting form on enter key press */
  const handleKeyPress = (e) => {
    if (e.key === KEYS.ENTER) {
      e.preventDefault()
      e.stopPropagation()
      usedForm?.submit()
    }
  }

  return group ? (
    <>
      {showTitle && (
        <Title level={4} mb={2}>
          {t('Zone')}
        </Title>
      )}
      <Form.Item
        label={t('Name')}
        name={[...group, 'title']}
        rules={[
          {
            required: true,
            message: t('Please enter your zone title!')
          },
          { max: MAX_LENGTH_FIELD, message: t('zone title is too long!') },
          { validator: validateInput }
        ]}>
        <Input placeholder={t('Please enter your zone title')} />
      </Form.Item>
      <Form.Item name={[...group, 'available']}>
        <CheckboxFormItem
          title={t('Availability')}
          description={t('Determine whether or not the zone is available')}
        />
      </Form.Item>
    </>
  ) : (
    <Form
      scrollToFirstError
      form={usedForm}
      initialValues={initialValues}
      onFinish={onSubmit}
      layout="vertical"
      onKeyPress={handleKeyPress}
      autoComplete="off">
      {showTitle && (
        <Title level={4} mb={2}>
          {t('Zone')}
        </Title>
      )}
      <Form.Item
        label={t('Name')}
        name="title"
        rules={[
          {
            required: true,
            message: t('Please, enter your zone name!')
          },
          { max: MAX_LENGTH_FIELD, message: t('zone name is too long!') },
          { validator: validateInput }
        ]}>
        <Input placeholder={t('Please, enter your zone name')} />
      </Form.Item>
      <Form.Item name={[...group, 'available']}>
        <CheckboxFormItem
          title={t('Availability')}
          description={t('Determine whether or not the zone is available')}
        />
      </Form.Item>
      {!form && (
        <>
          <ButtonAccent
            mr={2}
            htmlType="button"
            onClick={handleCancel}
            type="secondary">
            {t('Cancel')}
          </ButtonAccent>
          <ButtonAccent type="primary" onClick={() => usedForm.submit()}>
            {t('Save')}
          </ButtonAccent>
        </>
      )}
    </Form>
  )
}
ZoneSimpleForm.propTypes = {
  initialValues: PropTypes.object,
  onSubmit: PropTypes.func,
  onCancel: PropTypes.func,
  showTitle: PropTypes.bool,
  form: PropTypes.any,
  group: PropTypes.array
}

export default ZoneSimpleForm
