const styles = {
  fieldTitleProps: {
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap'
  },
  cardSelect: {
    height: '100%',
    cursor: 'default'
  }
}

export default styles
