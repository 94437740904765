const ALCOHOL_AMOUNTS = {
  HIGH_ALCOHOL: 'High alcohol',
  LOW_ALCOHOL: 'Low alcohol',
  NO_ALCOHOL: 'No alcohol'
}

const ALCOHOL_AMOUNTS_OPTIONS = Object.keys(ALCOHOL_AMOUNTS).map((key) => ({
  label: ALCOHOL_AMOUNTS[key],
  value: key
}))

export default ALCOHOL_AMOUNTS
export { ALCOHOL_AMOUNTS_OPTIONS, ALCOHOL_AMOUNTS }
