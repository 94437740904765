import { Box } from '@qonsoll/react-design'
import {
  COCKTAIL_AVAILABILITIES_OPTIONS,
  COCKTAIL_AVAILABILITIES_KEYS
} from '__constants__/enums'
import { EnumSelect } from 'components'
import PropTypes from 'prop-types'
import { useEffect, useState } from 'react'

const CocktailAvailabilityColumn = (props) => {
  const { cocktailId, onSelect, availability } = props

  const [currentAvailability, setCurrentAvailability] = useState(0)

  useEffect(() => {
    setCurrentAvailability(availability ?? COCKTAIL_AVAILABILITIES_KEYS.NO)
  }, [availability, setCurrentAvailability])

  const updateAvailability = (value) => {
    setCurrentAvailability(value)
    onSelect(cocktailId, { availability: value })
  }

  return (
    <Box minWidth="260px">
      <EnumSelect
        options={COCKTAIL_AVAILABILITIES_OPTIONS}
        onChange={updateAvailability}
        value={currentAvailability}
      />
    </Box>
  )
}

CocktailAvailabilityColumn.propTypes = {
  cocktailId: PropTypes.string,
  onSelect: PropTypes.func,
  availability: PropTypes.string
}

export default CocktailAvailabilityColumn
