import { useMemo } from 'react'
import { useHistory, useParams } from 'react-router-dom'
import { PageWrapper } from '@qonsoll/react-design'

import { COLLECTIONS } from '__constants__'
import { useTranslations } from 'contexts/Translation'
import { useGetDocumentsByIds, useLoading } from 'hooks'
import { HeaderBreadcrumbs, PageActions, Spinner } from 'components'

import {
  useGetItemTypeViewData,
  useItemTypesBaseActions
} from 'domains/ItemType/hooks'

import { MenuItemList } from 'domains/MenuItem/components'
import { useMenuItemBaseActions } from 'domains/MenuItem/hooks'

const ItemTypeShow = () => {
  /* Getting translations instance */
  const { t } = useTranslations()
  /* Getting params from router */
  const { itemTypeId } = useParams()
  const history = useHistory()

  // eslint-disable-next-line no-unused-vars
  const [itemType, itemTypeLoading, existingItemTypesReferences] =
    useGetItemTypeViewData(itemTypeId)

  const menuItemsParams = useMemo(
    () => ({
      collectionName: COLLECTIONS.MENU_ITEMS,
      entityIds: Object.keys(itemType?.menuItems || {})
    }),
    [itemType?.menuItems]
  )

  const [menuItems, menuItemsLoading] = useGetDocumentsByIds(menuItemsParams)

  const menuItemsSorted = useMemo(() => {
    return (
      menuItems?.sort((a, b) => {
        if (a?._createdAt?.seconds > b?._createdAt?.seconds) {
          return -1
        }
        if (a?._createdAt?.seconds < b?._createdAt?.seconds) {
          return 1
        }
        return 0
      }) ?? []
    )
  }, [menuItems])

  const onBack = () => {
    history.goBack()
  }

  const {
    onEdit,
    onDelete,
    loading: actionsLoading
  } = useItemTypesBaseActions({
    itemTypeId,
    itemType,
    existingItemTypesReferences
  })
  const { onCreate } = useMenuItemBaseActions()

  const headingProps = {
    title: itemType?.name?.en ?? itemType?.name?.no ?? itemType?.name,
    marginBottom: 3,
    textAlign: 'left'
  }
  const actions = {
    create: false,
    edit: false,
    delete: false
  }

  const loading = useLoading([itemTypeLoading, menuItemsLoading])

  return (
    <PageWrapper
      onBack={onBack}
      breadcrumbs={<HeaderBreadcrumbs />}
      headingProps={headingProps}
      action={
        <PageActions
          actions={actions}
          onDelete={onDelete}
          document={itemType}
          onCreate={onCreate}
          onEdit={onEdit}
          deleteBtnProps={{ withoutLabel: true, loading: actionsLoading }}
          createText={t('Create menu item')}
          editText={t('Edit')}
        />
      }>
      {loading ? (
        <Spinner height="70vh" text={t('List items are loading')} />
      ) : (
        <MenuItemList
          actions={{
            onCreate
          }}
          isListWithCreate
          menuItems={menuItemsSorted}
        />
      )}
    </PageWrapper>
  )
}

export default ItemTypeShow
