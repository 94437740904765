import { Box, Col, Container, Row, Text, Title } from '@qonsoll/react-design'
import { ButtonAccent, Table, Spinner } from 'components'
import { COCKTAIL_AVAILABILITIES_KEYS } from '__constants__'
import { Icon } from '@qonsoll/icons'
import { MenuCategoryFormDrawer } from 'domains/MenuCategory/components'
import { MenuDragableList, MenuDrawer } from 'domains/Menu/components'
import PropTypes from 'prop-types'
import { useCocktailsTableActions } from 'domains/Cocktail/hooks'
import { useMenuItemsTableConfig } from './hooks'
import { useTranslations } from 'contexts/Translation'
import { useExistingMenuActions } from 'domains/Menu/hooks'

const MenuAdvancedTable = (props) => {
  const {
    value = [],
    onChange,
    menuShow,
    menu,
    numberOfAvailableItems,
    onCreateCocktailCallback,
    onSelectCocktailsCallback,
    onCategoryCreateCallback,
    onCocktailDeleteCallback,
    onCocktailEditCallback,
    onDragAndDrop,
    additionalActions,
    onCategoryEdit,
    onCategoryDelete,
    isNewMenu,
    isDragAndDropDisabled,
    search,
    categoryId
  } = props
  /* Getting translation instance */
  const { t } = useTranslations()

  const [tableProps, loading] = useMenuItemsTableConfig(categoryId)

  const {
    isAdding,
    categoryDrawerVisibility,
    toggleAdd,
    toggleCategoryForm,
    onCocktailDelete,
    onEditClick,
    onResetInitialData,
    onEditCocktailPrice,
    categoryUpdateInNewMenu,
    categoryDeleteInNewMenu,
    onEditCocktailAvailability,
    editedMenuItemInitialData
  } = useCocktailsTableActions({
    value,
    onChange,
    onSelectCocktailsCallback,
    onCreateCocktailCallback,
    onCocktailDeleteCallback,
    onCocktailEditCallback,
    categoryId
  })

  const customOnCancel = () => {
    onResetInitialData()
    toggleAdd()
  }

  const {
    menuItemsSelected,
    handleMenuItemsSelectChange,
    handleSelectOptions,
    onCreateNewMenuItem
  } = useExistingMenuActions(categoryId, value, toggleAdd)

  const fullWidthTable = value?.length || menuShow
  const numberOfAvailableItemsComputed = isNewMenu
    ? value?.reduce(
        (sum, item) =>
          item?.availability !== COCKTAIL_AVAILABILITIES_KEYS.NO
            ? sum + 1
            : sum,
        0
      )
    : numberOfAvailableItems

  return (
    <Container>
      {loading ? (
        <Row>
          <Col>
            <Spinner height="60vh" text={t('Menu table is loading')} />
          </Col>
        </Row>
      ) : (
        <>
          <Row
            noGutters
            width="inherit"
            h={fullWidthTable ? 'between' : 'left'}
            mb={2}>
            <Col
              cw={fullWidthTable && !search && 'auto'}
              flexDirection="row"
              alignItems="center"
              justifyContent="space-between">
              <Box display="flex" flexDirection="column" width="100%">
                {search ? (
                  <Box mr={2}>{search}</Box>
                ) : (
                  <Title level={4}>{t('List of drinks')}</Title>
                )}
                {!value?.length && (
                  <Box display="flex" flexDirection="column" mt={4}>
                    <Text fontSize={16}>{`${t(
                      'There are no drinks on your menu yet'
                    )}.`}</Text>
                    <Text fontSize={16}>{`${t(
                      'Add at least one item to the menu'
                    )}.`}</Text>
                  </Box>
                )}
              </Box>
            </Col>
            <Col
              cw={fullWidthTable ? [12, 12, 'auto'] : 12}
              my={menuShow ? [2, 2, 0] : 3}
              flexDirection="row"
              flexWrap="wrap">
              {additionalActions && <Box mr={2}>{additionalActions}</Box>}
              <ButtonAccent
                onClick={toggleAdd}
                type="secondary"
                icon={<Icon name="Plus1Outlined" />}>
                {t('Add new item')}
              </ButtonAccent>
            </Col>
          </Row>
          <MenuDrawer
            value={{
              isAdding,
              customOnCancel,
              menuItemsSelected,
              editedMenuItemInitialData,
              handleMenuItemsSelectChange,
              handleSelectOptions,
              customSaveNewMenuItem: onCreateNewMenuItem,
              toggleAdd
            }}
            initialCategoryId={categoryId}
          />

          {!!value?.length && (
            <Table
              itemsInfo={{
                component: <MenuDragableList categoryId={categoryId} />,
                onChange: onChange,
                numberOfAvailableItems: numberOfAvailableItemsComputed,
                tableProps: tableProps,
                onUpdate: onChange ? onChange : onDragAndDrop,
                deleteCocktail: onCocktailDelete,
                editCocktail: onEditClick,
                onPriceChange: onEditCocktailPrice,
                categoryVisibility: categoryDrawerVisibility,
                changeVisibility: toggleCategoryForm,
                onCategoryEdit: isNewMenu
                  ? categoryUpdateInNewMenu
                  : onCategoryEdit,
                onCategoryDelete: isNewMenu
                  ? categoryDeleteInNewMenu
                  : onCategoryDelete,
                onEditCocktailAvailability: onEditCocktailAvailability,
                isDragAndDropDisabled
              }}
            />
          )}
          <MenuCategoryFormDrawer
            value={value}
            onChange={onChange}
            onCategoryCreateCallback={onCategoryCreateCallback}
            menu={menu}
            visibility={categoryDrawerVisibility}
            changeVisibility={toggleCategoryForm}
          />
        </>
      )}
    </Container>
  )
}
MenuAdvancedTable.propTypes = {
  value: PropTypes.arrayOf(PropTypes.object),
  onChange: PropTypes.func,
  menuShow: PropTypes.bool,
  menu: PropTypes.object,
  onDishDelete: PropTypes.func,
  isCreate: PropTypes.bool,
  onCreateCocktailCallback: PropTypes.func,
  onSelectCocktailsCallback: PropTypes.func,
  onCategoryCreateCallback: PropTypes.func,
  onCocktailDeleteCallback: PropTypes.func,
  onCocktailEditCallback: PropTypes.func,
  onDragAndDrop: PropTypes.func,
  additionalActions: PropTypes.node,
  onCategoryEdit: PropTypes.func,
  onCategoryDelete: PropTypes.func,
  isNewMenu: PropTypes.bool,
  numberOfAvailableItems: PropTypes.number,
  isDragAndDropDisabled: PropTypes.bool,
  search: PropTypes.node,
  categoryId: PropTypes.string,
  getMenuItems: PropTypes.func
}

export default MenuAdvancedTable
