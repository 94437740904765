import { Col, PageWrapper, Row } from '@qonsoll/react-design'

import { HeaderBreadcrumbs } from 'components'
import { TipAndTrickAdvancedForm } from 'domains/TipAndTrick/components'
import { useHistory } from 'react-router-dom'
import { useTranslations } from 'contexts/Translation'

const TipAndTrickCreatePage = (props) => {
  /* Getting translations instance */
  const { t } = useTranslations()
  /* Getting history instance */
  const history = useHistory()

  /* Configuring page header */
  const headingProps = {
    title: t('Tip and trick create'),
    titleSize: 3,
    marginBottom: 3,
    textAlign: 'left'
  }

  /* Function to handle back click */
  const handleBack = () => history.goBack()

  return (
    <PageWrapper
      onBack={handleBack}
      breadcrumbs={<HeaderBreadcrumbs />}
      headingProps={headingProps}>
      <Row noGutters>
        <Col cw={[12, 12, 8, 6]}>
          <TipAndTrickAdvancedForm />
        </Col>
      </Row>
    </PageWrapper>
  )
}

export default TipAndTrickCreatePage
