import { generatePath, useHistory } from 'react-router-dom'

import { COLLECTIONS } from '__constants__'
import PATHS from 'pages/paths'
import { deleteDocument, updateDocument } from 'services/api/firebase'
import { message } from 'antd'
import { useEvent } from 'hooks'
import { useMemo } from 'react'
import { useTranslations } from 'contexts/Translation'

/* Path's */
const createPath = PATHS.AUTHENTICATED.ITEM_TYPE_CREATE
const editPath = PATHS.AUTHENTICATED.ITEM_TYPE_EDIT
const showPath = PATHS.AUTHENTICATED.ITEM_TYPE_SHOW

const useItemTypesBaseActions = ({ itemTypeId, actions } = {}) => {
  /* Getting translations instance */
  const { t } = useTranslations()
  /* Getting history instance */
  const history = useHistory()

  /* Function to handle back click */
  const handleBack = useEvent(() => history.goBack())

  /* Handling opening of the create page */
  const handleCreate = useEvent(() => {
    history.push(createPath)
  })

  /* Handling opening of the show page */
  const handleOpen = useEvent(() => {
    const pathParams = { itemTypeId }
    const path = generatePath(showPath, pathParams)
    history.push(path)
  })

  /* Handling opening of the edit page or change view to edit mode */
  const handleEdit = useEvent(() => {
    const pathParams = { itemTypeId }
    const path = generatePath(editPath, pathParams)
    history.push(path)
  })

  const handleDelete = useEvent(async () => {
    try {
      await deleteDocument(COLLECTIONS.ITEM_TYPES, itemTypeId)
      message.success(t('Item type successfully deleted'))
    } catch (error) {
      message.error(error.message)
    }
  })

  const setDefaultInteractiveMenu = useEvent(async (interactiveMenuId) => {
    try {
      if (interactiveMenuId) {
        await updateDocument(COLLECTIONS.ITEM_TYPES, itemTypeId, {
          defaultInteractiveMenu: interactiveMenuId
        })

        message.success(t('Default interactive menu successfully updated'))
      }
    } catch (error) {
      message.error(
        `${t(
          'Something went wrong during setting default interactive menu'
        )}, ${error.message}`
      )
    }
  })

  return useMemo(
    () => ({
      onBack: handleBack,
      onCreate: handleCreate,
      onOpen: handleOpen,
      onEdit: handleEdit,
      onDelete: handleDelete,
      setDefaultInteractiveMenu,
      ...(actions || {}),
      createPath,
      editPath,
      showPath
    }),
    [
      actions,
      handleBack,
      handleCreate,
      handleDelete,
      handleEdit,
      handleOpen,
      setDefaultInteractiveMenu
    ]
  )
}

export default useItemTypesBaseActions
