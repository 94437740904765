import { useCallback, useMemo, useState } from 'react'
import moment from 'moment'
import firebase from 'firebase/compat/app'
import { useKeyPress } from '@umijs/hooks'
import { useLoadScript } from '@react-google-maps/api'

import { useFetchCountries } from 'hooks'
import { useTranslations } from 'contexts/Translation'
import { useUserContext } from 'contexts/UserContext'

import { useGetAddressInfo } from 'domains/Address/hooks'
import { useActionsCustomerAdvancedForm } from 'domains/Customer/components/CustomerAdvancedForm/hooks'
import {
  useCreateCustomerCallback,
  useCustomerWizardSteps
} from 'domains/Customer/hooks'

const REACT_APP_GOOGLE_MAP_API_KEY = process.env.REACT_APP_GOOGLE_MAP_API_KEY

const useCustomerWizard = (onFinishCallback) => {
  const { listCountries, listSortingCountries } = useFetchCountries()
  const onCustomerCreateCallback = useCreateCustomerCallback()
  const { user } = useUserContext()

  /* State for current step in wizard */
  const [stepNumber, setStepNumber] = useState(0)
  const [loading, setLoading] = useState(false)
  const initialValues = {
    licenceValidity: {
      from: firebase.firestore.Timestamp.fromDate(moment().toDate()),
      to: firebase.firestore.Timestamp.fromDate(
        moment().add(1, 'years').toDate()
      )
    },
    contactPerson: `${user?.lastName ? user?.lastName + ' ' : ''}${
      user?.firstName ? user?.firstName : ''
    }`,
    email: user?.email
  }

  const { isLoaded } = useLoadScript({
    googleMapsApiKey: REACT_APP_GOOGLE_MAP_API_KEY,
    libraries: ['places'],
    language: 'en'
  })

  /* State for saving wizard data */
  const [value, setValue] = useState(initialValues)

  /* Getting translations instance */
  const { t } = useTranslations()
  /* Getting wizard steps */
  const { companySteps, venueSteps } = useCustomerWizardSteps()

  const steps = value?.variantType === 'COMPANY' ? companySteps : venueSteps

  /* Getting saveForm action */
  const { saveForm } = useActionsCustomerAdvancedForm({
    createCallback: onCustomerCreateCallback
  })

  const getAddressInfo = useGetAddressInfo()

  /* On finish callback with saving data */
  const onFinish = useCallback(() => {
    // Avoid multiple clicks
    if (loading) return

    setLoading(true)

    const callback = (data) => onFinishCallback?.(data)

    saveForm({ customer: value }, callback)
  }, [loading, onFinishCallback, saveForm, value])
  /* On next button click callback */
  const onNextButtonClick = useCallback(
    () => (stepNumber === steps.length - 1 ? onFinish() : onNextStep()),
    [onFinish, stepNumber, steps.length]
  )

  /* Used to show or hide back button */
  const isBackButtonVisible = useMemo(() => stepNumber !== 0, [stepNumber])
  /* Getting text for next button based on current step */
  const nextButtonText = useMemo(() => {
    const stepsLeftNumber = steps.length - (stepNumber + 1)

    return stepNumber === steps.length - 1
      ? t('Finish')
      : `${t('Next step')} (${stepsLeftNumber} left)`
  }, [stepNumber, steps.length, t])

  /* Getting current step */
  const currentStep = useMemo(() => steps[stepNumber], [stepNumber, steps])
  /* Getting current step component */
  const currentStepComponent = useMemo(
    () =>
      currentStep?.component?.({
        value,
        setValue,
        stepNumber: stepNumber + 1,
        listCountries,
        listSortingCountries,
        getAddressInfo,
        isLoaded
        // contactPerson: `${userData?.lastName} ${userData?.firstName}`
      }),
    [
      currentStep,
      value,
      stepNumber,
      listCountries,
      listSortingCountries,
      getAddressInfo,
      isLoaded
    ]
  )

  /* Getting current step value */
  const currentStepValue = useMemo(
    () => value?.[currentStep?.name],
    [currentStep, value]
  )

  /* Setting previous step */
  const onPrevStep = () => setStepNumber((prevStep) => prevStep - 1)
  /* Setting next step */
  const onNextStep = () => setStepNumber((prevStep) => prevStep + 1)

  /* Handling enter key press */
  useKeyPress(
    'enter',
    () =>
      !!currentStepValue &&
      currentStep?.name !== 'description' &&
      onNextButtonClick()
  )
  /* Handling backspace key press */
  useKeyPress('esc', () => isBackButtonVisible && onPrevStep())

  return {
    // variables
    currentStepComponent,
    isBackButtonVisible,
    currentStepValue,
    nextButtonText,
    // methods
    onPrevStep,
    onNextButtonClick,
    // loadings
    loading
  }
}
export default useCustomerWizard
