import { Col, PageWrapper, Row } from '@qonsoll/react-design'
import {
  CustomerAdvancedView,
  CustomerWizard
} from 'domains/Customer/components'
import { HeaderBreadcrumbs, Spinner } from 'components'
import { useHistory, useParams } from 'react-router-dom'

import PATHS from 'pages/paths'
import { PageActions } from 'components'
import { generatePath } from 'react-router-dom'
import { useCustomerContext } from 'contexts/CustomerContext'
import { useTranslations } from 'contexts/Translation'

const CustomerShowPage = (props) => {
  /* Getting translations instance */
  const { t } = useTranslations()
  /* Getting history instance */
  const history = useHistory()
  /* Getting params from router */
  const params = useParams()

  const { customer, loading, onCurrentCustomerChange } = useCustomerContext()

  /* Configuring page header */
  const headingProps = {
    title: customer?.name || t('Customer show'),
    titleSize: 3,
    marginBottom: 3,
    textAlign: 'left'
  }

  /* Getting id from params */
  const { companyId } = params

  /* Configuring page actions */
  const actions = { edit: true }

  /* Function to handle back click */
  const handleBack = () => history.goBack()
  /* Handling opening of the edit page */
  const handleEdit = () => {
    const pathParams = { companyId }
    const path = generatePath(PATHS.AUTHENTICATED.COMPANY_EDIT, pathParams)
    history.push(path)
  }

  return (
    <PageWrapper
      action={<PageActions actions={actions} onEdit={handleEdit} />}
      onBack={handleBack}
      breadcrumbs={<HeaderBreadcrumbs />}
      headingProps={headingProps}>
      <Row noGutters h="center">
        {loading ? (
          <Col>
            <Spinner height="70vh" text={t('Customer loading')} />
          </Col>
        ) : !customer ? (
          <Col cw={[12, 12, 10, 8, 7]}>
            <CustomerWizard
              onFinish={(customer) => onCurrentCustomerChange(customer?._id)}
            />
          </Col>
        ) : (
          <Col cw={12}>
            <CustomerAdvancedView customer={customer} />
          </Col>
        )}
      </Row>
    </PageWrapper>
  )
}

export default CustomerShowPage
