import * as yup from 'yup'

import { attr, hasOne, model } from 'services/model'

const validationSchema = yup.object().shape({
  menu: yup
    .string()
    .typeError('Field menu has wrong type')
    .default(null)
    .nullable(),
  table: yup.string().typeError('Field table has wrong type'),
  restaurant: yup
    .string()
    .typeError('Field restaurant has wrong type')
    .default(null)
    .nullable()
})

const QRCode = model(
  'qRCode',
  {
    menu: hasOne('menu'),
    table: attr('string'),
    source: attr('string'),
    restaurant: hasOne('restaurant')
  },
  validationSchema
)

export default QRCode
