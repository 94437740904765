import { Box, Col, Row } from '@qonsoll/react-design'

import PropTypes from 'prop-types'
import { forwardRef } from 'react'
import styled from 'styled-components'

const ListHeader = styled(Box)`
  border-bottom: var(--border-width-default) var(--border-style-default)
    var(--ql-color-dark-t-lighten5);
  background: var(--table-header-bg);
  padding: 16px;
  border-radius: var(--ql-border-radius-default) var(--ql-border-radius-default)
    0 0;
`
const ItemWrapper = styled(Box)`
  width: 100%;
  user-select: none;
  border-bottom: var(--border-width-default) var(--border-style-default)
    var(--ql-color-dark-t-lighten5);

  &:hover {
    background: var(--table-row-hover-bg);
  }
`

const ScrollableCol = styled(Col)`
  overflow-x: scroll;

  &::-webkit-scrollbar {
    width: 0.1rem;
  }
  &::-webkit-scrollbar-thumb {
    background: transparent;
  }
`

const staticColStyle = {
  padding: '16px !important',
  alignItems: 'flex-start'
}

const imageStyle = {
  width: '48px',
  height: '48px',
  objectFit: 'cover',
  borderRadius: '8px',
  mr: '16px'
}

// eslint-disable-next-line react/display-name
const ScrollableRow = forwardRef((props, ref) => (
  <Row {...props}>
    {/* styled component is not suited for passing refs, so div is used here */}
    <div ref={ref} style={{ display: 'flex', width: '100%' }}>
      {props?.children}
    </div>
  </Row>
))

ScrollableRow.propTypes = {
  children: PropTypes.node
}

export {
  ItemWrapper,
  staticColStyle,
  imageStyle,
  ListHeader,
  ScrollableCol,
  ScrollableRow
}
