import { Box, Col, Container, Row } from '@qonsoll/react-design'

import PropTypes from 'prop-types'
import { useShowDataTypes } from 'hooks'

const MenuItemAdvancedView = (props) => {
  const { value } = props

  const { renderField } = useShowDataTypes()

  return (
    <Container>
      <Row noGutters>
        <Col cw={6} height="100%">
          {value?.map((data, index) => {
            return (
              <Box mb={2} key={`${data?._id}-${index}`}>
                {renderField(data)}
              </Box>
            )
          })}
        </Col>
      </Row>
    </Container>
  )
}
MenuItemAdvancedView.propTypes = {
  menuItem: PropTypes.object,
  value: PropTypes.array
}

export default MenuItemAdvancedView
