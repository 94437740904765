import { useTranslations } from '@qonsoll/translation'
import { COLLECTIONS } from '__constants__'
import { message } from 'antd'
import {
  collection,
  doc,
  serverTimestamp,
  setDoc,
  deleteDoc
} from 'firebase/firestore'
import { useState } from 'react'
import { useHistory } from 'react-router-dom'
import { updateDocument, getDocument } from 'services/api/firebase'
import { firestore } from 'services/firebase'
import differenceIds from 'modules/admin-module/helpers/differenceIds'

const useActionsInteractiveMenuAdvancedForm = (initialData) => {
  const [loading, setLoading] = useState(false)

  const history = useHistory()
  const { t } = useTranslations()

  const prepareValues = async (values) => {
    const interactiveMenuId =
      initialData?._id ??
      initialData?.interactiveMenuData?._id ??
      doc(collection(firestore, COLLECTIONS.INTERACTIVE_MENUS)).id

    const transformedQuestions = Object.values(values?.questions || {})
      .sort((a, b) => a?.order - b?.order)
      .map((question) => {
        const _id =
          question?._id ||
          doc(collection(firestore, COLLECTIONS.INTERACTIVE_MENU_QUESTIONS)).id

        delete question?.uid
        delete question?.order
        delete question?.optionSourceData

        const questionTime = question?._id ? '_updatedAt' : '_createdAt'

        return {
          _id,
          interactiveMenuId,
          [questionTime]: serverTimestamp(),
          ...question
        }
      })

    const [removedIds] = differenceIds(
      transformedQuestions,
      initialData?.interactiveMenuData?.questions
    )

    removedIds?.forEach((id) => {
      deleteDoc(doc(firestore, COLLECTIONS.INTERACTIVE_MENU_QUESTIONS, id))
    })

    transformedQuestions?.forEach(async (question) => {
      await setDoc(
        doc(firestore, COLLECTIONS.INTERACTIVE_MENU_QUESTIONS, question?._id),
        question
      )
    })

    const questionsIds = transformedQuestions?.map((question) => question?._id)

    const serviceFieldName = initialData ? '_updatedAt' : '_createdAt'

    return {
      ...values,
      _id: interactiveMenuId,
      [serviceFieldName]: serverTimestamp(),
      questions: questionsIds
    }
  }

  const onFinish = async (values) => {
    try {
      setLoading(true)
      const preparedValues = await prepareValues(values)

      if (initialData) {
        await updateDocument(
          COLLECTIONS.INTERACTIVE_MENUS,
          initialData?.interactiveMenuData?._id,
          preparedValues
        )
        message.success(t('Interactive menu was successfully updated'))
      } else {
        await setDoc(
          doc(
            collection(firestore, COLLECTIONS.INTERACTIVE_MENUS),
            preparedValues?._id
          ),
          preparedValues
        )

        const itemTypeData = await getDocument(
          COLLECTIONS.ITEM_TYPES,
          preparedValues?.itemType
        )

        if (!itemTypeData?.defaultInteractiveMenu) {
          await updateDocument(COLLECTIONS.ITEM_TYPES, itemTypeData?._id, {
            defaultInteractiveMenu: preparedValues?._id
          })
        }

        message.success(t('Interactive menu successfully created'))
      }

      history.goBack()
    } catch (error) {
      message.error(
        `${t('Something went wrong during saving interactive menu')}, ${
          error?.message
        }`
      )
    } finally {
      setLoading(false)
    }
  }

  const onReset = () => {
    history.goBack()
  }

  return { loading, onFinish, onReset }
}

export default useActionsInteractiveMenuAdvancedForm
