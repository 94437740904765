import { Box, Img, Text, Title } from '@qonsoll/react-design'

import Empty from 'assets/empty.svg'
import { useTranslations } from 'contexts/Translation'

const EmptySearch = () => {
  const { t } = useTranslations()
  return (
    <Box
      width="100%"
      display="flex"
      flexDirection="column"
      alignItems={['stretch', 'center']}>
      <Img
        width={['100%', 200, 200]}
        mt={['auto', 0]}
        mb={[3, 4]}
        src={Empty}
        alt="Empty search"
      />
      <Title
        fontFamily="var(--ql-font-family-headings)"
        variant={['h4', 'h2']}
        textAlign="center"
        mb={[2, 3]}>
        {t('Sorry but there are no matching results')}.
      </Title>
      <Text textAlign="center">{t('Try searching for something else')}.</Text>
    </Box>
  )
}

export default EmptySearch
