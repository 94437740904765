import { useEffect, useState, useCallback } from 'react'
import { getDocument } from 'services/api/firebase'
import { COLLECTIONS } from '__constants__'

/**
 * It fetches a measurementTypes documents from Firestore and returns it
 * @param cocktail - The cocktail object that we're getting the measurementTypes for.
 * @returns An array with two values. The first value is the measurementTypes object, and the second value is a
 * boolean that indicates whether the measurementTypes is loading.
 */
const useGetCocktailMeasurementTypes = (cocktail) => {
  /* State for data */
  const [measurementTypes, setMeasurementTypes] = useState([])
  /* State for loading */
  const [loading, setLoading] = useState(!!cocktail?.measurementTypes?.length)
  /* State for error */
  const [error, setError] = useState(null)

  /* Fetching data */
  const getMeasurementTypes = useCallback(async () => {
    try {
      /* Setting loading state to true */
      setLoading(true)

      /* Getting measurementTypes promises*/
      const promises = cocktail.measurementTypes.map((measurementType) =>
        getDocument(COLLECTIONS.MEASUREMENT_TYPES, measurementType)
      )

      /* Getting measurementTypes data */
      const data = await Promise.all(promises)

      /* Setting measurementTypes data */
      setMeasurementTypes(data)

      /* Setting loading state to false */
    } catch (error) {
      setError(error)
    }
    setLoading(false)
  }, [cocktail?.measurementTypes])

  useEffect(() => {
    cocktail?.measurementTypes?.length && getMeasurementTypes()
  }, [cocktail?.measurementTypes, getMeasurementTypes])

  return [measurementTypes, loading, error, getMeasurementTypes]
}

export default useGetCocktailMeasurementTypes
