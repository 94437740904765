import { useTranslations } from '@qonsoll/translation'
import { useHistory } from 'react-router-dom'
import { Col, PageWrapper, Row } from '@qonsoll/react-design'
import { HeaderBreadcrumbs } from 'components'
import { InteractiveMenuAdvancedForm } from 'domains/InteractiveMenu/components'

const InteractiveMenuCreate = () => {
  const { t } = useTranslations()
  const history = useHistory()

  const headingProps = {
    title: t('Create new interactive menus'),
    marginBottom: 3,
    textAlign: 'left'
  }

  const onBack = () => {
    history.goBack()
  }

  return (
    <PageWrapper
      onBack={onBack}
      breadcrumbs={<HeaderBreadcrumbs />}
      headingProps={headingProps}>
      <Row noGutters>
        <Col cw={[12, 12, 8, 6]}>
          <InteractiveMenuAdvancedForm />
        </Col>
      </Row>
    </PageWrapper>
  )
}

export default InteractiveMenuCreate
