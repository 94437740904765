import { useMemo } from 'react'
import { useLoading } from 'hooks'
import { useDocument } from 'services/api/firebase'
import { COLLECTIONS } from '__constants__'
import { useGetCustomerRestaurants } from 'domains/Customer/hooks'

const useGetCustomerInitialValues = (id) => {
  /* Fetching data using useDocument hook */
  const [customer, customerLoading] = useDocument({
    ref: id ? COLLECTIONS.CUSTOMERS + '/' + id : null
  })

  /* Fetching restaurant data */
  const [restaurants, restaurantLoading] = useGetCustomerRestaurants(customer)

  /* Merge all fetched data */
  const initialValues = useMemo(
    () => ({
      customer,
      restaurants
    }),
    [customer, restaurants]
  )

  /* Merge all loading states */
  const loadings = useMemo(
    () => (id ? [!customer, customerLoading, restaurantLoading] : []),
    [id, customer, customerLoading, restaurantLoading]
  )

  /* Getting loading state using merged loadings */
  const loading = useLoading(loadings)

  return [initialValues, loading]
}

export default useGetCustomerInitialValues
