import { doc, getDoc } from 'firebase/firestore'
import { useEffect, useState } from 'react'
import { useLocation } from 'react-router-dom'
import firestore from 'services/firebase/firestore'

import { useTranslations } from 'contexts/Translation'
import { capitalize } from 'helpers'
import { COLLECTIONS } from '__constants__'

import { REPLACED_DATA } from 'components/HeaderBreadcrumbs/__constants__'
import { transformCollectionName } from 'components/HeaderBreadcrumbs/helpers'

const useTransformPathSnippers = (pathSnippets, collectionsArray) => {
  const { t, language } = useTranslations()
  const { pathname } = useLocation()
  const [breadCrumbItem, setBreadCrumbItem] = useState()
  const handleLanguage = (data) => data[language] || data?.en || data?.name

  const transformedPathSnippets = pathSnippets?.map(async (item, index) => {
    // Check, when first item, do not transformed path snippet
    const isFirst = index === 0
    let prevItem =
      !isFirst && transformCollectionName(pathSnippets?.[index - 1])

    // Check when prev path snipper equal collectionName
    const findCollection = collectionsArray?.includes?.(prevItem)

    // If find collection name, get database field value, setting in collectionReplacedData
    if (findCollection) {
      const collectionData = REPLACED_DATA?.[prevItem]

      const field = collectionData?.dataBaseField
      const collectionName = collectionData?.collectionName

      const documentRef = doc(firestore, collectionName || prevItem, item)
      const documentSnap = await getDoc(documentRef)

      if (documentSnap?.exists()) {
        const docData =
          documentSnap?.data()?.[field] ?? documentSnap?.data()?.title

        const name =
          typeof docData === 'string' ? docData : handleLanguage(docData)

        return {
          name,
          disabled: collectionData?.disabled
        }
      }
    } else if (pathSnippets[0] === 'item-types' && pathSnippets[2]) {
      const collectionData = REPLACED_DATA?.[COLLECTIONS.MENU_ITEMS]

      const field = REPLACED_DATA[COLLECTIONS.MENU_ITEMS].dataBaseField
      const collectionName = collectionData?.collectionName

      const documentSnap = await getDoc(doc(firestore, collectionName, item))
      if (documentSnap?.exists()) {
        const docData = documentSnap?.data()?.[field]
        const name =
          typeof docData === 'string' ? docData : handleLanguage(docData)
        return {
          name,
          disabled: collectionData?.disabled
        }
      }
    }

    // Capitalize name
    const capitalizedName = capitalize(item?.split('-')?.join(' '))
    return { name: t(capitalizedName) }
  })

  // [LIFECYCLE]
  useEffect(() => {
    // Resolve promise
    const updateBreadcrumb = async () => {
      const data = await Promise.all(transformedPathSnippets)

      data?.length && setBreadCrumbItem(data)
    }

    pathname && updateBreadcrumb()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pathname, t])
  return breadCrumbItem
}

export default useTransformPathSnippers
