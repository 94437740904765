import { Box, Title } from '@qonsoll/react-design'
import {
  useActionsOrderedItemAdvancedForm,
  useInitializeOrderedItemAdvancedForm
} from './hooks'

import { ButtonAccent } from 'components'
import { CocktailAdvancedForm } from 'domains/Cocktail/components'
import { Form } from 'antd'
import { KEYS } from '__constants__'
import { OrderedItemSimpleForm } from 'domains/OrderedItem/components'
import PropTypes from 'prop-types'
import { useTranslations } from 'contexts/Translation'

const OrderedItemAdvancedForm = (props) => {
  const { initialData, group, showTitle } = props

  /* Getting translations instance */
  const { t } = useTranslations()
  /* Getting form instance from hook */
  const { form } = useInitializeOrderedItemAdvancedForm(initialData)

  /* Handling enter key press */
  const handleKeyPress = (e) => {
    if (e.key === KEYS.ENTER) {
      e.preventDefault()
      onFinish()
    }
  }

  /* Getting loading state and actions from hook */
  const { loading, onFinish, onReset } = useActionsOrderedItemAdvancedForm({
    initialData,
    form
  })

  return group ? (
    <>
      {showTitle && (
        <Title level={4} mb={2}>
          {t('Ordered item')}
        </Title>
      )}
      <OrderedItemSimpleForm
        showTitle={false}
        group={[...group, 'orderedItem']}
      />
      <CocktailAdvancedForm group={[...group, 'cocktail']} showTitle />
    </>
  ) : (
    <Form
      scrollToFirstError
      onKeyPress={handleKeyPress}
      onFinish={onFinish}
      layout="vertical"
      autoComplete="off"
      form={form}>
      {showTitle && (
        <Title level={4} mb={2}>
          {t('Ordered item')}
        </Title>
      )}
      <OrderedItemSimpleForm group={['orderedItem']} />
      <CocktailAdvancedForm group={['item']} showTitle />
      <Box display="flex">
        <ButtonAccent
          mr={2}
          htmlType="button"
          onClick={onReset}
          mb={4}
          type="secondary">
          {t('Cancel')}
        </ButtonAccent>
        <ButtonAccent
          type="primary"
          loading={loading}
          onClick={() => form.submit()}>
          {t('Save')}
        </ButtonAccent>
      </Box>
    </Form>
  )
}

OrderedItemAdvancedForm.propTypes = {
  initialData: PropTypes.object,
  group: PropTypes.array,
  showTitle: PropTypes.bool
}

export default OrderedItemAdvancedForm
