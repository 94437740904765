import { Box, Col, Container, Row, Text } from '@qonsoll/react-design'
import {
  ButtonAccent,
  ConditionalTooltipWrapper,
  SimpleSearchInput
} from 'components'
import { useGetMenuInfo, useMenuItemsActions } from 'domains/Menu/hooks'

import Fuse from 'fuse.js'
import { Icon } from '@qonsoll/icons'
import { MenuAdvancedTable } from 'domains/Menu/components'
import PropTypes from 'prop-types'
import { useMemo } from 'react'
import { useSimpleSearch } from 'hooks'
import { useTranslations } from 'contexts/Translation'
import { useUpdateMenuCategoryConfig } from 'domains/MenuCategory/hooks'
import { Tabs } from 'antd'
import { useState, useLayoutEffect } from 'react'
import { useDragAndDrop } from 'domains/Menu/contexts'

const MenuAdvancedView = (props) => {
  const { menu, menuItems, numberOfAvailableItems } = props

  /* Getting translations instance */
  const { t, language } = useTranslations()

  const menuInformation = useGetMenuInfo(menu)
  const [currentSelectedCategory, setCurrentSelectedCategory] = useState(null)
  const { isDndConfigWasChanged } = useDragAndDrop()

  const { menuConfig, onChangeMenuCategoryConfig, isMenuConfigWasUpdated } =
    useUpdateMenuCategoryConfig(menuItems, menu?._id)
  /* Object for search */
  const currentSelectedCategoryMenuItems = useMemo(
    () =>
      menuConfig?.find(
        (category) => category?.categoryId === currentSelectedCategory
      )?.menuItems,
    [currentSelectedCategory, menuConfig]
  )

  const fuseObject = useMemo(
    () =>
      currentSelectedCategoryMenuItems
        ? new Fuse(currentSelectedCategoryMenuItems, {
            includeScore: true,
            threshold: 0.2,
            keys: [
              ['name', language],
              ['name', 'en']
            ]
          })
        : null,
    [currentSelectedCategoryMenuItems, language]
  )

  const { onSearchInputChange, searchRef, searchResult } = useSimpleSearch(
    currentSelectedCategoryMenuItems,
    fuseObject
  )

  const onChangeCategory = (key) => {
    setCurrentSelectedCategory(key)
  }

  useLayoutEffect(() => {
    if (menuConfig) {
      const [firstCategory] = menuConfig

      const { categoryId } = firstCategory || {}

      setCurrentSelectedCategory(categoryId)
    }
  }, [menuConfig])

  const {
    saveMenuOrderLoading,
    onCreateMenuItemCallback,
    onSelectCocktailsCallback,
    onCategoryCreate,
    onDeleteItemCallback,
    onSaveItemsOrder,
    onItemEditCallback,
    onCategoryEdit,
    onCategoryDelete
  } = useMenuItemsActions({
    menu,
    menuConfig
  })

  const saveItemsOrderTooltipText = `${t(
    "You haven't changed the order of items yet"
  )}. ${t('Add items to menu and drag and drop them to save a new order')}.`

  const isDragAndDropDisabled = searchResult?.length < menuConfig?.length
  // Disable button if there is no changes or if there is value in the input for searching

  const handleDisplayOfSeparatorDot = (index) =>
    index === menuInformation?.length - 1 ? 'none' : 'block'

  return (
    <>
      <Container key="categoryId">
        <Row noGutters>
          <Col cw={12} mb={3}>
            <Row noGutters mb={2} width="100%">
              <Col cw={12} mb={2} flexDirection="row" flexWrap="wrap">
                {menuInformation?.map((menuData, index) => (
                  <Box
                    key={`${menuData?.key}-menu-info`}
                    display="flex"
                    alignItems="center">
                    {menuData?.data ? (
                      <>
                        <Text color={menuData?.color}>{menuData?.data}</Text>
                        <Text
                          type="secondary"
                          px={2}
                          display={handleDisplayOfSeparatorDot(index)}>
                          •
                        </Text>
                      </>
                    ) : (
                      <Text type="secondary">{menuData?.emptyText}</Text>
                    )}
                  </Box>
                ))}
              </Col>

              <Col cw={12}>
                <Text>
                  {menu?.description || t('No description have been added yet')}
                </Text>
              </Col>
            </Row>
          </Col>

          <Col cw={12}>
            <Tabs
              onChange={onChangeCategory}
              activeKey={currentSelectedCategory}>
              {menuConfig?.map((item) => {
                const saveItemsOrder = () => onSaveItemsOrder(item?.categoryId)

                return (
                  <Tabs.TabPane
                    tabKey={item?.categoryId}
                    key={item?.categoryId}
                    tab={item?.categoryName}>
                    <Box>
                      <MenuAdvancedTable
                        search={
                          <SimpleSearchInput
                            searchItems={onSearchInputChange}
                            searchRef={searchRef}
                            placeholder={t('Search item by its name')}
                          />
                        }
                        additionalActions={
                          <ConditionalTooltipWrapper
                            tooltipTitle={saveItemsOrderTooltipText}
                            withTooltip={!isMenuConfigWasUpdated}>
                            <ButtonAccent
                              loading={saveMenuOrderLoading}
                              onClick={saveItemsOrder}
                              icon={<Icon name="Save1Outlined" pr={2} />}
                              type="primary"
                              disabled={
                                !isDndConfigWasChanged?.[item?.categoryId]
                              }>
                              {t('Save items order')}
                            </ButtonAccent>
                          </ConditionalTooltipWrapper>
                        }
                        value={searchResult}
                        isDragAndDropDisabled={isDragAndDropDisabled}
                        menuShow
                        menu={menu}
                        categoryId={item?.categoryId}
                        numberOfAvailableItems={numberOfAvailableItems}
                        onCocktailDeleteCallback={onDeleteItemCallback}
                        onCreateCocktailCallback={onCreateMenuItemCallback}
                        onSelectCocktailsCallback={onSelectCocktailsCallback}
                        onCategoryCreateCallback={onCategoryCreate}
                        onCocktailEditCallback={onItemEditCallback}
                        onDragAndDrop={onChangeMenuCategoryConfig}
                        onCategoryDelete={onCategoryDelete}
                        onCategoryEdit={onCategoryEdit}
                      />
                    </Box>
                  </Tabs.TabPane>
                )
              })}
            </Tabs>
          </Col>
        </Row>
      </Container>
    </>
  )
}

MenuAdvancedView.propTypes = {
  menu: PropTypes.object,
  menuItems: PropTypes.array,
  numberOfAvailableItems: PropTypes.number,
  getMenuItems: PropTypes.func
}

export default MenuAdvancedView
