import { arrayRemove, collection, query, where } from 'firebase/firestore'
import { deleteDocument, updateDocument } from 'services/api/firebase'
import { generatePath, useHistory, useParams } from 'react-router-dom'

import { COLLECTIONS } from '__constants__'
import PATHS from 'pages/paths'
import { firestore } from 'services/firebase'
import { message } from 'antd'
import { useCollectionData } from 'react-firebase-hooks/firestore'
import { useCustomerContext } from 'contexts/CustomerContext'
import { useEvent } from 'hooks'
import { useMemo } from 'react'
import { useTranslations } from 'contexts/Translation'

/* Path's */
const createPath =
  PATHS.AUTHENTICATED.RESTAURANT_CREATE || PATHS.AUTHENTICATED.RESTAURANT_WIZARD
const editPath = PATHS.AUTHENTICATED.RESTAURANT_EDIT
const showPath = PATHS.AUTHENTICATED.RESTAURANT_SHOW

const useRestaurantBaseActions = ({ restaurantId, actions } = {}) => {
  /* Getting translations instance */
  const { t } = useTranslations()
  /* Getting history instance */
  const history = useHistory()

  const { profileId } = useParams()
  const { currentCustomerId } = useCustomerContext()

  /* Getting all the customers that have the restaurantId in their restaurants array. */
  const [customers, customersLoading] = useCollectionData(
    restaurantId &&
      query(
        collection(firestore, COLLECTIONS.CUSTOMERS),
        where('restaurants', 'array-contains', restaurantId)
      )
  )

  /* Function to handle back click */
  const handleBack = useEvent(() => history.goBack())

  /* Handling opening of the create page */
  const handleCreate = useEvent(() => {
    history.push(createPath)
  })

  /* Handling opening of the show page */
  const handleOpen = useEvent(() => {
    const pathParams = { companyId: currentCustomerId, restaurantId }
    const path = generatePath(showPath, pathParams)
    history.push(path)
  })

  /* Handling opening of the edit page or change view to edit mode */
  const handleEdit = useEvent(() => {
    const pathParams = { restaurantId, companyId: profileId }
    const path = generatePath(editPath, pathParams)
    history.push(path)
  })

  const handleDelete = useEvent(async () => {
    try {
      if (!customersLoading && Array?.isArray(customers)) {
        customers?.forEach((customer) =>
          updateDocument(COLLECTIONS.CUSTOMERS, customer?._id, {
            restaurants: arrayRemove(restaurantId)
          })
        )
      }
      await deleteDocument(COLLECTIONS.RESTAURANTS, restaurantId)
      history.push(PATHS.AUTHENTICATED.DASHBOARD)
      message.success(t('Restaurant successfully deleted'))
    } catch (error) {
      message.error(error.message)
    }
  })

  return useMemo(
    () => ({
      onBack: handleBack,
      onCreate: handleCreate,
      onOpen: handleOpen,
      onEdit: handleEdit,
      onDelete: handleDelete,
      ...(actions || {}),
      createPath,
      editPath,
      showPath
    }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [
      actions?.onBack,
      actions?.onCreate,
      actions?.onDelete,
      actions?.onEdit,
      actions?.onOpen,
      handleBack,
      handleCreate,
      handleDelete,
      handleEdit,
      handleOpen
    ]
  )
}

export default useRestaurantBaseActions
