import { Text } from '@qonsoll/react-design'
import { useTranslations } from '@qonsoll/translation'
import {
  LIST_FIELD_RELATION_DATA_TYPES,
  LIST_ITEM_EDITABLE_COMPONENTS,
  LIST_ITEM_VIEW_COMPONENTS
} from '__constants__'
import { Input, message } from 'antd'
import { useActionsMenuItemField } from 'domains/MenuItem/hooks'
import {
  checkIsLocalizationObject,
  checkIsObject,
  getRelationshipCollectionName
} from 'helpers'
import React, { useMemo, useState, useCallback, useEffect } from 'react'
import { getDocument } from 'services/api/firebase/helpers'

const useGetMenuListItemComponent = (columnData, columnValue, menuItemId) => {
  const [selectOptions, setSelectOptions] = useState([])
  const [loading, setLoading] = useState(false)

  const { value, onChangeAction, onBlurAction } = useActionsMenuItemField(
    columnData,
    menuItemId
  )

  const { t, language } = useTranslations()

  const getSelectOptions = useCallback(async () => {
    try {
      setLoading(true)

      const [
        relationshipCollectionName,
        relationshipDocumentId,
        parentCollectionName,
        parentItemsFieldName
      ] = getRelationshipCollectionName(columnData)

      if (relationshipCollectionName) {
        const parentData = await getDocument(
          parentCollectionName,
          relationshipDocumentId
        )

        const possibleOptionsIds = checkIsObject(
          parentData[parentItemsFieldName]
        )
          ? Object.keys(parentData[parentItemsFieldName])
          : []

        const possibleOptionsDocumentsPromises = possibleOptionsIds.map((id) =>
          getDocument(relationshipCollectionName, id)
        )

        const possibleOptionsDocuments = await Promise.all(
          possibleOptionsDocumentsPromises
        )

        const possibleOptions = possibleOptionsDocuments.map(
          ({ _id, name }) => ({
            value: _id,
            label: checkIsObject(name) ? name?.[language] ?? name?.en : name
          })
        )

        setSelectOptions(possibleOptions)
      }
    } catch (error) {
      message.error(
        `${t(
          'Something went wrong during fetching possible select options'
        )}, ${error?.message}`
      )
    } finally {
      setLoading(false)
    }
  }, [columnData, language, t])

  useEffect(() => {
    getSelectOptions()
  }, [getSelectOptions])

  const listItemComponent = useMemo(() => {
    const { isEditable, relationType } = columnData

    const isRelationship = LIST_FIELD_RELATION_DATA_TYPES.includes(relationType)

    const relationshipOptions = isRelationship ? { options: selectOptions } : {}

    const style = { maxWidth: '150px', display: 'flex', flex: 1 }

    const transformedColumnValue = checkIsLocalizationObject(columnValue)
      ? columnValue?.[language] || columnValue?.en
      : columnValue

    if (isEditable) {
      const component = LIST_ITEM_EDITABLE_COMPONENTS?.[relationType]

      return component ? (
        React.cloneElement(component, {
          value: value,
          onChange: onChangeAction,
          onBlur: onBlurAction,
          style,
          ...relationshipOptions
        })
      ) : (
        <Input value={transformedColumnValue} />
      )
    } else {
      const component = LIST_ITEM_VIEW_COMPONENTS?.[relationType]

      const style = {
        maxWidth: '100px',
        maxHeight: '100px',
        borderRadius: '12px'
      }

      return component ? (
        React.cloneElement(component, {
          src: value,
          style
        })
      ) : (
        <Text>{transformedColumnValue}</Text>
      )
    }
  }, [
    columnData,
    columnValue,
    language,
    onBlurAction,
    onChangeAction,
    selectOptions,
    value
  ])

  return [listItemComponent, loading]
}

export default useGetMenuListItemComponent
