const USER_ROLES = {
  SUPER_ADMIN: 'Super admin',
  LOCAL_ADMIN: 'Local admin',
  COMPANY_OWNER: 'Owner',
  COMPANY_ADMIN: 'Admin'
}
const USER_ROLES_KEYS = {
  SUPER_ADMIN: 'SUPER_ADMIN',
  LOCAL_ADMIN: 'LOCAL_ADMIN',
  COMPANY_OWNER: 'COMPANY_OWNER',
  COMPANY_ADMIN: 'COMPANY_ADMIN'
}
export { USER_ROLES, USER_ROLES_KEYS }
