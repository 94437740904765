const MIN_LENGTH_CUSTOMER_NAME = 1
const MIN_LENGTH_CONTACT_PERSON_NAME = 1
const MIN_LENGTH_CONTACT_PHONE_NUMBER = 3
const MIN_LENGTH_CONTACT_LICENSE_NUMBER = 12

export {
  MIN_LENGTH_CUSTOMER_NAME,
  MIN_LENGTH_CONTACT_PERSON_NAME,
  MIN_LENGTH_CONTACT_PHONE_NUMBER,
  MIN_LENGTH_CONTACT_LICENSE_NUMBER
}
