import PropTypes from 'prop-types'
import { Select } from '@qonsoll/react-design'

const SelectWithFixedDropdown = (props) => {
  const { children } = props
  return (
    <Select getPopupContainer={(trigger) => trigger.parentNode} {...props}>
      {children}
    </Select>
  )
}

export default SelectWithFixedDropdown

SelectWithFixedDropdown.propTypes = {
  props: PropTypes.func,
  children: PropTypes.node
}
