import { Col, PageWrapper, Row } from '@qonsoll/react-design'

import { HeaderBreadcrumbs } from 'components'
import { MenuAdvancedForm } from 'domains/Menu/components'
import { useHistory } from 'react-router-dom'
import { useTranslations } from 'contexts/Translation'

const MenuCreatePage = (props) => {
  /* Getting history instance */
  const history = useHistory()

  /* Getting translations instance */
  const { t } = useTranslations()

  /* Function to handle back click */
  const handleBack = () => history.goBack()

  return (
    <PageWrapper onBack={handleBack} breadcrumbs={<HeaderBreadcrumbs />}>
      <Row noGutters>
        <Col>
          <MenuAdvancedForm
            cw={[12, 12, 8, 6]}
            title={t('Create new menu')}
            subtitle={t(
              'Follow the instructions below to create your new menu in no time'
            )}
          />
        </Col>
      </Row>
    </PageWrapper>
  )
}

export default MenuCreatePage
