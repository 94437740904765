import { ExclamationCircleOutlined } from '@ant-design/icons'
import { Modal } from 'antd'
import PATHS from 'pages/paths'
import firebase from 'firebase/compat/app'
import { useCallback } from 'react'
import { useHistory } from 'react-router-dom'
import { useTranslations } from 'contexts/Translation'

const useConfirmLogout = () => {
  const { t } = useTranslations()
  const history = useHistory()

  return useCallback(() => {
    Modal.confirm({
      title: t('Confirm Logout'),
      centered: true,
      icon: <ExclamationCircleOutlined />,
      content: t('Are you sure you want to log out') + '?',
      okText: t('Logout'),
      onOk: async () => {
        localStorage.setItem('companyId', null)
        await firebase.auth().signOut()
        history.push(PATHS.CONFIG.AFTER_LOGOUT)
      },
      cancelText: t('Cancel')
    })
  }, [t, history])
}

export default useConfirmLogout
