import { Form } from 'antd'
import { Input } from '@qonsoll/react-design'
import styled from 'styled-components'

const StyledInput = styled(Input)`
  .ant-input-group-addon:last-child {
    border-top-right-radius: var(--ql-input-border-radius) !important;
    border-bottom-right-radius: var(--ql-input-border-radius) !important;
  }
`
const StyledFormItem = styled(Form.Item)`
  margin-bottom: 0px !important;
  flex: 1;
`
export { StyledInput, StyledFormItem }
