import { deleteDocument, updateDocument } from 'services/api/firebase'
import { generatePath, useHistory, useParams } from 'react-router-dom'

import { COLLECTIONS } from '__constants__'
import PATHS from 'pages/paths'
import { message } from 'antd'
import { useEvent } from 'hooks'
import { useMemo } from 'react'
import { useTranslations } from 'contexts/Translation'

/* Path's */
const createPath = PATHS.AUTHENTICATED.TABLE_CREATE
const editPath = PATHS.AUTHENTICATED.RESTAURANT_ZONE_EDIT
const showPath = PATHS.AUTHENTICATED.RESTAURANT_ZONE_SHOW

const useZoneBaseActions = ({ zoneId, actions, restaurant, refresh } = {}) => {
  /* Getting translations instance */
  const { t } = useTranslations()
  /* Getting history instance */
  const history = useHistory()
  /* Getting params from useParams hook for getting url params */
  const { companyId, restaurantId } = useParams()

  /* Function to handle back click */
  const handleBack = useEvent(() => history.goBack())

  /* Handling opening of the create page */
  const handleCreate = useEvent(() => {
    history.push(createPath, {
      parent: {
        id: zoneId,
        collection: COLLECTIONS.ZONES,
        field: 'tables',
        type: 'updateParentHasManyRelationship'
      }
    })
  })

  const handleOpen = useEvent(() => {
    const pathParams = { profileId: companyId, restaurantId, zoneId }
    const path = generatePath(showPath, pathParams)
    history.push(path)
  })

  /* Handling opening of the edit page or change view to edit mode */
  const handleEdit = useEvent(() => {
    const pathParams = { profileId: companyId, restaurantId, zoneId }
    const path = generatePath(editPath, pathParams)
    history.push(path)
  })

  const handleDelete = useEvent(async (doc, index) => {
    try {
      restaurant?.zones?.splice(index, 1)
      await updateDocument(COLLECTIONS.RESTAURANTS, restaurant?._id, {
        zones: restaurant?.zones
      })
      await deleteDocument(COLLECTIONS.ZONES, doc?._id ?? zoneId)
      refresh?.()
      message.success(t('Zone successfully deleted'))
    } catch (error) {
      message.error(error.message)
    }
  })

  return useMemo(
    () => ({
      onBack: handleBack,
      onCreate: handleCreate,
      onOpen: handleOpen,
      onEdit: handleEdit,
      onDelete: handleDelete,
      ...(actions || {}),
      createPath,
      editPath
    }),
    [actions, handleBack, handleCreate, handleDelete, handleEdit, handleOpen]
  )
}

export default useZoneBaseActions
