import { useEffect, useState, useCallback } from 'react'
import { getDocument } from 'services/api/firebase'
import { COLLECTIONS } from '__constants__'

/**
 * It fetches a allergens documents from Firestore and returns it
 * @param cocktail - The cocktail object that we're getting the allergens for.
 * @returns An array with two values. The first value is the allergens object, and the second value is a
 * boolean that indicates whether the allergens is loading.
 */
const useGetCocktailAllergens = (cocktail) => {
  /* State for data */
  const [allergens, setAllergens] = useState([])
  /* State for loading */
  const [loading, setLoading] = useState(!!cocktail?.allergens?.length)
  /* State for error */
  const [error, setError] = useState(null)

  /* Fetching data */
  const getAllergens = useCallback(async () => {
    try {
      /* Setting loading state to true */
      setLoading(true)

      /* Getting allergens promises*/
      const promises = cocktail.allergens.map((allergen) =>
        getDocument(COLLECTIONS.ALLERGENS, allergen)
      )

      /* Getting allergens data */
      const data = await Promise.all(promises)

      /* Setting allergens data */
      setAllergens(data)

      /* Setting loading state to false */
    } catch (error) {
      setError(error)
    }
    setLoading(false)
  }, [cocktail?.allergens])

  useEffect(() => {
    cocktail?.allergens?.length && getAllergens()
  }, [cocktail?.allergens, getAllergens])

  return [allergens, loading, error, getAllergens]
}

export default useGetCocktailAllergens
