import { Radio } from 'antd'
import styled from 'styled-components'

const Option = styled(Radio.Button)`
  &.ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled) {
    color: var(--option-color) !important;
  }
  &.ant-radio-button-wrapper:first-child,
  &.ant-radio-button-wrapper:last-child {
    border-radius: 20px;
  }
  &.ant-radio-button-wrapper:not(:first-child)::before {
    display: none;
  }
  &.ant-radio-button-wrapper-checked:focus-within {
    box-shadow: none !important;
  }
  &.ant-radio-button-wrapper {
    margin: 0 6px 6px 0;
    border-left-width: 1px;
    padding: 8px 16px;
    border-radius: 20px;
    font-weight: var(--ql-font-weight-medium);

    background: var(--option-bg);
    border: 1px solid var(--option-border);
    color: var(--option-color);

    &:hover {
      background: var(--option-hover-bg);
      border: 1px solid var(--option-hover-border);
      color: var(--option-hover-color);
      box-shadow: var(--option-hover-shadow);
    }
    &:active {
      background: var(--option-active-bg);
      border: 1px solid var(--option-active-border);
      color: var(--option-active-color);
      box-shadow: none;
    }
    &:focus {
      background: var(--option-focus-bg);
      border: 1px solid var(--option-focus-border);
      color: var(--option-focus-color);
      box-shadow: var(--option-focus-shadow);
    }
  }
  &&&&&.ant-radio-button-wrapper-checked {
    background: var(--option-bg);
    border: 1px solid var(--option-checked-border);
    color: var(--option-color);
    position: relative;
    &:hover {
      background: var(--option-hover-bg);
      border: 1px solid var(--option-checked-hover-border);
      color: var(--option-hover-color);
      box-shadow: var(--option-hover-shadow);
    }
    &:active {
      background: var(--option-active-bg);
      border: 1px solid var(--option-checked-active-border);
      color: var(--option-active-color);
      box-shadow: none;
    }
    &:focus,
    .ant-radio-button-checked:focus {
      background: var(--option-focus-bg);
      border: 1px solid var(--option-checked-focus-border);
      color: var(--option-focus-color);
      box-shadow: var(--option-focus-shadow);
    }
  }
  &.ant-radio-button-wrapper-disabled:not(.ant-radio-button-wrapper-checked) {
    background: var(--option-disabled-bg);
    border: 1px solid var(--option-disabled-border);
    color: var(--option-disabled-color);
    box-shadow: none;
  }
  &.ant-radio-button-wrapper-disabled.ant-radio-button-wrapper-checked {
    background: var(--option-checked-disabled-bg) !important;
    border: 1px solid var(--option-checked-disabled-border) !important;
    color: var(--option-disabled-color) !important;
    box-shadow: none !important;
  }

  &.ant-radio-button-wrapper-checked > span:nth-child(2) {
    margin-left: 24px;
    display: inline-block;
  }
  &.ant-radio-button-wrapper-checked .ant-radio-button-checked::before,
  &.ant-radio-button-wrapper-disabled.ant-radio-button-wrapper-checked::before {
    content: ' ';
    width: 8px;
    height: 14px;
    position: absolute;
    top: 50%;
    left: 16px;
    display: block;
    background: white;
    border: 2px solid var(--ql-text-accent1);
    border-top: 0;
    border-left: 0;
    transform: rotate(40deg) scale(1) translate(-50%, -50%);
    opacity: 1;
    transition: all var(--ql-animation-duration-default)
      var(--ql-animation-cubic-ease-out-back) var(--ql-animation-duration-fast);
  }
`

export default Option
