import { useEffect, useState, useCallback } from 'react'
import { getDocument } from 'services/api/firebase'
import { COLLECTIONS } from '__constants__'

/**
 * It fetches a ingredients documents from Firestore and returns it
 * @param cocktail - The cocktail object that we're getting the ingredients for.
 * @returns An array with two values. The first value is the ingredients object, and the second value is a
 * boolean that indicates whether the ingredients is loading.
 */
const useGetCocktailIngredients = (cocktail) => {
  /* State for data */
  const [ingredients, setIngredients] = useState([])
  /* State for loading */
  const [loading, setLoading] = useState(!!cocktail?.ingredients?.length)
  /* State for error */
  const [error, setError] = useState(null)

  /* Fetching data */
  const getIngredients = useCallback(async () => {
    try {
      /* Setting loading state to true */
      setLoading(true)

      /* Getting ingredients promises*/
      const promises = cocktail.ingredients.map((ingredient) =>
        getDocument(COLLECTIONS.INGREDIENTS, ingredient)
      )

      /* Getting ingredients data */
      const data = await Promise.all(promises)

      /* Setting ingredients data */
      setIngredients(data)

      /* Setting loading state to false */
    } catch (error) {
      setError(error)
    }
    setLoading(false)
  }, [cocktail?.ingredients])

  useEffect(() => {
    cocktail?.ingredients?.length && getIngredients()
  }, [cocktail?.ingredients, getIngredients])

  return [ingredients, loading, error, getIngredients]
}

export default useGetCocktailIngredients
