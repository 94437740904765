import { Card, Tabs, Tag } from 'antd'
import { Col, Container, Divider, Row, Text } from '@qonsoll/react-design'
import { useHistory, useParams } from 'react-router-dom'

import { ORDER_STATUSES, PAYMENT_TYPES } from '__constants__'
import { OrderOrderedItems } from 'domains/Order/components'
import PropTypes from 'prop-types'
import { useStateWithStorage } from 'hooks'
import { useTranslations } from 'contexts/Translation'

const OrderAdvancedView = (props) => {
  const { order, currency = 'NOK' } = props

  /* State for active tab in AdvancedView with saving it to storage */
  const [activeTab, setActiveTab] = useStateWithStorage(
    null,
    'order-advanced-view-active-tab'
  )

  /* Getting translations instance */
  const { t } = useTranslations()
  /* Getting history instance from useHistory hook for redirecting */
  const history = useHistory()
  /* Getting params from useParams hook for getting url params */
  const { orderId } = useParams()

  /* Getting TabPane component from Tabs */
  const { TabPane } = Tabs

  /* Handling change tab */
  const changeTab = (key) => {
    if (orderId) {
      if (key.includes(`orders/${orderId}`)) {
        history.push(key)
      }
      setActiveTab(key)
    }
  }
  const orderStatusColor =
    order?.orderStatus === ORDER_STATUSES.DONE
      ? 'var(--ribbon-active-bg)'
      : 'var(--ribbon-disabled-bg)'

  return (
    <Container>
      <Row negativeBlockMargin>
        <Col cw={4}>
          <Row noGutters mb={3}>
            <Col>
              <Card>
                <Row noGutters v="center" mb={2}>
                  <Col h="left">
                    <Text>{t('Status')}:</Text>
                  </Col>
                  <Col cw="auto" h="right">
                    <Tag
                      style={{ marginRight: '0' }}
                      color={orderStatusColor}
                      fontWeight="var(--ql-font-weight-bold)">
                      {order?.orderStatus}
                    </Tag>
                  </Col>
                </Row>
                <Row noGutters v="center" mb={2}>
                  <Col h="left">
                    <Text>{t('Table')}:</Text>
                  </Col>
                  <Col h="right">
                    <Text fontWeight="var(--ql-font-weight-bold)">
                      #{order?.tableNumber}
                    </Text>
                  </Col>
                </Row>
                <Row noGutters v="center" mb={2}>
                  <Col h="left">
                    <Text>{t('Total')}:</Text>
                  </Col>
                  <Col h="right">
                    <Text fontWeight="var(--ql-font-weight-bold)">
                      {`${order?.totalPrice} ${currency}`}
                    </Text>
                  </Col>
                </Row>
                {order?.comments && (
                  <>
                    <Divider py={2} />
                    <Row noGutters>
                      <Col cw={12}>
                        <Text variant="body2">{order?.comments}</Text>
                      </Col>
                    </Row>
                  </>
                )}
              </Card>
            </Col>
          </Row>
          <Row noGutters>
            <Col>
              <Card>
                <Row noGutters v="center" mb={2}>
                  <Col h="left">
                    <Text>{t('Payment options')}:</Text>
                  </Col>
                  <Col cw="auto" h="right">
                    <Text fontWeight="var(--ql-font-weight-bold)">
                      {PAYMENT_TYPES?.[order?.paymentOptions] || 'n/a'}
                    </Text>
                  </Col>
                </Row>
                <Row noGutters v="center" mb={2}>
                  <Col h="left">
                    <Text>{t('Time of payment')}:</Text>
                  </Col>
                  <Col cw="auto" h="right">
                    <Text fontWeight="var(--ql-font-weight-bold)">
                      {order?.payData || 'n/a'}
                    </Text>
                  </Col>
                </Row>
              </Card>
            </Col>
          </Row>
        </Col>

        <Col cw={8}>
          <Tabs defaultActiveKey={activeTab} onChange={changeTab}>
            <TabPane tab={t('Ordered items')} key="#orderedItem">
              <OrderOrderedItems order={order} currency={currency} />
            </TabPane>
          </Tabs>
        </Col>
      </Row>
    </Container>
  )
}

OrderAdvancedView.propTypes = {
  order: PropTypes.object,
  currency: PropTypes.string
}

export default OrderAdvancedView
