import { useCallback, useEffect, useState } from 'react'

import { COLLECTIONS } from '__constants__'
import { getDocument } from 'services/api/firebase'

/**
 * It fetches a menus documents from Firestore and returns it
 * @param restaurant - The restaurant object that we're getting the menus for.
 * @returns An array with two values. The first value is the menus object, and the second value is a
 * boolean that indicates whether the menus is loading.
 */
const useGetRestaurantMenus = (restaurant) => {
  /* State for data */
  const [menus, setMenus] = useState([])
  /* State for loading */
  const [loading, setLoading] = useState(!!restaurant?.menus?.length)
  /* State for error */
  const [error, setError] = useState(null)

  /* Fetching data */
  const getMenus = useCallback(async () => {
    try {
      /* Setting loading state to true */
      setLoading(true)

      /* Getting menus promises*/
      const promises = restaurant.menus.map((menu) =>
        getDocument(COLLECTIONS.MENUS, menu)
      )

      /* Getting menus data */
      const data = await Promise.all(promises)

      /* Setting menus data */
      setMenus(data)

      /* Setting loading state to false */
    } catch (error) {
      setError(error)
    }
    setLoading(false)
  }, [restaurant?.menus])

  useEffect(() => {
    restaurant?.menus?.length && getMenus()
  }, [restaurant?.menus, getMenus])

  return [menus, loading, error, getMenus]
}

export default useGetRestaurantMenus
