import { useTranslations } from '@qonsoll/translation'
import { Col, PageWrapper, Row } from '@qonsoll/react-design'
import { useParams } from 'react-router-dom'

import { HeaderBreadcrumbs, Spinner } from 'components'

import { InteractiveMenuAdvancedForm } from 'domains/InteractiveMenu/components'
import {
  useInteractiveMenuBaseActions,
  useGetInteractiveMenu
} from 'domains/InteractiveMenu/hooks'

const InteractiveMenuEdit = () => {
  /* Getting translations instance */
  const { t } = useTranslations()
  /* Getting params from router */
  const { interactiveMenuId } = useParams()

  const { onBack } = useInteractiveMenuBaseActions({ interactiveMenuId })

  const headingProps = {
    title: t('Edit interactive menu'),
    marginBottom: 3,
    textAlign: 'left'
  }

  /* Fetching all data for 'Interactive Menu - Show page' */
  const [initialData, loading] = useGetInteractiveMenu(interactiveMenuId)

  return (
    <PageWrapper
      onBack={onBack}
      breadcrumbs={<HeaderBreadcrumbs />}
      headingProps={headingProps}>
      <Row noGutters>
        <Col cw={[12, 12, 8, 6]}>
          {loading ? (
            <Spinner
              height="70vh"
              text={`${t('Interactive menu is loading')}...`}
            />
          ) : (
            <InteractiveMenuAdvancedForm initialData={initialData} />
          )}
        </Col>
      </Row>
    </PageWrapper>
  )
}

export default InteractiveMenuEdit
