import { Option } from '@qonsoll/react-design'
import PropTypes from 'prop-types'
import { SelectWithFixedDropdown } from 'components'
import { useTranslations } from 'contexts/Translation'

const SelectCountry = (props) => {
  const {
    value,
    onChange,
    countriesList,
    listLanguages,
    setMainLanguage,
    setMainCurrency,
    listSortingCountries,
    ...rest
  } = props

  /* Getting translations instance */
  const { t } = useTranslations()

  const handleChange = (element) => {
    // defined what country we select
    const selectedCountry =
      countriesList &&
      countriesList?.find((item) => item?.internationalName === element)

    // set main language due to selected country
    setMainLanguage &&
      setMainLanguage(
        selectedCountry?.languages
          ? selectedCountry?.languages[0]?.name
          : 'English'
      )
    // set main currency due to selected country
    setMainCurrency &&
      setMainCurrency(
        selectedCountry?.currency ? selectedCountry?.currency[0]?.code : 'USD'
      )

    onChange(element)
  }

  return (
    <SelectWithFixedDropdown
      aria-label="Country selection"
      aria-describedby="country-selection-description"
      value={value}
      onChange={handleChange}
      showSearch
      placeholder={t('Please choose a country')}
      {...rest}>
      {countriesList?.map((country, index) => (
        <Option key={country + index} value={country?.internationalName}>
          {country?.internationalName}
        </Option>
      ))}
    </SelectWithFixedDropdown>
  )
}
SelectCountry.propTypes = {
  value: PropTypes.object,
  onChange: PropTypes.func,
  countriesList: PropTypes.array,
  handleChangeCountryIndex: PropTypes.func,
  setMainCurrency: PropTypes.func,
  setMainLanguage: PropTypes.func,
  listLanguages: PropTypes.array,
  listSortingCountries: PropTypes.array
}

export default SelectCountry
