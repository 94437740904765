import { Col, Row, Text, Title } from '@qonsoll/react-design'
import {
  GlassTypeSelect,
  GlassTypeSimpleView
} from 'domains/GlassType/components'
import { useEffect, useState } from 'react'

import PropTypes from 'prop-types'
import { useGetGlassTypes } from 'domains/GlassType/hooks'
import { useTranslations } from 'contexts/Translation'

const GlassTypeSelectableList = (props) => {
  const {
    emptyProps,
    actions,
    value,
    onChange,
    withTitle = true,
    mode = 'multiple',
    transformationLimit = 6
  } = props

  /* State for selected item in list */
  const [selected, setSelected] = useState()

  /* Getting translations instance */
  const { t } = useTranslations()
  /* Fetching glassTypes */
  const [glassTypes] = useGetGlassTypes()

  /* Checking if mode is multiple */
  const isMultiple = mode === 'multiple'
  /* Checking if list is empty */
  const isEmpty = !glassTypes?.length
  /* Checking if multiple select is allowed */
  const isSelectComponent = glassTypes?.length > transformationLimit

  /* Handling selection of the item */
  const handleSelect = (id, selectValue = null) => {
    if (isSelectComponent) {
      setSelected(selectValue)
      onChange?.(selectValue)
    } else {
      const toggleMultipleSelection = (prev = []) =>
        prev.includes(id) ? prev.filter((i) => i !== id) : [...prev, id]
      const toggleSingleSelection = (prev, id) => (prev === id ? null : id)
      setSelected((prev) => {
        const _selected = isMultiple
          ? toggleMultipleSelection(prev)
          : toggleSingleSelection(prev, id)
        onChange?.(_selected)
        return _selected
      })
    }
  }
  /* Checking if the item is selected */
  const checkIsSelected = (id) =>
    isMultiple ? selected?.includes(id) : selected === id

  /* useEffect which helps to initialize the form */
  useEffect(() => {
    if (selected === undefined && value !== undefined) {
      setSelected(value)
    }
  }, [value, selected])

  return (
    <Row mx="var(--ql-grid-gutter-negative)">
      {withTitle && (
        <Col cw={12} mb={2}>
          <Row noGutters>
            <Col>
              <Title level={4}>{t('Glass types')}</Title>
            </Col>
            {isMultiple && (
              <Col cw="auto">
                <Text>
                  {selected?.length || '0'} / {glassTypes?.length}
                </Text>
              </Col>
            )}
          </Row>
        </Col>
      )}
      {!isEmpty &&
        !isSelectComponent &&
        glassTypes.map((glassType, index) => (
          <Col key={glassType?._id || index} cw={4}>
            <GlassTypeSimpleView
              actions={{ ...actions, onOpen: null }}
              index={index}
              isSelected={checkIsSelected(glassType?._id)}
              onSelect={handleSelect}
              glassType={glassType}
              typeImage
            />
          </Col>
        ))}
      {!isEmpty && isSelectComponent && (
        <Col cw={12}>
          <GlassTypeSelect
            mode="multiple"
            showTitle={false}
            value={selected}
            onChange={handleSelect}
          />
        </Col>
      )}
      {isEmpty && (
        <Col cw={12}>
          <Text variant="overline" color="var(--ql-state-disabled-color)">
            {emptyProps?.message || t('No glass types have been created yet')}
          </Text>
        </Col>
      )}
    </Row>
  )
}

GlassTypeSelectableList.propTypes = {
  cw: PropTypes.any,
  emptyProps: PropTypes.object,
  actions: PropTypes.any,
  value: PropTypes.any,
  onChange: PropTypes.func,
  withTitle: PropTypes.bool,
  mode: PropTypes.string,
  transformationLimit: PropTypes.number
}

export default GlassTypeSelectableList
