import updateParentHasManyRelationship from './updateParentHasManyRelationship'
import updateParentHasOneRelationship from './updateParentHasOneRelationship'

const map = {
  updateParentHasOneRelationship: updateParentHasOneRelationship,
  updateParentHasManyRelationship: updateParentHasManyRelationship
}

const updateParent = async (historyState, newId) => {
  const { collection, field, id, type } = historyState

  const action = map[type]
  if (action) await action(id, collection, field, newId)
}

export default updateParent
