const MENU_TYPES = {
  DRINK_TYPE: 'Drinks menu',
  DISH_TYPE: 'Dishes menu'
}

const MENU_TYPES_KEYS = {
  DRINK_TYPE: 'DRINK_TYPE',
  DISH_TYPE: 'DISH_TYPE'
}

const MENU_TYPES_OPTIONS = Object.keys(MENU_TYPES).map((key) => ({
  label: MENU_TYPES[key],
  value: key
}))

export { MENU_TYPES_OPTIONS, MENU_TYPES, MENU_TYPES_KEYS }
