const PATH_TYPES = {
  EDIT: 'EDIT',
  CREATE: 'CREATE',
  SHOW: 'SHOW',
  ALL: 'ALL',
  SETTINGS: 'SETTINGS'
}

export default PATH_TYPES
export { PATH_TYPES }
