import { CocktailSimpleView } from 'domains/Cocktail/components'
import PropTypes from 'prop-types'
import { Col, Row } from '@qonsoll/react-design'

const MenuCategoryCocktailsList = (props) => {
  const { cocktails } = props

  return (
    <Row negativeBlockMargin px={1}>
      {cocktails?.map((cocktail, index) => (
        <Col cw={12} key={cocktail?._id || `cocktail_view_${index}`} mb={2}>
          <CocktailSimpleView index={index} cocktail={cocktail} showLogo />
        </Col>
      ))}
    </Row>
  )
}
MenuCategoryCocktailsList.propTypes = {
  cocktails: PropTypes.array
}
export default MenuCategoryCocktailsList
