import { Box, Col, PageWrapper, Row } from '@qonsoll/react-design'
import { useTranslations } from '@qonsoll/translation'

import {
  HeaderBreadcrumbs,
  PageActions,
  Spinner,
  EmptySearch,
  SimpleSearchInput
} from 'components'

import { useInteractiveMenuAllBaseActions } from 'domains/InteractiveMenu/hooks'
import {
  InteractiveMenuList,
  InteractiveMenuSortMenu
} from 'domains/InteractiveMenu/components'

const InteractiveMenusAll = () => {
  const { t } = useTranslations()

  const {
    isRelatedSearchResults,
    headingProps,
    actions,
    loading,
    onSearchInputChange,
    searchRef,
    filterVisibility,
    onBack,
    onCreate,
    filterData,
    setFilterData,
    searchResult
  } = useInteractiveMenuAllBaseActions()

  return (
    <PageWrapper
      onBack={onBack}
      breadcrumbs={<HeaderBreadcrumbs />}
      headingProps={headingProps}>
      <Row noGutters>
        <Col cw={12}>
          {loading ? (
            <Spinner height="70vh" text={t('Interactive menus are loading')} />
          ) : (
            <Row noGutters>
              <Col cw={12} mb={4}>
                <Box display="flex" flex={1}>
                  <Box display="flex" flex={1} mr={3}>
                    <SimpleSearchInput
                      searchItems={onSearchInputChange}
                      searchRef={searchRef}
                      placeholder={t('Search interactive menu by name')}
                    />
                  </Box>
                  <PageActions
                    onCreate={onCreate}
                    actions={actions}
                    createText={t('Create interactive menu')}
                    sortMenu={
                      <InteractiveMenuSortMenu
                        filteredData={filterData}
                        setFilteredData={setFilterData}
                      />
                    }
                  />
                </Box>
              </Col>
              {isRelatedSearchResults ? (
                <Col cw={!filterVisibility && 12}>
                  <InteractiveMenuList interactiveMenus={searchResult} />
                </Col>
              ) : (
                <Col cw={12}>
                  <EmptySearch />
                </Col>
              )}
            </Row>
          )}
        </Col>
      </Row>
    </PageWrapper>
  )
}

export default InteractiveMenusAll
