import { ButtonAccent } from 'components'
import { notification } from 'antd'
import { useTranslations } from 'contexts/Translation'

const useConfirmDiscardChanges = (props) => {
  /* Getting translations instance */
  const { t } = useTranslations()

  const handleCancel = (reset) => {
    const key = `open${Date.now()}`
    const btn = (
      <ButtonAccent
        type="text"
        danger
        size="small"
        onClick={async () => {
          try {
            reset()
          } catch (error) {
            // eslint-disable-next-line
            console.error(error)
          } finally {
            notification.close(key)
          }
        }}>
        {t('Confirm')}
      </ButtonAccent>
    )
    notification.warn({
      message:
        props?.message ??
        `${t('Are you sure you want to discard the changes you made?')} `,
      btn,
      key
    })
  }
  return { handleCancel }
}

export default useConfirmDiscardChanges
