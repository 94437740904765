import {
  TRAINING_MATERIALS_AVAILABILITIES,
  TRAINING_MATERIALS_AVAILABILITIES_KEYS
} from '__constants__/enums'

import { useMemo } from 'react'

const useGetMaterialTitleAndRibbonColor = (availability) => {
  // This is needed to make old values expressed as YES/NO and new value as true/false to work
  const ribbonColor = useMemo(
    () =>
      availability === TRAINING_MATERIALS_AVAILABILITIES_KEYS.EXTRA_MATERIAL ||
      availability === false
        ? 'var(--ribbon-disabled-bg)'
        : (availability === TRAINING_MATERIALS_AVAILABILITIES_KEYS.AVAILABLE ||
            availability === true) &&
          'var(--ribbon-active-bg)',
    [availability]
  )
  // This is needed to make old values expressed as YES/NO and new value as true/false to work
  const materialTitle = useMemo(
    () =>
      availability === TRAINING_MATERIALS_AVAILABILITIES_KEYS.EXTRA_MATERIAL ||
      availability === false
        ? TRAINING_MATERIALS_AVAILABILITIES.EXTRA_MATERIAL
        : (availability === TRAINING_MATERIALS_AVAILABILITIES_KEYS.AVAILABLE ||
            availability === true) &&
          TRAINING_MATERIALS_AVAILABILITIES.AVAILABLE,
    [availability]
  )

  return { ribbonColor, materialTitle }
}

export default useGetMaterialTitleAndRibbonColor
