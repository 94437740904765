import { useEffect, useState, useCallback } from 'react'
import { getDocument } from 'services/api/firebase'
import { COLLECTIONS } from '__constants__'

/**
 * It fetches a cocktail document from Firestore and returns it
 * @param orderedItem - The orderedItem object that we're getting the cocktail for.
 * @returns An array with four values. The first value is the cocktail object, and the second value is a
 * boolean that indicates whether the cocktail is loading.
 */
const useGetOrderedItemCocktail = (orderedItem) => {
  /* State for data */
  const [cocktail, setCocktail] = useState(null)
  /* State for loading */
  const [loading, setLoading] = useState(!!orderedItem?.item)
  /* State for error */
  const [error, setError] = useState(null)

  /* Fetching data */
  const getCocktail = useCallback(async () => {
    try {
      setLoading(true)
      const document = await getDocument(
        COLLECTIONS.COCKTAILS_NEW,
        orderedItem.item
      )
      setCocktail(document)
      setLoading(false)
    } catch (error) {
      setError(error)
    }
  }, [orderedItem?.item])

  useEffect(() => {
    orderedItem?.item && getCocktail()
  }, [orderedItem?.item, getCocktail])

  return [cocktail, loading, error, getCocktail]
}

export default useGetOrderedItemCocktail
