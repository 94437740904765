/**
 * The function returns the best label option from a label object.
 * @param labelObject - The `labelObject` parameter is an object that contains labels in different
 * languages as its properties. The properties are named after the language code (e.g. "en" for
 * English, "fr" for French, etc.) and the values are the corresponding labels in that language. The
 * function `get
 * @returns The function `getAppropriateLabel` returns the best label option from the `labelObject`
 * parameter. If the `en` property is present and truthy, it will be returned. Otherwise, the function
 * will loop through all the properties of `labelObject` and return the first truthy value it finds. If
 * no truthy value is found, the function will return `undefined`.
 */
const getAppropriateLabel = (labelObject) => {
  let bestOption = labelObject?.en

  if (!bestOption) {
    labelObject &&
      Object.keys(labelObject)?.forEach((labelKey) => {
        const currentLabel = labelObject[labelKey]
        if (!bestOption && currentLabel) {
          bestOption = currentLabel
        }
      })
  }

  return bestOption
}

export default getAppropriateLabel
