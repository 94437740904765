import { createDocument, getId, updateDocument } from 'services/api/firebase'

import { Address } from 'models'
import { COLLECTIONS } from '__constants__'
import { message } from 'antd'
import { useGetAddressInfo } from 'domains/Address/hooks'
import { useGetErrorText } from 'hooks'
import { useHistory } from 'react-router-dom'
import { useState } from 'react'
import { useTranslations } from 'contexts/Translation'

const useActionsAddressSimpleForm = ({ initialData, form } = {}) => {
  /* State for loading */
  const [loading, setLoading] = useState(false)
  const getAddressInfo = useGetAddressInfo()

  /* Getting translations instance */
  const { t } = useTranslations()

  /* Getting history instance */
  const history = useHistory()

  const getErrorText = useGetErrorText()

  /* Function for preparing values for saving */
  const prepareValues = async (values, additionalValues) => {
    /* Getting id */
    const addressId = initialData?._id || getId(COLLECTIONS.ADDRESSES)
    const { locationId, description } = values?.locationInfo || values || {}
    const addressData = await getAddressInfo(locationId)
    /* Preparing address values */
    const preparedValues = {
      _id: addressId,
      locationId: locationId,
      description,
      ...addressData
    }
    return preparedValues
  }

  /* Function for saving address form */
  const saveForm = async (values, callback) => {
    try {
      // Prepare data to be saved
      const data = await prepareValues(values)
      // Save data
      if (initialData) {
        await updateDocument(
          COLLECTIONS.ADDRESSES,
          initialData.address?._id,
          data
        )
        message.success(t('Address successfully updated'))
      } else {
        await createDocument(COLLECTIONS.ADDRESSES, data, data._id)
        message.success(t('Address successfully created'))
      }
      // Final callback
      callback?.()
    } catch (error) {
      throw new Error(getErrorText(error))
    }
  }

  /* Validating form */
  const validateForm = (values) => {
    try {
      // Prepare data to be validated
      const validationData = {
        city: values?.['city'],
        country: values?.['country'],
        zipCode: values?.['zipCode'],
        streetName: values?.['streetName'],
        streetNumber: values?.['streetNumber']
      }
      Address.validationSchema.validateSync(validationData)
    } catch (error) {
      throw new Error(t('Validation error: ') + error.message)
    }
  }

  const onFinish = async () => {
    if (loading) return // Avoid multiple calls

    try {
      setLoading(true)
      // Get form values
      const formValues = form.getFieldsValue()
      // Validate fields
      validateForm(formValues)
      // Final callback
      const callback = () => history.goBack()
      // Save data
      await saveForm(formValues, callback)
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error(error)
      setLoading(false)
      message.error(error.message)
    }
  }

  const onReset = () => {
    form.resetFields()
  }

  return { onFinish, onReset, loading, saveForm, validateForm, prepareValues }
}

export default useActionsAddressSimpleForm
