import { Col, Container, Row } from '@qonsoll/react-design'
import { useHistory, useParams } from 'react-router-dom'
import PropTypes from 'prop-types'
import { Tabs } from 'antd'

import { useTranslations } from 'contexts/Translation'

import { useStateWithStorage } from 'hooks'
import { TabCount } from 'components'

import {
  RestaurantAddress,
  RestaurantMenus,
  RestaurantOrders,
  RestaurantSideView,
  RestaurantZones
} from 'domains/Restaurant/components'
import { useGetRestaurantOrdersDifferentStatuses } from 'domains/Restaurant/hooks'

const RestaurantAdvancedView = (props) => {
  const { restaurant } = props

  /* State for active tab in AdvancedView with saving it to storage */
  const [activeTab, setActiveTab] = useStateWithStorage(
    null,
    'restaurant-advanced-view-active-tab'
  )
  const [ordersData, ordersDataLoading] =
    useGetRestaurantOrdersDifferentStatuses(restaurant)

  /* Getting translations instance */
  const { t } = useTranslations()
  /* Getting history instance from useHistory hook for redirecting */
  const history = useHistory()
  /* Getting params from useParams hook for getting url params */
  const params = useParams()
  /* Getting id from params */
  const { restaurantId } = params

  /* Getting TabPane component from Tabs */
  const { TabPane } = Tabs

  /* Handling change tab */
  const changeTab = (key) => {
    if (params && restaurantId) {
      if (key.includes(`restaurants/${restaurantId}`)) {
        history.push(key)
      }
      setActiveTab(key)
    }
  }

  return (
    <Container>
      <Row negativeBlockMargin>
        <Col cw={4} mb={3}>
          <RestaurantSideView restaurant={restaurant}>
            <RestaurantAddress restaurant={restaurant} />
          </RestaurantSideView>
        </Col>
        <Col cw={8}>
          <Tabs defaultActiveKey={activeTab} onChange={changeTab}>
            <TabPane
              tab={<TabCount value={restaurant?.menus} text={t('Menus')} />}
              key="#menu">
              <RestaurantMenus restaurant={restaurant} />
            </TabPane>
            <TabPane
              tab={<TabCount value={restaurant?.zones} text={t('Zones')} />}
              key="#zones">
              <RestaurantZones restaurant={restaurant} />
            </TabPane>
            <TabPane
              tab={<TabCount value={ordersData} text={t('Orders')} />}
              key="#order">
              <RestaurantOrders
                restaurant={restaurant}
                orders={ordersData}
                ordersLoading={ordersDataLoading}
              />
            </TabPane>
          </Tabs>
        </Col>
      </Row>
    </Container>
  )
}

RestaurantAdvancedView.propTypes = {
  restaurant: PropTypes.object,
  setActions: PropTypes.func,
  selectedItems: PropTypes.array,
  setSelectedItems: PropTypes.func
}

export default RestaurantAdvancedView
