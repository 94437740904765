import { Col, Img, Row, Text } from '@qonsoll/react-design'
import { useMemo } from 'react'
import { Skeleton } from 'antd'
import PropTypes from 'prop-types'
import { doc } from 'firebase/firestore'
import { firestore } from 'services/firebase'
import { useDocumentData } from 'react-firebase-hooks/firestore'

import { useTranslations } from 'contexts/Translation'
import { useCustomerContext } from 'contexts/CustomerContext'

import { COLLECTIONS } from '__constants__'
import { glassStyles, imageStyles, styles } from './CocktailSimpleView.styles'

import { useGetCocktailGlassType } from 'domains/Cocktail/hooks'

const CocktailSimpleView = (props) => {
  const { cocktail, fullDesc, restaurant } = props

  /* Getting translations instance */
  const { t } = useTranslations()

  const { customer } = useCustomerContext()

  /* Fetching glass type data */
  const [glassType, loading] = useGetCocktailGlassType(cocktail)

  /* Fetching menuCocktails by customer. */
  const [menuCocktails] = useDocumentData(
    customer?.automaticMenuId &&
      doc(firestore, COLLECTIONS.MENUS + '/' + customer?.automaticMenuId)
  )
  /* A hook that is used to get the price of the cocktail from the menu cocktails. */
  const currentCompanyCocktailPrice = useMemo(
    () =>
      menuCocktails?.cocktailsAvailabilities?.find(
        (cocktails) => cocktails?.cocktailId === cocktail?._id
      )?.price,
    [cocktail?._id, menuCocktails?.cocktailsAvailabilities]
  )

  const cocktailCurrency = restaurant?.currency ?? customer?.currency

  return (
    <Row mx="var(--ql-grid-gutter-negative)" data-testid="cocktailListItem">
      {glassType?.image && (
        <Col cw={12} mb={3}>
          {loading ? (
            <Skeleton active={loading} />
          ) : cocktail?.imageUrl ? (
            <Img
              alt={t('Drink image')}
              style={imageStyles}
              src={cocktail?.imageUrl}
            />
          ) : (
            <Img
              alt={t('Drink glass')}
              style={glassStyles}
              src={glassType?.image}
            />
          )}
        </Col>
      )}

      {cocktail?.description && (
        <Col cw={12}>
          <Text {...(fullDesc ? {} : styles.fieldTitleProps)} mb={2}>
            {cocktail?.description}
          </Text>
        </Col>
      )}

      <Col cw={12}>
        <Text fontWeight="var(--ql-font-weight-semibold)">
          {t('Price')}: {currentCompanyCocktailPrice ?? cocktail?.price}{' '}
          {cocktailCurrency}
        </Text>
      </Col>
    </Row>
  )
}

CocktailSimpleView.propTypes = {
  cocktail: PropTypes.object,
  restaurant: PropTypes.object,
  fullDesc: PropTypes.bool
}

export default CocktailSimpleView
