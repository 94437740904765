import { COLLECTIONS, ORDER_STATUSES } from '__constants__'
import { collection, query, where } from 'firebase/firestore'

import { firestore } from 'services/firebase'
import { useCollectionData } from 'react-firebase-hooks/firestore'
import { useLoading } from 'hooks'
import { useMemo } from 'react'

/**
 * It fetches a orders documents from Firestore and returns it
 * @param restaurant - The restaurant object that we're getting the orders for.
 * @returns An array with two values. The first value is the orders object, and the second value is a
 * boolean that indicates whether the orders is loading.
 */
const useGetRestaurantOrdersDifferentStatuses = (restaurant) => {
  const [ordersDone, ordersDoneLoading] = useCollectionData(
    restaurant &&
      query(
        collection(firestore, COLLECTIONS.ORDERS),
        where('restaurantId', '==', restaurant?._id),
        where('orderStatus', '==', ORDER_STATUSES.DONE)
      )
  )
  const [ordersInProgress, ordersInProgressLoading] = useCollectionData(
    restaurant &&
      query(
        collection(firestore, COLLECTIONS.ORDERS),
        where('restaurantId', '==', restaurant?._id),
        where('orderStatus', '==', ORDER_STATUSES.IN_PROGRESS)
      )
  )

  /* Merge all fetched data */
  const initialValues = useMemo(
    () => [...(ordersInProgress ?? []), ...(ordersDone ?? [])],
    [ordersInProgress, ordersDone]
  )
  /* Merge all loading states */
  const loadings = useMemo(
    () =>
      restaurant
        ? [
            !ordersDone,
            !ordersInProgress,
            ordersDoneLoading,
            ordersInProgressLoading
          ]
        : [],
    [
      ordersDone,
      ordersDoneLoading,
      ordersInProgress,
      ordersInProgressLoading,
      restaurant
    ]
  )

  /* Getting loading state using merged loadings */
  const loading = useLoading(loadings)

  return [initialValues, loading]
}

export default useGetRestaurantOrdersDifferentStatuses
