import BlackoutContentStyled from './BlackoutContent.styled'
import PropTypes from 'prop-types'

const BlackoutContent = (props) => {
  const { asideLeftCollapsed, setAsideLeftCollapsed, lg } = props

  const handleClickBlackoutContent = () => {
    !asideLeftCollapsed && setAsideLeftCollapsed(true)
  }

  // Show BlackoutContent if there is no lg media size and left aside is not collapsed
  return !asideLeftCollapsed && !lg ? (
    <BlackoutContentStyled onClick={handleClickBlackoutContent} />
  ) : null
}

BlackoutContent.propTypes = {
  asideLeftCollapsed: PropTypes.bool,
  setAsideLeftCollapsed: PropTypes.func,
  lg: PropTypes.bool
}

export default BlackoutContent
