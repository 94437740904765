import { Customer, User } from 'models'
import {
  createDocument,
  getId,
  saveBelongsToRelationship,
  updateDocument
} from 'services/api/firebase'

import { COLLECTIONS } from '__constants__'
import firebase from 'firebase/compat/app'
import { message } from 'antd'
import { uploadImage } from 'helpers'
import { useActionsAddressSimpleForm } from 'domains/Address/components/AddressSimpleForm/hooks'
import { useGetErrorText } from 'hooks'
import { useHistory } from 'react-router-dom'
import { useState } from 'react'
import { useTranslations } from 'contexts/Translation'

const useActionsProfileAdvancedForm = ({
  initialData,
  form,
  mainLanguage,
  mainCurrency,
  companyAddress
} = {}) => {
  /* State for loading */
  const [loading, setLoading] = useState(false)
  /* Getting translations instance */
  const { t } = useTranslations()
  /* Getting history instance */
  const history = useHistory()

  const getErrorText = useGetErrorText()

  /* Getting Address values and validation function */
  const {
    prepareValues: prepareAddressValues,
    validateForm: validateAddressForm
  } = useActionsAddressSimpleForm({ initialData: companyAddress })
  /* Function for preparing values for saving */
  const prepareValues = async (values = {}, additionalValues = {}) => {
    /* Getting id */
    const customerId =
      initialData?.['customer']?._id || getId(COLLECTIONS.CUSTOMERS)
    const userId = initialData?.['user']?._id || getId(COLLECTIONS.USERS)

    const logoUrl =
      initialData?.['customer']?.logoUrl !== values?.['customer']?.['logoUrl']
        ? await uploadImage(values?.['customer']?.['logoUrl'])
        : initialData?.['customer']?.logoUrl

    const phoneNumber = values?.['customer']?.['phoneNumber']?.includes('+')
      ? values?.['customer']?.['phoneNumber']
      : '+' + values?.['customer']?.['phoneNumber']

    /* Preparing customer values */
    const additionalReferences = { customerId }

    const preparedValues = {
      customer: {
        _id: customerId ?? null,
        name: values?.['customer']?.['name'] ?? null,
        description: values?.['customer']?.['description'] ?? null,
        logoUrl: logoUrl ?? null,
        licenseNumber: values?.['customer']?.['licenseNumber'] ?? null,
        dateOfLicenseExpiration:
          firebase.firestore.Timestamp.fromDate(
            initialData
              ? initialData?.['customer']?.['dateOfLicenseExpiration']?.toDate()
              : values?.['customer']?.['dateOfLicenseExpiration']?.toDate()
          ) ?? null,
        email: values?.['customer']?.['email'] ?? null,
        country: values?.['customer']?.['country'] ?? null,
        mainLanguage:
          mainLanguage ?? values?.['customer']?.['mainLanguage'] ?? null,
        additionalLanguage:
          values?.['customer']?.['additionalLanguage'] ?? null,
        currency: mainCurrency ?? values?.['customer']?.['currency'] ?? null,
        phoneNumber: phoneNumber ?? null,
        contactPerson: values?.['customer']?.['contactPerson'] ?? null,
        billingType: values?.['customer']?.['billingType'] ?? null,
        address: await saveBelongsToRelationship(
          COLLECTIONS.ADDRESSES,
          await prepareAddressValues(values?.['customer']?.['locationInfo']),
          additionalReferences
        )
      },
      user: {
        _id: userId ?? null,
        firstName: values?.['user']?.['firstName']?.trim() ?? null,
        lastName: values?.['user']?.['lastName']?.trim() ?? null,
        phone: values?.['user']?.['phone'] ?? null,
        email: initialData
          ? initialData?.['user']?.['email']
          : values?.['user']?.['email'] ?? null
      }
    }

    return preparedValues
  }
  /* Saving form data */
  const saveForm = async (values, callback) => {
    try {
      // Prepare data to be saved
      const data = await prepareValues(values)

      // Save data
      if (initialData) {
        await updateDocument(
          COLLECTIONS.CUSTOMERS,
          initialData.customer?._id,
          data.customer
        )

        await updateDocument(
          COLLECTIONS.USERS,
          initialData.user?._id,
          data.user
        )

        message.success(t('Profile successfully updated'))
      } else {
        await createDocument(
          COLLECTIONS.CUSTOMERS,
          data.customer,
          data.customer._id
        )
        await createDocument(COLLECTIONS.USERS, data.user, data.user._id)
        message.success(t('Profile successfully created'))
      }
      // Final callback
      callback?.(data)
    } catch (error) {
      throw new Error(getErrorText(error))
    } finally {
      setLoading(false)
    }
  }

  /* Function for validating form */
  const validateForm = (values) => {
    try {
      // Prepare data to be validated
      const validationData = {
        name: values?.['customer']?.['name'],
        logoUrl: values?.['customer']?.['logoUrl'],
        // country: values?.['customer']?.['country'] ?? null,
        phoneNumber: values?.['customer']?.['phoneNumber'] ?? null,
        contactPerson: values?.['customer']?.['contactPerson'] ?? null,
        licenseNumber: values?.['customer']?.['licenseNumber'] ?? null,
        dateOfLicenseExpiration:
          firebase.firestore.Timestamp.fromDate(
            initialData
              ? initialData?.['customer']?.['dateOfLicenseExpiration']?.toDate()
              : values?.['customer']?.['dateOfLicenseExpiration']?.toDate()
          ) ?? null,
        mainLanguage:
          mainLanguage ?? values?.['customer']?.['mainLanguage'] ?? null,
        additionalLanguage:
          values?.['customer']?.['additionalLanguage'] ?? null,
        currency: mainCurrency ?? values?.['customer']?.['currency'] ?? null,
        billingType: values?.['customer']?.['billingType'] ?? null
      }
      Customer.validationSchema.validateSync(validationData)
      const validationDataUser = {
        firstName: values?.['user']?.['firstName'] ?? null,
        lastName: values?.['user']?.['lastName'] ?? null,
        phone: values?.['user']?.['phone'] ?? null,
        email: initialData
          ? initialData?.['user']?.['email']
          : values?.['user']?.['email'] ?? null
      }
      User.validationSchema.validateSync(validationDataUser)
      validateAddressForm(values?.['customer']?.['locationInfo'])
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error(error)
      throw new Error(t('Customer validation error: ') + t(error.message))
    }
  }

  /* On finish callback */
  const onFinish = async () => {
    if (loading) return // Avoid multiple calls

    try {
      setLoading(true)
      // Get form values
      const formValues = form.getFieldsValue()
      // Validate fields
      validateForm(formValues)
      // Final callback
      const callback = () => history.goBack()
      // Save data
      await saveForm(formValues, callback)
    } catch (error) {
      setLoading(false)
      message.error(error.message)
    }
  }

  /* On cancel callback */
  const onReset = () => {
    form.resetFields()
    history.goBack()
  }

  return {
    onFinish,
    onReset,
    loading,
    saveForm,
    validateForm,
    prepareValues
  }
}

export default useActionsProfileAdvancedForm
