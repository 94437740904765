import { Col, Container, Option, Row, Title } from '@qonsoll/react-design'

import PropTypes from 'prop-types'
import { SelectWithFixedDropdown } from 'components'
import _ from 'lodash'
import { getId } from 'services/api/firebase'
import { handleFilterOptions } from 'helpers'
import { useGetAllergens } from 'domains/Allergen/hooks'
import { useMemo } from 'react'
import { useTranslations } from 'contexts/Translation'

// Takes a value of type object and onChange function
// Returns selected option object
const AllergenSelect = (props) => {
  const {
    value,
    onChange,
    showTitle = true,
    allowClear = true,
    exclude = [],
    mode,
    ...rest
  } = props

  /* Getting all possibility variants for select */
  const [allAllergens = [], loading] = useGetAllergens()
  /* Getting translation instance */
  const { t } = useTranslations()

  /* Checking if mode is multiple */
  const isMultiple = useMemo(() => mode === 'multiple', [mode])
  /* Handling case when db record not added yet but value already exists and ready to be used */
  const valueComputed = useMemo(() => {
    if (isMultiple) return value
    return value?._id || value ? getId('Allergens') : null
  }, [value, isMultiple])
  /* Filtering out excluded values */
  const optionsComputed = useMemo(() => {
    const _options = valueComputed
      ? _.uniqBy([...allAllergens, valueComputed], '_id')
      : allAllergens
    const filteredOptions = _options.filter((o) => !exclude.includes(o._id))
    return filteredOptions
  }, [allAllergens, valueComputed, exclude])

  /* Handling change selected option */
  const handleChange = (value) => {
    if (isMultiple) {
      const _options = optionsComputed.filter((o) => value.includes(o._id))
      onChange?.(_options || null, value)
    } else {
      const _option = optionsComputed.find(({ _id: id }) => id === value)
      onChange?.(_option || null, value)
    }
  }

  return (
    <Container {...rest}>
      {showTitle && (
        <Title mb={2} level={4}>
          {t('Allergen')}
        </Title>
      )}
      <Row noGutters>
        <Col>
          <SelectWithFixedDropdown
            mode={mode}
            loading={loading}
            onChange={handleChange}
            allowClear={allowClear}
            aria-label="Select an allergen"
            filterOption={handleFilterOptions}
            aria-describedby="select-description"
            placeholder={t('Please, select allergen')}
            value={isMultiple ? valueComputed : valueComputed?._id}>
            {optionsComputed.map((allergen) => (
              <Option key={allergen?._id} value={allergen?._id}>
                {allergen?.name}
              </Option>
            ))}
          </SelectWithFixedDropdown>
        </Col>
      </Row>
    </Container>
  )
}

AllergenSelect.propTypes = {
  value: PropTypes.object,
  onChange: PropTypes.func,
  showTitle: PropTypes.bool,
  allowClear: PropTypes.bool,
  exclude: PropTypes.array,
  mode: PropTypes.string
}

export default AllergenSelect
