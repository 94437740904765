const USERS = 'users'
const LOGS = 'logs'
const MAILS = 'mails'
const COMPANIES = 'companies'
const CUSTOMERS = 'customers'
const RESTAURANTS = 'restaurants'
const Q_R_CODES = 'qRCodes'
const QR_CODES = 'q-r-codes'
const ORDERS = 'orders'
const TIP_AND_TRICKS = 'tipAndTricks'
const TRAINING_MATERIALS = 'trainingMaterials'
const MEASUREMENT_TYPES = 'measurementTypes'
const ALCOHOL_TASTE_TYPES = 'alcoholTasteTypes'
const MENU_TASTES = 'menuTastes'
const MEDIA_OBJECTS = 'mediaObjects'
const ADDRESSES = 'addresses'
const ORDER_STATUSES = 'orderStatuses'
const ORDERED_ITEMS = 'orderedItems'
const INVOICES = 'invoices'
const MENUS = 'menus'
const MENU_TYPES = 'menuTypes'
const MENU_CATEGORIES = 'menuCategories'
const TABLES = 'tables'
const EDUCATIONAL_CONTENTS = 'educational-contents'
const ZONES = 'zones'
const LISTS = 'lists'
const LIST_FIELDS = 'listFields'
const TYPES = 'types'
const LIST_ITEMS = 'listItems'

const COCKTAILS = 'cocktails'
const COCKTAILS_NEW = 'cocktailsNew'
// const DECORATION_TYPES = 'decorationTypes'
const DECORATION_TYPES = 'decorationTypesNew'
// const FLAVORS = 'flavors'
const FLAVORS = 'flavorsNew'
// const GLASS_TYPES = 'glassTypes'
const GLASS_TYPES = 'glassTypesNew'
// const MAIN_SPIRIT_TYPES = 'mainSpiritTypes'
const MAIN_SPIRIT_TYPES = 'mainSpiritTypesNew'
// const ALLERGENS = 'allergens'
const ALLERGENS = 'allergensNew'
// const INGREDIENTS = 'ingredients'
const INGREDIENTS = 'ingredientsNew'
// const METHODS = 'methods'
const METHODS = 'methodsNew'
const DISHES = 'dishes'
const MENUS_DISHES = 'menusDishes'
const PAYMENTS = 'payments'
const PROFILES = 'profiles'
const RECIPES = 'recipes'
const DRINKS = 'drinks'
const VENUES = 'venues'
const COUNTRIES = 'countries'
const OBJECTS = 'objects'
const OBJECT_FIELDS = 'objectFields'
const ITEM_TYPES = 'itemTypes'
const ITEM_TYPES_FIELDS = 'itemTypesFields'
const MENU_ITEMS = 'menuItems'
const OBJECT_INSTANCES = 'objectInstances'
const INTERACTIVE_MENUS = 'interactiveMenus'
const INTERACTIVE_MENU_QUESTIONS = 'interactiveMenuQuestions'
const ENUMS = 'enums'
const ENUM_INSTANCES = 'enumInstances'

export const COLLECTIONS = {
  USERS,
  LOGS,
  MAILS,
  COMPANIES,
  CUSTOMERS,
  RESTAURANTS,
  Q_R_CODES,
  QR_CODES,
  COCKTAILS,
  COCKTAILS_NEW,
  ORDERS,
  TIP_AND_TRICKS,
  TRAINING_MATERIALS,
  INGREDIENTS,
  MEASUREMENT_TYPES,
  ALLERGENS,
  METHODS,
  ALCOHOL_TASTE_TYPES,
  MAIN_SPIRIT_TYPES,
  FLAVORS,
  GLASS_TYPES,
  DECORATION_TYPES,
  MENU_TASTES,
  MEDIA_OBJECTS,
  ADDRESSES,
  ORDER_STATUSES,
  ORDERED_ITEMS,
  INVOICES,
  MENUS,
  MENU_TYPES,
  MENU_CATEGORIES,
  TABLES,
  EDUCATIONAL_CONTENTS,
  ZONES,
  DISHES,
  MENUS_DISHES,
  PAYMENTS,
  PROFILES,
  RECIPES,
  DRINKS,
  VENUES,
  COUNTRIES,
  LISTS,
  TYPES,
  LIST_FIELDS,
  OBJECTS,
  OBJECT_FIELDS,
  LIST_ITEMS,
  ITEM_TYPES,
  ITEM_TYPES_FIELDS,
  MENU_ITEMS,
  OBJECT_INSTANCES,
  INTERACTIVE_MENUS,
  INTERACTIVE_MENU_QUESTIONS,
  ENUMS,
  ENUM_INSTANCES
}
export default COLLECTIONS
