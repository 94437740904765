import { Box, Text } from '@qonsoll/react-design'
import { DragDropContext, Droppable } from 'react-beautiful-dnd'
import { StyledWrapper, styles } from './ItemTypeFieldsForm.styles'
import { useEffect, useState } from 'react'

import { ItemTypeFieldSimpleForm } from 'domains/ItemTypeField/components'
import PropTypes from 'prop-types'
import { generateNewObjectField } from 'domains/Object/helpers'
import { reorder } from 'helpers'
import { useTranslations } from '@qonsoll/translation'

const ItemTypeFieldsForm = (props) => {
  const { value, onChange, selectedLanguage } = props

  const [fields, setFields] = useState({})

  const { t } = useTranslations()

  useEffect(() => {
    value && setFields(value)
  }, [value])

  const onDragEnd = (result) => {
    // dropped outside the list
    if (!result.destination) {
      return
    }

    const items = reorder(
      Object.values(fields),
      result.source.index,
      result.destination.index
    )

    const newFields = Object.fromEntries(
      items?.map((item) => [item?._id || item?.uid, item]) || []
    )

    setFields(newFields)
    onChange?.(newFields)
  }

  const onAddField = () => {
    const newField = generateNewObjectField()

    const order = Object.keys(fields || {}).length

    const newFields = { ...fields, [newField?.uid]: { ...newField, order } }

    setFields(newField)
    onChange?.(newFields)
  }

  const onRemoveField = (uid) => () => {
    const newFields = { ...fields }
    delete newFields[uid]

    setFields(newFields)
    onChange?.(newFields)
  }

  const isRemoveButtonDisabled = Object.keys(fields || {}).length === 1

  return (
    <DragDropContext onDragEnd={onDragEnd}>
      <Droppable droppableId="droppable">
        {(provided) => (
          <StyledWrapper {...provided.droppableProps} ref={provided.innerRef}>
            {Object.values(fields || {})
              .sort((a, b) => a?.order - b?.order)
              ?.map((field, index) => (
                <ItemTypeFieldSimpleForm
                  key={field?.uid || field?._id}
                  field={field}
                  index={index}
                  selectedLanguage={selectedLanguage}
                  onRemoveField={onRemoveField}
                  isRemoveButtonDisabled={isRemoveButtonDisabled}
                  listsFormValue={value}
                />
              ))}
            {provided.placeholder} {/* Add the placeholder element */}
          </StyledWrapper>
        )}
      </Droppable>
      <Box pt={2}>
        <Text {...styles.addFieldButtonStyles} onClick={onAddField}>
          + {t('Add field')}
        </Text>
      </Box>
    </DragDropContext>
  )
}

ItemTypeFieldsForm.propTypes = {
  value: PropTypes.array,
  onChange: PropTypes.func,
  selectedLanguage: PropTypes.string
}

export default ItemTypeFieldsForm
