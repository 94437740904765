import { Box, Title } from '@qonsoll/react-design'
import { useEffect, useState } from 'react'
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd'
import { ButtonAccent } from 'components'
import { Icon } from '@qonsoll/icons'
import { MenuCategoryFormDrawer } from 'domains/MenuCategory/components'
import PropTypes from 'prop-types'
import { useDragAndDrop } from 'domains/Menu/contexts'

function DragableList(props) {
  const {
    listItems,
    renderItem,
    onCategoryEdit,
    onCategoryDelete,
    tableListWrapperStyles,
    disabled,
    categoryId
  } = props

  const [sortableItems, setSortableItems] = useState([])
  const [categoryValue, setCategoryValue] = useState()
  const [visibilityEdit, setVisibilityEdit] = useState(false)

  const { updateDndConfig, initializeDndConfig, currentSelectedCategory } =
    useDragAndDrop()

  const changeVisibility = () => setVisibilityEdit((prev) => !prev)

  const onSelectCategory = (value) => {
    setCategoryValue(value)
    value && changeVisibility()
  }

  const reorder = (list, startIndex, endIndex) => {
    const result = Array.from(list)
    const [removed] = result.splice(startIndex, 1)
    result.splice(endIndex, 0, removed)

    return result
  }

  function onSortEnd(result) {
    // dropped outside the list
    if (!result.destination) {
      return
    }

    const updatedItems = reorder(
      sortableItems,
      result.source.index,
      result.destination.index
    )

    setSortableItems(updatedItems)
    updateDndConfig(updatedItems, categoryId)
  }

  useEffect(() => {
    let isComponentMounted = true
    if (isComponentMounted) {
      setSortableItems(listItems)
      initializeDndConfig(listItems, categoryId)
    }
    return () => {
      isComponentMounted = false
    }
  }, [
    initializeDndConfig,
    listItems,
    listItems.length,
    currentSelectedCategory,
    categoryId
  ])

  const onDeleteCategory = () => (index) => {
    onCategoryDelete(index)
  }

  const onCategorySelect =
    () =>
    ({ index, name }) => {
      onSelectCategory({
        index,
        name
      })
    }

  return (
    <DragDropContext onDragEnd={onSortEnd}>
      <Droppable droppableId="droppable">
        {(provided, snapshot) => (
          <Box
            ref={provided.innerRef}
            {...provided.droppableProps}
            background="var(--ql-bg-white-default)"
            overflowY="scroll"
            {...tableListWrapperStyles}>
            {sortableItems?.map((item, index) => (
              <Draggable
                key={item?._id}
                disabled={disabled}
                draggableId={item?._id}
                index={index}>
                {(provided, snapshot) => (
                  <Box ref={provided.innerRef} {...provided.draggableProps}>
                    {item?.isDisabled ? (
                      <Box display="flex" alignItems="center">
                        <Title mr={2} level={4} my={3}>
                          {item?.name}
                        </Title>
                        <ButtonAccent
                          type="text"
                          icon={
                            <Box>
                              <Icon name="Edit3Outlined" />
                            </Box>
                          }
                          onClick={onCategorySelect({
                            index: index,
                            name: item?.name
                          })}
                        />
                        <ButtonAccent
                          type="text"
                          danger
                          icon={<Icon name="Trash4Outlined" />}
                          onClick={onDeleteCategory}
                        />
                      </Box>
                    ) : (
                      <Box display="flex" alignItems="center">
                        {renderItem
                          ? renderItem(item, index, provided.dragHandleProps)
                          : item}
                      </Box>
                    )}
                  </Box>
                )}
              </Draggable>
            ))}
          </Box>
        )}
      </Droppable>
      {categoryValue && (
        <MenuCategoryFormDrawer
          editValue={categoryValue}
          onCategoryUpdate={onCategoryEdit}
          visibility={visibilityEdit}
          changeVisibility={changeVisibility}
        />
      )}
    </DragDropContext>
  )
}

DragableList.propTypes = {
  onUpdate: PropTypes.func,
  onCategoryEdit: PropTypes.func,
  onCategoryDelete: PropTypes.func,
  listItems: PropTypes.array,
  renderItem: PropTypes.node,
  tableListWrapperStyles: PropTypes.object,
  targetDragElementIds: PropTypes.arrayOf(PropTypes.string),
  disabled: PropTypes.bool,
  categoryId: PropTypes.string
}

export default DragableList
