import { Box, Input } from '@qonsoll/react-design'
import {
  DrawerStyled,
  Overlay
} from 'domains/Dish/components/DishesListWithCreate/components/DishItemFormDrawer/DishItemFormDrawer.styled'

import { ButtonAccent } from 'components'
import { Form, Tabs } from 'antd'
import PropTypes from 'prop-types'
import { onTextAreaEnterPress } from 'helpers'
import { Fragment, useState, useEffect } from 'react'
import { useTranslations } from 'contexts/Translation'

const MenuCategoryFormDrawer = (props) => {
  const { value, isEditing, handleCancel, handleChange } = props

  const [selectedLanguage, setSelectedLanguage] = useState('en')
  const [isDisabled, setIsDisabled] = useState(false)
  const { t, languages } = useTranslations()
  const [form] = Form.useForm()

  const inputAddon = selectedLanguage?.toUpperCase()

  const onLanguageChange = (key) => setSelectedLanguage(key)
  const onCancel = () => {
    setIsDisabled(false)
    handleCancel?.()
    form.resetFields()
  }
  const validateEnglishLabel = () => {
    const formData = form.getFieldsValue()
    if (formData?.name?.en?.trim() === '') {
      setIsDisabled(true)
      return Promise.reject(new Error(t('English label is required')))
    } else {
      setIsDisabled(false)
      return Promise.resolve()
    }
  }

  const onSubmit = () => {
    const data = form.getFieldsValue()
    handleChange?.({ data, itemTypeId: value?.itemTypeId })
    form.resetFields()
  }

  useEffect(() => {
    if (value) {
      form.setFieldsValue(value)
    }
  }, [form, value])

  return (
    <Form
      preserve
      form={form}
      layout="vertical"
      autoComplete="off"
      scrollToFirstError
      initialValues={{ value }}>
      <Overlay showForm={isEditing} />
      <DrawerStyled showForm={isEditing}>
        <Tabs activeKey={selectedLanguage} onChange={onLanguageChange}>
          {languages?.map((language) => (
            <Tabs.TabPane key={language?.shortCode} tab={language?.name} />
          ))}
        </Tabs>
        <Form.Item name="name">
          {/* It works correctly with fragment or any empty tag */}
          <Fragment>
            <Form.Item
              label={t('Name')}
              name={['name', selectedLanguage]}
              rules={[
                {
                  required: true,
                  message: t('Please, enter your menu category name!')
                },
                { validator: validateEnglishLabel }
              ]}>
              <Input
                addonAfter={inputAddon}
                onPressEnter={onTextAreaEnterPress}
                placeholder={t('Please, enter your menu category name')}
              />
            </Form.Item>
          </Fragment>
        </Form.Item>
        <Box display="flex">
          <ButtonAccent mr={2} type="secondary" onClick={onCancel}>
            {t('Cancel')}
          </ButtonAccent>
          <ButtonAccent disabled={isDisabled} type="primary" onClick={onSubmit}>
            {t('Save')}
          </ButtonAccent>
        </Box>
      </DrawerStyled>
    </Form>
  )
}

MenuCategoryFormDrawer.propTypes = {
  isEditing: PropTypes.bool,
  handleCancel: PropTypes.func,
  handleChange: PropTypes.func,
  value: PropTypes.array
}

export default MenuCategoryFormDrawer
