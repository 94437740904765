import { Row } from '@qonsoll/react-design'
import styled from 'styled-components'

export const EnumSelectWrapper = styled(Row)`
  border-radius: var(--enum-select-border-radius);
  border: 1px solid var(--enum-select-border);
  overflow: hidden;
`

export const SelectableCol = styled.section.attrs({ role: 'option' })`
  z-index: 48;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  cursor: ${({ isDisabled }) => (isDisabled ? ' not-allowed' : 'pointer')};
  padding: 8px;
  background: ${(props) =>
    props.selected
      ? 'var(--enum-select-selected-bg)'
      : 'var(--enum-select-bg)'};
  border-right: ${(props) =>
    props.isLast ? 'none' : '1px solid var(--enum-select-border)'};
  font-weight: var(--ql-font-weight-semibold);

  background: ${({ isDisabled, selected }) =>
    isDisabled &&
    (selected
      ? 'var(--enum-select-selected-disabled-bg)'
      : 'var(--enum-select-disabled-bg)')};
`

export const selectStyles = {
  display: 'flex',
  flexDirection: 'row',
  width: '100%'
}

export const enumTextStyles = {
  default: 'var(--enum-select-color)',
  selected: 'var(--enum-select-selected-color)',
  isDisabled: 'var(--enum-select-disabled-color)'
}
