import { Box, Col, Row, Title } from '@qonsoll/react-design'
import { ButtonAccent, ButtonWithPopconfirm } from 'components'
import { COLLECTIONS, KEYS } from '__constants__'
import {
  useActionsRestaurantAdvancedForm,
  useInitializeRestaurantAdvancedForm
} from './hooks'

import { AddressSimpleForm } from 'domains/Address/components'
import { Form } from 'antd'
import { MenuSelectableList } from 'domains/Menu/components'
import PropTypes from 'prop-types'
import { RestaurantSimpleForm } from 'domains/Restaurant/components'
import { doc } from 'firebase/firestore'
import { firestore } from 'services/firebase'
import { useDocumentDataOnce } from 'react-firebase-hooks/firestore'
import { useParams } from 'react-router-dom'
import { useState } from 'react'
import { useTranslations } from 'contexts/Translation'

const RestaurantAdvancedForm = (props) => {
  const { initialData, group, showTitle = false } = props

  const [isFormChanged, setIsFormChanged] = useState(false)

  /* Getting translations instance */
  const { t } = useTranslations()
  const { companyId } = useParams()
  const [companyData] = useDocumentDataOnce(
    (companyId || initialData?.restaurant?.companyId) &&
      doc(
        firestore,
        COLLECTIONS.CUSTOMERS,
        companyId || initialData?.restaurant?.companyId
      )
  )
  /* Getting form instance from hook */
  const { form } = useInitializeRestaurantAdvancedForm(initialData, companyData)
  /* Handling enter key press */
  const handleKeyPress = (e) => {
    if (e.key === KEYS.ENTER) {
      e.preventDefault()
      onFinish()
    }
  }
  /* Getting loading state and actions from hook */
  const { loading, onFinish, onReset } = useActionsRestaurantAdvancedForm({
    initialData,
    form
  })
  const onFormValuesChange = () => setIsFormChanged(true)

  return group ? (
    <>
      {showTitle && (
        <Title level={4} mb={2}>
          {t('Restaurant')}
        </Title>
      )}
      <RestaurantSimpleForm
        initialData={initialData}
        showTitle={false}
        group={[...group, 'restaurant']}
      />
      <AddressSimpleForm
        description={initialData?.address?.description}
        group={[...group, 'address']}
        showTitle
      />
      <Row noGutters mb={2}>
        <Col>
          <Title level={4}>{t('Menus')}</Title>
        </Col>
      </Row>
      <Form.Item name={[group, 'menus']} label={t('Venue menu')}>
        <MenuSelectableList
          transformationLimit={0}
          forRestaurant
          companyData={companyData}
        />
      </Form.Item>
    </>
  ) : (
    <Form
      scrollToFirstError
      onValuesChange={onFormValuesChange}
      onKeyPress={handleKeyPress}
      onFinish={onFinish}
      layout="vertical"
      autoComplete="off"
      form={form}>
      {showTitle && (
        <Title level={4} mb={2}>
          {t('Restaurant')}
        </Title>
      )}
      <RestaurantSimpleForm initialData={initialData} group={['restaurant']} />
      <AddressSimpleForm
        description={initialData?.address?.description}
        group={['address']}
        showTitle
      />

      <Row noGutters mb={2}>
        <Col>
          <Title level={4}>{t('Menus')}</Title>
        </Col>
      </Row>
      <Form.Item name={['menus']} label={t('Venue menu')}>
        <MenuSelectableList
          transformationLimit={0}
          forRestaurant
          companyData={companyData}
        />
      </Form.Item>
      <Box display="flex">
        <ButtonWithPopconfirm
          onReset={onReset}
          documentTitle={initialData?.restaurant?.name}
          withPopconfirm={isFormChanged}
        />

        <ButtonAccent
          type="primary"
          loading={loading}
          onClick={() => form.submit()}>
          {t('Save')}
        </ButtonAccent>
      </Box>
    </Form>
  )
}
RestaurantAdvancedForm.propTypes = {
  initialData: PropTypes.object,
  group: PropTypes.array,
  showTitle: PropTypes.bool
}

export default RestaurantAdvancedForm
