import { Box, Col, Row, Text } from '@qonsoll/react-design'
import { HeaderWrapper, staticColStyle } from './ScrollableTableHeader.styled'
import { ScrollableCol, ScrollableRow } from 'components/Table/Table.styled'

import { Badge } from 'antd'
import { NOT_VISIBLE_TITLE } from '__constants__'
import PropTypes from 'prop-types'
import { ScrollSyncPane } from 'react-scroll-sync'
import { useDefineIfScrollable } from 'components/Table/hooks'
import { useRef } from 'react'

const ScrollableTableHeader = (props) => {
  const { tableProps, numberOfAvailability } = props
  const {
    scrollableContainerMinWidth,
    fixedColCw,
    scrollableColCw,
    headerTitles
  } = tableProps

  // whether col is scrollable and should have a shadow or not
  const ref = useRef()
  const isScrollable = useDefineIfScrollable(ref, scrollableContainerMinWidth)
  const colShadow = isScrollable ? 'var(--table-static-col-shadow)' : 'none'

  // slice array to remove first element, as it is static
  const scrollableItemsArray = headerTitles?.slice(1)

  const titleHorizontalAlign = (title) =>
    title === NOT_VISIBLE_TITLE ? 'right' : 'left'
  const actionsTitleOpacity = (title) =>
    title === NOT_VISIBLE_TITLE ? '0' : '1'

  return (
    <HeaderWrapper>
      <Row width="inherit" noGutters overflow="hidden">
        <Col
          cw={fixedColCw}
          {...staticColStyle}
          boxShadow={colShadow}
          v="center">
          <Text fontWeight="var(--ql-font-weight-bold)">
            {headerTitles[0]?.title}
          </Text>
        </Col>
        <ScrollSyncPane group="horizontal">
          <ScrollableCol cw={scrollableColCw} {...staticColStyle}>
            <ScrollableRow minWidth={scrollableContainerMinWidth} ref={ref}>
              {scrollableItemsArray?.map((item, index) => (
                <Col
                  display="flex"
                  v="center"
                  key={`${index}-title`}
                  h={titleHorizontalAlign(item?.title)}
                  cw={11 / scrollableItemsArray?.length}>
                  <Box alignItems="center" display="flex">
                    <Text
                      mr={2}
                      fontWeight="var(--ql-font-weight-bold)"
                      opacity={actionsTitleOpacity(item?.title)}>
                      {item?.title}
                    </Text>
                    <Box display={item?.withCounter ? 'block' : 'none'}>
                      <Badge showZero count={numberOfAvailability} />
                    </Box>
                  </Box>
                </Col>
              ))}
            </ScrollableRow>
          </ScrollableCol>
        </ScrollSyncPane>
      </Row>
    </HeaderWrapper>
  )
}

ScrollableTableHeader.propTypes = {
  tableProps: PropTypes.object,
  numberOfAvailability: PropTypes.number
}

export default ScrollableTableHeader
