import { AdminList } from 'domains/Admin/components'
import { Container } from '@qonsoll/react-design'
import PropTypes from 'prop-types'

export default function CustomerAdmins(props) {
  const { admins, adminsInvited, user } = props

  return (
    <Container>
      <AdminList
        adminsInvited={adminsInvited}
        cw={[12, 6]}
        admins={admins}
        user={user}
      />
    </Container>
  )
}

CustomerAdmins.propTypes = {
  admins: PropTypes.object.isRequired,
  adminsInvited: PropTypes.array,
  user: PropTypes.object.isRequired
}
