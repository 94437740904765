const styles = {
  image: {
    width: '128px',
    height: '128px',
    objectFit: 'cover',
    cursor: 'pointer'
  }
}

export default styles
