import { useEffect } from 'react'
import { Form } from 'antd'
import { convertTimestamp } from 'helpers'
import moment from 'moment'

const useInitializeCustomerAdvancedForm = (initialData) => {
  /* Getting form instance */
  const [form] = Form.useForm()

  /* Setting initial data */
  useEffect(() => {
    if (initialData) {
      /* Getting initial values */
      const { customer, restaurants } = initialData

      const date = customer?.['dateOfLicenseExpiration']
        ? moment(convertTimestamp(customer?.['dateOfLicenseExpiration']))
        : null

      /* Formatting date fields */
      const formValues = {
        customer: {
          name: customer?.['name'] ?? null,
          logoUrl: customer?.['logoUrl'] ?? null,
          typeOfLicense: customer?.['typeOfLicense'] ?? null,
          licenseNumber: customer?.['licenseNumber'] ?? null,
          dateOfLicenseExpiration: date,
          email: customer?.['email'] ?? null
        },
        restaurants: restaurants?.map((e) => e?._id).filter((e) => e) ?? []
      }

      /* Setting initial values to form instance */
      form.setFieldsValue(formValues)
    }
  }, [initialData, form])

  return { form }
}

export default useInitializeCustomerAdvancedForm
