import { Col, PageWrapper, Row } from '@qonsoll/react-design'

import { CustomerAdminInviteForm } from 'domains/Customer/components'
import { HeaderBreadcrumbs, Spinner } from 'components'
import { useCustomerContext } from 'contexts/CustomerContext'
import { useHistory } from 'react-router-dom'
import { useTranslations } from 'contexts/Translation'
import { COLLECTIONS } from '__constants__'
import { useCollectionData } from 'react-firebase-hooks/firestore'
import { collection, query, where } from 'firebase/firestore'
import { firestore } from 'services/firebase'

const CustomerAdminInvite = () => {
  /* Getting history instance */
  const history = useHistory()
  const { t } = useTranslations()

  /* Configuring page header */
  const headingProps = {
    title: t('Invite company administrator'),
    marginBottom: 3,
    textAlign: 'left'
  }

  const { currentCustomerId } = useCustomerContext()

  /* Function to handle back click */
  const handleBack = () => history.goBack()

  const [users, usersLoading] = useCollectionData(
    query(
      collection(firestore, COLLECTIONS.USERS),
      where('_companies', 'array-contains', currentCustomerId)
    )
  )

  return (
    <PageWrapper
      onBack={handleBack}
      breadcrumbs={<HeaderBreadcrumbs />}
      headingProps={headingProps}>
      <Row noGutters>
        <Col cw={[12, 12, 10, 8, 7]}>
          {usersLoading ? (
            <Spinner height="60vh" text={t('Loading admin invitation')} />
          ) : (
            <CustomerAdminInviteForm
              customerId={currentCustomerId}
              users={users}
            />
          )}
        </Col>
      </Row>
    </PageWrapper>
  )
}

export default CustomerAdminInvite
