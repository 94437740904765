import {
  createDocument,
  // getDocument,
  getId,
  saveBelongsToRelationship,
  updateDocument,
  updateParent
} from 'services/api/firebase'
import { useHistory, useParams } from 'react-router-dom'

import { COLLECTIONS } from '__constants__'
import { Restaurant } from 'models'
import { message } from 'antd'
import { uploadImage } from 'helpers'
import { useActionsAddressSimpleForm } from 'domains/Address/components/AddressSimpleForm/hooks'
import { useAutoGenerateLicenseNumber } from 'domains/Customer/hooks'
import { useGetErrorText } from 'hooks'
import { useState } from 'react'
import { useTranslations } from 'contexts/Translation'

const useActionsRestaurantAdvancedForm = ({ initialData, form } = {}) => {
  /* State for loading */
  const [loading, setLoading] = useState(false)
  /* Getting translations instance */
  const { t } = useTranslations()
  /* Getting history instance */
  const history = useHistory()
  /* Getting params instance */
  const params = useParams()
  /* Getting history state */
  const historyState = history.location.state
  /* Getting Address values and validation function */
  const {
    prepareValues: prepareAddressValues,
    validateForm: validateAddressForm
  } = useActionsAddressSimpleForm({ initialData: initialData?.address })
  const { autoGenerateLicenseNumber } = useAutoGenerateLicenseNumber()
  const getErrorText = useGetErrorText()
  /* Function for preparing values for saving */
  const prepareValues = async (values = {}, additionalValues = {}) => {
    /* Getting id */
    const restaurantId =
      initialData?.['restaurant']?._id || getId(COLLECTIONS.RESTAURANTS)
    const menusToArray =
      typeof values?.menus === 'string' ? [values?.menus] : values?.menus

    /* Preparing restaurant values */
    const additionalReferences = { restaurantId }
    const preparedValues = {
      _id: restaurantId,
      ...additionalValues,
      licenseNumber: autoGenerateLicenseNumber(values) ?? null,
      billingType: values?.['restaurant']?.['billingType'] ?? null,
      typeOfLicense: values?.['restaurant']?.['typeOfLicense'] ?? null,
      typeOfPlace: values?.['restaurant']?.['typeOfPlace'] ?? null,
      name: values?.['restaurant']?.['name'] ?? null,
      welcomeMessage: values?.['restaurant']?.['welcomeMessage'] ?? null,
      description: values?.['restaurant']?.['description'] ?? null,
      logoUrl: (await uploadImage(values?.['restaurant']?.['logoUrl'])) ?? null,
      address: await saveBelongsToRelationship(
        COLLECTIONS.ADDRESSES,
        await prepareAddressValues(values['address']),
        additionalReferences
      ),
      menus: menusToArray ?? [],
      orders: values['orders'] ?? [],
      currency: values?.['restaurant']?.['currency'] ?? null
    }
    if (params.orderId) preparedValues['orderId'] = params.orderId
    if (
      !initialData &&
      historyState?.parent &&
      historyState?.parent?.collection === COLLECTIONS.CUSTOMERS
    ) {
      preparedValues['companyId'] = historyState?.parent?.id
      // const customerDoc = await getDocument(
      //   COLLECTIONS.CUSTOMERS,
      //   historyState?.parent?.id
      // )
      // if (customerDoc?.automaticMenuId)
      //   preparedValues['automaticMenuId'] = customerDoc?.automaticMenuId
    }
    return preparedValues
  }

  /* Saving form data */
  const saveForm = async (values, callback) => {
    try {
      // Prepare data to be saved
      const data = await prepareValues(values)

      // Updating parent references
      // if(params.orderId) await updateParentHasOneRelationship (params.orderId, COLLECTIONS.ORDERS, 'restaurant', data._id)
      if (historyState?.parent)
        await updateParent(historyState.parent, data._id)

      // Save data
      if (initialData) {
        await updateDocument(
          COLLECTIONS.RESTAURANTS,
          initialData.restaurant?._id,
          data
        )
        message.success(t('Restaurant successfully updated'))
      } else {
        await createDocument(COLLECTIONS.RESTAURANTS, data, data._id)
        message.success(t('Restaurant successfully created'))
      }
      // Final callback
      callback?.()
    } catch (error) {
      throw new Error(getErrorText(error))
    }
  }

  /* Function for validating form */
  const validateForm = (values) => {
    try {
      // Prepare data to be validated
      const validationData = {
        name: values?.['restaurant']?.['name']?.trim(),
        welcomeMessage: values?.['restaurant']?.['welcomeMessage']?.trim(),
        description: values?.['restaurant']?.['description']?.trim(),
        logoUrl: values?.['restaurant']?.['logoUrl']
      }
      Restaurant.validationSchema.validateSync(validationData)
      validateAddressForm(values['address'])
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error(error)
      throw new Error(t('Restaurant validation error: ') + t(error.message))
    }
  }

  /* On finish callback */
  const onFinish = async () => {
    if (loading) return // Avoid multiple calls

    try {
      setLoading(true)
      // Get form values
      const formValues = form.getFieldsValue()
      // Validate fields
      validateForm(formValues)
      // Final callback
      const callback = () => history.goBack()
      // Save data
      await saveForm(formValues, callback)
    } catch (error) {
      setLoading(false)
      message.error(error.message)
    }
  }

  /* On cancel callback */
  const onReset = () => {
    form.resetFields()
    history.goBack()
  }

  return { onFinish, onReset, loading, saveForm, validateForm, prepareValues }
}

export default useActionsRestaurantAdvancedForm
