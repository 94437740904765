const styles = {
  fieldTitleProps: {
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    maxWidth: '450px',
    whiteSpace: 'nowrap'
  },
  fieldRowProps: {
    justifyContent: 'center',
    alignItems: 'center'
  },
  restaurantImg: {
    height: '160px',
    objectFit: 'contain',
    width: '100%',
    borderRadius: 'var(--card-radius)'
  },
  clamp: {
    clamp: '2'
  }
}

export default styles
