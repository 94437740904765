import { useCallback, useEffect } from 'react'

import { Form } from 'antd'
import { LIST_FIELD_DATA_TYPES } from '__constants__'

const useInitializeItemTypeInstanceAdvancedForm = ({ initialData, value }) => {
  const [form] = Form.useForm()

  const prepareListItemsValue = useCallback(
    (listFields) => {
      return (
        listFields?.map(
          ({ name, label, relationType, isRequired, _id, ...item }) => {
            // If object, fetch data from db and write it to the field with corresponding name

            let resultValue = item?.object
              ? value?.find((item) => item?.name === name)?.value
              : initialData?.menuItem?.[name]

            const emptyValue =
              relationType === LIST_FIELD_DATA_TYPES.HAS_MANY ? [] : null
            return {
              fieldName: name,
              label,
              dataType: relationType,
              [name]: resultValue ?? emptyValue,
              isRequired,
              _id,
              name: {
                en: initialData?.menuItem?.[name]?.en ?? '',
                no: initialData?.menuItem?.[name]?.no ?? ''
              },
              ...item
            }
          }
        ) ?? []
      )
    },
    [initialData?.menuItem, value]
  )

  useEffect(() => {
    const initialValues = {
      listItemFields: prepareListItemsValue(initialData?.itemTypeFieldsSorted)
    }

    form.setFieldsValue(initialValues)
  }, [form, initialData, prepareListItemsValue])

  return {
    form
  }
}

export default useInitializeItemTypeInstanceAdvancedForm
