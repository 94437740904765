import { COLLECTIONS, OBJECT_RELATION_TYPES } from '__constants__'

import PropTypes from 'prop-types'
import { Select } from 'antd'
import { doc } from 'firebase/firestore'
import { firestore } from 'services/firebase'
import { useDocumentData } from 'react-firebase-hooks/firestore'
import { useGetListItems } from 'domains/List/hooks'
import { useLoading } from 'hooks'
import { useMemo } from 'react'
import { useTranslations } from '@qonsoll/translation'

const ReferenceSelect = (props) => {
  const { item, value, onChange } = props

  const dataType = item?.dataType || item?.relationType
  const list = item?.list || item?.reference?.split('-')[1]
  const { language } = useTranslations()

  const [listData, listDataLoading] = useDocumentData(
    list && doc(firestore, COLLECTIONS.LISTS, list)
  )

  const [listItems, listItemsLoading] = useGetListItems(listData)

  const loading = useLoading([listDataLoading, listItemsLoading])
  const selectOptions = useMemo(() => {
    if (listItems) {
      const customOptions = listItems?.map((listItem) => ({
        label:
          listItem?.name?.[language] ?? listItem?.name?.en ?? listItem?.name,
        value: listItem?._id
      }))
      return customOptions
    }
    return []
  }, [language, listItems])

  return (
    <Select
      value={!loading && (value ?? [])}
      onChange={onChange}
      loading={loading}
      options={selectOptions}
      mode={dataType === OBJECT_RELATION_TYPES.HAS_MANY && 'multiple'}
    />
  )
}

ReferenceSelect.propTypes = {
  item: PropTypes.object,
  value: PropTypes.any,
  onChange: PropTypes.func
}

export default ReferenceSelect
