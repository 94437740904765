import { COLLECTIONS, MENU_TYPES_KEYS } from '__constants__'
import {
  createDocument,
  deleteDocument,
  updateDocument
} from 'services/api/firebase'
import { doc, writeBatch } from 'firebase/firestore'

import { firestore } from 'services/firebase'
import { message } from 'antd'
import { useState } from 'react'
import { useTranslations } from 'contexts/Translation'
import { useDragAndDrop } from 'domains/Menu/contexts'

const useMenuItemsActions = ({
  menu,
  updateMenuCategoryConfig,
  menuConfig
}) => {
  /* Getting translations instance */
  const { t } = useTranslations()

  const [saveMenuOrderLoading, setSaveMenuOrderLoading] = useState(false)
  const { dndConfig, clearDndChangeState } = useDragAndDrop()

  const onCreateMenuItemCallback = async (cocktailData) => {
    try {
      const itemData = await createDocument(
        `${COLLECTIONS.MENUS}/${menu?._id}/${
          menu?.menuType === MENU_TYPES_KEYS.DRINK_TYPE
            ? COLLECTIONS.COCKTAILS
            : COLLECTIONS.DISHES
        }`,
        cocktailData
      )

      const oldMenuConfig = JSON.parse(JSON.stringify(menu?.menuConfig))
      updateMenuCategoryConfig([...oldMenuConfig, { _id: itemData?._id }])
    } catch (err) {
      message.error(t('Error during new item create at menu'))
    }
  }

  const onSelectCocktailsCallback = async (itemsArray) => {
    try {
      const batch = writeBatch(firestore)

      itemsArray.forEach((item) => {
        batch.set(
          doc(
            firestore,
            `${COLLECTIONS.MENUS}/${menu?._id}/${
              menu?.menuType === MENU_TYPES_KEYS.DRINK_TYPE
                ? COLLECTIONS.COCKTAILS
                : COLLECTIONS.DISHES
            }`,
            item?._id
          ),
          item
        )
      })

      await batch.commit()

      const oldMenuConfig = JSON.parse(JSON.stringify(menu?.menuConfig))
      updateMenuCategoryConfig([
        ...oldMenuConfig,
        ...itemsArray.map((item) => ({ _id: item?._id }))
      ])
    } catch (err) {
      message.error(t('Error during existing items add to the menu'))
    }
  }

  const onCategoryCreate = async (categoryName) => {
    try {
      const oldMenuConfig = JSON.parse(JSON.stringify(menu?.menuConfig))
      await updateMenuCategoryConfig([
        ...oldMenuConfig,
        { isDisabled: true, name: categoryName }
      ])
    } catch (err) {
      message.error(t('Error during submenu create'))
    }
  }

  const onDeleteItemCallback = async (itemId) => {
    try {
      const filteredMenuConfig = menu?.menuConfig?.filter(
        (item) => item?._id !== itemId
      )
      await updateMenuCategoryConfig(filteredMenuConfig)
      await deleteDocument(
        `${COLLECTIONS.MENUS}/${menu?._id}/${
          menu?.menuType === MENU_TYPES_KEYS.DRINK_TYPE
            ? COLLECTIONS.COCKTAILS
            : COLLECTIONS.DISHES
        }`,
        itemId
      )
    } catch (err) {
      message.error(t('Error during submenu create'))
    }
  }

  const onSaveItemsOrder = async (categoryId) => {
    try {
      setSaveMenuOrderLoading(true)

      const newOrder = dndConfig?.[categoryId]?.map(({ _id }) => _id)
      await updateDocument(COLLECTIONS.MENU_CATEGORIES, categoryId, {
        itemsOrder: newOrder
      })

      clearDndChangeState(categoryId)

      message.success(t('Items order successfully saved'))
    } catch (err) {
      message.error(`${t('Error during saving items order')}, ${err?.message}`)
    } finally {
      setSaveMenuOrderLoading(false)
    }
  }

  const onItemEditCallback = async (newData, index) => {
    try {
      await updateDocument(
        `${COLLECTIONS.MENUS}/${menu?._id}/${
          menu?.menuType === MENU_TYPES_KEYS.DRINK_TYPE
            ? COLLECTIONS.COCKTAILS
            : COLLECTIONS.DISHES
        }`,
        newData?._id,
        newData
      )
      message.success(t('Item was updated successfully!'))
    } catch (err) {
      message.error(t('Error during menu item edit'))
    }
  }

  const onCategoryEdit = async (values) => {
    try {
      const newName = values?.name
      const objIndex = values?.index

      menuConfig[objIndex].name = newName

      await updateMenuCategoryConfig(menuConfig)
    } catch (err) {
      message.error(t('Error during submenu edit'))
    }
  }

  const onCategoryDelete = async (index) => {
    try {
      menuConfig.splice(index, 1)
      await updateMenuCategoryConfig(menuConfig)
    } catch (err) {
      message.error(t('Error during submenu delete'))
    }
  }

  return {
    saveMenuOrderLoading,
    onCreateMenuItemCallback,
    onSelectCocktailsCallback,
    onCategoryCreate,
    onDeleteItemCallback,
    onSaveItemsOrder,
    onItemEditCallback,
    onCategoryEdit,
    onCategoryDelete
  }
}

export default useMenuItemsActions
