import { useEffect, useState } from 'react'

import { Box } from '@qonsoll/react-design'
import { Input } from 'antd'
import PropTypes from 'prop-types'

const CocktailPriceColumn = (props) => {
  const { price, onSelect, index, cocktailId } = props

  const [currentPrice, setCurrentPrice] = useState(0)

  useEffect(() => {
    setCurrentPrice(price ?? 0)
  }, [price])

  const handleBlur = (e) => {
    if (e.target.value >= 0) {
      onSelect({ index: index, price: Number(e.target.value), cocktailId })
    }
  }

  const handleChange = (e) => {
    if (e.target.value >= 0) {
      setCurrentPrice(e.target.value)
    }
  }

  return (
    <Box minWidth={70}>
      <Input
        value={currentPrice}
        type="number"
        onChange={handleChange}
        onBlur={handleBlur}
      />
    </Box>
  )
}

CocktailPriceColumn.propTypes = {
  price: PropTypes.number,
  cocktailId: PropTypes.string,
  onSelect: PropTypes.func,
  index: PropTypes.number
}

export default CocktailPriceColumn
