import { model, belongsTo, attr, hasMany } from 'services/model'
import * as yup from 'yup'

const validationSchema = yup.object().shape({
  status: yup
    .string()
    .typeError('Field status has wrong type')
    .default(null)
    .nullable(),
  restaurant: yup
    .string()
    .typeError('Field restaurant has wrong type')
    .default(null)
    .nullable(),
  table: yup
    .string()
    .typeError('Field table has wrong type')
    .default(null)
    .nullable(),
  orderedItems: yup
    .array()
    .typeError('Field orderedItems has wrong type')
    .default(null)
    .nullable(),
  total: yup
    .string()
    .typeError('Field total has wrong type')
    .default(null)
    .nullable(),
  comments: yup
    .string()
    .typeError('Field comments has wrong type')
    .default(null)
    .nullable()
})

const Order = model(
  'order',
  {
    status: belongsTo('orderStatus'),
    restaurant: belongsTo('restaurant'),
    table: attr('string'),
    orderedItems: hasMany('orderedItem'),
    total: attr('string'),
    comments: attr('string')
  },
  validationSchema
)

export default Order
