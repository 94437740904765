import { Col, Row, Text } from '@qonsoll/react-design'

import { ItemTypeSimpleView } from 'domains/ItemType/components'
import { ListWrapper } from 'components'
import PropTypes from 'prop-types'
import { useTranslations } from 'contexts/Translation'

const ItemTypeList = (props) => {
  const { itemTypes, emptyProps, cw = [12, 6, 4, 4] } = props

  /* Getting translations instance */
  const { t } = useTranslations()

  /* Checking if list is empty */
  const isEmpty = !itemTypes?.length

  return (
    <ListWrapper negotiate="var(--page-height-to-negotiate-with-table-header)">
      <Row negativeBlockMargin>
        {isEmpty ? (
          <Col cw={cw}>
            <Text variant="overline" color="var(--ql-state-disabled-color)">
              {emptyProps?.message || t('No item type have been created yet')}
            </Text>
          </Col>
        ) : (
          itemTypes?.map((itemType, index) => (
            <Col mb={4} key={`type-${itemType?._id || index}`} cw={cw}>
              <ItemTypeSimpleView itemType={itemType} index={index} />
            </Col>
          ))
        )}
      </Row>
    </ListWrapper>
  )
}
ItemTypeList.propTypes = {
  itemTypes: PropTypes.arrayOf(PropTypes.object),
  actions: PropTypes.object,
  emptyProps: PropTypes.string,
  cw: PropTypes.array,
  isListWithCreate: PropTypes.bool
}

export default ItemTypeList
