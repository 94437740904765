import { MenuTypeView, MenuTypeForm } from './components'
import { useSimpleFormActions } from 'hooks'
import { useState } from 'react'
import { Form } from 'antd'
import { CardDropdown, SelectableCard } from 'components'
import { COLLECTIONS } from '__constants__'
import PropTypes from 'prop-types'

const MenuTypeSimpleView = (props) => {
  const { menuType, onSelect, index, isSelected, actions } = props

  /* Checking if component is in editing mode */
  const [isEditing, setIsEditing] = useState(false)
  /* Getting form instance from Form.useForm hook */
  const [form] = Form.useForm()

  /* Getting actions from useSimpleFormActions hook */
  const {
    handleDelete: removeDocument,
    handleCancel,
    handleSave,
    handleEdit
  } = useSimpleFormActions({
    collectionName: COLLECTIONS.MENU_TYPES,
    document: menuType,
    form,
    changeStateAction: setIsEditing
  })

  /* Handling deletion of the item */
  const handleDelete = () => removeDocument()
  /* Handling selection of the item */
  const handleSelect = (e) => {
    e.stopPropagation()
    onSelect?.(menuType?._id)
  }

  return (
    <CardDropdown
      onDelete={handleDelete}
      onEdit={handleEdit}
      onClick={handleSelect}
      document={menuType}
      index={index}
      data-testid="menuTypeListItem"
      {...actions}>
      <SelectableCard isSelected={isSelected}>
        {!isEditing ? (
          <MenuTypeView menuType={menuType} />
        ) : (
          <MenuTypeForm
            menuType={menuType}
            form={form}
            handleCancel={handleCancel}
            handleSave={handleSave}
          />
        )}
      </SelectableCard>
    </CardDropdown>
  )
}

MenuTypeSimpleView.propTypes = {
  menuType: PropTypes.object,
  onSelect: PropTypes.func,
  index: PropTypes.number,
  isSelected: PropTypes.bool,
  actions: PropTypes.object
}

export default MenuTypeSimpleView
