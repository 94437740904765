import { Box } from '@qonsoll/react-design'
import { Skeleton } from 'antd'
import PropTypes from 'prop-types'
import { useGetMenuItemColumnData } from 'domains/Menu/hooks'
import { useGetMenuListItemComponent } from 'domains/Menu/components/MenuListItem/hooks'
import { useLoading } from 'hooks'

const MenuListItemColumn = (props) => {
  const { columnData, menuItemId } = props

  const [columnValue, valueLoading] = useGetMenuItemColumnData(columnData)

  const [listItemComponent, componentLoading] = useGetMenuListItemComponent(
    columnData,
    columnValue,
    menuItemId
  )

  const loading = useLoading([valueLoading, componentLoading])

  return (
    <Box>
      <Skeleton
        active={true}
        title={null}
        paragraph={{
          rows: 1
        }}
        loading={loading}>
        {listItemComponent}
      </Skeleton>
    </Box>
  )
}

MenuListItemColumn.propTypes = {
  price: PropTypes.number,
  cocktailId: PropTypes.string,
  onSelect: PropTypes.func,
  index: PropTypes.number,
  spirits: PropTypes.array,
  columnData: PropTypes.object,
  menuItemId: PropTypes.string
}

export default MenuListItemColumn
