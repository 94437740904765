import { useDocumentDataOnce } from 'react-firebase-hooks/firestore'
import { useMemo } from 'react'
import { firestore } from 'services/firebase'
import { doc } from 'firebase/firestore'
import { COLLECTIONS } from '__constants__'

const useGetMenuById = (menuId) => {
  const [menu, menuLoading, error] = useDocumentDataOnce(
    menuId && doc(firestore, COLLECTIONS.MENUS, menuId)
  )

  return useMemo(() => [menu, menuLoading, error], [menu, menuLoading, error])
}

export default useGetMenuById
