import { useState, useMemo } from 'react'
import { Col, Divider, Row, Text, Title, Ribbon } from '@qonsoll/react-design'
import { useTranslations } from '@qonsoll/translation'
import { Icon } from '@qonsoll/icons'
import PropTypes from 'prop-types'
import { Tooltip } from 'antd'

import { CardDropdown, SelectableCard } from 'components'

import { DefaultInteractiveMenuButton } from './InteractiveMenuSimpleView.styled'

import {
  useGetItemTypeData,
  useItemTypesBaseActions
} from 'domains/ItemType/hooks'
import { useInteractiveMenuBaseActions } from 'domains/InteractiveMenu/hooks'

const InteractiveMenuSimpleView = (props) => {
  const { interactiveMenu, index, actions, cursor = 'pointer' } = props
  const [isCardHovered, setIsCardHovered] = useState(false)
  const { t } = useTranslations()

  const { name } = interactiveMenu

  // Fetch itemType
  const [itemTypeData] = useGetItemTypeData(interactiveMenu?.itemType)
  // Get base actions for ItemType
  const { setDefaultInteractiveMenu } = useItemTypesBaseActions({
    itemTypeId: itemTypeData?._id
  })
  // Get base actions for InteractiveMenu
  const { onOpen } = useInteractiveMenuBaseActions({
    interactiveMenuId: interactiveMenu?._id
  })

  // Mouse interaction
  const onMouseEnter = () => {
    setIsCardHovered(true)
  }
  const onMouseLeave = () => {
    setIsCardHovered(false)
  }

  const isDefaultInteractiveMenu = useMemo(
    () => itemTypeData?.defaultInteractiveMenu === interactiveMenu?._id,
    [interactiveMenu?._id, itemTypeData?.defaultInteractiveMenu]
  )

  const onSetMenuAsDefault = (event) => {
    event?.stopPropagation()
    setDefaultInteractiveMenu(interactiveMenu?._id)
  }

  const showTooltipTitle = isCardHovered && !isDefaultInteractiveMenu

  return (
    <CardDropdown
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
      style={{ cursor }}
      document={interactiveMenu}
      onClick={onOpen}
      index={index}
      height="100%"
      data-testid="listItem"
      {...actions}>
      {isDefaultInteractiveMenu ? (
        <Ribbon color={'var(--ribbon-active-bg)'} text={t('Default')} />
      ) : null}
      <SelectableCard
        position="relative"
        height="100%"
        style={{ height: '100%' }}>
        <Row noGutters v="center">
          <Col>
            <Title strong clamp="1" variant="h4" wordBreak="break-word">
              {name}
            </Title>
          </Col>
        </Row>
        <Divider />
        <Row noGutters v="center">
          <Col>
            <Text wordBreak="break-word" clamp="1">
              {itemTypeData?.name}
            </Text>
          </Col>
        </Row>
        {showTooltipTitle && (
          <Tooltip
            title={
              itemTypeData?.name &&
              t(`Set this interactive menu default for ${itemTypeData?.name} `)
            }>
            <DefaultInteractiveMenuButton
              onClick={onSetMenuAsDefault}
              size="small"
              type="primary"
              shape="circle"
              icon={<Icon size="14px" fill="white" name="CheckOutlined" />}
            />
          </Tooltip>
        )}
      </SelectableCard>
    </CardDropdown>
  )
}

InteractiveMenuSimpleView.propTypes = {
  interactiveMenu: PropTypes.object,
  index: PropTypes.number,
  actions: PropTypes.object,
  cursor: PropTypes.string
}

export default InteractiveMenuSimpleView
