import { Checkbox } from 'components'
import PropTypes from 'prop-types'
import { StyledDetails } from '../RestaurantFilter.styles'
import { Text } from '@qonsoll/react-design'
import { useGetAddresses } from 'domains/Address/hooks'
import { useSubfilterActions } from 'hooks'
import { useTranslations } from 'contexts/Translation'

const AddressFilter = ({ filterData, setFilterData }) => {
  // [ADDITIONAL_HOOKS]
  const [addresses] = useGetAddresses()
  const { t } = useTranslations()

  // [COMPUTED_PROPERTIES]
  const summaryStyles = { marginBottom: '8px' }

  const { onToggleDetails, onChange, checkIsEnabled } = useSubfilterActions({
    filterData,
    setFilterData,
    fieldName: 'address',
    operand: '=='
  })

  return (
    <StyledDetails
      open={localStorage.getItem(`address-details-visibility`) === 'opened'}>
      <summary style={summaryStyles} onClick={() => onToggleDetails('address')}>
        {t('Address')}
      </summary>
      {addresses?.map((address, index) => (
        <div key={`status-${index}`}>
          <Checkbox
            key={`status-${index}`}
            checked={checkIsEnabled(address)}
            onChange={() => onChange(address)}>
            <Text wordBreak="break-word">
              {address?.city || t('Unnamed filter')}
            </Text>
          </Checkbox>
        </div>
      ))}
    </StyledDetails>
  )
}

AddressFilter.propTypes = {
  filterData: PropTypes.object,
  setFilterData: PropTypes.func
}

export default AddressFilter
