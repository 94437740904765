import './spin.css'

import { Box, Col, Img, Row, Text } from '@qonsoll/react-design'

import PropTypes from 'prop-types'
import logoSvg from 'assets/logo.svg'

const Spinner = (props) => {
  const { text, ...rest } = props

  return (
    <Box
      height="inherit"
      display="flex"
      alignItems="center"
      justifyContent="center"
      {...rest}>
      <Row
        alignItems="center"
        mx="var(--ql-grid-gutter-negative)"
        justifyContent="center">
        <Col cw={[12, 12, 'auto']} mb={[2, 2, 0]}>
          <Img
            src={logoSvg}
            alt="Pick your pour"
            height={40}
            className="spin-logo"
          />
        </Col>
        {text && (
          <Col cw={[12, 12, 'auto']}>
            <Text textAlign="center">{text}</Text>
          </Col>
        )}
      </Row>
    </Box>
  )
}

Spinner.propTypes = {
  text: PropTypes.string
}

export default Spinner
