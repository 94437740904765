import PATHS from 'pages/paths'
const { AUTHENTICATED } = PATHS

const PATHNAME_TO_SELECTED_MENU_ITEM = {
  [AUTHENTICATED.PROFILE_SHOW]: AUTHENTICATED.PROFILE_SHOW,
  [AUTHENTICATED.PROFILE_EDIT]: AUTHENTICATED.PROFILE_SHOW,

  [AUTHENTICATED.RESTAURANT_SHOW]: AUTHENTICATED.PROFILE_SHOW,
  [AUTHENTICATED.RESTAURANT_EDIT]: AUTHENTICATED.PROFILE_SHOW,
  [AUTHENTICATED.RESTAURANT_CREATE]: AUTHENTICATED.PROFILE_SHOW,

  [AUTHENTICATED.RESTAURANT_MENU_SHOW]: AUTHENTICATED.PROFILE_SHOW,
  [AUTHENTICATED.RESTAURANT_MENU_CREATE]: AUTHENTICATED.PROFILE_SHOW,
  [AUTHENTICATED.RESTAURANT_MENU_EDIT]: AUTHENTICATED.PROFILE_SHOW,

  [AUTHENTICATED.RESTAURANT_ZONE_SHOW]: AUTHENTICATED.PROFILE_SHOW,
  [AUTHENTICATED.RESTAURANT_ZONE_CREATE]: AUTHENTICATED.PROFILE_SHOW,
  [AUTHENTICATED.RESTAURANT_ZONE_EDIT]: AUTHENTICATED.PROFILE_SHOW,

  [AUTHENTICATED.RESTAURANT_ZONE_CREATE_Q_R_CODE_CREATE]:
    AUTHENTICATED.PROFILE_SHOW,

  [AUTHENTICATED.RESTAURANT_ORDER_SHOW]: AUTHENTICATED.PROFILE_SHOW,

  [AUTHENTICATED.COCKTAILS_ALL]: AUTHENTICATED.COCKTAILS_ALL,
  [AUTHENTICATED.COCKTAIL_SHOW]: AUTHENTICATED.COCKTAILS_ALL,
  [AUTHENTICATED.COCKTAIL_CREATE]: AUTHENTICATED.COCKTAILS_ALL,
  [AUTHENTICATED.COCKTAIL_EDIT]: AUTHENTICATED.COCKTAILS_ALL,

  [AUTHENTICATED.INGREDIENTS_ALL]: AUTHENTICATED.INGREDIENTS_ALL,
  [AUTHENTICATED.INGREDIENT_SHOW]: AUTHENTICATED.INGREDIENTS_ALL,
  [AUTHENTICATED.INGREDIENT_CREATE]: AUTHENTICATED.INGREDIENTS_ALL,
  [AUTHENTICATED.INGREDIENT_EDIT]: AUTHENTICATED.INGREDIENTS_ALL,

  [AUTHENTICATED.TIP_AND_TRICKS_ALL]: AUTHENTICATED.TIP_AND_TRICKS_ALL,
  [AUTHENTICATED.TIP_AND_TRICK_SHOW]: AUTHENTICATED.TIP_AND_TRICK_SHOW,

  [AUTHENTICATED.MENUS_ALL]: AUTHENTICATED.MENUS_ALL,
  [AUTHENTICATED.MENU_SHOW]: AUTHENTICATED.MENUS_ALL,
  [AUTHENTICATED.MENU_CREATE]: AUTHENTICATED.MENUS_ALL,
  [AUTHENTICATED.MENU_EDIT]: AUTHENTICATED.MENUS_ALL,

  [AUTHENTICATED.MENU_COCKTAIL_SHOW]: AUTHENTICATED.MENUS_ALL,
  [AUTHENTICATED.MENU_DISH_SHOW]: AUTHENTICATED.MENUS_ALL,

  [AUTHENTICATED.INTERACTIVE_MENUS_ALL]: AUTHENTICATED.INTERACTIVE_MENUS_ALL,
  [AUTHENTICATED.INTERACTIVE_MENU_CREATE]: AUTHENTICATED.INTERACTIVE_MENUS_ALL,
  [AUTHENTICATED.INTERACTIVE_MENU_SHOW]: AUTHENTICATED.INTERACTIVE_MENUS_ALL,
  [AUTHENTICATED.INTERACTIVE_MENU_EDIT]: AUTHENTICATED.INTERACTIVE_MENUS_ALL,

  [AUTHENTICATED.ITEM_TYPES_ALL]: AUTHENTICATED.ITEM_TYPES_ALL,
  [AUTHENTICATED.ITEM_TYPE_SHOW]: AUTHENTICATED.ITEM_TYPES_ALL,
  [AUTHENTICATED.ITEM_TYPE_INSTANCE_SHOW]: AUTHENTICATED.ITEM_TYPES_ALL
}

export { PATHNAME_TO_SELECTED_MENU_ITEM }
