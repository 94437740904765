/**
 * It returns a string that is the current time in milliseconds, converted to base 36, concatenated
 * with a random string of length 2, also in base 36.
 * @returns A string of 36 characters.
 */
const uid = () => {
  return Date.now().toString(36) + Math.random().toString(36).substring(2)
}

export default uid
