import { Tag } from 'antd'
import styled from 'styled-components'

const TagStyled = styled(Tag)`
  background-color: var(--ql-bg-dark-lighten6);
  border-color: transparent;
  border-radius: 12px;
  font-weight: var(--ql-font-weight-bold);

  &.ant-tag {
    padding: 0 10px;
  }
  cursor: default;
  :hover {
    opacity: 1;
  }
`
export default TagStyled
