import { AriaGroup, Option } from 'components'
import { Col, Row, Title } from '@qonsoll/react-design'

import { ARIA_ROLES } from '__constants__'
import PropTypes from 'prop-types'
import { useEffect } from 'react'
import { useGetAlcoholTasteTypes } from 'domains/AlcoholTasteType/hooks'
import { useTranslations } from 'contexts/Translation'

const AlcoholTasteTypeRadioButtons = (props) => {
  const { onChange, value, withTitle } = props

  /* Getting translations instance */
  const { t } = useTranslations()
  /* Fetching alcoholTasteTypes */
  const [alcoholTasteTypes] = useGetAlcoholTasteTypes()

  const onAlcoholTasteTypeChange = (alcoholTasteTypeId) =>
    onChange?.(alcoholTasteTypeId)

  useEffect(() => {
    if (alcoholTasteTypes?.length)
      value ? onChange?.(value) : onChange?.(alcoholTasteTypes?.[0]?._id)
    //eslint-disable-next-line
  }, [alcoholTasteTypes])

  return (
    <Row mx="var(--ql-grid-gutter-negative)">
      {withTitle && (
        <Col cw={12} mb={2}>
          <Title level={4}>{t('Alcohol taste type')}</Title>
        </Col>
      )}
      <Col>
        <AriaGroup
          role={ARIA_ROLES.RADIOGROUP}
          ariaLabelledby="Alcohol tase type"
          onChange={onAlcoholTasteTypeChange}
          value={value || alcoholTasteTypes?.[0]?._id}
          buttonStyle="solid">
          {alcoholTasteTypes?.map(({ _id, name }) => (
            <Option key={_id} value={_id}>
              {name}
            </Option>
          ))}
        </AriaGroup>
      </Col>
    </Row>
  )
}

AlcoholTasteTypeRadioButtons.propTypes = {
  onChange: PropTypes.func,
  value: PropTypes.string,
  withTitle: PropTypes.bool
}

export default AlcoholTasteTypeRadioButtons
