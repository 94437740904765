import { Col, Row, Text } from '@qonsoll/react-design'

import { AddItemCard } from 'components'
import PropTypes from 'prop-types'
import { TrainingMaterialSimpleView } from 'domains/TrainingMaterial/components'
import { useTranslations } from 'contexts/Translation'

const TrainingMaterialList = (props) => {
  const {
    trainingMaterials,
    isListWithCreate,
    listView,
    emptyProps,
    actions,
    withAvailability,
    cw = [12, 6, 4]
  } = props

  /* Getting translations instance */
  const { t } = useTranslations()

  /* Checking if list is empty */
  const isEmpty = !trainingMaterials?.length
  /* Computing item width */
  const itemWidth = listView ? 12 : cw

  return (
    <Row negativeBlockMargin>
      {isListWithCreate && actions?.onCreate && (
        <Col mb={4} cw={itemWidth}>
          <AddItemCard onClick={actions.onCreate} />
        </Col>
      )}
      {!isEmpty &&
        trainingMaterials.map((trainingMaterial, index) => (
          <Col
            mb={4}
            key={trainingMaterial?._id || index}
            cw={itemWidth}
            height="inherit">
            <TrainingMaterialSimpleView
              withAvailability={withAvailability}
              actions={actions}
              index={index}
              trainingMaterial={trainingMaterial}
            />
          </Col>
        ))}
      {isEmpty && (
        <Col cw={12}>
          <Text variant="overline" color="var(--ql-state-disabled-color)">
            {emptyProps?.message ||
              t('No educational materials have been created yet')}
          </Text>
        </Col>
      )}
    </Row>
  )
}

TrainingMaterialList.propTypes = {
  trainingMaterials: PropTypes.array,
  isListWithCreate: PropTypes.bool,
  withAvailability: PropTypes.bool,
  listView: PropTypes.bool,
  emptyProps: PropTypes.object,
  actions: PropTypes.any,
  cw: PropTypes.any
}

export default TrainingMaterialList
