import { Input } from '@qonsoll/react-design'
import PropType from 'prop-types'

const SimpleSearchInput = (props) => {
  const { searchItems, searchRef, placeholder } = props

  return (
    <Input ref={searchRef} onChange={searchItems} placeholder={placeholder} />
  )
}

SimpleSearchInput.propTypes = {
  searchItems: PropType.func,
  searchRef: PropType.object,
  placeholder: PropType.string
}

export default SimpleSearchInput
