import { useEffect, useState, useCallback } from 'react'
import { getDocument } from 'services/api/firebase'
import { COLLECTIONS } from '__constants__'

/**
 * It fetches a menuType document from Firestore and returns it
 * @param menu - The menu object that we're getting the menuType for.
 * @returns An array with four values. The first value is the menuType object, and the second value is a
 * boolean that indicates whether the menuType is loading.
 */
const useGetMenuMenuType = (menu) => {
  /* State for data */
  const [menuType, setMenuType] = useState(null)
  /* State for loading */
  const [loading, setLoading] = useState(!!menu?.menuType)
  /* State for error */
  const [error, setError] = useState(null)

  /* Fetching data */
  const getMenuType = useCallback(async () => {
    try {
      setLoading(true)
      const document = await getDocument(COLLECTIONS.MENU_TYPES, menu.menuType)
      setMenuType(document)
      setLoading(false)
    } catch (error) {
      setError(error)
    }
  }, [menu?.menuType])

  useEffect(() => {
    menu?.menuType && getMenuType()
  }, [menu?.menuType, getMenuType])

  return [menuType, loading, error, getMenuType]
}

export default useGetMenuMenuType
