const styles = {
  fieldTitleProps: {
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    maxWidth: '250px',
    whiteSpace: 'nowrap'
  },
  fieldRowProps: {
    justifyContent: 'center',
    alignItems: 'center',
    py: '4px',
    mb: 2
  }
}

export default styles
