import { Box, Title } from '@qonsoll/react-design'
import {
  useActionsTableAdvancedForm,
  useInitializeTableAdvancedForm
} from './hooks'

import { ButtonAccent } from 'components'
import { Form } from 'antd'
import { KEYS } from '__constants__'
import PropTypes from 'prop-types'
import { TableSimpleForm } from 'domains/Table/components'
import { useTranslations } from 'contexts/Translation'

const TableAdvancedForm = (props) => {
  const { initialData, group, showTitle } = props

  /* Getting translations instance */
  const { t } = useTranslations()
  /* Getting form instance from hook */
  const { form } = useInitializeTableAdvancedForm(initialData)
  /* Getting loading state and actions from hook */
  const { loading, onFinish, onReset } = useActionsTableAdvancedForm({
    initialData,
    form
  })

  /* Handling enter key press */
  const handleKeyPress = (e) => {
    if (e.key === KEYS.ENTER) {
      e.preventDefault()
      onFinish()
    }
  }

  return group ? (
    <>
      {showTitle && (
        <Title level={4} mb={2}>
          {t('Table')}
        </Title>
      )}
      <TableSimpleForm showTitle={false} group={[...group, 'table']} />
    </>
  ) : (
    <>
      <Form
        scrollToFirstError
        onKeyPress={handleKeyPress}
        onFinish={onFinish}
        layout="vertical"
        autoComplete="off"
        form={form}>
        {showTitle && (
          <Title level={4} mb={2}>
            {t('Table')}
          </Title>
        )}
        <TableSimpleForm group={['table']} />
        <Box display="flex">
          <ButtonAccent
            type="secondary"
            mr={2}
            htmlType="button"
            onClick={onReset}
            mb={4}>
            {t('Cancel')}
          </ButtonAccent>
          <ButtonAccent
            type="primary"
            loading={loading}
            onClick={() => form.submit()}>
            {t('Save')}
          </ButtonAccent>
        </Box>
      </Form>
    </>
  )
}
TableAdvancedForm.propTypes = {
  initialData: PropTypes.object,
  group: PropTypes.array,
  showTitle: PropTypes.bool
}

export default TableAdvancedForm
