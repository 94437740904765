const PATHS = {
  CONFIG: {
    DEFAULT: '/auth',
    AFTER_LOGIN: '/profiles/:profileId',
    AFTER_LOGOUT: '/auth',
    AFTER_SIGNUP: '/profiles/:profileId'
  },
  UNAUTHENTICATED: {
    LOGIN: '/auth',
    LOGIN_WITH_EMAIL: '/auth/login-with-email',
    SIGNUP: '/auth/signup',
    SIGNUP_WITH_EMAIL: '/auth/sign-up-with-email',
    FORGOT_PASSWORD: '/auth/forgot-password',
    CONFIRM_EMAIL: '/auth/confirm-email',
    CHANGE_PASSWORD: '/auth/change-password'
  },
  SERVICE: {
    ACCESS_DENIED: '/service/access-denied',
    GDPR: '/service/gdpr',
    NOT_FOUND: '/service/404',
    TERMS_AND_CONDITIONS: '/service/terms-and-conditions'
  },
  AUTHENTICATED: {
    DASHBOARD: '/dashboard',
    USER_SHOW: '/users/:userId',
    TRANSLATIONS: '/translations',
    SETTINGS: '/settings',
    STATISTICS: '/statistics',
    RESTAURANTS_ALL: '/restaurants',
    RESTAURANT_SHOW: '/profiles/:companyId/venues/:restaurantId',
    RESTAURANT_EDIT: '/profiles/:companyId/venues/:restaurantId/edit',
    RESTAURANT_CREATE: '/profiles/:companyId/venues/create',
    ADDRESS_SHOW: '/addresses/:addressId',
    ADDRESS_EDIT: '/addresses/:addressId/edit',
    MEDIA_OBJECT_SHOW: '/media-objects/:mediaObjectId',
    MEDIA_OBJECT_EDIT: '/media-objects/:mediaObjectId/edit',
    MEDIA_OBJECT_CREATE: '/media-object/create',
    COCKTAIL_SHOW: '/drinks/:cocktailId',
    COCKTAIL_EDIT: '/drinks/:cocktailId/edit',
    COCKTAILS_ALL: '/drinks',
    COCKTAIL_CREATE: '/drink/create',
    INGREDIENT_SHOW: '/ingredients/:ingredientId',
    INGREDIENT_EDIT: '/ingredients/:ingredientId/edit',
    INGREDIENTS_ALL: '/ingredients',
    INGREDIENT_CREATE: '/ingredient/create',
    MEASUREMENT_TYPE_SHOW: '/measurement-types/:measurementTypeId',
    MEASUREMENT_TYPE_EDIT: '/measurement-types/:measurementTypeId/edit',
    ALLERGEN_SHOW: '/allergens/:allergenId',
    ALLERGEN_EDIT: '/allergens/:allergenId/edit',
    METHOD_SHOW: '/methods/:methodId',
    METHOD_EDIT: '/methods/:methodId/edit',
    ALCOHOL_TASTE_TYPE_SHOW: '/alcohol-taste-types/:alcoholTasteTypeId',
    ALCOHOL_TASTE_TYPE_EDIT: '/alcohol-taste-types/:alcoholTasteTypeId/edit',
    MAIN_SPIRIT_TYPE_SHOW: '/main-spirit-types/:mainSpiritTypeId',
    MAIN_SPIRIT_TYPE_EDIT: '/main-spirit-types/:mainSpiritTypeId/edit',
    FLAVOR_SHOW: '/flavors/:flavorId',
    FLAVOR_EDIT: '/flavors/:flavorId/edit',
    GLASS_TYPE_SHOW: '/glass-types/:glassTypeId',
    GLASS_TYPE_EDIT: '/glass-types/:glassTypeId/edit',
    DECORATION_TYPE_SHOW: '/decoration-types/:decorationTypeId',
    DECORATION_TYPE_EDIT: '/decoration-types/:decorationTypeId/edit',
    ORDER_SHOW: '/orders/:orderId',
    ORDER_EDIT: '/orders/:orderId/edit',
    ORDER_STATUS_SHOW: '/order-statuses/:orderStatusId',
    ORDER_STATUS_EDIT: '/order-statuses/:orderStatusId/edit',
    ORDERED_ITEM_SHOW: '/ordered-items/:orderedItemId',
    ORDERED_ITEM_EDIT: '/ordered-items/:orderedItemId/edit',
    Q_R_CODES_ALL: '/q-r-codes',
    Q_R_CODE_CREATE: '/q-r-code/create',
    RESTAURANT_Q_R_CODE_ALL: '/venues/:restaurantId/q-r-codes',
    RESTAURANT_Q_R_CODE_SHOW: '/venues/:restaurantId/q-r-codes/:qRCodeId',
    RESTAURANT_Q_R_CODE_CREATE: '/venues/:restaurantId/q-r-code/create',
    RESTAURANT_Q_R_CODE_EDIT: '/venues/:restaurantId/q-r-codes/:qRCodeId/edit',
    USERS_ALL: '/users',
    USER_CREATE: '/user/create',
    USER_EDIT: '/users/:userId/edit',
    COMPANY_SHOW: '/company/:companyId',
    COMPANY_EDIT: '/companies/:companyId/edit',
    CUSTOMER_WIZARD: '/customer/wizard',
    CUSTOMER_CREATE: '/customer/create',
    CUSTOMER_SHOW: '/customers/:customerId',
    CUSTOMER_EDIT: '/customers/:customerId/edit',
    TIP_AND_TRICKS_ALL: '/tip-and-tricks',
    TIP_AND_TRICK_SHOW: '/tip-and-tricks/:tipAndTrickId',
    TIP_AND_TRICK_CREATE: '/tip-and-trick/create',
    TRAINING_MATERIALS_ALL: '/training-materials',
    TRAINING_MATERIAL_SHOW: '/training-materials/:trainingMaterialId',
    TRAINING_MATERIAL_CREATE: '/training-material/create',
    EDUCATIONAL_CONTENTS_ALL: '/educational-contents',
    EDUCATIONAL_CONTENT_SHOW: '/educational-contents/:educationalContentId',
    MENUS_ALL: '/menus',
    MENU_SHOW: '/menus/:menuId',
    RESTAURANT_MENUS_ALL: '/venues/:restaurantId/menus',
    RESTAURANT_MENU_SHOW:
      '/profiles/:profileId/venues/:restaurantId/menus/:menuId',
    RESTAURANT_MENU_CREATE:
      '/profiles/:profileId/venues/:restaurantId/menus/create',
    RESTAURANT_MENU_EDIT:
      '/profiles/:profileId/venues/:restaurantId/menus/:menuId/edit',
    RESTAURANT_MENU_COCKTAIL_SHOW:
      '/profiles/:profileId/venues/:restaurantId/menus/:menuId/drinks/:cocktailId',
    MENU_CREATE: '/menu/create',

    MENU_EDIT: '/menus/:menuId/edit',
    MENU_COCKTAIL_SHOW: '/menus/:menuId/drinks/:cocktailId',
    MENU_CATEGORY_SHOW: '/menu-categories/:menuCategoryId',
    MENU_CATEGORY_EDIT: '/menu-categories/:menuCategoryId/edit',
    MENU_CATEGORIES_ALL: '/menu-categories',
    MENU_CATEGORY_CREATE: '/menu-category/create',
    MENU_TYPE_SHOW: '/menu-types/:menuTypeId',
    MENU_TYPE_EDIT: '/menu-types/:menuTypeId/edit',
    MENU_TYPES_ALL: '/menu-types',
    MENU_TYPE_CREATE: '/menu-type/create',
    SETTINGS_MENU_TYPES_ALL: '/settings/menu-types',
    PROFILE_SHOW: '/profiles/:profileId',
    PROFILE_EDIT: '/profiles/:profileId/edit',
    TABLES_ALL: '/tables',
    TABLE_EDIT: '/tables/:tableId/edit',
    TABLE_SHOW: '/tables/:tableId',
    TABLE_CREATE: '/table/create',
    RESTAURANT_TABLES_ALL: '/venues/:restaurantId/tables',
    RESTAURANT_TABLE_SHOW: '/venues/:restaurantId/tables/:tableId',
    RESTAURANT_TABLE_CREATE: '/venues/:restaurantId/table/create',
    RESTAURANT_TABLE_EDIT: '/venues/:restaurantId/tables/:tableId/edit',
    ZONES_ALL: '/zones',
    ZONE_CREATE: '/zone/create',
    ZONE_EDIT: '/zones/:zoneId/edit',
    RESTAURANT_ZONE_ALL: '/venues/:restaurantId/zones',
    RESTAURANT_ZONE_CREATE:
      '/profiles/:profileId/venues/:restaurantId/zone/create',
    RESTAURANT_ZONE_EDIT:
      '/profiles/:profileId/venues/:restaurantId/zones/:zoneId/edit',
    RESTAURANT_ZONE_SHOW:
      '/profiles/:profileId/venues/:restaurantId/zones/:zoneId',
    MENU_DISH_SHOW: '/menus-dishes/:dishMenuId',
    MENU_DISH_EDIT: '/menus-dishes/:dishMenuId/edit',
    DISH_SHOW: '/menus-dishes/:dishMenuId/dishes/:dishId',
    DISH_CREATE: 'menus-dishes/create',
    DISH_EDIT: '/menus-dishes/:dishMenuId/dishes/:dishId/edit',
    RESTAURANT_ZONE_CREATE_Q_R_CODE_CREATE:
      '/profiles/:profileId/venues/:restaurantId/zones/:zoneId/q-r-code/create',
    RESTAURANT_ORDER_SHOW:
      '/profiles/:profileId/venues/:restaurantId/orders/:orderId',
    COMPANY_ADMIN_INVITE: '/profiles/:companyId/customer-admin-invite',
    INTERACTIVE_MENUS_ALL: '/interactive-menus',
    INTERACTIVE_MENU_CREATE: '/interactive-menus/create',
    INTERACTIVE_MENU_SHOW: '/interactive-menus/:interactiveMenuId',
    INTERACTIVE_MENU_EDIT: '/interactive-menus/:interactiveMenuId/edit',
    ITEM_TYPES_ALL: '/item-types',
    ITEM_TYPE_CREATE: '/item-types/create',
    ITEM_TYPE_EDIT: '/item-types/:itemTypeId/edit',
    ITEM_TYPE_SHOW: '/item-types/:itemTypeId',
    ITEM_TYPE_INSTANCE_CREATE: '/item-types/:itemTypeId/create',
    ITEM_TYPE_INSTANCE_SHOW: '/item-types/:itemTypeId/:menuItemId',
    ITEM_TYPE_INSTANCE_EDIT: '/item-types/:itemTypeId/:menuItemId/edit'
  }
}

export default PATHS
