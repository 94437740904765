import { COLLECTIONS } from '__constants__'
import { deleteDocument } from 'services/api/firebase'
import { message } from 'antd'
import { useEvent } from 'hooks'
import { useHistory } from 'react-router-dom'
import { useMemo } from 'react'
import { useTranslations } from 'contexts/Translation'

const useObjectInstanceBaseActions = ({
  objectInstance,
  callback,
  fieldName,
  objectInstanceId
} = {}) => {
  /* Getting translations instance */
  const { t } = useTranslations()
  /* Getting history instance */
  const history = useHistory()
  /* Function to handle back click */
  const handleBack = useEvent(() => history.goBack())
  const removeDocument = useEvent(async () => {
    try {
      await deleteDocument(COLLECTIONS.OBJECT_INSTANCES, objectInstance?._id)
      callback &&
        fieldName &&
        callback?.(fieldName, null, objectInstanceId, true)
      message.success(t('Object successfully deleted'))
    } catch (error) {
      message.error(
        t(`Error during deletion of object instance: ${error.message}`)
      )
    }
  })

  return useMemo(
    () => ({
      onBack: handleBack,
      onDelete: removeDocument
    }),
    [handleBack, removeDocument]
  )
}

export default useObjectInstanceBaseActions
