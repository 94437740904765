import { COLLECTIONS } from '__constants__'
import { useDocumentData } from 'react-firebase-hooks/firestore'
import { doc } from 'firebase/firestore'
import { firestore } from 'services/firebase'

const useGetItemTypeData = (itemTypeId) => {
  return useDocumentData(
    itemTypeId && doc(firestore, COLLECTIONS.ITEM_TYPES, itemTypeId)
  )
}
export default useGetItemTypeData
