import { Box, Input, PhoneInput, Title } from '@qonsoll/react-design'
import { ButtonAccent, ImageUploader, SelectCountry } from 'components'
import { CustomerCurrencySelect, CustomerLanguageSelect } from './components'
import {
  KEYS,
  MAX_LENGTH_CONTACT_PERSON_NAME,
  MAX_LENGTH_CONTACT_PHONE_NUMBER,
  MAX_LENGTH_FIELD,
  MIN_LENGTH_CONTACT_PERSON_NAME,
  MIN_LENGTH_CONTACT_PHONE_NUMBER
} from '__constants__'
import { useFetchCountries, useValidateInput } from 'hooks'

import { Form } from 'antd'
import { LocationForm } from 'domains/Location/components'
import PropTypes from 'prop-types'
import { useTranslations } from 'contexts/Translation'

const CustomerSimpleForm = (props) => {
  const {
    initialValues,
    onSubmit,
    onCancel,
    showTitle,
    form,
    group,
    mainLanguage,
    setMainLanguage,
    mainCurrency,
    setMainCurrency,
    description
  } = props

  /* Fetch all countries */
  const { listCountries, listLanguages, listCurrencies } = useFetchCountries()
  /* Getting translations instance */
  const { t } = useTranslations()
  /* Getting new form instance from Form.useForm hook */
  const [baseForm] = Form.useForm()
  /* A custom hook that is used to validate input fields. */
  const validateInput = useValidateInput()

  /* Getting used form from props or using base form */
  const usedForm = form || baseForm

  /* Resetting form on cancel */
  const handleCancel = () => {
    usedForm.resetFields()
    onCancel?.()
  }
  /* Submitting form on enter key press */
  const handleKeyPress = (e) => {
    if (e.key === KEYS.ENTER) {
      e.preventDefault()
      e.stopPropagation()
      usedForm?.submit()
    }
  }

  return group ? (
    <>
      {showTitle && (
        <Title level={4} mb={2}>
          {t('Customer')}
        </Title>
      )}
      <Form.Item
        label={t('Logo')}
        name={[...group, 'logoUrl']}
        rules={[
          {
            required: false,
            message: t('Please enter your customer logo!')
          }
        ]}>
        <ImageUploader placeholder={t('Please enter your customer logo')} />
      </Form.Item>
      <Form.Item
        label={t('Name')}
        name={[...group, 'name']}
        rules={[
          {
            required: true,
            message: t('Please enter your customer name!')
          },
          { max: MAX_LENGTH_FIELD, message: t('customer name is too long!') },
          { validator: validateInput }
        ]}>
        <Input
          placeholder={t('Please enter your customer name')}
          onPressEnter={onSubmit}
        />
      </Form.Item>
      <Form.Item
        label={t('Contact person name')}
        name={[...group, 'contactPerson']}
        rules={[
          {
            required: true,
            message: t('This field is required!')
          },
          {
            max: MAX_LENGTH_CONTACT_PERSON_NAME,
            message: t('Contact person name is too long!')
          },
          {
            min: MIN_LENGTH_CONTACT_PERSON_NAME,
            message: t('Contact person name is too short!')
          },
          { validator: validateInput }
        ]}>
        <Input
          placeholder={t('Please enter your contact person name')}
          onPressEnter={onSubmit}
        />
      </Form.Item>
      <Form.Item
        label={t('Contact phone number')}
        name={[...group, 'phoneNumber']}
        rules={[
          {
            required: true,
            message: t('This field is required!')
          },
          {
            max: MAX_LENGTH_CONTACT_PHONE_NUMBER,
            message: t('Contact phone number is too long!')
          },
          {
            min: MIN_LENGTH_CONTACT_PHONE_NUMBER,
            message: t('Contact phone number is too short!')
          },
          { validator: validateInput }
        ]}>
        <PhoneInput
          country="no"
          specialLabel=""
          searchPlaceholder={t('Search for a country')}
          enableSearch
          preferredCountries={['no', 'ua']}
          placeholder={t('Please enter your contact phone number')}
        />
      </Form.Item>

      <Form.Item
        label={t('Email')}
        name={[...group, 'email']}
        rules={[
          {
            max: MAX_LENGTH_FIELD,
            message: t('Country name is too long!')
          },
          { type: 'email', message: t('Please, enter valid email!') },
          { validator: validateInput }
        ]}>
        <Input placeholder={t('Please, enter company email')} />
      </Form.Item>

      <Form.Item
        label={t('Address')}
        name={[...group, 'locationInfo']}
        rules={[
          {
            required: true,
            message: t('Please enter your location!')
          }
        ]}>
        <LocationForm
          // add custom prop to call function inside Autocomplete only on edit but not on wizard
          editedFromProfile
          countriesList={listCountries}
          setMainCurrency={setMainCurrency}
          setMainLanguage={setMainLanguage}
          listLanguages={listLanguages}
          description={description}
        />
      </Form.Item>

      <Form.Item label={t('Main language')} name={[...group, 'mainLanguage']}>
        <CustomerLanguageSelect
          setMainCurrency={setMainCurrency}
          setMainLanguage={setMainLanguage}
          listLanguages={listLanguages}
          mainLanguage={mainLanguage}
        />
      </Form.Item>
      <Form.Item
        label={t('Additional language')}
        name={[...group, 'additionalLanguage']}>
        <CustomerLanguageSelect listLanguages={listLanguages} />
      </Form.Item>
      <Form.Item label={t('Currency')} name={[...group, 'currency']}>
        <CustomerCurrencySelect
          setMainCurrency={setMainCurrency}
          mainCurrency={mainCurrency}
          listCurrencies={listCurrencies}
        />
      </Form.Item>
    </>
  ) : (
    <Form
      scrollToFirstError
      form={usedForm}
      initialValues={initialValues}
      onFinish={onSubmit}
      layout="vertical"
      onKeyPress={handleKeyPress}
      autoComplete="off">
      {showTitle && (
        <Title level={4} mb={2}>
          {t('Customer')}
        </Title>
      )}
      <Form.Item
        label={t('Logo')}
        name="logoUrl"
        rules={[
          {
            required: false,
            message: t('Please enter your customer logo!')
          }
        ]}>
        <ImageUploader placeholder={t('Please enter your customer logo')} />
      </Form.Item>
      <Form.Item
        label={t('Name')}
        name="name"
        rules={[
          {
            required: true,
            message: t('Please enter your customer name!')
          },
          { max: MAX_LENGTH_FIELD, message: t('customer name is too long!') }
        ]}>
        <Input placeholder={t('Please enter your customer name')} />
      </Form.Item>
      <Form.Item
        label={t('Contact person name')}
        name={[...group, 'contactPerson']}
        rules={[
          {
            required: true,
            message: t('This field is required!')
          },
          {
            max: MAX_LENGTH_CONTACT_PERSON_NAME,
            message: t('Contact person name is too long!')
          },
          {
            min: MIN_LENGTH_CONTACT_PERSON_NAME,
            message: t('Contact person name is too short!')
          },
          { validator: validateInput }
        ]}>
        <Input
          placeholder={t('Please enter your contact person name')}
          onPressEnter={onSubmit}
        />
      </Form.Item>
      <Form.Item
        label={t('Contact phone number')}
        name={[...group, 'phoneNumber']}
        rules={[
          {
            required: true,
            message: t('This field is required!')
          },
          {
            max: MAX_LENGTH_CONTACT_PHONE_NUMBER,
            message: t('Contact phone number is too long!')
          },
          {
            min: MIN_LENGTH_CONTACT_PHONE_NUMBER,
            message: t('Contact phone number is too short!')
          },
          { validator: validateInput }
        ]}>
        <PhoneInput
          country="no"
          specialLabel=""
          searchPlaceholder={t('Search for a country')}
          enableSearch
          preferredCountries={['no', 'ua']}
          placeholder={t('Please enter your contact phone number')}
        />
      </Form.Item>

      <Form.Item
        label={t('Email')}
        name={[...group, 'email']}
        rules={[
          {
            max: MAX_LENGTH_FIELD,
            message: t('Country name is too long!')
          },
          { type: 'email', message: t('Please, enter valid email!') },
          { validator: validateInput }
        ]}>
        <Input placeholder={t('Please, enter company email')} />
      </Form.Item>

      <Form.Item
        label={t('Country')}
        name={[...group, 'country']}
        rules={[
          {
            required: true,
            message: t('This field is required!')
          },
          {
            max: MAX_LENGTH_FIELD,
            message: t('Country name is too long!')
          }
        ]}>
        <SelectCountry
          countriesList={listCountries}
          setMainCurrency={setMainCurrency}
          setMainLanguage={setMainLanguage}
          listLanguages={listLanguages}
        />
      </Form.Item>
      <Form.Item label={t('Main language')} name={[...group, 'mainLanguage']}>
        <CustomerLanguageSelect
          setMainCurrency={setMainCurrency}
          setMainLanguage={setMainLanguage}
          listLanguages={listLanguages}
          mainLanguage={mainLanguage}
        />
      </Form.Item>
      <Form.Item
        label={t('Additional language')}
        name={[...group, 'additionalLanguage']}>
        <CustomerLanguageSelect listLanguages={listLanguages} />
      </Form.Item>
      <Form.Item label={t('Currency')} name={[...group, 'currency']}>
        <CustomerCurrencySelect
          setMainCurrency={setMainCurrency}
          mainCurrency={mainCurrency}
          listCurrencies={listCurrencies}
        />
      </Form.Item>

      {!form && (
        <Box display="flex">
          <ButtonAccent
            mr={2}
            htmlType="button"
            onClick={handleCancel}
            type="secondary">
            {t('Cancel')}
          </ButtonAccent>
          <ButtonAccent type="primary" onClick={() => usedForm.submit()}>
            {t('Save')}
          </ButtonAccent>
        </Box>
      )}
    </Form>
  )
}

CustomerSimpleForm.propTypes = {
  initialValues: PropTypes.object,
  onSubmit: PropTypes.func,
  onCancel: PropTypes.func,
  showTitle: PropTypes.bool,
  form: PropTypes.any,
  group: PropTypes.array,
  mainLanguage: PropTypes.string,
  setMainLanguage: PropTypes.func,
  mainCurrency: PropTypes.string,
  setMainCurrency: PropTypes.func,
  description: PropTypes.string
}

export default CustomerSimpleForm
