import { useTranslations } from 'contexts/Translation'
import {
  Container,
  Row,
  Col,
  Text,
  Title,
  Img,
  Card
} from '@qonsoll/react-design'

import PropTypes from 'prop-types'

const GlassTypeAdvancedView = (props) => {
  const { glassType } = props

  /* Getting translations instance */
  const { t } = useTranslations()

  return (
    <Container>
      <Row mx="var(--ql-grid-gutter-negative)">
        <Col cw={4} mb={3}>
          <Card>
            <Row noGutters mb={2} v="center">
              <Col h="left">
                <Img
                  alt="Glass image"
                  style={{
                    maxHeight: '500px',
                    objectFit: 'cover',
                    width: '100%',
                    borderRadius: 'var(--card-radius)'
                  }}
                  src={glassType?.image}
                />
              </Col>
            </Row>
            <Row noGutters mb={2} v="center">
              <Col h="left">
                <Title level={5}>{glassType?.name}</Title>
              </Col>
            </Row>
            <Row noGutters v="center" mb={2}>
              <Col h="left">
                <Text variant="overline" type="secondary">
                  {t('Value')}:
                </Text>
              </Col>
              <Col h="right">
                <Text>{glassType?.value}</Text>
              </Col>
            </Row>
          </Card>
        </Col>
      </Row>
    </Container>
  )
}

GlassTypeAdvancedView.propTypes = {
  glassType: PropTypes.object
}

export default GlassTypeAdvancedView
