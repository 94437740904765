import { Col, PageWrapper, Row } from '@qonsoll/react-design'
import { HeaderBreadcrumbs, Spinner } from 'components'
import { useHistory, useParams } from 'react-router-dom'

import { COLLECTIONS } from '__constants__'
import { PageActions } from 'components'
import { TrainingMaterialAdvancedView } from 'domains/TrainingMaterial/components'
import { useDocument } from 'services/api/firebase'
import { useTranslations } from 'contexts/Translation'

const TrainingMaterialShowPage = (props) => {
  /* Getting translations instance */
  const { t } = useTranslations()
  /* Getting history instance */
  const history = useHistory()
  /* Getting params from router */
  const { tipAndTrickId } = useParams()
  /* Fetching document data from useDocument hook */
  const [trainingMaterial, loading] = useDocument({
    ref: COLLECTIONS.TRAINING_MATERIALS + '/' + tipAndTrickId
  })

  /* Configuring page header */
  const headingProps = {
    title: trainingMaterial?.title,
    marginBottom: 3,
    textAlign: 'left'
  }

  /* Configuring page actions */
  const actions = { edit: false }

  /* Function to handle back click */
  const handleBack = () => history.goBack()
  /* Handling opening of the edit page */

  return (
    <PageWrapper
      action={<PageActions actions={actions} />}
      onBack={handleBack}
      breadcrumbs={<HeaderBreadcrumbs />}
      headingProps={headingProps}>
      <Row noGutters>
        {loading ? (
          <Col>
            <Spinner height="70vh" text={t('Educational content loading')} />
          </Col>
        ) : (
          <Col cw={12}>
            <TrainingMaterialAdvancedView trainingMaterial={trainingMaterial} />
          </Col>
        )}
      </Row>
    </PageWrapper>
  )
}

export default TrainingMaterialShowPage
