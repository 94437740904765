import { CollapseButton, StyledAside } from './LayoutAside.styled'
import { LANGUAGE_SELECT_VARIANTS, LOGO_VARIANTS } from '__constants__'
import { Logo, LogoutMenuItem, MainMenu } from '../../components'

import { Box } from '@qonsoll/react-design'
import { CustomerSelect } from 'domains/Customer/components'
import { Grid } from 'antd'
import { Icon } from '@qonsoll/icons'
import { LanguageSelect } from 'domains/Translation/components'
import MainMenuWrapper from '../../components/MainMenu/MainMenu.styled'
import PropTypes from 'prop-types'
import { useCustomerContext } from 'contexts/CustomerContext'

const { useBreakpoint } = Grid

const LayoutAside = (props) => {
  const { collapsed, setCollapsed } = props

  const { lg } = useBreakpoint()

  const customerContext = useCustomerContext()

  // Get logo variant, icon name, language select variant by state of aside
  const computedLogoVariant = collapsed
    ? LOGO_VARIANTS.SIMPLE
    : LOGO_VARIANTS.FULL
  const computedIconName = collapsed
    ? 'ChevronRightOutlined'
    : 'ChevronLeftOutlined'
  const computedLanguageSelectVariant = collapsed
    ? LANGUAGE_SELECT_VARIANTS.SIMPLE
    : LANGUAGE_SELECT_VARIANTS.FULL

  // Handle open/close aside
  const onCollapse = () => setCollapsed(!collapsed)

  // Handle close aside
  const handleCloseLeftAside = () => setCollapsed(true)

  return (
    <StyledAside collapsed={collapsed} lg={lg}>
      {!lg && (
        <CollapseButton collapsed={collapsed} onClick={onCollapse}>
          <Icon size={16} name={computedIconName} />
        </CollapseButton>
      )}

      <Box mb={24} px={14}>
        <Logo variant={computedLogoVariant} />
      </Box>

      <Box mb={24} px={12}>
        <LanguageSelect variant={computedLanguageSelectVariant} />
      </Box>

      <Box mb={48} px={12}>
        {!!customerContext && <CustomerSelect width="100%" />}
      </Box>

      <MainMenuWrapper mb="auto" overflowY="auto">
        <MainMenu
          collapsed={collapsed}
          handleCloseLeftAside={handleCloseLeftAside}
          lg={lg}
        />
      </MainMenuWrapper>

      <Box mx={24}>
        <LogoutMenuItem collapsed={collapsed} />
      </Box>
    </StyledAside>
  )
}

LayoutAside.propTypes = {
  user: PropTypes.shape({
    uid: PropTypes.string,
    photoURL: PropTypes.string,
    displayName: PropTypes.string,
    email: PropTypes.string
  }),
  collapsed: PropTypes.bool,
  setCollapsed: PropTypes.func
}
export default LayoutAside
