import { model, attr, hasMany } from 'services/model'
import * as yup from 'yup'

const validationSchema = yup.object().shape({
  name: yup
    .string()
    .typeError('Field name has wrong type')
    .required('Field name is required'),
  value: yup
    .string()
    .typeError('Field value has wrong type')
    .default(null)
    .nullable(),
  items: yup
    .array()
    .typeError('Field items has wrong type')
    .default(null)
    .nullable()
})

const MenuCategory = model(
  'menuCategory',
  {
    name: attr('string'),
    value: attr('string'),
    items: hasMany('cocktail')
  },
  validationSchema
)

export default MenuCategory
