import { Col, PageWrapper, Row } from '@qonsoll/react-design'
import { HeaderBreadcrumbs, Spinner } from 'components'
import { useHistory, useParams } from 'react-router-dom'

import { ZoneAdvancedForm } from 'domains/Zone/components'
import { useGetZoneInitialValues } from 'domains/Zone/hooks'
import { useTranslations } from 'contexts/Translation'

const ZoneEdit = () => {
  /* Getting translations instance */
  const { t } = useTranslations()
  /* Getting history instance */
  const history = useHistory()
  /* Getting params from router */
  const { zoneId } = useParams()
  /* Fetching table initial values */
  const [initialValues, loading] = useGetZoneInitialValues(zoneId)
  /* Configuring page header */
  const headingProps = {
    marginBottom: 3,
    title: t('Edit zone'),
    textAlign: 'left'
  }
  /* Function to handle back click */
  const handleBack = () => history.goBack()

  return (
    <PageWrapper
      onBack={handleBack}
      breadcrumbs={<HeaderBreadcrumbs />}
      headingProps={headingProps}>
      <Row noGutters>
        {loading ? (
          <Col>
            <Spinner height="70vh" text={t('Zone loading')} />
          </Col>
        ) : (
          <Col cw={[12, 12, 8, 6]}>
            <ZoneAdvancedForm initialData={initialValues} />
          </Col>
        )}
      </Row>
    </PageWrapper>
  )
}

export default ZoneEdit
