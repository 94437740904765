import { Box, Col, Img, Row, Text } from '@qonsoll/react-design'
import { ButtonAccent, CardDropdown, SelectableCard } from 'components'
import { Tooltip, message } from 'antd'
import {
  deleteDocument,
  updateParentHasManyRelationship
} from 'services/api/firebase'

import { COLLECTIONS } from '__constants__'
import { Icon } from '@qonsoll/icons'
import PropTypes from 'prop-types'
import { doc } from 'firebase/firestore'
import { firestore } from 'services/firebase'
import printJS from 'print-js'
import styles from './QRCodeSimpleView.styles'
import { useDocumentData } from 'react-firebase-hooks/firestore'
import { useGetDocumentsByIds } from 'hooks'
import { useMemo } from 'react'
import { useParams } from 'react-router-dom'
import { useTranslations } from 'contexts/Translation'

const QRCodeSimpleView = (props) => {
  const { restaurant, qRCode, index, isSelected, refresh } = props

  /* Getting translations instance */
  const { t } = useTranslations()

  const { zoneId } = useParams()

  // Cocktails menu
  // eslint-disable-next-line no-unused-vars
  const [cocktailMenu, cocktailMenuLoading] = useDocumentData(
    restaurant?.automaticMenuId &&
      doc(firestore, COLLECTIONS.MENUS, restaurant?.automaticMenuId)
  )

  /* Getting menu dishes information */
  const [menusData, menuLoading] = useGetDocumentsByIds({
    entityIds: qRCode?.menu,
    collectionName: COLLECTIONS.MENUS
  })

  const onPrintQrCode = (e) => {
    e.stopPropagation()
    printJS({
      printable: qRCode?.qrCodeUrl,
      type: 'image',
      documentTitle: '',
      imageStyle: 'margin-left:20%; margin-right:20%;'
    })
  }

  const deleteText = t('QR-code ') + qRCode?.table

  // Available dish menu by this qrCode plus cocktail menu
  const visibleMenusTitle = useMemo(
    () => menusData?.map((menu) => menu?.title).join(', '),
    [menusData]
  )

  const handleDelete = async () => {
    try {
      await updateParentHasManyRelationship(
        zoneId,
        COLLECTIONS.ZONES,
        'qRCodes',
        qRCode?._id,
        true
      )
      await deleteDocument(COLLECTIONS.Q_R_CODES, qRCode?._id)
      message.success(t('QR-Code successfully deleted'))
      refresh?.()
    } catch (error) {
      message.error(error.message)
    }
  }

  return (
    <CardDropdown
      document={qRCode}
      index={index}
      data-testid="qRCodeListItem"
      deleteText={deleteText}
      height="100%"
      cursor="default">
      <SelectableCard
        isSelected={isSelected}
        height="100%"
        style={{ height: '100%', display: 'flex', flexDirection: 'column' }}>
        <Row noGutters h="right">
          <Col cw="auto">
            <Tooltip title={t('Print Qrcode')}>
              <ButtonAccent
                onClick={onPrintQrCode}
                type="text"
                icon={
                  <Box>
                    <Icon size={20} name="PrinterOutlined" />
                  </Box>
                }
                disabled={!qRCode?.qrCodeUrl}
              />
            </Tooltip>
          </Col>
          <Col cw="auto">
            <Tooltip title={t('Delete Qrcode')}>
              <ButtonAccent
                onClick={handleDelete}
                type="text"
                danger
                icon={<Icon size={20} name="Trash4Outlined" />}
              />
            </Tooltip>
          </Col>
        </Row>

        <Row noGutters {...styles.fieldRowProps} v="center" h="center" flex="1">
          <Col cw={qRCode?.qrCodeUrl ? 12 : 'auto'}>
            <Img
              style={{ borderRadius: '8px' }}
              alt="QR Code"
              src={qRCode?.qrCodeUrl}
              width="100%"
              height="100%"
            />
          </Col>
        </Row>
        <Row noGutters {...styles.fieldRowProps}>
          <Col>
            <Text type="secondary">{t('Menu')}</Text>
          </Col>
          <Col cw="auto" maxWidth="100%">
            <Text>
              {(!menuLoading && !cocktailMenuLoading && visibleMenusTitle) ||
                t('Removed menu')}
            </Text>
          </Col>
        </Row>
        <Row noGutters {...styles.fieldRowProps}>
          <Col>
            <Text type="secondary">{t('Table number')}</Text>
          </Col>
          <Col cw="auto">
            <Text {...styles.fieldTitleProps}># {qRCode?.table}</Text>
          </Col>
        </Row>
      </SelectableCard>
    </CardDropdown>
  )
}

QRCodeSimpleView.propTypes = {
  qRCode: PropTypes.object,
  index: PropTypes.number,
  isSelected: PropTypes.bool,
  refresh: PropTypes.func,
  restaurant: PropTypes.object
}

export default QRCodeSimpleView
