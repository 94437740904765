import { ARIA_ROLES } from '__constants__'
import PropTypes from 'prop-types'
import { Radio } from 'antd'
import { createElement } from 'react'

// A component that allows you to use the aria attributes in Radio.Group, Select,
// or a div components
// props {ariaLabelledby} string
// props {children} node
// props {style} object
// props {role} string
const AriaGroup = (props) => {
  const { ariaLabelledby, children, style, role, ...rest } = props

  // object with used components as aria containers
  const usedComponents = {
    radiogroup: { component: Radio.Group },
    group: {
      component: 'div'
    }
  }

  // role
  const roleComputed = role || ARIA_ROLES.GROUP

  return createElement(
    usedComponents[roleComputed].component,
    {
      role: roleComputed,
      'aria-labelledby': ariaLabelledby,
      style: style,
      ...rest
    },
    children
  )
}

AriaGroup.propTypes = {
  ariaLabelledby: PropTypes.string,
  children: PropTypes.node,
  style: PropTypes.object,
  role: PropTypes.string,
  isRadio: PropTypes.bool
}

export default AriaGroup
