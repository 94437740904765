import { useEffect } from 'react'
import { Form } from 'antd'

const useInitializeQRCodeAdvancedForm = (initialData) => {
  /* Getting form instance */
  const [form] = Form.useForm()

  /* Setting initial data */
  useEffect(() => {
    if (initialData) {
      /* Getting initial values */
      const { qRCode, menu, restaurant } = initialData

      /* Formatting date fields */
      const formValues = {
        qRCode: {
          table: qRCode?.['table'] ?? null,
          source: qRCode?.['source'] ?? null
        },
        menu: menu ?? null,
        restaurant: restaurant ?? null
      }

      /* Setting initial values to form instance */
      form.setFieldsValue(formValues)
    }
  }, [initialData, form])

  return { form }
}

export default useInitializeQRCodeAdvancedForm
