import { collection, orderBy, query } from 'firebase/firestore'

import { COLLECTIONS } from '__constants__'
import { firestore } from 'services/firebase'
import { useCollectionData } from 'react-firebase-hooks/firestore'
import { useMemo } from 'react'

/**
 * It returns an array of values that are used to render the ingredients
 * @param props - An object that contains the query parameters.
 * @returns An array of values.
 */
const useGetIngredients = (props) => {
  const collectionPath = collection(firestore, COLLECTIONS.INGREDIENTS)

  /* Query for getting collection reference */
  const orderByParam = useMemo(() => {
    if (Array.isArray(props?.orderBy)) {
      const [field, type] = props.orderBy
      return field && type ? orderBy(field, type) : null
    }
    return null
  }, [props?.orderBy])

  const queryParams = [collectionPath, orderByParam]?.filter(Boolean)

  return useCollectionData(query(...queryParams))
}

export default useGetIngredients
