import { MENU_ACTIVITIES } from '__constants__'

/* It checks menu status and return correct value, was written because of old and new structure */
const checkMenuStatus = (menu) => {
  if (MENU_ACTIVITIES?.[menu?.status]) {
    return MENU_ACTIVITIES?.[menu?.status]
  } else if (typeof menu?.status === 'boolean') {
    return menu?.status ? MENU_ACTIVITIES?.ACTIVE : MENU_ACTIVITIES?.NOT_ACTIVE
  }

  return null
}

export default checkMenuStatus
