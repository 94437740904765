import { Col, Container, Option, Row, Title } from '@qonsoll/react-design'

import PropTypes from 'prop-types'
import { SelectWithFixedDropdown } from 'components'
import _ from 'lodash'
import { getId } from 'services/api/firebase'
import { useGetIngredients } from 'domains/Ingredient/hooks'
import { useMemo } from 'react'
import { useTranslations } from 'contexts/Translation'

// Takes a value of type object and onChange function
// Returns selected option object
const IngredientSelect = (props) => {
  const {
    value,
    onChange,
    showTitle = true,
    allowClear = true,
    exclude = [],
    mode,
    placeholder,
    oneOption,
    showSearch = false,
    ...rest
  } = props

  /* Getting all possibility variants for select */
  const [allIngredients = [], loading] = useGetIngredients()
  /* Getting translation instance */
  const { t } = useTranslations()

  /* Checking if mode is multiple */
  const isMultiple = useMemo(() => mode === 'multiple', [mode])
  /* Handling case when db record not added yet but value already exists and ready to be used */
  const valueComputed = useMemo(() => {
    if (isMultiple) return value
    if (oneOption) return value
    return value?._id || value ? getId('Ingredients') : null
  }, [isMultiple, value, oneOption])

  /* Filtering out excluded values */
  const optionsComputed = useMemo(() => {
    const _options = valueComputed
      ? _.uniqBy([...allIngredients, valueComputed], '_id')
      : allIngredients
    const filteredOptions = _options?.filter((o) => !exclude?.includes(o?._id))
    return filteredOptions
  }, [allIngredients, valueComputed, exclude])

  /* Handling change selected option */
  const handleChange = (value) => {
    if (isMultiple) {
      const _options = optionsComputed?.filter((o) => value?.includes(o?._id))
      onChange?.(_options || null, value)
    } else {
      const _option = optionsComputed?.find(({ _id: id }) => id === value)
      onChange?.(_option || null, value)
    }
  }

  /**
   * If the input is found in the option, return true
   * @param input - The value of the input field
   * @param option - the option that is being filtered
   */
  const handleFilterOptions = (input, option) =>
    option?.props?.children?.toLowerCase()?.indexOf(input?.toLowerCase()) >= 0

  return (
    <Container {...rest}>
      {showTitle && (
        <Title mb={2} level={4}>
          {t('Ingredient')}
        </Title>
      )}
      <Row noGutters>
        <Col>
          <SelectWithFixedDropdown
            showSearch={showSearch}
            aria-label="Select an ingredient"
            aria-describedby="select-description"
            mode={mode}
            loading={loading}
            onChange={handleChange}
            allowClear={allowClear}
            filterOption={handleFilterOptions}
            placeholder={placeholder ?? t('Please, select ingredient')}
            value={valueComputed}>
            {optionsComputed.map((ingredient) => (
              <Option key={ingredient?._id} value={ingredient?._id}>
                {ingredient?.name}
              </Option>
            ))}
          </SelectWithFixedDropdown>
        </Col>
      </Row>
    </Container>
  )
}

IngredientSelect.propTypes = {
  value: PropTypes.object,
  onChange: PropTypes.func,
  showTitle: PropTypes.bool,
  allowClear: PropTypes.bool,
  exclude: PropTypes.array,
  mode: PropTypes.string,
  oneOption: PropTypes.bool,
  showSearch: PropTypes.bool,
  placeholder: PropTypes.string
}

export default IngredientSelect
