import { Col, PageWrapper, Row } from '@qonsoll/react-design'
import { HeaderBreadcrumbs, Spinner } from 'components'
import { MenuTypeList, MenuTypeSortMenu } from 'domains/MenuType/components'
import { useFilter, useListView } from 'hooks'

import PATHS from 'pages/paths'
import { PageActions } from 'components'
import PropTypes from 'prop-types'
import { useGetMenuTypes } from 'domains/MenuType/hooks'
import { useMemo } from 'react'
import { useMenuTypeBaseActions } from 'domains/MenuType/hooks'
import { useTranslations } from 'contexts/Translation'

const MenuTypesAllPage = (props) => {
  const { isListWithCreate, withHeader = true } = props

  /* Getting translations instance */
  const { t } = useTranslations()
  /* State for controlling list view with localStorage remembering */
  const [listView, setListView] = useListView('menuType')
  /* Getting filter params */
  const filterParams = useFilter('menuType')
  /* Destructuring filter params */
  const { filterVisibility, filterData, setFilterData } = filterParams
  /* Building filter query */
  const ref = useMemo(() => filterData, [filterData])
  /* Fetching menuTypes */
  const [menuTypes, loading] = useGetMenuTypes(ref)
  /* Getting base actions */
  const listActions = useMenuTypeBaseActions()

  /* Configuring page header */
  const headingProps = {
    title: t('Menu types'),
    titleSize: 3,
    marginBottom: 3,
    textAlign: 'left'
  }

  /* Configuring page actions */
  const actions = {
    filter: false,
    sort: true,
    listView: true,
    create: Boolean(
      PATHS.AUTHENTICATED.MENU_TYPE_CREATE ||
        PATHS.AUTHENTICATED.MENU_TYPE_WIZARD
    )
  }

  /* Extracting base actions */
  const { onBack, onCreate } = listActions

  return (
    <PageWrapper
      action={
        withHeader ? (
          <PageActions
            {...filterParams}
            listView={listView}
            setListView={setListView}
            onCreate={onCreate}
            actions={actions}
            createText={t('Create menu type')}
            sortMenu={
              <MenuTypeSortMenu
                filteredData={filterData}
                setFilteredData={setFilterData}
              />
            }
          />
        ) : null
      }
      onBack={withHeader ? onBack : null}
      breadcrumbs={withHeader ? <HeaderBreadcrumbs /> : null}
      headingProps={withHeader ? headingProps : null}>
      <Row noGutters>
        {loading ? (
          <Col>
            <Spinner height="70vh" text={t('Menu type loading')} />
          </Col>
        ) : (
          <Col cw={!filterVisibility && 12}>
            <MenuTypeList
              listView={listView}
              isListWithCreate={isListWithCreate}
              actions={{ onCreate }}
              menuTypes={menuTypes}
            />
          </Col>
        )}
      </Row>
    </PageWrapper>
  )
}

MenuTypesAllPage.propTypes = {
  isListWithCreate: PropTypes.bool,
  withHeader: PropTypes.bool
}

export default MenuTypesAllPage
