import { message } from 'antd'
import { useCallback } from 'react'

const API_KEY = process.env.REACT_APP_GOOGLE_MAP_API_KEY

const useGetAddressInfo = () => {
  return useCallback(async (locationId) => {
    try {
      // Make a GET request to Google Maps Geocoding API using the place_id and API key.
      const response = await fetch(
        `https://maps.googleapis.com/maps/api/geocode/json?place_id=${locationId}&key=${API_KEY}&language=en`
      )

      const data = await response.json()
      // If the status of the response is 'OK', extract the address components.
      if (data.status === 'OK') {
        const addressComponents = data?.results[0]?.address_components

        // to cover some address gaps when save address in DB we need to save full address info
        const locationFullInfo = Object.assign({}, data?.results)

        // Find the country, city, street, street number, and zip code from the address components.

        // we need to check few of this possible address types because in different countries they can be
        // different and we need to cover all possible cases for city. In future probably we need to to the same for
        // other address types like neighborhood, area like "Malopolskie voevodstvo in Poland, Krakow"
        //  or big shop-centers, airports, etc - for now only one addressNumber display instead of it
        //  display this info now so keep it in plan for future. Now we cover city + State for example
        // Edmonton - city, Alberta - state, Canada - country. If user write only Alberta - we will wright it as a city

        const country =
          addressComponents?.find((component) =>
            component.types.includes('country')
          )?.long_name || null
        const cityComponent = addressComponents?.find(
          (component) =>
            component.types.includes('postal_town') ||
            (component.types.includes('locality') &&
              component.types.includes('political'))
        )?.long_name
        // cover the case when city has state and without it address will be not full
        const stateComponent = addressComponents?.find(
          (component) =>
            component.types.includes('administrative_area_level_1') &&
            component.types.includes('political')
        )?.long_name

        let city = ''
        // cover the case when city and region are the same word according to Goggle response
        if (cityComponent) {
          city += cityComponent
          if (stateComponent && stateComponent !== cityComponent) {
            city += `, ${stateComponent}`
          }
        } else if (stateComponent) {
          city += stateComponent
        }

        const streetName =
          addressComponents.find((component) =>
            component.types.includes('route')
          )?.long_name || null
        const streetNumber =
          addressComponents.find((component) =>
            component.types.includes('street_number')
          )?.long_name || null
        const zipCode =
          addressComponents.find((component) =>
            component.types.includes('postal_code')
          )?.long_name || null

        return {
          country,
          city,
          streetName,
          streetNumber,
          zipCode,
          locationFullInfo
        }
      }
    } catch (error) {
      message.error('Geocoding API Error', error)
    }
  }, [])
}

export default useGetAddressInfo
