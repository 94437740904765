import { createDocument, getId, updateDocument } from 'services/api/firebase'

import { COLLECTIONS } from '__constants__'
import { OrderStatus } from 'models'
import { message } from 'antd'
import { useGetErrorText } from 'hooks'
import { useHistory } from 'react-router-dom'
import { useState } from 'react'
import { useTranslations } from 'contexts/Translation'

const useActionsOrderStatusSimpleForm = ({ initialData, form } = {}) => {
  /* State for loading */
  const [loading, setLoading] = useState(false)

  /* Getting translations instance */
  const { t } = useTranslations()

  /* Getting history instance */
  const history = useHistory()
  const getErrorText = useGetErrorText()
  /* Function for preparing values for saving */
  const prepareValues = async (values, additionalValues) => {
    /* Getting id */
    const orderStatusId = initialData?._id || getId(COLLECTIONS.ORDER_STATUSES)

    /* Preparing orderStatus values */
    const preparedValues = {
      _id: orderStatusId,
      ...additionalValues,
      name: values?.['name'] ?? null,
      value: values?.['value'] ?? null
    }
    return preparedValues
  }

  /* Function for saving orderStatus form */
  const saveForm = async (values, callback) => {
    try {
      // Prepare data to be saved
      const data = await prepareValues(values)
      // Save data
      if (initialData) {
        await updateDocument(
          COLLECTIONS.ORDER_STATUSES,
          initialData.orderStatus?._id,
          data
        )
        message.success(t('OrderStatus successfully updated'))
      } else {
        await createDocument(COLLECTIONS.ORDER_STATUSES, data, data._id)
        message.success(t('OrderStatus successfully created'))
      }
      // Final callback
      callback?.()
    } catch (error) {
      throw new Error(getErrorText(error))
    }
  }

  /* Validating form */
  const validateForm = (values) => {
    try {
      // Prepare data to be validated
      const validationData = {
        name: values?.['name'],
        value: values?.['value']
      }
      OrderStatus.validationSchema.validateSync(validationData)
    } catch (error) {
      throw new Error(t('Validation error: ') + error.message)
    }
  }

  const onFinish = async () => {
    if (loading) return // Avoid multiple calls

    try {
      setLoading(true)
      // Get form values
      const formValues = form.getFieldsValue()
      // Validate fields
      validateForm(formValues)
      // Final callback
      const callback = () => history.goBack()
      // Save data
      await saveForm(formValues, callback)
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error(error)
      setLoading(false)
      message.error(error.message)
    }
  }

  const onReset = () => {
    form.resetFields()
  }

  return { onFinish, onReset, loading, saveForm, validateForm, prepareValues }
}

export default useActionsOrderStatusSimpleForm
