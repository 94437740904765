import { message, notification } from 'antd'
import { useCallback, useMemo } from 'react'
import PATHS from 'pages/paths'
import { createDocument, deleteDocument } from 'services/api/firebase'

import { useTranslations } from 'contexts/Translation'
import { MAIL_TYPES, COLLECTIONS, USER_ROLES, ADMIN_TYPES } from '__constants__'
import { ButtonAccent } from 'components'

const APP_URL = process.env.REACT_APP_DEPLOYED_APP_URL
const APP_FUNCTIONS = process.env.REACT_APP_FIREBASE_FUNCTIONS_URL

const useAdminActions = ({
  onSelect,
  admin,
  currentCustomerId,
  isUserOwner,
  user
}) => {
  /* Getting translations instance */
  const { t } = useTranslations()
  /* Handling selection of the item */
  const handleSelect = useCallback(
    (e) => {
      e.stopPropagation()
      onSelect?.(admin?._id)
    },
    [admin?._id, onSelect]
  )

  const onConfirmAdminDelete = useCallback(
    async (key) => {
      try {
        const request = await fetch(
          `${APP_FUNCTIONS}/users/delete-admin/${admin?._id}?companyId=${currentCustomerId}`,
          {
            method: 'DELETE'
          }
        )

        if (!request.ok) {
          message.error(t('Error during deleted admin'))
        } else {
          message.success(t('Admin successfully deleted '))
        }
      } catch (error) {
        message.error(
          `${t('Something went wrong during admin deletion')} ${error}`
        )
      } finally {
        notification.close(key)
      }
    },
    [admin?._id, currentCustomerId, t]
  )

  const onDeleteActiveAdmin = useCallback(() => {
    const key = `open${Date.now()}`
    const btn = (
      <ButtonAccent
        type="secondary"
        danger
        size="small"
        onClick={() => onConfirmAdminDelete(key)}>
        {t('Confirm')}
      </ButtonAccent>
    )

    notification.warn({
      message: `${t('Are you sure you want to delete the selected Admin')}? ${t(
        'The deletion cannot be undone'
      )}.`,
      btn,
      key
    })
  }, [onConfirmAdminDelete, t])

  const onResentNotification = useCallback(async () => {
    try {
      const data = {
        _id: admin?._id,
        userEmail: admin?._id,
        buttonLink: `${APP_URL}${PATHS.UNAUTHENTICATED.SIGNUP_WITH_EMAIL}?email=${admin?._id}`,
        customerId: currentCustomerId,
        type: MAIL_TYPES.COMPANY_ADMIN_INVITATION
      }
      await deleteDocument(COLLECTIONS.MAILS, admin?._id)
      await createDocument(COLLECTIONS.MAILS, data, data?._id)
      message.success(t('Invitation successfully resent'))
    } catch (error) {
      message.error(
        `${t('Something went wrong during resent invitation')} ${error}`
      )
    }
  }, [admin?._id, currentCustomerId, t])

  const onConfirmEmailDelete = useCallback(
    async (key) => {
      try {
        await deleteDocument(COLLECTIONS.MAILS, admin?._id)
        message.success(t('Invited email successfully deleted'))
      } catch (error) {
        message.error(
          `${t('Something went wrong while deleting the invited email')}, ${
            error.message
          }`
        )
      } finally {
        notification.close(key)
      }
    },
    [admin?._id, t]
  )

  const onDeleteEmail = useCallback(() => {
    const key = `open${Date.now()}`
    const btn = (
      <ButtonAccent
        type="secondary"
        danger
        size="small"
        onClick={() => onConfirmEmailDelete(key)}>
        {t('Confirm')}
      </ButtonAccent>
    )

    notification.warn({
      message: `${t(
        'Are you sure you want to delete the selected email '
      )}? ${t('The deletion cannot be undone')}.`,
      btn,
      key
    })
  }, [onConfirmEmailDelete, t])

  const adminData = useMemo(() => {
    const {
      firstName,
      lastName,
      userEmail,
      email,
      type,
      companyRoles = {}
    } = admin || {}

    const adminInitials =
      firstName || lastName
        ? `${firstName?.at(0).toUpperCase()}${lastName?.at(0).toUpperCase()}`
        : null

    const adminEmail = userEmail || email
    const adminRole = USER_ROLES[companyRoles[currentCustomerId]] || 'n/a'
    const fullName = `${firstName ?? ''} ${lastName ?? ''}`
    const showName = fullName?.trim()?.length > 0 ? fullName : 'n/a'
    const disabledAdminDeletion = user?.email === admin?.email
    const isVisibleResendButton =
      isUserOwner && type === MAIL_TYPES.COMPANY_ADMIN_INVITATION
    const adminType =
      type === MAIL_TYPES.COMPANY_ADMIN_INVITATION
        ? t(ADMIN_TYPES.INVITED)
        : t(ADMIN_TYPES.ACTIVE)

    const ribbonBg =
      type === MAIL_TYPES.COMPANY_ADMIN_INVITATION
        ? 'var(--ribbon-disabled-bg)'
        : 'var(--ribbon-active-bg)'

    let onDeleteAdmin

    if (type === MAIL_TYPES.COMPANY_ADMIN_INVITATION) {
      onDeleteAdmin = onDeleteEmail
    } else {
      onDeleteAdmin = onDeleteActiveAdmin
    }

    return {
      adminInitials,
      adminEmail,
      adminRole,
      fullName,
      showName,
      adminType,
      ribbonBg,
      onDeleteAdmin,
      disabledAdminDeletion,
      isVisibleResendButton
    }
  }, [
    admin,
    currentCustomerId,
    isUserOwner,
    onDeleteActiveAdmin,
    onDeleteEmail,
    t,
    user?.email
  ])

  return useMemo(
    () => ({
      onDeleteEmail,
      onResentNotification,
      onDeleteActiveAdmin,
      handleSelect,
      adminData
    }),
    [
      adminData,
      handleSelect,
      onDeleteActiveAdmin,
      onDeleteEmail,
      onResentNotification
    ]
  )
}

export default useAdminActions
