import { Box } from '@qonsoll/react-design'
import styled from 'styled-components'

const BlackoutContentStyled = styled(Box)`
  background: rgba(0, 0, 0, 0.7);
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  z-index: 999;
`

export default BlackoutContentStyled
