import { AddItemCard, Empty, SimpleSearchInput } from 'components'
import { Col, Row, Text } from '@qonsoll/react-design'

import Fuse from 'fuse.js'
import PropTypes from 'prop-types'
import { QRCodeSimpleView } from 'domains/QRCode/components'
import { useMemo } from 'react'
import { useSimpleSearch } from 'hooks'
import { useTranslations } from 'contexts/Translation'

const QRCodeList = (props) => {
  const {
    restaurant,
    qRCodes,
    isListWithCreate,
    listView,
    emptyProps,
    actions,
    cw = [12, 6, 6],
    selectedItems,
    setSelectedItems,
    isSearchQRCode,
    refresh
  } = props

  /* Getting translations instance */
  const { t } = useTranslations()

  /* Object for search items in a list*/
  const fuseObject = useMemo(
    () =>
      new Fuse(qRCodes, {
        // At what point does the match algorithm give up. 0 - max, 1 - min
        threshold: 0.2,
        keys: ['table']
      }),
    [qRCodes]
  )
  const { onSearchInputChange, searchRef, searchResult } = useSimpleSearch(
    qRCodes,
    fuseObject,
    1
  )

  /* Checking if list is empty */
  const isEmpty = !qRCodes?.length
  /* Computing item width */
  const itemWidth = listView ? 12 : cw

  const isSelected = (id) => {
    return selectedItems?.some(({ _id: existId }) => existId === id)
  }
  const onSelectItem = ({ _id, ...rest }) => {
    if (rest?.qrCodeUrl) {
      if (isSelected(_id)) {
        const indexForRemove = selectedItems?.findIndex(
          ({ _id: existId }) => existId === _id
        )

        const copySelectedItems = [...selectedItems]
        copySelectedItems?.splice(indexForRemove, 1)

        setSelectedItems?.(copySelectedItems)
      } else {
        setSelectedItems?.([...selectedItems, { _id, ...rest }])
      }
    }
  }

  const isNoRelatedSearchResults = !searchResult?.length && !isEmpty
  const isRelatedSearchResults = searchResult?.length && !isEmpty

  return (
    <>
      <Row noGutters mb={3}>
        <Col cw={12}>
          {isSearchQRCode && (
            <SimpleSearchInput
              searchItems={onSearchInputChange}
              searchRef={searchRef}
              placeholder={t('Search QR-codes by table number')}
            />
          )}
        </Col>
      </Row>

      <Row negativeBlockMargin>
        {isListWithCreate && actions?.onCreate && !isNoRelatedSearchResults && (
          <Col mb={3} cw={itemWidth}>
            <AddItemCard onClick={actions?.onCreate} minHeight="98px" />
          </Col>
        )}
        {isRelatedSearchResults ? (
          searchResult?.map((qRCode, index) => (
            <Col mb={3} key={qRCode?._id || index} cw={itemWidth}>
              <QRCodeSimpleView
                refresh={refresh}
                restaurant={restaurant}
                actions={actions}
                index={index}
                qRCode={qRCode}
                onSelectItem={onSelectItem}
                isSelectedItem={isSelected}
              />
            </Col>
          ))
        ) : (
          <Col cw={12}>
            {isNoRelatedSearchResults ? (
              <Empty
                message={t('No QR codes with such table number')}
                onCreateButtonClick={actions?.onCreate}
                buttonMessage={t('Create new QR code')}
              />
            ) : (
              <Text variant="overline" color="var(--ql-state-disabled-color)">
                {emptyProps?.message || t('No QR codes have been created yet')}
              </Text>
            )}
          </Col>
        )}
      </Row>
    </>
  )
}

QRCodeList.propTypes = {
  qRCodes: PropTypes.array,
  isListWithCreate: PropTypes.bool,
  listView: PropTypes.bool,
  emptyProps: PropTypes.object,
  actions: PropTypes.any,
  cw: PropTypes.any,
  selectedItems: PropTypes.array,
  setSelectedItems: PropTypes.func,
  isSearchQRCode: PropTypes.bool,
  restaurant: PropTypes.object,
  refresh: PropTypes.func
}

export default QRCodeList
