import { COCKTAIL_AVAILABILITIES_KEYS, COLLECTIONS } from '__constants__'
import { getId, updateDocument, updateParent } from 'services/api/firebase'

import { COCKTAIL_STATUS } from '__constants__/enums'
import { Cocktail } from 'models'
import { message } from 'antd'
import { uploadImage } from 'helpers'
import { useActionsRecipeSimpleForm } from 'domains/Recipe/components/RecipeSimpleForm/hooks'
import { useCustomerContext } from 'contexts/CustomerContext'
import { useGenerateCoctailDescriptionFromIngredientsAndAllergens } from 'domains/Cocktail/components/CocktailAdvancedForm/hooks/generate'
import { useGetErrorText } from 'hooks'
import { useHistory } from 'react-router-dom'
import { useState } from 'react'
import { useTranslations } from 'contexts/Translation'

const useActionsCocktailAdvancedForm = ({
  initialData,
  form,
  customOnFinish,
  customSaveCocktail
} = {}) => {
  const { currentCustomerId } = useCustomerContext()

  /* State for loading */
  const [loading, setLoading] = useState(false)
  /* Getting translations instance */
  const { t } = useTranslations()
  /* Getting history instance */
  const history = useHistory()
  /* Getting history state */
  const historyState = history.location.state

  const getErrorText = useGetErrorText()

  /* Getting description generator function */
  const generateDescription =
    useGenerateCoctailDescriptionFromIngredientsAndAllergens()
  /* Getting functions for recipe */
  const { saveRecipes } = useActionsRecipeSimpleForm(initialData?.cocktail)

  /* Function for preparing values for saving */
  const prepareValues = async (values = {}, additionalValues = {}) => {
    /* Getting id */
    const cocktailId =
      initialData?.['cocktail']?._id || getId(COLLECTIONS.COCKTAILS_NEW)

    const description = await generateDescription({
      ingredients: values['ingredients'],
      allergens: values['allergens']
    })

    /* Preparing cocktail values */
    const preparedValues = {
      _id: cocktailId,
      ...additionalValues,
      _createdBy: currentCustomerId,
      companyId: currentCustomerId,
      status: COCKTAIL_STATUS.PRIVATE,
      imageUrl: (await uploadImage(values?.['cocktail']?.['imageUrl'])) ?? null,
      title: values?.['cocktail']?.['title'] ?? null,
      price: values?.['cocktail']?.['price'] ?? 0,
      description: description ?? null,
      ingredients: values['ingredients'] ?? [],
      measurementTypes: values['measurementTypes'] ?? [],
      allergens: values['allergens'] ?? [],
      methods: values['methods'] ?? [],
      alcoholTasteTypes: values['alcoholTasteTypes'] ?? [],
      mainSpiritType: values['mainSpiritType'] ?? null,
      flavor: values['flavor'] ?? null,
      glassType: values['glassType'] ?? null,
      decorationType: values['decorationType'] ?? null,
      specialComment: values['specialComment'] ?? null,
      availability: values['availability'] ?? COCKTAIL_AVAILABILITIES_KEYS.NO
    }

    return preparedValues
  }

  /* Saving form data */
  const saveForm = async (values, callback) => {
    try {
      /* Getting the recipes from the recipe form and then adding them to the cocktail form. */
      const [recipesIds, ingredients] = await saveRecipes(values?.recipes)
      /* Adding the recipes to the cocktail. */
      const data = await prepareValues(
        { ...values, ingredients },
        {
          recipes: recipesIds
        }
      )

      if (historyState?.parent)
        await updateParent(historyState.parent, data._id)

      if (customSaveCocktail) customSaveCocktail(data)
      else if (initialData) {
        // Save data
        await updateDocument(
          COLLECTIONS.COCKTAILS_NEW,
          initialData.cocktail?._id,
          data
        )
        message.success(t('Drink successfully updated'))
      }
      // Final callback
      callback?.()
    } catch (error) {
      throw new Error(getErrorText(error))
    }
  }

  /* Function for validating form */
  const validateForm = (values) => {
    try {
      // Prepare data to be validated
      const validationData = {
        imageUrl: values?.['cocktail']?.['imageUrl'] ?? null,
        title: values?.['cocktail']?.['title'],
        price: values?.['cocktail']?.['price']
      }
      Cocktail.validationSchema.validateSync(validationData)
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error(error)
      throw new Error(t('Drink validation error: ') + t(error.message))
    }
  }

  /* On finish callback */
  const onFinish = async () => {
    if (loading) return // Avoid multiple calls

    try {
      setLoading(true)
      // Get form values
      const formValues = form.getFieldsValue()
      // Validate fields
      validateForm(formValues)
      // Final callback
      const callback = () => history.goBack()
      // Save data
      if (customOnFinish) {
        const formValuesCopy = JSON.parse(JSON.stringify(formValues))
        Object.keys(formValuesCopy).forEach((key) => {
          if (!formValuesCopy[key]) formValuesCopy[key] = null
        })
        if (formValues?.['cocktail']?.['imageUrl']) {
          await customOnFinish?.({
            ...formValuesCopy,
            cocktail: {
              ...formValues?.['cocktail'],
              imageUrl: formValues?.['cocktail']?.['imageUrl'] ?? null
            }
          })
        } else {
          await customOnFinish?.(formValuesCopy)
        }
        form.resetFields()
      } else await saveForm(formValues, callback)
    } catch (error) {
      message.error(error.message)
    } finally {
      setLoading(false)
    }
  }

  /* On cancel callback */
  const onReset = () => {
    form.resetFields()
    history.goBack()
  }

  return { onFinish, onReset, loading, saveForm, validateForm, prepareValues }
}

export default useActionsCocktailAdvancedForm
