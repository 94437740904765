import PropTypes from 'prop-types'
import { Text } from '@qonsoll/react-design'
import { useGetEnumString } from 'hooks'

const CoctailSpiritTypeColumn = (props) => {
  const { spirits } = props
  const spiritsName = Array.isArray(spirits)
    ? spirits?.map((item) => item?.name)
    : spirits?.name || ''
  const spiritsEnum = useGetEnumString(spiritsName)

  return <Text>{spiritsEnum || 'n/a'}</Text>
}

CoctailSpiritTypeColumn.propTypes = { spirits: PropTypes.array }

export default CoctailSpiritTypeColumn
