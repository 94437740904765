import { ArrowUpOutlined, MinusOutlined } from '@ant-design/icons'
import { Col, Row, Text } from '@qonsoll/react-design'
import { useCallback, useMemo, Fragment } from 'react'
import PropTypes from 'prop-types'

import { useTranslations } from 'contexts/Translation'
import { ButtonAccent } from 'components'

const ItemTypeSortMenu = (props) => {
  const { filteredData, setFilteredData } = props

  /* Getting translations instance */
  const { t } = useTranslations()

  /* Fields for sorting */
  const sortKeys = useMemo(
    () => ({
      'Item types name': 'name',
      'Creation Date': '_createdAt'
    }),
    []
  )

  /* Sort button click action */
  const sortButtonClick = (key) => () => {
    const { orderBy } = filteredData
    const [field, direction] = orderBy || []
    const newSortField = sortKeys[key]
    let newDirection = 'asc'

    if (field === newSortField) {
      newDirection = direction === 'asc' ? 'desc' : 'asc'
    }

    setFilteredData({
      ...filteredData,
      orderBy: [newSortField, newDirection]
    })
  }

  /* Getting sort icon for field */
  const getSortIcon = useCallback(
    (key) => {
      const field = sortKeys[key]
      const { orderBy } = filteredData
      const [currentSortField, direction] = orderBy || []

      if (field !== currentSortField) {
        return <MinusOutlined />
      }

      const arrowDirection = direction === 'asc' ? 0 : 180
      return <ArrowUpOutlined rotate={arrowDirection} />
    },
    [filteredData, sortKeys]
  )

  /* Clearing sort */
  const onClearSort = () => setFilteredData({ ...filteredData, orderBy: null })

  return (
    <Fragment>
      <Row>
        <Col>
          {Object.keys(sortKeys).map((key, index) => (
            <Row mb={2} key={index}>
              <Col>
                <Text>{key}</Text>
              </Col>
              <Col cw={1}>
                <ButtonAccent
                  icon={getSortIcon(key)}
                  size="small"
                  type="text"
                  onClick={sortButtonClick(key)}
                />
              </Col>
            </Row>
          ))}
        </Col>
      </Row>
      <Row>
        <Col h="center">
          <ButtonAccent onClick={onClearSort} size="small">
            {t('Clear sort')}
          </ButtonAccent>
        </Col>
      </Row>
    </Fragment>
  )
}
ItemTypeSortMenu.propTypes = {
  filteredData: PropTypes.object,
  setFilteredData: PropTypes.func
}

export default ItemTypeSortMenu
