import { Col, PageWrapper, Row } from '@qonsoll/react-design'
import { ItemTypeAdvancedForm } from 'domains/ItemType/components'
import { HeaderBreadcrumbs, Spinner } from 'components'
import { useHistory, useParams } from 'react-router-dom'
import { useTranslations } from 'contexts/Translation'
import { useGetItemTypeInitialValues } from 'domains/ItemType/hooks'

const ItemTypeEdit = () => {
  /* Getting history instance */
  const history = useHistory()
  const params = useParams()

  const { itemTypeId } = params

  const [initialValues, loading] = useGetItemTypeInitialValues(itemTypeId)

  /* Getting translations instance */
  const { t } = useTranslations()

  /* Configuring page header */
  const headingProps = {
    marginBottom: 1,
    title: t('Edit item type'),
    textAlign: 'left'
  }

  /* Function to handle back click */
  const handleBack = () => history.goBack()

  return (
    <PageWrapper
      onBack={handleBack}
      breadcrumbs={<HeaderBreadcrumbs />}
      headingProps={headingProps}>
      <Row noGutters>
        <Col cw={12}>
          {loading ? (
            <Spinner height="70vh" text={t('Item type loading')} />
          ) : (
            <ItemTypeAdvancedForm initialData={initialValues} />
          )}
        </Col>
      </Row>
    </PageWrapper>
  )
}

export default ItemTypeEdit
