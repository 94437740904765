import { Text } from '@qonsoll/react-design'
import { useGetEnumString } from 'hooks'
import PropTypes from 'prop-types'
import { useGetIngredientsFromRecipe } from 'domains/Recipe/hooks'

const CocktailIngredientsColumn = (props) => {
  const { cocktail } = props

  /* Fetching ingredients from recipe */
  const [ingredients] = useGetIngredientsFromRecipe(cocktail?.recipes)

  const ingredientsName = ingredients
    ?.map((item) => item?.name)
    ?.filter(Boolean)
  const ingredientsEnum = useGetEnumString(ingredientsName)

  return <Text>{ingredientsEnum || 'n/a'}</Text>
}

CocktailIngredientsColumn.propTypes = {
  cocktail: PropTypes.object
}

export default CocktailIngredientsColumn
