import { ButtonAccent, EnumSelect } from 'components'
import { Form, Input } from 'antd'
import {
  KEYS,
  MAX_LENGTH_FIELD,
  TABLE_AVAILABILITIES_OPTIONS
} from '__constants__'

import PropTypes from 'prop-types'
import { Title } from '@qonsoll/react-design'
import { useTranslations } from 'contexts/Translation'

const TableSimpleForm = (props) => {
  const { initialValues, onSubmit, onCancel, showTitle, form, group } = props
  /* Getting translations instance */
  const { t } = useTranslations()
  /* Getting new form instance from Form.useForm hook */
  const [baseForm] = Form.useForm()
  /* Getting used form from props or using base form */
  const usedForm = form || baseForm
  /* Resetting form on cancel */
  const handleCancel = () => {
    usedForm.resetFields()
    onCancel?.()
  }
  /* Submitting form on enter key press */
  const handleKeyPress = (e) => {
    if (e.key === KEYS.ENTER) {
      e.preventDefault()
      e.stopPropagation()
      usedForm?.submit()
    }
  }

  return group ? (
    <>
      {showTitle && (
        <Title level={4} mb={2}>
          {t('Table')}
        </Title>
      )}
      <Form.Item
        label={t('Name')}
        name={[...group, 'title']}
        rules={[
          {
            required: true,
            message: t('Please enter your table title!')
          },
          { max: MAX_LENGTH_FIELD, message: t('table title is too long!') }
        ]}>
        <Input placeholder={t('Please enter your table title')} />
      </Form.Item>
      <Form.Item label={t('Available')} name={[...group, 'available']}>
        <EnumSelect
          options={TABLE_AVAILABILITIES_OPTIONS}
          ariaLabelledby="Table availability"
        />
      </Form.Item>
    </>
  ) : (
    <Form
      scrollToFirstError
      form={usedForm}
      initialValues={initialValues}
      onFinish={onSubmit}
      layout="vertical"
      onKeyPress={handleKeyPress}
      autoComplete="off">
      {showTitle && (
        <Title level={4} mb={2}>
          {t('Table')}
        </Title>
      )}
      <Form.Item
        label={t('Name')}
        name="title"
        rules={[
          {
            required: true,
            message: t('Please, enter your table name!')
          },
          { max: MAX_LENGTH_FIELD, message: t('table name is too long!') }
        ]}>
        <Input placeholder={t('Please, enter your table name')} />
      </Form.Item>
      <Form.Item label={t('Available')} name={[...group, 'Available']}>
        <EnumSelect
          options={TABLE_AVAILABILITIES_OPTIONS}
          ariaLabelledby="Table availability"
        />
      </Form.Item>
      {!form && (
        <>
          <ButtonAccent
            mr={2}
            htmlType="button"
            onClick={handleCancel}
            type="secondary">
            {t('Cancel')}
          </ButtonAccent>
          <ButtonAccent type="primary" onClick={() => usedForm.submit()}>
            {t('Save')}
          </ButtonAccent>
        </>
      )}
    </Form>
  )
}
TableSimpleForm.propTypes = {
  initialValues: PropTypes.object,
  onSubmit: PropTypes.func,
  onCancel: PropTypes.func,
  showTitle: PropTypes.bool,
  form: PropTypes.any,
  group: PropTypes.array
}

export default TableSimpleForm
