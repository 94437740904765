import { COLLECTIONS } from '__constants__'
import { arrayRemove, arrayUnion, serverTimestamp } from 'firebase/firestore'
import {
  createDocument,
  getId,
  updateDocument,
  updateParent,
  deleteDocument
} from 'services/api/firebase'
import { useHistory, useParams } from 'react-router-dom'

import { Menu } from 'models'
import { message } from 'antd'
import { useOnSaveErrorWithCode } from 'hooks'
import { useState } from 'react'
import { useTranslations } from 'contexts/Translation'
import differenceIds from 'modules/admin-module/helpers/differenceIds'
import { useUser } from 'domains/User/context'

const useActionsMenuAdvancedForm = ({ initialData, form } = {}) => {
  /* State for loading */
  const [loading, setLoading] = useState(false)
  /* Getting translations instance */
  const { t } = useTranslations()
  /* Getting history instance */
  const history = useHistory()
  /* Getting history state */
  const historyState = history.location.state

  const onSaveErrorWithCode = useOnSaveErrorWithCode()
  const { restaurantId } = useParams()
  const { user = {} } = useUser()

  const currentCompanyId = user?.currentCompanyId ?? null

  // Try/catch is not needed here because the function is called inside onFinish which is already wrapped in try / catch
  const makeMenuCategories = async ({ menuId, categoriesValues }) => {
    const menuCategories = await categoriesValues?.map((item) => {
      const data = {
        _id: item?._id ?? getId(COLLECTIONS.MENU_CATEGORIES),
        _createdAt: item?._createdAt ?? serverTimestamp(),
        _updatedAt: serverTimestamp(),
        name: { en: item?.name?.en ?? null, no: item?.name?.no ?? null },
        menuId: menuId,
        itemTypeId: item.itemTypeId,
        itemsOrder: item?.itemsOrder?.map((menuItem) => menuItem?._id) ?? [],
        items:
          item?.itemsOrder?.reduce((acc, item) => {
            acc[item?._id] = true
            return acc
          }, {}) ?? {}
      }

      return data
    })

    const categoriesOrder =
      (await menuCategories?.map((menuCategory) => menuCategory?._id)) ?? []
    const categories =
      (await menuCategories?.reduce((acc, menuCategory) => {
        acc[menuCategory?._id] = true
        return acc
      }, {})) ?? {}

    await saveMenuCategories(menuCategories)

    return {
      categories,
      categoriesOrder
    }
  }

  // Try/catch is not needed here because the function is called inside onFinish which is already wrapped in try / catch
  const saveMenuCategories = async (menuCategories) => {
    const [removedIds, createdIds] = differenceIds(
      menuCategories,
      initialData?.menu?.categories
    )
    removedIds?.forEach((menuCategoryId) => {
      deleteDocument(COLLECTIONS.MENU_CATEGORIES, menuCategoryId)
    })

    menuCategories?.forEach((menuCategory) => {
      if (createdIds?.includes(menuCategory?._id)) {
        createDocument(
          COLLECTIONS.MENU_CATEGORIES,
          menuCategory,
          menuCategory?._id
        )
      } else {
        updateDocument(
          COLLECTIONS.MENU_CATEGORIES,
          menuCategory?._id,
          menuCategory
        )
      }
    })
  }

  /* Function for preparing values for saving */
  const prepareValues = async (values = {}, additionalValues = {}) => {
    /* Getting id */
    const menuId = initialData?.['menu']?._id || getId(COLLECTIONS.MENUS)

    const categoriesData = await makeMenuCategories({
      menuId,
      categoriesValues: values?.['menu']?.['categories']
    })

    /* Preparing menu values */
    const preparedValues = {
      _id: menuId,
      ...additionalValues,
      _createdBy: currentCompanyId,
      name: {
        en: values?.['menu']?.['name']?.['en'] ?? null,
        no: values?.['menu']?.['name']?.['no'] ?? null
      },
      company: values?.['menu']?.['company'] ?? null,
      privacy: values?.['menu']?.['privacy'] ?? false,
      description: values?.['menu']?.['description']?.trim() ?? null,
      status: values?.['menu']?.['status'] ?? false,
      ...categoriesData
    }

    return preparedValues
  }

  const onMakeMenuActive = async (data) => {
    try {
      if (data?.status) {
        await updateDocument(COLLECTIONS.RESTAURANTS, restaurantId, {
          activeMenus: arrayUnion(data?._id)
        })
      } else {
        await updateDocument(COLLECTIONS.RESTAURANTS, restaurantId, {
          activeMenus: arrayRemove(data?._id)
        })
      }
    } catch (error) {
      throw new Error(onSaveErrorWithCode(error))
    }
  }

  /* Saving form data */
  const saveForm = async (values, callback) => {
    try {
      // Prepare data to be saved
      const data = await prepareValues(values)

      if (historyState?.parent) updateParent(historyState.parent, data?._id)

      // Save data
      if (initialData) {
        onMakeMenuActive(data)
        await updateDocument(COLLECTIONS.MENUS, initialData.menu?._id, data)
        message.success(t('Menu successfully updated'))
      } else {
        onMakeMenuActive(data)

        await createDocument(COLLECTIONS.MENUS, data, data?._id)

        message.success(t('Menu successfully created'))
      }
      // Final callback
      callback?.()
    } catch (error) {
      throw new Error(onSaveErrorWithCode(error))
    }
  }

  /* Function for validating form */
  const validateForm = (values) => {
    try {
      // Prepare data to be validated
      const validationData = {
        title: values?.['menu']?.['title'],
        status: values?.['menu']?.['status']
      }
      Menu.validationSchema.validateSync(validationData)
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error(error)
      throw new Error(t('Menu validation error: ') + t(error.message))
    }
  }

  /* On finish callback */
  const onFinish = async () => {
    if (loading) return // Avoid multiple calls
    try {
      setLoading(true)
      // Get form values
      const formValues = form.getFieldsValue()

      // Validate fields
      validateForm(formValues)
      // Final callback
      const callback = () => history.goBack()
      // Save data
      await saveForm(formValues, callback)
    } catch (error) {
      message.error(error.message)
    } finally {
      setLoading(false)
    }
  }

  /* On cancel callback */
  const onReset = () => {
    form.resetFields()
    history.goBack()
  }

  return { onFinish, onReset, loading, saveForm, validateForm, prepareValues }
}

export default useActionsMenuAdvancedForm
