import { COLLECTIONS, PAYMENT_STATUSES } from '__constants__'
import { createDocument, getId } from 'services/api/firebase'
import { notification } from 'antd'

import moment from 'moment'
import { useTranslations } from 'contexts/Translation'

const useCreatePayment = ({ restaurantId, restaurantName, currency }) => {
  // [ADDITIONAL HOOKS]
  const { t, language } = useTranslations()

  return async (paymentTypes, userEmail, amount, phoneNumber) => {
    try {
      const paymentId = getId(COLLECTIONS.PAYMENTS)
      const appointmentDateFormatted = moment(new Date(), 'YYYY-MM-DD HH:mm:ss')
        ?.locale(language)
        ?.format('DD.MM.YY')
      const appointmentDateFormattedString = appointmentDateFormatted
        ? ` ${appointmentDateFormatted}`
        : ''

      // creation of payment
      await createDocument(
        COLLECTIONS.PAYMENTS,
        {
          amount: Number(amount) * 100,
          restaurantId,
          status: PAYMENT_STATUSES.CREATED_STATUS,
          paymentTypes: [paymentTypes],
          currency: currency || 'NOK',
          merchantReference: `${restaurantName} payment №${paymentId}`,
          userEmail: userEmail || '',
          phoneNumber,
          description: `${restaurantName}: ${appointmentDateFormattedString}`
        },
        paymentId
      )

      return paymentId
    } catch (error) {
      //eslint-disable-next-line
      console.error(
        `Error occured in the createPayment (${paymentTypes}) payment type`,
        error
      )

      // showing error notification on UI
      notification.error({
        message: t('Error'),
        description: t(
          'Error occured in the creation of payment, please try again later.'
        )
      })
    }
  }
}

export default useCreatePayment
