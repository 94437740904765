import { useState, useEffect } from 'react'
import { doc, onSnapshot } from 'firebase/firestore'
import { firestore } from 'services/firebase'
import { useCallback } from 'react'
import { getDocument } from 'services/api/firebase'

const useListenDocuments = (ids, collectionName) => {
  const [loading, setLoading] = useState(true)
  const [documents, setDocuments] = useState([])

  const initializeData = useCallback(async () => {
    if (Array.isArray(ids)) {
      const fetchPromises = ids?.map((id) => getDocument(collectionName, id))

      const documentsData = await Promise.all(fetchPromises)

      setDocuments(documentsData)
    }
  }, [collectionName, ids])

  useEffect(() => {
    initializeData()
  }, [initializeData])

  useEffect(() => {
    const unsubscribes = []

    // Initialize the documents
    ids.forEach((documentId) => {
      const documentRef = doc(firestore, collectionName, documentId)
      const unsubscribe = onSnapshot(documentRef, (docSnapshot) => {
        if (docSnapshot.exists()) {
          setDocuments((prevDocuments) => {
            // Update the existing document with the updated data
            const updatedDocuments = prevDocuments.map((prevDoc) => {
              if (prevDoc?._id === documentId) {
                return { _id: documentId, ...docSnapshot.data() }
              }
              return prevDoc
            })
            return updatedDocuments
          })
        }
      })
      unsubscribes.push(unsubscribe)
    })

    setLoading(false)

    // Unsubscribe from all snapshot listeners when the component unmounts
    return () => {
      unsubscribes.forEach((unsubscribe) => unsubscribe())
    }
  }, [collectionName, ids])

  return [documents, loading]
}

export default useListenDocuments
