/**
 * The function transforms an array into an object with each array element as a key and a boolean value
 * of true.
 * @param array - The parameter `array` is expected to be an array.
 * @returns If the input `array` is an array, the function will return an object where each element of
 * the array is a key with a value of `true`. If the input `array` is not an array, it will simply
 * return the input `array`.
 */
const transformArrayToObject = (array) => {
  if (Array.isArray(array)) {
    return array.reduce((acc, item) => ({ ...acc, [item]: true }), {})
  }

  return array
}

export default transformArrayToObject
