import { useEffect } from 'react'
import { Form } from 'antd'

const useInitializeTipAndTrickAdvancedForm = (initialData) => {
  /* Getting form instance */
  const [form] = Form.useForm()

  /* Setting initial data */
  useEffect(() => {
    if (initialData) {
      /* Getting initial values */
      const { tipAndTrick, mediaObjects } = initialData

      /* Formatting date fields */
      const formValues = {
        tipAndTrick: {
          title: tipAndTrick?.['title'] ?? null,
          description: tipAndTrick?.['description'] ?? null,
          content: tipAndTrick?.['content'] ?? null
        },
        mediaObjects: mediaObjects ?? null
      }

      /* Setting initial values to form instance */
      form.setFieldsValue(formValues)
    }
  }, [initialData, form])

  return { form }
}

export default useInitializeTipAndTrickAdvancedForm
