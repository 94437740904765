import { useState } from 'react'

const useCheckIfFormWasUpdated = () => {
  const [isFormWasUpdated, setIsFormWasUpdated] = useState(false)
  const onFormFieldsChange = (changedValues) => {
    changedValues && setIsFormWasUpdated(true)
  }

  const setFormWasNotUpdated = () => setIsFormWasUpdated(false)

  return { isFormWasUpdated, onFormFieldsChange, setFormWasNotUpdated }
}

export default useCheckIfFormWasUpdated
