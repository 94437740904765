import { Box, Col, Row, Text, Title } from '@qonsoll/react-design'
import PropTypes from 'prop-types'
import { useState } from 'react'
import { Form, Tabs } from 'antd'

import { ButtonAccent, CheckboxFormItem } from 'components'
import { KEYS, MENU_AVAILABILITIES_KEYS } from '__constants__'
import { useConfirmDiscardChanges } from 'hooks'
import { useTranslations } from 'contexts/Translation'
import {
  useActionsMenuAdvancedForm,
  useInitializeMenuAdvancedForm
} from './hooks'

import { CocktailTableWithCreateAndSelect } from 'domains/Cocktail/components'
import { CustomerSelect } from 'domains/Customer/components'
import { MenuSimpleForm } from 'domains/Menu/components'

const MenuAdvancedForm = (props) => {
  const { initialData, group, title, subtitle, cw } = props

  const [isFormUpdate, setIsFormUpdate] = useState(false)
  const [selectedLanguage, setSelectedLanguage] = useState('en')

  const [menuPrivacy, setMenuPrivacy] = useState(
    typeof initialData?.menu?.privacy === 'boolean'
      ? initialData?.menu?.privacy
      : initialData?.menu?.privacy === MENU_AVAILABILITIES_KEYS.PRIVATE
  )
  /* Getting translations instance */
  const { t, languages } = useTranslations()
  /* Getting form instance from hook */
  const { form } = useInitializeMenuAdvancedForm(initialData)
  const { handleCancel } = useConfirmDiscardChanges()

  /* Getting loading state and actions from hook */
  const { loading, onFinish, onReset } = useActionsMenuAdvancedForm({
    initialData,
    form
  })

  const onLanguageChange = (key) => {
    setSelectedLanguage(key)
  }
  /* Handling enter key press */
  const handleKeyPress = (e) => {
    if (e.key === KEYS.ENTER) {
      e.preventDefault()
      onFinish()
    }
  }

  const onFiledChange = (data) => {
    if (data?.menu?.cocktailsAvailabilities) {
      setIsFormUpdate(false)
    } else {
      setIsFormUpdate(true)
    }
  }

  const onCancel = () => {
    isFormUpdate ? handleCancel(onReset) : onReset()
    setIsFormUpdate(false)
  }

  const onPrivacyChangeCallback = (value) => setMenuPrivacy(value)

  return group ? (
    <>
      <Row noGutters>
        <Col cw={cw || 12}>
          <Box mb={4}>
            <Title mt={0} mb={2}>
              {title}
            </Title>
            <Text>{subtitle}</Text>
          </Box>

          <MenuSimpleForm group={[...group, 'menu']} />

          <Form.Item name={['menu', 'privacy']}>
            <CheckboxFormItem
              title={t('Privacy')}
              description={t('Determines whether or not the menu is private')}
              onChangeCallback={onPrivacyChangeCallback}
            />
          </Form.Item>

          <Form.Item name={['menu', 'status']}>
            <CheckboxFormItem
              title={t('Activeness')}
              description={t('Determines whether or not the menu is active')}
            />
          </Form.Item>

          <Box
            display={
              menuPrivacy === MENU_AVAILABILITIES_KEYS.PRIVATE || menuPrivacy
                ? 'block'
                : 'none'
            }>
            <Form.Item name={['menu', 'company']} label={t('Company')}>
              <CustomerSelect mode="multiple" />
            </Form.Item>
          </Box>
        </Col>
      </Row>
    </>
  ) : (
    <Form
      preserve
      scrollToFirstError
      onValuesChange={(data) => onFiledChange(data)}
      onKeyPress={handleKeyPress}
      onFinish={onFinish}
      layout="vertical"
      autoComplete="off"
      form={form}>
      <Row noGutters mt={-3}>
        <Col cw={cw || 12}>
          <Box mb={4}>
            <Title mb={2}>{title}</Title>
            <Text>{subtitle}</Text>
          </Box>
          <Tabs activeKey={selectedLanguage} onChange={onLanguageChange}>
            {languages?.map((language) => (
              <Tabs.TabPane key={language?.shortCode} tab={language?.name} />
            ))}
          </Tabs>

          <Form.Item name="menu">
            <MenuSimpleForm
              group={['menu']}
              selectedLanguage={selectedLanguage}
            />
          </Form.Item>

          <Form.Item name={['menu', 'privacy']}>
            <CheckboxFormItem
              title={t('Privacy')}
              description={t('Determines whether or not the menu is private')}
              onChangeCallback={onPrivacyChangeCallback}
            />
          </Form.Item>

          <Form.Item name={['menu', 'status']}>
            <CheckboxFormItem
              title={t('Activeness')}
              description={t('Determines whether or not the menu is active')}
            />
          </Form.Item>

          <Form.Item name={['menu', 'categories']}>
            <CocktailTableWithCreateAndSelect isNewMenu={true} />
          </Form.Item>
        </Col>
      </Row>

      <Box display="flex">
        <ButtonAccent
          type="secondary"
          mr={2}
          htmlType="button"
          onClick={onCancel}
          mb={4}>
          {t('Cancel')}
        </ButtonAccent>
        <ButtonAccent
          type="primary"
          loading={loading}
          onClick={() => form.submit()}>
          {t('Save')}
        </ButtonAccent>
      </Box>
    </Form>
  )
}
MenuAdvancedForm.propTypes = {
  initialData: PropTypes.object,
  group: PropTypes.array,
  title: PropTypes.string,
  subtitle: PropTypes.string,
  cw: PropTypes.array
}

export default MenuAdvancedForm
