import { Redirect, Route, Switch, useHistory } from 'react-router-dom'

import PATHS from 'pages/paths'
import { PageWrapper } from '@qonsoll/react-design'
import { SETTINGS_ROUTES } from 'pages/App/routes'
import { Tabs } from 'antd'
import { useStateWithStorage } from 'hooks'
import { useTranslations } from 'contexts/Translation'

const { TabPane } = Tabs

const Settings = () => {
  /* State for active tab control */
  const [lastActiveTab, setLastActiveTab] = useStateWithStorage(
    PATHS.AUTHENTICATED.SETTINGS_MENU_TYPES_ALL,
    `setting-last-active-tab`
  )
  /* Getting history instance */
  const history = useHistory()
  /* Getting translations instance */
  const { t } = useTranslations()

  /* Configuring page */
  const headingProps = {
    title: t('Settings'),
    textAlign: 'left',
    marginBottom: 1
  }

  /* Tab items */
  const tabsData = [
    {
      title: t('Menu types'),
      key: PATHS.AUTHENTICATED.SETTINGS_MENU_TYPES_ALL
    }
  ]

  /* Callback function to handle tab change */
  const onChangeTab = (key) => {
    setLastActiveTab(key)
    history.push(key)
  }

  return (
    <PageWrapper headingProps={headingProps}>
      <Tabs
        defaultActiveKey={lastActiveTab}
        activeKey={lastActiveTab}
        onChange={onChangeTab}>
        {tabsData.map(({ title, key }) => (
          <TabPane tab={title} key={key} />
        ))}
      </Tabs>
      <Switch>
        {SETTINGS_ROUTES.map((routeProps) => (
          <Route key={routeProps.key} {...routeProps} />
        ))}
        <Redirect to={PATHS.AUTHENTICATED.SETTINGS_MENU_TYPES_ALL} />
      </Switch>
    </PageWrapper>
  )
}

export default Settings
