import { Box, Col, Row, Text, Title } from '@qonsoll/react-design'
import {
  ButtonAccent,
  ButtonWithPopconfirm,
  ImageUploader,
  Switch
} from 'components'
import { Form, InputNumber } from 'antd'
import {
  useActionsQRCodeAdvancedForm,
  useInitializeQRCodeAdvancedForm
} from './hooks'
import { useHistory, useParams } from 'react-router-dom'

import { MenuSelectableList } from 'domains/Menu/components'
import PropTypes from 'prop-types'
import { useState } from 'react'
import { useTranslations } from 'contexts/Translation'

const QRCodeAdvancedForm = (props) => {
  const { initialData } = props
  const [isFormChanged, setIsFormChanged] = useState(false)

  const { restaurantId } = useParams()
  /* Getting translations instance */
  const { t } = useTranslations()
  /* Getting history instance */
  const history = useHistory()

  /* Getting form instance from hook */
  const { form } = useInitializeQRCodeAdvancedForm(initialData)

  const { loading, onFinish, onReset } = useActionsQRCodeAdvancedForm({
    initialData,
    form,
    restaurantId: restaurantId
  })

  const [isMultipleTable, setIsMultipleTable] = useState(false)
  const onMultiTableChange = () => setIsMultipleTable(!isMultipleTable)

  const [min, setMin] = useState(0)
  const [max, setMax] = useState(100)

  const minValUpdateHandler = (value) => {
    setMin(() => {
      const valInNum = +parseInt(value, 10)
      if (valInNum < max) {
        return valInNum
      }
      return max - 1
    })
  }

  const maxValUpdateHandler = (value) => {
    setIsFormChanged(true)
    setMax(() => {
      const valInNum = +parseInt(value, 10)
      if (valInNum > min) {
        return valInNum
      }
      return min + 1
    })
  }

  const onHandleFinish = (formValues) =>
    onFinish(formValues, { isMultipleTable, range: [min, max] })

  const onFormValuesChange = () => setIsFormChanged(true)

  const isNotGenerateManyQRCodes =
    history?.location?.state?.parent?.manyQRCodes ?? true

  return (
    <Form
      scrollToFirstError
      onValuesChange={onFormValuesChange}
      initialValues={{
        restaurant: restaurantId,
        table: history?.location?.state?.parent?.tableNumber
      }}
      onFinish={onHandleFinish}
      layout="vertical"
      autoComplete="off"
      form={form}>
      <Form.Item
        label={t('Logo in center qr code')}
        name={['image']}
        rules={[
          {
            required: false
          }
        ]}>
        <ImageUploader />
      </Form.Item>
      <Title mb={2} level={4}>
        {t('Menu')}
      </Title>
      <Form.Item
        label={['Menu']}
        name={['menu']}
        rules={[
          {
            required: true,
            message: t('Please select menu!')
          }
        ]}>
        <MenuSelectableList
          transformationLimit={0}
          withTitle={false}
          isDishesMenu
        />
      </Form.Item>

      {!isMultipleTable ? (
        <Form.Item
          label={t('Table number')}
          name={['table']}
          rules={[
            {
              required: true,
              message: t('Please enter your QR code table!')
            }
          ]}>
          <InputNumber placeholder={t('Please enter your QR code table')} />
        </Form.Item>
      ) : (
        <Row noOuterGutters>
          <Col cw={6}>
            <Form.Item
              label={t('Table number from')}
              name={['tableNumberFrom']}
              rules={[
                {
                  required: true,
                  message: t('Please enter your QR code table!')
                }
              ]}>
              <InputNumber
                value={min}
                max={max - 1}
                onChange={minValUpdateHandler}
                type="number"
                placeholder={t('Please enter your QR code table from')}
              />
            </Form.Item>
          </Col>
          <Col cw={6}>
            <Form.Item
              label={t('Table number to')}
              name={['tableNumberTo']}
              rules={[
                {
                  required: true,
                  message: t('Please enter your QR code table!')
                }
              ]}>
              <InputNumber
                value={max}
                min={min + 1}
                onBlur={onFormValuesChange}
                onChange={maxValUpdateHandler}
                type="number"
                placeholder={t('Please enter your QR code table to')}
              />
            </Form.Item>
          </Col>
        </Row>
      )}

      {isNotGenerateManyQRCodes ? (
        <Box display="flex" justifyContent="space-between" mb="16px">
          <Text type="secondary">{t('Should be generated many qr codes')}</Text>
          <Switch onChange={onMultiTableChange} />
        </Box>
      ) : null}

      <Box display="flex">
        <ButtonWithPopconfirm
          withPopconfirm={isFormChanged}
          onReset={onReset}
          documentTitle={initialData?.zone?.title}
          mb={4}>
          {t('Cancel')}
        </ButtonWithPopconfirm>
        <ButtonAccent
          type="primary"
          loading={loading}
          onClick={() => form.submit()}>
          {t('Save')}
        </ButtonAccent>
      </Box>
    </Form>
  )
}
QRCodeAdvancedForm.propTypes = {
  initialData: PropTypes.object,
  group: PropTypes.array,
  showTitle: PropTypes.bool
}
export default QRCodeAdvancedForm
