import { Col, Container, Row, Title } from '@qonsoll/react-design'

import { Input } from 'antd'
import PropTypes from 'prop-types'
import { useTranslations } from 'contexts/Translation'

const ContactPersonStep = (props) => {
  const { value, setValue, stepNumber } = props

  /* Getting translations instance */
  const { t } = useTranslations()

  /* Handling changes */
  const handleChange = (event) =>
    setValue((prev) => ({ ...prev, contactPerson: event.target.value }))
  const titleTextComputed = `${t('Step')} ${stepNumber}. ${t(
    'Representative name'
  )}`

  return (
    <Container mb="24px">
      <Title textAlign="center" variant="h4" mb="24px">
        {titleTextComputed}
      </Title>
      <Row noGutters h="center">
        <Col cw={12}>
          <Input
            value={value?.contactPerson}
            onChange={handleChange}
            placeholder={t('Please enter representative name')}
          />
        </Col>
      </Row>
    </Container>
  )
}

ContactPersonStep.propTypes = {
  value: PropTypes.object,
  setValue: PropTypes.func,
  stepNumber: PropTypes.number
}

export default ContactPersonStep
