import { Box, Col, PageWrapper, Row } from '@qonsoll/react-design'
import {
  ButtonAccent,
  HeaderBreadcrumbs,
  PageActions,
  Spinner
} from 'components'
import {
  deleteDocument,
  updateDocument,
  useDocument
} from 'services/api/firebase'
import { generatePath, useHistory, useParams } from 'react-router-dom'

import { COLLECTIONS } from '__constants__'
import { Icon } from '@qonsoll/icons'
import PATHS from 'pages/paths'
import { ZoneAdvancedView } from 'domains/Zone/components'
import { message } from 'antd'
import printJS from 'print-js'
import { useGetZoneQRCodes } from 'domains/Zone/hooks'
import { useTranslations } from 'contexts/Translation'

const ZoneShow = (props) => {
  /* Getting translations instance */
  const { t } = useTranslations()
  /* Getting history instance */
  const history = useHistory()
  /* Getting params from router */
  const { profileId, restaurantId, zoneId } = useParams()
  /* Fetching document data from useDocument hook */
  const [zone, loading] = useDocument({
    ref: COLLECTIONS.ZONES + '/' + zoneId
  })

  const [zoneQRCodes] = useGetZoneQRCodes(zone)

  /* Fetching document data from useDocument hook */
  const [restaurant, restaurantLoading] = useDocument({
    ref: COLLECTIONS.RESTAURANTS + '/' + restaurantId
  })
  /* Configuring page actions */
  const actions = { edit: true }
  /* Function to handle back click */
  const handleBack = () => history.goBack()
  /* Handling opening of the edit page */
  const handleEdit = () => {
    const pathParams = { profileId, restaurantId, zoneId }
    const path = generatePath(
      PATHS.AUTHENTICATED.RESTAURANT_ZONE_EDIT,
      pathParams
    )
    history.push(path)
  }

  /* It deletes the zone and all the QR codes associated with it */
  const handleDelete = async () => {
    await updateDocument(COLLECTIONS.RESTAURANTS, restaurant?._id, {
      zones: restaurant?.zones?.filter((item) => item !== zoneId)
    })
    if (Array?.isArray(zone?.qRCodes)) {
      zone?.qRCodes?.forEach((qRCodeId) =>
        deleteDocument(COLLECTIONS.Q_R_CODES, qRCodeId)
      )
    }
    await deleteDocument(COLLECTIONS.ZONES, zoneId)
    message.success(t('Zone was deleted successfully'))
    history.goBack()
  }

  /* Sends QR codes to print */
  const onPrintQrCode = (e) => {
    e.stopPropagation()
    const qrCodeUrls = zoneQRCodes?.map((qrCode) => qrCode?.qrCodeUrl)
    printJS({
      printable: qrCodeUrls,
      type: 'image',
      documentTitle: '',
      imageStyle: 'margin-left:20%; margin-right:20%;'
    })
  }

  /* Configuring page header */
  const headingProps = {
    marginBottom: 3,
    title: zone?.title,
    textAlign: 'left'
  }

  return (
    <PageWrapper
      headingProps={headingProps}
      action={
        <Box display="flex">
          <ButtonAccent
            mr={2}
            type="secondary"
            disabled={!zoneQRCodes}
            onClick={onPrintQrCode}
            icon={<Icon name="PrinterOutlined" mr={2} />}>
            {t('Print all QR')}
          </ButtonAccent>
          <PageActions document={zone} actions={actions} onEdit={handleEdit} />
          <ButtonAccent
            ml={2}
            type="secondary"
            onClick={handleDelete}
            icon={<Icon name="Trash4Outlined" />}
            danger
          />
        </Box>
      }
      onBack={handleBack}
      breadcrumbs={<HeaderBreadcrumbs />}>
      <Row noGutters>
        {loading && restaurantLoading ? (
          <Col>
            <Spinner height="70vh" text={t('Zone loading')} />
          </Col>
        ) : (
          <Col cw={12}>
            <ZoneAdvancedView zone={zone} restaurant={restaurant} />
          </Col>
        )}
      </Row>
    </PageWrapper>
  )
}

export default ZoneShow
