import { Box, Text } from '@qonsoll/react-design'

import { Icon } from '@qonsoll/icons'
import PropTypes from 'prop-types'

const CocktailItemColumn = (props) => {
  const { cocktail, dragable = false, draggableProps = {} } = props
  return (
    <>
      <Box
        display="flex"
        justifyContent="center"
        height="100%"
        alignItems="center"
        cursor="grab"
        id="drag-icon-wrapper"
        {...draggableProps}>
        {dragable && (
          <Icon
            mr={1}
            name="DraggerOutlined"
            size={20}
            fill="var(--ql-text-secondary)"
            id="drag-icon"
          />
        )}
      </Box>
      <Box
        display="flex"
        justifyContent="center"
        flexDirection="column"
        width="100%"
        height="100%">
        <Text level={5}>{cocktail?.title}</Text>
        {cocktail?.description && (
          <Text variant="body2" wordBreak="break-word">
            {cocktail?.description || 'n/a'}
          </Text>
        )}
      </Box>
    </>
  )
}

CocktailItemColumn.propTypes = {
  cocktail: PropTypes.object,
  dragable: PropTypes.bool,
  draggableProps: PropTypes.object
}

export default CocktailItemColumn
