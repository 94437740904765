import { Option } from '@qonsoll/react-design'
import PropTypes from 'prop-types'
import { SelectWithFixedDropdown } from 'components'
import { useTranslations } from 'contexts/Translation'

const CustomerCurrencySelect = (props) => {
  const { value, onChange, listCurrencies, mainCurrency, setMainCurrency } =
    props

  /* Getting translations instance */
  const { t } = useTranslations()

  /* Handling changes */
  const handleChange = (value) => {
    setMainCurrency?.(value)
    onChange()
  }

  return (
    <SelectWithFixedDropdown
      aria-label="Currency Selector"
      aria-describedby="currency-selector-description"
      value={mainCurrency || value}
      onChange={handleChange}
      showSearch
      placeholder={t('Please choose a currency')}>
      {listCurrencies?.map((value, index) => (
        <Option key={value + index} value={value}>
          {value}
        </Option>
      ))}
    </SelectWithFixedDropdown>
  )
}

CustomerCurrencySelect.propTypes = {
  value: PropTypes.object,
  setMainCurrency: PropTypes.func,
  onChange: PropTypes.func,
  mainCurrency: PropTypes.string,
  listCurrencies: PropTypes.array
}

export default CustomerCurrencySelect
