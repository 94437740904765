import { Box } from '@qonsoll/react-design'
import PropTypes from 'prop-types'
import { ScrollSync } from 'react-scroll-sync'
import { ScrollableTableHeader } from './components'
import { cloneElement } from 'react'

const Table = (props) => {
  const { itemsInfo } = props

  const { tableProps, numberOfAvailableItems = 0 } = itemsInfo
  return (
    <ScrollSync>
      <Box>
        <ScrollableTableHeader
          numberOfAvailability={numberOfAvailableItems}
          tableProps={tableProps}
        />
        {cloneElement(itemsInfo?.component, {
          ...itemsInfo
        })}
      </Box>
    </ScrollSync>
  )
}

Table.propTypes = {
  itemsInfo: PropTypes.object
}

export default Table
