import { Col, Container, Row, Text } from '@qonsoll/react-design'

import { Icon } from '@qonsoll/icons'
import PropTypes from 'prop-types'
import { Spinner } from 'components'
import { Tooltip } from 'antd'
import { useGetRestaurantAddress } from 'domains/Restaurant/hooks'
import { useMemo } from 'react'
import { useTranslations } from 'contexts/Translation'

export default function RestaurantAddress(props) {
  const { restaurant } = props
  const { t } = useTranslations()

  /* Getting relationship data */
  const [address, loading] = useGetRestaurantAddress(restaurant)

  const isExistsAddress = useMemo(
    () =>
      address?.city ||
      address?.country ||
      address?.streetName ||
      address?.zipCode
        ? true
        : false,
    [address]
  )

  /* A computed property. */
  const computedAddress = useMemo(() => {
    let customAddress = ''

    if (address?.streetName) customAddress += address?.streetName
    if (address?.city)
      customAddress += (customAddress ? ', ' : '') + address?.city
    if (address?.zipCode)
      customAddress += (customAddress ? ', ' : '') + address?.zipCode
    if (address?.country)
      customAddress += (customAddress ? ', ' : '') + address?.country

    return customAddress
  }, [address])

  const addressRestaurant =
    restaurant?.address && !isExistsAddress
      ? restaurant?.address
      : isExistsAddress
      ? computedAddress
      : null

  return addressRestaurant ? (
    <Container>
      <Row noGutters v="start">
        <Col cw="auto" mr={2}>
          <Tooltip title={t('Venue location')}>
            <Icon name="MarkerPin1Outlined" />
          </Tooltip>
        </Col>
        <Col>
          {loading ? <Spinner flex={1} /> : <Text>{addressRestaurant}</Text>}
        </Col>
      </Row>
    </Container>
  ) : null
}

RestaurantAddress.propTypes = {
  restaurant: PropTypes.object.isRequired
}
