import { doc, getDoc } from 'firebase/firestore'
import { getDocument } from 'services/api/firebase'
import { COLLECTIONS } from '__constants__'
import { firestore } from 'services/firebase'
import { useTranslations } from 'contexts/Translation'

const useGenerateCoctailDescriptionFromIngredientsAndAllergens = () => {
  const { t } = useTranslations()

  const fetchDocumentByIdsArray = async (collectionName, idsArray) => {
    const promises = await Promise.allSettled(
      idsArray?.map((id) => getDoc(doc(firestore, collectionName, id)))
    )

    return promises?.map(({ value }) => value?.data())
  }

  const generateDescription = async ({ ingredients, allergens }) => {
    const coctailCompoundPromises = []

    if (ingredients?.length)
      coctailCompoundPromises.push(
        new Promise((resolve) =>
          resolve(fetchDocumentByIdsArray(COLLECTIONS.INGREDIENTS, ingredients))
        ).then((data) => ({ name: COLLECTIONS.INGREDIENTS, data }))
      )
    if (allergens?.length)
      coctailCompoundPromises.push(
        new Promise((resolve) =>
          resolve(fetchDocumentByIdsArray(COLLECTIONS.ALLERGENS, allergens))
        ).then((data) => ({ name: COLLECTIONS.ALLERGENS, data }))
      )

    const settledPromises = await Promise.allSettled(coctailCompoundPromises)

    const allergensFromIngredients = []

    /* If item has ingredient, fetch allergens from ingredients */
    if (settledPromises?.[0]?.value?.name === COLLECTIONS.INGREDIENTS) {
      const promises = ingredients?.map((item) =>
        getDocument(COLLECTIONS.INGREDIENTS, item)
      )

      /* Getting ingredients data */
      const data = await Promise.all(promises)

      /* Getting allergens promises from ingredients */
      const promisesAllergensFromIngredients = data
        ?.map((item) =>
          item?.allergens?.map((allergenId) =>
            getDocument(COLLECTIONS.ALLERGENS, allergenId)
          )
        )
        ?.flat()
        ?.filter(Boolean)

      /* Getting allergens data from ingredients */
      const allergenDataFromIngredient = await Promise.all(
        promisesAllergensFromIngredients
      )
      allergensFromIngredients?.push(...allergenDataFromIngredient)
    }

    const allergensFromSelection = settledPromises
      ?.map(
        (item) =>
          item?.value?.name === COLLECTIONS.ALLERGENS && item?.value?.data
      )
      ?.flat()

    /* Removing duplicates from the array. */
    const uniqAllergens = [
      ...allergensFromSelection,
      ...allergensFromIngredients
    ]
      ?.filter(Boolean)
      ?.filter(
        (item, index, self) =>
          self?.findIndex((secondItem) => secondItem?._id === item?._id) ===
          index
      )

    const descriptionString = settledPromises?.reduce((acc, curr) => {
      if (curr?.value?.name === COLLECTIONS.INGREDIENTS) {
        return `${t('Ingredients')}: ${curr?.value?.data
          ?.map((ingredient) => ingredient?.name?.toLowerCase())
          ?.join(', ')}`
      }
      if (curr?.value?.name === COLLECTIONS.ALLERGENS) {
        return `${acc?.length ? `${acc}; ` : ''}${t(
          'Allergens'
        )}: ${uniqAllergens
          ?.map((allergen) => allergen?.name?.toLowerCase())
          ?.join(', ')}`
      } else return acc
    }, '')

    if (descriptionString?.length) {
      return `${descriptionString?.[0]?.toUpperCase()}${descriptionString?.slice(
        1
      )}`
    } else {
      return null
    }
  }

  return generateDescription
}

export default useGenerateCoctailDescriptionFromIngredientsAndAllergens
