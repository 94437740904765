import { Col, Ribbon, Row, Text, Title, Box } from '@qonsoll/react-design'
import { Avatar, Tag } from 'antd'
import { Icon } from '@qonsoll/icons'
import PropTypes from 'prop-types'

import { useCustomerContext } from 'contexts/CustomerContext'
import { useTranslations } from 'contexts/Translation'
import { ADMIN_TYPES } from '__constants__'
import { ButtonAccent, CardDropdown, SelectableCard } from 'components'

import styles from './AdminSimpleView.styled'

import { useAdminActions } from 'domains/Admin/hooks'

const AdminSimpleView = (props) => {
  const { admin, onSelect, index, isSelected, actions, isUserOwner, user } =
    props

  const { currentCustomerId } = useCustomerContext()

  /* Getting translations instance */
  const { t } = useTranslations()
  /* Getting base actions */
  const { onResentNotification, handleSelect, adminData } = useAdminActions({
    onSelect,
    admin,
    currentCustomerId,
    isUserOwner,
    user
  })

  return (
    <CardDropdown
      onClick={handleSelect}
      document={admin}
      index={index}
      height="100%"
      {...actions}>
      <Ribbon bg={adminData?.ribbonBg} text={adminData?.adminType} />
      <SelectableCard
        isSelected={isSelected}
        style={styles.cardSelect}
        height="100%">
        {isUserOwner ? (
          <ButtonAccent
            disabled={adminData?.disabledAdminDeletion}
            mt={-5}
            ml={-3}
            type="secondary"
            onClick={adminData?.onDeleteAdmin}
            icon={<Icon name="Trash4Outlined" />}
            danger
          />
        ) : null}

        <Row mt={isUserOwner ? -3 : 0} mb={2} h="center">
          <Col cw={12} mb={2} h="center">
            <Avatar
              size={70}
              display="flex"
              src={admin?.avatarUrl}
              alt="User avatar">
              {adminData?.adminInitials && (
                <Text fontSize={26}>{adminData?.adminInitials}</Text>
              )}
            </Avatar>
          </Col>
          <Col cw={12} mb={2} h="center" v="center">
            <Title level={4} textAlign="center" clamp="1" strong>
              {adminData?.showName}
            </Title>
          </Col>
          <Col cw={12} mb={2} h="center" v="center">
            <Text
              type="secondary"
              variant="body2"
              clamp="1"
              wordBreak="break-word">
              {adminData?.adminEmail}
            </Text>
          </Col>
          <Col
            cw={12}
            h="center"
            v="center"
            mb={adminData?.adminType === ADMIN_TYPES.INVITED ? 2 : 0}>
            <Tag>{adminData?.adminRole}</Tag>
          </Col>
          {adminData?.isVisibleResendButton ? (
            <Col cw={12} h="center" v="center">
              <Box display="flex">
                <ButtonAccent onClick={onResentNotification}>
                  {t('Resent invitation')}
                </ButtonAccent>
              </Box>
            </Col>
          ) : null}
        </Row>
      </SelectableCard>
    </CardDropdown>
  )
}

AdminSimpleView.propTypes = {
  admin: PropTypes.object,
  onSelect: PropTypes.func,
  index: PropTypes.number,
  isSelected: PropTypes.bool,
  actions: PropTypes.object,
  isUserOwner: PropTypes.bool,
  user: PropTypes.object
}

export default AdminSimpleView
