import { Box } from '@qonsoll/react-design'
import styled from 'styled-components'

const Drawer = styled(Box)`
  position: fixed;
  right: ${({ showForm }) => (!showForm ? '-568px' : '0px')};
  top: 0;
  height: 100vh;
  width: 568px;
  background-color: var(--ql-bg-white-default);
  transition: right 1s ease;
  padding: 24px;
  z-index: 1000;
  border-left: 1px solid var(--ql-border-dark-lighten5);
  overflow: auto;
  &::-webkit-scrollbar {
    width: 0.5rem;
    background: transparent;
  }
  &::-webkit-scrollbar-thumb {
    width: 0.5rem;
    background: var(--scroll-thumb-color);
  }
  &::-webkit-scrollbar-track {
    width: 0.5rem;
    background: transparent;
  }
`
const Overlay = styled.div`
  background: var(--ql-bg-dark-lighten4);
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1000;
  visibility: ${({ showForm }) => (!showForm ? 'hidden' : 'visible')};
  opacity: ${({ showForm }) => (!showForm ? '0%' : '100%')};
  transition: all 1s ease;
`

export { Drawer, Overlay }
