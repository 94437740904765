import {
  AddressStep,
  BillingTypeStep,
  ContactPersonStep,
  EmailStep,
  LogoUrlStep,
  NameStep,
  PhoneNumberStep,
  TypeLicenseStep,
  TypeOfPlaceStep,
  VariantItemStep
} from 'domains/Customer/components/CustomerWizard/components'

// we need memo here as this hook has no dependencies, files are static, so...
const useCustomerWizardSteps = () => {
  const companySteps = [
    { component: VariantItemStep, name: 'variantType' },
    { component: NameStep, name: 'name' },
    { component: AddressStep, name: 'country' },
    { component: LogoUrlStep, name: 'logoUrl' },
    { component: PhoneNumberStep, name: 'phoneNumber' },
    { component: EmailStep, name: 'email' },
    { component: ContactPersonStep, name: 'contactPerson' }
  ]

  const venueSteps = [
    { component: VariantItemStep, name: 'variantType' },
    { component: NameStep, name: 'name' },
    { component: AddressStep, name: 'country' },
    { component: LogoUrlStep, name: 'logoUrl' },
    { component: PhoneNumberStep, name: 'phoneNumber' },
    { component: EmailStep, name: 'email' },
    { component: ContactPersonStep, name: 'contactPerson' },
    { component: TypeLicenseStep, name: 'typeOfLicense' },
    { component: BillingTypeStep, name: 'billingType' },
    // here shud be payment method
    { component: TypeOfPlaceStep, name: 'typeOfPlace' }
  ]

  return { companySteps, venueSteps }
}

export default useCustomerWizardSteps
