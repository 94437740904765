import { Col, PageWrapper, Row } from '@qonsoll/react-design'
import { ItemTypeAdvancedForm } from 'domains/ItemType/components'
import { HeaderBreadcrumbs } from 'components'
import { useHistory } from 'react-router-dom'
import { useTranslations } from 'contexts/Translation'

const ItemTypeCreate = (props) => {
  /* Getting history instance */
  const history = useHistory()

  /* Getting translations instance */
  const { t } = useTranslations()

  /* Configuring page header */
  const headingProps = {
    marginBottom: 1,
    title: t('Create item type'),
    textAlign: 'left'
  }

  /* Function to handle back click */
  const handleBack = () => history.goBack()

  return (
    <PageWrapper
      onBack={handleBack}
      breadcrumbs={<HeaderBreadcrumbs />}
      headingProps={headingProps}>
      <Row noGutters>
        <Col cw={12}>
          <ItemTypeAdvancedForm />
        </Col>
      </Row>
    </PageWrapper>
  )
}

export default ItemTypeCreate
