export const OBJECT_RELATION_TYPES = {
  BOOLEAN: 'Boolean',
  NUMBER: 'Number',
  STRING: 'String',
  IMAGE: 'Image',
  HAS_MANY: 'hasMany',
  HAS_ONE: 'hasOne'
}

export const OBJECT_RELATION_TYPES_OPTIONS = Object.keys(
  OBJECT_RELATION_TYPES
).map((key) => ({
  value: OBJECT_RELATION_TYPES[key],
  label: OBJECT_RELATION_TYPES[key]
}))

export const OBJECT_RELATION_TYPES_WITH_REFERENCE = [
  OBJECT_RELATION_TYPES.HAS_MANY,
  OBJECT_RELATION_TYPES.HAS_ONE
]
