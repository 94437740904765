import { Box, Text } from '@qonsoll/react-design'

import { ButtonAccent } from 'components'
import { Link } from 'react-router-dom'
import PATHS from 'pages/paths'
import { Popconfirm } from 'antd'
import { useTermsAndConditionsPopconfirmActions } from 'domains/App/hooks'
import { useTranslations } from 'contexts/Translation'

const AcceptOrDeclineTermsAndConditionsPopconfirm = () => {
  const { t } = useTranslations()
  const { buttonText, titleText, linkText, onAgreementChange } =
    useTermsAndConditionsPopconfirmActions()

  return (
    <Popconfirm
      title={
        <Box display="flex" alignItems="center">
          <Text mr={1}>{titleText}</Text>
          <Link to={PATHS.SERVICE.TERMS_AND_CONDITIONS}>{linkText}</Link>
        </Box>
      }
      okText={t('Yes')}
      onConfirm={onAgreementChange}
      cancelText={t('No')}>
      <ButtonAccent>{buttonText}</ButtonAccent>
    </Popconfirm>
  )
}

export default AcceptOrDeclineTermsAndConditionsPopconfirm
