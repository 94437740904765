import PropTypes from 'prop-types'
import { Text } from '@qonsoll/react-design'
import { useGetEnumString } from 'hooks'

const CocktailMethodColumn = (props) => {
  const { methods } = props

  const methodsName = Array.isArray(methods)
    ? methods?.map((item) => item?.name)
    : methods?.name || ''
  const methodsEnum = useGetEnumString(methodsName)

  return <Text>{methodsEnum || 'n/a'}</Text>
}

CocktailMethodColumn.propTypes = { methods: PropTypes.array }

export default CocktailMethodColumn
