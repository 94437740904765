import PropTypes from 'prop-types'
import { CardDropdown, SelectableCard, TagStyled } from 'components'
import { useItemTypesBaseActions } from 'domains/ItemType/hooks'
import { Col, Row, Text } from '@qonsoll/react-design'

const ItemTypeSimpleView = (props) => {
  const { itemType, index } = props
  const { _id, name } = itemType

  const { onOpen } = useItemTypesBaseActions({ itemTypeId: _id })

  const itemTypeItemsCount = Object.keys(itemType?.menuItems || {}).length

  return (
    <CardDropdown
      height="100%"
      index={index}
      document={itemType}
      onClick={onOpen}
      data-testid="listItem">
      <SelectableCard height="100%">
        <Row noGutters v="center">
          <Col>
            <Text wordBreak="break-word">{name}</Text>
          </Col>
          <Col cw="auto" ml={2}>
            <TagStyled>
              <Text>{itemTypeItemsCount}</Text>
            </TagStyled>
          </Col>
        </Row>
      </SelectableCard>
    </CardDropdown>
  )
}
ItemTypeSimpleView.propTypes = {
  itemType: PropTypes.object,
  index: PropTypes.number
}

export default ItemTypeSimpleView
