export const LIST_FIELD_DATA_TYPES = {
  BOOLEAN: 'Boolean',
  NUMBER: 'Number',
  STRING: 'String',
  IMAGE: 'Image',
  HAS_MANY: 'hasMany',
  HAS_ONE: 'hasOne'
}

export const LIST_FIELD_DATA_TYPES_OPTIONS = Object.keys(
  LIST_FIELD_DATA_TYPES
).map((key) => ({
  value: LIST_FIELD_DATA_TYPES[key],
  label: LIST_FIELD_DATA_TYPES[key]
}))

export const LIST_FIELD_RELATION_DATA_TYPES = [
  LIST_FIELD_DATA_TYPES.HAS_MANY,
  LIST_FIELD_DATA_TYPES.HAS_ONE
]
