const OTHERS_VISIBILITY = {
  YES: 'Yes',
  NO: 'No'
}

const OTHERS_VISIBILITY_KEYS = {
  YES: 'YES',
  NO: 'NO'
}

const OTHERS_VISIBILITY_OPTIONS = Object.keys(OTHERS_VISIBILITY).map((key) => ({
  label: OTHERS_VISIBILITY[key],
  value: key
}))

export { OTHERS_VISIBILITY_OPTIONS, OTHERS_VISIBILITY, OTHERS_VISIBILITY_KEYS }
