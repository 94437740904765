import { Box, Title } from '@qonsoll/react-design'
import {
  useActionsCustomerAdvancedForm,
  useInitializeCustomerAdvancedForm
} from './hooks'

import { ButtonAccent } from 'components'
import { CustomerSimpleForm } from 'domains/Customer/components'
import { Form } from 'antd'
import { KEYS } from '__constants__'
import PropTypes from 'prop-types'
import { useTranslations } from 'contexts/Translation'

const CustomerAdvancedForm = (props) => {
  const { initialData, group, showTitle } = props

  /* Getting translations instance */
  const { t } = useTranslations()
  /* Getting form instance from hook */
  const { form } = useInitializeCustomerAdvancedForm(initialData)

  /* Handling enter key press */
  const handleKeyPress = (e) => {
    if (e.key === KEYS.ENTER) {
      e.preventDefault()
      onFinish()
    }
  }

  /* Getting loading state and actions from hook */
  const { loading, onFinish, onReset } = useActionsCustomerAdvancedForm({
    initialData,
    form
  })

  return group ? (
    <>
      {showTitle && (
        <Title level={4} mb={2}>
          {t('Customer')}
        </Title>
      )}
      <CustomerSimpleForm showTitle={false} group={[...group, 'customer']} />
      {/* <Form.Item name={[group, 'restaurants']}>
        <RestaurantSelectableList />
      </Form.Item> */}
    </>
  ) : (
    <Form
      scrollToFirstError
      onKeyPress={handleKeyPress}
      onFinish={onFinish}
      layout="vertical"
      autoComplete="off"
      form={form}>
      {showTitle && (
        <Title level={4} mb={2}>
          {t('Customer')}
        </Title>
      )}
      <CustomerSimpleForm group={['customer']} />
      {/* <Form.Item name={['restaurants']}>
        <RestaurantSelectableList />
      </Form.Item> */}
      <Box display="flex">
        <ButtonAccent
          type="secondary"
          mr={2}
          htmlType="button"
          onClick={onReset}
          mb={4}>
          {t('Cancel')}
        </ButtonAccent>
        <ButtonAccent
          type="primary"
          loading={loading}
          onClick={() => form.submit()}>
          {t('Save')}
        </ButtonAccent>
      </Box>
    </Form>
  )
}
CustomerAdvancedForm.propTypes = {
  initialData: PropTypes.object,
  group: PropTypes.array,
  showTitle: PropTypes.bool
}

export default CustomerAdvancedForm
