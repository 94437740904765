import { Col, Container, PhoneInput, Row, Title } from '@qonsoll/react-design'

import PropTypes from 'prop-types'
import { VARIANT_TYPES } from '__constants__'
import { useTranslations } from 'contexts/Translation'

const PhoneNumberStep = (props) => {
  const { value, setValue, stepNumber } = props

  /* Getting translations instance */
  const { t } = useTranslations()

  const titleTextComputed = `${t('Step')} ${stepNumber}. ${t(
    `${VARIANT_TYPES[value?.variantType]} phone`
  )}`

  return (
    <Container mb="24px">
      <Title textAlign="center" variant="h4" mb="24px">
        {titleTextComputed}
      </Title>
      <Row noGutters h="center">
        <Col cw={12}>
          {/* this component doesn't work in our version of @qonsoll/react-design library */}
          <PhoneInput
            country="no"
            // value={value}
            searchPlaceholder={t('Search for a country')}
            preferredCountries={['no', 'ua']}
            enableSearch
            onChange={(phone) =>
              setValue((prev) => ({ ...prev, phoneNumber: `+${phone}` }))
            }
          />
        </Col>
      </Row>
    </Container>
  )
}

PhoneNumberStep.propTypes = {
  value: PropTypes.object,
  setValue: PropTypes.func,
  stepNumber: PropTypes.number
}

export default PhoneNumberStep
