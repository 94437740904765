import { COLLECTIONS } from '__constants__'

export const BREADCRUMBS_COLLECTIONS = {
  [COLLECTIONS.COMPANIES]: COLLECTIONS.CUSTOMERS,
  [COLLECTIONS.TRAINING_MATERIALS]: COLLECTIONS.TIP_AND_TRICKS,
  [COLLECTIONS.EDUCATIONAL_CONTENTS]: COLLECTIONS.TRAINING_MATERIALS,
  [COLLECTIONS.COCKTAILS]: COLLECTIONS.COCKTAILS_NEW,
  [COLLECTIONS.QR_CODES]: COLLECTIONS.Q_R_CODES
}
export default BREADCRUMBS_COLLECTIONS
