import { CardDropdown, SelectableCard } from 'components'
import { Col, Divider, Ribbon, Row, Text, Title } from '@qonsoll/react-design'

import { ORDER_STATUSES } from '__constants__'
import PropTypes from 'prop-types'
import styles from './OrderSimpleView.styles'
import { useOrderBaseActions } from 'domains/Order/hooks'
import { useTranslations } from 'contexts/Translation'

const OrderSimpleView = (props) => {
  const { currency = 'NOK', order, index, isSelected, actions } = props

  /* Getting translations instance */
  const { t } = useTranslations()
  /* Getting base actions */
  // eslint-disable-next-line no-unused-vars
  const { onOpen, onEdit, onDelete } = useOrderBaseActions({
    orderId: order?._id,
    actions
  })

  const ribbonBgComputed =
    order?.orderStatus === ORDER_STATUSES.DONE
      ? 'var(--ribbon-active-bg)'
      : 'var(--ribbon-disabled-bg)'

  // const deleteText = t('Order') + ` #${order?.orderNumber}`
  return (
    <CardDropdown
      height="100%"
      // onDelete={onDelete}
      // onEdit={onEdit}
      onClick={onOpen}
      document={order}
      index={index}
      data-testid="orderListItem"
      // deleteText={deleteText}
      // {...actions}
    >
      <Ribbon bg={ribbonBgComputed} text={order?.orderStatus} />
      <SelectableCard
        isSelected={isSelected}
        height="100%"
        style={{ height: '100%' }}>
        <Row noGutters {...styles.fieldRowProps} my={2}>
          <Col flexDirection="row">
            <Title level={4} mr={2}>
              {t('Order')}
            </Title>
            <Title level={4}>#{order?.orderNumber}</Title>
          </Col>
        </Row>
        <Row noGutters {...styles.fieldRowProps}>
          <Col>
            <Text type="secondary">{t('Table')}:</Text>
          </Col>
          <Col cw="auto">
            <Text
              {...styles.fieldTitleProps}
              fontWeight="var(--ql-font-weight-semibold)">
              #{order?.tableNumber}
            </Text>
          </Col>
        </Row>
        <Row noGutters {...styles.fieldRowProps}>
          <Col>
            <Text type="secondary">{t('Total')}:</Text>
          </Col>
          <Col cw="auto">
            <Text
              {...styles.fieldTitleProps}
              fontWeight="var(--ql-font-weight-semibold)">
              {`${order?.totalPrice} ${currency}`}
            </Text>
          </Col>
        </Row>
        <Divider py={2} />
        <Row noGutters {...styles.fieldRowProps}>
          <Col cw={12}>
            <Text variant="body2">{order?.comments ?? 'n/a'}</Text>
          </Col>
        </Row>
      </SelectableCard>
    </CardDropdown>
  )
}

OrderSimpleView.propTypes = {
  order: PropTypes.object,
  onSelect: PropTypes.func,
  index: PropTypes.number,
  isSelected: PropTypes.bool,
  actions: PropTypes.object,
  currency: PropTypes.string
}

export default OrderSimpleView
