import { ButtonAccent, IconSelect } from 'components'
import { Form, Input } from 'antd'
import { KEYS, MAX_LENGTH_FIELD } from '__constants__'

import PropTypes from 'prop-types'
import { Title } from '@qonsoll/react-design'
import { useTranslations } from 'contexts/Translation'

const MainSpiritTypeSimpleForm = (props) => {
  const { initialValues, onSubmit, onCancel, showTitle, form, group } = props

  /* Getting translations instance */
  const { t } = useTranslations()
  /* Getting new form instance from Form.useForm hook */
  const [baseForm] = Form.useForm()

  /* Getting used form from props or using base form */
  const usedForm = form || baseForm

  /* Resetting form on cancel */
  const handleCancel = () => {
    usedForm.resetFields()
    onCancel?.()
  }
  /* Submitting form on enter key press */
  const handleKeyPress = (e) => {
    if (e.key === KEYS.ENTER) {
      e.preventDefault()
      e.stopPropagation()
      usedForm?.submit()
    }
  }

  return group ? (
    <>
      {showTitle && (
        <Title level={4} mb={2}>
          {t('Main spirit type')}
        </Title>
      )}
      <Form.Item
        label={t('Name')}
        name={[...group, 'name']}
        rules={[
          {
            required: true,
            message: t('Please enter your main spirit type name!')
          },
          {
            max: MAX_LENGTH_FIELD,
            message: t('main spirit type name is too long!')
          }
        ]}>
        <Input
          placeholder={t('Please enter your main spirit type name')}
          onPressEnter={onSubmit}
        />
      </Form.Item>
      <Form.Item
        label={t('Value')}
        name={[...group, 'value']}
        rules={[
          {
            required: false,
            message: t('Please enter your main spirit type value!')
          },
          {
            max: MAX_LENGTH_FIELD,
            message: t('main spirit type value is too long!')
          }
        ]}>
        <Input placeholder={t('Please enter your main spirit type value')} />
      </Form.Item>
      <Form.Item
        label={t('Icon')}
        name={[...group, 'icon']}
        rules={[
          {
            required: false,
            message: t('Please enter your main spirit type icon!')
          }
        ]}>
        <IconSelect
          placeholder={t('Please enter your main spirit type icon')}
        />
      </Form.Item>
    </>
  ) : (
    <Form
      scrollToFirstError
      form={usedForm}
      initialValues={initialValues}
      onFinish={onSubmit}
      layout="vertical"
      onKeyPress={handleKeyPress}
      autoComplete="off">
      {showTitle && (
        <Title level={4} mb={2}>
          {t('Main spirit type')}
        </Title>
      )}
      <Form.Item
        label={t('Name')}
        name="name"
        rules={[
          {
            required: true,
            message: t('Please enter your main spirit type name!')
          },
          {
            max: MAX_LENGTH_FIELD,
            message: t('main spirit type name is too long!')
          }
        ]}>
        <Input placeholder={t('Please enter your main spirit type name')} />
      </Form.Item>
      <Form.Item
        label={t('Value')}
        name="value"
        rules={[
          {
            required: false,
            message: t('Please enter your main spirit type value!')
          },
          {
            max: MAX_LENGTH_FIELD,
            message: t('main spirit type value is too long!')
          }
        ]}>
        <Input placeholder={t('Please enter your main spirit type value')} />
      </Form.Item>
      <Form.Item
        label={t('Icon')}
        name="icon"
        rules={[
          {
            required: false,
            message: t('Please enter your main spirit type icon!')
          }
        ]}>
        <IconSelect
          placeholder={t('Please enter your main spirit type icon')}
        />
      </Form.Item>
      {!form && (
        <>
          <ButtonAccent
            mr={2}
            htmlType="button"
            onClick={handleCancel}
            type="secondary">
            {t('Cancel')}
          </ButtonAccent>
          <ButtonAccent type="primary" onClick={() => usedForm.submit()}>
            {t('Save')}
          </ButtonAccent>
        </>
      )}
    </Form>
  )
}

MainSpiritTypeSimpleForm.propTypes = {
  initialValues: PropTypes.object,
  onSubmit: PropTypes.func,
  onCancel: PropTypes.func,
  showTitle: PropTypes.bool,
  form: PropTypes.any,
  group: PropTypes.array
}

export default MainSpiritTypeSimpleForm
