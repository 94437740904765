import _ from 'lodash'

const toLowerCamelCase = (str) => {
  let words = _.split(_.replace(str, /[^\w\s-]/gi, ''), ' ')
  let firstWord = _.toLower(_.trim(_.shift(words) || ''))

  let camelCasedWords = _.map(words, (word) => {
    return _.capitalize(_.toLower(_.trim(word)))
  }).filter(Boolean)

  if (_.isEmpty(firstWord)) {
    firstWord = _.toLower(_.shift(camelCasedWords))
  }

  return _.concat([firstWord], camelCasedWords).join('')
}

export default toLowerCamelCase
