import { useEffect, useState } from 'react'
import {
  useFormDataTypes,
  useFormItemLabel,
  useFormItemName,
  useFormItemValuePropName
} from 'hooks'

import PropTypes from 'prop-types'
import { Spinner } from 'components'
import { Title } from '@qonsoll/react-design'
import { useTranslations } from 'contexts/Translation'
import { StyledFormItem } from './ListItemFieldsForm.styles'

const ListItemFieldsForm = (props) => {
  const { value, onChange, selectedLanguage, tabsProps } = props
  const [labels, setLabels] = useState([])
  const { t, language } = useTranslations()

  const inputAddon = selectedLanguage?.toUpperCase()
  const dataTypes = useFormDataTypes({ inputAddon, selectedLanguage })
  const formItemLabel = useFormItemLabel({ labels, selectedLanguage })
  const formItemName = useFormItemName({ selectedLanguage })
  const valuePropName = useFormItemValuePropName()

  const arrangeItemProps = (parentFormItemName) => ({
    parentFormItemName: parentFormItemName,
    listRef: null,
    parentFormOnChange: onChange,
    parentFormValue: value
  })

  useEffect(() => {
    if (value && language) {
      setLabels(
        value?.map(({ _id, label, fieldName }) => ({
          _id,
          label,
          fieldName
        }))
      )
    }
  }, [value, selectedLanguage, language])

  return value ? (
    value?.map((item, index) => {
      const parentFormItemName = formItemName('listItemFields', item, index)
      const itemProps = arrangeItemProps(parentFormItemName)
      const labelStyled = item?.object ? (
        <Title level={2}>{formItemLabel(item)}</Title>
      ) : (
        formItemLabel(item)
      )
      return (
        <StyledFormItem
          valuePropName={valuePropName(item)}
          key={`list-item-field-${item?._id}`}
          label={labelStyled}
          name={parentFormItemName}
          rules={[
            {
              required: item?.isRequired,
              message: `${t('Please enter')} ${
                item?.label[selectedLanguage] ?? item?.fieldName
              }`
            }
          ]}>
          {dataTypes?.[item?.dataType]?.(item, itemProps, tabsProps) || <></>}
        </StyledFormItem>
      )
    })
  ) : (
    <Spinner />
  )
}
ListItemFieldsForm.propTypes = {
  value: PropTypes.array,
  onChange: PropTypes.func,
  selectedLanguage: PropTypes.string,
  tabsProps: PropTypes.object
}

export default ListItemFieldsForm
