import { COLLECTIONS } from '__constants__'
import { message } from 'antd'
import { updateDocument } from 'services/api/firebase'
import { useState, useMemo, useEffect } from 'react'
import { useTranslations } from 'contexts/Translation'
import { areArraysWithDocumentsEqual } from 'helpers'

const useUpdateMenuCategoryConfig = (initialMenuConfig, menuId) => {
  const [menuConfig, setMenuConfig] = useState(initialMenuConfig)
  const [isMenuConfigWasUpdated, setIsMenuConfigWasUpdated] = useState(false)

  const { t } = useTranslations()

  const onChangeMenuCategoryConfig = (newConfig) => {
    // Set new menu config only if config was changed
    if (!areArraysWithDocumentsEqual(newConfig, initialMenuConfig)) {
      setMenuConfig(newConfig)
    } else setIsMenuConfigWasUpdated(false)
  }

  const updateMenuCategoryConfig = async (newConfig) => {
    try {
      await updateDocument(COLLECTIONS.MENUS, menuId, {
        menuConfig: newConfig
      })
      setIsMenuConfigWasUpdated(false)

      message.success(t('Menu was successfully updated'))
    } catch (error) {
      message.error(t('Error during updating menu'))
    }
  }

  useEffect(() => {
    // Set initial menu config if it was changed
    if (!areArraysWithDocumentsEqual(initialMenuConfig, menuConfig)) {
      setMenuConfig(initialMenuConfig)
    }
  }, [initialMenuConfig, menuConfig])

  useMemo(() => {
    // Check if changed element is exist
    const arraysAreEqual = !menuConfig?.find(
      (value, index) => value?._id !== initialMenuConfig?.[index]?._id
    )

    // Set isMenuConfigWasUpdated like true if element if the arrays do not match,
    // set isMenuConfigWasUpdated like false if arrays are equal,
    // this handles the case if the array was changed several times, but remained as it was initially

    setIsMenuConfigWasUpdated(!arraysAreEqual)
  }, [menuConfig, initialMenuConfig])

  return {
    menuConfig,
    onChangeMenuCategoryConfig,
    updateMenuCategoryConfig,
    isMenuConfigWasUpdated
  }
}

export default useUpdateMenuCategoryConfig
