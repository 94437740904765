import { Box, Col, PageWrapper, Row } from '@qonsoll/react-design'
import {
  ButtonAccent,
  HeaderBreadcrumbs,
  PageActions,
  Spinner
} from 'components'
import { arrayRemove, collection, query, where } from 'firebase/firestore'
import {
  deleteDocument,
  updateDocument,
  useListenDocument
} from 'services/api/firebase'
import { useHistory, useParams } from 'react-router-dom'

import { COLLECTIONS } from '__constants__'
import { Icon } from '@qonsoll/icons'
import PATHS from 'pages/paths'
import { RestaurantAdvancedView } from 'domains/Restaurant/components'
import { firestore } from 'services/firebase'
import { generatePath } from 'react-router-dom'
import { message } from 'antd'
import { useCollectionData } from 'react-firebase-hooks/firestore'
import { useState } from 'react'
import { useTranslations } from 'contexts/Translation'

const RestaurantShowPage = (props) => {
  /* Getting translations instance */
  const { t } = useTranslations()
  /* Getting history instance */
  const history = useHistory()
  /* Getting params from router */
  const { restaurantId, companyId } = useParams()
  /* Fetching document data from useDocument hook */
  const [restaurant, loading] = useListenDocument({
    ref: COLLECTIONS.RESTAURANTS + '/' + restaurantId
  })
  const [customers, customersLoading] = useCollectionData(
    restaurantId &&
      query(
        collection(firestore, COLLECTIONS.CUSTOMERS),
        where('restaurants', 'array-contains', restaurantId)
      )
  )
  const [selectedItems, setSelectedItems] = useState([])

  /* Function to handle back click */
  const handleBack = () => history.goBack()

  /* Configuring page header */
  const headingProps = {
    marginBottom: 3
  }

  const handleEdit = () => {
    const pathParams = { restaurantId: restaurant?._id, companyId }
    const path = generatePath(PATHS.AUTHENTICATED.RESTAURANT_EDIT, pathParams)
    history.push(path)
  }

  /* It deletes the restaurant from the database and redirects the user to the dashboard */
  const handleDelete = async () => {
    try {
      if (!customersLoading && Array?.isArray(customers)) {
        customers.forEach((customer) =>
          updateDocument(COLLECTIONS.CUSTOMERS, customer?._id, {
            restaurants: arrayRemove(restaurantId)
          })
        )
      }
      await deleteDocument(COLLECTIONS.RESTAURANTS, restaurantId)
      await deleteDocument(COLLECTIONS.ADDRESSES, restaurant?.address)
      history.goBack()
      message.success(t('Restaurant successfully deleted'))
    } catch (error) {
      message.error(error.message)
    }
  }
  const onOpenStaff = () => {
    const el = document.createElement('input')
    el.value = `${process.env.REACT_APP_FIREBASE_STAFF_DOMAIN}?restaurantId=${restaurant?._id}`
    document.body.appendChild(el)
    el.select()
    document.execCommand('copy')
    document.body.removeChild(el)

    message.success(t('Link successfully copied to clipboard'))
  }
  const actions = { edit: true, delete: false }
  return (
    <PageWrapper
      action={
        <Box display="flex">
          <PageActions onEdit={handleEdit} actions={actions} />
          <ButtonAccent
            mx={2}
            type="secondary"
            onClick={onOpenStaff}
            icon={<Icon name="Copy1Outlined" mr={2} />}>
            {t('Link to staff')}
          </ButtonAccent>
          <ButtonAccent
            type="secondary"
            onClick={handleDelete}
            icon={<Icon name="Trash4Outlined" />}
            danger
          />
        </Box>
      }
      headingProps={headingProps}
      onBack={handleBack}
      breadcrumbs={<HeaderBreadcrumbs />}>
      <Row noGutters>
        {loading ? (
          <Col>
            <Spinner height="70vh" text={t('Restaurant loading')} />
          </Col>
        ) : (
          <Col cw={12}>
            <RestaurantAdvancedView
              selectedItems={selectedItems}
              setSelectedItems={setSelectedItems}
              restaurant={restaurant}
            />
          </Col>
        )}
      </Row>
    </PageWrapper>
  )
}

export default RestaurantShowPage
