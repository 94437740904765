import { COLLECTIONS } from '__constants__'
import { collection, doc, query, where } from 'firebase/firestore'
import { useLoading } from 'hooks'
import {
  useCollectionData,
  useDocumentData
} from 'react-firebase-hooks/firestore'
import { firestore } from 'services/firebase'

const useGetMenuItemViewData = (menuItemId) => {
  const [menuItem, menuItemLoading] = useDocumentData(
    menuItemId && doc(firestore, COLLECTIONS.MENU_ITEMS + '/' + menuItemId)
  )

  const [menuWithSelectedMenuItem, menusLoading] = useCollectionData(
    menuItemId &&
      query(
        collection(firestore, COLLECTIONS.MENU_CATEGORIES),
        where('itemsOrder', 'array-contains', menuItemId)
      )
  )

  const loading = useLoading([menuItemLoading, menusLoading])

  return [menuItem, menuWithSelectedMenuItem, loading]
}

export default useGetMenuItemViewData
