import { useEffect, useState, useCallback } from 'react'
import { getDocument } from 'services/api/firebase'
import { COLLECTIONS } from '__constants__'

/**
 * It fetches a cocktails documents from Firestore and returns it
 * @param menuCategory - The menuCategory object that we're getting the cocktails for.
 * @returns An array with two values. The first value is the cocktails object, and the second value is a
 * boolean that indicates whether the cocktails is loading.
 */
const useGetMenuCategoryCocktails = (menuCategory) => {
  /* State for data */
  const [cocktails, setCocktails] = useState([])
  /* State for loading */
  const [loading, setLoading] = useState(!!menuCategory?.items?.length)
  /* State for error */
  const [error, setError] = useState(null)

  /* Fetching data */
  const getCocktails = useCallback(async () => {
    try {
      /* Setting loading state to true */
      setLoading(true)

      /* Getting cocktails promises*/
      const promises = menuCategory.items.map((cocktail) =>
        getDocument(COLLECTIONS.COCKTAILS_NEW, cocktail)
      )

      /* Getting cocktails data */
      const data = await Promise.all(promises)

      /* Setting cocktails data */
      setCocktails(data)

      /* Setting loading state to false */
    } catch (error) {
      setError(error)
    }
    setLoading(false)
  }, [menuCategory])

  useEffect(() => {
    menuCategory?.items?.length && getCocktails()
  }, [menuCategory?.items, getCocktails])

  return [cocktails, loading, error, getCocktails]
}

export default useGetMenuCategoryCocktails
