import { useEffect, useState, useCallback } from 'react'
import { getDocument } from 'services/api/firebase'
import { COLLECTIONS } from '__constants__'

/**
 * It fetches a mediaObjects documents from Firestore and returns it
 * @param cocktail - The cocktail object that we're getting the mediaObjects for.
 * @returns An array with two values. The first value is the mediaObjects object, and the second value is a
 * boolean that indicates whether the mediaObjects is loading.
 */
const useGetCocktailMediaObjects = (cocktail) => {
  /* State for data */
  const [mediaObjects, setMediaObjects] = useState([])
  /* State for loading */
  const [loading, setLoading] = useState(!!cocktail?.mediaObjects?.length)
  /* State for error */
  const [error, setError] = useState(null)

  /* Fetching data */
  const getMediaObjects = useCallback(async () => {
    try {
      /* Setting loading state to true */
      setLoading(true)

      /* Getting mediaObjects promises*/
      const promises = cocktail.mediaObjects.map((mediaObject) =>
        getDocument(COLLECTIONS.MEDIA_OBJECTS, mediaObject)
      )

      /* Getting mediaObjects data */
      const data = await Promise.all(promises)

      /* Setting mediaObjects data */
      setMediaObjects(data)

      /* Setting loading state to false */
    } catch (error) {
      setError(error)
    }
    setLoading(false)
  }, [cocktail?.mediaObjects])

  useEffect(() => {
    cocktail?.mediaObjects?.length && getMediaObjects()
  }, [cocktail?.mediaObjects, getMediaObjects])

  return [mediaObjects, loading, error, getMediaObjects]
}

export default useGetCocktailMediaObjects
