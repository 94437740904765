import { CardDropdown, SelectableCard } from 'components'
import { Col, Ribbon, Row, Title } from '@qonsoll/react-design'
import {
  useGetZoneTitleAndRibbonColor,
  useZoneBaseActions
} from 'domains/Zone/hooks'

import PropTypes from 'prop-types'
import styles from './ZoneSimpleView.styles'

const ZoneSimpleView = (props) => {
  const { zone, index, isSelected, actions } = props

  /* Getting base actions */
  const { onOpen } = useZoneBaseActions({
    zoneId: zone?._id,
    actions
  })

  const { ribbonColor, zoneTitle } = useGetZoneTitleAndRibbonColor(
    zone?.available
  )

  return (
    <CardDropdown
      onClick={onOpen}
      document={zone}
      index={index}
      data-testid="zoneListItem"
      deleteText={zone?.title}>
      <Ribbon bg={ribbonColor} text={zoneTitle} />
      <SelectableCard isSelected={isSelected}>
        <Row noGutters {...styles.fieldRowProps} mt={2}>
          <Col cw={12}>
            <Title level={4} {...styles.fieldTitleProps}>
              {zone?.title}
            </Title>
          </Col>
        </Row>
      </SelectableCard>
    </CardDropdown>
  )
}

ZoneSimpleView.propTypes = {
  zone: PropTypes.object,
  onSelect: PropTypes.func,
  index: PropTypes.number,
  isSelected: PropTypes.bool,
  actions: PropTypes.object
}

export default ZoneSimpleView
