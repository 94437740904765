const DEFAULT_TYPE = 'relationship'
const DEFAULT_DATATYPE = 'hasManyWithCreate'

const hasManyWithCreate = (ref, options) => {
  try {
    if (!ref) throw new Error('No ref provided to the hasManyWithCreate method')
  } catch (e) {
    // eslint-disable-next-line no-console
    console.error(e)
  }
  return {
    type: DEFAULT_TYPE,
    dataType: DEFAULT_DATATYPE,
    ref,
    ...options
  }
}

export default hasManyWithCreate
