import { Box, Divider, Menu } from '@qonsoll/react-design'
import { Icon } from '@qonsoll/icons'
import { Fragment, useMemo, useCallback } from 'react'
import { generatePath, useHistory } from 'react-router-dom'
import PropTypes from 'prop-types'

import PATHS from 'pages/paths'
import { MenuItem } from 'components'
import { useTranslations } from 'contexts/Translation'

import { useUser } from 'domains/User/context'
import { useGetSelectedMenuItem } from 'components/BoilerplateLayout/components/MainMenu/hooks'

const MainMenu = (props) => {
  const { collapsed, handleCloseLeftAside, lg } = props

  const selectedMenuItem = useGetSelectedMenuItem()

  const { t } = useTranslations()
  const history = useHistory()
  const { user } = useUser()

  const handleGoToMenuItem = useCallback(
    (path) => {
      history.push(path)
      // Close aside if one is opened
      !lg && handleCloseLeftAside?.()
    },
    [handleCloseLeftAside, history, lg]
  )

  const menuItems = useMemo(
    () => [
      {
        key: PATHS.AUTHENTICATED.PROFILE_SHOW,
        icon: <Icon name="Dashboard2Outlined" />,
        text: t('My Profile'),
        onClick: () =>
          user?._id &&
          handleGoToMenuItem(
            generatePath(PATHS.AUTHENTICATED.PROFILE_SHOW, {
              profileId: user?._id
            })
          )
      },
      {
        key: PATHS.AUTHENTICATED.TIP_AND_TRICKS_ALL,
        icon: <Icon name="FileQuestion1Outlined" />,
        text: t('Tips & tricks'),
        onClick: () =>
          handleGoToMenuItem(PATHS.AUTHENTICATED.TIP_AND_TRICKS_ALL)
      },
      {
        key: PATHS.AUTHENTICATED.MENUS_ALL,
        icon: <Icon name="RestaurantMenuOutlined" />,
        text: t('Menus'),
        onClick: () => handleGoToMenuItem(PATHS.AUTHENTICATED.MENUS_ALL)
      },
      {
        key: PATHS.AUTHENTICATED.ITEM_TYPES_ALL,
        icon: <Icon name="TypeSquareOutlined" />,
        text: t('Item Types'),
        onClick: () => handleGoToMenuItem(PATHS.AUTHENTICATED.ITEM_TYPES_ALL)
      },
      {
        key: PATHS.AUTHENTICATED.INTERACTIVE_MENUS_ALL,
        icon: <Icon name="BookOpen1Outlined" />,
        text: t('Interactive menus'),
        onClick: () =>
          handleGoToMenuItem(PATHS.AUTHENTICATED.INTERACTIVE_MENUS_ALL)
      }
    ],
    [t, user, handleGoToMenuItem]
  )

  return (
    <Menu
      mode="inline"
      defaultSelectedKeys={[selectedMenuItem]}
      selectedKeys={selectedMenuItem}>
      {menuItems?.map((item) => (
        <Fragment key={item.key}>
          <MenuItem
            key={item?.key}
            onClick={item?.onClick}
            icon={item?.icon}
            tabIndex="0">
            {!collapsed && item?.text}
          </MenuItem>
          {item?.divided && (
            <Box my={3}>
              <Divider />
            </Box>
          )}
        </Fragment>
      ))}
    </Menu>
  )
}

MainMenu.propTypes = {
  collapsed: PropTypes.bool,
  handleCloseLeftAside: PropTypes.func,
  lg: PropTypes.bool
}

export default MainMenu
