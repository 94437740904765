import { Col, PageWrapper, Row } from '@qonsoll/react-design'
import { HeaderBreadcrumbs, Spinner } from 'components'

import { ItemTypeInstanceAdvancedForm } from 'domains/ItemTypeInstance/components'
import { useGetItemTypeInstanceInitialValues } from 'domains/ItemTypeInstance/hooks'
import { useHistory } from 'react-router-dom'
import { useTranslations } from 'contexts/Translation'

const ItemTypeInstanceCreate = () => {
  /* Getting translations instance */
  const { t } = useTranslations()

  const [initialData, loading] = useGetItemTypeInstanceInitialValues()

  const history = useHistory()

  /* Configuring page header */
  const headingProps = {
    marginBottom: 1,
    title: t('Create new menu item'),
    textAlign: 'left'
  }

  const onBack = () => {
    history.goBack()
  }
  return (
    <PageWrapper
      onBack={onBack}
      breadcrumbs={<HeaderBreadcrumbs />}
      headingProps={headingProps}>
      <Row noGutters>
        <Col>
          {loading ? (
            <Spinner />
          ) : (
            <ItemTypeInstanceAdvancedForm initialData={initialData} />
          )}
        </Col>
      </Row>
    </PageWrapper>
  )
}

export default ItemTypeInstanceCreate
