import { COLLECTIONS, LIST_FIELD_DATA_TYPES } from '__constants__'
import { collection, doc, serverTimestamp } from 'firebase/firestore'

import { firestore } from 'services/firebase'
import { message } from 'antd'
import { uploadImage } from 'helpers'
import { useState } from 'react'
import { useTranslations } from '@qonsoll/translation'

const useObjectInstanceAdvancedFormActions = ({
  initialData,
  form,
  formItemPrefix,
  hideForm,
  setFormWasNotUpdated,
  objectProps,
  setIsDisabled
}) => {
  const [loading, setLoading] = useState(false)
  const { t } = useTranslations()
  const {
    objectInstanceData,
    objectId,
    objectFieldName,
    updateItemTypeInstanceWithObjectValue,
    chosenObject,
    resetChosenObject
  } = objectProps

  const prepareObjectInstances = (
    objectInstance,
    objectInstanceId,
    serviceFieldName,
    additionalValues
  ) => {
    const updatedObjectInstancesArray = chosenObject
      ? objectInstanceData?.map((item) =>
          item?._id === chosenObject?._id
            ? {
                _id: objectInstanceId,
                [serviceFieldName]: serverTimestamp(),
                objectId: initialData?.list?._id || objectId,
                ...objectInstance,
                ...additionalValues
              }
            : item
        )
      : [
          ...(objectInstanceData || []),
          {
            _id: objectInstanceId,
            [serviceFieldName]: serverTimestamp(),
            objectId: initialData?.list?._id || objectId,
            ...objectInstance,
            ...additionalValues
          }
        ]

    return updatedObjectInstancesArray
  }
  const prepareValues = async (values = {}, additionalValues = {}) => {
    const objectInstanceId =
      chosenObject?._id ||
      doc(collection(firestore, COLLECTIONS.OBJECT_INSTANCES)).id

    const objectInstance = {}
    for (const item of initialData || []) {
      if (item?.relationType === LIST_FIELD_DATA_TYPES.IMAGE) {
        const uploadedImageUrl = await uploadImage(
          values?.[formItemPrefix]?.find((obj) =>
            Object.prototype.hasOwnProperty.call(obj || {}, [item?.name])
          )?.[item?.name]
        )
        objectInstance[item?.name] = uploadedImageUrl ?? null
      } else {
        const fieldValue = values?.[formItemPrefix]?.find((obj) => {
          return Object.prototype.hasOwnProperty.call(obj || {}, [item?.name])
        })?.[item?.name]

        const transformedFieldValue = item?.isLocalizationEnabled
          ? {
              en: fieldValue?.en ?? null,
              no: fieldValue?.no ?? null
            }
          : fieldValue ?? null

        objectInstance[item?.name] = transformedFieldValue
      }
    }

    const serviceFieldName = initialData?._id ? '_updatedAt' : '_createdAt'
    const preparedObjectInstances = prepareObjectInstances(
      objectInstance,
      objectInstanceId,
      serviceFieldName,
      additionalValues
    )

    return preparedObjectInstances
  }

  const onFinish = async (values) => {
    try {
      setLoading(true)
      const preparedValues = await prepareValues(values)

      // This function gets prepared objectInstance data and sets it in parent Form with corresponding field name
      // so later this data can be saved after parent Form creation submit
      updateItemTypeInstanceWithObjectValue?.(objectFieldName, preparedValues)
    } catch (error) {
      message.error(
        `${t('Something went wrong during saving object instance')}, ${
          error?.message
        }`
      )
    } finally {
      setLoading(false)
      resetChosenObject?.()
      form.resetFields()
    }
  }

  const onReset = () => {
    form.resetFields()
  }
  const onSubmit = () => {
    form.submit()
    hideForm?.()
    setIsDisabled(true)
    setFormWasNotUpdated?.()
  }

  return { onFinish, onReset, onSubmit, loading }
}

export default useObjectInstanceAdvancedFormActions
