import { useCallback, useEffect } from 'react'

import { Form } from 'antd'
import { LIST_FIELD_DATA_TYPES } from '__constants__'

const useInitializeObjectInstanceAdvancedForm = ({
  initialData,
  fieldName,
  objectInstanceData,
  chosenObject
}) => {
  const [form] = Form.useForm()
  const prepareListItemsValue = useCallback(
    (fields) => {
      return (
        fields?.map(({ name, label, dataType, isRequired, _id, ...item }) => {
          const emptyValue =
            item?.dataType === LIST_FIELD_DATA_TYPES.HAS_MANY ||
            item?.relationType === LIST_FIELD_DATA_TYPES.HAS_MANY
              ? []
              : null

          return {
            fieldName: name,
            [name]: chosenObject?.[name] || emptyValue,
            label,
            dataType: item?.dataType || item?.relationType,
            isRequired,
            _id: _id,
            ...item
          }
        }) ?? []
      )
    },

    [chosenObject]
  )
  useEffect(() => {
    const initialValues = {
      [fieldName]: prepareListItemsValue(initialData)
    }

    form.setFieldsValue(initialValues)
  }, [fieldName, form, initialData, prepareListItemsValue])

  return { form }
}

export default useInitializeObjectInstanceAdvancedForm
