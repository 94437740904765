import { memo, useEffect, useState } from 'react'

import { AddItemCard } from 'components'
import { Img } from '@qonsoll/react-design'
import PropTypes from 'prop-types'
import { Upload } from 'antd'
import styles from './ImageUploader.styles'
import { uploadImage } from 'helpers'
import { useTranslations } from 'contexts/Translation'

/**
 * It's a React component that renders an image uploader
 * @param value {string} - The image url
 * @param onChange {function} - The function to call when the image is changed
 * @param withDirectUpload {boolean} - If true, returns a url to the image
 */
const ImageUploader = (props) => {
  const { value, onChange, withDirectUpload = false } = props

  // [COMPONENT_STATE_HOOKS]
  const [file, setFile] = useState(null)

  // [ADDITIONAL_HOOKS]
  const { t } = useTranslations()

  // [HANDLER_FUNCTIONS]
  const getBase64 = (img, callback) => {
    const reader = new FileReader()
    reader.addEventListener('load', () => callback(reader.result))
    reader.readAsDataURL(img)
  }
  const handleUpload = async ({ onSuccess, file }) => {
    if (withDirectUpload) {
      const [url] = await uploadImage(file)
      onChange(url)
    } else {
      onChange?.(file)
      getBase64(file, (result) => setFile(result))
    }
    onSuccess()
  }

  // [LIFECYCLE]
  useEffect(() => value && setFile(value), [value])

  return (
    <Upload fileList={null} customRequest={handleUpload}>
      {file ? (
        <Img alt="Uploaded image" style={styles.image} src={file || value} />
      ) : (
        <AddItemCard width="128px" height="128px" message={t('Upload image')} />
      )}
    </Upload>
  )
}
ImageUploader.propTypes = {
  value: PropTypes.string,
  onChange: PropTypes.func,
  withDirectUpload: PropTypes.bool
}

export default memo(ImageUploader)
