import { Form } from 'antd'
import { useEffect } from 'react'

const useInitializeRestaurantAdvancedForm = (initialData, companyData) => {
  /* Getting form instance */
  const [form] = Form.useForm()
  /* Setting initial data */
  useEffect(() => {
    if (initialData) {
      /* Getting initial values */
      const { restaurant, address, mediaObjects, menus, orders, qrCodes } =
        initialData

      /* Formatting date fields */
      const formValues = {
        restaurant: {
          name: restaurant?.['name'] ?? null,
          welcomeMessage: restaurant?.['welcomeMessage'] ?? null,
          description: restaurant?.['description'] ?? null,
          logoUrl: restaurant?.['logoUrl'] ?? null,
          billingType: restaurant?.['billingType'] ?? null,
          typeOfLicense: restaurant?.['typeOfLicense'] ?? null,
          typeOfPlace: restaurant?.['typeOfPlace'] ?? null,
          currency: restaurant?.['currency'] ?? null
        },
        address:
          address?.locationId && address?.description
            ? {
                locationId: address?.locationId,
                description: address?.description,
                locationInfo: {
                  locationId: address?.locationId,
                  description: address?.description
                }
              }
            : null,
        mediaObjects: mediaObjects ?? null,
        menus: menus?.map((e) => e?._id).filter((e) => e) ?? [],
        orders: orders?.map((e) => e?._id).filter((e) => e) ?? [],
        qrCodes: qrCodes ?? null
      }

      /* Setting initial values to form instance */
      form.setFieldsValue(formValues)
    } else {
      // const formValue = {
      //   menus: companyData?.automaticMenuId
      // }
      // /* Setting initial values to form instance */
      // form.setFieldsValue(formValue)
    }
  }, [initialData, form, companyData?.automaticMenuId])

  return { form }
}

export default useInitializeRestaurantAdvancedForm
