import { Col, Container, Row, Title } from '@qonsoll/react-design'

import { MenuCategoryCocktails } from 'domains/MenuCategory/components'
import PropTypes from 'prop-types'

const MenuCategoryAdvancedView = (props) => {
  const { menuCategory } = props

  return (
    <Container>
      <Row noGutters mb={2} v="center">
        <Col>
          <Title level={5}>{menuCategory?.name}</Title>
        </Col>
      </Row>
      <MenuCategoryCocktails menuCategory={menuCategory} />
    </Container>
  )
}
MenuCategoryAdvancedView.propTypes = {
  menuCategory: PropTypes.object,
  menuId: PropTypes.string,
  refresh: PropTypes.func
}

export default MenuCategoryAdvancedView
