import { Image, Input, InputNumber, Select, Switch } from 'antd'
import { LIST_FIELD_DATA_TYPES } from './listFieldDataTypes'

export const LIST_ITEM_EDITABLE_COMPONENTS = {
  [LIST_FIELD_DATA_TYPES.STRING]: <Input />,
  [LIST_FIELD_DATA_TYPES.NUMBER]: <InputNumber decimalSeparator="," />,
  [LIST_FIELD_DATA_TYPES.BOOLEAN]: <Switch />,
  [LIST_FIELD_DATA_TYPES.HAS_ONE]: <Select />,
  [LIST_FIELD_DATA_TYPES.HAS_MANY]: <Select mode="multiple" />,
  [LIST_FIELD_DATA_TYPES.IMAGE]: <Image />
}

export const LIST_ITEM_VIEW_COMPONENTS = {
  [LIST_FIELD_DATA_TYPES.IMAGE]: <Image />
}
