import { Container, Spin } from '@qonsoll/react-design'
import PropTypes from 'prop-types'
import { useHistory } from 'react-router-dom'

import { COLLECTIONS } from '__constants__'

import { OrderList } from 'domains/Order/components'
import { useOrderBaseActions } from 'domains/Order/hooks'

export default function RestaurantOrders(props) {
  const { restaurant, actions = {}, orders, ordersLoading } = props

  /* Getting history instance */
  const history = useHistory()
  /* Getting Order actions */
  const { createPath } = useOrderBaseActions()

  const handleCreate = () => {
    history.push(createPath, {
      parent: {
        id: restaurant?._id,
        collection: COLLECTIONS.RESTAURANTS,
        field: 'orders',
        type: 'updateParentHasManyRelationship'
      }
    })
  }

  /* Actions */
  const computedActions = {
    ...actions,
    onCreate: createPath ? handleCreate : null
  }
  return (
    <Container>
      {ordersLoading ? (
        <Spin />
      ) : (
        <OrderList
          isListWithCreate={!!createPath}
          actions={computedActions}
          orders={orders}
          currency={restaurant?.currency}
        />
      )}
    </Container>
  )
}

RestaurantOrders.propTypes = {
  restaurant: PropTypes.object.isRequired,
  actions: PropTypes.object,
  orders: PropTypes.array,
  ordersLoading: PropTypes.bool
}
