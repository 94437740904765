import ListWrapperStyled from './ListWrapper.styles'
import PropTypes from 'prop-types'
import { useRef } from 'react'

const ListWrapper = ({ children, negotiate }) => {
  const listWrapperRef = useRef(null)

  // Function which handles scrolling in the list
  const onScroll = (event) => {
    // Get scroll position
    const scrollTop = event?.target?.scrollTop

    // Get list wrapper's height
    const clientHeight = listWrapperRef?.current?.clientHeight
    // Get height of the content inside the list
    const height = listWrapperRef?.current?.children?.[0]?.clientHeight
    // Get list wrapper's style
    const listWrapperStyle = listWrapperRef?.current?.style

    if (listWrapperStyle) {
      // If it was scrolled but the end of the list was not reached, set box shadow for top and bottom
      if (scrollTop > 0 && scrollTop < height - clientHeight) {
        listWrapperStyle['box-shadow'] =
          'var(--list-wrapper-box-shadow-top-bottom)'
      }
      // If it was scrolled but the end of the list was reached, set box shadow for top
      else if (scrollTop > 0) {
        listWrapperStyle['box-shadow'] = 'var(--list-wrapper-box-shadow-top)'
      }
      // If content inside list wrapper is bigger then list wrapper set box shadow for bottom
      else {
        if (clientHeight !== height) {
          listWrapperStyle['box-shadow'] =
            'var(--list-wrapper-box-shadow-bottom)'
        }
      }
    }
  }

  return (
    <ListWrapperStyled
      negotiate={negotiate}
      onScroll={onScroll}
      ref={listWrapperRef}
      onLoad={onScroll}>
      {children}
    </ListWrapperStyled>
  )
}

ListWrapper.propTypes = {
  children: PropTypes.node,
  negotiate: PropTypes.number
}

export default ListWrapper
