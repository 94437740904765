const DISABLED_LINK = [
  'zones',
  'restaurants',
  'menus-dishes',
  'orders',
  'q-r-code',
  'profiles',
  'dishes',
  'menus',
  'zone',
  'venues'
]
export default DISABLED_LINK
