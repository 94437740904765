import { useCallback, useEffect, useState } from 'react'

import { COLLECTIONS } from '__constants__'
import { getDocument } from 'services/api/firebase'

/**
 * It fetches a address document from Firestore and returns it
 * @param restaurant - The restaurant object that we're getting the address for.
 * @returns An array with four values. The first value is the address object, and the second value is a
 * boolean that indicates whether the address is loading.
 */
const useGetRestaurantAddress = (restaurant) => {
  /* State for data */
  const [address, setAddress] = useState(null)
  /* State for loading */
  const [loading, setLoading] = useState(!!restaurant?.address)
  /* State for error */
  const [error, setError] = useState(null)

  /* Fetching data */
  const getAddress = useCallback(async () => {
    try {
      setLoading(true)
      const document = await getDocument(
        COLLECTIONS.ADDRESSES,
        restaurant.address
      )
      setAddress(document)
      setLoading(false)
    } catch (error) {
      setError(error)
    }
  }, [restaurant?.address])

  useEffect(() => {
    restaurant?.address && getAddress()
  }, [restaurant?.address, getAddress])

  return [address, loading, error, getAddress]
}

export default useGetRestaurantAddress
