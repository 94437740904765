import { ButtonAccent, ButtonWithPopconfirm } from 'components'
import { Col, Row } from '@qonsoll/react-design'
import { Form, Tabs } from 'antd'
import {
  useActionsItemTypeInstanceAdvancedForm,
  useInitializeItemTypeInstanceAdvancedForm
} from 'domains/ItemTypeInstance/hooks'
import { useMemo, useState } from 'react'

import { ListItemFieldsForm } from 'domains/ListItem/components'
import PropTypes from 'prop-types'
import { useCheckIfFormWasUpdated } from 'hooks'
import { useTranslations } from '@qonsoll/translation'

const ItemTypeInstanceAdvancedForm = (props) => {
  const {
    initialData,
    cw,
    isGoBack = true,
    customCancel,
    customSave,
    editMode,
    value,
    toggleAdd
  } = props

  const { languages, t } = useTranslations()
  const [selectedLanguage, setSelectedLanguage] = useState('en')
  const { isFormWasUpdated, onFormFieldsChange } = useCheckIfFormWasUpdated()
  const onLanguageChange = (key) => {
    setSelectedLanguage(key)
  }
  const { form } = useInitializeItemTypeInstanceAdvancedForm({
    initialData,
    value
  })

  const { onFinish, onReset, loading } = useActionsItemTypeInstanceAdvancedForm(
    { initialData, isGoBack, customCancel, customSave, editMode, toggleAdd }
  )

  const isNeedTranslations = useMemo(
    () =>
      initialData?.itemTypeFieldsSorted?.some(
        (field) => field?.isLocalizationEnabled
      ),
    [initialData?.itemTypeFieldsSorted]
  )

  const onSubmit = () => form.submit()
  const onCancel = () => onReset()

  const saveButtonMessage = editMode ? 'Save menu item' : 'Create new menu item'

  const formItemWidth = cw || [12, 12, 8, 6]
  const isCancelBtnWithPopconfirm = isFormWasUpdated

  // tabs props for synchronizing them on ItemTypeInstance and ObjectInstance forms
  const tabsProps = {
    isNeedTranslations: isNeedTranslations,
    onLanguageChange: onLanguageChange
  }

  return (
    <Form
      preserve
      layout="vertical"
      form={form}
      onFinish={onFinish}
      onValuesChange={onFormFieldsChange}>
      {isNeedTranslations && (
        <Tabs onChange={onLanguageChange} activeKey={selectedLanguage}>
          {languages?.map((language) => {
            return (
              <Tabs.TabPane key={language?.shortCode} tab={language?.name} />
            )
          })}
        </Tabs>
      )}
      <Row my={4} noGutters>
        <Col cw={formItemWidth}>
          <Form.Item name={['listItemFields']}>
            <ListItemFieldsForm
              selectedLanguage={selectedLanguage}
              tabsProps={tabsProps}
            />
          </Form.Item>
        </Col>
      </Row>
      <Row noGutters>
        <Col cw="auto">
          <ButtonWithPopconfirm
            withPopconfirm={isCancelBtnWithPopconfirm}
            onReset={onCancel}
          />
        </Col>
        <Col cw="auto">
          <ButtonAccent type="primary" loading={loading} onClick={onSubmit}>
            {t(saveButtonMessage)}
          </ButtonAccent>
        </Col>
      </Row>
    </Form>
  )
}

ItemTypeInstanceAdvancedForm.propTypes = {
  initialData: PropTypes.object,
  editMode: PropTypes.bool,
  cw: PropTypes.oneOf([PropTypes.object, PropTypes.array]),
  isGoBack: PropTypes.bool,
  customCancel: PropTypes.func,
  customSave: PropTypes.func,
  value: PropTypes.array,
  toggleAdd: PropTypes.func
}

export default ItemTypeInstanceAdvancedForm
