import {
  createDocument,
  getId,
  saveBelongsToRelationship,
  updateDocument,
  updateParent
} from 'services/api/firebase'
import { useHistory, useParams } from 'react-router-dom'

import { COLLECTIONS } from '__constants__'
import { Order } from 'models'
import { message } from 'antd'
import { useActionsOrderStatusSimpleForm } from 'domains/OrderStatus/components/OrderStatusSimpleForm/hooks'
import { useActionsRestaurantAdvancedForm } from 'domains/Restaurant/components/RestaurantAdvancedForm/hooks'
import { useGetErrorText } from 'hooks'
import { useState } from 'react'
import { useTranslations } from 'contexts/Translation'

const useActionsOrderAdvancedForm = ({ initialData, form } = {}) => {
  /* State for loading */
  const [loading, setLoading] = useState(false)
  /* Getting translations instance */
  const { t } = useTranslations()
  /* Getting history instance */
  const history = useHistory()
  /* Getting params instance */
  const params = useParams()
  /* Getting history state */
  const historyState = history.location.state

  const getErrorText = useGetErrorText()

  /* Getting OrderStatus values and validation function */
  const {
    prepareValues: prepareOrderStatusValues,
    validateForm: validateOrderStatusForm
  } = useActionsOrderStatusSimpleForm({ initialData: initialData?.status })
  /* Getting Restaurant values and validation function */
  const {
    prepareValues: prepareRestaurantValues,
    validateForm: validateRestaurantForm
  } = useActionsRestaurantAdvancedForm({ initialData: initialData?.restaurant })

  /* Function for preparing values for saving */
  const prepareValues = async (values = {}, additionalValues = {}) => {
    /* Getting id */
    const orderId = initialData?.['order']?._id || getId(COLLECTIONS.ORDERS)

    /* Preparing order values */
    const additionalReferences = { orderId }
    const preparedValues = {
      _id: orderId,
      ...additionalValues,
      table: values?.['order']?.['table'] ?? null,
      total: values?.['order']?.['total'] ?? null,
      comments: values?.['order']?.['comments'] ?? null,

      status: await saveBelongsToRelationship(
        COLLECTIONS.ORDER_STATUSES,
        await prepareOrderStatusValues(values['status']),
        additionalReferences
      ),

      restaurant: await saveBelongsToRelationship(
        COLLECTIONS.RESTAURANTS,
        await prepareRestaurantValues(values['restaurant']),
        additionalReferences
      ),

      orderedItems: values['orderedItems'] ?? []
    }
    if (params.restaurantId)
      preparedValues['restaurantId'] = params.restaurantId
    return preparedValues
  }

  /* Saving form data */
  const saveForm = async (values, callback) => {
    try {
      // Prepare data to be saved
      const data = await prepareValues(values)

      // Updating parent references
      // if(params.restaurantId) await updateParentHasManyRelationship (params.restaurantId, COLLECTIONS.RESTAURANTS, 'orders', data._id)
      if (historyState?.parent)
        await updateParent(historyState.parent, data._id)

      // Save data
      if (initialData) {
        await updateDocument(COLLECTIONS.ORDERS, initialData.order?._id, data)
        message.success(t('Order successfully updated'))
      } else {
        await createDocument(COLLECTIONS.ORDERS, data, data._id)
        message.success(t('Order successfully created'))
      }
      // Final callback
      callback?.()
    } catch (error) {
      throw new Error(getErrorText(error))
    }
  }

  /* Function for validating form */
  const validateForm = (values) => {
    try {
      // Prepare data to be validated
      const validationData = {
        table: values?.['order']?.['table'],
        total: values?.['order']?.['total'],
        comments: values?.['order']?.['comments']
      }
      Order.validationSchema.validateSync(validationData)
      validateOrderStatusForm(values['status'])
      validateRestaurantForm(values['restaurant'])
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error(error)
      throw new Error(t('Order validation error: ') + t(error.message))
    }
  }

  /* On finish callback */
  const onFinish = async () => {
    if (loading) return // Avoid multiple calls

    try {
      setLoading(true)
      // Get form values
      const formValues = form.getFieldsValue()
      // Validate fields
      validateForm(formValues)
      // Final callback
      const callback = () => history.goBack()
      // Save data
      await saveForm(formValues, callback)
    } catch (error) {
      setLoading(false)
      message.error(error.message)
    }
  }

  /* On cancel callback */
  const onReset = () => {
    form.resetFields()
    history.goBack()
  }

  return { onFinish, onReset, loading, saveForm, validateForm, prepareValues }
}

export default useActionsOrderAdvancedForm
