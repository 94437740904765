import { ButtonAccent, Drawer, Overlay } from 'components'
import { COLLECTIONS, LIST_FIELD_DATA_TYPES } from '__constants__'
import { Col, Row, Skeleton, Text } from '@qonsoll/react-design'
import {
  ObjectInstanceAdvancedForm,
  ObjectInstanceList
} from 'domains/ObjectInstance/components'

import { Icon } from '@qonsoll/icons'
import PropTypes from 'prop-types'
import { doc } from 'firebase/firestore'
import { firestore } from 'services/firebase'
import { useDocumentData } from 'react-firebase-hooks/firestore'
import { useGetDocumentsByIds, useLoading } from 'hooks'
import { useState } from 'react'
import { useTranslations } from 'contexts/Translation'
import { useUpdateItemTypeInstanceWithObjectValue } from 'domains/ItemTypeInstance/hooks'

const ObjectInstanceListWithCreate = (props) => {
  const { objectId, selectedLanguage, itemProps = {}, tabsProps } = props
  const { parentFormItemName, parentFormOnChange, parentFormValue } = itemProps

  const { t } = useTranslations()

  const [formVisibility, setFormVisibility] = useState(false)
  const showForm = () => setFormVisibility(true)
  const hideForm = () => setFormVisibility(false)
  const [chosenObject, setChosenObject] = useState(null)
  const chooseObject = (obj) => setChosenObject(obj)
  const resetChosenObject = () => setChosenObject(null)
  const [isDisabled, setIsDisabled] = useState(true)
  const toggleDisabled = () => setIsDisabled((prev) => !prev)

  const hideOverlay = () => {
    hideForm?.()
    resetChosenObject?.()
    toggleDisabled?.()
  }

  const [object, objectLoading] = useDocumentData(
    objectId && doc(firestore, COLLECTIONS.OBJECTS, objectId)
  )
  const [objectFields, objectFieldsLoading] = useGetDocumentsByIds({
    collectionName: COLLECTIONS.OBJECT_FIELDS,
    entityIds: object?.fieldsOrder
  })

  // This fn set in the object field of parentForm the value ob object from current form
  const updateItemTypeInstanceWithObjectValue =
    useUpdateItemTypeInstanceWithObjectValue({
      parentFormValue: parentFormValue,
      parentFormOnChange: parentFormOnChange
    })

  // fieldName of object inside menu item
  const objectFieldName = parentFormItemName[parentFormItemName?.length - 1]
  // Real object instance data based on objectTemplate fields
  const objectInstanceData = parentFormValue?.find(
    (item) => item?.fieldName === objectFieldName
  )
  const loading = useLoading([objectLoading, objectFieldsLoading])
  const isObjectInstances = objectInstanceData?.[objectFieldName]?.length
  const objectInstanceTypeHasOneExists =
    objectInstanceData?.[objectFieldName]?.length === 1 &&
    objectInstanceData?.dataType === LIST_FIELD_DATA_TYPES.HAS_ONE

  const objectProps = {
    objectInstanceData: objectInstanceData?.[objectFieldName],
    objectFields: objectFields,
    objectId: objectId,
    objectFieldName: objectFieldName,
    updateItemTypeInstanceWithObjectValue:
      updateItemTypeInstanceWithObjectValue,
    chosenObject,
    resetChosenObject
  }

  return (
    <Row noGutters>
      <Col cw={12}>
        <Overlay showForm={formVisibility} onClick={hideOverlay} />
        <Drawer showForm={formVisibility}>
          {loading ? (
            <Skeleton active={loading} />
          ) : (
            <ObjectInstanceAdvancedForm
              tabsProps={tabsProps}
              itemProps={itemProps}
              selectedLanguage={selectedLanguage}
              hideForm={hideForm}
              objectProps={objectProps}
              isDisabled={isDisabled}
              setIsDisabled={setIsDisabled}
            />
          )}
        </Drawer>

        {!objectInstanceTypeHasOneExists && (
          <Row noGutters h="right" mt={-4}>
            <Col cw="auto">
              <ButtonAccent
                type="secondary"
                onClick={showForm}
                icon={<Icon name="Plus1Outlined" />}>
                {t('Add object instance')}
              </ButtonAccent>
            </Col>
          </Row>
        )}

        {isObjectInstances ? (
          <ObjectInstanceList
            selectedLanguage={selectedLanguage}
            objectInstancesData={objectInstanceData?.[objectFieldName]}
            showForm={showForm}
            chooseObject={chooseObject}
            updateItemTypeInstanceWithObjectValue={
              updateItemTypeInstanceWithObjectValue
            }
            objectFieldName={objectFieldName}
          />
        ) : (
          <Text variant="overline" color="var(--ql-state-disabled-color)">
            {t('No object instances have been added yet')}
          </Text>
        )}
      </Col>
    </Row>
  )
}

ObjectInstanceListWithCreate.propTypes = {
  objectId: PropTypes.string,
  selectedLanguage: PropTypes.string,
  itemProps: PropTypes.object,
  tabsProps: PropTypes.object
}

export default ObjectInstanceListWithCreate
