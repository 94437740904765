import { AddItemCard, ListWrapper } from 'components'
import { Col, Row, Text } from '@qonsoll/react-design'

import { MenuSimpleView } from 'domains/Menu/components'
import PropTypes from 'prop-types'
import { useTranslations } from 'contexts/Translation'

const MenuList = (props) => {
  const { menus, isListWithCreate, listView, actions, cw = [12, 6, 4] } = props

  /* Getting translations instance */
  const { t } = useTranslations()
  /* Checking if list is empty */
  const isEmpty = !menus?.length
  /* Computing item width */
  const itemWidth = listView ? 12 : cw

  return (
    <ListWrapper negotiate="var(--page-height-to-negotiate-with-table-header)">
      <Row negativeBlockMargin>
        {isListWithCreate && actions?.onCreate && (
          <Col mb={4} cw={itemWidth}>
            <AddItemCard
              minHeight="98px"
              message={t('Add menu')}
              onClick={actions?.onCreate}
            />
          </Col>
        )}
        {isEmpty ? (
          <Col cw={12}>
            <Text variant="overline" color="var(--ql-state-disabled-color)">
              {t('No menus have been created yet')}
            </Text>
          </Col>
        ) : (
          menus?.map((menu, index) => (
            <Col mb={4} key={menu?._id || index} cw={itemWidth}>
              <MenuSimpleView actions={actions} index={index} menu={menu} />
            </Col>
          ))
        )}
      </Row>
    </ListWrapper>
  )
}

MenuList.propTypes = {
  menus: PropTypes.array,
  isListWithCreate: PropTypes.bool,
  listView: PropTypes.bool,
  emptyProps: PropTypes.object,
  actions: PropTypes.any,
  cw: PropTypes.any
}

export default MenuList
