import {
  COLLECTIONS,
  LIST_FIELD_DATA_TYPES,
  UNUSED_DIFFERENCE_MENU_ITEM_KEYS
} from '__constants__'
import {
  collection,
  doc,
  serverTimestamp,
  setDoc,
  updateDoc
} from 'firebase/firestore'

import { firestore } from 'services/firebase'
import { message } from 'antd'
import { compareObjects, uploadImage } from 'helpers'
import { useHistory, useParams } from 'react-router-dom'
import { useState } from 'react'
import { useTranslations } from '@qonsoll/translation'

const useActionsItemTypeInstanceAdvancedForm = ({
  initialData,
  isGoBack,
  customCancel,
  customSave,
  toggleAdd
}) => {
  const [loading, setLoading] = useState(false)

  const history = useHistory()
  const params = useParams()

  const { menuId } = params

  const { t } = useTranslations()
  const prepareObjectInstances = (menuItemObjectInstances, menuItemId) => {
    return menuItemObjectInstances?.map((item) => ({
      ...item,
      menuItemId: menuItemId
    }))
  }
  // Try/catch is not needed here because the function is called inside onFinish which is already wrapped in try / catch
  const prepareValues = async (values = {}, additionalValues = {}) => {
    const menuItemId =
      initialData?.menuItem?._id ||
      doc(collection(firestore, COLLECTIONS.MENU_ITEMS)).id

    const menuItemFields = {}
    let menuItemObjectInstances = []
    for (const item of values?.listItemFields || []) {
      if (item?.dataType === LIST_FIELD_DATA_TYPES.IMAGE) {
        menuItemFields[item?.fieldName] =
          (await uploadImage(item[item?.fieldName])) ?? null
      } else if (item?.dataType === LIST_FIELD_DATA_TYPES.BOOLEAN) {
        menuItemFields[item?.fieldName] = item[item?.fieldName] ?? false
      } else if (item?.object && item[item?.fieldName]) {
        if (item?.dataType === LIST_FIELD_DATA_TYPES.HAS_ONE) {
          menuItemFields[item?.fieldName] =
            item[item?.fieldName][0]?._id ?? null
        }
        if (item?.dataType === LIST_FIELD_DATA_TYPES.HAS_MANY) {
          menuItemFields[item?.fieldName] =
            item[item?.fieldName]?.map((objItem) => objItem?._id) ?? []
        }

        if (item[item?.fieldName]?.length) {
          menuItemObjectInstances?.push(...item[item?.fieldName])
        }
      } else {
        menuItemFields[item?.fieldName] = item[item?.fieldName] ?? null
      }
    }

    const serviceFieldName = initialData?.menuItem?._id
      ? '_updatedAt'
      : '_createdAt'

    return {
      menuItem: {
        _id: menuItemId,
        [serviceFieldName]: serverTimestamp(),
        itemTypeId: initialData?.itemType?._id,

        ...additionalValues,
        ...menuItemFields
      },
      menuItemObjectInstances: prepareObjectInstances(
        menuItemObjectInstances,
        menuItemId
      )
    }
  }

  const validatedName = (data) => {
    const objName = data?.listItemFields?.find(
      (item) => item?.fieldName === 'name'
    )

    const isNameEnglishEmpty = objName?.name?.en?.trim()?.length > 0

    if (!isNameEnglishEmpty) {
      throw new Error(`${t('name in english can not be empty')}`)
    }

    return isNameEnglishEmpty
  }

  const onFinish = async (values) => {
    try {
      setLoading(true)

      if (!validatedName(values)) return

      const preparedValues = await prepareValues(values)

      const { menuItem, menuItemObjectInstances } = preparedValues

      const { onlyMenuRewrite } = initialData?.menuItem || {}

      if (customSave) customSave(preparedValues)
      if (initialData?.menuItem?._id) {
        if (onlyMenuRewrite) {
          const difference = compareObjects(menuItem, initialData?.menuItem)

          const differenceResult = Object.keys(difference).reduce(
            (acc, key) => {
              if (!UNUSED_DIFFERENCE_MENU_ITEM_KEYS.includes(key)) {
                return { ...acc, [key]: menuItem[key] }
              }
              return acc
            },
            {}
          )

          const previousRewritesData =
            initialData?.menuItem?.rewrites?.[menuId] || {}

          await updateDoc(
            doc(firestore, COLLECTIONS.MENU_ITEMS, initialData?.menuItem?._id),
            {
              rewrites: {
                [menuId]: {
                  ...previousRewritesData,
                  ...differenceResult
                }
              },
              _updatedAt: serverTimestamp()
            }
          )
        } else {
          await updateDoc(
            doc(firestore, COLLECTIONS.MENU_ITEMS, initialData?.menuItem?._id),
            menuItem
          )

          menuItemObjectInstances?.length &&
            menuItemObjectInstances?.forEach(
              async (instance) =>
                await setDoc(
                  doc(firestore, COLLECTIONS.OBJECT_INSTANCES, instance?._id),
                  instance
                )
            )
        }
        message.success(t('Menu item was edited successfully'))
      } else {
        await setDoc(
          doc(firestore, COLLECTIONS.MENU_ITEMS, menuItem?._id),
          menuItem
        )

        !initialData?.menuItem?._id &&
          (await updateDoc(
            doc(firestore, COLLECTIONS.ITEM_TYPES, initialData?.itemType?._id),
            {
              menuItems: {
                ...(initialData?.itemType?.menuItems || {}),
                [menuItem?._id]: true
              }
            }
          ))

        // objects instances saving
        await Promise.all(
          menuItemObjectInstances?.map((instance) =>
            setDoc(
              doc(firestore, COLLECTIONS.OBJECT_INSTANCES, instance?._id),
              instance
            )
          )
        )

        message.success(t('Menu item was created successfully'))
      }
      toggleAdd && toggleAdd?.()
      isGoBack && history.goBack()
    } catch (error) {
      message.error(
        `${t('Something went wrong during saving menu item')}, ${
          error?.message
        }`
      )
    } finally {
      setLoading(false)
    }
  }

  const onReset = () => {
    if (isGoBack) history.goBack()
    if (customCancel) customCancel()
  }

  return { onFinish, onReset, loading }
}

export default useActionsItemTypeInstanceAdvancedForm
