import { useCallback, useEffect, useState } from 'react'

import { COLLECTIONS } from '__constants__'
import { getDocument } from 'services/api/firebase'

/**
 * It fetches a orderedItems documents from Firestore and returns it
 * @param order - The order object that we're getting the orderedItems for.
 * @returns An array with two values. The first value is the orderedItems object, and the second value is a
 * boolean that indicates whether the orderedItems is loading.
 */
const useGetOrderOrderedItems = (order) => {
  /* State for data */
  const [orderedItems, setOrderedItems] = useState([])

  /* State for loading */
  const [loading, setLoading] = useState(!!order?.orderedItems?.length)
  /* State for error */
  const [error, setError] = useState(null)

  /* Fetching data */
  const getOrderedItems = useCallback(async () => {
    try {
      /* Setting loading state to true */
      setLoading(true)

      /* Getting orderedItems promises*/
      const promises = Object.keys(order?.orderData)?.map((orderedItem) =>
        getDocument(COLLECTIONS.COCKTAILS_NEW, orderedItem)
      )

      /* Getting orderedItems data */
      const data = await Promise.all(promises)

      /* Setting orderedItems data */
      setOrderedItems(data)

      /* Setting loading state to false */
    } catch (error) {
      setError(error)
    }
    setLoading(false)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [order?.orderedItems])

  useEffect(() => {
    getOrderedItems()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [order?.orderData])

  return [orderedItems, loading, error, getOrderedItems]
}

export default useGetOrderOrderedItems
