import {
  Col,
  Container,
  Layout,
  LayoutSystemProvider,
  PageWrapper,
  Row
} from '@qonsoll/react-design'

import { TermAndConditionsOfUseSimpleView } from 'domains/App/components'
import { useHistory } from 'react-router-dom'
import { useTranslations } from 'contexts/Translation'

const TermsAndConditionsOfUse = () => {
  const { t } = useTranslations()
  const history = useHistory()

  const handleBackClick = () => history.goBack()

  return (
    <LayoutSystemProvider>
      <Layout>
        <PageWrapper
          onBack={handleBackClick}
          headingProps={{
            title: `${t('Terms')} & ${t('Conditions and Privacy Policy')}`,
            titleSize: 3
          }}>
          <Container>
            <Row>
              <Col>
                <TermAndConditionsOfUseSimpleView />
              </Col>
            </Row>
          </Container>
        </PageWrapper>
      </Layout>
    </LayoutSystemProvider>
  )
}

export default TermsAndConditionsOfUse
