import { Col, PageWrapper, Row } from '@qonsoll/react-design'
import { HeaderBreadcrumbs, PageActions, Spinner } from 'components'
import { generatePath, useHistory, useParams } from 'react-router-dom'
import {
  useGetMenuItemData,
  useGetMenuItemViewData,
  useMenuItemBaseActions
} from 'domains/MenuItem/hooks'
import { MenuItemAdvancedView } from 'domains/MenuItem/components'
import PATHS from 'pages/paths'
import { useLoading } from 'hooks'
import { useTranslations } from 'contexts/Translation'

const MenuItemShowPage = () => {
  /* Getting translations instance */
  const { t, language } = useTranslations()
  const history = useHistory()
  /* Getting params from router */
  const { itemTypeId, menuItemId } = useParams()
  const [combinedData, combinedLoading] = useGetMenuItemData(
    itemTypeId,
    menuItemId
  )
  const params = useParams()
  const [menuItem, menuWithSelectedMenuItem, menuItemLoading] =
    useGetMenuItemViewData(menuItemId)

  const {
    onBack,
    loading: actionLoading,
    onDelete
  } = useMenuItemBaseActions({
    menuItemId,
    menuItem,
    menuWithSelectedMenuItem
  })
  const loading = useLoading([menuItemLoading, combinedLoading])

  const headingProps = {
    title: menuItem?.name?.[language] ?? menuItem?.name?.en ?? t('Menu item'),
    marginBottom: 3,
    textAlign: 'left'
  }

  const actions = {
    edit: false,
    delete: false
  }

  const onEdit = () => {
    history.push(
      generatePath(PATHS.AUTHENTICATED.ITEM_TYPE_INSTANCE_EDIT, { ...params })
    )
  }

  return (
    <PageWrapper
      onBack={onBack}
      action={
        <PageActions
          document={menuItem}
          actions={actions}
          onDelete={onDelete}
          onEdit={onEdit}
          deleteBtnProps={{ loading: actionLoading }}
        />
      }
      breadcrumbs={<HeaderBreadcrumbs />}
      headingProps={headingProps}>
      <Row noGutters>
        {loading ? (
          <Spinner />
        ) : (
          <Col cw={12}>
            <MenuItemAdvancedView menuItem={menuItem} value={combinedData} />
          </Col>
        )}
      </Row>
    </PageWrapper>
  )
}

export default MenuItemShowPage
