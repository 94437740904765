import {
  useGetRestaurantAddress,
  useGetRestaurantMediaObjects,
  useGetRestaurantMenus,
  useGetRestaurantOrders,
  useGetRestaurantQRCodes
} from 'domains/Restaurant/hooks'

import { COLLECTIONS } from '__constants__'
import { useDocument } from 'services/api/firebase'
import { useLoading } from 'hooks'
import { useMemo } from 'react'

const useGetRestaurantInitialValues = (id) => {
  /* Fetching data using useDocument hook */
  const [restaurant, restaurantLoading] = useDocument({
    ref: id ? COLLECTIONS.RESTAURANTS + '/' + id : null
  })

  /* Fetching address data */
  const [address, addressLoading] = useGetRestaurantAddress(restaurant)

  /* Fetching mediaObject data */
  const [mediaObjects, mediaObjectLoading] =
    useGetRestaurantMediaObjects(restaurant)

  /* Fetching menu data */
  const [menus, menuLoading] = useGetRestaurantMenus(restaurant)

  /* Fetching order data */
  const [orders, orderLoading] = useGetRestaurantOrders(restaurant)

  /* Fetching qRCode data */
  const [qrCodes, qRCodeLoading] = useGetRestaurantQRCodes(restaurant)

  /* Merge all fetched data */
  const initialValues = useMemo(
    () => ({
      restaurant,
      address,
      mediaObjects,
      menus,
      orders,
      qrCodes
    }),
    [restaurant, address, mediaObjects, menus, orders, qrCodes]
  )

  /* Merge all loading states */
  const loadings = useMemo(
    () =>
      id
        ? [
            !restaurant,
            restaurantLoading,
            addressLoading,
            mediaObjectLoading,
            menuLoading,
            orderLoading,
            qRCodeLoading
          ]
        : [],
    [
      id,
      restaurant,
      restaurantLoading,
      addressLoading,
      mediaObjectLoading,
      menuLoading,
      orderLoading,
      qRCodeLoading
    ]
  )

  /* Getting loading state using merged loadings */
  const loading = useLoading(loadings)

  return [initialValues, loading]
}

export default useGetRestaurantInitialValues
