import { useMemo } from 'react'
import { useLoading } from 'hooks'
import { useDocument } from 'services/api/firebase'
import { COLLECTIONS } from '__constants__'
import {
  useGetOrderOrderStatus,
  useGetOrderOrderedItems
} from 'domains/Order/hooks'
import { useGetRestaurantInitialValues } from 'domains/Restaurant/hooks'

const useGetOrderInitialValues = (id) => {
  /* Fetching data using useDocument hook */
  const [order, orderLoading] = useDocument({
    ref: id ? COLLECTIONS.ORDERS + '/' + id : null
  })

  /* Fetching orderStatus data */
  const [status, orderStatusLoading] = useGetOrderOrderStatus(order)

  /* Fetching restaurant data */
  const [restaurant, restaurantLoading] = useGetRestaurantInitialValues(
    order?.['restaurant']
  )

  /* Fetching orderedItem data */
  const [orderedItems, orderedItemLoading] = useGetOrderOrderedItems(order)

  /* Merge all fetched data */
  const initialValues = useMemo(
    () => ({
      order,
      status,
      restaurant,
      orderedItems
    }),
    [order, status, restaurant, orderedItems]
  )

  /* Merge all loading states */
  const loadings = useMemo(
    () =>
      id
        ? [
            !order,
            orderLoading,
            orderStatusLoading,
            restaurantLoading,
            orderedItemLoading
          ]
        : [],
    [
      id,
      order,
      orderLoading,
      orderStatusLoading,
      restaurantLoading,
      orderedItemLoading
    ]
  )

  /* Getting loading state using merged loadings */
  const loading = useLoading(loadings)

  return [initialValues, loading]
}

export default useGetOrderInitialValues
