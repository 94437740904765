import { useEffect, useState, useCallback } from 'react'
import { getDocument } from 'services/api/firebase'
import { COLLECTIONS } from '__constants__'

/**
 * It fetches a methods documents from Firestore and returns it
 * @param cocktail - The cocktail object that we're getting the methods for.
 * @returns An array with two values. The first value is the methods object, and the second value is a
 * boolean that indicates whether the methods is loading.
 */
const useGetCocktailMethods = (cocktail) => {
  /* State for data */
  const [methods, setMethods] = useState([])
  /* State for loading */
  const [loading, setLoading] = useState(!!cocktail?.methods?.length)
  /* State for error */
  const [error, setError] = useState(null)

  /* Fetching data */
  const getMethods = useCallback(async () => {
    try {
      /* Setting loading state to true */
      setLoading(true)

      /* Getting methods promises*/
      const promises = cocktail.methods.map((method) =>
        getDocument(COLLECTIONS.METHODS, method)
      )

      /* Getting methods data */
      const data = await Promise.all(promises)

      /* Setting methods data */
      setMethods(data)

      /* Setting loading state to false */
    } catch (error) {
      setError(error)
    }
    setLoading(false)
  }, [cocktail?.methods])

  useEffect(() => {
    cocktail?.methods?.length && getMethods()
  }, [cocktail?.methods, getMethods])

  return [methods, loading, error, getMethods]
}

export default useGetCocktailMethods
