import { Box, Title } from '@qonsoll/react-design'
import {
  StyledFilter,
  buttonProps,
  filterBackground,
  filterMaxHeight,
  iconStyles
} from './MenuCategoryFilter.styles'

import { ButtonAccent } from 'components'
import { CloseOutlined } from '@ant-design/icons'
import { CocktailFilter } from './components'
import PropTypes from 'prop-types'
import { useTranslations } from 'contexts/Translation'

const MenuCategoryFilter = (props) => {
  const { filterVisibility, setFilterVisibility, filterData, setFilterData } =
    props

  /* Getting translations instance */
  const { t } = useTranslations()

  /* Closing filter */
  const handleClose = () => setFilterVisibility(false)

  return filterVisibility ? (
    <>
      <StyledFilter maxHeight={filterMaxHeight} background={filterBackground}>
        <Box display="flex" justifyContent="space-between" mt={3}>
          <Title mb={2} level={4}>
            {t('Filter')}
          </Title>
          <ButtonAccent
            {...buttonProps}
            onClick={handleClose}
            icon={<CloseOutlined style={iconStyles} />}
          />
        </Box>
        <CocktailFilter filterData={filterData} setFilterData={setFilterData} />
      </StyledFilter>
    </>
  ) : null
}

MenuCategoryFilter.propTypes = {
  filterVisibility: PropTypes.bool,
  setFilterVisibility: PropTypes.func,
  filterData: PropTypes.object,
  setFilterData: PropTypes.func
}

export default MenuCategoryFilter
