const BILLING_TYPES = {
  MONTHLY: 'Monthly',
  ANNUALLY: 'Annually'
}

const BILLING_TYPES_KEYS = {
  MONTHLY: 'MONTHLY',
  ANNUALLY: 'ANNUALLY'
}

const BILLING_TYPES_OPTIONS = Object.keys(BILLING_TYPES).map((key) => ({
  label: BILLING_TYPES[key],
  value: key
}))

export { BILLING_TYPES, BILLING_TYPES_KEYS, BILLING_TYPES_OPTIONS }
