import { Col, Container, Row, Text } from '@qonsoll/react-design'

import { CocktailSimpleView } from '../../../Cocktail/components'
import PropTypes from 'prop-types'
import { Spinner } from 'components'
import { useGetOrderedItemCocktail } from '../../hooks'
import { useTranslations } from 'contexts/Translation'

export default function OrderedItemCocktail(props) {
  const { orderedItem } = props

  /* Getting required data */
  const [cocktail, loading] = useGetOrderedItemCocktail(orderedItem)

  /* Getting translation instance */
  const { t } = useTranslations()

  return (
    <Container>
      <Row noGutters>
        <Col>
          {loading ? (
            <Spinner flex={1} />
          ) : cocktail ? (
            <CocktailSimpleView {...props} cocktail={cocktail} />
          ) : (
            <Text type="secondary" variant="overline">
              {t('No drinks have been added yet')}
            </Text>
          )}
        </Col>
      </Row>
    </Container>
  )
}

OrderedItemCocktail.propTypes = {
  orderedItem: PropTypes.object.isRequired
}
