import { Col, Container, Option, Row, Text, Title } from '@qonsoll/react-design'
import { collection, orderBy, query } from 'firebase/firestore'

import { COLLECTIONS } from '__constants__'
import { Icon } from '@qonsoll/icons'
import PropTypes from 'prop-types'
import { SelectWithFixedDropdown } from 'components'
import _ from 'lodash'
import { firestore } from 'services/firebase'
import { handleFilterOptions } from 'helpers'
import { useCollectionData } from 'react-firebase-hooks/firestore'
import { useMemo } from 'react'
import { useTranslations } from 'contexts/Translation'

// Takes a value of type object and onChange function
// Returns selected option object
const MenuSelect = (props) => {
  const {
    value,
    onChange,
    showTitle = false,
    allowClear = true,
    exclude = [],
    mode,
    infoText,
    ...rest
  } = props

  const [allMenus = [], loading] = useCollectionData(
    query(collection(firestore, COLLECTIONS.MENUS), orderBy('title', 'asc'))
  )

  /* Getting translation instance */
  const { t } = useTranslations()

  /* Checking if mode is multiple */
  const isMultiple = useMemo(() => mode === 'multiple', [mode])

  /* Handling case when db record not added yet but value already exists and ready to be used */
  const valueComputed = useMemo(() => {
    if (isMultiple) return value
    return value || null
  }, [isMultiple, value])

  /* Filtering out excluded values */
  const optionsComputed = useMemo(() => {
    const _options = _.uniqBy([...allMenus], '_id')

    const filteredOptions = _options?.filter(
      (option) => !exclude?.includes(option?._id)
    )

    return filteredOptions
  }, [allMenus, exclude])

  /* Handling change selected option */
  const handleChange = (value) => {
    if (isMultiple) {
      const _options = optionsComputed?.filter((option) =>
        value?.includes(option?._id)
      )
      onChange?.(_options || null, value)
    } else {
      const _option = optionsComputed?.find(({ _id: id }) => id === value)
      onChange?.(_option || null, value)
    }
  }
  const selectValue = isMultiple ? valueComputed : valueComputed?._id

  return (
    <Container {...rest}>
      {showTitle && (
        <Title mb={2} level={4}>
          {t('Menu')}
        </Title>
      )}
      <Row noGutters mb={infoText ? 2 : 0}>
        <Col>
          <SelectWithFixedDropdown
            mode={mode}
            allowClear={allowClear}
            loading={loading}
            placeholder={t('Please, select menu')}
            value={selectValue}
            onChange={handleChange}
            filterOption={handleFilterOptions}
            aria-label="Select menu"
            aria-describedby="menu-description">
            {optionsComputed.map((menu) => (
              <Option key={menu?._id} value={menu?._id}>
                {menu?.title}
              </Option>
            ))}
          </SelectWithFixedDropdown>
        </Col>
      </Row>
      {infoText ? (
        <Row noGutters>
          <Col flexDirection="row">
            <Icon
              name="AnnotationQuestionOutlined"
              fill="var(--ql-icon-color-secondary)"
              mr={2}
            />
            <Text type="secondary">{infoText}</Text>
          </Col>
        </Row>
      ) : null}
    </Container>
  )
}

MenuSelect.propTypes = {
  value: PropTypes.object,
  onChange: PropTypes.func,
  showTitle: PropTypes.bool,
  allowClear: PropTypes.bool,
  exclude: PropTypes.array,
  mode: PropTypes.string,
  infoText: PropTypes.string
}

export default MenuSelect
