import { Checkbox } from 'antd'
import styled from 'styled-components'

export const StyledCheckBox = styled(Checkbox)`
  .ant-checkbox {
    top: 0;
    width: 24px;
    height: 24px;
    border-radius: 6px;
  }
  .ant-checkbox-inner {
    border-radius: 6px;
    height: 100%;
    width: 100%;
  }
  .ant-checkbox .ant-checkbox-inner {
    border: 1px solid var(--checkbox-border);
  }
  .ant-checkbox:hover .ant-checkbox-inner,
  .ant-checkbox:focus .ant-checkbox-inner {
    border: 1px solid var(--checkbox-hover-border);
    box-shadow: var(--checkbox-hover-shadow);
  }
  .ant-checkbox:active .ant-checkbox-inner {
    border: 1px solid var(--checkbox-active-border);
    box-shadow: var(--checkbox-active-shadow);
  }
  .ant-checkbox-checked::after {
    border-radius: 6px;
  }
  .ant-checkbox-checked .ant-checkbox-inner::after {
    width: 11px;
    height: 13px;
    left: 4px;
    border-width: 3px;
    border-color: var(--ql-text-white);
  }
  .ant-checkbox-disabled:not(.ant-checkbox-checked) .ant-checkbox-inner,
  .ant-checkbox-disabled:not(.ant-checkbox-checked):hover .ant-checkbox-inner {
    background: var(--checkbox-disabled-bg);
    border: 1px solid var(--checkbox-disabled-border);
    box-shadow: none;
  }
  .ant-checkbox-disabled .ant-checkbox-inner,
  .ant-checkbox-disabled:hover .ant-checkbox-inner {
    background: var(--checkbox-checked-disabled-bg);
    border: 1px solid transparent;
    box-shadow: none;
  }
`
