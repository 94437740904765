const MENU_ACTIVITIES = {
  ACTIVE: 'Active',
  NOT_ACTIVE: 'Not active'
}

const MENU_ACTIVITIES_KEYS = {
  ACTIVE: 'ACTIVE',
  NOT_ACTIVE: 'NOT_ACTIVE'
}

const MENU_ACTIVITIES_OPTIONS = Object.keys(MENU_ACTIVITIES).map((key) => ({
  label: MENU_ACTIVITIES[key],
  value: key
}))

export { MENU_ACTIVITIES, MENU_ACTIVITIES_KEYS, MENU_ACTIVITIES_OPTIONS }
