import { useHistory } from 'react-router-dom'
import { useMemo, useCallback } from 'react'
import Fuse from 'fuse.js'

import { useTranslations } from 'contexts/Translation'
import PATHS from 'pages/paths'
import { useLoading, useSimpleSearch, useFilter } from 'hooks'

import { useGetItemTypes } from 'domains/ItemType/hooks'

import { useCustomerContext } from 'contexts/CustomerContext'

const useItemTypesAllBaseActions = () => {
  /* Hook that provides translation strings based on the current language. */
  const { t } = useTranslations()
  /* A hook from react-router-dom used to access the browser history instance and navigate programmatically to different pages */
  const history = useHistory()
  const { customer } = useCustomerContext()

  /* Getting filter params */
  const { filterVisibility, filterData, setFilterData } = useFilter('item_type')
  /* Building filter query */
  const ref = useMemo(() => filterData, [filterData])

  const whereQuery = customer?._id
    ? [`access.companies.${customer?._id}`, '==', true]
    : []

  /* An array of objects representing the different item types.*/
  /* A boolean indicating whether the item types are currently being fetched or not.*/
  const [itemTypes, itemTypesLoading] = useGetItemTypes({
    orderBy: ref?.orderBy,
    where: whereQuery
  })

  /* An instance of Fuse that is created with the item types array and some options to perform a fuzzy search on the item types based on their name property */
  const fuseObject = useMemo(
    () =>
      itemTypes
        ? new Fuse(itemTypes, {
            // At what point does the match algorithm give up. 0 - max, 1 - min
            threshold: 0.1,
            keys: ['name']
          })
        : null,
    [itemTypes]
  )

  /* Setup search for companies */
  const { onSearchInputChange, searchRef, searchResult, searchLoading } =
    useSimpleSearch(itemTypes, fuseObject)

  /* A boolean indicating whether the item types or the search results are currently being fetched or not. */
  const loading = useLoading([itemTypesLoading, searchLoading])
  /* Checking if list is empty */
  const isEmpty = !itemTypes?.length
  /* Checking if there are no search results and if the list is not empty. */
  const isNoRelatedSearchResults = !searchResult?.length && !isEmpty
  /* Checking if there are no search results and if the list is not empty. */
  const isRelatedSearchResults = searchResult?.length && !isEmpty

  /* An object containing props to be passed to a heading component that displays the title of the page. */
  const headingProps = useMemo(
    () => ({
      title: t('Item types'),
      marginBottom: 3,
      textAlign: 'left'
    }),
    [t]
  )

  /* An object containing booleans that indicate whether certain actions are allowed on the item types list. */
  const actions = useMemo(
    () => ({
      create: false,
      sort: true
    }),
    []
  )

  /* A function that navigates back to the previous page in the browser history */
  const onBack = useCallback(() => history.goBack(), [history])

  /* A function that navigates to the page for creating a new item type */
  const onCreate = useCallback(
    () => history.push(PATHS.AUTHENTICATED.ITEM_TYPE_CREATE),
    [history]
  )

  return useMemo(
    () => ({
      loading,
      isEmpty,
      isNoRelatedSearchResults,
      isRelatedSearchResults,
      onSearchInputChange,
      searchRef,
      onCreate,
      actions,
      onBack,
      headingProps,
      searchResult,
      filterData,
      setFilterData,
      filterVisibility
    }),
    [
      actions,
      filterData,
      headingProps,
      isEmpty,
      isNoRelatedSearchResults,
      isRelatedSearchResults,
      loading,
      onBack,
      onCreate,
      onSearchInputChange,
      searchRef,
      searchResult,
      setFilterData,
      filterVisibility
    ]
  )
}

export default useItemTypesAllBaseActions
