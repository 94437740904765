import { useState } from 'react'
import { message } from 'antd'
import moment from 'moment'
import { useHistory } from 'react-router-dom'
import firebase from 'firebase/compat/app'

import {
  createDocument,
  getId,
  saveBelongsToRelationship,
  updateDocument,
  updateParent
} from 'services/api/firebase'

import {
  BILLING_TYPES_KEYS,
  COLLECTIONS,
  USER_ROLES_KEYS,
  VARIANT_TYPES_KEYS
} from '__constants__'

import { Customer } from 'models'
import { uploadImage } from 'helpers'
import { useGetErrorText } from 'hooks'
import { useTranslations } from 'contexts/Translation'
import { useUserContext } from 'contexts/UserContext'

import { useAutoGenerateLicenseNumber } from 'domains/Customer/hooks'

const useActionsCustomerAdvancedForm = ({
  initialData,
  form,
  createCallback
} = {}) => {
  const { userId } = useUserContext()
  const { autoGenerateLicenseNumber } = useAutoGenerateLicenseNumber()
  /* State for loading */
  const [loading, setLoading] = useState(false)
  /* Getting translations instance */
  const { t } = useTranslations()
  /* Getting history instance */
  const history = useHistory()
  /* Getting history state */
  const historyState = history.location.state

  const getErrorText = useGetErrorText()

  /* Function for preparing values for saving */
  const prepareValues = async (values = {}, additionalValues = {}) => {
    /* Getting id */
    const customerId =
      initialData?.['customer']?._id || getId(COLLECTIONS.CUSTOMERS)

    const { addressInfo } = values?.['customer'] || {}

    const addressId = values?.[addressInfo]?._id || getId(COLLECTIONS.ADDRESSES)
    /* Preparing customer values */
    const preparedValues = {
      _id: customerId,
      ...additionalValues,
      name: values?.['customer']?.['name'] ?? null,
      address:
        (await saveBelongsToRelationship(COLLECTIONS.ADDRESSES, {
          _id: addressId,
          ...addressInfo
        })) ?? null,
      logoUrl: (await uploadImage(values?.['customer']?.['logoUrl'])) ?? null,
      contactPerson: values?.['customer']?.['contactPerson']?.trim() ?? null,
      phoneNumber: values?.['customer']?.['phoneNumber'] ?? null,
      billingType: values?.['customer']?.['billingType'] ?? null,
      typeOfLicense: values?.['customer']?.['typeOfLicense'] ?? null,

      mainLanguage: values?.['customer']?.['mainLanguage'] ?? null,
      currency: values?.['customer']?.['currency'] ?? null,
      email: values?.['customer']?.['email'] ?? null,
      typeOfPlace: values?.['customer']?.['typeOfPlace'] ?? null
    }

    if (!initialData) {
      const expirationDate =
        values?.['customer']?.['billingType'] === BILLING_TYPES_KEYS.ANNUALLY
          ? moment().add({ years: 1 }).toDate()
          : moment().add({ months: 1 }).toDate()
      preparedValues.dateOfLicenseExpiration =
        firebase.firestore.Timestamp.fromDate(expirationDate)
    }

    return preparedValues
  }

  const onFinishWizard = async (values, data, address) => {
    if (values?.customer?.variantType === VARIANT_TYPES_KEYS.VENUE) {
      const addressId = data?.address
      const restaurantData = {
        ...data,
        licenseNumber: autoGenerateLicenseNumber(values) ?? null,
        address
      }

      const document = await createDocument(
        COLLECTIONS.RESTAURANTS,
        restaurantData
      )

      const dataExtended = {
        ...data,
        restaurants: [document?._id]
      }

      await createDocument(COLLECTIONS.CUSTOMERS, dataExtended, data?._id)
      // const id = await createCallback?.(data)
      await updateDocument(COLLECTIONS.RESTAURANTS, document?._id, {
        // automaticMenuId: id,
        // menus: [id],
        companyId: data?._id
      })
      await updateDocument(COLLECTIONS.ADDRESSES, addressId, {
        restaurantId: document?._id
      })
    } else {
      await createDocument(COLLECTIONS.CUSTOMERS, data, data?._id)
      await createCallback?.(data)
    }
  }

  /* Saving form data */
  const saveForm = async (values, callback) => {
    try {
      // Prepare data to be saved
      const data = await prepareValues(values)

      await updateDocument(COLLECTIONS.USERS, userId, {
        _companies: [data?._id],
        currentCompanyId: data?._id,
        companyRoles: { [data?._id]: USER_ROLES_KEYS.COMPANY_OWNER }
      })

      if (historyState?.parent)
        await updateParent(historyState.parent, data._id)

      // Save data
      if (initialData) {
        await updateDocument(
          COLLECTIONS.CUSTOMERS,
          initialData.customer?._id,
          data
        )
        message.success(t('Customer successfully updated'))
      } else {
        const addressId = getId(COLLECTIONS.ADDRESSES)
        const { addressInfo } = values?.['customer'] || {}
        const address =
          (await saveBelongsToRelationship(COLLECTIONS.ADDRESSES, {
            _id: addressId,
            ...addressInfo
          })) ?? null
        await onFinishWizard(values, data, address)

        message.success(t('Customer successfully created'))
      }
      // Final callback
      callback?.(data)
    } catch (error) {
      throw new Error(getErrorText(error))
    } finally {
      setLoading(false)
    }
  }

  /* Function for validating form */
  const validateForm = (values) => {
    try {
      // Prepare data to be validated
      const validationData = {
        name: values?.['customer']?.['name'],
        description: values?.['customer']?.['description'],
        logoUrl: values?.['customer']?.['logoUrl']
      }
      Customer.validationSchema.validateSync(validationData)
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error(error)
      throw new Error(t('Customer validation error: ') + t(error.message))
    }
  }

  /* On finish callback */
  const onFinish = async () => {
    if (loading) return // Avoid multiple calls

    try {
      setLoading(true)
      // Get form values
      const formValues = form.getFieldsValue()
      // Validate fields
      validateForm(formValues)
      // Final callback
      const callback = () => history.goBack()
      // Save data
      await saveForm(formValues, callback)
    } catch (error) {
      setLoading(false)
      message.error(error.message)
    }
  }

  /* On cancel callback */
  const onReset = () => {
    form.resetFields()
    history.goBack()
  }

  return {
    onFinish,
    onReset,
    loading,
    saveForm,
    validateForm,
    prepareValues
  }
}

export default useActionsCustomerAdvancedForm
