import { css } from 'styled-components'

export const BTN_VARIANTS = {
  primary: css`
    background: var(--btn-primary-bg);
    color: var(--btn-primary-color);
    border-color: var(--btn-primary-border);
    &&& > div > svg > path {
      stroke: var(--btn-primary-color);
    }

    &&&:hover {
      background: var(--btn-primary-hover-bg);
      color: var(--btn-primary-hover-color);
      border-color: var(--btn-primary-hover-border);
      box-shadow: var(--btn-primary-hover-shadow);
    }

    &&&:active {
      background: var(--btn-primary-active-bg);
      color: var(--btn-primary-active-color);
      border-color: var(--btn-primary-active-border);
      box-shadow: var(--btn-primary-active-shadow);
    }

    &&&:focus {
      background: var(--btn-primary-focus-bg);
      color: var(--btn-primary-focus-color);
      border-color: var(--btn-primary-focus-border);
      outline: var(--btn-outline-focus);
      box-shadow: var(--btn-primary-focus-shadow);
    }
  `,
  secondary: css`
    background: var(--btn-secondary-bg);
    color: var(--btn-secondary-color);
    border-color: var(--btn-secondary-border);
    &&& > div > svg > path {
      stroke: var(--btn-secondary-color);
    }

    &&&:hover {
      background: var(--btn-secondary-hover-bg);
      color: var(--btn-secondary-hover-color);
      border-color: var(--btn-secondary-hover-border);
      box-shadow: var(--btn-secondary-hover-shadow);
    }

    &&&:active {
      background: var(--btn-secondary-active-bg);
      color: var(--btn-secondary-active-color);
      border-color: var(--btn-secondary-active-border);
      box-shadow: var(--btn-secondary-active-shadow);
    }

    &&&:focus {
      background: var(--btn-secondary-focus-bg);
      color: var(--btn-secondary-focus-color);
      border-color: var(--btn-secondary-focus-border);
      outline: var(--btn-outline-focus);
      box-shadow: var(--btn-secondary-focus-shadow);
    }
  `,
  text: css`
    background: var(--btn-text-bg);
    color: var(--btn-text-color);
    border-color: var(--btn-text-border);
    &&& > div > svg > path {
      stroke: var(--btn-text-color);
    }

    &&&:hover {
      background: var(--btn-text-hover-bg);
      color: var(--btn-text-hover-color);
      border-color: var(--btn-text-hover-border);
      box-shadow: var(--btn-text-hover-shadow);
    }

    &&&:active {
      background: var(--btn-text-active-bg);
      color: var(--btn-text-active-color);
      border-color: var(--btn-text-active-border);
      box-shadow: var(--btn-text-active-shadow);
    }

    &&&:focus {
      background: var(--btn-text-focus-bg);
      color: var(--btn-text-focus-color);
      border-color: var(--btn-text-focus-border);
      outline: var(--btn-outline-focus);
      box-shadow: var(--btn-text-focus-shadow);
    }
  `,
  'primary-danger': css`
    background: var(--btn-primary-danger-bg);
    color: var(--btn-primary-danger-color);
    border-color: var(--btn-primary-danger-border);
    &&& > div > svg > path {
      stroke: var(--btn-primary-danger-color);
    }

    &&&:hover {
      background: var(--btn-primary-danger-hover-bg);
      color: var(--btn-primary-danger-hover-color);
      border-color: var(--btn-primary-danger-hover-border);
      box-shadow: var(--btn-primary-danger-hover-shadow);
    }

    &&&:active {
      background: var(--btn-primary-danger-active-bg);
      color: var(--btn-primary-danger-active-color);
      border-color: var(--btn-primary-danger-active-border);
      box-shadow: var(--btn-primary-danger-active-shadow);
    }

    &&&:focus {
      background: var(--btn-primary-danger-focus-bg);
      color: var(--btn-primary-danger-focus-color);
      border-color: var(--btn-primary-danger-focus-border);
      outline: var(--btn-outline-focus);
      box-shadow: var(--btn-primary-danger-focus-shadow);
    }
  `,
  'secondary-danger': css`
    background: var(--btn-secondary-danger-bg);
    color: var(--btn-secondary-danger-color);
    border-color: var(--btn-secondary-danger-border);
    &&& > div > svg > path {
      stroke: var(--btn-secondary-danger-color);
    }

    &&&:hover {
      background: var(--btn-secondary-danger-hover-bg);
      color: var(--btn-secondary-danger-hover-color);
      border-color: var(--btn-secondary-danger-hover-border);
      box-shadow: var(--btn-secondary-danger-hover-shadow);
    }

    &&&:active {
      background: var(--btn-secondary-danger-active-bg);
      color: var(--btn-secondary-danger-active-color);
      border-color: var(--btn-secondary-danger-active-border);
      box-shadow: var(--btn-secondary-danger-active-shadow);
    }

    &&&:focus {
      background: var(--btn-secondary-danger-focus-bg);
      color: var(--btn-secondary-danger-focus-color);
      border-color: var(--btn-secondary-danger-focus-border);
      outline: var(--btn-outline-focus);
      box-shadow: var(--btn-secondary-danger-focus-shadow);
    }
  `,
  'text-danger': css`
    background: var(--btn-text-danger-bg);
    color: var(--btn-text-danger-color);
    border-color: var(--btn-text-danger-border);
    &&& > div > svg > path {
      stroke: var(--btn-text-danger-color);
    }

    &&&:hover {
      background: var(--btn-text-danger-hover-bg);
      color: var(--btn-text-danger-hover-color);
      border-color: var(--btn-text-danger-hover-border);
      box-shadow: var(--btn-text-danger-hover-shadow);
    }

    &&&:active {
      background: var(--btn-text-danger-active-bg);
      color: var(--btn-text-danger-active-color);
      border-color: var(--btn-text-danger-active-border);
      box-shadow: var(--btn-text-danger-active-shadow);
    }

    &&&:focus {
      background: var(--btn-text-danger-focus-bg);
      color: var(--btn-text-danger-focus-color);
      border-color: var(--btn-text-danger-focus-border);
      outline: var(--btn-outline-focus);
      box-shadow: var(--btn-text-danger-focus-shadow);
    }
  `,
  'primary-disabled': css`
    background: var(--btn-primary-disabled-bg) !important;
    color: var(--btn-primary-disabled-color) !important;
    border-color: var(--btn-primary-disabled-border) !important;
    box-shadow: transparent !important;
    pointer-events: none;
    &&& > div > svg > path {
      stroke: var(--btn-primary-disabled-color);
    }
    &&&:hover {
      pointer-events: none;
    }

    &&&:active {
      pointer-events: none;
    }

    &&&:focus {
      pointer-events: none;
    }
  `,
  'secondary-disabled': css`
    background: var(--btn-secondary-disabled-bg) !important;
    color: var(--btn-secondary-disabled-color) !important;
    border-color: var(--btn-secondary-disabled-border) !important;
    box-shadow: transparent !important;
    pointer-events: none;
    &&& > div > svg > path {
      stroke: var(--btn-secondary-disabled-color);
    }
    &&&:hover {
      pointer-events: none;
    }

    &&&:active {
      pointer-events: none;
    }

    &&&:focus {
      pointer-events: none;
    }
  `,
  'text-disabled': css`
    background: var(--btn-text-disabled-bg) !important;
    color: var(--btn-text-disabled-color) !important;
    border-color: var(--btn-text-disabled-border) !important;
    box-shadow: transparent !important;
    pointer-events: none;
    &&& > div > svg > path {
      stroke: var(--btn-text-disabled-color);
    }
    &&&:hover {
      pointer-events: none;
    }

    &&&:active {
      pointer-events: none;
    }

    &&&:focus {
      pointer-events: none;
    }
  `
}
