import { Box } from '@qonsoll/react-design'
import PropTypes from 'prop-types'
import { Tooltip } from 'antd'

const ConditionalTooltipWrapper = (props) => {
  const { withTooltip, children, tooltipTitle, tooltipPlacement } = props

  return withTooltip ? (
    <Tooltip placement={tooltipPlacement || 'top'} title={tooltipTitle}>
      {/* Box is needed to trigger tooltip even if the children-node in disabled status */}
      <Box style={{ cursor: 'not-allowed' }}>{children}</Box>
    </Tooltip>
  ) : (
    children
  )
}

ConditionalTooltipWrapper.propTypes = {
  withTooltip: PropTypes.bool,
  children: PropTypes.node,
  tooltipTitle: PropTypes.string,
  tooltipPlacement: PropTypes.string,
  tooltipVisible: PropTypes.bool
}

export default ConditionalTooltipWrapper
