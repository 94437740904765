import { Container } from '@qonsoll/react-design'
import PropTypes from 'prop-types'
import { useGetMenuCategoryCocktails } from '../../hooks'
import { MenuCategoryCocktailsList } from 'domains/MenuCategory/components'

export default function MenuCategoryCocktails(props) {
  const { menuCategory } = props

  /* Getting relationship data */
  // eslint-disable-next-line no-unused-vars
  const [cocktails, loading, error, refresh] =
    useGetMenuCategoryCocktails(menuCategory)

  return (
    <Container>
      <MenuCategoryCocktailsList cw={[12, 12, 12, 6]} cocktails={cocktails} />
    </Container>
  )
}

MenuCategoryCocktails.propTypes = {
  menuCategory: PropTypes.object.isRequired,
  actions: PropTypes.object
}
