import { AddItemCard, ListWrapper } from 'components'
import { Col, Row, Text } from '@qonsoll/react-design'

import { InteractiveMenuSimpleView } from 'domains/InteractiveMenu/components'
import PropTypes from 'prop-types'
import { useTranslations } from 'contexts/Translation'

const InteractiveMenuList = (props) => {
  const {
    interactiveMenus,
    isListWithCreate,
    listView,
    emptyProps,
    actions,
    cw = [12, 6, 4, 4]
  } = props

  /* Getting translations instance */
  const { t } = useTranslations()

  /* Checking if list is empty */
  const isEmpty = !interactiveMenus?.length
  /* Computing item width */
  const itemWidth = listView ? 12 : cw

  return (
    <ListWrapper negotiate="var(--page-height-to-negotiate-with-table-header)">
      <Row negativeBlockMargin pr={2}>
        {isListWithCreate && actions?.onCreate && (
          <Col mb={4} cw={itemWidth}>
            <AddItemCard
              minHeight="98px"
              message={t('Add interactiveMenu')}
              onClick={actions.onCreate}
            />
          </Col>
        )}
        {!isEmpty &&
          interactiveMenus?.map((interactiveMenu, index) => (
            <Col mb={4} key={interactiveMenu?._id || index} cw={itemWidth}>
              <InteractiveMenuSimpleView
                actions={actions}
                index={index}
                interactiveMenu={interactiveMenu}
              />
            </Col>
          ))}
        {isEmpty && (
          <Col cw={12}>
            <Text variant="overline" color="var(--ql-state-disabled-color)">
              {emptyProps?.message ||
                t('No interactive menu has been created yet')}
            </Text>
          </Col>
        )}
      </Row>
    </ListWrapper>
  )
}

InteractiveMenuList.propTypes = {
  interactiveMenus: PropTypes.array,
  isListWithCreate: PropTypes.bool,
  listView: PropTypes.bool,
  emptyProps: PropTypes.object,
  actions: PropTypes.any,
  cw: PropTypes.any
}

export default InteractiveMenuList
