import { useMemo } from 'react'
import { useLoading } from 'hooks'
import { useDocument } from 'services/api/firebase'
import { COLLECTIONS } from '__constants__'
import {} from 'domains/Method/hooks'

const useGetMethodInitialValues = (id) => {
  /* Fetching data using useDocument hook */
  const [method, methodLoading] = useDocument({
    ref: id ? COLLECTIONS.METHODS + '/' + id : null
  })

  /* Merge all fetched data */
  const initialValues = useMemo(
    () => ({
      method
    }),
    [method]
  )

  /* Merge all loading states */
  const loadings = useMemo(
    () => (id ? [!method, methodLoading] : []),
    [id, method, methodLoading]
  )

  /* Getting loading state using merged loadings */
  const loading = useLoading(loadings)

  return [initialValues, loading]
}

export default useGetMethodInitialValues
