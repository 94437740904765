import { useEffect, useState, useCallback } from 'react'
import { getDocument } from 'services/api/firebase'
import { COLLECTIONS } from '__constants__'

/**
 * It fetches a alcoholTasteTypes documents from Firestore and returns it
 * @param cocktail - The cocktail object that we're getting the alcoholTasteTypes for.
 * @returns An array with two values. The first value is the alcoholTasteTypes object, and the second value is a
 * boolean that indicates whether the alcoholTasteTypes is loading.
 */
const useGetCocktailAlcoholTasteTypes = (cocktail) => {
  /* State for data */
  const [alcoholTasteTypes, setAlcoholTasteTypes] = useState([])
  /* State for loading */
  const [loading, setLoading] = useState(!!cocktail?.alcoholTasteTypes?.length)
  /* State for error */
  const [error, setError] = useState(null)

  /* Fetching data */
  const getAlcoholTasteTypes = useCallback(async () => {
    try {
      /* Setting loading state to true */
      setLoading(true)

      /* Getting alcoholTasteTypes promises*/
      const promises = cocktail.alcoholTasteTypes.map((alcoholTasteType) =>
        getDocument(COLLECTIONS.ALCOHOL_TASTE_TYPES, alcoholTasteType)
      )

      /* Getting alcoholTasteTypes data */
      const data = await Promise.all(promises)

      /* Setting alcoholTasteTypes data */
      setAlcoholTasteTypes(data)

      /* Setting loading state to false */
    } catch (error) {
      setError(error)
    }
    setLoading(false)
  }, [cocktail?.alcoholTasteTypes])

  useEffect(() => {
    cocktail?.alcoholTasteTypes?.length && getAlcoholTasteTypes()
  }, [cocktail?.alcoholTasteTypes, getAlcoholTasteTypes])

  return [alcoholTasteTypes, loading, error, getAlcoholTasteTypes]
}

export default useGetCocktailAlcoholTasteTypes
