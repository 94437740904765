import { useEffect, useState, useCallback } from 'react'
import { getDocument } from 'services/api/firebase'
import { COLLECTIONS } from '__constants__'

/**
 * It fetches a orders documents from Firestore and returns it
 * @param restaurant - The restaurant object that we're getting the orders for.
 * @returns An array with two values. The first value is the orders object, and the second value is a
 * boolean that indicates whether the orders is loading.
 */
const useGetRestaurantOrders = (restaurant) => {
  /* State for data */
  const [orders, setOrders] = useState([])
  /* State for loading */
  const [loading, setLoading] = useState(!!restaurant?.orders?.length)
  /* State for error */
  const [error, setError] = useState(null)

  /* Fetching data */
  const getOrders = useCallback(async () => {
    try {
      /* Setting loading state to true */
      setLoading(true)

      /* Getting orders promises*/
      const promises = restaurant.orders.map((order) =>
        getDocument(COLLECTIONS.ORDERS, order)
      )

      /* Getting orders data */
      const data = await Promise.all(promises)

      /* Setting orders data */
      setOrders(data)

      /* Setting loading state to false */
    } catch (error) {
      setError(error)
    }
    setLoading(false)
  }, [restaurant?.orders])

  useEffect(() => {
    restaurant?.orders?.length && getOrders()
  }, [restaurant?.orders, getOrders])

  return [orders, loading, error, getOrders]
}

export default useGetRestaurantOrders
