import { useState } from 'react'

/**
 * It returns an array of two values, the first being a boolean that indicates whether the form is
 * valid or not, and the second being a function that can be used to update the validity of the form
 * @param form - The form object that is returned from the useForm hook.
 * @returns An array with two elements.
 */
const useFormValidity = (form, initial) => {
  const [isValid, setIsValid] = useState(initial ?? true)

  /* On change handler, provide it into <Form /> component */
  const handleChange = () => {
    form
      ?.validateFields()
      .then(() => {
        !isValid && setIsValid(true)
      })
      .catch((errorFields) => {
        isValid && setIsValid(false)
      })
  }

  return [isValid, handleChange]
}

export default useFormValidity
