import { COLLECTIONS, MENU_AVAILABILITIES_KEYS } from '__constants__'
import { Col, Row, Text, Title } from '@qonsoll/react-design'
import { MenuSelect, MenuSimpleView } from 'domains/Menu/components'
import { useEffect, useMemo, useState } from 'react'

import PropTypes from 'prop-types'
import _ from 'lodash'
import { useDocument } from 'services/api/firebase'
import { useGetMenus } from 'domains/Menu/hooks'
import { useParams } from 'react-router-dom'
import { useTranslations } from 'contexts/Translation'

const MenuSelectableList = (props) => {
  const {
    cw,
    emptyProps,
    actions,
    value,
    onChange,
    withTitle = false,
    mode = 'multiple',
    transformationLimit = 1,
    forRestaurant,
    companyData
  } = props

  /* State for selected item in list */
  const [selected, setSelected] = useState()

  /* Getting translations instance */
  const { t } = useTranslations()
  /* Getting history instance */

  const { restaurantId } = useParams()
  const [restaurant] = useDocument({
    ref: COLLECTIONS.RESTAURANTS + '/' + restaurantId
  })

  /* Fetching menus */
  const [allMenus] = useGetMenus()

  // all public + private when there is access to current company
  const menusForCreateRestaurant = useMemo(() => {
    return allMenus?.filter((item) => {
      return (
        item?.privacy === MENU_AVAILABILITIES_KEYS.PUBLIC ||
        item?.privacy === false ||
        ((item?.privacy === MENU_AVAILABILITIES_KEYS.PRIVATE ||
          item?.privacy === true) &&
          item?.company?.includes(companyData?._id))
        //    ||
        // restaurant?.automaticMenuId === item?._id ||
        // companyData?.automaticMenuId === item?._id
      )
    })
  }, [allMenus, companyData?._id])

  const menusForCreateQrCode = useMemo(
    () =>
      allMenus?.filter(
        (item) => restaurant?.menus?.includes(item?._id)
        // ||
        // restaurant?.automaticMenuId === item?._id
      ),
    [allMenus, restaurant?.menus]
  )

  const menus = forRestaurant ? menusForCreateRestaurant : menusForCreateQrCode

  const excludeMenuIds = useMemo(
    () => _.differenceWith(allMenus, menus, _.isEqual)?.map(({ _id }) => _id),
    [allMenus, menus]
  )
  /* Checking if mode is multiple */
  const isMultiple = mode === 'multiple'
  /* Checking if list is empty */
  const isEmpty = !menus?.length
  /* Computing item width */
  const itemWidth = cw || [12, 6, 4, 3]
  /* Checking if multiple select is allowed */
  const isSelectComponent = menus?.length > transformationLimit

  /* Handling selection of the item */
  const handleSelect = (id, selectValue = null) => {
    if (isSelectComponent) {
      setSelected(selectValue)
      onChange?.(selectValue)
    } else {
      const toggleMultipleSelection = (prev = []) =>
        prev.includes(id) ? prev.filter((i) => i !== id) : [...prev, id]
      const toggleSingleSelection = (prev, id) => (prev === id ? null : id)
      setSelected((prev) => {
        const _selected = isMultiple
          ? toggleMultipleSelection(prev)
          : toggleSingleSelection(prev, id)
        onChange?.(_selected)
        return _selected
      })
    }
  }
  /* Checking if the item is selected */
  const checkIsSelected = (id) =>
    isMultiple ? selected?.includes(id) : selected === id

  /* useEffect which helps to initialize the form */
  useEffect(() => {
    if (!selected && value !== undefined) {
      setSelected(value)
    }
  }, [value, selected])

  return (
    <Row mx="var(--ql-grid-gutter-negative)">
      {withTitle && (
        <Col cw={12} mb={2}>
          <Row noGutters>
            <Col>
              <Title level={4}>{t('Menus')}</Title>
            </Col>
            <Col cw="auto">
              <Text>
                {selected?.length || '0'} / {menus?.length}
              </Text>
            </Col>
          </Row>
        </Col>
      )}
      {!isEmpty &&
        !isSelectComponent &&
        menus?.map((menu, index) => (
          <Col mb={4} key={menu?._id || index} cw={itemWidth}>
            <MenuSimpleView
              actions={{ ...actions, onOpen: null }}
              index={index}
              isSelected={checkIsSelected(menu?._id)}
              onSelect={handleSelect}
              menu={menu}
            />
          </Col>
        ))}
      {!isEmpty && isSelectComponent && (
        <Col cw={12}>
          <MenuSelect
            mode="multiple"
            showTitle={false}
            value={selected}
            onChange={handleSelect}
            exclude={excludeMenuIds}
          />
        </Col>
      )}
      {isEmpty && (
        <Col cw={12}>
          <Text variant="overline" color="var(--ql-state-disabled-color)">
            {emptyProps?.message || t('No menus have been created yet')}
          </Text>
        </Col>
      )}
    </Row>
  )
}

MenuSelectableList.propTypes = {
  cw: PropTypes.any,
  emptyProps: PropTypes.object,
  actions: PropTypes.any,
  value: PropTypes.any,
  onChange: PropTypes.func,
  withTitle: PropTypes.bool,
  mode: PropTypes.string,
  transformationLimit: PropTypes.number,
  forRestaurant: PropTypes.bool,
  companyId: PropTypes.string,
  companyData: PropTypes.object
}

export default MenuSelectableList
