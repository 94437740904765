import { CheckOutlined, CloseOutlined } from '@ant-design/icons'
import { Col, Row } from '@qonsoll/react-design'

import { ButtonAccent } from 'components'
import { MenuTypeSimpleForm } from 'domains/MenuType/components'
import PropTypes from 'prop-types'

const MenuTypeForm = (props) => {
  const { menuType, form, handleSave, handleCancel } = props

  return (
    <Row mx="var(--ql-grid-gutter-negative)" v="center">
      <Col mb="calc(var(--ql-form-item-vertical-spacing-without-message) * -1)">
        <MenuTypeSimpleForm
          onSubmit={handleSave}
          form={form}
          initialValues={menuType}
          showTitle={false}
        />
      </Col>
      <Col
        cw={12}
        display="flex"
        flexDirection="row"
        justifyContent="flex-end"
        mt={3}>
        <ButtonAccent
          ghost
          onClick={handleCancel}
          shape="circle"
          type="primary"
          icon={<CloseOutlined />}
        />
        <ButtonAccent
          ml="8px"
          shape="circle"
          type="primary"
          onClick={handleSave}
          icon={<CheckOutlined />}
        />
      </Col>
    </Row>
  )
}

MenuTypeForm.propTypes = {
  form: PropTypes.object,
  handleCancel: PropTypes.func,
  handleSave: PropTypes.func,
  menuType: PropTypes.object
}

export default MenuTypeForm
