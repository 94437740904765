import { ButtonAccent } from 'components'
import { Icon } from '@qonsoll/icons'
import { useConfirmLogout } from 'components/BoilerplateLayout/hooks'
import { useTranslations } from 'contexts/Translation'
import PropTypes from 'prop-types'

const LogoutMenuItem = (props) => {
  const { collapsed } = props

  const { t } = useTranslations()
  const confirmLogout = useConfirmLogout()

  const buttonText = !collapsed && t('Log out')
  const iconPaddingRight = !collapsed && 2

  return (
    <ButtonAccent
      type="secondary"
      block
      danger
      onClick={confirmLogout}
      icon={<Icon name="LogOut4Outlined" pr={iconPaddingRight} />}>
      {buttonText}
    </ButtonAccent>
  )
}

LogoutMenuItem.propTypes = {
  user: PropTypes.shape({
    uid: PropTypes.string,
    photoURL: PropTypes.string,
    displayName: PropTypes.string,
    email: PropTypes.string
  }),
  collapsed: PropTypes.bool,
  setCollapsed: PropTypes.func
}

export default LogoutMenuItem
