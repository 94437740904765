import { deleteDoc, doc, getDoc } from 'firebase/firestore'

import { COLLECTIONS } from '__constants__'
import { firestore } from 'services/firebase'

const deleteInvitationDocument = async (email) => {
  const docRef = doc(firestore, COLLECTIONS.MAILS, email)
  const docData = await getDoc(docRef)
  deleteDoc(docRef)
  return docData.data()
}

export default deleteInvitationDocument
