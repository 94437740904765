import { Card, Col, Container, Row, Text } from '@qonsoll/react-design'
import { useHistory, useParams } from 'react-router-dom'

import { OrderedItemCocktail } from 'domains/OrderedItem/components'
import PropTypes from 'prop-types'
import { StickyContainer } from 'react-sticky'
import { Tabs } from 'antd'
import { useStateWithStorage } from 'hooks'
import { useTranslations } from 'contexts/Translation'

const OrderedItemAdvancedView = (props) => {
  const { orderedItem } = props

  /* State for active tab in AdvancedView with saving it to storage */
  const [activeTab, setActiveTab] = useStateWithStorage(
    null,
    'ordered-item-advanced-view-active-tab'
  )

  /* Getting translations instance */
  const { t } = useTranslations()
  /* Getting history instance from useHistory hook for redirecting */
  const history = useHistory()
  /* Getting params from useParams hook for getting url params */
  const params = useParams()
  /* Getting id from params */
  const { orderedItemId } = params

  /* Getting TabPane component from Tabs */
  const { TabPane } = Tabs

  /* Handling change tab */
  const changeTab = (key) => {
    if (params && orderedItemId) {
      if (key.includes(`orderedItems/${orderedItemId}`)) {
        history.push(key)
      }
      setActiveTab(key)
    }
  }

  return (
    <Container>
      <Row mx="var(--ql-grid-gutter-negative)">
        <Col cw={4} mb={3}>
          <Card>
            <Row noGutters v="center" mb={2}>
              <Col h="left">
                <Text variant="overline" type="secondary">
                  {t('Quantity')}:
                </Text>
              </Col>
              <Col h="right">
                <Text>{orderedItem?.quantity}</Text>
              </Col>
            </Row>
          </Card>
        </Col>
        <Col cw={8}>
          <StickyContainer>
            <Tabs defaultActiveKey={activeTab} onChange={changeTab}>
              <TabPane tab={t('Drink')} key="#drink">
                <OrderedItemCocktail orderedItem={orderedItem} />
              </TabPane>
            </Tabs>
          </StickyContainer>
        </Col>
      </Row>
    </Container>
  )
}

OrderedItemAdvancedView.propTypes = {
  orderedItem: PropTypes.object
}

export default OrderedItemAdvancedView
