import { Col, Container, Row, Title } from '@qonsoll/react-design'

import { CustomerAvatarUploader } from 'domains/Customer/components'
import PropTypes from 'prop-types'
import { useTranslations } from 'contexts/Translation'

const LogoUrlStep = (props) => {
  const { value, setValue, stepNumber } = props

  /* Getting translations instance */
  const { t } = useTranslations()

  /* Handling changes */
  const handleChange = (value) =>
    setValue((prev) => ({ ...prev, logoUrl: value }))

  const titleTextComputed = `${t('Step')} ${stepNumber}. ${t(
    'Please, add logo'
  )}`

  return (
    <Container mb="24px">
      <Title textAlign="center" variant="h4" mb="24px">
        {titleTextComputed}
      </Title>
      <Row noGutters h="center">
        <Col cw="auto">
          <CustomerAvatarUploader
            value={value?.logoUrl}
            onChange={handleChange}
          />
        </Col>
      </Row>
    </Container>
  )
}

LogoUrlStep.propTypes = {
  value: PropTypes.object,
  setValue: PropTypes.func,
  stepNumber: PropTypes.number
}

export default LogoUrlStep
