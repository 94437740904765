import styled, { css } from 'styled-components'

import { Box } from '@qonsoll/react-design'

const CardDropdownWrapper = styled(Box).attrs(({ focusable }) => ({
  tabIndex: focusable ? 0 : -1
}))`
  &:focus-visible {
    ${({ focusable }) =>
      focusable &&
      css`
        outline: 2px solid var(--ql-color-white);
        box-shadow: 0 0 0 4px var(--ql-color-accent2);
        border-radius: var(--card-radius);
      `}
  }
`

export default CardDropdownWrapper
