import { COLLECTIONS, MAIL_TYPES } from '__constants__'
import { collection, doc, getDocs, query, where } from 'firebase/firestore'

import PATHS from 'pages/paths'
import { createDocument } from 'services/api/firebase'
import { firestore } from 'services/firebase'

const sendPasswordResetMail = async ({
  email,
  onError,
  onSuccess,
  onEnd,
  t
}) => {
  try {
    const mailId = doc(collection(firestore, COLLECTIONS.MAILS)).id
    const getUserByEmailQuery = await getDocs(
      query(
        collection(firestore, COLLECTIONS.USERS),
        where('email', '==', email)
      )
    )
    if (getUserByEmailQuery.empty)
      throw new Error('User with such email does not exist')
    const uid = getUserByEmailQuery?.docs?.[0]?.data()?._id
    createDocument(
      COLLECTIONS.MAILS,
      {
        userEmail: email,
        buttonLink: `${process.env.REACT_APP_DEPLOYED_APP_URL}${PATHS.UNAUTHENTICATED.CHANGE_PASSWORD}?email=${email}&uid=${uid}&mailId=${mailId}`,
        subject: 'Pick Your Pour. Change password',
        type: MAIL_TYPES.PASSWORD_RESTORE
      },
      mailId
    )
    onSuccess?.()
  } catch (err) {
    onError(err)
  } finally {
    onEnd?.()
  }
}

export default sendPasswordResetMail
