import {
  createDocument,
  getId,
  updateDocument,
  updateParent
} from 'services/api/firebase'
import { useHistory, useParams } from 'react-router-dom'

import { COLLECTIONS } from '__constants__'
import { QRCode } from 'models'
import { message } from 'antd'
import qrCode from 'qrcode'
import { uploadImage } from 'helpers'
import { useDocument } from 'services/api/firebase'
import { useGetDocumentsByIds } from 'hooks'
import { useGetErrorText } from 'hooks'
import { useState } from 'react'
import { useTranslations } from 'contexts/Translation'

const BUCKET_NAME = 'qr-codes'

const useActionsQRCodeAdvancedForm = ({
  initialData,
  form,
  restaurantId
} = {}) => {
  const { zoneId } = useParams()

  const [zone] = useDocument({
    ref: zoneId ? COLLECTIONS.ZONES + '/' + zoneId : null
  })

  const [qRCodes] = useGetDocumentsByIds({
    collectionName: COLLECTIONS.Q_R_CODES,
    entityIds: zone?.qRCodes
  })

  const [loading, setLoading] = useState(false)
  /* Getting translations instance */
  const { t } = useTranslations()
  /* Getting history instance */
  const history = useHistory()
  /* Getting history state */
  const historyState = history.location.state
  /* Getting fields for update parent data */
  const historyStateParent = historyState?.parent
    ? {
        collection: historyState?.parent?.collection,
        field: historyState?.parent?.field,
        type: historyState?.parent?.type,
        id: historyState?.parent?.id,
        childrenQRCodesIds: historyState?.parent?.childrenQRCodesIds
      }
    : null
  const getErrorText = useGetErrorText()
  /* Function for preparing values for saving */
  const prepareValues = async (values = {}, additionalValues = {}) => {
    /* Getting id */
    const qRCodeId =
      initialData?.['qRCode']?._id || getId(COLLECTIONS.Q_R_CODES)
    /* Preparing qRCode values */
    const preparedValues = {
      _id: qRCodeId,
      table: values?.['table'] ?? null,
      menu: values?.['menu'] ?? null,
      restaurant: restaurantId || null,
      image: values?.['image'] ?? null,
      range: values?.['range'] ?? null,
      isMultipleTable: values?.['isMultipleTable'] ?? null
    }
    return preparedValues
  }

  const uid = () => {
    const head = Date.now().toString(16)
    const tail = Math.random().toString(16).substring(0, 16)
    return head + tail
  }

  const loadImage = (url) => {
    return new Promise((resolve) => {
      const image = new Image(100, 100)
      image.onload = () => resolve(image)
      image.src = url
    })
  }

  const saveBlob = async (data, tableNumber) => {
    const file = new File([data], `qr-code-logo-${uid()}`, { type: data.type })

    const qrCodeUrl = await uploadImage(file, `${BUCKET_NAME}/${uid()}`)
    return { qrCodeUrl, tableNumber }
  }

  const toBase64 = (file) =>
    new Promise((resolve, reject) => {
      const reader = new FileReader()
      reader.readAsDataURL(file)
      reader.onload = () => resolve(reader.result)
      reader.onerror = (error) => reject(error)
    })

  const createQrCodePerOneTable = async ({
    restaurantId,
    menuId,
    tableNumber,
    imageFile
  }) => {
    const menuIds = menuId?.length
      ? menuId?.map((menu) => `&menuId=${menu}`).join('')
      : ''
    const fullUrl = `${process.env.REACT_APP_FIREBASE_CLIENT_DOMAIN}?restaurantId=${restaurantId}${menuIds}&table=${tableNumber}`
    const canvas = document.createElement('canvas')

    canvas.width = '400px'
    canvas.height = '400px'

    const data = await qrCode.toCanvas(canvas, fullUrl, {
      scale: 6,
      quality: 1,
      errorCorrectionLevel: 'H',
      margin: 5,
      color: {
        dark: '#000000',
        light: '#ffffff'
      }
    })

    const ctx = data.getContext('2d')
    const tableNumberString = `# ${tableNumber}`
    ctx.font = '32px Arial'
    ctx.fillText(
      tableNumberString,
      canvas.width / 2 - ctx.measureText(tableNumberString).width / 2,
      25
    )

    if (imageFile) {
      const img = await loadImage(imageFile)
      ctx.drawImage(
        img,
        canvas.width / 2 - img.width / 2,
        canvas.height / 2 - img.height / 2,
        100,
        100
      )
    }

    const blob = await new Promise((resolve) => canvas.toBlob(resolve))

    return saveBlob(blob, tableNumber)
  }

  const createQrCodesAndSaveToDB = async (values) => {
    let imageInBase64
    if (values?.image) {
      imageInBase64 = await toBase64(values?.image)
    }

    if (values?.isMultipleTable) {
      const [min = 0, max = 0] = values?.range || []
      const range = Array(max - min + 1)
        .fill()
        .map((_, idx) => min + idx)

      const promises = range.map((tableNumber) =>
        createQrCodePerOneTable({
          restaurantId: values?.restaurant,
          menuId: values?.menu,
          tableNumber: tableNumber,
          imageFile: imageInBase64
        })
      )

      const qrCodeUrls = await Promise.all(promises)

      delete values?.image
      delete values?.range
      delete values?.table

      const createDocumentPromises = qrCodeUrls.map((urlObj) =>
        createDocument(
          COLLECTIONS.Q_R_CODES,
          {
            ...values,
            qrCodeUrl: urlObj.qrCodeUrl,
            table: urlObj.tableNumber
          },
          getId(COLLECTIONS.Q_R_CODES)
        )
      )

      /* Update parent with new qRCodeIds */
      const qRCodesArray = await Promise.all(createDocumentPromises)
      const qRCodesIds = qRCodesArray?.map((qRCode) => qRCode?._id)
      const newChildren = [
        ...(historyStateParent?.childrenQRCodesIds || []),
        ...qRCodesIds
      ]

      await updateDocument(
        historyStateParent?.collection,
        historyStateParent?.id,
        {
          [historyStateParent?.field]: newChildren
        }
      )

      return Promise.all(createDocumentPromises)
    }

    const qrCodeUrl = await createQrCodePerOneTable({
      restaurantId: values?.restaurant,
      menuId: values?.menu,
      tableNumber: values?.table,
      imageFile: imageInBase64
    })

    delete values?.image
    delete values?.range

    return createDocument(
      COLLECTIONS.Q_R_CODES,
      { ...values, qrCodeUrl: qrCodeUrl.qrCodeUrl },
      values._id
    )
  }

  /* Saving form data */
  const saveForm = async (values, callback) => {
    try {
      // Prepare data to be saved
      const data = await prepareValues(values)

      if (!data?.isMultipleTable && historyStateParent) {
        await updateParent(historyStateParent, data?._id)
      }

      // Update or save data
      if (initialData) {
        await updateDocument(COLLECTIONS.Q_R_CODES, initialData?.qRCode?._id, {
          menu: data?.menu,
          restaurant: data?.restaurant,
          table: data?.table
        })
        message.success(t('QRCode successfully updated'))
      } else {
        await createQrCodesAndSaveToDB(data)

        message.success(t('QRCode successfully created'))
      }

      // Final callback
      callback?.()
    } catch (error) {
      throw new Error(getErrorText(error))
    }
  }
  /* Function for validating form */
  const validateForm = (values) => {
    const isExistQrCode = qRCodes
      ?.map((value) => value?.table)
      ?.includes(values?.table)

    try {
      if (isExistQrCode) {
        throw new Error(t('QRCode for this table is already exist!'))
      } else {
        // Prepare data to be validated
        const validationData = {
          table: values?.table,
          source: values?.['qRCode']?.['source']
        }

        QRCode.validationSchema.validateSync(validationData)
      }
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error(error)
      const errorMessage = isExistQrCode
        ? t('QRCode for this table is already exist!')
        : t('QRCode validation error: ') + t(error.message)
      throw new Error(errorMessage)
    }
  }

  /* On finish callback */
  const onFinish = async (_, { isMultipleTable, range }) => {
    if (loading) return // Avoid multiple calls

    try {
      setLoading(true)

      // Get form values
      const formValues = form.getFieldsValue()

      // // Validate fields
      validateForm(formValues)
      // Final callback
      const callback = () => history.goBack()

      // Save data
      await saveForm({ ...formValues, isMultipleTable, range }, callback)

      setLoading(false)
    } catch (error) {
      setLoading(false)
      message.error(error.message)
    }
  }

  /* On cancel callback */
  const onReset = () => {
    form.resetFields()
    history.goBack()
  }

  return { onFinish, onReset, loading, saveForm, validateForm, prepareValues }
}
export default useActionsQRCodeAdvancedForm
