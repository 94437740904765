import { Col, Row, Box, Text, Card, Divider } from '@qonsoll/react-design'
import PropTypes from 'prop-types'
import { Empty } from 'antd'

import { useTranslations } from 'contexts/Translation'
import { ListWrapper } from 'components'

const InteractiveMenuQuestionList = (props) => {
  const { interactiveMenuQuestions } = props
  /* Getting translations instance */
  const { t, language } = useTranslations()
  /* Checking length */
  const isNotEmptyQuestions = !!interactiveMenuQuestions?.length

  const translatedText = (text) => text?.[language] ?? text?.en ?? text

  return isNotEmptyQuestions ? (
    <ListWrapper negotiate="var(--page-height-to-negotiate-with-table-header)">
      {interactiveMenuQuestions?.map((questionItem, index) => (
        <Card
          key={questionItem?._id}
          mb={interactiveMenuQuestions?.length === index ? 0 : 2}>
          <Row noGutters v="center" h="center">
            <Col cw={8}>
              <Text type="secondary" mr={1} variant="body1">
                {questionItem?.text}
              </Text>
            </Col>
            <Divider type="vertical" height="26px" />
            <Col>
              <Text style={{ wordBreak: 'break-all' }} variant="body1">
                {translatedText(questionItem?.optionSourceData?.label)}
              </Text>
            </Col>
          </Row>
        </Card>
      ))}
    </ListWrapper>
  ) : (
    <Box mt={2}>
      <Empty
        description={<Text>{t('No questions have been created yet')}</Text>}
      />
    </Box>
  )
}
InteractiveMenuQuestionList.propTypes = {
  interactiveMenuQuestions: PropTypes.array
}

export default InteractiveMenuQuestionList
