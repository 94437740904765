import { useMemo } from 'react'
import { generatePath, useHistory } from 'react-router-dom'
import { message } from 'antd'
import { deleteDocument } from 'services/api/firebase/helpers'
import { useEvent } from 'hooks'
import { useTranslations } from 'contexts/Translation'
import PATHS from 'pages/paths'
import { COLLECTIONS } from '__constants__'

const createPath = PATHS.AUTHENTICATED.INTERACTIVE_MENU_CREATE
const editPath = PATHS.AUTHENTICATED.INTERACTIVE_MENU_EDIT
const showPath = PATHS.AUTHENTICATED.INTERACTIVE_MENU_SHOW

const useInteractiveMenuBaseActions = ({
  interactiveMenu,
  interactiveMenuId,
  actions
}) => {
  /* Getting translations instance */
  const { t } = useTranslations()
  /* Getting history instance */
  const history = useHistory()

  /* Function to handle back click */
  const handleBack = useEvent(() => history.goBack())

  /* Handling opening of the create page */
  const handleCreate = useEvent(() => {
    history.push(createPath)
  })

  /* Handling opening of the show page */
  const handleOpen = useEvent(() => {
    const pathParams = { interactiveMenuId }
    const path = generatePath(showPath, pathParams)
    history.push(path)
  })

  /* Handling opening of the edit page or change view to edit mode */
  const handleEdit = useEvent(() => {
    const pathParams = { interactiveMenuId }
    const path = generatePath(editPath, pathParams)
    history.push(path)
  })

  const isDeletionDisabled = useMemo(
    () =>
      interactiveMenuId ===
      interactiveMenu?.itemTypeData?.defaultInteractiveMenu,
    [interactiveMenu?.itemTypeData?.defaultInteractiveMenu, interactiveMenuId]
  )

  // TODO: function is not implemented, it will be done in a few days
  const handleDelete = useEvent(async () => {
    try {
      if (isDeletionDisabled) {
        message.error(t('You cannot delete the default interactive menu'))
      } else {
        const { interactiveMenuQuestions } = interactiveMenu || {}

        const questionsDeletePromises = Array.isArray(interactiveMenuQuestions)
          ? interactiveMenuQuestions.map(({ _id }) =>
              deleteDocument(COLLECTIONS.INTERACTIVE_MENU_QUESTIONS, _id)
            )
          : []

        await Promise.all(questionsDeletePromises)

        await deleteDocument(COLLECTIONS.INTERACTIVE_MENUS, interactiveMenuId)

        message.success(t('Interactive menu was successfully deleted'))

        history.goBack()
      }
    } catch (error) {
      message.error(
        `${t('Error while deleting the interactive menu')}: ${error?.message}`
      )
    }
  })

  return useMemo(
    () => ({
      onBack: handleBack,
      onCreate: handleCreate,
      onOpen: handleOpen,
      onEdit: handleEdit,
      onDelete: handleDelete,
      ...(actions || {}),
      createPath,
      editPath,
      showPath,
      isDeletionDisabled
    }),
    [
      actions,
      handleBack,
      handleCreate,
      handleDelete,
      handleEdit,
      handleOpen,
      isDeletionDisabled
    ]
  )
}

export default useInteractiveMenuBaseActions
