import { COCKTAIL_AVAILABILITIES_KEYS } from '__constants__'
import { uploadImage } from 'helpers'
import { useActionsRecipeSimpleForm } from 'domains/Recipe/components/RecipeSimpleForm/hooks'
import { useState } from 'react'
import { message } from 'antd'
import { useTranslations } from 'contexts/Translation'
import { useParams } from 'react-router-dom'
import { useDragAndDrop } from 'domains/Menu/contexts'

const useCocktailsTableActions = ({
  value,
  onChange,
  onSelectCocktailsCallback,
  onCreateCocktailCallback,
  onCocktailDeleteCallback,
  onCocktailEditCallback,
  categoryId
}) => {
  const { t } = useTranslations()

  const [isAdding, setIsAdding] = useState(false)
  const [categoryDrawerVisibility, setCategoryDrawerVisibility] =
    useState(false)

  const [editedMenuItemInitialData, setEditedMenuItemInitialData] =
    useState(null)

  const params = useParams()

  const { dndConfig } = useDragAndDrop()

  const { menuId } = params

  const { saveRecipes } = useActionsRecipeSimpleForm(
    editedMenuItemInitialData?.recipes
  )

  const toggleAdd = () => setIsAdding((prev) => !prev)
  const onExistingCocktailsAdded = (values) => {
    if (onChange) onChange?.([...value, ...values])
    else if (onSelectCocktailsCallback) onSelectCocktailsCallback(values)
  }
  const toggleCategoryForm = () => setCategoryDrawerVisibility((prev) => !prev)

  const onNewCocktailCreated = async (cocktailData) => {
    const {
      cocktail,
      methods,
      flavor,
      glassType,
      alcoholTasteTypes,
      allergens,
      decorationType,
      specialComment,
      mainSpiritType,
      availability
    } = cocktailData
    const [recipeIds] = await saveRecipes(cocktailData?.recipes)

    const cocktailDatabaseObject = {
      ...cocktail,
      availability: availability ?? COCKTAIL_AVAILABILITIES_KEYS.NO,
      imageUrl: (await uploadImage(cocktail?.['imageUrl'])) ?? null,
      title: cocktail?.['title']?.trim() ?? null,
      price: cocktail?.['price'] ?? 0,
      methods: methods ?? [],
      recipes: recipeIds ?? null,
      flavor: flavor ?? [],
      glassType: glassType ?? null,
      alcoholTasteTypes: alcoholTasteTypes ?? null,
      allergens: allergens ?? [],
      decorationType: decorationType?.trim() ?? null,
      specialComment: specialComment?.trim() ?? null,
      mainSpiritType: mainSpiritType ?? null
    }

    if (onChange) onChange?.([...value, cocktailDatabaseObject])
    else if (onCreateCocktailCallback)
      onCreateCocktailCallback(cocktailDatabaseObject)
  }

  const onCocktailDelete = ({ cocktailId, index }) => {
    if (onChange) onChange(value?.filter((_, ind) => ind !== index))
    else onCocktailDeleteCallback?.(cocktailId)
  }

  const onResetInitialData = () =>
    editedMenuItemInitialData && setEditedMenuItemInitialData(null)

  const onEditSaveClick = async (values) => {
    const [recipeIds] = await saveRecipes(values?.recipes)
    const data = {
      imageUrl: (await uploadImage(values?.['cocktail']?.['imageUrl'])) ?? null,
      title: values?.['cocktail']?.['title'] ?? null,
      allergens: values['allergens'] ?? [],
      methods: values['methods'] ?? [],
      alcoholTasteTypes: values['alcoholTasteTypes'] ?? [],
      mainSpiritType: values['mainSpiritType'] ?? [],
      flavor: values['flavor'] ?? [],
      glassType: values['glassType'] ?? null,
      decorationType: values['decorationType'] ?? null,
      specialComment: values['specialComment'] ?? null,
      recipes: recipeIds ?? null
    }
    if (editedMenuItemInitialData?.['cocktail']?._id) {
      data._id = editedMenuItemInitialData?.['cocktail']?._id
    }
    if (onChange) {
      onChange(
        value.map((item, index) =>
          index === editedMenuItemInitialData?.index
            ? { ...item, ...data }
            : item
        )
      )
    } else if (onCocktailEditCallback) {
      onCocktailEditCallback(data, editedMenuItemInitialData?.index)
    }
    onResetInitialData()
  }

  // TODO: check if this work with all cocktails
  const onEditClick = async (_id) => {
    const menuItem = dndConfig?.[categoryId]?.find((item) => item?._id === _id)

    const menuRewritesObject = menuItem?.rewrites?.[menuId]

    setEditedMenuItemInitialData({
      ...menuItem,
      ...menuRewritesObject,
      onlyMenuRewrite: true
    })
    toggleAdd()
  }

  const onEditCocktailPrice = (data) => {
    const { price, ...cocktail } = value[data?.index]
    const cocktailData = {
      ...cocktail,
      price: Number(data?.price)
    }

    if (onChange) {
      onChange(
        value.map((item, index) =>
          index === data?.index ? { ...item, ...cocktailData } : item
        )
      )
    } else if (onCocktailEditCallback) {
      onCocktailEditCallback(cocktailData, data?.index)
    }
  }

  const onEditCocktailAvailability = (data) => {
    const { availability, ...cocktail } = value[data?.index]
    const cocktailData = {
      ...cocktail,
      availability: data?.availability
    }

    if (onChange) {
      onChange(
        value.map((item, index) =>
          index === data?.index ? { ...item, ...cocktailData } : item
        )
      )
    } else if (onCocktailEditCallback) {
      onCocktailEditCallback(cocktailData, data?.index)
    }
  }

  const categoryUpdateInNewMenu = (newCategory) => {
    try {
      const newName = newCategory?.name
      const objIndex = newCategory?.index

      const valueCopy = [...value]
      valueCopy[objIndex].name = newName
      onChange(valueCopy)
    } catch (err) {
      message.error(t('Error during submenu update'))
    }
  }

  const categoryDeleteInNewMenu = (index) => {
    try {
      const valueCopy = [...value]
      valueCopy.splice(index, 1)
      onChange(valueCopy)
    } catch (err) {
      // eslint-disable-next-line
          console.error('ERROR during submenu delete:', err)
      message.error(t('Error during submenu delete'))
    }
  }

  return {
    isAdding,
    categoryDrawerVisibility,
    editedMenuItemInitialData,
    toggleAdd,
    toggleCategoryForm,
    onExistingCocktailsAdded,
    onNewCocktailCreated,
    onCocktailDelete,
    onEditClick,
    onEditSaveClick,
    onResetInitialData,
    onEditCocktailPrice,
    categoryUpdateInNewMenu,
    categoryDeleteInNewMenu,
    onEditCocktailAvailability
  }
}

export default useCocktailsTableActions
