import {
  useActionsAlcoholTasteTypeAdvancedForm,
  useInitializeAlcoholTasteTypeAdvancedForm
} from './hooks'

import { AlcoholTasteTypeSimpleForm } from 'domains/AlcoholTasteType/components'
import { ButtonAccent } from 'components'
import { Form } from 'antd'
import { KEYS } from '__constants__'
import PropTypes from 'prop-types'
import { Title } from '@qonsoll/react-design'
import { useTranslations } from 'contexts/Translation'

const AlcoholTasteTypeAdvancedForm = (props) => {
  const { initialData, group, showTitle } = props

  /* Getting translations instance */
  const { t } = useTranslations()
  /* Getting form instance from hook */
  const { form } = useInitializeAlcoholTasteTypeAdvancedForm(initialData)

  /* Handling enter key press */
  const handleKeyPress = (e) => {
    if (e.key === KEYS.ENTER) {
      e.preventDefault()
      onFinish()
    }
  }

  /* Getting loading state and actions from hook */
  const { loading, onFinish, onReset } = useActionsAlcoholTasteTypeAdvancedForm(
    {
      initialData,
      form
    }
  )

  return group ? (
    <>
      {showTitle && (
        <Title level={4} mb={2}>
          {t('Alcohol taste type')}
        </Title>
      )}
      <AlcoholTasteTypeSimpleForm
        showTitle={false}
        group={[...group, 'alcoholTasteType']}
      />
    </>
  ) : (
    <Form
      scrollToFirstError
      onKeyPress={handleKeyPress}
      onFinish={onFinish}
      layout="vertical"
      autoComplete="off"
      form={form}>
      {showTitle && (
        <Title level={4} mb={2}>
          {t('Alcohol taste type')}
        </Title>
      )}
      <AlcoholTasteTypeSimpleForm group={['alcoholTasteType']} />
      <ButtonAccent
        type="secondary"
        mr={2}
        htmlType="button"
        onClick={onReset}
        mb={4}>
        {t('Cancel')}
      </ButtonAccent>
      <ButtonAccent
        type="primary"
        loading={loading}
        onClick={() => form.submit()}>
        {t('Save')}
      </ButtonAccent>
    </Form>
  )
}
AlcoholTasteTypeAdvancedForm.propTypes = {
  initialData: PropTypes.object,
  group: PropTypes.array,
  showTitle: PropTypes.bool
}

export default AlcoholTasteTypeAdvancedForm
