import { Form } from 'antd'

import styled from 'styled-components'

export const FormStyled = styled(Form.Item)`
  margin: 0 !important;
  padding: 0 !important;

  .ant-form-item,
  .ant-form-item-explain,
  .ant-form-item-explain-success {
    margin: 0 !important;
    padding: 0 !important;
    min-height: 0 !important;
  }
`
