import { useEffect, useState } from 'react'

import PropTypes from 'prop-types'
import UserContext from './UserContext'
import firebase from 'firebase/compat/app'
import { getDocument } from 'services/api/firebase'
import { useAuthState } from 'react-firebase-hooks/auth'

const UserProvider = ({ children }) => {
  const [currentUser, setCurrentUser] = useState(null)

  const [user] = useAuthState(firebase.auth())
  const userId = user?.uid

  useEffect(() => {
    const fetchData = async () => {
      const userData = await getDocument('users', userId)
      setCurrentUser(userData || null)
    }
    fetchData()
  }, [userId])

  return (
    <UserContext.Provider value={{ user: currentUser, userId }}>
      {children}
    </UserContext.Provider>
  )
}

UserProvider.propTypes = {
  children: PropTypes.element.isRequired
}

export default UserProvider
