import { Input, TextArea } from '@qonsoll/react-design'
import { KEYS, MAX_LENGTH_DESCRIPTION, MAX_LENGTH_FIELD } from '__constants__'

import { ButtonAccent } from 'components'
import { Form } from 'antd'
import PropTypes from 'prop-types'
import { onTextAreaEnterPress } from 'helpers'
import { useTranslations } from 'contexts/Translation'
import { useValidateInput } from 'hooks'

const MenuSimpleForm = (props) => {
  const { initialValues, onSubmit, onCancel, form, group, selectedLanguage } =
    props

  /* Getting translations instance */
  const { t } = useTranslations()
  /* Getting new form instance from Form.useForm hook */
  const [baseForm] = Form.useForm()
  const validateInput = useValidateInput()

  const usedForm = form || baseForm

  const inputAddon = selectedLanguage?.toUpperCase()

  /* Resetting form on cancel */
  const handleCancel = () => {
    usedForm.resetFields()
    onCancel?.()
  }
  /* Submitting form on enter key press */
  const handleKeyPress = (e) => {
    if (e.key === KEYS.ENTER) {
      e.preventDefault()
      e.stopPropagation()
      usedForm?.submit()
    }
  }

  return group ? (
    <>
      <Form.Item
        label={t('Name')}
        name={[...group, 'name', selectedLanguage]}
        rules={[
          {
            required: true,
            message: t('Please, enter your menu name!')
          },
          { max: MAX_LENGTH_FIELD, message: t('Menu name is too long!') },
          { validator: validateInput }
        ]}>
        <Input
          addonAfter={inputAddon}
          onPressEnter={onTextAreaEnterPress}
          placeholder={t('Please, enter your menu name')}
        />
      </Form.Item>

      <Form.Item
        label={t('Description')}
        name={[...group, 'description']}
        rules={[
          {
            max: MAX_LENGTH_DESCRIPTION,
            message: t('Menu description is too long!')
          }
        ]}>
        <TextArea
          onPressEnter={onTextAreaEnterPress}
          showCount
          maxLength={140}
          autoSize={{ minRows: 3, maxRows: 3 }}
          placeholder={`${t(
            'Enter some explanatory text for you or other staff'
          )}...`}
        />
      </Form.Item>
    </>
  ) : (
    <Form
      preserve
      scrollToFirstError
      form={usedForm}
      layout="vertical"
      autoComplete="off"
      onFinish={onSubmit}
      onKeyPress={handleKeyPress}
      initialValues={initialValues}>
      <Form.Item
        label={t('Name')}
        name="title"
        rules={[
          {
            required: true,
            message: t('Please, enter your menu name!')
          },
          { max: MAX_LENGTH_FIELD, message: t('Menu name is too long!') },
          { validator: validateInput }
        ]}>
        <Input
          onPressEnter={onTextAreaEnterPress}
          placeholder={t('Please, enter your menu name')}
        />
      </Form.Item>
      <Form.Item
        label={t('Label')}
        name={['label', selectedLanguage]}
        rules={[
          {
            required: true,
            message: t('Please, enter menu label!')
          },
          { validator: validateInput }
        ]}>
        <Input
          addonAfter={inputAddon}
          onPressEnter={onTextAreaEnterPress}
          placeholder={t('Please, enter menu label')}
        />
      </Form.Item>

      <Form.Item
        label={t('Description')}
        name="description"
        rules={[
          {
            max: MAX_LENGTH_DESCRIPTION,
            message: t('menu description is too long!')
          }
        ]}>
        <TextArea
          onPressEnter={onTextAreaEnterPress}
          showCount
          maxLength={140}
          autoSize={{ minRows: 3, maxRows: 3 }}
          placeholder={`${t(
            'Enter some explanatory text for you or other staff'
          )}...`}
        />
      </Form.Item>

      {!form && (
        <>
          <ButtonAccent
            mr={2}
            htmlType="button"
            onClick={handleCancel}
            type="secondary">
            {t('Cancel')}
          </ButtonAccent>
          <ButtonAccent type="primary" onClick={() => usedForm.submit()}>
            {t('Save')}
          </ButtonAccent>
        </>
      )}
    </Form>
  )
}

MenuSimpleForm.propTypes = {
  initialValues: PropTypes.object,
  onSubmit: PropTypes.func,
  onCancel: PropTypes.func,
  form: PropTypes.any,
  group: PropTypes.array,
  selectedLanguage: PropTypes.string
}

export default MenuSimpleForm
