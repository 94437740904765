import * as yup from 'yup'

import { attr, model } from 'services/model'

const validationSchema = yup.object().shape({
  firstName: yup
    .string()
    .typeError('Field firstName has wrong type')
    .required('Field firstName is required'),
  lastName: yup
    .string()
    .typeError('Field lastName has wrong type')
    .required('Field lastName is required'),
  email: yup
    .string()
    .typeError('Field email has wrong type')
    .required('Field email is required'),
  phone: yup.string().required('Field phone is required')
})

const User = model(
  'user',
  {
    firstName: attr('string'),
    lastName: attr('string'),
    email: attr('string'),
    phone: attr('string')
  },
  validationSchema
)

export default User
