const useUpdateItemTypeInstanceWithObjectValue = ({
  parentFormValue,
  parentFormOnChange
}) => {
  // This fn modifies parentForm value(ItemTypeInstance) with new object data
  const updateItemTypeInstanceWithObjectValue = (
    objectName,
    objectData,
    objectInstanceId,
    isDelete
  ) => {
    let copyValue = [...parentFormValue]
    const objectToChange = parentFormValue?.find(
      (item) => item?.fieldName === objectName
    )
    const indexOfObject = parentFormValue?.indexOf(objectToChange)
    if (isDelete) {
      copyValue[indexOfObject][objectName] = copyValue[indexOfObject][
        objectName
      ]?.filter((item) => item?._id !== objectInstanceId)
    } else {
      copyValue[indexOfObject][objectName] = objectData
    }

    parentFormOnChange(copyValue)
  }

  return updateItemTypeInstanceWithObjectValue
}

export default useUpdateItemTypeInstanceWithObjectValue
