import { collection, query } from 'firebase/firestore'

import { COLLECTIONS } from '__constants__'
import { firestore } from 'services/firebase'
import { useCollectionDataOnce } from 'react-firebase-hooks/firestore'
import { useFetchCountries } from 'hooks'

const useAutoGenerateLicenseNumber = () => {
  const { listCountries } = useFetchCountries()
  const [restaurants] = useCollectionDataOnce(
    query(collection(firestore, COLLECTIONS.RESTAURANTS))
  )

  const autoGenerateLicenseNumber = (values) => {
    const typeLicenseValue =
      values?.['customer']?.['typeOfLicense'] ||
      values?.['restaurant']?.['typeOfLicense']

    const billingTypeValue =
      values?.['customer']?.['billingType'] ||
      values?.['restaurant']?.['billingType']

    const countryValue =
      values?.['customer']?.['country'] || values?.['restaurant']?.['country']

    const countryIndex =
      listCountries?.findIndex(
        (country) => country?.internationalName === countryValue
      ) + 1
    const typeLicense = typeLicenseValue === 'Standard' ? '1' : '0'

    const billingType = billingTypeValue === 'MONTHLY' ? '0' : '1'

    const corporateLicenseNumber = typeLicenseValue === 'Corporation' ? '1' : ''

    const typeLicenseComputed =
      corporateLicenseNumber === '1' ? '0' : typeLicense

    let countryNumber = '000'

    if (countryIndex.toString().length === 1) {
      countryNumber = countryIndex?.toString().padStart(3, '0')
    } else if (countryIndex?.toString().length === 2) {
      countryNumber = countryIndex?.toString().padStart(3, '0')
    } else countryNumber = countryIndex

    let restaurantNumber = '0'

    if (restaurants?.length?.toString().length === 1) {
      restaurantNumber = restaurants?.length?.toString().padStart(5, '0')
    } else if (restaurants?.length?.toString().length === 2) {
      restaurantNumber = restaurants?.length.toString().padStart(4, '0')
    } else if (restaurants?.length?.toString().length === 3) {
      restaurantNumber = restaurants?.length?.toString().padStart(3, '0')
    } else restaurantNumber = restaurants?.length

    return `${countryNumber}${typeLicenseComputed}${billingType}${restaurantNumber}${corporateLicenseNumber}`
  }

  return { autoGenerateLicenseNumber }
}

export default useAutoGenerateLicenseNumber
