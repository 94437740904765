import { Col, PageWrapper, Row } from '@qonsoll/react-design'
import { useHistory, useParams } from 'react-router-dom'
import { doc } from 'firebase/firestore'
import { firestore } from 'services/firebase'
import { updateDocument } from 'services/api/firebase'
import { useDocumentData } from 'react-firebase-hooks/firestore'

import { useTranslations } from 'contexts/Translation'

import { COLLECTIONS, ORDER_STATUSES } from '__constants__'
import { HeaderBreadcrumbs, Spinner, ButtonAccent } from 'components'

import { OrderAdvancedView } from 'domains/Order/components'
import { useCreatePayment } from 'domains/Payment/hooks'

const OrderShowPage = (props) => {
  /* Getting translations instance */
  const { t } = useTranslations()
  /* Getting history instance */
  const history = useHistory()
  /* Getting params from router */
  const { orderId } = useParams()
  /* Fetching document data from useDocument hook */
  const [order, loading] = useDocumentData(
    orderId && doc(firestore, COLLECTIONS.ORDERS + '/' + orderId)
  )
  const [restaurant, restaurantLoading] = useDocumentData(
    order?.restaurantId &&
      doc(firestore, COLLECTIONS.RESTAURANTS + '/' + order?.restaurantId)
  )

  const orderTitleComputed = `${t('Order')} #${order?.orderNumber}`

  // TODO add currency fetch for restaurant
  const createPayment = useCreatePayment({
    restaurantId: order?.restaurantId,
    restaurantName: restaurant?.name,
    currency: restaurant?.currency
  })

  /* Configuring page header */
  const headingProps = {
    title: orderTitleComputed,
    marginBottom: 3,
    textAlign: 'left'
  }
  /* Function to handle back click */
  const handleBack = () => history.goBack()
  /* Handling opening of the edit page */

  const onFinishOrder = async () => {
    const orderUpdateObject = {
      orderStatus: ORDER_STATUSES.DONE
    }
    if (order?.phoneNumber) {
      const paymentId = await createPayment(
        order?.paymentOptions,
        order?.userEmail,
        order?.totalPrice,
        order?.phoneNumber
      )
      orderUpdateObject.paymentId = paymentId
    }
    await updateDocument(COLLECTIONS.ORDERS, order?._id, orderUpdateObject)
    history.goBack()
  }

  const buttonVisible =
    order?.orderStatus === ORDER_STATUSES.DONE ? 'none' : 'block'

  return (
    <PageWrapper
      action={
        <ButtonAccent display={buttonVisible} onClick={onFinishOrder}>
          {t('Done')}
        </ButtonAccent>
      }
      onBack={handleBack}
      breadcrumbs={<HeaderBreadcrumbs />}
      headingProps={headingProps}>
      <Row noGutters>
        {loading || restaurantLoading ? (
          <Col>
            <Spinner height="70vh" text={t('Order loading')} />
          </Col>
        ) : (
          <Col cw={12}>
            <OrderAdvancedView currency={restaurant?.currency} order={order} />
          </Col>
        )}
      </Row>
    </PageWrapper>
  )
}

export default OrderShowPage
