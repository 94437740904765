import { useHistory } from 'react-router-dom'
import { useState } from 'react'
import { message } from 'antd'
import {
  arrayUnion,
  collection,
  getDocs,
  query,
  where
} from 'firebase/firestore'
import {
  createDocument,
  deleteDocument,
  updateDocument
} from 'services/api/firebase'
import { firestore } from 'services/firebase'

import PATHS from 'pages/paths'
import { useTranslations } from 'contexts/Translation'
import { COLLECTIONS, MAIL_TYPES, USER_ROLES_KEYS } from '__constants__'

const useCustomerAdminInviteFormActions = (form, customerId, users) => {
  /* State for loading */
  const [loading, setLoading] = useState(false)
  /* Getting translations instance */
  const { t } = useTranslations()
  /* Getting history instance */
  const history = useHistory()

  /* Function for preparing values for saving */
  const prepareValues = (values = {}, additionalValues = {}) => {
    /* Preparing mail values */
    const preparedValues = {
      _id: values?.['email'],
      userEmail: values?.['email'],
      buttonLink: `${process.env.REACT_APP_DEPLOYED_APP_URL}${PATHS.UNAUTHENTICATED.SIGNUP_WITH_EMAIL}?email=${values?.['email']}`,
      type: MAIL_TYPES.COMPANY_ADMIN_INVITATION,
      customerId: customerId,
      ...additionalValues
    }

    return preparedValues
  }

  /* Function for validating form */
  const validateForm = async (values, callback) => {
    const data = prepareValues(values)

    try {
      const computedQuery = query(
        collection(firestore, COLLECTIONS.USERS),
        where('email', '==', values?.['email'])
      )

      const querySnapshot = await getDocs(computedQuery)
      const response = querySnapshot?.docs?.map((item) => item.data())

      const userId = response[0]?._id
      const customerCompanies = response[0]?._companies
      const isUserAlreadyAdmin = await customerCompanies?.includes(customerId)

      const userCompanies = { ...(response[0]?.companyRoles || {}) }
      if (users?.length) {
        userCompanies[customerId] = USER_ROLES_KEYS.COMPANY_ADMIN
      } else {
        userCompanies[customerId] = USER_ROLES_KEYS.COMPANY_OWNER
      }

      if (isUserAlreadyAdmin) {
        throw new Error(
          t('This user is already admin for this company try another email')
        )
      } else if (!response.length) {
        await createDocument(COLLECTIONS.MAILS, data, data?._id)
        message.success(t('Invitation successfully sent'))
      } else {
        await updateDocument(COLLECTIONS.USERS, userId, {
          _companies: arrayUnion(customerId),
          currentCompanyId: customerId,
          companyRoles: userCompanies
        })
        message.success(t('Admin successfully invited'))
      }

      const existingMails = await getDocs(
        query(
          collection(firestore, COLLECTIONS.MAILS),
          where('_id', '==', values?.['email'])
        )
      )

      if (existingMails.empty)
        await deleteDocument(COLLECTIONS.MAILS, values?.['email'])
      callback?.(data)
    } catch (error) {
      throw new Error(`${t('Something went wrong during data save')} ${error}`)
    } finally {
      setLoading(false)
    }
  }

  /* On finish callback */
  const onFinish = async () => {
    if (loading) return // Avoid multiple calls

    try {
      setLoading(true)
      // Get form values
      const formValues = form.getFieldsValue()
      // Final callback
      const callback = () => onReset()
      await validateForm(formValues, callback)
    } catch (error) {
      setLoading(false)
      message.error(error.message)
    }
  }

  /* On cancel callback */
  const onReset = () => {
    form.resetFields()
    history.goBack()
  }

  return {
    onFinish,
    onReset,
    loading,

    validateForm,
    prepareValues
  }
}

export default useCustomerAdminInviteFormActions
