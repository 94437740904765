import { collection, getDocs, query, where } from 'firebase/firestore'
import { useEffect, useState } from 'react'

import { COLLECTIONS } from '__constants__'
import { firestore } from 'services/firebase'

const useCheckInvitedOrActiveAdmin = (customerId) => {
  const [isAdminInvitedOrActive, setIsAdminInvitedOrActive] = useState(false)
  const [loading, setLoading] = useState(true)

  useEffect(() => {
    const fetchData = async () => {
      try {
        setLoading(true)
        const fetchPromises = await Promise.allSettled([
          getDocs(
            query(
              collection(firestore, COLLECTIONS.USERS),
              where('_context.customerId', '==', customerId)
            )
          ),
          getDocs(
            query(
              collection(firestore, COLLECTIONS.MAILS),
              where('customerId', '==', customerId)
            )
          )
        ])

        const adminInvited = !fetchPromises?.[1]?.value?.empty
          ? 'Invited'
          : null
        const adminActive = !fetchPromises?.[0]?.value?.empty ? 'Active' : null

        setIsAdminInvitedOrActive(adminActive || adminInvited)
      } catch (err) {
        //eslint-disable-next-line
        console.error(
          'Error during customer admin or invited admin fetch:',
          err
        )
      } finally {
        setLoading(false)
      }
    }
    customerId && fetchData()
  }, [customerId])

  return [isAdminInvitedOrActive, loading]
}

export default useCheckInvitedOrActiveAdmin
