import { COLLECTIONS } from '__constants__'
import { doc } from 'firebase/firestore'
import { useLoading, useGetDocumentsByIds } from 'hooks'
import { firestore } from 'services/firebase'
import { useDocumentData } from 'react-firebase-hooks/firestore'
import { useMemo } from 'react'

const useGetItemTypeInitialValues = (itemTypeId) => {
  const [itemType, itemTypeLoading] = useDocumentData(
    itemTypeId && doc(firestore, COLLECTIONS.ITEM_TYPES, itemTypeId)
  )

  const itemTypeFieldsParams = useMemo(
    () => ({
      entityIds: itemType?.fieldsOrder || [],
      collectionName: COLLECTIONS.ITEM_TYPES_FIELDS
    }),
    [itemType?.fieldsOrder]
  )

  const [fields, fieldsLoading] = useGetDocumentsByIds(itemTypeFieldsParams)

  const initialData = useMemo(() => {
    const transformedFields = fields?.reduce((acc, field) => {
      return {
        ...acc,
        [field?._id]: { ...field }
      }
    }, {})

    return {
      ...(itemType || {}),
      fields: transformedFields
    }
  }, [fields, itemType])

  const loading = useLoading([itemTypeLoading, fieldsLoading])

  return [initialData, loading]
}

export default useGetItemTypeInitialValues
