import { useState } from 'react'
import MenuDragAndDropContext from './MenuDragAndDropContext'
import PropTypes from 'prop-types'
import { useCallback } from 'react'

const MenuDragAndDropProvider = (props) => {
  const [dndConfig, setDndConfig] = useState({})
  const [isDndConfigWasChanged, setIsDndConfigWasChanged] = useState({})

  const { children } = props

  const updateDndConfig = (value, categoryId) => {
    setDndConfig((prev) => ({ ...prev, [categoryId]: value }))
    setIsDndConfigWasChanged((prev) => ({ ...prev, [categoryId]: true }))
  }

  const initializeDndConfig = useCallback((value, categoryId) => {
    setDndConfig((prev) => ({ ...prev, [categoryId]: value }))
  }, [])

  const clearDndChangeState = (categoryId) => {
    setIsDndConfigWasChanged((prev) => ({ ...prev, [categoryId]: false }))
  }

  return (
    <MenuDragAndDropContext.Provider
      value={{
        dndConfig,
        updateDndConfig,
        initializeDndConfig,
        clearDndChangeState,
        isDndConfigWasChanged
      }}>
      {children}
    </MenuDragAndDropContext.Provider>
  )
}

MenuDragAndDropProvider.propTypes = {
  children: PropTypes.node
}

export default MenuDragAndDropProvider
