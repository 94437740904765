import { useMemo } from 'react'
import { useLoading } from 'hooks'
import { useDocument } from 'services/api/firebase'
import { COLLECTIONS } from '__constants__'
import {
  useGetMenuMenuCategories,
  useGetMenuMenuType,
  useGetMenuMediaObjects,
  useGetMenuCocktails
} from 'domains/Menu/hooks'

const useGetMenuInitialValues = (id) => {
  /* Fetching data using useDocument hook */
  const [menu, menuLoading] = useDocument({
    ref: id ? COLLECTIONS.MENUS + '/' + id : null
  })

  /* Fetching menuCategory data */
  const [categories, categoriesLoading] = useGetMenuMenuCategories(menu)

  /* Fetching menuType data */
  const [menuType, menuTypeLoading] = useGetMenuMenuType(menu)

  /* Fetching mediaObject data */
  const [mediaObjects, mediaObjectLoading] = useGetMenuMediaObjects(menu)

  /* Fetching cocktail data */
  const [cocktails, cocktailLoading] = useGetMenuCocktails(menu)

  /* Merge all fetched data */
  const initialValues = useMemo(
    () => ({
      menu,
      categories,
      menuType,
      mediaObjects,
      cocktails
    }),
    [menu, categories, menuType, mediaObjects, cocktails]
  )

  /* Merge all loading states */
  const loadings = useMemo(
    () =>
      id
        ? [
            !menu,
            menuLoading,
            categoriesLoading,
            menuTypeLoading,
            mediaObjectLoading,
            cocktailLoading
          ]
        : [],
    [
      id,
      menu,
      menuLoading,
      categoriesLoading,
      menuTypeLoading,
      mediaObjectLoading,
      cocktailLoading
    ]
  )

  /* Getting loading state using merged loadings */
  const loading = useLoading(loadings)

  return [initialValues, loading]
}

export default useGetMenuInitialValues
