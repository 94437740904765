import { Option } from '@qonsoll/react-design'
import PropTypes from 'prop-types'
import { SelectWithFixedDropdown } from 'components'
import { useTranslations } from 'contexts/Translation'

const CustomerLanguageSelect = (props) => {
  const { value, onChange, listLanguages, mainLanguage, setMainLanguage } =
    props

  /* Getting translations instance */
  const { t } = useTranslations()

  /* Handling changes */
  const handleChange = (value) => {
    onChange?.(value)
    setMainLanguage?.(value)
  }

  return (
    <SelectWithFixedDropdown
      aria-label="Select language"
      aria-describedby="language-description"
      value={mainLanguage || value}
      onChange={handleChange}
      showSearch
      placeholder={t('Please choose a language')}>
      {listLanguages?.map((item) => (
        <Option key={item} value={item} aria-label={`${item} language`}>
          {item}
        </Option>
      ))}
    </SelectWithFixedDropdown>
  )
}

CustomerLanguageSelect.propTypes = {
  value: PropTypes.object,
  onChange: PropTypes.func,
  stepNumber: PropTypes.number,
  setMainLanguage: PropTypes.func,
  mainLanguage: PropTypes.string,
  listLanguages: PropTypes.array
}

export default CustomerLanguageSelect
