import styled from 'styled-components'

const ListWrapperStyled = styled.div`
  height: ${({ negotiate }) =>
    negotiate
      ? `calc(100vh - ${negotiate})`
      : 'calc(100vh - var(--page-height-to-negotiate))'};
  overflow-y: scroll;
  overflow-x: hidden;
  transition: all var(--ql-animation-duration-default);
`

export default ListWrapperStyled
