import { CheckOutlined, CloseOutlined } from '@ant-design/icons'
import { Col, Row } from '@qonsoll/react-design'

import { ButtonAccent } from 'components'
import PropTypes from 'prop-types'
import { UserSimpleForm } from 'domains/User/components'

const UserForm = (props) => {
  const { user, form, handleSave, handleCancel } = props

  return (
    <Row noOuterGutters v="center">
      <Col mb="calc(var(--ql-form-item-vertical-spacing-without-message) * -1)">
        <UserSimpleForm
          onSubmit={handleSave}
          form={form}
          initialValues={user}
          showTitle={false}
        />
      </Col>
      <Col cw="auto" display="block">
        <ButtonAccent
          ghost
          onClick={handleCancel}
          shape="circle"
          type="primary"
          icon={<CloseOutlined />}
        />
        <ButtonAccent
          ml="8px"
          shape="circle"
          type="primary"
          onClick={handleSave}
          icon={<CheckOutlined />}
        />
      </Col>
    </Row>
  )
}

UserForm.propTypes = {
  form: PropTypes.object,
  handleCancel: PropTypes.func,
  handleSave: PropTypes.func,
  user: PropTypes.object
}

export default UserForm
