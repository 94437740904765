import { model, attr } from 'services/model'
import * as yup from 'yup'

const validationSchema = yup.object().shape({
  city: yup
    .string()
    .typeError('Field city has wrong type')
    .default(null)
    .nullable(),
  country: yup
    .string()
    .typeError('Field country has wrong type')
    .default(null)
    .nullable(),
  zipCode: yup
    .string()
    .typeError('Field zipCode has wrong type')
    .default(null)
    .nullable(),
  streetName: yup
    .string()
    .typeError('Field streetName has wrong type')
    .default(null)
    .nullable(),
  streetNumber: yup
    .string()
    .typeError('Field streetNumber has wrong type')
    .default(null)
    .nullable()
})

const Address = model(
  'address',
  {
    city: attr('string'),
    country: attr('string'),
    zipCode: attr('string'),
    streetName: attr('string'),
    streetNumber: attr('string')
  },
  validationSchema
)

export default Address
