import { useCallback, useEffect, useRef, useState } from 'react'

const useSimpleSearch = (items, fuseObject, minInputValueLenght = 3) => {
  /* State which stores search result */
  const [searchResult, setSearchResult] = useState(items)
  const [searchLoading, setSearchLoading] = useState(true)

  const searchRef = useRef()
  const searchItems = useCallback(
    (initialListState) => {
      if (searchRef?.current?.input?.value?.length >= minInputValueLenght) {
        const searchRes = fuseObject?.search(searchRef.current.input.value)
        setSearchResult(searchRes?.map((item) => item.item))
      } else setSearchResult(initialListState)
    },
    [fuseObject, minInputValueLenght]
  )

  const onSearchInputChange = () => searchItems(items)

  useEffect(() => {
    setSearchLoading(true)
    items?.length && searchItems(items)
    setSearchLoading(false)
  }, [items, searchItems])

  return { searchResult, searchRef, searchLoading, onSearchInputChange }
}

export default useSimpleSearch
