import { Form, Input } from 'antd'
import { Link, useLocation } from 'react-router-dom'
import { useEffect, useMemo, useState } from 'react'
import { useGDPRStatus, useSessionActions } from 'domains/Session/hooks'

import { ButtonAccent } from 'components'
import PATHS from 'pages/paths'
import PropTypes from 'prop-types'
import { useTranslations } from 'contexts/Translation'

import { CheckboxAgreement } from './SignupForm.styles'

const SignupForm = ({ signup, onError, ...rest }) => {
  const { t } = useTranslations()
  const [loading, setLoading] = useState(false)

  const { search } = useLocation()
  const [form] = Form.useForm()
  /* Using the useGDPRStatus hook to get the GDPR status of the user. */
  const gdpr = useGDPRStatus()
  const { deleteInvitationDocument } = useSessionActions()

  const queryObject = useMemo(() => new URLSearchParams(search), [search])

  const onFinish = (credentials) => {
    /* Setting the loading state to true, then calling the signup function, then setting the loading
    state to false. */
    setLoading(true)
    signup({
      credentials,
      onError,
      onInvitationAccept: deleteInvitationDocument,
      gdpr
    })
    setLoading(false)
  }

  useEffect(() => {
    queryObject?.get('email') &&
      form.setFieldsValue({ email: queryObject?.get('email') })
  }, [queryObject, form])

  return (
    <Form onFinish={onFinish} form={form} {...rest}>
      <Form.Item
        name="firstName"
        rules={[
          {
            required: true,
            message: t('This field is required')
          },
          {
            max: 50,
            message: t('First name should be less than 50 characters long')
          }
        ]}>
        <Input size="large" autoFocus placeholder={t('First name')} />
      </Form.Item>
      <Form.Item
        name="lastName"
        rules={[
          {
            required: true,
            message: t('This field is required')
          },
          {
            max: 50,
            message: t('Last name should be less than 50 characters long')
          }
        ]}>
        <Input size="large" placeholder={t('Last name')} />
      </Form.Item>
      <Form.Item
        name="email"
        rules={[
          {
            required: true,
            message: t('This field is required')
          },
          {
            type: 'email',
            message: t('Please use format email@example.com')
          },
          {
            min: 3,
            message: t('Email should be more than 3 characters long')
          },
          {
            max: 128,
            message: t('Email should be less than 128 characters long')
          }
        ]}>
        <Input
          value={queryObject?.get('email')}
          disabled={queryObject?.get('email')}
          size="large"
          placeholder={t('Email')}
        />
      </Form.Item>
      <Form.Item
        name="password"
        hasFeedback
        rules={[
          { required: true, message: t('Please, enter your password') },
          { min: 8, message: t('Password must be at least 8 characters') },
          {
            max: 32,
            message: t('Password must be less than 32 characters long')
          }
        ]}>
        <Input.Password size="large" placeholder={t('Password')} />
      </Form.Item>
      <Form.Item
        name="confirm"
        dependencies={['password']}
        hasFeedback
        rules={[
          {
            required: true,
            message: t('Please confirm your password!')
          },
          ({ getFieldValue }) => ({
            validator(_, value) {
              if (!value || getFieldValue('password') === value) {
                return Promise.resolve()
              }
              return Promise.reject(
                new Error(t('Confirmation password and password dont match'))
              )
            }
          })
        ]}>
        <Input.Password size="large" placeholder={t('Confirm Password')} />
      </Form.Item>
      <Form.Item
        name="agreement"
        valuePropName="checked"
        rules={[
          {
            validator: (_, value) =>
              value
                ? Promise.resolve()
                : Promise.reject(new Error(t('Should accept agreement')))
          }
        ]}>
        <CheckboxAgreement>
          {t('I have read the')}{' '}
          <Link to={PATHS.SERVICE.TERMS_AND_CONDITIONS}>{t('agreement')}</Link>
        </CheckboxAgreement>
      </Form.Item>
      <ButtonAccent
        type="primary"
        htmlType="submit"
        size="large"
        block
        loading={loading}>
        {t('Sign up')}
      </ButtonAccent>
    </Form>
  )
}

SignupForm.propTypes = {
  form: PropTypes.object,
  onError: PropTypes.func.isRequired,
  onFinish: PropTypes.func,
  onFinishFailed: PropTypes.func,
  signup: PropTypes.func.isRequired
}

export default SignupForm
