import { useGetDocumentsByIds } from 'hooks'
import { COLLECTIONS } from '__constants__'
import { useEffect, useState, useCallback } from 'react'
import { getDocument } from 'services/api/firebase'

/* Getting ingredients from recipe */
const useGetIngredientsFromRecipe = (recipes) => {
  const [recipeData, recipeDataLoading] = useGetDocumentsByIds({
    collectionName: COLLECTIONS.RECIPES,
    entityIds: recipes
  })

  /* State for data */
  const [ingredients, setIngredients] = useState([])
  /* State for loading */
  const [loading, setLoading] = useState(!!recipes?.length)
  /* State for error */
  const [error, setError] = useState(null)

  /* Fetching data */
  const getIngredients = useCallback(async () => {
    try {
      /* Setting loading state to true */
      setLoading(true)

      /* Getting ingredients promises*/
      const promises = recipeData.map((item) =>
        getDocument(COLLECTIONS.INGREDIENTS, item?.ingredient)
      )

      /* Getting ingredients data */
      const snapshots = await Promise.allSettled(promises)
      const relatedData = []

      snapshots.forEach(({ reason, value }) => {
        if (reason) return
        relatedData.push(value)
      })

      /* Setting ingredients data */
      setIngredients(relatedData?.filter(Boolean))

      /* Setting loading state to false */
    } catch (error) {
      setError(error)
    }
    setLoading(false)
  }, [recipeData])

  useEffect(() => {
    !recipeDataLoading && recipeData?.length && getIngredients()
  }, [getIngredients, recipeData, recipeDataLoading])

  return [ingredients, loading, error, getIngredients]
}

export default useGetIngredientsFromRecipe
