import { model, attr } from 'services/model'
import * as yup from 'yup'

const validationSchema = yup.object().shape({
  name: yup
    .string()
    .typeError('Field name has wrong type')
    .required('Field name is required'),
  isAllergen: yup
    .boolean()
    .typeError('Field isAllergen has wrong type')
    .default(null)
    .nullable(),
  value: yup
    .string()
    .typeError('Field value has wrong type')
    .default(null)
    .nullable(),
  icon: yup
    .string()
    .typeError('Field icon has wrong type')
    .default(null)
    .nullable(),
  measurement: yup
    .string()
    .typeError('Field measurement has wrong type')
    .default(null)
    .nullable(),
  inPrice: yup
    .number()
    .typeError('Field in-price has wrong type')
    .default(null)
    .nullable(),
  outPrice: yup
    .number()
    .typeError('Field out-price has wrong type')
    .default(null)
    .nullable(),
  category: yup
    .string()
    .typeError('Field category has wrong type')
    .default(null)
    .nullable(),
  amount: yup
    .number()
    .typeError('Field amount has wrong type')
    .default(null)
    .nullable()
})

const Ingredient = model(
  'ingredient',
  {
    name: attr('string'),
    isAllergen: attr('boolean'),
    value: attr('string'),
    icon: attr('string'),
    measurement: attr('string'),
    inPrice: attr('number'),
    outPrice: attr('number'),
    category: attr('string'),
    amount: attr('number')
  },
  validationSchema
)

export default Ingredient
