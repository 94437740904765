import {
  getDocument,
  updateDocument,
  useListenDocument
} from 'services/api/firebase'
import { useEffect, useState } from 'react'

import { COLLECTIONS } from '__constants__'
import CustomerContext from './CustomerContext'
import PropTypes from 'prop-types'
import { message } from 'antd'
import { useGetDocumentsByIds } from 'hooks'
import { useTranslations } from '@qonsoll/translation'
import { useUserContext } from 'contexts/UserContext'

const CustomerProvider = ({ children }) => {
  const [currentCustomerId, setCurrentCustomerId] = useState(null)
  const [currentCustomerLoading, setCurrentCustomerLoading] = useState(false)
  const [isDisabledSelect, setIsDisabledSelect] = useState(true)

  const { userId } = useUserContext()
  const { t } = useTranslations()

  // get current company witch displaying in app
  const [customer, loading] = useListenDocument(
    currentCustomerId && {
      ref: COLLECTIONS.CUSTOMERS + '/' + currentCustomerId
    }
  )

  // get current user of application
  const [currentUser] = useListenDocument({
    ref: COLLECTIONS.USERS + '/' + userId
  })

  // get all possibility companies that can vr switched
  const [allCustomersData] = useGetDocumentsByIds({
    collectionName: COLLECTIONS.CUSTOMERS,
    entityIds: currentUser?._companies
  })

  const allCustomers = allCustomersData?.filter((item) => item !== undefined)

  // check if current company delete
  const checkCustomerData = async () => {
    setCurrentCustomerLoading(true)
    try {
      if (customer === undefined) {
        await updateDocument(COLLECTIONS.USERS, userId, {
          currentCompanyId:
            currentUser?.currentCompanyId || currentUser?._companies[0]
        })
      } else if (currentUser?._companies.length === 0) {
        await updateDocument(COLLECTIONS.USERS, userId, {
          currentCompanyId: null
        })
      }

      setCurrentCustomerId(currentUser?.currentCompanyId)
    } catch (error) {
      message.error(t('Something went wrong during checking customer data'))
    } finally {
      setCurrentCustomerLoading(false)
    }
  }

  // change current company data
  const onCurrentCustomerChange = (customerId) => {
    setCurrentCustomerId(customerId)
    userId &&
      updateDocument(COLLECTIONS.USERS, userId, {
        currentCompanyId: customerId
      })
  }

  useEffect(() => {
    currentUser && checkCustomerData()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    const fetchData = async () => {
      const userData = await getDocument(COLLECTIONS.USERS, userId)

      currentUser && setCurrentCustomerId(userData?.currentCompanyId)
    }

    fetchData()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentUser])

  useEffect(() => {
    allCustomers?.length > 1
      ? setIsDisabledSelect(false)
      : setIsDisabledSelect(true)
  }, [allCustomers?.length])

  return (
    <CustomerContext.Provider
      value={{
        customer,
        currentCustomerId,
        currentCustomerLoading,
        loading,
        allCustomers,
        setCurrentCustomerId,
        onCurrentCustomerChange,
        isDisabledSelect: isDisabledSelect
      }}>
      {children}
    </CustomerContext.Provider>
  )
}

CustomerProvider.propTypes = {
  children: PropTypes.element.isRequired
}

export default CustomerProvider
