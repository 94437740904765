import { COLLECTIONS } from '__constants__'
import { doc } from 'firebase/firestore'
import { firestore } from 'services/firebase'
import { useDocument } from 'services/api/firebase'
import { useDocumentDataOnce } from 'react-firebase-hooks/firestore'
import { useLoading } from 'hooks'
import { useMemo } from 'react'
import { useCustomerContext } from 'contexts/CustomerContext'

const useGetUserInitialValues = (id) => {
  /* Fetching data using useDocument hook */
  const [user, userLoading] = useDocument({
    ref: id ? COLLECTIONS.USERS + '/' + id : null
  })
  const currentCustomer = useCustomerContext()
  const [customer, customerLoading] = useDocumentDataOnce(
    doc(
      firestore,
      `${COLLECTIONS.CUSTOMERS}/${currentCustomer?.currentCustomerId}`
    )
  )

  /* Merge all fetched data */
  const initialValues = useMemo(
    () => ({
      user,
      customer
    }),
    [user, customer]
  )

  /* Merge all loading states */
  const loadings = useMemo(
    () => (id ? [userLoading, customerLoading] : []),
    [id, userLoading, customerLoading]
  )

  /* Getting loading state using merged loadings */
  const loading = useLoading(loadings)

  return [initialValues, loading]
}

export default useGetUserInitialValues
