import { Col, Row, Text, Title } from '@qonsoll/react-design'

import { CocktailItem } from 'domains/Cocktail/components/CocktailsReviewTable/components'
import PropTypes from 'prop-types'
import { useMemo } from 'react'
import { useTranslations } from 'contexts/Translation'

const CocktailsReviewTable = (props) => {
  const { cocktails, withTitle, emptyProps, tableProps, actions, menuId } =
    props

  /* Getting translations instance */
  const { t } = useTranslations()

  /* Checking if list is empty */
  const isEmpty = useMemo(() => !cocktails?.length, [cocktails])

  return (
    <Row negativeBlockMargin>
      {withTitle && (
        <Col cw={12} mb={2}>
          <Row noGutters>
            <Col>
              <Title level={4}>{t('Drinks')}</Title>
            </Col>
          </Row>
        </Col>
      )}
      {!isEmpty && (
        <Col cw={12}>
          {cocktails?.map((item, index) => (
            <CocktailItem
              cocktail={item}
              index={index}
              key={`${index}-cocktail`}
              tableProps={tableProps}
              actions={actions}
              menuId={menuId}
            />
          ))}
        </Col>
      )}
      {isEmpty && (
        <Col cw={12}>
          <Text variant="overline" color="var(--ql-state-disabled-color)">
            {emptyProps?.message || t('No drinks have been created yet')}
          </Text>
        </Col>
      )}
    </Row>
  )
}

CocktailsReviewTable.propTypes = {
  cocktails: PropTypes.array,
  withTitle: PropTypes.bool,
  emptyProps: PropTypes.object,
  tableProps: PropTypes.object,
  actions: PropTypes.object,
  menuId: PropTypes.string
}

export default CocktailsReviewTable
