import { model, belongsTo, attr } from 'services/model'
import * as yup from 'yup'

const validationSchema = yup.object().shape({
  item: yup
    .string()
    .typeError('Field item has wrong type')
    .default(null)
    .nullable(),
  quantity: yup
    .number()
    .typeError('Field quantity has wrong type')
    .default(null)
    .nullable()
})

const OrderedItem = model(
  'orderedItem',
  {
    item: belongsTo('cocktail'),
    quantity: attr('number')
  },
  validationSchema
)

export default OrderedItem
