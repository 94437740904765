import { UserView, UserForm } from './components'
import { useSimpleFormActions } from 'hooks'
import { useState } from 'react'
import { Form } from 'antd'
import { CardDropdown, SelectableCard } from 'components'
import { COLLECTIONS } from '__constants__'
import PropTypes from 'prop-types'

const UserSimpleView = (props) => {
  const { user, onSelect, index, isSelected, actions } = props

  /* Checking if component is in editing mode */
  const [isEditing, setIsEditing] = useState(false)
  /* Getting form instance from Form.useForm hook */
  const [form] = Form.useForm()

  /* Getting actions from useSimpleFormActions hook */
  const {
    handleDelete: removeDocument,
    handleCancel,
    handleSave,
    handleEdit
  } = useSimpleFormActions({
    collectionName: COLLECTIONS.USERS,
    document: user,
    form,
    changeStateAction: setIsEditing
  })

  /* Handling deletion of the item */
  const handleDelete = () => removeDocument()
  /* Handling selection of the item */
  const handleSelect = (e) => {
    e.stopPropagation()
    onSelect?.(user?._id)
  }

  return (
    <CardDropdown
      onDelete={handleDelete}
      onEdit={handleEdit}
      onClick={handleSelect}
      document={user}
      index={index}
      data-testid="userListItem"
      {...actions}>
      <SelectableCard isSelected={isSelected}>
        {!isEditing ? (
          <UserView user={user} />
        ) : (
          <UserForm
            user={user}
            form={form}
            handleCancel={handleCancel}
            handleSave={handleSave}
          />
        )}
      </SelectableCard>
    </CardDropdown>
  )
}

UserSimpleView.propTypes = {
  user: PropTypes.object,
  onSelect: PropTypes.func,
  index: PropTypes.number,
  isSelected: PropTypes.bool,
  actions: PropTypes.object
}

export default UserSimpleView
