import { Form } from 'antd'
import { useEffect } from 'react'

const useInitializeMenuCategoryAdvancedForm = (initialData) => {
  /* Getting form instance */
  const [form] = Form.useForm()

  /* Setting initial data */
  useEffect(() => {
    if (initialData) {
      /* Getting initial values */
      const { menuCategory, items } = initialData

      /* Formatting date fields */
      const formValues = {
        menuCategory: {
          name: menuCategory?.['name'] ?? null,
          value: menuCategory?.['value'] ?? null,
          cocktailsAvailabilities:
            menuCategory?.['cocktailsAvailabilities'] ?? null
        },
        items: items?.map((e) => e?._id).filter((e) => e) ?? []
      }

      /* Setting initial values to form instance */
      form.setFieldsValue(formValues)
    }
  }, [initialData, form])

  return { form }
}

export default useInitializeMenuCategoryAdvancedForm
