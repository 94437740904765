import { Breadcrumb } from 'antd'
import { DISABLED_LINK } from 'components/HeaderBreadcrumbs/__constants__'
import { Link } from 'react-router-dom'
import { PATH_TYPES } from '__constants__'
import pluralize from 'pluralize'
import { transformCollectionName } from 'components/HeaderBreadcrumbs/helpers'
import { useMemo } from 'react'

const useExtraBreadcrumbItems = (pathSnippets, collections, breadCrumbItem) => {
  return useMemo(
    () =>
      pathSnippets
        ?.map((_, index) => {
          const url = `/${pathSnippets?.slice(0, index + 1).join('/')}`

          // Check when create or edit path
          const isCreate = url.includes(PATH_TYPES.CREATE.toLowerCase())
          const isEdit = url.includes(PATH_TYPES.EDIT.toLowerCase())

          const splitUrl = url.split('/')
          const lastPathSnippet = splitUrl[splitUrl?.length - 1]

          const customUrl = splitUrl
            ?.map((item) => {
              const pluralizeItem = pluralize(item)
              return collections?.find(
                (collection) =>
                  collection === transformCollectionName(pluralizeItem)
              )
                ? pluralizeItem
                : item
            })
            ?.join('/')

          // If path create or edit return original url
          const currentUrl = isCreate || isEdit ? url : customUrl

          const disabledLink = DISABLED_LINK?.some(
            (link) => lastPathSnippet === link
          )
          const disabledDocuments = breadCrumbItem?.[index]?.disabled
          let disabled = disabledDocuments || disabledLink

          if (pathSnippets?.[0] === 'menus' || pathSnippets?.[0] === 'venues') {
            disabled = false
          }

          const showedName = breadCrumbItem?.[index]?.name
          return showedName
            ? {
                component: (
                  <Breadcrumb.Item key={currentUrl}>
                    <Link to={currentUrl} disabled={disabled}>
                      {showedName}
                    </Link>
                  </Breadcrumb.Item>
                )
              }
            : null
        })
        .filter(Boolean),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [breadCrumbItem]
  )
}

export default useExtraBreadcrumbItems
