const getRemoveFieldButtonProps = (
  isRemoveButtonDisabled,
  onRemoveField,
  field
) => ({
  cursor: isRemoveButtonDisabled ? 'not-allowed' : 'pointer',
  onClick: !isRemoveButtonDisabled ? () => onRemoveField(field?.uid) : () => {},
  fill: isRemoveButtonDisabled ? 'gray' : 'var(--ql-color-danger)'
})

export default getRemoveFieldButtonProps
