const MENU_AVAILABILITIES = {
  PRIVATE: 'Private',
  PUBLIC: 'Public'
}

const MENU_AVAILABILITIES_KEYS = {
  PRIVATE: 'PRIVATE',
  PUBLIC: 'PUBLIC'
}

const MENU_AVAILABILITIES_OPTIONS = Object.keys(MENU_AVAILABILITIES).map(
  (key) => ({ label: MENU_AVAILABILITIES[key], value: key })
)

export {
  MENU_AVAILABILITIES_OPTIONS,
  MENU_AVAILABILITIES,
  MENU_AVAILABILITIES_KEYS
}
