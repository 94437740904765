import { Text } from '@qonsoll/react-design'
import { useGetEnumString } from 'hooks'
import PropTypes from 'prop-types'
import { useGetAllergensFromRecipe } from 'domains/Recipe/hooks'

const CocktailAllergensColumn = (props) => {
  const { cocktail } = props

  /* Getting relationship data */
  const [allergens] = useGetAllergensFromRecipe(cocktail)
  const allergensName = allergens?.map((item) => item?.name)
  const allergensEnum = useGetEnumString(allergensName)

  return <Text>{allergensEnum || 'n/a'}</Text>
}

CocktailAllergensColumn.propTypes = {
  cocktail: PropTypes.object
}

export default CocktailAllergensColumn
