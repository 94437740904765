import { useEffect } from 'react'
import { Form } from 'antd'

const useInitializeTrainingMaterialAdvancedForm = (initialData) => {
  /* Getting form instance */
  const [form] = Form.useForm()

  /* Setting initial data */
  useEffect(() => {
    if (initialData) {
      /* Getting initial values */
      const { trainingMaterial, mediaObjects } = initialData

      /* Formatting date fields */
      const formValues = {
        trainingMaterial: {
          title: trainingMaterial?.['title'] ?? null,
          description: trainingMaterial?.['description'] ?? null,
          content: trainingMaterial?.['content'] ?? null
        },
        mediaObjects: mediaObjects ?? null
      }

      /* Setting initial values to form instance */
      form.setFieldsValue(formValues)
    }
  }, [initialData, form])

  return { form }
}

export default useInitializeTrainingMaterialAdvancedForm
