import { Switch } from '@qonsoll/react-design'
import styled from 'styled-components'

const SwitchStyled = styled(Switch)`
  &.ant-switch {
    height: 32px;
    width: 52px;
    background: var(--switch-bg);
    border: 1px solid var(--switch-border);
  }
  &.ant-switch:hover:not(.ant-switch-disabled),
  &.ant-switch:focus:not(.ant-switch-disabled) {
    border: 1px solid var(--switch-hover-border);
    box-shadow: var(--switch-hover-shadow);
  }
  &.ant-switch:active:not(.ant-switch-disabled) .ant-switch-handle::before {
    background: var(--switch-dot-default-active-bg);
  }
  &.ant-switch-checked:active .ant-switch-handle::before {
    background: var(--switch-dot-checked-active-bg) !important;
  }
  .ant-switch-handle {
    width: 24px;
    height: 24px;
    top: 3px;
    left: 4px;
  }
  .ant-switch-handle:not(.ant-switch-checked)::before {
    border-radius: var(--ql-border-radius-full);
    box-shadow: none;
    background: var(--switch-dot-default-bg);
  }
  &.ant-switch-checked .ant-switch-handle::before {
    border-radius: var(--ql-border-radius-full);
    box-shadow: none;
    background: var(--switch-dot-checked-bg);
  }
  &.ant-switch-checked .ant-switch-handle {
    left: calc(100% - 24px - 4px);
  }
  &.ant-switch-disabled:not(.ant-switch-checked) {
    background: var(--switch-disabled-bg) !important;
    border: 1px solid var(--switch-disabled-border) !important;
    opacity: 1 !important;
  }
  &.ant-switch-disabled:not(.ant-switch-checked) .ant-switch-handle::before {
    background: var(--switch-dot-default-disabled-bg) !important;
  }
  &.ant-switch-disabled {
    background: var(--switch-disabled-bg) !important;
    border: 1px solid var(--switch-disabled-border) !important;
    opacity: 1 !important;
  }
  &.ant-switch-disabled .ant-switch-handle::before {
    background: var(--switch-dot-checked-disabled-bg) !important;
  }
`

export default SwitchStyled
