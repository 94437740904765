import { COLLECTIONS } from '__constants__'
import { message } from 'antd'
import { doc, getDoc } from 'firebase/firestore'
import { useCallback, useEffect } from 'react'
import { useState } from 'react'
import { firestore } from 'services/firebase'

const useGetListItems = (list) => {
  const [listItems, setListItems] = useState([])
  const [loading, setLoading] = useState(false)

  const fetchListItems = useCallback(async () => {
    try {
      setLoading(true)
      const listItemsIds = Object.keys(list?.listItems || {})

      const listItems = await Promise.all(
        listItemsIds?.map(async (listItemId) => {
          const listItem = await getDoc(
            doc(firestore, COLLECTIONS.LIST_ITEMS, listItemId)
          )
          return listItem?.data()
        })
      )

      setListItems(listItems)
    } catch (error) {
      message.error(error?.message)
    } finally {
      setLoading(false)
    }
  }, [list?.listItems])

  useEffect(() => {
    list && fetchListItems()
  }, [fetchListItems, list])

  return [listItems, loading]
}

export default useGetListItems
