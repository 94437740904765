import { Col, Container, Divider, Row, Text } from '@qonsoll/react-design'

import { ButtonAccent } from 'components'
import { CONTENT_TYPES_LABELS } from '__constants__'
import { Player } from '@qonsoll/qvideo'
import PropTypes from 'prop-types'
import { Tag } from 'antd'
import { capitalize } from 'helpers'
import { useGetMaterialTitleAndRibbonColor } from 'domains/TrainingMaterial/hooks'
import { useMemo } from 'react'
import { useTranslations } from 'contexts/Translation'

const API_KEY = process.env.REACT_APP_QVIDEO_API_KEY

const TrainingMaterialAdvancedView = (props) => {
  const { trainingMaterial } = props
  const { contentType, content, description, availability } = trainingMaterial

  // [ADDITIONAL HOOKS]
  const { t } = useTranslations()
  const { ribbonColor, materialTitle } =
    useGetMaterialTitleAndRibbonColor(availability)
  // [COMPUTED PROPERTIES]
  const isVideo = trainingMaterial?.contentType === CONTENT_TYPES_LABELS.VIDEO
  const isPresentation =
    trainingMaterial?.contentType === CONTENT_TYPES_LABELS.PRESENTATION

  // define content type label: Video or Presentation
  const materialType = useMemo(() => capitalize(contentType), [contentType])

  return (
    <Container>
      <Row mx="var(--ql-grid-gutter-negative)">
        <Col cw={12}>
          <Row noGutters>
            <Col cw="auto">
              <Tag color={ribbonColor}>{materialTitle}</Tag>
            </Col>
            <Col cw="auto">
              <Tag>{capitalize(materialType)}</Tag>
            </Col>

            <Col cw={12} mt={3}>
              <Text>{description}</Text>
            </Col>
            {isPresentation && (
              <Col h="right" mt={2}>
                <ButtonAccent
                  mt={2}
                  type="secondary"
                  onClick={() => window.open(content?.[0]?.url, '_blank')}>
                  {t('Open presentation')}
                </ButtonAccent>
              </Col>
            )}
          </Row>
          {isVideo && (
            <>
              <Divider my={3} />
              <Row noGutters mb={3} mt={4}>
                <Col
                  cw={12}
                  maxHeight="70vh"
                  minHeight="70vh"
                  color="var(--ql-color-dark)">
                  <Player
                    apiKey={API_KEY}
                    videoToken={content?.[0]?.videoToken}
                  />
                </Col>
              </Row>
            </>
          )}
        </Col>
      </Row>
    </Container>
  )
}
TrainingMaterialAdvancedView.propTypes = {
  trainingMaterial: PropTypes.object,
  withAvailability: PropTypes.bool
}

export default TrainingMaterialAdvancedView
