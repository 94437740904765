import {
  CocktailAllergensColumn,
  CocktailAvailabilityColumn,
  CocktailIngredientsColumn,
  CocktailItemColumn,
  CocktailPriceColumn
} from 'domains/Cocktail/components/CocktailsReviewTable/components'
import { Col, Row } from '@qonsoll/react-design'
import {
  ItemWrapper,
  ScrollableCol,
  ScrollableRow,
  staticColStyle
} from 'components/Table/Table.styled'

import { COLLECTIONS } from '__constants__'
import PropTypes from 'prop-types'
import { ScrollSyncPane } from 'react-scroll-sync'
import { doc } from 'firebase/firestore'
import { firestore } from 'services/firebase'
import { useDefineIfScrollable } from 'components/Table/hooks'
import { useDocumentData } from 'react-firebase-hooks/firestore'
import { useRef } from 'react'

const MenuCategoryItem = (props) => {
  const {
    cocktailId,
    onPriceChange,
    onAvailabilityChange,
    availability,
    tableProps
  } = props
  const {
    scrollableColumnWidth,
    scrollableContainerMinWidth,
    fixedColCw,
    scrollableColCw
  } = tableProps

  const [cocktail] = useDocumentData(
    cocktailId && doc(firestore, COLLECTIONS.COCKTAILS_NEW + '/' + cocktailId)
  )

  const onUpdateAvailable = (value) =>
    onAvailabilityChange(cocktailId, { availability: value })

  // whether col is scrollable and should have a shadow or not
  const ref = useRef()
  const isScrollable = useDefineIfScrollable(ref, scrollableContainerMinWidth)
  const colShadow = isScrollable ? 'var(--table-static-col-shadow)' : 'none'

  return (
    <ItemWrapper>
      <Row noGutters v="center" overflow="hidden">
        <Col
          cw={fixedColCw}
          {...staticColStyle}
          alignSelf="stretch"
          flexDirection="row"
          boxShadow={colShadow}
          v="center">
          <CocktailItemColumn dragable cocktail={cocktail} />
        </Col>
        <ScrollSyncPane group="horizontal">
          <ScrollableCol cw={scrollableColCw} padding={3}>
            <ScrollableRow
              ref={ref}
              minWidth={scrollableContainerMinWidth}
              v="center"
              height="100%">
              <Col cw={scrollableColumnWidth[0]} v="center">
                <CocktailPriceColumn
                  value={cocktail?.price}
                  onSelect={onPriceChange}
                  {...props}
                />
              </Col>
              <Col cw={scrollableColumnWidth[1]} v="center">
                <CocktailIngredientsColumn cocktail={cocktail} />
              </Col>
              <Col cw={scrollableColumnWidth[2]} v="center">
                <CocktailAllergensColumn cocktail={cocktail} />
              </Col>
              <Col cw={scrollableColumnWidth[3]} v="center">
                <CocktailAvailabilityColumn
                  value={availability}
                  onChange={onUpdateAvailable}
                />
              </Col>
            </ScrollableRow>
          </ScrollableCol>
        </ScrollSyncPane>
      </Row>
    </ItemWrapper>
  )
}

MenuCategoryItem.propTypes = {
  cocktailId: PropTypes.string,
  onPriceChange: PropTypes.func,
  onAvailabilityChange: PropTypes.func,
  availability: PropTypes.string,
  tableProps: PropTypes.object
}

export default MenuCategoryItem
