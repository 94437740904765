import { COLLECTIONS, LIST_FIELD_DATA_TYPES } from '__constants__'
import { useEffect, useState } from 'react'
import { getDocument } from 'services/api/firebase'
import { message } from 'antd'
import { useTranslations } from 'contexts/Translation'

// Fetch combined fields for an object instance
const fetchObjectCombinedFields = async (objDoc, oneDoc) => {
  if (!objDoc?.fieldsOrder || !oneDoc) {
    return null
  }

  const objectCombinedFields = await Promise.all(
    objDoc.fieldsOrder.map(async (objFieldId) => {
      const objField = await getDocument(COLLECTIONS.OBJECT_FIELDS, objFieldId)
      if (!objField) {
        return null
      }

      if (objField.relationType === LIST_FIELD_DATA_TYPES.HAS_ONE) {
        const refPath =
          objField.object ||
          (objField.enum ? COLLECTIONS.ENUM_INSTANCES : COLLECTIONS.LIST_ITEMS)

        const secDoc = await getDocument(refPath, oneDoc[objField.name])
        if (!secDoc) {
          return null
        }

        return {
          _id: objFieldId,
          relationType: objField.relationType,
          label: objField.label,
          isObject: false,
          name: objField.name,
          value: secDoc.name
        }
      }

      if (objField.relationType === LIST_FIELD_DATA_TYPES.HAS_MANY) {
        const refPath = objField.enum
          ? COLLECTIONS.ENUM_INSTANCES
          : COLLECTIONS.LIST_ITEMS

        const array = await Promise.all(
          oneDoc[objField.name]?.map(async (id) => {
            if (!id) {
              return null
            }

            const doc = await getDocument(refPath, id)
            if (!doc) {
              return null
            }

            return doc.name
          })
        )

        return {
          _id: objFieldId,
          relationType: objField.relationType,
          label: objField.label,
          isObject: false,
          name: objField.name,
          value: array.filter(Boolean)
        }
      }

      // Handle references with no relation type
      return {
        _id: objFieldId,
        relationType: objField.relationType,
        label: objField.label,
        isObject: false,
        name: objField.name,
        value: oneDoc[objField.name] || null
      }
    })
  )

  return {
    value: objectCombinedFields.filter(Boolean),
    objectId: oneDoc.objectId
  }
}

// Fetch object instances for an object
const fetchObjectInstances = async (objectIds, objDoc) => {
  if (!objectIds || !objectIds.length || !objDoc) {
    return []
  }

  const objectInstances = await Promise.all(
    objectIds.map(async (objectId) => {
      const oneDoc = await getDocument(COLLECTIONS.OBJECT_INSTANCES, objectId)

      if (!oneDoc) {
        return null
      }

      const objectCombinedFields = await fetchObjectCombinedFields(
        objDoc,
        oneDoc
      )

      return objectCombinedFields
    })
  )

  return objectInstances.filter(Boolean)
}

// Get collection path for an item type field
const getRefCollectionPath = (itemTypeField) => {
  let refCollectionPath = COLLECTIONS.LIST_ITEMS
  if (itemTypeField?.object) refCollectionPath = COLLECTIONS.OBJECT_INSTANCES
  if (itemTypeField?.enum) refCollectionPath = COLLECTIONS.ENUM_INSTANCES

  return refCollectionPath
}

// Fetch combined fields for a menu item
async function fetchCombinedFields(fieldsOrder, menuItem) {
  const combinedFields = await Promise.all(
    fieldsOrder?.map(async (fieldId, index) => {
      const itemTypeField = await getDocument(
        COLLECTIONS.ITEM_TYPES_FIELDS,
        fieldId
      )

      if (!itemTypeField) {
        return null
      }
      const { relationType, label, name } = itemTypeField
      let value = null
      let isObject = false

      if (LIST_FIELD_DATA_TYPES.HAS_ONE === relationType && menuItem[name]) {
        const refCollectionPath = getRefCollectionPath(itemTypeField)
        const oneDoc = await getDocument(refCollectionPath, menuItem[name])

        if (itemTypeField.object) {
          const objDoc = await getDocument(
            COLLECTIONS.OBJECTS,
            oneDoc?.objectId
          )

          const objectCombinedFields = await fetchObjectCombinedFields(
            objDoc,
            oneDoc
          )

          isObject = true
          value = [objectCombinedFields]
        } else {
          value = oneDoc?.name ?? null
        }
      } else if (
        LIST_FIELD_DATA_TYPES.HAS_MANY === relationType &&
        menuItem[name]?.length
      ) {
        const refCollectionPath = getRefCollectionPath(itemTypeField)
        if (itemTypeField.object) {
          const objDoc = await getDocument(
            COLLECTIONS.OBJECTS,
            itemTypeField.object
          )
          const objArray = await fetchObjectInstances(menuItem[name], objDoc)
          isObject = true
          value = objArray?.filter(Boolean)
        } else {
          const array = await Promise.all(
            menuItem[name]?.map(async (id) => {
              return id && (await getDocument(refCollectionPath, id))
            })
          )
          value = array?.map((item) => item?.name ?? null)
        }
      } else {
        value = menuItem[name]
      }

      return {
        _id: fieldId,
        relationType: relationType,
        label: label,
        isObject: isObject,
        name: name,
        value: value
      }
    })
  )

  return combinedFields
}

const useGetMenuItemData = (itemTypeId, menuItemId) => {
  const [combinedData, setCombinedData] = useState([])
  const [combinedLoading, setCombinedLoading] = useState(false)
  const { t } = useTranslations()

  useEffect(() => {
    const fetchData = async () => {
      try {
        setCombinedLoading(true)
        const [itemType, menuItem] = await Promise.all([
          getDocument(COLLECTIONS.ITEM_TYPES, itemTypeId),
          getDocument(COLLECTIONS.MENU_ITEMS, menuItemId)
        ])
        if (!itemType || !menuItem) {
          return
        }
        const fieldsOrder = itemType.fieldsOrder
        const combinedFields = await fetchCombinedFields(fieldsOrder, menuItem)
        setCombinedData(combinedFields.filter(Boolean))
      } catch (error) {
        message.error(t('menu-item.general-error'))
      } finally {
        setCombinedLoading(false)
      }
    }

    fetchData()
  }, [itemTypeId, menuItemId, t])

  return [combinedData, combinedLoading]
}

export default useGetMenuItemData
