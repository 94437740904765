import { useState } from 'react'

import { COLLECTIONS } from '__constants__'
import { getDocument } from 'services/api/firebase'
import { useCustomerContext } from 'contexts/CustomerContext'

/**
 * It returns an array of values that are used to render the restaurants
 * @param props - An object that contains the query parameters.
 * @returns An array of values.
 */
const useGetCustomerRestaurants = (props) => {
  const [restaurants, setRestaurants] = useState(null)
  const { customer } = useCustomerContext()

  const getAllRestaurants = async () => {
    const restaurantDataPromises = customer?.restaurants?.map(
      (id) => id && getDocument(COLLECTIONS.RESTAURANTS, id)
    )

    Promise.all(restaurantDataPromises).then((values) => setRestaurants(values))
  }

  getAllRestaurants()

  return [restaurants]
}

export default useGetCustomerRestaurants
