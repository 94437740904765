import { useEffect } from 'react'
import { Form } from 'antd'

const useInitializeMediaObjectAdvancedForm = (initialData) => {
  /* Getting form instance */
  const [form] = Form.useForm()

  /* Setting initial data */
  useEffect(() => {
    if (initialData) {
      /* Getting initial values */
      const { mediaObject } = initialData

      /* Formatting date fields */
      const formValues = {
        mediaObject: {
          url: mediaObject?.['url'] ?? null,
          size: mediaObject?.['size'] ?? null,
          description: mediaObject?.['description'] ?? null,
          format: mediaObject?.['format'] ?? null
        }
      }

      /* Setting initial values to form instance */
      form.setFieldsValue(formValues)
    }
  }, [initialData, form])

  return { form }
}

export default useInitializeMediaObjectAdvancedForm
