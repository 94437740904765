import { COLLECTIONS } from '__constants__'
import { useCallback, useEffect, useState } from 'react'

import { getDocument, useListenDocuments } from 'services/api/firebase'
import { message } from 'antd'
import { useTranslations } from 'contexts/Translation'
import { useDocumentData } from 'react-firebase-hooks/firestore'
import { doc } from 'firebase/firestore'
import { firestore } from 'services/firebase'
import { useLoading } from 'hooks'
import { useMemo } from 'react'

const useGetMenuItems = (menuId, menuType, menuConfig) => {
  const { t } = useTranslations()
  const [menuItems, setMenuItems] = useState([])
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState(null)

  const [menuItem, menuItemLoading] = useDocumentData(
    menuId && doc(firestore, COLLECTIONS.MENUS, menuId)
  )

  const categoriesFetchData = useMemo(
    () => [menuItem?.categoriesOrder || [], COLLECTIONS.MENU_CATEGORIES],
    [menuItem?.categoriesOrder]
  )

  const [categoriesList, categoriesListLoading] = useListenDocuments(
    ...categoriesFetchData
  )

  const getData = useCallback(async () => {
    try {
      if (menuItem) {
        setLoading(true)

        const filteredCategoriesList = categoriesList.filter(Boolean)
        let orderedMenuItemsByCategory = []

        for (const category of filteredCategoriesList) {
          const { _id, items, itemsOrder, itemTypeId } = category

          const itemTypeData = await getDocument(
            COLLECTIONS.ITEM_TYPES,
            itemTypeId
          )

          const { name: categoryName } = itemTypeData || {}

          const menuItems = await Promise.all(
            itemsOrder.map(async (itemKey) => {
              if (items[itemKey]) {
                return await getDocument(COLLECTIONS.MENU_ITEMS, itemKey)
              }
              return null
            })
          )

          const filteredMenuItems = menuItems.filter(Boolean)
          orderedMenuItemsByCategory.push({
            categoryId: _id,
            categoryName,
            menuItems: filteredMenuItems
          })
        }

        setMenuItems(orderedMenuItemsByCategory)
      }
    } catch (error) {
      message.error(t('Error during getting menu items'))
      setError(error)
    } finally {
      setLoading(false)
    }
  }, [categoriesList, menuItem, t])

  const fetchLoading = useLoading([
    menuItemLoading,
    categoriesListLoading,
    loading
  ])

  useEffect(() => {
    let isMount = true
    let unsubscribe = () => {}

    if (menuId) {
      getData()
    }

    return () => {
      isMount && unsubscribe?.()
      isMount = false
    }
  }, [menuId, menuType, menuConfig, t, getData])
  return [menuItems, fetchLoading, error]
}

export default useGetMenuItems
