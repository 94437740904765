import { Box, Col, Img, Row, Title } from '@qonsoll/react-design'

import PropTypes from 'prop-types'
import { imageProps } from './GlassTypeView.styles'

const GlassTypeView = (props) => {
  const { glassType, typeImage } = props

  return (
    <Box height="100px" cursor="pointer">
      {typeImage ? (
        <Img style={imageProps} src={glassType?.image} alt="Glass image" />
      ) : (
        glassType?.name && (
          <Row justifyContent="center" alignItems="center" noInnerGutters>
            {glassType?.image && (
              <Col>
                <Img
                  style={imageProps}
                  src={glassType?.image}
                  alt="Glass image"
                />
              </Col>
            )}
            <Col>
              <Title
                level={5}
                overflow="hidden"
                textOverflow="ellipsis"
                whiteSpace="nowrap">
                {glassType?.name}
              </Title>
            </Col>
          </Row>
        )
      )}
    </Box>
  )
}

GlassTypeView.propTypes = {
  glassType: PropTypes.object,
  typeImage: PropTypes.bool
}

export default GlassTypeView
