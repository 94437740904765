import { Box, Input } from '@qonsoll/react-design'

import { ButtonAccent } from 'components'
import { Form } from 'antd'
import { KEYS } from '__constants__'
import PropTypes from 'prop-types'
import { useCustomerAdminInviteFormActions } from './hooks'
import { useTranslations } from 'contexts/Translation'

const CustomerAdminInviteForm = (props) => {
  const { customerId, users } = props

  /* Getting translations instance */
  const { t } = useTranslations()
  /* Getting new form instance from Form.useForm hook */
  const [baseForm] = Form.useForm()

  const { onFinish, loading, onReset } = useCustomerAdminInviteFormActions(
    baseForm,
    customerId,
    users
  )

  /* Submitting form on enter key press */
  const handleKeyPress = (e) => {
    if (e.key === KEYS.ENTER) {
      e.preventDefault()
      e.stopPropagation()
      baseForm?.submit()
    }
  }

  const onSentInvite = () => {
    baseForm.submit()
  }

  return (
    <Form
      scrollToFirstError
      form={baseForm}
      onFinish={onFinish}
      layout="vertical"
      onKeyPress={handleKeyPress}
      autoComplete="off">
      <Form.Item
        label={t('Admin email')}
        name={['email']}
        rules={[
          {
            required: true,
            message: t('Email is required!')
          },
          {
            type: 'email',
            message: t('Please enter valid email')
          }
        ]}>
        <Input placeholder={t('Enter email')} />
      </Form.Item>
      <Box display="flex">
        <ButtonAccent
          mr={2}
          type="secondary"
          htmlType="button"
          onClick={onReset}>
          {t('Cancel')}
        </ButtonAccent>
        <ButtonAccent type="primary" loading={loading} onClick={onSentInvite}>
          {t('Send')}
        </ButtonAccent>
      </Box>
    </Form>
  )
}
CustomerAdminInviteForm.propTypes = {
  customerId: PropTypes.string,
  users: PropTypes.array
}

export default CustomerAdminInviteForm
