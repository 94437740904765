import { Box, Input, Title } from '@qonsoll/react-design'
import { Form, Popconfirm } from 'antd'
import {
  useActionsCocktailAdvancedForm,
  useInitializeCocktailAdvancedForm
} from './hooks'

import { AlcoholTasteTypeRadioButtons } from 'domains/AlcoholTasteType/components'
import { AllergenSelectableList } from 'domains/Allergen/components'
import { ButtonAccent, EnumSelect } from 'components'
import { CocktailSimpleForm } from 'domains/Cocktail/components'
import { FlavorSelect } from 'domains/Flavor/components'
import { GlassTypeSelectableList } from 'domains/GlassType/components'
import { COCKTAIL_AVAILABILITIES_OPTIONS, KEYS } from '__constants__'
import { MainSpiritTypeSelect } from 'domains/MainSpiritType/components'
import { MethodSelect } from 'domains/Method/components'
import PropTypes from 'prop-types'
import { RecipeListWithCreate } from 'domains/Recipe/components'
import { onTextAreaEnterPress } from 'helpers'
import { useTranslations } from 'contexts/Translation'

const CocktailAdvancedForm = (props) => {
  const {
    initialData,
    group,
    showTitle,
    customOnFinish,
    customOnCancel,
    onFinishCallback,
    customSaveCocktail
  } = props

  /* Getting translations instance */
  const { t } = useTranslations()
  /* Getting form instance from hook */
  const { form, isDirty, onFormValuesChange } =
    useInitializeCocktailAdvancedForm(initialData)

  /* Handling enter key press */
  const handleKeyPress = (e) => {
    if (e.key === KEYS.ENTER) {
      e.preventDefault()
      onFinish()
    }
  }

  /* Getting loading state and actions from hook */
  const { loading, onFinish, onReset } = useActionsCocktailAdvancedForm({
    initialData,
    form,
    customOnFinish,
    customSaveCocktail
  })

  const onCancel = () => {
    if (customOnCancel) {
      form.resetFields()
      customOnCancel?.()
    } else onReset?.()
  }

  const onFormFinish = () => {
    onFinish?.()
    onFinishCallback?.()
  }

  return group ? (
    <>
      {showTitle && (
        <Title level={4} mb={2}>
          {t('Drink')}
        </Title>
      )}
      <CocktailSimpleForm showTitle={false} group={[...group, 'cocktail']} />

      <Form.Item label={t('Recipe')} name={[...group, 'recipes']}>
        <RecipeListWithCreate />
      </Form.Item>
      <Form.Item label={t('Allergens')} name={[...group, 'allergens']}>
        <AllergenSelectableList withTitle={false} transformationLimit={1} />
      </Form.Item>
      <Form.Item label={t('Method')} name={[...group, 'methods']}>
        <MethodSelect showTitle={false} mode="multiple" />
      </Form.Item>
      <Form.Item
        rules={[
          { required: true, message: t('Please choose alcohol taste type!') }
        ]}
        label={t('Alcohol taste type')}
        name={[...group, 'alcoholTasteTypes']}>
        <AlcoholTasteTypeRadioButtons />
      </Form.Item>
      <Form.Item
        rules={[{ required: true, message: t('Please choose availability!') }]}
        label={t('Availability')}
        name={[...group, 'availability']}>
        <EnumSelect options={COCKTAIL_AVAILABILITIES_OPTIONS} />
      </Form.Item>
      <Form.Item
        label={t('Main spirit type')}
        name={[...group, 'mainSpiritType']}
        rules={[
          { required: true, message: t('Please choose main spirit type!') }
        ]}>
        <MainSpiritTypeSelect mode="multiple" />
      </Form.Item>
      <Form.Item
        rules={[{ required: true, message: t('Please choose flavor!') }]}
        label={t('Flavor')}
        name={[...group, 'flavor']}>
        <FlavorSelect mode="multiple" />
      </Form.Item>
      <Form.Item
        rules={[{ required: true, message: t('Please choose glass type!') }]}
        label={t('Glass type')}
        mode="single"
        name={['glassType']}>
        <GlassTypeSelectableList withTitle={false} />
      </Form.Item>
      <Form.Item label={t('Garnish')} name={[...group, 'decorationType']}>
        <Input
          onPressEnter={onTextAreaEnterPress}
          placeholder={t('Please, input garnish')}
        />
      </Form.Item>
      <Form.Item label={t('Special comment')} name={['specialComment']}>
        <Input
          onPressEnter={onTextAreaEnterPress}
          placeholder={t('Please, input special comment')}
        />
      </Form.Item>
    </>
  ) : (
    <Form
      onValuesChange={onFormValuesChange}
      scrollToFirstError
      onKeyPress={handleKeyPress}
      onFinish={onFormFinish}
      layout="vertical"
      autoComplete="off"
      form={form}>
      {showTitle && (
        <Title level={4} mb={2}>
          {t('Drink')}
        </Title>
      )}
      <CocktailSimpleForm group={['cocktail']} />

      <Form.Item label={t('Recipe')} name={['recipes']}>
        <RecipeListWithCreate />
      </Form.Item>
      <Form.Item label={t('Allergens')} name={['allergens']}>
        <AllergenSelectableList withTitle={false} transformationLimit={4} />
      </Form.Item>
      <Form.Item label={t('Method')} name={['methods']}>
        <MethodSelect showTitle={false} mode="multiple" />
      </Form.Item>
      <Form.Item
        label={t('Alcohol taste type')}
        name={['alcoholTasteTypes']}
        rules={[
          { required: true, message: t('Please choose alcohol taste type!') }
        ]}>
        <AlcoholTasteTypeRadioButtons />
      </Form.Item>
      <Form.Item
        rules={[{ required: true, message: t('Please choose availability!') }]}
        label={t('Availability')}
        name={['availability']}>
        <EnumSelect
          defaultValue={COCKTAIL_AVAILABILITIES_OPTIONS.at(1).value}
          options={COCKTAIL_AVAILABILITIES_OPTIONS}
        />
      </Form.Item>
      <Form.Item
        label={t('Main spirit type')}
        name={['mainSpiritType']}
        rules={[
          { required: true, message: t('Please choose main spirit type!') }
        ]}>
        <MainSpiritTypeSelect mode="multiple" />
      </Form.Item>
      <Form.Item
        rules={[{ required: true, message: t('Please choose flavor!') }]}
        label={t('Flavor')}
        name={['flavor']}>
        <FlavorSelect mode="multiple" />
      </Form.Item>
      <Form.Item
        rules={[{ required: true, message: t('Please choose glass type!') }]}
        label={t('Glass type')}
        name={['glassType']}>
        <GlassTypeSelectableList withTitle={false} mode="single" />
      </Form.Item>
      <Form.Item label={t('Garnish')} name={['decorationType']}>
        <Input
          onPressEnter={onTextAreaEnterPress}
          placeholder={t('Please, input garnish')}
        />
      </Form.Item>
      <Form.Item label={t('Special comment')} name={['specialComment']}>
        <Input
          onPressEnter={onTextAreaEnterPress}
          placeholder={t('Please, input special comment')}
        />
      </Form.Item>

      <Box display="flex">
        <Popconfirm
          disabled={!isDirty}
          title={`${t(
            'Are you sure you want to discard the changes you have made'
          )}?`}
          okText={t('Yes')}
          onConfirm={onCancel}
          cancelText={t('No')}>
          <ButtonAccent
            onClick={!isDirty && onCancel}
            type="secondary"
            mr={2}
            htmlType="button"
            mb={4}>
            {t('Cancel')}
          </ButtonAccent>
        </Popconfirm>
        <ButtonAccent
          type="primary"
          loading={loading}
          onClick={() => form.submit()}>
          {t('Save')}
        </ButtonAccent>
      </Box>
    </Form>
  )
}
CocktailAdvancedForm.propTypes = {
  initialData: PropTypes.object,
  group: PropTypes.array,
  showTitle: PropTypes.bool,
  customOnFinish: PropTypes.func,
  customOnCancel: PropTypes.func,
  onFinishCallback: PropTypes.func,
  customSaveCocktail: PropTypes.func
}

export default CocktailAdvancedForm
