const COCKTAIL_AVAILABILITIES = {
  YES: 'Yes',
  NO: 'No',
  POSSIBLE: 'Possible'
}

const COCKTAIL_AVAILABILITIES_KEYS = {
  YES: 'YES',
  NO: 'NO',
  POSSIBLE: 'POSSIBLE'
}

const COCKTAIL_AVAILABILITIES_OPTIONS = Object.keys(
  COCKTAIL_AVAILABILITIES
).map((key) => ({ label: COCKTAIL_AVAILABILITIES[key], value: key }))

export default COCKTAIL_AVAILABILITIES
export {
  COCKTAIL_AVAILABILITIES_OPTIONS,
  COCKTAIL_AVAILABILITIES,
  COCKTAIL_AVAILABILITIES_KEYS
}
