import { Col, Container, Option, Row, Title } from '@qonsoll/react-design'

import PropTypes from 'prop-types'
import { SelectWithFixedDropdown } from 'components'
import { getId } from 'services/api/firebase'
import { handleFilterOptions } from 'helpers'
import { useGetMethods } from 'domains/Method/hooks'
import { useMemo } from 'react'
import { useTranslations } from 'contexts/Translation'

// Takes a value of type object and onChange function
// Returns selected option object
const MethodSelect = (props) => {
  const {
    value,
    onChange,
    showTitle = true,
    allowClear = true,
    exclude = [],
    mode,
    ...rest
  } = props

  /* Getting all possibility variants for select */
  const [allMethods = [], loading] = useGetMethods()
  /* Getting translation instance */
  const { t } = useTranslations()

  /* Checking if mode is multiple */
  const isMultiple = useMemo(() => mode === 'multiple', [mode])
  /* Handling case when db record not added yet but value already exists and ready to be used */
  const valueComputed = useMemo(() => {
    if (isMultiple) return value
    return value?._id || value ? getId('Methods') : null
  }, [value, isMultiple])
  /* Filtering out excluded values */
  const optionsComputed = useMemo(() => {
    const _options = allMethods
    const filteredOptions = _options.filter((o) => !exclude.includes(o._id))
    return filteredOptions
  }, [allMethods, exclude])
  /* Handling change selected option */

  return (
    <Container {...rest}>
      {showTitle && (
        <Title mb={2} level={4}>
          {t('Method')}
        </Title>
      )}
      <Row noGutters>
        <Col>
          <SelectWithFixedDropdown
            mode={mode}
            loading={loading}
            onChange={onChange}
            allowClear={allowClear}
            aria-label="Select method"
            filterOption={handleFilterOptions}
            aria-describedby="Select method description"
            placeholder={t('Please, select method')}
            value={isMultiple ? valueComputed : valueComputed?._id}>
            {optionsComputed.map((method) => (
              <Option key={method?._id} value={method?._id}>
                {method?.name}
              </Option>
            ))}
          </SelectWithFixedDropdown>
        </Col>
      </Row>
    </Container>
  )
}

MethodSelect.propTypes = {
  value: PropTypes.object,
  onChange: PropTypes.func,
  showTitle: PropTypes.bool,
  allowClear: PropTypes.bool,
  exclude: PropTypes.array,
  mode: PropTypes.string
}

export default MethodSelect
