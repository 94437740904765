import { generateConstantsOptions } from 'helpers'

const WINE_SIMPLE_SUGAR_SCALES = {
  HIGH: 'High',
  MEDIUM: 'Medium',
  LOW: 'Low'
}

const WINE_SIMPLE_SUGAR_SCALES_OPTIONS = generateConstantsOptions(
  WINE_SIMPLE_SUGAR_SCALES
)

export { WINE_SIMPLE_SUGAR_SCALES_OPTIONS, WINE_SIMPLE_SUGAR_SCALES }
