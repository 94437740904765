import { Link, useLocation } from 'react-router-dom'
import { useExtraBreadcrumbItems, useTransformPathSnippers } from './hooks'

import { Breadcrumb } from 'antd'
import { COLLECTIONS } from '__constants__'
import { useTranslations } from 'contexts/Translation'

const HeaderBreadcrumbs = () => {
  // [ADDITIONAL_HOOKS]
  const { t } = useTranslations()
  const { pathname } = useLocation()

  // [COMPUTED_PROPERTIES]
  /* Splits a string into an array */
  const pathSnippets = pathname.split('/').filter((item) => item)

  const collectionsArray = Object.values(COLLECTIONS)

  const breadCrumbItem = useTransformPathSnippers(
    pathSnippets,
    collectionsArray
  )

  const extraBreadcrumbItems = useExtraBreadcrumbItems(
    pathSnippets,
    collectionsArray,
    breadCrumbItem
  )

  return (
    <Breadcrumb>
      <Breadcrumb.Item key={'/'}>
        <Link to="/" className="link-hover">
          {t('Home')}
        </Link>
      </Breadcrumb.Item>
      {extraBreadcrumbItems?.map(({ component }) => component)}
    </Breadcrumb>
  )
}

export default HeaderBreadcrumbs
