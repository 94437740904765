import { Box, Col, PageWrapper, Row, Text } from '@qonsoll/react-design'
import {
  EmptySearch,
  HeaderBreadcrumbs,
  PageActions,
  SimpleSearchInput,
  Spinner
} from 'components'
import { MenuList, MenuSortMenu } from 'domains/Menu/components'
import { useFilter, useSimpleSearch } from 'hooks'
import { useGetMenus, useMenuBaseActions } from 'domains/Menu/hooks'
import { useMemo, useRef } from 'react'

import Fuse from 'fuse.js'
import PATHS from 'pages/paths'
import PropTypes from 'prop-types'
import { useHistory } from 'react-router-dom'
import { useTranslations } from 'contexts/Translation'
// import { useCustomerContext } from 'contexts/CustomerContext'
import { useUser } from 'domains/User/context'

const MenusAllPage = (props) => {
  const { isListWithCreate, withHeader = true } = props
  /* Getting translations instance */
  const { t, language } = useTranslations()
  /* Current company id */
  // const { currentCustomerId, customer } = useCustomerContext()
  /* Getting filter params */
  const filterParams = useFilter('menu', {
    orderBy: ['_createdAt', 'desc']
  })
  const { user = {} } = useUser()

  const currentCompanyId = user?.currentCompanyId ?? null

  /* Destructuring filter params */
  const { filterVisibility, filterData, setFilterData } = filterParams
  /* Building filter query */
  const ref = useMemo(() => filterData, [filterData])
  /* Fetching menus */
  const [menus, loading] = useGetMenus(ref)
  /* Filtering menus, leaving only its own and shared with company */
  const menusFiltered = useMemo(
    () =>
      menus?.filter(({ _id, _createdBy, company, privacy }) => {
        if (!privacy) {
          return true
        }
        if (_createdBy === currentCompanyId) {
          return true
        }
        // if (
        //   company?.includes(currentCustomerId) &&
        //   _id !== customer?.automaticMenuId
        // ) {
        //   return true
        // }

        return false
      }),

    [menus, currentCompanyId]
  )

  const searchRef = useRef()
  const history = useHistory()

  /* Checking if list is empty */
  const isEmpty = !menus?.length

  const listActions = useMenuBaseActions()
  // object for search
  const fuseObject = useMemo(
    () =>
      menusFiltered
        ? new Fuse(menusFiltered, {
            // At what point does the match algorithm give up. 0 - max, 1 - min
            threshold: 0.1,
            keys: ['title', 'description', `name.${language}`, 'name']
          })
        : null,
    [language, menusFiltered]
  )

  /* Setup search for companies */
  const { onSearchInputChange, searchResult, searchLoading } = useSimpleSearch(
    menusFiltered,
    fuseObject,
    searchRef
  )

  const onSearch = () => {
    onSearchInputChange()
  }

  /* Configuring page header */
  const headingProps = {
    title: t('Cocktail menus'),
    marginBottom: 3,
    textAlign: 'left'
  }

  /* Configuring page actions */
  const actions = {
    sort: true,
    create: true
  }

  /* Handling opening of the create page */
  const handleCreate = () => history.push(PATHS.AUTHENTICATED.MENU_CREATE)

  /* Extracting base actions */
  const { onBack, onCreate } = listActions

  const isNoRelatedSearchResults = !searchResult?.length && !isEmpty
  const isRelatedSearchResults = searchResult?.length && !isEmpty

  return (
    <PageWrapper
      onBack={withHeader ? onBack : null}
      breadcrumbs={withHeader ? <HeaderBreadcrumbs /> : null}
      headingProps={withHeader ? headingProps : null}>
      <Row noGutters height="100%">
        {loading || searchLoading ? (
          <Col>
            <Spinner height="70vh" text={t('Menu loading')} />
          </Col>
        ) : (
          <Col mb={1} cw={!filterVisibility && 12}>
            <Row negativeBlockMargin>
              <Col cw={12} mb={4} flexDirection="row">
                <Box display="flex" width="100%">
                  <Box width="100%" mr={3}>
                    <SimpleSearchInput
                      searchItems={onSearch}
                      searchRef={searchRef}
                      placeholder={t('Search menus by name, description')}
                    />
                  </Box>
                  {
                    <PageActions
                      onCreate={handleCreate}
                      actions={actions}
                      createText={t('Create menu')}
                      sortMenu={
                        <MenuSortMenu
                          filteredData={filterData}
                          setFilteredData={setFilterData}
                        />
                      }
                    />
                  }
                </Box>
              </Col>
              {isRelatedSearchResults ? (
                <Col cw={12}>
                  <MenuList
                    isListWithCreate={isListWithCreate}
                    actions={{ onCreate }}
                    menus={searchResult}
                  />
                </Col>
              ) : isNoRelatedSearchResults ? (
                <Col cw={12}>
                  <EmptySearch />
                </Col>
              ) : (
                <Col cw={12}>
                  <Text
                    variant="overline"
                    color="var(--ql-state-disabled-color)">
                    {t('No menus have been created yet')}
                  </Text>
                </Col>
              )}
            </Row>
          </Col>
        )}
      </Row>
    </PageWrapper>
  )
}

MenusAllPage.propTypes = {
  isListWithCreate: PropTypes.bool,
  withHeader: PropTypes.bool
}

export default MenusAllPage
