import { useCallback, useEffect, useState } from 'react'

import { COLLECTIONS } from '__constants__'
import { getDocument } from 'services/api/firebase'

const useGetMenuMenuCategories = (menu) => {
  // Categories
  const [categories, setCategories] = useState(null)
  const [categoriesLoading, setCategoriesLoading] = useState(false)
  const [categoriesError, setCategoriesError] = useState(null)

  const getMenuCategories = useCallback(async (menuCategories) => {
    try {
      setCategoriesLoading(true)
      // Get the categories
      const categoriesData = await Promise.all(
        menuCategories?.map(async (category) => {
          const categoryData = await getDocument(
            COLLECTIONS.MENU_CATEGORIES,
            category
          )
          if (!categoryData) {
            return null
          }

          let value = {
            ...categoryData
          }

          if (value?.name === undefined) {
            const itemTypeData = await getDocument(
              COLLECTIONS.ITEM_TYPES,
              categoryData?.itemTypeId
            )
            value.name = itemTypeData?.label ?? { en: null, no: null }
          }

          if (
            categoryData?.itemsOrder &&
            categoryData?.itemsOrder?.length > 0
          ) {
            const itemsData = await Promise.all(
              categoryData?.itemsOrder?.map(
                async (item) => await getDocument(COLLECTIONS.MENU_ITEMS, item)
              )
            )
            value.itemsOrder = itemsData
          }

          return value
        })
      )
      setCategories(categoriesData?.filter(Boolean))
    } catch (error) {
      setCategoriesError(error)
    } finally {
      setCategoriesLoading(false)
    }
  }, [])

  useEffect(() => {
    menu?.categoriesOrder?.length && getMenuCategories(menu?.categoriesOrder)
  }, [getMenuCategories, menu?.categoriesOrder])

  return [categories, categoriesLoading, categoriesError]
}

export default useGetMenuMenuCategories
