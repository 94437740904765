import { Option } from '@qonsoll/react-design'
import PropTypes from 'prop-types'
import { SelectWithFixedDropdown } from 'components'
import { useTranslations } from 'contexts/Translation'

const RestaurantCurrencySelect = (props) => {
  const { value, onChange, listCurrencies, setMainCurrency } = props
  /* Getting translations instance */
  const { t } = useTranslations()

  /* Handling changes */
  const handleChange = (value) => {
    setMainCurrency?.(value)
    onChange(value)
  }

  return (
    <SelectWithFixedDropdown
      aria-label="Currency Selector"
      aria-describedby="currency-selector-description"
      value={value}
      onChange={handleChange}
      showSearch
      placeholder={t('Please choose a currency')}>
      {listCurrencies?.map((value, index) => (
        <Option key={value + index} value={value}>
          {value}
        </Option>
      ))}
    </SelectWithFixedDropdown>
  )
}

RestaurantCurrencySelect.propTypes = {
  value: PropTypes.object,
  setMainCurrency: PropTypes.func,
  onChange: PropTypes.func,
  customerCurrency: PropTypes.string,
  listCurrencies: PropTypes.array
}

export default RestaurantCurrencySelect
