import * as yup from 'yup'

import { attr, hasMany, hasManyWithCreate, hasOne, model } from 'services/model'

const validationSchema = yup.object().shape({
  title: yup
    .string()
    .typeError('Field title has wrong type')
    .required('Field title is required'),
  price: yup
    .number()
    .typeError('Field price has wrong type')
    .required('Field price is required'),
  description: yup.string().typeError('Field description has wrong type'),
  mediaObjects: yup
    .array()
    .typeError('Field mediaObjects has wrong type')
    .default(null)
    .nullable(),
  ingredients: yup
    .array()
    .typeError('Field ingredients has wrong type')
    .default(null)
    .nullable(),
  measurementTypes: yup
    .array()
    .typeError('Field measurementTypes has wrong type')
    .default(null)
    .nullable(),
  allergens: yup
    .array()
    .typeError('Field allergens has wrong type')
    .default(null)
    .nullable(),
  methods: yup
    .array()
    .typeError('Field methods has wrong type')
    .default(null)
    .nullable(),
  alcoholTasteTypes: yup
    .array()
    .typeError('Field alcoholTasteTypes has wrong type')
    .default(null)
    .nullable(),
  mainSpiritType: yup
    .string()
    .typeError('Field mainSpiritType has wrong type')
    .default(null)
    .nullable(),
  flavor: yup
    .string()
    .typeError('Field flavor has wrong type')
    .default(null)
    .nullable(),
  glassType: yup
    .string()
    .typeError('Field glassType has wrong type')
    .default(null)
    .nullable(),
  decorationType: yup
    .string()
    .typeError('Field decorationType has wrong type')
    .default(null)
    .nullable(),
  specialComment: yup
    .string()
    .typeError('Field specialComment has wrong type')
    .default(null)
    .nullable(),
  imageUrl: yup
    .string()
    .typeError('Field image url has wrong type')
    .default(null)
    .nullable()
})

const Cocktail = model(
  'cocktail',
  {
    title: attr('string'),
    price: attr('number'),
    description: attr('string'),
    mediaObjects: hasManyWithCreate('mediaObject'),
    ingredients: hasMany('ingredient'),
    measurementTypes: hasMany('measurementType'),
    allergens: hasMany('allergen'),
    methods: hasMany('method'),
    alcoholTasteTypes: hasMany('alcoholTasteType'),
    mainSpiritType: hasOne('mainSpiritType'),
    flavor: hasOne('flavor'),
    glassType: hasOne('glassType'),
    decorationType: hasOne('decorationType'),
    specialComment: hasOne('specialComment'),
    imageUrl: attr('string')
  },
  validationSchema
)

export default Cocktail
