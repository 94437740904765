const CURRENCIES = {
  NOK: 'NOK',
  USD: 'USD',
  EUR: 'EUR'
}

const CURRENCY_OPTIONS = Object.keys(CURRENCIES).map((key) => ({
  label: CURRENCIES[key],
  value: key
}))

export default CURRENCIES
export { CURRENCIES, CURRENCY_OPTIONS }
