import styled from 'styled-components'

const StyledWrapper = styled.div`
  max-height: calc(100vh - var(--list-creation-page-height-to-negotiate));
  overflow-y: scroll;
  margin-left: -20px;
  ::-webkit-scrollbar {
    width: hidden;
    width: 4px;
  }
  ::-webkit-scrollbar-track {
    visibility: hidden;
  }
  ::-webkit-scrollbar-thumb {
    visibility: hidden;
  }
  ::-webkit-scrollbar-button {
    visibility: hidden;
  }
  &:hover {
    ::-webkit-scrollbar {
      visibility: visible;
      width: 4px;
      height: 4px;
    }
    ::-webkit-scrollbar-track {
      visibility: visible;
      background-color: transparent;
      border-radius: 100px;
    }
    ::-webkit-scrollbar-thumb {
      visibility: visible;
      border-radius: 100px;
      background-image: linear-gradient(
        to bottom,
        var(--ql-bg-accent1-default) 0%,
        var(--ql-bg-accent1-lighten2) 100%
      );
    }
    ::-webkit-scrollbar-button {
      visibility: visible;
      background: transparent;
    }
  }
`
const styles = {
  addFieldButtonStyles: {
    fontWeight: '400',
    variant: 'body1',
    color: 'var(--ql-color-accent1)',
    cursor: 'pointer'
  }
}
export { StyledWrapper, styles }
