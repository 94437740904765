// session statuses: system
const CREATED_STATUS = 'CREATED'
const SENT_STATUS = 'SENT'
const ERROR_STATUS = 'ERROR'
const UNKNOWN_STATUS = 'UNKNOWN'

// session statuses: session publishing
const SKIPPED_STATUS = 'SKIPPED'
const FAILED_STATUS = 'FAILED'

export const PAYMENT_STATUSES = {
  CREATED_STATUS,
  SENT_STATUS,
  ERROR_STATUS,
  UNKNOWN_STATUS,
  SKIPPED_STATUS,
  FAILED_STATUS
}
