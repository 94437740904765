import { useEffect } from 'react'
import { Form } from 'antd'

const useInitializeMethodAdvancedForm = (initialData) => {
  /* Getting form instance */
  const [form] = Form.useForm()

  /* Setting initial data */
  useEffect(() => {
    if (initialData) {
      /* Getting initial values */
      const { method } = initialData

      /* Formatting date fields */
      const formValues = {
        method: {
          name: method?.['name'] ?? null,
          value: method?.['value'] ?? null,
          icon: method?.['icon'] ?? null
        }
      }

      /* Setting initial values to form instance */
      form.setFieldsValue(formValues)
    }
  }, [initialData, form])

  return { form }
}

export default useInitializeMethodAdvancedForm
