import { useHistory, generatePath } from 'react-router-dom'
import PATHS from 'pages/paths'
import { COLLECTIONS } from '__constants__'
import { useTranslations } from 'contexts/Translation'
import { deleteDocument } from 'services/api/firebase'
import { message } from 'antd'
import { useEvent } from 'hooks'
import { useMemo } from 'react'

/* Path's */
const createPath =
  PATHS.AUTHENTICATED.MEASUREMENT_TYPE_CREATE ||
  PATHS.AUTHENTICATED.MEASUREMENT_TYPE_WIZARD
const editPath = PATHS.AUTHENTICATED.MEASUREMENT_TYPE_EDIT
const showPath = PATHS.AUTHENTICATED.MEASUREMENT_TYPE_SHOW

const useMeasurementTypeBaseActions = ({ measurementTypeId, actions } = {}) => {
  /* Getting translations instance */
  const { t } = useTranslations()
  /* Getting history instance */
  const history = useHistory()

  /* Function to handle back click */
  const handleBack = useEvent(() => history.goBack())

  /* Handling opening of the create page */
  const handleCreate = useEvent(() => {
    history.push(createPath)
  })

  /* Handling opening of the show page */
  const handleOpen = useEvent(() => {
    const pathParams = { measurementTypeId }
    const path = generatePath(showPath, pathParams)
    history.push(path)
  })

  /* Handling opening of the edit page or change view to edit mode */
  const handleEdit = useEvent(() => {
    const pathParams = { measurementTypeId }
    const path = generatePath(editPath, pathParams)
    history.push(path)
  })

  const handleDelete = useEvent(async () => {
    try {
      await deleteDocument(COLLECTIONS.MEASUREMENT_TYPES, measurementTypeId)
      message.success(t('MeasurementType successfully deleted'))
    } catch (error) {
      message.error(error.message)
    }
  })

  return useMemo(
    () => ({
      onBack: handleBack,
      onCreate: handleCreate,
      onOpen: handleOpen,
      onEdit: handleEdit,
      onDelete: handleDelete,
      ...(actions || {}),
      createPath,
      editPath,
      showPath
    }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [
      actions?.onBack,
      actions?.onCreate,
      actions?.onDelete,
      actions?.onEdit,
      actions?.onOpen,
      handleBack,
      handleCreate,
      handleDelete,
      handleEdit,
      handleOpen
    ]
  )
}

export default useMeasurementTypeBaseActions
