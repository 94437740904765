import { Col, Row } from '@qonsoll/react-design'
import { Form, Popconfirm } from 'antd'
import {
  useInitializeObjectInstanceAdvancedForm,
  useObjectInstanceAdvancedFormActions,
  useObjectInstanceBaseActions
} from 'domains/ObjectInstance/hooks'

import { ButtonAccent } from 'components'
import { Icon } from '@qonsoll/icons'
import { ObjectInstanceFormItem } from 'domains/ObjectInstance/components'
import PropTypes from 'prop-types'
import { Tabs } from 'antd'
import _ from 'lodash'
import { useCheckIfFormWasUpdated } from 'hooks'
import { useTranslations } from 'contexts/Translation'

const ObjectInstanceAdvancedForm = (props) => {
  const {
    selectedLanguage,
    hideForm,
    tabsProps,
    objectProps,
    isDisabled,
    setIsDisabled
  } = props
  const { isNeedTranslations, onLanguageChange } = tabsProps
  const {
    objectInstanceData,
    objectFields,
    objectFieldName,
    updateItemTypeInstanceWithObjectValue,
    chosenObject,
    resetChosenObject
  } = objectProps
  const { t, language, languages } = useTranslations()

  // This is needed to make possible to edit labels for different languages
  const formItemPrefix = _.camelCase(objectFieldName)
  const { onFormFieldsChange, setFormWasNotUpdated } =
    useCheckIfFormWasUpdated()

  const { form } = useInitializeObjectInstanceAdvancedForm({
    initialData: objectFields,
    fieldName: objectFieldName,
    objectInstanceData: objectInstanceData,
    chosenObject: chosenObject
  })
  const { onFinish, loading, onSubmit } = useObjectInstanceAdvancedFormActions({
    initialData: objectFields,
    objectProps,
    form,
    formItemPrefix,
    hideForm,
    setFormWasNotUpdated,
    setIsDisabled
  })

  const { onDelete } = useObjectInstanceBaseActions({
    objectInstance: chosenObject,
    callback: updateItemTypeInstanceWithObjectValue,
    fieldName: objectFieldName,
    objectInstanceId: chosenObject?._id
  })
  const objectInstanceName =
    objectInstanceData?.name?.[selectedLanguage] ??
    objectInstanceData?.name?.en ??
    objectInstanceData?.name ??
    t('Object instance')

  const arrangeItemProps = (field) => ({
    parentFormItemName: null,
    listRef: field?.reference
  })
  const buttonLabel = chosenObject
    ? t('Save object instance')
    : t('Create object instance')

  const handleDelete = () => {
    onDelete()
    hideForm()
    setIsDisabled(true)
    setFormWasNotUpdated(true)
    form.resetFields()
    resetChosenObject()
  }

  return (
    <>
      {isNeedTranslations && (
        <Tabs onChange={onLanguageChange}>
          {languages?.map((language) => (
            <Tabs.TabPane key={language?.shortCode} tab={language?.name} />
          ))}
        </Tabs>
      )}
      <Form
        onFinish={onFinish}
        layout="vertical"
        form={form}
        onValuesChange={onFormFieldsChange}>
        <Form.Item name={formItemPrefix}>
          {objectFields?.map((field, index) => {
            const itemProps = arrangeItemProps(field)
            return (
              <ObjectInstanceFormItem
                key={`${index}-object-instance-form-item`}
                itemProps={itemProps}
                selectedLanguage={selectedLanguage}
                language={language}
                index={index}
                formItemPrefix={formItemPrefix}
                field={field}
                form={form}
                setIsDisabled={setIsDisabled}
              />
            )
          })}
        </Form.Item>
      </Form>
      <Row noGutters>
        {chosenObject && (
          <Col cw="auto">
            <Popconfirm
              title={`${t(
                `Are you sure you want to delete this ${objectInstanceName}`
              )}?`}
              okText={t('Yes')}
              onConfirm={handleDelete}
              cancelText={t('No')}>
              <ButtonAccent
                danger
                icon={<Icon name="Trash4Outlined" />}
                type="secondary"
                mr={2}
                htmlType="button"
              />
            </Popconfirm>
          </Col>
        )}
        <Col cw="auto">
          <ButtonAccent
            type="primary"
            loading={loading}
            onClick={onSubmit}
            disabled={isDisabled}>
            {buttonLabel}
          </ButtonAccent>
        </Col>
      </Row>
    </>
  )
}

ObjectInstanceAdvancedForm.propTypes = {
  selectedLanguage: PropTypes.string,
  hideForm: PropTypes.func,
  tabsProps: PropTypes.object,
  objectProps: PropTypes.object,
  isDisabled: PropTypes.bool,
  setIsDisabled: PropTypes.func
}

export default ObjectInstanceAdvancedForm
