import { COLLECTIONS } from '__constants__'
import { doc } from 'firebase/firestore'
import { useGetDocumentsByIds, useLoading } from 'hooks'
import { useMemo } from 'react'
import { useDocumentData } from 'react-firebase-hooks/firestore'
import { firestore } from 'services/firebase'

const useGetCategoryDrawerInitialValues = (categoryId) => {
  const [categoryData, categoryDataLoading] = useDocumentData(
    categoryId && doc(firestore, COLLECTIONS.MENU_CATEGORIES, categoryId)
  )

  const [itemTypeData, itemTypeLoading] = useDocumentData(
    categoryData?.itemTypeId &&
      doc(firestore, COLLECTIONS.ITEM_TYPES, categoryData?.itemTypeId)
  )

  const menuItemsOptions = useMemo(
    () => ({
      collectionName: COLLECTIONS.MENU_ITEMS,
      entityIds: Object.keys(itemTypeData?.menuItems || {})
    }),
    [itemTypeData?.menuItems]
  )

  const [allItemTypeMenuItems, menuItemsLoading] =
    useGetDocumentsByIds(menuItemsOptions)

  const itemTypeFieldsOptions = useMemo(
    () => ({
      collectionName: COLLECTIONS.ITEM_TYPES_FIELDS,
      entityIds: itemTypeData?.fieldsOrder || []
    }),
    [itemTypeData?.fieldsOrder]
  )

  const [itemTypeFields, itemTypeFieldsLoading] = useGetDocumentsByIds(
    itemTypeFieldsOptions
  )

  const possibleOptions = useMemo(
    () =>
      allItemTypeMenuItems?.filter(
        ({ _id }) =>
          !categoryData?.itemsOrder?.some(
            (existingItem) => existingItem === _id
          )
      ),
    [allItemTypeMenuItems, categoryData]
  )

  const loading = useLoading([
    categoryDataLoading,
    menuItemsLoading,
    itemTypeLoading,
    itemTypeFieldsLoading
  ])

  return { possibleOptions, itemTypeData, loading, itemTypeFields }
}

export default useGetCategoryDrawerInitialValues
