const handleLanguage = (data, language) => {
  // Check if the language exists and has a non-empty value
  if (data?.[language] && data?.[language]?.length > 0) {
    return data?.[language]
  }

  // Check if the English translation exists and has a non-empty value
  if (data?.en && data?.en?.length > 0) {
    return data.en
  }

  // Check if the name field exists and has a non-empty value
  if (data?.name && data?.name?.length > 0) {
    return data?.name
  }

  // If none of the above conditions are true, return the original data object
  return data
}

export default handleLanguage
