import { Form } from 'antd'
import { generateNewObjectField } from 'domains/Object/helpers'
import { useEffect } from 'react'

const useInitializeItemTypeAdvancedForm = (initialData) => {
  const [form] = Form.useForm()

  const { fields } = initialData || {}
  const newField = generateNewObjectField()

  useEffect(() => {
    // if initialData -> display its data
    // if not initialData -> display default form-field
    const listFieldsComputed = initialData
      ? Object.values(fields)?.reduce((acc, item, index) => {
          acc[item?._id] = {
            ...item,
            isDisabledChanges: item?.name === 'name' ? true : false,
            order: index
          }
          return acc
        }, {})
      : {
          [newField?.uid]: {
            ...newField,
            name: 'name',
            isDisabledChanges: true,
            order: 0
          }
        }

    const initialValues = {
      itemType: {
        name: initialData?.name || '',
        label: initialData?.label || ''
      },
      fields: listFieldsComputed
    }

    form.setFieldsValue(initialValues)
    // do not add form in dep here, because it will cause exccess paint of form-item on rerender
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [initialData, fields])

  return { form }
}

export default useInitializeItemTypeAdvancedForm
