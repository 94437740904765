import { useMemo } from 'react'
import { useTranslations } from 'contexts/Translation'
import StyledLanguageSelect from './LanguageSelect.styles'
import { LANGUAGE_SELECT_VARIANTS } from '__constants__'
import PropTypes from 'prop-types'

const LanguageSelect = (props) => {
  const { variant = LANGUAGE_SELECT_VARIANTS.FULL } = props

  const { setCurrentLanguage, language, languages, loading } = useTranslations()

  const handleChange = (value) => {
    setCurrentLanguage(value)
  }

  const options = useMemo(
    () =>
      languages
        // .filter(({ value }) => value !== language)
        // temporarily commented out until there is currently no correct solution
        // for not displaying the selected language in the select
        .map(({ name, value, label }) => ({
          // Set short codes as labels if select has simple variant and label is select is has full variant
          label:
            variant === LANGUAGE_SELECT_VARIANTS.SIMPLE
              ? value?.toUpperCase()
              : label,
          name,
          value
        })),
    [languages, variant]
  )

  const defaultLanguage = useMemo(() => {
    // Find current variant

    const currentLanguage =
      languages.find(({ value }) => value === language) || languages?.at(0)

    // Set short code as label if select has simple variant and label is select is has full variant
    return variant === LANGUAGE_SELECT_VARIANTS.SIMPLE
      ? { ...currentLanguage, label: currentLanguage?.shortCode?.toUpperCase() }
      : currentLanguage
  }, [language, languages, variant])

  return (
    <StyledLanguageSelect
      aria-label="Language Select"
      aria-describedby="language-select-description"
      onSelect={handleChange}
      defaultValue={defaultLanguage?.value}
      options={options}
      loading={loading}
    />
  )
}

LanguageSelect.propTypes = {
  variant: PropTypes.oneOf([...Object.values(LANGUAGE_SELECT_VARIANTS)])
}

export default LanguageSelect
