import { Col, Row } from '@qonsoll/react-design'
import { Form, Popconfirm, Tabs } from 'antd'
import {
  ItemTypeFieldsForm,
  ItemTypeSimpleForm
} from 'domains/ItemType/components'
import {
  useActionsItemTypeAdvancedForm,
  useInitializeItemTypeAdvancedForm
} from 'domains/ItemType/hooks'

import { ButtonAccent } from 'components'
import PropTypes from 'prop-types'
import { useState } from 'react'
import { useTranslations } from '@qonsoll/translation'

const ItemTypeAdvancedForm = (props) => {
  const { initialData } = props

  const { languages } = useTranslations()

  const [selectedLanguage, setSelectedLanguage] = useState('en')

  const onLanguageChange = (key) => {
    setSelectedLanguage(key)
  }

  const { form } = useInitializeItemTypeAdvancedForm(initialData)
  const { onFinish, onReset, loading } =
    useActionsItemTypeAdvancedForm(initialData)

  const { t } = useTranslations()

  const onSubmit = () => form.submit()

  const submitButtonText = initialData ? 'Edit item type' : 'Create item type'

  return (
    <Form onFinish={onFinish} form={form} layout="vertical">
      <Tabs onChange={onLanguageChange}>
        {languages?.map((language) => (
          <Tabs.TabPane key={language?.shortCode} tab={language?.name} />
        ))}
      </Tabs>
      <Row noGutters>
        <Col cw={12}>
          <Form.Item name={['itemType']}>
            <ItemTypeSimpleForm
              form={form}
              selectedLanguage={selectedLanguage}
            />
          </Form.Item>
          <Form.Item name={['fields']}>
            <ItemTypeFieldsForm selectedLanguage={selectedLanguage} />
          </Form.Item>
        </Col>
      </Row>
      <Row noGutters>
        <Col cw="auto">
          <Popconfirm
            title={`${t(
              'Are you sure you want to discard the changes you have made'
            )}?`}
            okText={t('Yes')}
            onConfirm={onReset}
            cancelText={t('No')}>
            <ButtonAccent
              onClick={onReset}
              type="secondary"
              mr={2}
              htmlType="button">
              {t('Cancel')}
            </ButtonAccent>
          </Popconfirm>
        </Col>
        <Col cw="auto">
          <ButtonAccent type="primary" loading={loading} onClick={onSubmit}>
            {t(submitButtonText)}
          </ButtonAccent>
        </Col>
      </Row>
    </Form>
  )
}

ItemTypeAdvancedForm.propTypes = {
  initialData: PropTypes.object
}

export default ItemTypeAdvancedForm
