import { model, attr, hasManyWithCreate } from 'services/model'
import * as yup from 'yup'

const validationSchema = yup.object().shape({
  title: yup
    .string()
    .typeError('Field title has wrong type')
    .default(null)
    .nullable(),
  description: yup
    .string()
    .typeError('Field description has wrong type')
    .required('Field description is required'),
  content: yup
    .string()
    .typeError('Field content has wrong type')
    .default(null)
    .nullable(),
  mediaObjects: yup
    .array()
    .typeError('Field mediaObjects has wrong type')
    .default(null)
    .nullable()
})

const TipAndTrick = model(
  'tipAndTrick',
  {
    title: attr('string'),
    description: attr('string'),
    content: attr('string'),
    mediaObjects: hasManyWithCreate('mediaObject')
  },
  validationSchema
)

export default TipAndTrick
