import { useTranslations } from '@qonsoll/translation'
import { COLLECTIONS } from '__constants__'
import { message } from 'antd'
import { transformArrayToObject } from 'helpers'
import { useState } from 'react'
import { updateDocument } from 'services/api/firebase/helpers'

const useExistingMenuActions = (categoryId, existingItems, toggleAdd) => {
  const [menuItemsSelected, setMenuItemsSelected] = useState([])

  const { t } = useTranslations()

  const handleMenuItemsSelectChange = (keys) => {
    setMenuItemsSelected(keys)
  }

  const addItemsToCurrentMenuCategory = async (menuItems = []) => {
    const existingItemsIds = existingItems?.map(({ _id }) => _id)

    const newItemsOrder = [...(existingItemsIds || []), ...(menuItems || [])]

    const newItemsObject = transformArrayToObject(newItemsOrder)

    await updateDocument(COLLECTIONS.MENU_CATEGORIES, categoryId, {
      items: newItemsObject,
      itemsOrder: newItemsOrder
    })
  }

  const handleSelectOptions = async () => {
    try {
      await addItemsToCurrentMenuCategory(menuItemsSelected)

      message.success(t('Menu items added successfully'))

      setMenuItemsSelected([])

      toggleAdd?.()
    } catch (error) {
      message.error(
        `${t('Something went wrong during adding items')}, ${error?.message}`
      )
    }
  }

  const onCreateNewMenuItem = async (values) => {
    try {
      const { _id } = values?.menuItem || {}

      const existingItemsIds = existingItems?.map(({ _id }) => _id)

      if (existingItemsIds?.includes(_id)) return

      if (_id) {
        await addItemsToCurrentMenuCategory([_id])

        toggleAdd?.()
      }
    } catch (error) {
      message.error(
        `${t('Something went wrong during adding items')}, ${error?.message}`
      )
    }
  }

  return {
    menuItemsSelected,
    handleMenuItemsSelectChange,
    handleSelectOptions,
    onCreateNewMenuItem
  }
}

export default useExistingMenuActions
