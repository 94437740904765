import { useTranslations } from '@qonsoll/translation'
import { getAppropriateLabel, toLowerCamelCase } from 'helpers'

const useFieldSimpleFormValidationRules = (listsFormValue, field) => {
  const { t } = useTranslations()

  const validateFieldUniqueName = (_, value) => {
    const isFieldUnique = Object.values(listsFormValue)?.every((formField) => {
      return formField?.uid !== field?.uid
        ? toLowerCamelCase(getAppropriateLabel(formField?.label)) !==
            toLowerCamelCase(value)
        : true
    })

    if (value && !isFieldUnique) {
      return Promise.reject(new Error(t('Field with this name already exists')))
    } else {
      return Promise.resolve()
    }
  }

  const validateEnglishLabel = (_, value) => {
    const currentField = Object.values(listsFormValue)?.find(
      (formField) => formField?.uid === field?.uid
    )

    if (currentField?.label?.en?.trim() === '') {
      return Promise.reject(new Error(t('English label is required')))
    } else {
      return Promise.resolve()
    }
  }

  return {
    validateFieldUniqueName,
    validateEnglishLabel
  }
}

export default useFieldSimpleFormValidationRules
