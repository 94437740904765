import { COLLECTIONS, LIST_FIELD_DATA_TYPES } from '__constants__'
import { useEffect, useState } from 'react'
import { getDocument } from 'services/api/firebase'
import { message } from 'antd'
import { useTranslations } from 'contexts/Translation'

const useGetMenuItemDataEdit = (itemTypeId, menuItemId) => {
  const [menuItemData, setMenuItemData] = useState(null)
  const [menuItemDataLoading, setMenuItemDataLoading] = useState(false)
  const { t } = useTranslations()

  useEffect(() => {
    const fetchData = async () => {
      try {
        const [itemType, menuItem] = await Promise.all([
          getDocument(COLLECTIONS.ITEM_TYPES, itemTypeId),
          getDocument(COLLECTIONS.MENU_ITEMS, menuItemId)
        ])
        if (!itemType || !menuItem) {
          return
        }

        const combinedFields = await Promise.all(
          itemType.fieldsOrder?.map(async (fieldId) => {
            const itemTypeField = await getDocument(
              COLLECTIONS.ITEM_TYPES_FIELDS,
              fieldId
            )
            if (!itemTypeField) {
              return null
            }
            const { relationType, label, name } = itemTypeField
            let value = null
            let isObject = true
            if (itemTypeField?.object) {
              if (
                itemTypeField?.relationType ===
                  LIST_FIELD_DATA_TYPES.HAS_MANY &&
                menuItem[name]
              ) {
                const array = await Promise.all(
                  menuItem[name]?.map(
                    async (id) =>
                      id && getDocument(COLLECTIONS.OBJECT_INSTANCES, id)
                  )
                )
                value = array
              } else if (
                itemTypeField?.relationType === LIST_FIELD_DATA_TYPES.HAS_ONE &&
                menuItem[name]
              ) {
                value = [
                  await getDocument(
                    COLLECTIONS.OBJECT_INSTANCES,
                    menuItem[name]
                  )
                ]
              }
            }
            return { _id: fieldId, relationType, label, value, isObject, name }
          })
        )

        setMenuItemData(combinedFields.filter(Boolean))
      } catch (error) {
        message.error(`${t('Error during fetching data')}: ${error?.message}`)
      } finally {
        setMenuItemDataLoading(false)
      }
    }

    fetchData()
  }, [itemTypeId, menuItemId, t])

  return [menuItemData, menuItemDataLoading]
}

export default useGetMenuItemDataEdit
