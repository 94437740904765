import { ButtonAccent, PageActions, Spinner } from 'components'
import { Col, PageWrapper, Row } from '@qonsoll/react-design'
import {
  CustomerAdvancedView,
  CustomerWizard
} from 'domains/Customer/components'
import { generatePath, useHistory } from 'react-router-dom'

import { COLLECTIONS, USER_ROLES_KEYS } from '__constants__'
import { Icon } from '@qonsoll/icons'
import PATHS from 'pages/paths'
import { useConfirmLogout } from 'components/BoilerplateLayout/hooks'
import { useCustomerContext } from 'contexts/CustomerContext'
import { useDeleteCompany } from 'domains/Customer/hooks'
import { useListenDocument } from 'services/api/firebase'
import { useTranslations } from 'contexts/Translation'
import { useUser } from 'domains/User/context'
import { useLoading } from 'hooks'

const CustomerShowPage = (props) => {
  /* Getting translations instance */
  const { t } = useTranslations()
  /* Getting history instance */
  const history = useHistory()
  const confirmLogout = useConfirmLogout()

  const { user, loading: currentUserLoading } = useUser()
  const [userData, userDataLoading] = useListenDocument({
    ref: user ? COLLECTIONS.USERS + '/' + user?._id : null
  })

  const {
    customer,
    loading,
    onCurrentCustomerChange,
    currentCustomerId,
    currentCustomerLoading
  } = useCustomerContext()

  const isUserOwner =
    user?.companyRoles?.[customer?._id] === USER_ROLES_KEYS.COMPANY_OWNER

  const deleteCompany = useDeleteCompany({ customer })

  const headingProps = {
    marginBottom: 3,
    title: t('My Profile'),
    textAlign: 'left'
  }

  const actions = { edit: !!customer }

  const handleEdit = () => {
    const pathParams = { profileId: user?._id }
    const path = generatePath(PATHS.AUTHENTICATED.PROFILE_EDIT, pathParams)
    history.push(path)
  }

  const computedHeading = userData?._companies?.length ? headingProps : null
  const displayLogout = userData?._companies?.length ? 'none' : 'block'
  const handleDelete = () => deleteCompany(currentCustomerId, user?._id)

  const dataLoading = useLoading([
    loading,
    currentUserLoading,
    userDataLoading,
    currentCustomerLoading
  ])

  return (
    <PageWrapper
      action={
        <Row noGutters>
          {isUserOwner ? (
            <>
              <Col mr={2}>
                <PageActions
                  actions={actions}
                  editText={t('Edit Profile')}
                  onEdit={handleEdit}
                />
              </Col>
              <Col mr={2}>
                <ButtonAccent
                  type="secondary"
                  danger
                  onClick={handleDelete}
                  icon={<Icon name="Trash4Outlined" />}
                />
              </Col>
            </>
          ) : null}
          <Col display={displayLogout}>
            <ButtonAccent
              onClick={confirmLogout}
              type="secondary"
              danger
              icon={<Icon name="LogOut1Outlined" />}
            />
          </Col>
        </Row>
      }
      headingProps={computedHeading}>
      <Row noGutters h="center">
        {dataLoading ? (
          <Col>
            <Spinner height="70vh" text={t('Customer is loading')} />
          </Col>
        ) : (
          <>
            {!customer ? (
              <Col cw={[12, 12, 10, 5, 5]}>
                <CustomerWizard
                  onFinish={(customer) => {
                    onCurrentCustomerChange(customer?._id)
                  }}
                />
              </Col>
            ) : (
              <Col cw={12}>
                <CustomerAdvancedView customer={customer} user={user} />
              </Col>
            )}
          </>
        )}
      </Row>
    </PageWrapper>
  )
}

export default CustomerShowPage
