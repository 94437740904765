const TRAINING_MATERIALS_AVAILABILITIES = {
  AVAILABLE: 'Available',
  EXTRA_MATERIAL: 'Extra material'
}
const TRAINING_MATERIALS_AVAILABILITIES_KEYS = {
  AVAILABLE: 'AVAILABLE',
  EXTRA_MATERIAL: 'EXTRA_MATERIAL'
}

const TRAINING_MATERIALS_AVAILABILITIES_OPTIONS = Object.keys(
  TRAINING_MATERIALS_AVAILABILITIES
).map((key) => ({ label: TRAINING_MATERIALS_AVAILABILITIES[key], value: key }))

export default TRAINING_MATERIALS_AVAILABILITIES
export {
  TRAINING_MATERIALS_AVAILABILITIES_OPTIONS,
  TRAINING_MATERIALS_AVAILABILITIES,
  TRAINING_MATERIALS_AVAILABILITIES_KEYS
}
