import {
  createDocument,
  getId,
  updateDocument,
  updateParent
} from 'services/api/firebase'

import { COLLECTIONS } from '__constants__'
import { Zone } from 'models'
import { message } from 'antd'
import { useCustomerContext } from 'contexts/CustomerContext'
import { useGetErrorText } from 'hooks'
import { useHistory } from 'react-router-dom'
import { useState } from 'react'
import { useTranslations } from 'contexts/Translation'

const useActionsZoneAdvancedForm = ({ initialData, form } = {}) => {
  /* State for loading */
  const [loading, setLoading] = useState(false)
  /* Getting translations instance */
  const { t } = useTranslations()
  /* Getting history instance */
  const history = useHistory()
  /* Getting history state */
  const historyState = history.location.state
  /* Getting current company id */
  const { currentCustomerId } = useCustomerContext()
  const getErrorText = useGetErrorText()
  /* Function for preparing values for saving */
  const prepareValues = async (values = {}, additionalValues = {}) => {
    /* Getting id */
    const zoneId = initialData?.['zone']?._id || getId(COLLECTIONS.ZONES)

    /* Preparing table values */
    const preparedValues = {
      _id: zoneId,
      ...additionalValues,
      title: values?.['zone']?.['title'] ?? null,
      available: values?.['zone']?.['available'] ?? null
    }
    if (!initialData) {
      preparedValues.company = [currentCustomerId]
    }

    return preparedValues
  }

  /* Saving form data */
  const saveForm = async (values, callback) => {
    try {
      // Prepare data to be saved
      const data = await prepareValues(values)

      if (historyState?.parent)
        await updateParent(historyState.parent, data._id)

      // Save data
      if (initialData) {
        await updateDocument(COLLECTIONS.ZONES, initialData.zone?._id, data)
        message.success(t('Zone successfully updated'))
      } else {
        await createDocument(COLLECTIONS.ZONES, data, data?._id)
        message.success(t('Zone successfully created'))
      }
      // Final callback
      callback?.()
    } catch (error) {
      throw new Error(getErrorText(error))
    }
  }

  /* Function for validating form */
  const validateForm = (values) => {
    try {
      // Prepare data to be validated
      const validationData = {
        title: values?.['zone']?.['title']
      }
      Zone.validationSchema.validateSync(validationData)
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error(error)
      throw new Error(t('Zone validation error: ') + t(error.message))
    }
  }

  /* On finish callback */
  const onFinish = async () => {
    if (loading) return // Avoid multiple calls

    try {
      setLoading(true)
      // Get form values
      const formValues = form.getFieldsValue()
      // Validate fields
      validateForm(formValues)
      // Final callback
      const callback = () => history.goBack()
      // Save data
      await saveForm(formValues, callback)

      setLoading(false)
    } catch (error) {
      setLoading(false)
      message.error(error.message)
    }
  }

  /* On cancel callback */
  const onReset = () => {
    form.resetFields()
    history.goBack()
  }

  return { onFinish, onReset, loading, saveForm, validateForm, prepareValues }
}

export default useActionsZoneAdvancedForm
