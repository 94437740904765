import _ from 'lodash'
import { useMemo } from 'react'

const useGetEnumString = (items) => {
  const singleStringName = typeof items !== 'string' || !items ? 'n/a' : items
  const itemsEnumString = useMemo(
    () =>
      Array.isArray(items) && items?.length
        ? items
            ?.map((item, index) =>
              index === 0 ? _.capitalize(item) : item?.toLowerCase()
            )
            .join(', ')
        : singleStringName,
    [items, singleStringName]
  )

  return itemsEnumString
}

export default useGetEnumString
