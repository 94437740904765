import { COLLECTIONS } from '__constants__'
import { useDocumentData } from 'react-firebase-hooks/firestore'
import { doc } from 'firebase/firestore'
import { firestore } from 'services/firebase'

const useGetCategoryData = (categoryId) => {
  const categoryData = useDocumentData(
    categoryId && doc(firestore, COLLECTIONS.MENU_CATEGORIES, categoryId)
  )

  return [...categoryData]
}
export default useGetCategoryData
