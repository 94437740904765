import { capitalize } from 'helpers'

export const CONTENT_TYPES = {
  VIDEO: ['mp4', 'mov', 'avi', 'wmv'],
  PRESENTATION: ['ppt', 'pptx', 'pdf']
}

export const CONTENT_TYPES_LABELS = Object.keys(CONTENT_TYPES).reduce(
  (obj, val) => {
    obj[val] = val
    return obj
  },
  {}
)

export const CONTENT_TYPES_LABELS_OPTIONS = Object.keys(CONTENT_TYPES).map(
  (key) => ({ label: capitalize(key), value: key })
)
