import { generatePath, useHistory } from 'react-router-dom'
import { message, notification } from 'antd'

import { ButtonAccent } from 'components'
import { COLLECTIONS } from '__constants__'
import PATHS from 'pages/paths'
import { deleteDocument } from 'services/api/firebase'
import { useEvent } from 'hooks'
import { useMemo } from 'react'
import { useTranslations } from 'contexts/Translation'

/* Path's */
const createPath = PATHS.AUTHENTICATED.OBJECT_CREATE
const editPath = PATHS.AUTHENTICATED.OBJECT_EDIT
const showPath = PATHS.AUTHENTICATED.OBJECTS_SHOW

const useObjectBaseActions = ({ objectId, actions, name } = {}) => {
  /* Getting translations instance */
  const { t } = useTranslations()
  /* Getting history instance */
  const history = useHistory()

  /* Function to handle back click */
  const handleBack = useEvent(() => history.goBack())

  /* Handling opening of the create page */
  const handleCreate = useEvent(() => {
    history.push(createPath)
  })

  /* Handling opening of the show page */
  const handleOpen = useEvent(() => {
    const pathParams = { objectId }
    const path = generatePath(showPath, pathParams)
    history.push(path)
  })

  /* Handling opening of the edit page or change view to edit mode */
  const handleEdit = useEvent(() => {
    const pathParams = { objectId }
    const path = generatePath(editPath, pathParams)
    history.push(path)
  })

  const removeDocument = useEvent(async (key) => {
    try {
      await deleteDocument(COLLECTIONS.OBJECTS, objectId)

      message.success(t('Object successfully deleted'))
    } catch (error) {
      message.error(error.message)
    } finally {
      notification.close(key)
    }
  })

  const handleDelete = useEvent(() => {
    const key = `open${Date.now()}`
    const btn = (
      <ButtonAccent
        danger
        size="small"
        type="secondary"
        onClick={() => removeDocument(key)}>
        {t('Confirm')}
      </ButtonAccent>
    )

    notification.warn({
      message: `${t('Are you sure you want to delete')} ${name}?`,
      btn,
      key
    })
  })

  return useMemo(
    () => ({
      onBack: handleBack,
      onCreate: handleCreate,
      onOpen: handleOpen,
      onEdit: handleEdit,
      onDelete: handleDelete,
      ...(actions || {}),
      createPath,
      editPath,
      showPath
    }),
    [actions, handleBack, handleCreate, handleDelete, handleEdit, handleOpen]
  )
}

export default useObjectBaseActions
