import { useGetDocumentsByIds } from 'hooks'
import { COLLECTIONS } from '__constants__'
import { useEffect, useState, useCallback } from 'react'
import { getDocument } from 'services/api/firebase'

const useGetAllergensFromRecipe = (cocktail) => {
  const [recipeData, recipeDataLoading] = useGetDocumentsByIds({
    collectionName: COLLECTIONS.RECIPES,
    entityIds: cocktail?.recipes
  })
  /* State for data */
  const [allergens, setAllergens] = useState([])
  /* State for loading */
  const [loading, setLoading] = useState(!!cocktail?.recipes?.length)
  /* State for error */
  const [error, setError] = useState(null)

  const getAllergens = useCallback(async () => {
    try {
      setLoading(true)
      /* Getting ingredients promises*/
      const promises = recipeData?.map((item) =>
        getDocument(COLLECTIONS.INGREDIENTS, item?.ingredient)
      )

      /* Getting ingredients data */
      const data = await Promise.allSettled(promises)
      const valueFromData = data?.map((item) => item?.value)

      /* Getting allergens promises from ingredients */
      const promisesAllergensFromIngredients = valueFromData
        ?.map((item) =>
          item?.allergens?.map((allergenId) =>
            getDocument(COLLECTIONS.ALLERGENS, allergenId)
          )
        )
        ?.flat()
        ?.filter(Boolean)

      /* Getting allergens data from ingredients */
      const allergenDataFromIngredient = await Promise.allSettled(
        promisesAllergensFromIngredients
      )
      const allergensFromIngredient = allergenDataFromIngredient?.map(
        (item) => item?.value
      )

      /* Getting allergens promises*/
      const promisesAllergens = cocktail?.allergens?.map((allergenId) =>
        getDocument(COLLECTIONS.ALLERGENS, allergenId)
      )

      /* Getting allergens data */
      const allergenData = await Promise.all(promisesAllergens)

      /* Removing duplicates from the array. */
      const uniqAllergens = [
        ...allergenData,
        ...allergensFromIngredient
      ]?.filter(
        (item, index, self) =>
          self?.findIndex((secondItem) => secondItem?._id === item?._id) ===
          index
      )

      setAllergens(uniqAllergens)
    } catch (error) {
      setError(error)
    }
    setLoading(false)
  }, [cocktail, recipeData])

  useEffect(() => {
    !recipeDataLoading && recipeData?.length && getAllergens()
  }, [getAllergens, recipeData, recipeDataLoading])

  return [allergens, loading, error, getAllergens]
}

export default useGetAllergensFromRecipe
