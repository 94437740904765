import { Popconfirm, message } from 'antd'
import { deleteDocument, updateDocument } from 'services/api/firebase'
import { generatePath, useHistory } from 'react-router-dom'

import { Box } from '@qonsoll/react-design'
import { ButtonAccent } from 'components'
import { COLLECTIONS } from '__constants__'
import { Icon } from '@qonsoll/icons'
import PATHS from 'pages/paths'
import PropTypes from 'prop-types'
import { arrayRemove } from 'firebase/firestore'
import { useCallback } from 'react'
import { useCustomerContext } from 'contexts/CustomerContext'
import { useTranslations } from 'contexts/Translation'

const CocktailActionsColumn = (props) => {
  const { cocktail } = props

  const history = useHistory()
  const { t } = useTranslations()
  const { currentCustomerId, customer } = useCustomerContext()

  const cocktailId = cocktail?.cocktailId

  const onShowClick = useCallback(
    (cocktailId) => {
      const path = generatePath(PATHS.AUTHENTICATED.COCKTAIL_SHOW, {
        cocktailId
      })

      history.push(path)
    },
    [history]
  )
  const onEditClick = useCallback(
    (cocktailId) => {
      const path = generatePath(PATHS.AUTHENTICATED.COCKTAIL_EDIT, {
        cocktailId
      })

      history.push(path)
    },
    [history]
  )

  const onDelete = useCallback(
    async (cocktailId) => {
      await updateDocument(COLLECTIONS.MENUS, customer?.automaticMenuId, {
        menuConfig: arrayRemove({ _id: cocktailId })
      })

      await deleteDocument(
        `${COLLECTIONS.MENUS}/${customer?.automaticMenuId}/${COLLECTIONS.COCKTAILS}`,
        cocktailId
      )
      message.success(t('Successfully deleted drink'))
    },
    [customer?.automaticMenuId, t]
  )

  const showCocktail = () => onShowClick(cocktailId)
  const editCocktail = () => onEditClick(cocktailId)
  const deleteCocktail = () => onDelete(cocktailId)

  return (
    <Box display="flex">
      <ButtonAccent
        mr={2}
        icon={
          <Box>
            <Icon name="EyeOutlined" />
          </Box>
        }
        onClick={showCocktail}
        type="text"
      />
      {cocktail?._createdBy === currentCustomerId && (
        <>
          <ButtonAccent
            mr={cocktail?._createdBy === currentCustomerId && 2}
            icon={
              <Box>
                <Icon name="Edit3Outlined" />
              </Box>
            }
            onClick={editCocktail}
            type="text"
          />
          <Popconfirm
            title={`${t('Are you sure you want to remove')} ${cocktail?.name}?`}
            okText={t('Yes')}
            onConfirm={deleteCocktail}
            cancelText={t('No')}>
            <ButtonAccent
              type="text"
              danger
              icon={<Icon name="Trash4Outlined" />}
            />
          </Popconfirm>
        </>
      )}
    </Box>
  )
}

CocktailActionsColumn.propTypes = {
  cocktail: PropTypes.object,
  menuId: PropTypes.string,
  cocktailsAvailabilityAndPrice: PropTypes.array
}

export default CocktailActionsColumn
