import { COLLECTIONS } from '__constants__'
import { message } from 'antd'
import { updateDocument } from 'services/api/firebase'
import { useMemo } from 'react'
import { useTranslations } from 'contexts/Translation'
import { useUser } from 'domains/User/context'

const useTermsAndConditionsPopconfirmActions = () => {
  const { user } = useUser()
  const { t } = useTranslations()

  const buttonText = useMemo(
    () =>
      `${t(`${user?.agreement ? 'Decline' : 'Accept'} Terms`)} & ${t(
        'Conditions and Privacy Policy'
      )}`,
    [user, t]
  )

  const titleText = useMemo(
    () =>
      `Are you sure you want to ${t(
        `${user?.agreement ? 'decline' : 'accept'}`
      )}`,
    [user, t]
  )

  const linkText = useMemo(
    () => `${t('Terms')} & ${t('Conditions and Privacy Policy')}?`,
    [t]
  )

  const onAgreementChange = async () => {
    const isAcceptedAgreement = user?.agreement
    try {
      await updateDocument(COLLECTIONS.USERS, user?._id, {
        agreement: !user?.agreement
      })
      message.success(
        `${t('Successfully')} ${
          isAcceptedAgreement
            ? t('declined agreement')
            : t('accepted agreement')
        }`
      )
    } catch (err) {
      //eslint-disable-next-line
      console.error(`Error during agreement ${isAcceptedAgreement ? 'accept' : 'decline'} operation:`, err)
      message.error(
        `${t('Error during agreement')} ${
          isAcceptedAgreement ? t('accept operation') : t('decline operation')
        }:`
      )
    }
  }

  return { buttonText, linkText, titleText, onAgreementChange }
}
export default useTermsAndConditionsPopconfirmActions
