const ZONE_AVAILABILITIES = {
  AVAILABLE: 'Available',
  NOT_AVAILABLE: 'Not available'
}

const ZONE_AVAILABILITIES_KEYS = {
  AVAILABLE: 'AVAILABLE',
  NOT_AVAILABLE: 'NOT_AVAILABLE'
}

const ZONE_AVAILABILITIES_OPTIONS = Object.keys(ZONE_AVAILABILITIES).map(
  (key) => ({ label: ZONE_AVAILABILITIES[key], value: key })
)

export default ZONE_AVAILABILITIES
export {
  ZONE_AVAILABILITIES_OPTIONS,
  ZONE_AVAILABILITIES,
  ZONE_AVAILABILITIES_KEYS
}
