import { Container } from '@qonsoll/react-design'
import PropTypes from 'prop-types'
import { QRCodeList } from 'domains/QRCode/components'
import { useQRCodeBaseActions } from 'domains/QRCode/hooks'

const ZoneQRCodes = (props) => {
  const { zone, restaurant, refresh, zoneQRCodes } = props

  /* Getting QRCode actions */
  const { onDelete, onCreate } = useQRCodeBaseActions({
    zone,
    refresh,
    restaurant,
    zoneId: zone?._id
  })

  /* Actions */
  const computedActions = {
    onDelete,
    onCreate
  }

  return (
    <Container>
      <QRCodeList
        refresh={refresh}
        restaurant={restaurant}
        cw={[12, 6, 6, 3]}
        isListWithCreate={true}
        actions={computedActions}
        qRCodes={zoneQRCodes}
        withoutEmptyMessage
        isSearchQRCode
      />
    </Container>
  )
}
ZoneQRCodes.propTypes = {
  zone: PropTypes.object,
  restaurant: PropTypes.object,
  refresh: PropTypes.func,
  zoneQRCodes: PropTypes.array
}
export default ZoneQRCodes
