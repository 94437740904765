import { Icon } from '@qonsoll/icons'
import { Col, Input, Row } from '@qonsoll/react-design'
import { useTranslations } from '@qonsoll/translation'
import { Form } from 'antd'
import PropTypes from 'prop-types'
import { Draggable } from 'react-beautiful-dnd'
import { SelectWithFixedDropdown } from 'components'

const InteractiveMenuQuestionSimpleForm = (props) => {
  const {
    question,
    selectedItemType,
    itemTypesFieldsLoading,
    fieldsOptions,
    onRemoveQuestion,
    index
  } = props

  const { t } = useTranslations()

  const idComputed = question?._id || question?.uid

  const handleFilterOptions = (input, option) =>
    option?.label?.toLowerCase()?.indexOf(input?.toLowerCase()) === 0

  return (
    <Draggable key={idComputed} draggableId={idComputed} index={index}>
      {(provided) => (
        <Row
          ref={provided.innerRef}
          {...provided.draggableProps}
          h="between"
          v="center"
          noOuterGutters>
          <Col cw="auto" h="left">
            <Icon
              name="DraggerOutlined"
              size={16}
              fill="var(--ql-text-secondary)"
              {...provided.dragHandleProps}
            />
          </Col>
          <Col cw={5}>
            <Form.Item
              rules={[
                {
                  required: true,
                  message: t('Please, enter question text')
                }
              ]}
              label={t('Text')}
              name={['questions', idComputed, 'text']}>
              <Input placeholder={t('Enter question text')} />
            </Form.Item>
          </Col>
          <Col cw={5}>
            <Form.Item
              rules={[
                {
                  required: true,
                  message: t('Please, select question source')
                }
              ]}
              label={t('Option source')}
              name={['questions', idComputed, 'optionSource']}>
              <SelectWithFixedDropdown
                showSearch
                options={fieldsOptions}
                disabled={!selectedItemType}
                loading={itemTypesFieldsLoading}
                filterOption={handleFilterOptions}
                placeholder={t('Enter question text')}
              />
            </Form.Item>
          </Col>
          <Col cw={1} h="right">
            <Icon
              onClick={onRemoveQuestion(idComputed)}
              cursor="pointer"
              fill="red"
              name="Trash3Outlined"
              size={20}
            />
          </Col>
        </Row>
      )}
    </Draggable>
  )
}

InteractiveMenuQuestionSimpleForm.propTypes = {
  question: PropTypes.object,
  selectedItemType: PropTypes.object,
  itemTypesFieldsLoading: PropTypes.bool,
  fieldsOptions: PropTypes.arrayOf(PropTypes.object),
  onRemoveQuestion: PropTypes.func,
  index: PropTypes.number
}

export default InteractiveMenuQuestionSimpleForm
