const TYPE_LICENSE = {
  Lite: 'Lite',
  Standard: 'Standard'
}
const TYPE_LICENSE_KEYS = {
  LITE: 'LITE',
  STANDARD: 'STANDARD'
}

const TYPE_LICENSE_OPTIONS = Object.keys(TYPE_LICENSE).map((key) => ({
  label: TYPE_LICENSE[key],
  value: key
}))

export { TYPE_LICENSE, TYPE_LICENSE_OPTIONS, TYPE_LICENSE_KEYS }
