import { ButtonAccent, ImageUploader } from 'components'
import { Col, Row, Title } from '@qonsoll/react-design'
import { Form, Input, InputNumber } from 'antd'
import { KEYS, MAX_LENGTH_FIELD } from '__constants__'

import PropTypes from 'prop-types'
import { useTranslations } from 'contexts/Translation'
import { useValidateInput } from 'hooks'

const CocktailSimpleForm = (props) => {
  const { initialValues, onSubmit, onCancel, showTitle, form, group } = props

  /* Getting translations instance */
  const { t } = useTranslations()
  /* Getting new form instance from Form.useForm hook */
  const [baseForm] = Form.useForm()
  /* A custom hook that is used to validate input fields. */
  const validateInput = useValidateInput()
  /* Getting used form from props or using base form */
  const usedForm = form || baseForm

  /* Resetting form on cancel */
  const handleCancel = () => {
    usedForm.resetFields()
    onCancel?.()
  }
  /* Submitting form on enter key press */
  const handleKeyPress = (e) => {
    if (e.key === KEYS.ENTER) {
      e.preventDefault()
      e.stopPropagation()
      usedForm?.submit()
    }
  }

  return group ? (
    <>
      {showTitle && (
        <Title level={4} mb={2}>
          {t('Drink')}
        </Title>
      )}
      <Form.Item label={t('Image url')} name={[...group, 'imageUrl']}>
        <ImageUploader placeholder={t('Please enter your drink image url')} />
      </Form.Item>
      <Row noOuterGutters>
        <Col cw={9}>
          <Form.Item
            label={t('Name')}
            name={[...group, 'title']}
            rules={[
              {
                required: true,
                message: t('Please enter your drink title!')
              },
              {
                max: MAX_LENGTH_FIELD,
                message: t('drink title is too long!')
              },
              { validator: validateInput }
            ]}>
            <Input
              placeholder={t('Please enter your drink title')}
              onPressEnter={onSubmit}
            />
          </Form.Item>
        </Col>
        <Col cw={3}>
          <Form.Item
            label={t('Price')}
            name={[...group, 'price']}
            rules={[
              {
                required: true,
                message: t('Please enter your drink price!')
              }
            ]}>
            <InputNumber
              min={0}
              decimalSeparator=","
              placeholder={t('Price')}
            />
          </Form.Item>
        </Col>
      </Row>
    </>
  ) : (
    <Form
      scrollToFirstError
      form={usedForm}
      initialValues={initialValues}
      onFinish={onSubmit}
      layout="vertical"
      onKeyPress={handleKeyPress}
      autoComplete="off">
      {showTitle && (
        <Title level={4} mb={2}>
          {t('Cocktail')}
        </Title>
      )}
      <Form.Item label={t('Image url')} name={[...group, 'imageUrl']}>
        <ImageUploader placeholder={t('Please enter your drink image url')} />
      </Form.Item>
      <Form.Item
        label={t('Name')}
        name="title"
        rules={[
          {
            required: true,
            message: t('Please enter your drink title!')
          },
          { max: MAX_LENGTH_FIELD, message: t('drink title is too long!') },
          { validator: validateInput }
        ]}>
        <Input placeholder={t('Please enter your drink title')} />
      </Form.Item>
      <Form.Item
        label={t('Price')}
        name="price"
        rules={[
          {
            required: true,
            message: t('Please enter your drink price!')
          }
        ]}>
        <InputNumber min={0} decimalSeparator="," placeholder={t('Price')} />
      </Form.Item>
      {!form && (
        <>
          <ButtonAccent
            mr={2}
            htmlType="button"
            onClick={handleCancel}
            type="secondary">
            {t('Cancel')}
          </ButtonAccent>
          <ButtonAccent type="primary" onClick={() => usedForm.submit()}>
            {t('Save')}
          </ButtonAccent>
        </>
      )}
    </Form>
  )
}

CocktailSimpleForm.propTypes = {
  initialValues: PropTypes.object,
  onSubmit: PropTypes.func,
  onCancel: PropTypes.func,
  showTitle: PropTypes.bool,
  form: PropTypes.any,
  group: PropTypes.array
}

export default CocktailSimpleForm
