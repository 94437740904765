import { COLLECTIONS, STORAGE_PATHS } from '__constants__'
import { generatePath, useHistory } from 'react-router-dom'
import { message, notification } from 'antd'

import { ButtonAccent } from 'components'
import PATHS from 'pages/paths'
import { deleteDocument } from 'services/api/firebase'
import { removeFile } from 'services/storage'
import { useEvent } from 'hooks'
import { useMemo } from 'react'
import { useTranslations } from 'contexts/Translation'

/* Path's */
const createPath =
  PATHS.AUTHENTICATED.TRAINING_MATERIAL_CREATE ||
  PATHS.AUTHENTICATED.TRAINING_MATERIAL_WIZARD
const editPath = PATHS.AUTHENTICATED.TRAINING_MATERIAL_EDIT
const showPath = PATHS.AUTHENTICATED.TIP_AND_TRICK_SHOW

const useTrainingMaterialBaseActions = ({
  tipAndTrickId,
  actions,
  trainingMaterial,
  withGoBack
} = {}) => {
  /* Getting translations instance */
  const { t } = useTranslations()
  /* Getting history instance */
  const history = useHistory()

  /* Function to handle back click */
  const handleBack = useEvent(() => history.goBack())

  /* Handling opening of the create page */
  const handleCreate = useEvent(() => {
    history.push(createPath)
  })

  /* Handling opening of the show page */
  const handleOpen = useEvent(() => {
    const pathParams = { tipAndTrickId }
    const path = generatePath(showPath, pathParams)
    history.push(path)
  })

  /* Handling opening of the edit page or change view to edit mode */
  const handleEdit = useEvent(() => {
    const pathParams = { tipAndTrickId }
    const path = generatePath(editPath, pathParams)
    history.push(path)
  })

  // onSubmit deletion of educational material
  const onSubmitDelete = async (notificationKey) => {
    await deleteDocument(COLLECTIONS.TRAINING_MATERIALS, tipAndTrickId)
    await removeFile(
      `${STORAGE_PATHS.TRAINING_MATERIAL}/${trainingMaterial?.content?.[0]?.id}_${trainingMaterial?.content?.[0]?.name}`
    )
    message.success(t('Educational material successfully deleted'))
    notification.close(notificationKey)
    withGoBack && history.goBack()
  }

  const handleDelete = useEvent(async () => {
    try {
      const key = `open${Date.now()}`
      const confirmBtn = (
        <ButtonAccent
          danger
          type="text"
          size="small"
          onClick={() => onSubmitDelete(key)}>
          {t('Confirm')}
        </ButtonAccent>
      )
      notification.warn({
        message: `${t('Do you really want to delete the')} ${
          trainingMaterial?.title || t('current training material')
        }`,
        btn: confirmBtn,
        key
      })
    } catch (error) {
      message.error(error.message)
    }
  })

  return useMemo(
    () => ({
      onBack: handleBack,
      onCreate: handleCreate,
      onOpen: handleOpen,
      onEdit: handleEdit,
      onDelete: handleDelete,
      ...(actions || {}),
      createPath,
      editPath,
      showPath
    }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [
      actions?.onBack,
      actions?.onCreate,
      actions?.onDelete,
      actions?.onEdit,
      actions?.onOpen,
      handleBack,
      handleCreate,
      handleDelete,
      handleEdit,
      handleOpen
    ]
  )
}

export default useTrainingMaterialBaseActions
