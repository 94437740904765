import { AllergenSelect, AllergenSimpleView } from 'domains/Allergen/components'
import { Col, Row, Text, Title } from '@qonsoll/react-design'
import { useEffect, useState } from 'react'

import PropTypes from 'prop-types'
import { useGetAllergens } from 'domains/Allergen/hooks'
import { useTranslations } from 'contexts/Translation'

const AllergenSelectableList = (props) => {
  const {
    cw,
    emptyProps,
    actions,
    value,
    onChange,
    withTitle = false,
    mode = 'multiple',
    transformationLimit = 6
  } = props

  /* State for selected item in list */
  const [selected, setSelected] = useState()

  /* Getting translations instance */
  const { t } = useTranslations()
  /* Fetching allergens */
  const [allergens] = useGetAllergens()

  /* Checking if mode is multiple */
  const isMultiple = mode === 'multiple'
  /* Checking if list is empty */
  const isEmpty = !allergens?.length
  /* Computing item width */
  const itemWidth = cw || [12, 6, 4, 3]
  /* Checking if multiple select is allowed */
  const isSelectComponent = allergens?.length > transformationLimit

  /* Handling selection of the item */
  const handleSelect = (id, selectValue = null) => {
    if (isSelectComponent) {
      setSelected(selectValue)
      onChange?.(selectValue)
    } else {
      const toggleMultipleSelection = (prev = []) =>
        prev.includes(id) ? prev.filter((i) => i !== id) : [...prev, id]
      const toggleSingleSelection = (prev, id) => (prev === id ? null : id)
      setSelected((prev) => {
        const _selected = isMultiple
          ? toggleMultipleSelection(prev)
          : toggleSingleSelection(prev, id)
        onChange?.(_selected)
        return _selected
      })
    }
  }
  /* Checking if the item is selected */
  const checkIsSelected = (id) =>
    isMultiple ? selected?.includes(id) : selected === id

  /* useEffect which helps to initialize the form */
  useEffect(() => {
    if (selected === undefined && value !== undefined) {
      setSelected(value)
    }
  }, [value, selected])

  return (
    <Row mx="var(--ql-grid-gutter-negative)">
      {withTitle && (
        <Col cw={12} mb={2}>
          <Row noGutters>
            <Col>
              <Title level={4}>{t('Allergens')}</Title>
            </Col>
            <Col cw="auto">
              <Text>
                {selected?.length || '0'} / {allergens?.length}
              </Text>
            </Col>
          </Row>
        </Col>
      )}
      {!isEmpty &&
        !isSelectComponent &&
        allergens.map((allergen, index) => (
          <Col key={allergen?._id || index} cw={itemWidth}>
            <AllergenSimpleView
              actions={{ ...actions, onOpen: null }}
              index={index}
              isSelected={checkIsSelected(allergen?._id)}
              onSelect={handleSelect}
              allergen={allergen}
            />
          </Col>
        ))}
      {!isEmpty && isSelectComponent && (
        <Col cw={12}>
          <AllergenSelect
            mode="multiple"
            showTitle={false}
            value={selected}
            onChange={handleSelect}
          />
        </Col>
      )}
      {isEmpty && (
        <Col cw={12}>
          <Text variant="overline" color="var(--ql-state-disabled-color)">
            {emptyProps?.message || t('No allergens have been created yet')}
          </Text>
        </Col>
      )}
    </Row>
  )
}

AllergenSelectableList.propTypes = {
  cw: PropTypes.any,
  emptyProps: PropTypes.object,
  actions: PropTypes.any,
  value: PropTypes.any,
  onChange: PropTypes.func,
  withTitle: PropTypes.bool,
  mode: PropTypes.string,
  transformationLimit: PropTypes.number
}

export default AllergenSelectableList
