import { useEffect, useMemo, useState } from 'react'

import { COLLECTIONS } from '__constants__'
import firebase from 'firebase/compat/app'
import { useCollectionDataOnce } from 'react-firebase-hooks/firestore'

const useFetchCountries = () => {
  const [listLanguages, setListLanguages] = useState(null)
  const [listCurrencies, setListCurrencies] = useState(null)

  // Get list of all countries
  const [listCountries] = useCollectionDataOnce(
    firebase
      .firestore()
      .collection(COLLECTIONS.COUNTRIES)
      .orderBy('internationalName', 'asc')
  )

  const getAllCountriesData = () => {
    // Get all languages
    const languages = listCountries
      ?.map((country) => country?.languages[0]?.name)
      ?.sort((a, b) => a.localeCompare(b))

    /* Getting the currency name from the country object. */
    const currencies = listCountries
      ?.map((country) => country?.currency[0]?.code)
      ?.filter((item) => item !== null)
      ?.sort((a, b) => a.localeCompare(b))

    const uniqLanguages = [...new Set(languages)]
    const uniqCurrencies = [...new Set(currencies)]

    listCountries && setListLanguages(uniqLanguages)
    listCountries && setListCurrencies(uniqCurrencies)
  }

  useEffect(() => {
    listCountries && getAllCountriesData()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [listCountries])

  return useMemo(() => {
    return {
      listCountries,
      listLanguages,
      listCurrencies
    }
  }, [listCountries, listLanguages, listCurrencies])
}

export default useFetchCountries
