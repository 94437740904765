import {
  AppstoreOutlined,
  DeleteOutlined,
  EditOutlined,
  PlusOutlined,
  SortAscendingOutlined,
  UnorderedListOutlined
} from '@ant-design/icons'
import { Box, Divider } from '@qonsoll/react-design'

import { ButtonAccent } from 'components'
import { Popover } from '@qonsoll/react-design'
import PropTypes from 'prop-types'
import { Tooltip } from 'antd'
import { useTranslations } from 'contexts/Translation'

/**
 * It renders a set of buttons that are used to perform actions on a list of items
 * @param filterButtonText - The text that will be displayed when the user hovers over the filter
 * button
 * @param onFilterButtonClick - function that will be called when the filter button is clicked
 * @param filterButtonIcon - Icon to be displayed on the filter button
 * @param listView - boolean
 * @param setListView - a function that sets the listView state
 * @param onCreate - function to be called when the create button is clicked
 * @param onWizard - function to be called when the wizard button is clicked
 * @param onEdit - Function to be called when the edit button is clicked
 * @param actions - an object with the following properties:
 * @param createText - The text to be displayed on the create button
 * @returns A function that returns a JSX element
 */
const PageActions = (props) => {
  const {
    filterButtonText,
    onFilterButtonClick,
    filterButtonIcon,
    listView,
    setListView,
    onCreate,
    onWizard,
    onEdit,
    onDelete,
    actions,
    createText,
    sortMenu,
    customText,
    onCustomAction,
    customIcon,
    customButtonStyles,
    customTooltipTitle,
    editText
  } = props

  /* Getting translations instance */
  const { t } = useTranslations()

  const editMr = actions.delete || actions.filter ? true : false

  return (
    <Box display="flex">
      {actions.edit && (
        <ButtonAccent
          mr={editMr && 2}
          icon={<EditOutlined />}
          onClick={onEdit}
          type="secondary">
          {editText || t('Edit')}
        </ButtonAccent>
      )}
      {actions.delete && (
        <ButtonAccent
          type="secondary"
          danger
          icon={<DeleteOutlined />}
          onClick={onDelete}
          {...props?.deleteBtnProps}>
          {t('Delete')}
        </ButtonAccent>
      )}
      {actions.filter && (
        <Tooltip title={filterButtonText}>
          <ButtonAccent
            shape="circle"
            type="text"
            mr={2}
            onClick={onFilterButtonClick}
            icon={filterButtonIcon}
          />
        </Tooltip>
      )}
      {actions.sort && (
        <Tooltip title={t('Open sort menu')}>
          <Popover content={sortMenu} trigger="click" placement="bottomRight">
            <ButtonAccent
              shape="circle"
              type="text"
              mr={2}
              icon={<SortAscendingOutlined />}
            />
          </Popover>
        </Tooltip>
      )}
      {actions.listView && (
        <Tooltip title={t('Change list view')} placement="topRight">
          <ButtonAccent
            shape="circle"
            type="text"
            onClick={() => setListView(!listView)}
            icon={listView ? <AppstoreOutlined /> : <UnorderedListOutlined />}
          />
        </Tooltip>
      )}
      {actions.wizard && (
        <>
          <Divider mx={3} height="24px" alignSelf="center" type="vertical" />
          <ButtonAccent
            type="primary"
            icon={<PlusOutlined />}
            onClick={onWizard}>
            {createText}
          </ButtonAccent>
        </>
      )}
      {actions.custom && (
        <Tooltip title={customTooltipTitle}>
          <ButtonAccent
            mr={actions.create ? 2 : null}
            type="secondary"
            icon={customIcon}
            onClick={onCustomAction}
            {...customButtonStyles}>
            {customText}
          </ButtonAccent>
        </Tooltip>
      )}
      {actions.create && (
        <>
          {!actions.custom && (
            <Divider mx={3} height="24px" alignSelf="center" type="vertical" />
          )}
          <ButtonAccent
            type="primary"
            icon={<PlusOutlined />}
            onClick={onCreate}>
            {createText}
          </ButtonAccent>
        </>
      )}
    </Box>
  )
}

PageActions.propTypes = {
  actions: PropTypes.shape({
    create: PropTypes.bool,
    edit: PropTypes.bool,
    filter: PropTypes.bool,
    listView: PropTypes.bool,
    sort: PropTypes.bool,
    wizard: PropTypes.bool,
    custom: PropTypes.bool,
    delete: PropTypes.bool
  }),
  sortMenu: PropTypes.node,
  createText: PropTypes.string,
  filterButtonIcon: PropTypes.any,
  filterButtonText: PropTypes.string,
  listView: PropTypes.bool,
  onCreate: PropTypes.func,
  onEdit: PropTypes.func,
  onDelete: PropTypes.func,
  onFilterButtonClick: PropTypes.func,
  onWizard: PropTypes.func,
  onCustomAction: PropTypes.func,
  setListView: PropTypes.func,
  customButtonStyles: PropTypes.object,
  customIcon: PropTypes.node,
  customTooltipTitle: PropTypes.string,
  customText: PropTypes.string,
  editText: PropTypes.string,
  deleteBtnProps: PropTypes.object
}

export default PageActions
