import { Col, Row, Text, Title } from '@qonsoll/react-design'
import { CardDropdown, SelectableCard } from 'components'
import { useGetMenuInfo, useMenuBaseActions } from 'domains/Menu/hooks'

import PropTypes from 'prop-types'
import styles from './MenuSimpleView.styles'
import { useTranslations } from 'contexts/Translation'
import { Fragment } from 'react'

const MenuSimpleView = (props) => {
  const { menu, index, isSelected, actions } = props
  /* Getting translations instance */
  const { t, language } = useTranslations()
  /* Getting base actions */
  const { onOpen } = useMenuBaseActions({
    menuId: menu?._id,
    actions
  })

  const menuInformation = useGetMenuInfo(menu)

  const handleDisplayOfSeperatorDot = (index) =>
    index === menuInformation?.length - 1 ? 'none' : 'block'

  const menuDescription =
    menu?.description ?? t('No description have been added yet')

  return (
    <CardDropdown
      onClick={onOpen}
      document={menu}
      index={index}
      height="100%"
      data-testid="menuListItem">
      <SelectableCard
        isSelected={isSelected}
        height="100%"
        style={{ height: '100%' }}>
        <Row noGutters {...styles.fieldRowProps} mt={2}>
          <Col cw={12} mb={2}>
            <Title level={4} clamp="1">
              {menu?.title ?? menu?.name?.[language] ?? menu?.name?.en}
            </Title>
          </Col>
          <Col cw={12} mb={2} flexDirection="row" flexWrap="wrap">
            {menuInformation?.map((menuData, index) => (
              <Fragment key={`${menuData?.key}-menu-info`}>
                {menuData?.data ? (
                  <>
                    <Text color={menuData?.color}>{menuData?.data}</Text>
                    <Text
                      type="secondary"
                      px={2}
                      display={handleDisplayOfSeperatorDot(index)}>
                      •
                    </Text>
                  </>
                ) : (
                  <Text type="secondary">{menuData?.emptyText}</Text>
                )}
              </Fragment>
            ))}
          </Col>
          <Col cw={12}>
            <Text {...styles.fieldTitleProps} type="secondary">
              {menuDescription}
            </Text>
          </Col>
        </Row>
      </SelectableCard>
    </CardDropdown>
  )
}

MenuSimpleView.propTypes = {
  menu: PropTypes.object,
  onSelect: PropTypes.func,
  index: PropTypes.number,
  isSelected: PropTypes.bool,
  actions: PropTypes.object
}

export default MenuSimpleView
