import { useCallback } from 'react'

const useFormItemLabel = ({ labels, selectedLanguage }) => {
  return useCallback(
    (item) => {
      const labelObj = labels?.find((label) => label?._id === item?._id)
      const labelCopy = labelObj?.label
      const translation = labelCopy?.[selectedLanguage]
      if (translation) return translation
      if (
        !labelCopy ||
        Object?.values(labelCopy)?.every((value) => value === null)
      )
        return labelObj?.fieldName
      return Object?.values(labelCopy)?.filter(Boolean)[0]
    },
    [labels, selectedLanguage]
  )
}

export default useFormItemLabel
