import { CardDropdown, SelectableCard } from 'components'
import { Col, Row, Text, Title } from '@qonsoll/react-design'

import PropTypes from 'prop-types'
import styles from './OrderedItemSimpleView.styles'
import { useTranslations } from 'contexts/Translation'

const OrderedItemSimpleView = (props) => {
  const { orderedItem, index, currency = 'NOK', isSelected, actions } = props
  /* Getting translations instance */
  const { t } = useTranslations()

  return (
    <CardDropdown
      onDelete={actions?.onDelete}
      onClick={actions?.onDelete}
      document={orderedItem}
      index={index}
      data-testid="orderedItemListItem">
      <SelectableCard isSelected={isSelected}>
        <Row v="top" noOuterGutters>
          <Col cw={6}>
            <Title level={4} {...styles.fieldTitleProps}>
              {orderedItem?.title}
            </Title>
          </Col>
          <Col cw={3} flexDirection="row">
            <Text type="secondary">{t('Quantity')}:&nbsp;</Text>
            <Text fontWeight="var(--ql-font-weight-bold)">
              {orderedItem?.count}
            </Text>
          </Col>
          <Col flexDirection="row" cw={3}>
            <Text type="secondary">{t('Price')}:&nbsp;</Text>
            <Text fontWeight="var(--ql-font-weight-bold)">
              {`${orderedItem?.price} ${currency}`}
            </Text>
          </Col>
        </Row>
      </SelectableCard>
    </CardDropdown>
  )
}

OrderedItemSimpleView.propTypes = {
  orderedItem: PropTypes.object,
  index: PropTypes.number,
  isSelected: PropTypes.bool,
  actions: PropTypes.object,
  currency: PropTypes.string
}

export default OrderedItemSimpleView
