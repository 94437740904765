import StyledContentWrapper from './ContentWrapper.styles'
import PropTypes from 'prop-types'

const ContentWrapper = (props) => {
  const { children, asideLeftCollapsed, lg } = props

  return (
    <StyledContentWrapper asideLeftCollapsed={asideLeftCollapsed} lg={lg}>
      {children}
    </StyledContentWrapper>
  )
}

ContentWrapper.propTypes = {
  asideLeftCollapsed: PropTypes.bool,
  children: PropTypes.node,
  lg: PropTypes.bool
}

export default ContentWrapper
