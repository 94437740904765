import styled from 'styled-components'
import { Select } from 'antd'

const StyledLanguageSelect = styled(Select)`
  width: 100%;

  .ant-select-single {
    display: flex;
    align-items: center;
  }
`

export default StyledLanguageSelect
