import { useEffect } from 'react'
import { Form } from 'antd'

const useInitializeOrderedItemAdvancedForm = (initialData) => {
  /* Getting form instance */
  const [form] = Form.useForm()

  /* Setting initial data */
  useEffect(() => {
    if (initialData) {
      /* Getting initial values */
      const { orderedItem, item } = initialData

      /* Formatting date fields */
      const formValues = {
        orderedItem: {
          quantity: orderedItem?.['quantity'] ?? null
        },
        item: item ?? null
      }

      /* Setting initial values to form instance */
      form.setFieldsValue(formValues)
    }
  }, [initialData, form])

  return { form }
}

export default useInitializeOrderedItemAdvancedForm
