import { Box, Option, Select, Text } from '@qonsoll/react-design'

import { ICONS_LIST } from './__constants__'
import { Icon } from '@qonsoll/icons'
import PropTypes from 'prop-types'

/**
 * It's a Select component that allows you to select an icon from a list of icons
 * @param props - The props that are passed to the component.
 * @returns A Select component with a list of icons.
 */
const IconSelect = (props) => {
  const { value, onChange } = props

  const handleChange = (value) => {
    onChange?.(value || null)
  }

  return (
    <Select
      showSearch
      allowClear
      value={value}
      onChange={handleChange}
      style={{ width: '100%' }}
      placeholder="Please select">
      {ICONS_LIST.map((name) => (
        <Option key={name} value={name}>
          <Box display="flex" alignItems="center">
            <Icon
              mr={2}
              name={name}
              fill="var(--ql-typography-text-color-primary)"
            />
            <Text>{name}</Text>
          </Box>
        </Option>
      ))}
    </Select>
  )
}

IconSelect.propTypes = {
  value: PropTypes.oneOf(ICONS_LIST),
  onChange: PropTypes.func
}

export default IconSelect
