import PropTypes from 'prop-types'
import { Text } from '@qonsoll/react-design'
import { useGetEnumString } from 'hooks'

const CoctailFlavorColumn = (props) => {
  const { flavors } = props

  const flavorsName = Array.isArray(flavors)
    ? flavors?.map((item) => item?.name)
    : flavors?.name || ''
  const flavorsEnum = useGetEnumString(flavorsName)
  return <Text>{flavorsEnum || 'n/a'}</Text>
}

CoctailFlavorColumn.propTypes = { flavors: PropTypes.array }

export default CoctailFlavorColumn
