import { Form } from 'antd'
import { useEffect } from 'react'
import { TABLE_AVAILABILITIES_KEYS } from '__constants__'

const useInitializeTableAdvancedForm = (initialData) => {
  /* Getting form instance */
  const [form] = Form.useForm()

  /* Setting initial data */
  useEffect(() => {
    if (initialData) {
      /* Getting initial values */
      const { table } = initialData

      /* Formatting date fields */
      const formValues = {
        table: {
          title: table?.['title'] ?? null,
          available: table?.['available'] ?? null
        }
      }

      /* Setting initial values to form instance */
      form.setFieldsValue(formValues)
    } else {
      const formValues = {
        table: {
          title: null,
          available: TABLE_AVAILABILITIES_KEYS.ACTIVE ?? null
        }
      }

      /* Setting initial values to form instance */
      form.setFieldsValue(formValues)
    }
  }, [initialData, form])

  return { form }
}

export default useInitializeTableAdvancedForm
