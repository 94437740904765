import { Form } from 'antd'
import { useEffect, useRef, useState } from 'react'
import { COCKTAIL_AVAILABILITIES_OPTIONS } from '__constants__'

const useInitializeCocktailAdvancedForm = (initialData) => {
  /* Getting form instance */
  const [form] = Form.useForm()
  const [isDirty, setIsDirty] = useState(false)
  const isFieldInitialized = useRef({
    alcoholTasteTypes: false,
    availability: false
  })

  /* Setting initial data */
  useEffect(() => {
    if (initialData) {
      /* Getting initial values */
      const {
        cocktail,
        allergens,
        methods,
        alcoholTasteTypes,
        mainSpiritType,
        flavor,
        glassType,
        decorationType,
        recipes,
        specialComment
      } = initialData

      /* Formatting date fields */
      const formValues = {
        cocktail: {
          imageUrl: cocktail?.['imageUrl'] ?? null,
          title: cocktail?.['title'] ?? null,
          price: cocktail?.['price'] ?? null,
          description: cocktail?.['description'] ?? null
        },
        recipes: recipes ?? [],
        allergens:
          allergens?.map((item) => item?._id).filter((item) => item) ?? [],
        methods: Array.isArray(methods)
          ? methods?.map((item) => item?._id).filter((item) => item)
          : methods ?? [],
        alcoholTasteTypes: Array.isArray(alcoholTasteTypes)
          ? alcoholTasteTypes
              ?.map((item) => item?._id)
              .filter((item) => item) ?? []
          : alcoholTasteTypes ?? [],
        mainSpiritType:
          mainSpiritType?.map((item) => item?._id).filter((item) => item) ?? [],
        flavor: flavor?.map((item) => item?._id).filter((item) => item) ?? [],
        glassType: glassType?._id ?? null,
        decorationType: decorationType ?? null,
        availability:
          cocktail?.['availability'] ??
          COCKTAIL_AVAILABILITIES_OPTIONS?.at(1)?.value,
        specialComment: specialComment ?? null
      }

      /* Setting initial values to form instance */
      form.setFieldsValue(formValues)

      setIsDirty(false)
    }
  }, [initialData, form])

  const onFormValuesChange = (data) => {
    /* Checking if all fields that initializing using onChange function are initialized */
    if (Object.values(isFieldInitialized.current).every((key) => Boolean(key)))
      setIsDirty(true)
    else {
      const { alcoholTasteTypes, availability } = data
      if (alcoholTasteTypes) isFieldInitialized.current.alcoholTasteTypes = true
      if (availability) isFieldInitialized.current.availability = true
    }
  }

  return { form, isDirty, onFormValuesChange }
}

export default useInitializeCocktailAdvancedForm
