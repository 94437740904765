import { useMemo } from 'react'
import { useLoading } from 'hooks'
import { useDocument } from 'services/api/firebase'
import { COLLECTIONS } from '__constants__'
import {} from 'domains/Allergen/hooks'

const useGetAllergenInitialValues = (id) => {
  /* Fetching data using useDocument hook */
  const [allergen, allergenLoading] = useDocument({
    ref: id ? COLLECTIONS.ALLERGENS + '/' + id : null
  })

  /* Merge all fetched data */
  const initialValues = useMemo(
    () => ({
      allergen
    }),
    [allergen]
  )

  /* Merge all loading states */
  const loadings = useMemo(
    () => (id ? [!allergen, allergenLoading] : []),
    [id, allergen, allergenLoading]
  )

  /* Getting loading state using merged loadings */
  const loading = useLoading(loadings)

  return [initialValues, loading]
}

export default useGetAllergenInitialValues
