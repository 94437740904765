import { Col, Container, Row, Title } from '@qonsoll/react-design'

import { DatePicker } from 'antd'
import PropTypes from 'prop-types'
import firebase from 'firebase/compat/app'
import moment from 'moment'
import { useTranslations } from 'contexts/Translation'

const { RangePicker } = DatePicker

const LicenceValidityStep = (props) => {
  const { value, setValue, stepNumber } = props

  /* Getting translations instance */
  const { t } = useTranslations()

  /* Handling changes */
  const handleChange = (value) => {
    if (value && value?.length) {
      const [startDate, endDate] = value

      const convertMomentToTimestamp = (value) =>
        value && firebase.firestore.Timestamp.fromDate(value?.toDate())

      setValue((prev) => ({
        ...prev,
        licenceValidity: {
          from: convertMomentToTimestamp(startDate),
          to: convertMomentToTimestamp(endDate)
        }
      }))
    }
  }

  const extractInitialValues = (licenceValidity) => {
    const validityDateFromValueParsed =
      licenceValidity?.from && moment(licenceValidity.from?.seconds * 1000)
    const validityDateToValueParsed =
      licenceValidity?.to && moment(licenceValidity.to?.seconds * 1000)

    const validityDateFromComputed = validityDateFromValueParsed || moment()
    const validityDateToComputed =
      validityDateToValueParsed || moment().add(1, 'years')
    return [validityDateFromComputed, validityDateToComputed]
  }

  const titleTextComputed = `${t('Step')} ${stepNumber}. ${t(
    'Licence validity terms'
  )}`

  return (
    <Container mb="24px">
      <Title textAlign="center" variant="h4" mb="24px">
        {titleTextComputed}
      </Title>
      <Row noGutters h="center">
        <Col cw={12}>
          <RangePicker
            value={extractInitialValues(value?.licenceValidity)}
            onChange={handleChange}
          />
        </Col>
      </Row>
    </Container>
  )
}

LicenceValidityStep.propTypes = {
  value: PropTypes.object,
  setValue: PropTypes.func,
  stepNumber: PropTypes.number
}

export default LicenceValidityStep
