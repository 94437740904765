import {
  useGetCocktailAlcoholTasteTypes,
  useGetCocktailAllergens,
  useGetCocktailDecorationType,
  useGetCocktailGlassType,
  useGetCocktailIngredients,
  useGetCocktailMeasurementTypes,
  useGetCocktailMediaObjects,
  useGetCocktailMethods
} from 'domains/Cocktail/hooks'

import { COLLECTIONS } from '__constants__'
import { useCustomerContext } from 'contexts/CustomerContext'
import { useGetMenuById } from 'domains/Menu/hooks'
import { useLoading, useGetDocumentsByIds } from 'hooks'
import { useMemo } from 'react'
import { useDocumentDataOnce } from 'react-firebase-hooks/firestore'
import { doc } from 'firebase/firestore'
import { firestore } from 'services/firebase'

const useGetCocktailInitialValues = (id) => {
  const { customer } = useCustomerContext()

  /* Fetching data using useDocument hook */
  const cocktailPath = `${COLLECTIONS.MENUS}/${customer?.automaticMenuId}/${COLLECTIONS.COCKTAILS}/${id}`
  const [cocktail, cocktailLoading] = useDocumentDataOnce(
    customer?.automaticMenuId && id && doc(firestore, cocktailPath)
  )

  const [menu, menuLoading] = useGetMenuById(customer?.automaticMenuId)

  /* Fetching mediaObject data */
  const [mediaObjects, mediaObjectLoading] =
    useGetCocktailMediaObjects(cocktail)

  /* Fetching ingredient data */
  const [ingredients, ingredientLoading] = useGetCocktailIngredients(cocktail)

  /* Fetching measurementType data */
  const [measurementTypes, measurementTypeLoading] =
    useGetCocktailMeasurementTypes(cocktail)

  /* Fetching allergen data */
  const [allergens, allergenLoading] = useGetCocktailAllergens(cocktail)

  /* Fetching method data */
  const [methods, methodLoading] = useGetCocktailMethods(cocktail)

  /* Fetching alcoholTasteType data */
  const [alcoholTasteTypes, alcoholTasteTypeLoading] =
    useGetCocktailAlcoholTasteTypes(cocktail)

  /* Fetching mainSpiritType data */
  const [mainSpiritType, mainSpiritTypeLoading] = useGetDocumentsByIds({
    collectionName: COLLECTIONS.MAIN_SPIRIT_TYPES,
    entityIds: cocktail?.mainSpiritType
  })

  /* Fetching flavor data */
  const [flavor, flavorLoading] = useGetDocumentsByIds({
    collectionName: COLLECTIONS.FLAVORS,
    entityIds: cocktail?.flavor
  })

  /* Fetching recipe data */
  const [recipes, recipesLoading] = useGetDocumentsByIds({
    collectionName: COLLECTIONS.RECIPES,
    entityIds: cocktail?.recipes
  })

  /* Fetching glassType data */
  const [glassType, glassTypeLoading] = useGetCocktailGlassType(cocktail)

  /* Fetching decorationType data */
  const [decorationType, decorationTypeLoading] =
    useGetCocktailDecorationType(cocktail)

  /* Merge all fetched data */
  const initialValues = useMemo(
    () => ({
      cocktail,
      mediaObjects,
      ingredients,
      measurementTypes,
      allergens,
      methods: methods?.length ? methods : cocktail?.methods,
      alcoholTasteTypes: alcoholTasteTypes?.length
        ? alcoholTasteTypes
        : cocktail?.alcoholTasteTypes,
      mainSpiritType,
      flavor,
      glassType,
      decorationType: decorationType ?? cocktail?.decorationType,
      availability:
        menu?.cocktailsAvailabilities?.[cocktail?._id]?.availability,
      recipes
    }),
    [
      cocktail,
      menu,
      mediaObjects,
      ingredients,
      measurementTypes,
      allergens,
      methods,
      alcoholTasteTypes,
      mainSpiritType,
      flavor,
      glassType,
      decorationType,
      recipes
    ]
  )

  /* Merge all loading states */
  const loadings = useMemo(
    () =>
      id
        ? [
            !cocktail,
            cocktailLoading,
            mediaObjectLoading,
            ingredientLoading,
            measurementTypeLoading,
            allergenLoading,
            methodLoading,
            alcoholTasteTypeLoading,
            mainSpiritTypeLoading,
            flavorLoading,
            glassTypeLoading,
            decorationTypeLoading,
            menuLoading,
            recipesLoading
          ]
        : [],
    [
      id,
      cocktail,
      cocktailLoading,
      mediaObjectLoading,
      ingredientLoading,
      measurementTypeLoading,
      allergenLoading,
      methodLoading,
      alcoholTasteTypeLoading,
      mainSpiritTypeLoading,
      flavorLoading,
      glassTypeLoading,
      decorationTypeLoading,
      menuLoading,
      recipesLoading
    ]
  )

  /* Getting loading state using merged loadings */
  const loading = useLoading(loadings)

  return [initialValues, loading]
}

export default useGetCocktailInitialValues
