import { useEffect, useState, useCallback } from 'react'
import { getDocument } from 'services/api/firebase'
import { COLLECTIONS } from '__constants__'

/**
 * It fetches a decorationType document from Firestore and returns it
 * @param cocktail - The cocktail object that we're getting the decorationType for.
 * @returns An array with four values. The first value is the decorationType object, and the second value is a
 * boolean that indicates whether the decorationType is loading.
 */
const useGetCocktailDecorationType = (cocktail) => {
  /* State for data */
  const [decorationType, setDecorationType] = useState(null)
  /* State for loading */
  const [loading, setLoading] = useState(!!cocktail?.decorationType)
  /* State for error */
  const [error, setError] = useState(null)

  /* Fetching data */
  const getDecorationType = useCallback(async () => {
    try {
      setLoading(true)
      const document = await getDocument(
        COLLECTIONS.DECORATION_TYPES,
        cocktail.decorationType
      )
      setDecorationType(document)
      setLoading(false)
    } catch (error) {
      setError(error)
    }
  }, [cocktail?.decorationType])

  useEffect(() => {
    cocktail?.decorationType && getDecorationType()
  }, [cocktail?.decorationType, getDecorationType])

  return [decorationType, loading, error, getDecorationType]
}

export default useGetCocktailDecorationType
