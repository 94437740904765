import { useTranslations } from 'contexts/Translation'
import { Container, Row, Col, Text, Title, Card } from '@qonsoll/react-design'

import PropTypes from 'prop-types'

const MethodAdvancedView = (props) => {
  const { method } = props

  /* Getting translations instance */
  const { t } = useTranslations()

  return (
    <Container>
      <Row mx="var(--ql-grid-gutter-negative)">
        <Col cw={4} mb={3}>
          <Card>
            <Row noGutters mb={2} v="center">
              <Col h="left">
                <Title level={5}>{method?.name}</Title>
              </Col>
            </Row>
            <Row noGutters v="center" mb={2}>
              <Col h="left">
                <Text variant="overline" type="secondary">
                  {t('Value')}:
                </Text>
              </Col>
              <Col h="right">
                <Text>{method?.value}</Text>
              </Col>
            </Row>
            <Row noGutters v="center" mb={2}>
              <Col h="left">
                <Text variant="overline" type="secondary">
                  {t('Icon')}:
                </Text>
              </Col>
              <Col h="right">
                <Text>{method?.icon}</Text>
              </Col>
            </Row>
          </Card>
        </Col>
      </Row>
    </Container>
  )
}

MethodAdvancedView.propTypes = {
  method: PropTypes.object
}

export default MethodAdvancedView
