import { Img } from '@qonsoll/react-design'
import logoFull from 'assets/logoFull.svg'
import logoSimple from 'assets/logo.svg'
import { LOGO_VARIANTS } from '__constants__'
import PropTypes from 'prop-types'
import { useTranslations } from 'contexts/Translation'

const Logo = (props) => {
  const { variant = LOGO_VARIANTS.FULL } = props

  const { t } = useTranslations()

  // Get logo's src by variant
  const computedSrc = variant === LOGO_VARIANTS.FULL ? logoFull : logoSimple

  return <Img src={computedSrc} alt={t('Pick your pour')} height={48} />
}

Logo.propTypes = {
  variant: PropTypes.oneOf([...Object.values(LOGO_VARIANTS)])
}

export default Logo
