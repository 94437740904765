import { Col, Row, Text } from '@qonsoll/react-design'

import PATHS from 'pages/paths'
import { ProfileNavigatorCard } from './ProfileNavigatorCard.style'
import { useHistory } from 'react-router-dom'
import { useTranslations } from 'contexts/Translation'

const ProfileNavigator = () => {
  const { t } = useTranslations()
  const history = useHistory()
  const onTipsAndTricksClick = () =>
    history.push(PATHS.AUTHENTICATED.TIP_AND_TRICKS_ALL)
  const onMenusClick = () => history.push(PATHS.AUTHENTICATED.MENUS_ALL)
  const onMenuBuilderClick = () => history.push(PATHS.AUTHENTICATED.MENU_CREATE)
  const onTermsAndConditionsClick = () =>
    history.push(PATHS.SERVICE.TERMS_AND_CONDITIONS)

  return (
    <Row noOuterGutters>
      <Col cw={12}>
        <Row noOuterGutters mb={2}>
          <Col>
            <ProfileNavigatorCard onClick={onTermsAndConditionsClick}>
              <Text textAlign="center">{`${t('Terms')} & ${t(
                'Conditions and Privacy Policy'
              )}`}</Text>
            </ProfileNavigatorCard>
          </Col>
          <Col>
            <ProfileNavigatorCard onClick={onMenuBuilderClick}>
              <Text textAlign="center">{t('Menu builder')}</Text>
            </ProfileNavigatorCard>
          </Col>
        </Row>
        <Row noOuterGutters>
          <Col>
            <ProfileNavigatorCard onClick={onTipsAndTricksClick}>
              <Text textAlign="center">{t('Tips & tricks')}</Text>
            </ProfileNavigatorCard>
          </Col>
          <Col>
            <ProfileNavigatorCard onClick={onMenusClick}>
              <Text textAlign="center">{t('Menus')}</Text>
            </ProfileNavigatorCard>
          </Col>
        </Row>
      </Col>
    </Row>
  )
}

export default ProfileNavigator
