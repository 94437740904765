import { collection, getDocs, query, where } from 'firebase/firestore'
import { useCallback, useEffect, useState } from 'react'

import { COLLECTIONS } from '__constants__'
import { firestore } from 'services/firebase'

/**
 * It fetches a qRCodes documents from Firestore and returns it
 * @param restaurant - The restaurant object that we're getting the qRCodes for.
 * @returns An array with two values. The first value is the qRCodes object, and the second value is a
 * boolean that indicates whether the qRCodes is loading.
 */
const useGetRestaurantQRCodes = (restaurant) => {
  /* State for data */
  const [qRCodes, setQRCodes] = useState([])
  /* State for loading */
  const [loading, setLoading] = useState(false)
  /* State for error */
  const [error, setError] = useState(null)

  /* Fetching data */
  const getQRCodes = useCallback(async () => {
    try {
      /* Setting loading state to true */
      setLoading(true)

      const computedQuery = query(
        collection(firestore, COLLECTIONS.Q_R_CODES),
        where('restaurant', '==', restaurant?._id)
      )

      const querySnapshot = await getDocs(computedQuery)

      const response = querySnapshot?.docs?.map((item) => item.data())

      /* Setting qRCodes data */
      setQRCodes(response)

      /* Setting loading state to false */
    } catch (error) {
      setError(error)
    }
    setLoading(false)
  }, [restaurant])

  useEffect(() => {
    restaurant?._id && getQRCodes()
  }, [restaurant?._id, getQRCodes])

  return [qRCodes, loading, error, getQRCodes]
}
export default useGetRestaurantQRCodes
