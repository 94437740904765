import { generatePath, useHistory, useParams } from 'react-router-dom'

import { COLLECTIONS } from '__constants__'
import PATHS from 'pages/paths'
import { deleteDocument } from 'services/api/firebase'
import { message } from 'antd'
import { updateParentHasManyRelationship } from 'services/api/firebase'
import { useEvent } from 'hooks'
import { useMemo } from 'react'
import { useTranslations } from 'contexts/Translation'

/* Path's */
const createPath = PATHS.AUTHENTICATED.RESTAURANT_ZONE_CREATE_Q_R_CODE_CREATE
const showPath = PATHS.AUTHENTICATED.Q_R_CODE_SHOW

const useQRCodeBaseActions = ({
  qRCodeId,
  actions,
  restaurant,
  table,
  refresh,
  zoneId,
  zone
} = {}) => {
  /* Getting translations instance */
  const { t } = useTranslations()
  /* Getting history instance */
  const history = useHistory()
  const { profileId, restaurantId } = useParams()

  /* Function to handle back click */
  const handleBack = useEvent(() => history.goBack())

  /* Handling opening of the create page */
  const handleCreate = useEvent(() => {
    const pathParams = { profileId, restaurantId, zoneId: zone?._id }
    history.push(generatePath(createPath, pathParams), {
      parent: {
        menus: restaurant?.menus || [],
        qRCodes: zone?.qRCodes || [],
        restaurantId: restaurant?._id,
        id: zone?._id,
        collection: COLLECTIONS.ZONES,
        field: 'qRCodes',
        type: 'updateParentHasManyRelationship',
        childrenQRCodesIds: zone?.qRCodes
      }
    })
  })

  /* Handling opening of the show page */
  const handleOpen = useEvent(() => {
    const pathParams = { qRCodeId }
    const path = generatePath(showPath, pathParams)
    history.push(path)
  })

  const handleDelete = useEvent(async (doc) => {
    try {
      await updateParentHasManyRelationship(
        zone?._id,
        COLLECTIONS.ZONES,
        'qRCodes',
        doc?._id,
        true
      )
      await deleteDocument(COLLECTIONS.Q_R_CODES, qRCodeId || doc?._id)
      message.success(t('QR-Code successfully deleted'))
      refresh?.()
    } catch (error) {
      message.error(error.message)
    }
  })

  return useMemo(
    () => ({
      onBack: handleBack,
      onCreate: handleCreate,
      onOpen: handleOpen,
      onDelete: handleDelete,
      ...(actions || {}),
      createPath,
      showPath
    }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [actions, handleBack, handleCreate, handleDelete, handleOpen]
  )
}

export default useQRCodeBaseActions
