import { Col, PageWrapper, Row, Text, Box } from '@qonsoll/react-design'
import PropTypes from 'prop-types'

import { useTranslations } from 'contexts/Translation'

import {
  HeaderBreadcrumbs,
  PageActions,
  Spinner,
  SimpleSearchInput,
  EmptySearch
} from 'components'

import { ItemTypeList, ItemTypeSortMenu } from 'domains/ItemType/components'
import { useItemTypesAllBaseActions } from 'domains/ItemType/hooks'

const ItemTypesAll = (props) => {
  const { t } = useTranslations()

  const {
    loading,
    isNoRelatedSearchResults,
    isRelatedSearchResults,
    onSearchInputChange,
    searchRef,
    onCreate,
    actions,
    onBack,
    headingProps,
    searchResult,
    filterData,
    setFilterData
  } = useItemTypesAllBaseActions()

  return (
    <PageWrapper
      onBack={onBack}
      breadcrumbs={<HeaderBreadcrumbs />}
      headingProps={headingProps}>
      {loading ? (
        <Spinner />
      ) : (
        <Row noGutters>
          <Col cw={12} mb={4}>
            <Box display="flex">
              <Box display="flex" flex={1} mr={3}>
                <SimpleSearchInput
                  searchItems={onSearchInputChange}
                  searchRef={searchRef}
                  placeholder={t('Search types by name')}
                />
              </Box>
              <PageActions
                actions={actions}
                onCreate={onCreate}
                createText={t('Create item type')}
                sortMenu={
                  <ItemTypeSortMenu
                    filteredData={filterData}
                    setFilteredData={setFilterData}
                  />
                }
              />
            </Box>
          </Col>

          <Col cw={12}>
            {isRelatedSearchResults ? (
              <ItemTypeList itemTypes={searchResult} />
            ) : isNoRelatedSearchResults ? (
              <EmptySearch />
            ) : (
              <Text variant="overline" color="var(--ql-state-disabled-color)">
                {t('No item types have been created yet')}
              </Text>
            )}
          </Col>
        </Row>
      )}
    </PageWrapper>
  )
}

ItemTypesAll.propTypes = {
  isListWithCreate: PropTypes.bool,
  withHeader: PropTypes.bool
}

export default ItemTypesAll
