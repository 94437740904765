import { collection, query, orderBy } from 'firebase/firestore'
import { useCollectionData } from 'react-firebase-hooks/firestore'
import { firestore } from 'services/firebase'
import { COLLECTIONS } from '__constants__'

const useGetObjects = (config) => {
  const queryData = [
    collection(firestore, COLLECTIONS.OBJECTS),
    ...(config?.orderBy?.length ? [orderBy(...config.orderBy)] : [])
  ]

  return useCollectionData(query(...queryData))
}

export default useGetObjects
