const PAYMENT_TYPES = {
  CARD: 'Card',
  VIPPS: 'Vipps',
  GOOGLE_PAY: 'Google pay',
  APPLE_PAY_PAYMENT_TYPE: 'Apple pay'
}
const PAYMENT_TYPES_KEYS = {
  CARD: 'CARD',
  VIPPS: 'VIPPS',
  GOOGLE_PAY: 'GOOGLE_PAY',
  APPLE_PAY_PAYMENT_TYPE: 'APPLE_PAY_PAYMENT_TYPE'
}
const PAYMENT_TYPES_OPTIONS = Object.keys(PAYMENT_TYPES).map((key) => ({
  label: PAYMENT_TYPES[key],
  value: key
}))

export { PAYMENT_TYPES, PAYMENT_TYPES_KEYS, PAYMENT_TYPES_OPTIONS }
