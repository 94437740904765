import { Box, Col, Divider, Img, Row, Text, Title } from '@qonsoll/react-design'

import { Icon } from '@qonsoll/icons'
import PropTypes from 'prop-types'
import { TYPE_OF_PLACE } from '__constants__'
import { Tooltip } from 'antd'
import moment from 'moment'
import notFound from 'assets/Error-01.png'
import { useCustomerContext } from 'contexts/CustomerContext'
import { useMemo } from 'react'
import { useTranslations } from 'contexts/Translation'

const RestaurantSideView = (props) => {
  const { restaurant, children } = props
  const { customer } = useCustomerContext()
  /* Getting translations instance */
  const { t } = useTranslations()

  const expiredDate = useMemo(
    () =>
      moment(restaurant?.dateOfLicenseExpiration?.toDate())?.format('YYYY/M/D'),
    [restaurant?.dateOfLicenseExpiration]
  )

  const billingPlan = useMemo(
    () =>
      `${restaurant?.billingType?.[0]}${restaurant?.billingType
        ?.substring(1)
        ?.toLowerCase()} `,
    [restaurant?.billingType]
  )
  const billingPlanComputed = restaurant?.billingType?.[0] ? billingPlan : 'n/a'
  const restaurantCurrency = restaurant?.currency || customer?.currency
  return (
    <Row noGutters>
      <Col>
        <Img
          height={128}
          width={128}
          objectFit="cover"
          alt="Restaurant logo"
          borderRadius="20px"
          src={restaurant?.logoUrl ?? notFound}
        />
      </Col>

      <Col cw={12} mb={3}>
        <Title clamp="1">{restaurant?.name}</Title>
      </Col>

      {/* Now Ann did`nt know exactly how it should works  */}
      {/* {restaurant?.welcomeMessage && (
        <>
          <Col cw={12}>
            <Text fontWeight={500} fontSize="18px">
              <i>{restaurant?.welcomeMessage}</i>
            </Text>
          </Col>

          <Divider my={3} />
        </>
      )} */}

      <Col cw={12} mb={3}>
        <Title mb={1} level={3}>
          {t('Description')}
        </Title>
        <Divider mb={2} />
        {restaurant?.description ? (
          <Text>{restaurant?.description}</Text>
        ) : (
          <Text type="secondary">{t('No description')}</Text>
        )}
      </Col>
      <Col cw={12} mb={3}>
        <Title mb={1} level={3}>
          {t('License')}
        </Title>
        <Divider mb={2} />
        <Box display="flex" mb={2} flex={1} justifyContent="space-between">
          <Text type="secondary">{`${t('Type')}:`}</Text>
          <Text>{restaurant?.typeOfLicense || 'n/a'}</Text>
        </Box>
        <Box display="flex" mb={2} flex={1} justifyContent="space-between">
          <Text type="secondary">{`${t('Number')}:`}</Text>
          <Text>{restaurant?.licenseNumber || 'n/a'}</Text>
        </Box>
        <Box display="flex" flex={1} justifyContent="space-between">
          <Text type="secondary">{`${t('Expired date')}:`}</Text>
          <Text>{expiredDate || 'n/a'}</Text>
        </Box>
      </Col>

      <Col cw={12} mb={3}>
        <Title mb={1} level={3}>
          {t('Billing')}
        </Title>
        <Divider mb={2} />
        <Box display="flex" mb={2} flex={1} justifyContent="space-between">
          <Text type="secondary">{`${t('Plan')}:`}</Text>
          <Text>{billingPlanComputed}</Text>
        </Box>
      </Col>
      {restaurant?.typeOfPlace ? (
        <Col cw={12} flexDirection="row" mb={3} h="center" mt={2}>
          <Tooltip title={t('Venue type')}>
            <Icon name="Building2Outlined" mr={2} />
          </Tooltip>
          <Text>{TYPE_OF_PLACE[restaurant?.typeOfPlace]}</Text>
        </Col>
      ) : (
        <Col cw={12} mb={3}>
          <Title mb={1} level={3}>
            {t('Other')}
          </Title>
          <Divider mb={2} />
          <Box display="flex" mb={2} flex={1} justifyContent="space-between">
            <Text type="secondary">{`${t('Venue type')}:`}</Text>
            <Text>n/a</Text>
          </Box>
        </Col>
      )}
      <Col cw={12} mb={3} h="center" flexDirection="row">
        <Tooltip title={t('Venue currency')}>
          <Icon name="CurrencyDollarCircleOutlined" mr={2} />
        </Tooltip>
        <Text>{restaurantCurrency}</Text>
      </Col>
      <Col cw={12}>{children}</Col>
    </Row>
  )
}

RestaurantSideView.propTypes = {
  restaurant: PropTypes.object,
  children: PropTypes.node
}

export default RestaurantSideView
