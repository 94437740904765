import { Box, Col, Row, Text } from '@qonsoll/react-design'
import { ButtonAccent, CardDropdown, SelectableCard } from 'components'

import { Icon } from '@qonsoll/icons'
import PropTypes from 'prop-types'
import { useObjectInstanceBaseActions } from 'domains/ObjectInstance/hooks'
import { useTranslations } from 'contexts/Translation'
import { useShowDataTypes } from 'hooks'

const ObjectInstanceSimpleView = (props) => {
  const {
    objectInstanceData,
    cursor,
    actions,
    onOpen,
    index,
    editMode = false,
    onEdit,
    selectedLanguage,
    chooseObject,
    updateItemTypeInstanceWithObjectValue,
    objectFieldName
  } = props

  const { t, language } = useTranslations()
  const { renderField } = useShowDataTypes()

  const { onDelete } = useObjectInstanceBaseActions({
    objectInstance: objectInstanceData,
    callback: updateItemTypeInstanceWithObjectValue,
    fieldName: objectFieldName,
    objectInstanceId: objectInstanceData?._id
  })
  const nameComputed =
    objectInstanceData?.name?.[selectedLanguage] ??
    objectInstanceData?.name?.[language] ??
    objectInstanceData?.name?.en ??
    t('Object instance')

  const handleEdit = () => {
    onEdit()
    chooseObject(objectInstanceData)
  }

  return objectInstanceData ? (
    <CardDropdown
      style={{ cursor }}
      document={objectInstanceData}
      index={index}
      height="100%"
      onClick={onOpen}
      data-testid="listItem"
      {...actions}>
      <SelectableCard height="100%" style={{ height: '100%', cursor }}>
        <Row noGutters v="center" h="between">
          {objectInstanceData?.value?.length ? (
            <Col cw={12} height="100%">
              {objectInstanceData?.value?.map((item, index) => {
                return (
                  <Box mb={2} key={`${item?._id}-${index}`}>
                    {renderField(item)}
                  </Box>
                )
              })}
            </Col>
          ) : (
            <Col>
              <Text wordBreak="break-word">{nameComputed}</Text>
            </Col>
          )}
          {editMode && (
            <>
              <Col cw="auto">
                <ButtonAccent
                  onClick={handleEdit}
                  type="text"
                  icon={
                    <Box>
                      <Icon name="Edit3Outlined" />
                    </Box>
                  }
                />
              </Col>
              <Col cw="auto">
                <ButtonAccent
                  onClick={onDelete}
                  danger
                  type="text"
                  icon={<Icon name="Trash4Outlined" />}
                />
              </Col>
            </>
          )}
        </Row>
      </SelectableCard>
    </CardDropdown>
  ) : (
    'n/a'
  )
}

ObjectInstanceSimpleView.propTypes = {
  objectInstanceData: PropTypes.object,
  onOpen: PropTypes.func,
  index: PropTypes.number,
  actions: PropTypes.object,
  cursor: PropTypes.string,
  objectFieldName: PropTypes.string,
  editMode: PropTypes.bool,
  onEdit: PropTypes.func,
  chooseObject: PropTypes.func,
  updateItemTypeInstanceWithObjectValue: PropTypes.func,
  selectedLanguage: PropTypes.bool,
  data: PropTypes.object
}

export default ObjectInstanceSimpleView
