import { Checkbox } from 'components'
import PropTypes from 'prop-types'
import { StyledDetails } from '../RestaurantFilter.styles'
import { Text } from '@qonsoll/react-design'
import { useGetOrders } from 'domains/Order/hooks'
import { useSubfilterActions } from 'hooks'
import { useTranslations } from 'contexts/Translation'

const OrderFilter = ({ filterData, setFilterData }) => {
  // [ADDITIONAL_HOOKS]
  const [orders] = useGetOrders()
  const { t } = useTranslations()

  // [COMPUTED_PROPERTIES]
  const summaryStyles = { marginBottom: '8px' }

  const { onToggleDetails, onChange, checkIsEnabled } = useSubfilterActions({
    filterData,
    setFilterData,
    fieldName: 'orders',
    operand: 'array-contains'
  })

  return (
    <StyledDetails
      open={localStorage.getItem(`order-details-visibility`) === 'opened'}>
      <summary style={summaryStyles} onClick={() => onToggleDetails('order')}>
        {t('Order')}
      </summary>
      {orders?.map((order, index) => (
        <div key={`status-${index}`}>
          <Checkbox
            key={`status-${index}`}
            checked={checkIsEnabled(order)}
            onChange={() => onChange(order)}>
            <Text wordBreak="break-word">
              {order?.status || t('Unnamed filter')}
            </Text>
          </Checkbox>
        </div>
      ))}
    </StyledDetails>
  )
}

OrderFilter.propTypes = {
  filterData: PropTypes.object,
  setFilterData: PropTypes.func
}

export default OrderFilter
