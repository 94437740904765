import { CardDropdown, SelectableCard } from 'components'
import { GlassTypeForm, GlassTypeView } from './components'

import { COLLECTIONS } from '__constants__'
import { Form } from 'antd'
import PropTypes from 'prop-types'
import { useSimpleFormActions } from 'hooks'
import { useState } from 'react'

const GlassTypeSimpleView = (props) => {
  const { glassType, onSelect, index, isSelected, actions, typeImage } = props

  /* Checking if component is in editing mode */
  const [isEditing, setIsEditing] = useState(false)
  /* Getting form instance from Form.useForm hook */
  const [form] = Form.useForm()
  /* Getting actions from useSimpleFormActions hook */
  const {
    handleDelete: removeDocument,
    handleCancel,
    handleSave,
    handleEdit
  } = useSimpleFormActions({
    collectionName: COLLECTIONS.GLASS_TYPES,
    document: glassType,
    form,
    changeStateAction: setIsEditing
  })

  /* Handling deletion of the item */
  const handleDelete = () => removeDocument()
  /* Handling selection of the item */
  const handleSelect = (e) => {
    e.stopPropagation()
    onSelect?.(glassType?._id)
  }

  return (
    <CardDropdown
      mb={3}
      onDelete={handleDelete}
      onEdit={handleEdit}
      onClick={handleSelect}
      document={glassType}
      index={index}
      data-testid="glassTypeListItem"
      {...actions}>
      <SelectableCard style={{ padding: '12px' }} isSelected={isSelected}>
        {!isEditing ? (
          <GlassTypeView typeImage={typeImage} glassType={glassType} />
        ) : (
          <GlassTypeForm
            glassType={glassType}
            form={form}
            handleCancel={handleCancel}
            handleSave={handleSave}
          />
        )}
      </SelectableCard>
    </CardDropdown>
  )
}

GlassTypeSimpleView.propTypes = {
  glassType: PropTypes.object,
  onSelect: PropTypes.func,
  index: PropTypes.number,
  isSelected: PropTypes.bool,
  actions: PropTypes.object,
  typeImage: PropTypes.bool
}

export default GlassTypeSimpleView
