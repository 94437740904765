import { Container } from '@qonsoll/react-design'
import PropTypes from 'prop-types'
import { QRCodeList } from 'domains/QRCode/components'
import { useQRCodeBaseActions } from 'domains/QRCode/hooks'

const TableQRCodes = (props) => {
  const {
    table,
    actions = {},
    restaurant,
    refresh,
    tableQRCodes,
    zoneId
  } = props

  /* Getting QRCode actions */
  const { onDelete, onCreate } = useQRCodeBaseActions({
    table,
    refresh,
    restaurant,
    zoneId
  })

  /* Actions */
  const computedActions = {
    ...actions,
    onDelete,
    onCreate
  }

  return (
    <Container>
      <QRCodeList
        cw={[12, 6]}
        isListWithCreate={true}
        actions={computedActions}
        qRCodes={tableQRCodes}
        withoutEmptyMessage
        isSearchQRCode
      />
    </Container>
  )
}
TableQRCodes.propTypes = {
  table: PropTypes.object.isRequired,
  actions: PropTypes.object,
  setActions: PropTypes.func,
  selectedItems: PropTypes.array,
  setSelectedItems: PropTypes.func,
  restaurant: PropTypes.object.isRequired,
  refresh: PropTypes.func,
  tableQRCodes: PropTypes.array,
  zoneId: PropTypes.string.isRequired
}

export default TableQRCodes
