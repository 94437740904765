import { useEffect, useState } from 'react'

import { Form } from 'antd'
import moment from 'moment'

const useInitializeProfileAdvancedForm = (initialData, companyAddress) => {
  /* Getting form instance */
  const [form] = Form.useForm()
  const [mainLanguage, setMainLanguage] = useState(null)
  const [mainCurrency, setMainCurrency] = useState(null)

  /* Setting initial data */
  useEffect(() => {
    if (initialData) {
      /* Getting initial values */
      const { customer, user } = initialData

      /* Formatting date fields */
      const formValues = {
        customer: {
          _id: customer?.['_id'] ?? null,
          name: customer?.['name'] ?? null,
          contactPerson: customer?.['contactPerson'] ?? null,
          description: customer?.['description'] ?? null,
          logoUrl: customer?.['logoUrl'] ?? null,
          typeOfLicense: customer?.['typeOfLicense'] ?? null,
          licenseNumber: customer?.['licenseNumber'] ?? null,
          dateOfLicenseExpiration:
            moment(customer?.['dateOfLicenseExpiration']?.toDate()) ?? null,
          mainLanguage: customer?.['mainLanguage'] ?? null,
          additionalLanguage: customer?.['additionalLanguage'] ?? null,
          currency: customer?.['currency'] ?? null,
          billingType: customer?.['billingType'] ?? null,
          phoneNumber: customer?.['phoneNumber'] ?? null,
          email: customer?.['email'] ?? null,
          locationInfo:
            companyAddress?.locationId && companyAddress?.description
              ? {
                  locationId: companyAddress?.locationId,
                  description: companyAddress?.description,
                  locationInfo: {
                    locationId: companyAddress?.locationId,
                    description: companyAddress?.description
                  }
                }
              : null
        },
        user: {
          _id: user?.['_id'] ?? null,
          firstName: user?.['firstName'] ?? null,
          lastName: user?.['lastName'] ?? null,
          phone: user?.['phone'] ?? null,
          email: user?.['email'] ?? null
        }
      }

      setMainLanguage(customer?.mainLanguage)
      setMainCurrency(customer?.currency)

      /* Setting initial values to form instance */
      form.setFieldsValue(formValues)
    }
  }, [
    initialData,
    form,
    companyAddress?.locationId,
    companyAddress?.description
  ])

  return { form, mainLanguage, setMainLanguage, mainCurrency, setMainCurrency }
}

export default useInitializeProfileAdvancedForm
