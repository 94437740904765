import { Col, PageWrapper, Row } from '@qonsoll/react-design'
import { HeaderBreadcrumbs, Spinner } from 'components'
import { useHistory, useParams } from 'react-router-dom'

import { ItemTypeInstanceAdvancedForm } from 'domains/ItemTypeInstance/components'
import { useGetItemTypeInstanceInitialValues } from 'domains/ItemTypeInstance/hooks'
import { useGetMenuItemDataEdit } from 'domains/MenuItem/hooks'
import { useLoading } from 'hooks'
import { useTranslations } from 'contexts/Translation'

const MenuItemEdit = () => {
  /* Getting translations instance */
  const { t } = useTranslations()
  const { itemTypeId, menuItemId } = useParams()
  const history = useHistory()

  const [initialData, loading] = useGetItemTypeInstanceInitialValues()

  const [combinedData, combinedLoading] = useGetMenuItemDataEdit(
    itemTypeId,
    menuItemId
  )

  /* Configuring page header */
  const headingProps = {
    marginBottom: 1,
    title: t('Edit menu item'),
    textAlign: 'left'
  }
  const loadingText = `${t('Is loading')}...`

  const onBack = () => {
    history.goBack()
  }
  const isLoading = useLoading([loading, combinedLoading])
  return (
    <PageWrapper
      onBack={onBack}
      breadcrumbs={<HeaderBreadcrumbs />}
      headingProps={headingProps}>
      <Row noGutters>
        <Col>
          {isLoading ? (
            <Spinner text={loadingText} />
          ) : (
            <ItemTypeInstanceAdvancedForm
              initialData={initialData}
              value={combinedData}
              editMode
            />
          )}
        </Col>
      </Row>
    </PageWrapper>
  )
}

export default MenuItemEdit
