import { Box, Col, Input, Row, Select, Text } from '@qonsoll/react-design'
import {
  OBJECT_RELATION_TYPES,
  OBJECT_RELATION_TYPES_OPTIONS,
  OBJECT_RELATION_TYPES_WITH_REFERENCE
} from '__constants__'
import {
  StyledCheckBox,
  StyledCheckboxWrapper,
  StyledCol,
  StyledFormItem,
  StyledInput,
  styles
} from './ItemTypeFieldSimpleForm.styles'
import { useMemo, useState } from 'react'

import { ConditionalTooltipWrapper } from 'components'
import { Draggable } from 'react-beautiful-dnd'
import { Form } from 'antd'
import { Icon } from '@qonsoll/icons'
import PropTypes from 'prop-types'
import _ from 'lodash'
import { getRemoveFieldButtonProps } from 'helpers'
import { useFieldSimpleFormValidationRules } from 'hooks/forms'
import { useGetEnums } from 'domains/Enum/hooks'
import { useGetLists } from 'domains/List/hooks'
import { useGetObjects } from 'domains/Object/hooks'
import { useLoading } from 'hooks'
import { useTranslations } from '@qonsoll/translation'

const ItemTypeFieldSimpleForm = (props) => {
  const {
    field,
    index,
    selectedLanguage,
    onRemoveField,
    isRemoveButtonDisabled,
    listsFormValue
  } = props
  const [inputValue, setInputValue] = useState('')

  const { t } = useTranslations()
  const isFieldCanBeLocalized =
    field?.relationType === OBJECT_RELATION_TYPES.STRING

  const isReferenceSelectDisabled =
    !OBJECT_RELATION_TYPES_WITH_REFERENCE.includes(field?.relationType)

  const inputAddon = selectedLanguage?.toUpperCase()

  /* Fetching data from collections */
  const [lists, listsLoading] = useGetLists()
  const [objects, objectsLoading] = useGetObjects()
  const [enums, enumsLoading] = useGetEnums({ orderBy: ['_createdAt', 'desc'] })

  /* Prepared options for select with reference */
  const referenceSelectOptions = useMemo(() => {
    if (Array.isArray(lists) && Array.isArray(objects)) {
      const listsOptions = {
        label: t('Lists'),
        options: lists.map((list) => ({
          label: list?.name,
          value: `list-${list?._id}`
        }))
      }

      const objectsOptions = {
        label: t('Objects'),
        options: objects.map((object) => ({
          label: object?.name,
          value: `object-${object?._id}`
        }))
      }

      const enumsOptions = {
        label: t('Enums'),
        options:
          enums?.map((enumItem) => ({
            label: enumItem?.name?.[selectedLanguage] ?? enumItem?.name?.en,
            value: `enum-${enumItem?._id}`
          })) ?? []
      }

      return [listsOptions, objectsOptions, enumsOptions]
    }
    return []
  }, [enums, lists, objects, selectedLanguage, t])

  /* Select loading with reference */
  const referenceSelectLoading = useLoading([
    listsLoading,
    objectsLoading,
    enumsLoading
  ])

  const camelCasingFieldNameFromInput = (e) => {
    if (field?.name !== inputValue) {
      setInputValue(_.camelCase(e.target.value))
      field.name = _.camelCase(e.target.value)
    }
  }
  const fieldId = field?._id || field?.uid

  const removeButtonProps = getRemoveFieldButtonProps(
    isRemoveButtonDisabled || field?.isDisabledChanges,
    onRemoveField(fieldId),
    field
  )

  const { validateFieldUniqueName, validateEnglishLabel } =
    useFieldSimpleFormValidationRules(listsFormValue, field)

  return (
    <Draggable key={fieldId} draggableId={fieldId} index={index}>
      {(provided) => (
        <Row
          pl={20}
          noOuterGutters
          mb={24}
          display="flex"
          alignItems="flex-start"
          ref={provided.innerRef}
          {...provided.draggableProps}
          key={field?.uid}>
          <StyledCol cw={6} flexDirection="row">
            <Box
              mr={32}
              alignItems="center"
              flexDirection="row"
              flex="1"
              position="relative">
              <Box position="relative" flex="1">
                <StyledFormItem
                  label={t('Field name')}
                  rules={[
                    {
                      required: true,
                      message: t('Please enter field name')
                    },
                    !field?.isDisabledChanges
                      ? {
                          validator: validateFieldUniqueName
                        }
                      : {}
                  ]}
                  name={['fields', fieldId, 'name']}>
                  <Input
                    value={inputValue}
                    onBlur={camelCasingFieldNameFromInput}
                    disabled={field?.isDisabledChanges}
                    placeholder={t('Enter name')}
                  />
                </StyledFormItem>
                <Icon {...styles.iconStyles} {...provided.dragHandleProps} />

                <StyledCheckboxWrapper>
                  <Box mr={2}>
                    <StyledFormItem
                      valuePropName="checked"
                      name={['fields', fieldId, 'isRequired']}>
                      <StyledCheckBox>{t('Mandatory')}</StyledCheckBox>
                    </StyledFormItem>
                  </Box>
                  <Box>
                    <StyledFormItem
                      valuePropName="checked"
                      name={['fields', fieldId, 'isEditable']}>
                      <StyledCheckBox>{t('Editable')}</StyledCheckBox>
                    </StyledFormItem>
                  </Box>
                </StyledCheckboxWrapper>
              </Box>
            </Box>
            <Box flex="1">
              <Form.Item
                label={t('Field label')}
                rules={[
                  {
                    validator: validateEnglishLabel
                  }
                ]}
                name={['fields', fieldId, 'label', selectedLanguage]}>
                <StyledInput
                  addonAfter={inputAddon}
                  placeholder={t('Enter type label')}
                />
              </Form.Item>
            </Box>
          </StyledCol>

          <StyledCol cw={6}>
            <Row noOuterGutters width="100%" flexWrap="nowrap">
              <Col cw={5} position="relative" flex="1">
                <Form.Item
                  label={<Text isEllipsis>{t('Relation type')}</Text>}
                  name={['fields', fieldId, 'relationType']}>
                  <Select
                    maxWidth="100%"
                    disabled={field?.isDisabledChanges}
                    options={OBJECT_RELATION_TYPES_OPTIONS}
                    placeholder={t('Select relation type')}
                  />
                </Form.Item>
                {isFieldCanBeLocalized && (
                  <Box top="68px" position="absolute" width="200px">
                    <Form.Item
                      valuePropName="checked"
                      name={['fields', fieldId, 'isLocalizationEnabled']}>
                      <StyledCheckBox>
                        {t('Enable localization')}
                      </StyledCheckBox>
                    </Form.Item>
                  </Box>
                )}
              </Col>

              <Col cw={5}>
                <StyledFormItem
                  label={t('Reference')}
                  name={['fields', fieldId, 'reference']}
                  rules={[
                    {
                      required: !isReferenceSelectDisabled && true,
                      message: t('Please enter field reference')
                    }
                  ]}>
                  <Select
                    showSearch
                    filterOption={(input, option) =>
                      (option?.label ?? '')
                        .toLowerCase()
                        .includes(input.toLowerCase())
                    }
                    disabled={isReferenceSelectDisabled}
                    loading={referenceSelectLoading}
                    options={referenceSelectOptions}
                    placeholder={t('Select reference')}
                  />
                </StyledFormItem>
              </Col>

              <Col cw={2} alignSelf="center">
                <ConditionalTooltipWrapper
                  tooltipTitle={t(
                    'This field is required and cannot be deleted, as everything has a name'
                  )}
                  tooltipPlacement="left"
                  withTooltip={field?.name === 'name'}>
                  <Icon
                    name="Trash3Outlined"
                    size={20}
                    {...removeButtonProps}
                  />
                </ConditionalTooltipWrapper>
              </Col>
            </Row>
          </StyledCol>
        </Row>
      )}
    </Draggable>
  )
}

ItemTypeFieldSimpleForm.propTypes = {
  field: PropTypes.object,
  index: PropTypes.number,
  selectedLanguage: PropTypes.string,
  onRemoveField: PropTypes.func,
  isRemoveButtonDisabled: PropTypes.bool,
  listsFormValue: PropTypes.object
}

export default ItemTypeFieldSimpleForm
