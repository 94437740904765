import * as yup from 'yup'

import {
  attr,
  belongsTo,
  hasMany,
  hasManyWithCreate,
  model
} from 'services/model'

const validationSchema = yup.object().shape({
  address: yup
    .string()
    .typeError('Field address has wrong type')
    .default(null)
    .nullable(),
  name: yup
    .string()
    .typeError('Field name has wrong type')
    .required('Field name is required'),
  welcomeMessage: yup
    .string()
    .typeError('Field welcomeMessage has wrong type')
    .default(null)
    .nullable(),
  logoUrl: yup
    .string()
    .typeError('Field logoUrl has wrong type')
    .default(null)
    .nullable(),
  mediaObjects: yup
    .array()
    .typeError('Field mediaObjects has wrong type')
    .default(null)
    .nullable(),
  menus: yup
    .array()
    .typeError('Field menus has wrong type')
    .default(null)
    .nullable(),
  orders: yup
    .array()
    .typeError('Field orders has wrong type')
    .default(null)
    .nullable(),
  qrCodes: yup
    .array()
    .typeError('Field qrCodes has wrong type')
    .default(null)
    .nullable()
})

const Restaurant = model(
  'restaurant',
  {
    address: belongsTo('address'),
    name: attr('string'),
    welcomeMessage: attr('string'),
    description: attr('string'),
    logoUrl: attr('string'),
    mediaObjects: hasManyWithCreate('mediaObject'),
    menus: hasMany('menu'),
    orders: hasMany('order'),
    qrCodes: hasManyWithCreate('qRCode')
  },
  validationSchema
)

export default Restaurant
