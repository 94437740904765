import { useEffect } from 'react'
import { Form } from 'antd'

const useInitializeDecorationTypeAdvancedForm = (initialData) => {
  /* Getting form instance */
  const [form] = Form.useForm()

  /* Setting initial data */
  useEffect(() => {
    if (initialData) {
      /* Getting initial values */
      const { decorationType } = initialData

      /* Formatting date fields */
      const formValues = {
        decorationType: {
          name: decorationType?.['name'] ?? null,
          value: decorationType?.['value'] ?? null,
          icon: decorationType?.['icon'] ?? null
        }
      }

      /* Setting initial values to form instance */
      form.setFieldsValue(formValues)
    }
  }, [initialData, form])

  return { form }
}

export default useInitializeDecorationTypeAdvancedForm
