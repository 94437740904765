import { Box, Col, Container, Row, Text, Title } from '@qonsoll/react-design'
import { Icon } from '@qonsoll/icons'
import { Tag, Popconfirm, Popover } from 'antd'
import PropTypes from 'prop-types'

import { ButtonAccent } from 'components'
import { handleLanguage } from 'helpers'
import { useTranslations } from 'contexts/Translation'

import { useTableBaseActions } from './hooks'

import { useGetItemTypeInstanceInitialValues } from 'domains/ItemTypeInstance/hooks'
import { MenuDrawer } from 'domains/Menu/components'
import { MenuCategoryFormDrawer } from 'domains/MenuCategory/components'

const CocktailTableWithCreateAndSelect = (props) => {
  const { value = [], onChange, menuShow, additionalActions, search } = props
  /* Getting translation instance */
  const { t, language } = useTranslations()

  const {
    isAdding,
    editedCocktailInitialData,
    toggleAdd,
    customOnCancel,
    onClearCategory,
    itemSelected,
    menuItemsSelected,
    onChangeCategory,
    handleMenuItemsSelectChange,
    handleSelectOptions,
    customSaveNewMenuItem,
    menuItems,
    itemTypes,
    isEditing,
    toggleEdit,
    onChangeVisibilityEditing,
    menuCategoryEdit,
    handleCancelCategoryEdit,
    handleChangeCategoryEdit,
    onDeleteCategory,
    firstNameOfMenuCategory
  } = useTableBaseActions({ value, onChange })

  const [initialData] = useGetItemTypeInstanceInitialValues(itemSelected)
  const menuDrawerData = {
    isAdding,
    editedCocktailInitialData,
    itemSelected,
    onClearCategory,
    onChangeCategory,
    categories: itemTypes,
    initialData,
    customOnCancel,
    customSaveNewMenuItem,
    handleSelectOptions,
    menuItems,
    menuItemsSelected,
    handleMenuItemsSelectChange
  }
  const fullWidthTable = value?.length || menuShow

  const nameMenuCategory = (name) => name?.[language] ?? name?.en
  const popconfirmTitle = (name) =>
    `${t('Are you sure you want to remove')} ${nameMenuCategory(name)} ?`

  return (
    <Container>
      <Row
        mb={3}
        noGutters
        width="inherit"
        flexDirection="column"
        h={fullWidthTable ? 'between' : 'left'}>
        <Col
          cw={fullWidthTable && !search && 'auto'}
          flexDirection="row"
          alignItems="center"
          justifyContent="space-between">
          <Title level={4}>{t('List of menu categories and items')}</Title>
          {additionalActions && <Box mr={2}>{additionalActions}</Box>}
          <ButtonAccent
            onClick={toggleAdd}
            type="secondary"
            icon={<Icon name="Plus1Outlined" mr={1} />}>
            {t('Add item')}
          </ButtonAccent>
        </Col>
        <Col>
          {!value?.length && (
            <Box display="flex" flexDirection="column" mt={4}>
              <Text fontSize={16}>{`${t(
                'There are no items on your menu yet'
              )}.`}</Text>
              <Text fontSize={16}>{`${t(
                'Add at least one item to the menu'
              )}.`}</Text>
            </Box>
          )}
        </Col>
      </Row>

      <MenuDrawer value={menuDrawerData} />

      {/* List of (itemTypes) and their (menuItems) */}
      {value?.length ? (
        <Row noGutters>
          {value?.map((menuCategory, index) => (
            <Col key={menuCategory?.itemTypeId ?? index} cw={12}>
              <Box
                mb={2}
                display="flex"
                alignItems="center"
                justifyContent="space-between">
                <Title variant="h4">
                  {nameMenuCategory(menuCategory?.name)}
                </Title>
                <Box>
                  <Popover
                    content={firstNameOfMenuCategory(menuCategory?.itemTypeId)}>
                    <ButtonAccent
                      type="text"
                      icon={
                        <Box>
                          <Icon name="InfoCircleOutlined" />
                        </Box>
                      }
                    />
                  </Popover>
                  <ButtonAccent
                    onClick={onChangeVisibilityEditing(menuCategory)}
                    type="text"
                    icon={
                      <Box>
                        <Icon name="Edit3Outlined" />
                      </Box>
                    }
                  />
                  <Popconfirm
                    title={popconfirmTitle(menuCategory?.name)}
                    okText={t('Yes')}
                    onConfirm={onDeleteCategory(menuCategory?.itemTypeId)}
                    cancelText={t('No')}>
                    <ButtonAccent
                      icon={<Icon name="Trash4Outlined" />}
                      danger
                      type="text"
                    />
                  </Popconfirm>
                </Box>
              </Box>
              <Box display="flex" flexWrap="wrap">
                {menuCategory?.itemsOrder?.map((item, index) => (
                  <Box key={item?._id || index} mb={1}>
                    <Tag>{handleLanguage(item?.name, language)}</Tag>
                  </Box>
                ))}
              </Box>
            </Col>
          ))}
        </Row>
      ) : null}

      <MenuCategoryFormDrawer
        isEditing={isEditing}
        toggleEdit={toggleEdit}
        handleCancel={handleCancelCategoryEdit}
        handleChange={handleChangeCategoryEdit}
        value={menuCategoryEdit}
      />
    </Container>
  )
}

CocktailTableWithCreateAndSelect.propTypes = {
  value: PropTypes.arrayOf(PropTypes.object),
  onChange: PropTypes.func,
  menuShow: PropTypes.bool,
  menu: PropTypes.object,
  onDishDelete: PropTypes.func,
  isCreate: PropTypes.bool,
  onCreateCocktailCallback: PropTypes.func,
  onSelectCocktailsCallback: PropTypes.func,
  onCategoryCreateCallback: PropTypes.func,
  onCocktailDeleteCallback: PropTypes.func,
  onCocktailEditCallback: PropTypes.func,
  onDragAndDrop: PropTypes.func,
  additionalActions: PropTypes.node,
  onCategoryEdit: PropTypes.func,
  onCategoryDelete: PropTypes.func,
  isNewMenu: PropTypes.bool,
  numberOfAvailableItems: PropTypes.number,
  isDragAndDropDisabled: PropTypes.bool,
  search: PropTypes.node
}

export default CocktailTableWithCreateAndSelect
