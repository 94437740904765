import { uid } from 'helpers'
import { OBJECT_RELATION_TYPES } from '__constants__'

const generateNewObjectField = () => ({
  uid: uid(),
  name: '',
  label: {
    en: '',
    no: ''
  },
  isLocalizationEnabled: true,
  relationType: OBJECT_RELATION_TYPES.STRING,
  isRequired: false,
  reference: null
})

export default generateNewObjectField
