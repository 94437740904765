const CATEGORIES_KEYS = {
  BEER: 'BEER',
  DRY_GOODS: 'DRY_GOODS',
  FORTIFIED_WINE: 'FORTIFIED_WINE',
  FRUIT: 'FRUIT',
  HERBS: 'HERBS',
  MEAT: 'MEAT',
  MINERAL_WATER: 'MINERAL_WATER',
  SPIRITS: 'SPIRITS',
  VEGETABLE: 'VEGETABLE',
  WINE: 'WINE'
}

const CATEGORIES = {
  [CATEGORIES_KEYS.BEER]: 'Beer',
  [CATEGORIES_KEYS.DRY_GOODS]: 'Dry goods',
  [CATEGORIES_KEYS.FORTIFIED_WINE]: 'Fortified wine',
  [CATEGORIES_KEYS.FRUIT]: 'Fruit',
  [CATEGORIES_KEYS.HERBS]: 'Herbs',
  [CATEGORIES_KEYS.MEAT]: 'Meat',
  [CATEGORIES_KEYS.MINERAL_WATER]: 'Mineral water',
  [CATEGORIES_KEYS.SPIRITS]: 'Spirits',
  [CATEGORIES_KEYS.VEGETABLE]: 'Vegetable',
  [CATEGORIES_KEYS.WINE]: 'Wine'
}

const CATEGORIES_OPTIONS = Object.keys(CATEGORIES).map((key) => ({
  label: CATEGORIES[key],
  value: key
}))

export { CATEGORIES, CATEGORIES_KEYS, CATEGORIES_OPTIONS }
