import { Card } from '@qonsoll/react-design'
import styled from 'styled-components'

export const ProfileNavigatorCard = styled(Card).attrs({ tabIndex: 0 })`
  height: 100%;
  width: -webkit-fill-available;
  width: -moz-available;
  width: fill-available;
  cursor: pointer;
  & .ant-card-body {
    display: flex;
    flex: 1;
    align-items: center;
    justify-content: center;
    height: 100%;
  }
  &:focus-visible {
    outline: 2px solid var(--ql-color-white);
    box-shadow: 0 0 0 4px var(--ql-color-accent2);
  }
`
