import { Box, Card } from '@qonsoll/react-design'

import { Checkbox } from 'components'
import PropTypes from 'prop-types'
import { cardStyles } from './SelectableCard.styled'

/**
 * If the card is selected, use the selected styles, otherwise use the default styles.
 * @param isSelected {boolean} - If true, the card is selected
 * @param onSelect {function} - The function to call when the card is selected
 */
const SelectableCard = (props) => {
  const { isSelected, onSelect, children, style, height } = props

  // [COMPUTED_PROPERTIES]
  const styles = style || cardStyles.default

  return (
    <Card
      onClick={onSelect}
      bodyStyle={{ ...styles, ...style }}
      shadowless
      height={height}>
      {(onSelect || isSelected) && (
        <Box position="absolute" right="8px" top="8px">
          <Checkbox onChange={onSelect} checked={isSelected} />
        </Box>
      )}
      {children}
    </Card>
  )
}

SelectableCard.propTypes = {
  isSelected: PropTypes.bool,
  onSelect: PropTypes.func,
  children: PropTypes.node,
  style: PropTypes.object,
  bodyStyle: PropTypes.object,
  height: PropTypes.string
}

export default SelectableCard
