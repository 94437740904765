import { generatePath, useHistory } from 'react-router-dom'

import { COLLECTIONS } from '__constants__'
import { Container } from '@qonsoll/react-design'
import PropTypes from 'prop-types'
import { RestaurantList } from 'domains/Restaurant/components'
import { updateDocument } from 'services/api/firebase'
import { useCustomerContext } from 'contexts/CustomerContext'
import { useGetCustomerRestaurants } from 'domains/Customer/hooks'
import { useRestaurantBaseActions } from 'domains/Restaurant/hooks'

export default function CustomerRestaurants(props) {
  const { customer, actions = {} } = props

  const { currentCustomerId } = useCustomerContext()

  /* Getting relationship data */
  // eslint-disable-next-line no-unused-vars
  const [restaurants, loading, error, refresh] =
    useGetCustomerRestaurants(customer)
  /* Getting history instance */
  const history = useHistory()
  /* Getting Restaurant actions */
  const { createPath } = useRestaurantBaseActions()

  const handleDelete = async (doc, index) => {
    customer.restaurants.splice(index, 1)
    await updateDocument(COLLECTIONS.CUSTOMERS, customer._id, {
      restaurants: customer.restaurants
    })
    refresh?.()
  }

  const handleCreate = () => {
    const pathParams = { companyId: currentCustomerId }

    history.push(generatePath(createPath, pathParams), {
      parent: {
        id: customer?._id,
        collection: COLLECTIONS.CUSTOMERS,
        field: 'restaurants',
        type: 'updateParentHasManyRelationship'
      }
    })
  }

  /* Actions */
  const computedActions = {
    ...actions,
    onDelete: handleDelete,
    onCreate: createPath ? handleCreate : null
  }

  return (
    <Container>
      <RestaurantList
        cw={[12, 6]}
        isListWithCreate={!!createPath}
        actions={computedActions}
        restaurants={restaurants}
      />
    </Container>
  )
}

CustomerRestaurants.propTypes = {
  customer: PropTypes.object.isRequired,
  actions: PropTypes.object
}
