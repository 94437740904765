const LANGUAGES = {
  EN: 'English',
  NO: 'Norwegian'
}

const LANGUAGES_OPTIONS = Object.keys(LANGUAGES).map((key) => ({
  label: LANGUAGES[key],
  value: key
}))

export default LANGUAGES
export { LANGUAGES, LANGUAGES_OPTIONS }
