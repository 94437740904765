import styled from 'styled-components'

const HeaderWrapper = styled.div`
  background: var(--ql-bg-white-default);
  border-bottom: 1px solid var(--ql-color-dark-t-lighten5);
  position: sticky;
  top: 0;
  z-index: 98;
`

const staticColStyle = {
  py: '3'
}

export { HeaderWrapper, staticColStyle }
