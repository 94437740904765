import { useTranslations } from 'contexts/Translation'

const useGetErrorText = () => {
  const { t } = useTranslations()
  const isDev = process.env.REACT_APP_ENVIRONMENT === 'DEVELOPMENT'

  const getErrorText = (error, withCode = true) => {
    // eslint-disable-next-line no-console
    isDev && console.error(error)
    return withCode
      ? t(`Something went wrong during data save`) +
          '. ' +
          t('Error code') +
          ': ' +
          error?.code
      : t(`Something went wrong during data save`) + '. '
  }

  return getErrorText
}

export default useGetErrorText
