import { Col, PageWrapper, Row } from '@qonsoll/react-design'

import { HeaderBreadcrumbs } from 'components'
import { RestaurantAdvancedForm } from 'domains/Restaurant/components'
import { useHistory } from 'react-router-dom'
import { useTranslations } from 'contexts/Translation'

const RestaurantCreatePage = (props) => {
  /* Getting history instance */
  const history = useHistory()
  const { t } = useTranslations()

  /* Function to handle back click */
  const handleBack = () => history.goBack()

  /* Configuring page header */
  const headingProps = {
    marginBottom: 1,
    title: t('Create new venue'),
    textAlign: 'left'
  }

  return (
    <PageWrapper
      onBack={handleBack}
      breadcrumbs={<HeaderBreadcrumbs />}
      headingProps={headingProps}>
      <Row noGutters>
        <Col cw={[12, 12, 8, 6]}>
          <RestaurantAdvancedForm />
        </Col>
      </Row>
    </PageWrapper>
  )
}

export default RestaurantCreatePage
