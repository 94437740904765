import { ChangePasswordForm } from 'domains/Session/components'
import { PageWrapper } from '@qonsoll/react-design'
import { useHandleError } from 'hooks'
import { useLocation } from 'react-router-dom'
import { useMemo } from 'react'
import { useSessionActions } from 'domains/Session/hooks'
import { useTranslations } from 'contexts/Translation'

const RestorePassword = () => {
  const { t } = useTranslations()
  // [ADDITIONAL HOOKS]
  const { changePassword } = useSessionActions()
  const onError = useHandleError()
  const { search } = useLocation()

  // [COMPUTED_PROPERTIES]
  const queryObject = useMemo(() => new URLSearchParams(search), [search])

  const triggerChangePassword = async (password) =>
    await changePassword(
      queryObject?.get('uid'),
      queryObject?.get('email'),
      password,
      queryObject?.get('mailId'),
      onError
    )

  return (
    <PageWrapper
      alignMiddle
      height="100%"
      headingProps={{
        title: t('Restore password'),
        subTitle: t('Enter new password and confirm it to change'),
        textAlign: 'center',
        marginBottom: 40
      }}
      contentWidth={['100%', '100%', 400]}>
      <ChangePasswordForm changePassword={triggerChangePassword} />
    </PageWrapper>
  )
}

export default RestorePassword
