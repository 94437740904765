import {
  useFormDataTypes,
  useFormItemName,
  useFormItemValuePropName
} from 'hooks'

import { Form } from 'antd'
import PropTypes from 'prop-types'
import { useTranslations } from 'contexts/Translation'

const ObjectInstanceFormItem = (props) => {
  const {
    selectedLanguage,
    field,
    formItemPrefix,
    index,
    itemProps,
    language,
    form,
    setIsDisabled
  } = props

  const { t } = useTranslations()
  const inputAddon = selectedLanguage?.toUpperCase()
  const dataTypes = useFormDataTypes({ inputAddon, selectedLanguage })
  const formItemName = useFormItemName({ selectedLanguage })
  const valuePropName = useFormItemValuePropName()

  // Validate input with field 'name', as english language should be required
  const validateEnglishLabel = () => {
    const formData = form.getFieldsValue()
    const keyFormData = Object.keys(formData)

    if (formData?.[keyFormData][0]?.name?.en?.trim() === '') {
      setIsDisabled(true)
      return Promise.reject(new Error(t('English name is required')))
    } else {
      setIsDisabled(false)
      return Promise.resolve()
    }
  }

  return (
    <Form.Item
      valuePropName={valuePropName(field)}
      rules={[
        {
          required: field?.isRequired,
          message: `${t('Please enter')} ${
            field?.label?.[selectedLanguage] ?? field?.name
          }`
        },
        { validator: validateEnglishLabel }
      ]}
      name={formItemName(formItemPrefix, field, index, field?.name)}
      label={field?.label?.[language] || field?.label?.en}
      key={`${index}-field`}>
      {dataTypes?.[field?.relationType]?.(field, itemProps) || <></>}
    </Form.Item>
  )
}

ObjectInstanceFormItem.propTypes = {
  selectedLanguage: PropTypes.string,
  language: PropTypes.string,
  field: PropTypes.object,
  formItemPrefix: PropTypes.array,
  index: PropTypes.number,
  itemProps: PropTypes.object,
  setIsDisabled: PropTypes.func,
  form: PropTypes.object
}

export default ObjectInstanceFormItem
