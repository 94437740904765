import { Box } from '@qonsoll/react-design'
import styled from 'styled-components'

const MainMenuWrapper = styled(Box)`
  &::-webkit-scrollbar {
    width: 6px;
  }
  &::-webkit-scrollbar-thumb {
    width: 6px;
    background: var(--ql-color-accent2-t-lighten5);
  }
  &::-webkit-scrollbar-track {
    width: 6px;
    background: transparent;
  }
  .ant-menu-item:hover div svg path {
    stroke: var(--ql-color-accent1);
  }
  .ant-menu:not(.ant-menu-horizontal) .ant-menu-item-selected div svg path {
    stroke: var(--ql-color-accent1);
    border-right: none;
  }
  /* default */
  .ant-menu-item:hover:not(.ant-menu-item-selected),
  .ant-menu-item:active:not(.ant-menu-item-selected),
  .ant-menu-item:focus:not(.ant-menu-item-selected) {
    background: var(--ql-bg-white-default);
    border-right: 3px solid var(--ql-border-accent1-default);
  }
  /* checked */
  .ant-menu:not(.ant-menu-horizontal) .ant-menu-item-selected {
    background: var(--ql-bg-accent1-lighten6);
  }
`

export default MainMenuWrapper
