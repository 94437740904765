import { Box } from '@qonsoll/react-design'
import styled from 'styled-components'

const ScrollBox = styled(Box)`
  box-sizing: border-box;
  overflow-y: scroll;
  max-height: ${({ maxHeight }) => maxHeight || '70vh'};
  width: 100%;

  &::-webkit-scrollbar {
    width: 0.25rem;
    border-radius: 1rem;
    margin-left: -6px;
  }
  &::-webkit-scrollbar-thumb {
    background: var(--action-secondary-bg);
    border-radius: 1rem;
  }
  &::-webkit-scrollbar-thumb:hover {
    background: var(--scroll-primary-hover-bg);
  }
`
export default ScrollBox
