import { Form, Input } from 'antd'

import { ButtonAccent } from 'components'
import PropTypes from 'prop-types'
import { useState } from 'react'
import { useTranslations } from 'contexts/Translation'

const ChangePasswordForm = (props) => {
  const { changePassword, ...rest } = props
  const { t } = useTranslations()

  // [COMPONENT_STATE]
  const [loading, setLoading] = useState(false)

  // [CLEAN_FUNCTIONS]
  const onFinish = async (values) => {
    /* Setting the loading state to true, then calling the signup function, then setting the loading
      state to false. */
    setLoading(true)
    await changePassword(values?.['password'])
    setLoading(false)
  }

  return (
    <Form onFinish={onFinish} {...rest}>
      <Form.Item
        name="password"
        hasFeedback
        rules={[
          { required: true, message: t('Please, enter your password') },
          { min: 8, message: t('Password must be at least 8 characters') }
        ]}>
        <Input.Password size="large" placeholder={t('Password')} />
      </Form.Item>
      <Form.Item
        name="confirm"
        dependencies={['password']}
        hasFeedback
        rules={[
          {
            required: true,
            message: t('Please confirm your password!')
          },
          ({ getFieldValue }) => ({
            validator(_, value) {
              if (!value || getFieldValue('password') === value) {
                return Promise.resolve()
              }
              return Promise.reject(
                new Error(t('The two passwords that you entered do not match!'))
              )
            }
          })
        ]}>
        <Input.Password size="large" placeholder={t('Confirm Password')} />
      </Form.Item>
      <ButtonAccent
        type="primary"
        htmlType="submit"
        size="large"
        block
        loading={loading}>
        {t('Change password')}
      </ButtonAccent>
    </Form>
  )
}

ChangePasswordForm.propTypes = {
  changePassword: PropTypes.func.isRequired
}

export default ChangePasswordForm
