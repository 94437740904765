import { Box, Text } from '@qonsoll/react-design'

import { Icon } from '@qonsoll/icons'
import PropTypes from 'prop-types'
import { useTranslations } from 'contexts/Translation'
import { useParams } from 'react-router-dom'
import { checkIsLocalizationObject } from 'helpers'

const MenuItemColumn = (props) => {
  const { item, dragable = false, draggableProps = {}, rewrites } = props
  const { language } = useTranslations()

  const { menuId } = useParams()

  const rewritesMenuObject = rewrites?.[menuId]

  const nameText =
    rewritesMenuObject?.name?.[language] || item?.name?.[language]

  const descriptionValue = rewritesMenuObject?.description || item?.description

  const descriptionText = checkIsLocalizationObject(descriptionValue)
    ? descriptionValue?.[language] || descriptionValue?.en
    : descriptionValue

  return (
    <>
      <Box
        display="flex"
        justifyContent="center"
        height="100%"
        alignItems="center"
        cursor="grab"
        id="drag-icon-wrapper"
        {...draggableProps}>
        {dragable && (
          <Icon
            mr={1}
            name="DraggerOutlined"
            size={20}
            fill="var(--ql-text-secondary)"
          />
        )}
      </Box>
      <Box
        display="flex"
        flexDirection="column"
        height="100%"
        justifyContent="center">
        <Text level={5}>{nameText}</Text>
        {descriptionText && (
          <Text variant="body2" wordBreak="break-word">
            {descriptionText}
          </Text>
        )}
      </Box>
    </>
  )
}

MenuItemColumn.propTypes = {
  item: PropTypes.object,
  dragable: PropTypes.bool,
  draggableProps: PropTypes.object,
  rewrites: PropTypes.object
}

export default MenuItemColumn
