import { useEffect, useState, useCallback } from 'react'
import { getDocument } from 'services/api/firebase'
import { COLLECTIONS } from '__constants__'

/**
 * It fetches a glassType document from Firestore and returns it
 * @param cocktail - The cocktail object that we're getting the glassType for.
 * @returns An array with four values. The first value is the glassType object, and the second value is a
 * boolean that indicates whether the glassType is loading.
 */
const useGetCocktailGlassType = (cocktail) => {
  /* State for data */
  const [glassType, setGlassType] = useState(null)
  /* State for loading */
  const [loading, setLoading] = useState(!!cocktail?.glassType)
  /* State for error */
  const [error, setError] = useState(null)

  /* Fetching data */
  const getGlassType = useCallback(async () => {
    try {
      setLoading(true)
      const document = await getDocument(
        COLLECTIONS.GLASS_TYPES,
        cocktail.glassType
      )
      setGlassType(document)
      setLoading(false)
    } catch (error) {
      setError(error)
    }
  }, [cocktail?.glassType])

  useEffect(() => {
    cocktail?.glassType && getGlassType()
  }, [cocktail?.glassType, getGlassType])

  return [glassType, loading, error, getGlassType]
}

export default useGetCocktailGlassType
