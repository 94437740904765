import { Radio } from 'antd'
import styled from 'styled-components'

const RadioStyled = styled(Radio)`
  .ant-radio {
    width: 24px;
    height: 24px;
  }
  .ant-radio-inner {
    width: 100%;
    height: 100%;
  }
  .ant-radio:not(.ant-radio-checked) .ant-radio-inner {
    border: 1px solid var(--radio-border);
    box-shadow: none;
  }
  .ant-radio:hover:not(.ant-radio-checked) .ant-radio-inner,
  .ant-radio:focus:not(.ant-radio-checked) .ant-radio-inner {
    border: 1px solid var(--radio-hover-border);
    box-shadow: var(--radio-hover-shadow) !important;
  }
  .ant-radio:active:not(.ant-radio-checked) .ant-radio-inner {
    border: 1px solid var(--radio-active-border);
    box-shadow: var(--radio-active-shadow);
  }
  /* checked */
  .ant-radio-checked:not(.ant-radio-disabled) .ant-radio-inner {
    box-shadow: inset 0 0 0 4px var(--radio-checked-border);
  }
  .ant-radio-checked .ant-radio-inner::after {
    content: '';
    background: transparent;
  }
  .ant-radio:hover:not(.ant-radio-disabled) .ant-radio-inner,
  .ant-radio:focus .ant-radio-inner {
    border: 1px solid var(--radio-checked-hover-border);
    box-shadow: var(--radio-hover-shadow),
      inset 0 0 0 4px var(--radio-checked-border);
  }
  .ant-radio:active .ant-radio-checked .ant-radio-inner {
    border: 1px solid var(--radio-checked-active-border);
    box-shadow: inset 0 0 0 4px var(--radio-checked-border),
      var(--radio-active-shadow);
  }
  .ant-radio-disabled .ant-radio-inner {
    background: var(--radio-disabled-bg) !important;
    border: 1px solid var(--radio-disabled-border) !important;
    box-shadow: none !important;
  }
`

export default RadioStyled
