import { Container, Title } from '@qonsoll/react-design'
import PropTypes from 'prop-types'
import {
  useGetRestaurantZones,
  useRestaurantZoneActions
} from 'domains/Zone/hooks'
import { ZoneList } from 'domains/Zone/components'
import { useTranslations } from 'contexts/Translation'

const RestaurantZones = (props) => {
  const { restaurant, actions = {} } = props

  /* Getting relationship data */
  // eslint-disable-next-line no-unused-vars
  const [zones, loading, error, refresh] = useGetRestaurantZones(restaurant)
  /* Getting Restaurant-Zone actions */
  const { createPath, onCreate, onDelete } = useRestaurantZoneActions({
    restaurant,
    refresh
  })
  /* Getting translations instance */
  const { t } = useTranslations()

  /* Actions */
  const computedActions = {
    ...actions,
    onDelete: onDelete,
    onCreate: onCreate ?? null
  }

  return loading ? (
    <Title variant="h4">{t('Zones are loading')}...</Title>
  ) : (
    <Container>
      <ZoneList
        cw={[12, 6, 4]}
        isListWithCreate={!!createPath}
        actions={computedActions}
        zones={zones}
      />
    </Container>
  )
}

RestaurantZones.propTypes = {
  restaurant: PropTypes.object.isRequired,
  actions: PropTypes.object
}

export default RestaurantZones
