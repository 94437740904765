import { useCallback, useEffect, useRef, useState } from 'react'

import { COLLECTIONS } from '__constants__'
import { getDocument } from 'services/api/firebase'

const useGetItemTypeFieldData = (itemTypeData) => {
  const objectsWithFieldsIds = itemTypeData?.fields
  const [documents, setDocuments] = useState([])
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState(null)

  // have to use ref to avoid rerendering
  const idsObjRef = useRef(objectsWithFieldsIds)
  // This way, the useEffect that updates the idsObjRef won't depend directly on objectsWithFieldsIds and won't cause an infinite loop.
  useEffect(() => {
    idsObjRef.current = objectsWithFieldsIds
  }, [objectsWithFieldsIds])

  const getData = useCallback(async () => {
    try {
      setLoading(true)
      const fetchedDocuments = []

      if (itemTypeData && itemTypeData.fieldsOrder) {
        for (const key of itemTypeData.fieldsOrder) {
          if (itemTypeData.fields[key]) {
            const document = await getDocument(
              COLLECTIONS.ITEM_TYPES_FIELDS,
              key
            )
            fetchedDocuments.push(document)
          }
        }
      }

      setDocuments(fetchedDocuments)
      setLoading(false)
    } catch (error) {
      setError(error)
      setLoading(false)
    }
    // we need  dependency to able to update the table when the data changes
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [itemTypeData])
  useEffect(() => {
    objectsWithFieldsIds && getData()
  }, [getData, objectsWithFieldsIds])
  return [documents, loading, error, getData]
}
export default useGetItemTypeFieldData
