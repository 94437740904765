import {
  createDocument,
  getId,
  updateDocument,
  updateParent
} from 'services/api/firebase'

import { COLLECTIONS } from '__constants__'
import { TrainingMaterial } from 'models'
import { message } from 'antd'
import { useGetErrorText } from 'hooks'
import { useHistory } from 'react-router-dom'
import { useState } from 'react'
import { useTranslations } from 'contexts/Translation'

const useActionsTrainingMaterialAdvancedForm = ({ initialData, form } = {}) => {
  /* State for loading */
  const [loading, setLoading] = useState(false)
  /* Getting translations instance */
  const { t } = useTranslations()
  /* Getting history instance */
  const history = useHistory()
  /* Getting history state */
  const historyState = history.location.state
  const getErrorText = useGetErrorText()
  /* Function for preparing values for saving */
  const prepareValues = async (values = {}, additionalValues = {}) => {
    /* Getting id */
    const trainingMaterialId =
      initialData?.['trainingMaterial']?._id ||
      getId(COLLECTIONS.TRAINING_MATERIALS)

    /* Preparing trainingMaterial values */
    const preparedValues = {
      _id: trainingMaterialId,
      ...additionalValues,
      title: values?.['trainingMaterial']?.['title'] ?? null,
      description: values?.['trainingMaterial']?.['description'] ?? null,
      content: values?.['trainingMaterial']?.['content'] ?? null
    }
    return preparedValues
  }

  /* Saving form data */
  const saveForm = async (values, callback) => {
    try {
      // Prepare data to be saved
      const data = await prepareValues(values)

      if (historyState?.parent)
        await updateParent(historyState.parent, data._id)

      // Save data
      if (initialData) {
        await updateDocument(
          COLLECTIONS.TRAINING_MATERIALS,
          initialData.trainingMaterial?._id,
          data
        )
        message.success(t('TrainingMaterial successfully updated'))
      } else {
        await createDocument(COLLECTIONS.TRAINING_MATERIALS, data, data._id)
        message.success(t('TrainingMaterial successfully created'))
      }
      // Final callback
      callback?.()
    } catch (error) {
      throw new Error(getErrorText(error))
    }
  }

  /* Function for validating form */
  const validateForm = (values) => {
    try {
      // Prepare data to be validated
      const validationData = {
        title: values?.['trainingMaterial']?.['title'],
        description: values?.['trainingMaterial']?.['description'],
        content: values?.['trainingMaterial']?.['content']
      }
      TrainingMaterial.validationSchema.validateSync(validationData)
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error(error)
      throw new Error(
        t('TrainingMaterial validation error: ') + t(error.message)
      )
    }
  }

  /* On finish callback */
  const onFinish = async () => {
    if (loading) return // Avoid multiple calls

    try {
      setLoading(true)
      // Get form values
      const formValues = form.getFieldsValue()
      // Validate fields
      validateForm(formValues)
      // Final callback
      const callback = () => history.goBack()
      // Save data
      await saveForm(formValues, callback)
    } catch (error) {
      setLoading(false)
      message.error(error.message)
    }
  }

  /* On cancel callback */
  const onReset = () => {
    form.resetFields()
    history.goBack()
  }

  return { onFinish, onReset, loading, saveForm, validateForm, prepareValues }
}

export default useActionsTrainingMaterialAdvancedForm
