import { useEffect } from 'react'
import { Form } from 'antd'

const useInitializeOrderAdvancedForm = (initialData) => {
  /* Getting form instance */
  const [form] = Form.useForm()

  /* Setting initial data */
  useEffect(() => {
    if (initialData) {
      /* Getting initial values */
      const { order, status, restaurant, orderedItems } = initialData

      /* Formatting date fields */
      const formValues = {
        order: {
          table: order?.['table'] ?? null,
          total: order?.['total'] ?? null,
          comments: order?.['comments'] ?? null
        },
        status: status ?? null,
        restaurant: restaurant ?? null,
        orderedItems: orderedItems?.map((e) => e?._id).filter((e) => e) ?? []
      }

      /* Setting initial values to form instance */
      form.setFieldsValue(formValues)
    }
  }, [initialData, form])

  return { form }
}

export default useInitializeOrderAdvancedForm
