import { Col, PageWrapper, Row } from '@qonsoll/react-design'
import { HeaderBreadcrumbs, Spinner } from 'components'
import { useHistory, useParams } from 'react-router-dom'

import { MainSpiritTypeAdvancedForm } from 'domains/MainSpiritType/components'
import { useGetMainSpiritTypeInitialValues } from 'domains/MainSpiritType/hooks'
import { useTranslations } from 'contexts/Translation'

const MainSpiritTypeEditPage = (props) => {
  /* Getting translations instance */
  const { t } = useTranslations()
  /* Getting history instance */
  const history = useHistory()
  /* Getting params from router */
  const params = useParams()
  /* Getting id from params */
  const { mainSpiritTypeId } = params

  /* Fetching mainSpiritType initial values */
  const [initialValues, loading] =
    useGetMainSpiritTypeInitialValues(mainSpiritTypeId)

  /* Configuring page header */
  const headingProps = {
    title: t('Main spirit type edit'),
    titleSize: 3,
    marginBottom: 3,
    textAlign: 'left'
  }

  /* Function to handle back click */
  const handleBack = () => history.goBack()

  return (
    <PageWrapper
      onBack={handleBack}
      breadcrumbs={<HeaderBreadcrumbs />}
      headingProps={headingProps}>
      <Row noGutters>
        {loading ? (
          <Col>
            <Spinner height="70vh" text={t('Main spirit type loading')} />
          </Col>
        ) : (
          <Col cw={[12, 12, 8, 6]}>
            <MainSpiritTypeAdvancedForm initialData={initialValues} />
          </Col>
        )}
      </Row>
    </PageWrapper>
  )
}

export default MainSpiritTypeEditPage
