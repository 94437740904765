import { Col, Row, Box, Text, Divider } from '@qonsoll/react-design'
import PropTypes from 'prop-types'

import { useTranslations } from 'contexts/Translation'

import { InteractiveMenuQuestionList } from 'domains/InteractiveMenuQuestion/components'

const InteractiveMenuAdvancedView = (props) => {
  const { initialData } = props
  const { interactiveMenuQuestions, itemTypeData } = initialData

  /* Getting translations instance */
  const { t } = useTranslations()

  return (
    <Row noGutters>
      <Col cw={12} mb={2} flexDirection="row" alignItems="center">
        <Text type="secondary" mr={1} variant="body1">
          {t('Item type')}:
        </Text>
        <Text style={{ wordBreak: 'break-all' }} variant="body1">
          {itemTypeData?.name}
        </Text>
      </Col>
      <Col cw={[12, 10, 8, 6]} mb={2}>
        <Box>
          <Divider>
            <Text>{t('Questions')}</Text>
          </Divider>
        </Box>
        <InteractiveMenuQuestionList
          interactiveMenuQuestions={interactiveMenuQuestions}
        />
      </Col>
    </Row>
  )
}
InteractiveMenuAdvancedView.propTypes = {
  initialData: PropTypes.object
}
export default InteractiveMenuAdvancedView
