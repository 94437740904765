import { COLLECTIONS } from '__constants__'
import {
  createDocument,
  getId,
  updateDocument,
  deleteDocument
} from 'services/api/firebase'
import differenceIds from 'modules/admin-module/helpers/differenceIds'

const useActionsRecipeSimpleForm = (initialData) => {
  /* Function for preparing values for saving */
  const prepareRecipeValues = async (values = {}) => {
    /* Getting id */
    const recipeId = values?._id ?? getId(COLLECTIONS.RECIPES)

    /* Preparing cocktail values */
    const preparedValues = {
      _id: recipeId,
      amount: values?.amount ?? 0,
      measurement: values?.measurement ?? null,
      ingredient: values?.ingredient ?? null
    }

    return preparedValues
  }

  const getRecipes = async (recipes) => {
    const recipeData = await Promise?.allSettled(
      recipes?.map((item) => prepareRecipeValues(item))
    )

    return recipeData
      ?.map((item) => item?.value)
      ?.filter((item) => item?.ingredient)
  }

  const saveRecipes = async (values = []) => {
    try {
      /* Getting the recipes from the values. */
      const recipes = await getRecipes(values)
      /* Getting the difference between the recipes that 
      are in the database and the recipes that are in the form. */
      const [removedIds, createdIds] = differenceIds(
        recipes,
        initialData?.recipes
      )

      /* Getting recipe ids, and return them for saving in Cocktail or Dish */
      const recipesIds = recipes?.map((item) => item?._id)
      const recipeIdsArray = [...recipesIds, ...createdIds]
      const savingRecipeIds = [...new Set(recipeIdsArray)]

      /* Getting the ingredient ids from the recipes. */
      const ingredientIds = recipes?.map((item) => item?.ingredient)

      /* Deleting the recipe from the database. */
      removedIds?.forEach((id) => {
        deleteDocument(COLLECTIONS.RECIPES, id)
      })

      /* Checking if the recipe is in the createdIds array. 
        If it is, it will create a new document in the database. 
        If it is not, it will update the document in the database. */
      recipes?.forEach((recipe) => {
        if (createdIds?.includes(recipe?._id)) {
          createDocument(
            COLLECTIONS.RECIPES,
            {
              ...recipe
            },
            recipe?._id
          )
        } else {
          updateDocument(COLLECTIONS.RECIPES, recipe?._id, {
            ...recipe
          })
        }
      })

      return [savingRecipeIds, ingredientIds]
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error(error)
    }
  }

  return {
    prepareRecipeValues,
    getRecipes,
    saveRecipes
  }
}

export default useActionsRecipeSimpleForm
