import { model, attr } from 'services/model'
import * as yup from 'yup'

const validationSchema = yup.object().shape({
  paymentStatus: yup
    .string()
    .typeError('Field paymentStatus has wrong type')
    .default(null)
    .nullable(),
  totalPaymentDue: yup
    .date()
    .typeError('Field totalPaymentDue has wrong type')
    .default(null)
    .nullable(),
  scheduledPaymentDate: yup
    .date()
    .typeError('Field scheduledPaymentDate has wrong type')
    .default(null)
    .nullable(),
  minimumPaymentDue: yup
    .date()
    .typeError('Field minimumPaymentDue has wrong type')
    .default(null)
    .nullable(),
  billingPeriod: yup
    .string()
    .typeError('Field billingPeriod has wrong type')
    .default(null)
    .nullable()
})

const Invoice = model(
  'invoice',
  {
    paymentStatus: attr('string'),
    totalPaymentDue: attr('date'),
    scheduledPaymentDate: attr('date'),
    minimumPaymentDue: attr('date'),
    billingPeriod: attr('string')
  },
  validationSchema
)

export default Invoice
