import { MenuItem } from '@qonsoll/react-design'
import styled from 'styled-components'

const MenuItemStyled = styled(MenuItem)`
  &.ant-dropdown-menu-item {
    border-radius: 20px;
    background: var(--ql-bg-white-default);
    color: var(--ql-text-primary);
    padding: 8px 20px;
    display: flex;
    align-items: center;
  }
  /* default */
  /* hover */
  &.ant-dropdown-menu-item:hover:not(.ant-dropdown-menu-item-danger) {
    background: var(--ql-bg-accent1-lighten6);
    color: var(--ql-text-accent1);
  }
  &.ant-dropdown-menu-item:hover:not(.ant-dropdown-menu-item-danger)
    > div
    > svg
    > path {
    stroke: var(--ql-text-accent1);
  }
  /* active */
  &.ant-dropdown-menu-item:active:not(.ant-dropdown-menu-item-danger) {
    background: var(--ql-bg-dark-lighten7);
    color: var(--ql-text-secondary);
  }
  &.ant-dropdown-menu-item:active:not(.ant-dropdown-menu-item-danger)
    > div
    > svg
    > path {
    stroke: var(--ql-text-secondary);
  }
  /* focus */
  &.ant-dropdown-menu-item:focus:not(.ant-dropdown-menu-item-danger) {
    background: var(--ql-bg-accent1-default);
    color: var(--ql-text-white);
  }
  &.ant-dropdown-menu-item:focus:not(.ant-dropdown-menu-item-danger)
    > div
    > svg
    > path {
    stroke: var(--ql-text-white);
  }
  /* danger */
  /* hover */
  &.ant-dropdown-menu-item:hover {
    background: var(--ql-bg-danger-lighten6);
    color: var(--ql-text-danger);
  }
  &.ant-dropdown-menu-item:hover > div > svg > path {
    stroke: var(--ql-text-danger);
  }
  /* active */
  &.ant-dropdown-menu-item:active {
    background: var(--ql-bg-danger-lighten6);
    color: var(--ql-text-secondary);
  }
  &.ant-dropdown-menu-item:active > div > svg > path {
    stroke: var(--ql-text-secondary);
  }
  /* focus */
  &.ant-dropdown-menu-item:focus {
    background: var(--ql-bg-danger-default);
    color: var(--ql-text-white);
  }
  &.ant-dropdown-menu-item:focus > div > svg > path {
    stroke: var(--ql-text-white);
  }
`

export default MenuItemStyled
