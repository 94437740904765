import { useTranslations } from 'contexts/Translation'

const useOnSaveErrorWithCode = () => {
  const { t } = useTranslations()
  const isDev = process.env.REACT_APP_ENVIRONMENT === 'DEVELOPMENT'

  const onSaveErrorWithCode = (error) => {
    // eslint-disable-next-line no-console
    isDev && console.error(error)
    let errorText = `${t(`Something went wrong during data save`)}. `
    if (error?.message) errorText += `${t('Error message')}: ${error.message}`
    else if (error?.code) errorText += `${t('Error code')}: ${error?.code}`
    return errorText
  }

  return onSaveErrorWithCode
}

export default useOnSaveErrorWithCode
