import { Box, Col, Row, Text } from '@qonsoll/react-design'
import {
  CocktailActionsColumn,
  CocktailAvailabilityColumn,
  CocktailIngredientsColumn,
  CocktailMethodColumn,
  CocktailPriceColumn,
  CoctailFlavorColumn,
  CoctailSpiritTypeColumn
} from 'domains/Cocktail/components/CocktailsReviewTable/components'
import {
  ItemWrapper,
  ScrollableCol,
  ScrollableRow,
  staticColStyle
} from 'components/Table/Table.styled'

import PropTypes from 'prop-types'
import { ScrollSyncPane } from 'react-scroll-sync'
import { useDefineIfScrollable } from 'components/Table/hooks'
import { useRef } from 'react'

const CocktailItem = (props) => {
  const { cocktail, tableProps, actions, menuId, index } = props
  const {
    scrollableColumnWidth,
    scrollableContainerMinWidth,
    fixedColCw,
    scrollableColCw
  } = tableProps

  // whether col is scrollable and should have a shadow or not
  const ref = useRef()
  const isScrollable = useDefineIfScrollable(ref, scrollableContainerMinWidth)
  const colShadow = isScrollable ? 'var(--table-static-col-shadow)' : 'none'

  return (
    <ItemWrapper>
      <Row noGutters v="center" overflow="hidden">
        <Col
          v="center"
          cw={fixedColCw}
          {...staticColStyle}
          alignSelf="stretch"
          alignItems="center"
          flexDirection="row"
          boxShadow={colShadow}>
          <Box
            display="flex"
            flexDirection="column"
            width="100%"
            justifyContent="center">
            <Text level={5}>{cocktail?.name}</Text>
            {cocktail?.description && (
              <Text variant="body2" wordBreak="break-word">
                {cocktail?.description}
              </Text>
            )}
          </Box>
        </Col>
        <ScrollSyncPane group="horizontal">
          <ScrollableCol cw={scrollableColCw} padding={3}>
            <ScrollableRow
              ref={ref}
              minWidth={scrollableContainerMinWidth}
              v="center"
              height="100%">
              <Col cw={scrollableColumnWidth[0]} v="center">
                <CocktailPriceColumn
                  index={index}
                  cocktailId={cocktail?.cocktailId}
                  price={cocktail?.price}
                  onSelect={actions?.onPriceChange}
                />
              </Col>
              <Col cw={scrollableColumnWidth[1]} v="center">
                <CoctailSpiritTypeColumn spirits={cocktail?.mainSpirit} />
              </Col>
              <Col cw={scrollableColumnWidth[2]} v="center">
                <CoctailFlavorColumn flavors={cocktail?.flavor} />
              </Col>
              <Col cw={scrollableColumnWidth[3]} v="center">
                <CocktailMethodColumn methods={cocktail?.methods} />
              </Col>
              <Col cw={scrollableColumnWidth[4]} v="center">
                <CocktailIngredientsColumn cocktail={cocktail} />
              </Col>
              <Col cw={scrollableColumnWidth[5]} v="center">
                <CocktailAvailabilityColumn
                  cocktailId={cocktail?.cocktailId}
                  onSelect={actions?.onAvailabilityChange}
                  availability={cocktail?.availability}
                />
              </Col>
              <Col cw={scrollableColumnWidth[6]} h="right" v="center">
                <CocktailActionsColumn
                  cocktail={cocktail}
                  menuId={menuId}
                  cocktailsAvailabilityAndPrice={
                    actions?.cocktailsAvailabilityAndPrice
                  }
                />
              </Col>
            </ScrollableRow>
          </ScrollableCol>
        </ScrollSyncPane>
      </Row>
    </ItemWrapper>
  )
}

CocktailItem.propTypes = {
  cocktail: PropTypes.object,
  tableProps: PropTypes.object,
  actions: PropTypes.object,
  menuId: PropTypes.string,
  index: PropTypes.number
}

export default CocktailItem
