import {
  createDocument,
  getId,
  updateDocument,
  updateParent
} from 'services/api/firebase'
import { useHistory, useParams } from 'react-router-dom'

import { COLLECTIONS } from '__constants__'
import { MediaObject } from 'models'
import { message } from 'antd'
import { useGetErrorText } from 'hooks'
import { useState } from 'react'
import { useTranslations } from 'contexts/Translation'

const useActionsMediaObjectAdvancedForm = ({ initialData, form } = {}) => {
  /* State for loading */
  const [loading, setLoading] = useState(false)
  /* Getting translations instance */
  const { t } = useTranslations()
  /* Getting history instance */
  const history = useHistory()
  /* Getting params instance */
  const params = useParams()
  /* Getting history state */
  const historyState = history.location.state
  const getErrorText = useGetErrorText()
  /* Function for preparing values for saving */
  const prepareValues = async (values = {}, additionalValues = {}) => {
    /* Getting id */
    const mediaObjectId =
      initialData?.['mediaObject']?._id || getId(COLLECTIONS.MEDIA_OBJECTS)

    /* Preparing mediaObject values */
    const preparedValues = {
      _id: mediaObjectId,
      ...additionalValues,
      url: values?.['mediaObject']?.['url'] ?? null,
      size: values?.['mediaObject']?.['size'] ?? null,
      description: values?.['mediaObject']?.['description'] ?? null,
      format: values?.['mediaObject']?.['format'] ?? null
    }
    if (params.restaurantId)
      preparedValues['restaurantId'] = params.restaurantId
    return preparedValues
  }

  /* Saving form data */
  const saveForm = async (values, callback) => {
    try {
      // Prepare data to be saved
      const data = await prepareValues(values)

      // Updating parent references
      // if(params.restaurantId) await updateParentHasOneRelationship (params.restaurantId, COLLECTIONS.RESTAURANTS, 'mediaObjects', data._id)
      if (historyState?.parent)
        await updateParent(historyState.parent, data._id)

      // Save data
      if (initialData) {
        await updateDocument(
          COLLECTIONS.MEDIA_OBJECTS,
          initialData.mediaObject?._id,
          data
        )
        message.success(t('MediaObject successfully updated'))
      } else {
        await createDocument(COLLECTIONS.MEDIA_OBJECTS, data, data._id)
        message.success(t('MediaObject successfully created'))
      }
      // Final callback
      callback?.()
    } catch (error) {
      throw new Error(getErrorText(error))
    }
  }

  /* Function for validating form */
  const validateForm = (values) => {
    try {
      // Prepare data to be validated
      const validationData = {
        url: values?.['mediaObject']?.['url'],
        size: values?.['mediaObject']?.['size'],
        description: values?.['mediaObject']?.['description'],
        format: values?.['mediaObject']?.['format']
      }
      MediaObject.validationSchema.validateSync(validationData)
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error(error)
      throw new Error(t('MediaObject validation error: ') + t(error.message))
    }
  }

  /* On finish callback */
  const onFinish = async () => {
    if (loading) return // Avoid multiple calls

    try {
      setLoading(true)
      // Get form values
      const formValues = form.getFieldsValue()
      // Validate fields
      validateForm(formValues)
      // Final callback
      const callback = () => history.goBack()
      // Save data
      await saveForm(formValues, callback)
    } catch (error) {
      setLoading(false)
      message.error(error.message)
    }
  }

  /* On cancel callback */
  const onReset = () => {
    form.resetFields()
    history.goBack()
  }

  return { onFinish, onReset, loading, saveForm, validateForm, prepareValues }
}

export default useActionsMediaObjectAdvancedForm
