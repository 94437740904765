import { COLLECTIONS } from '__constants__'
import { DragableList } from 'components'
import { MenuListItem } from 'domains/Menu/components'
import { doc } from 'firebase/firestore'
import PropTypes from 'prop-types'
import { useMemo } from 'react'
import { memo } from 'react'
import { useDocumentData } from 'react-firebase-hooks/firestore'
import { useListenDocuments } from 'services/api/firebase/hooks'
import { firestore } from 'services/firebase'

const MenuDragableList = (props) => {
  const {
    onUpdate,
    onChange,
    deleteCocktail,
    editCocktail,
    isCreate,
    tableProps,
    onPriceChange,
    onCategoryEdit,
    categoryVisibility,
    changeVisibility,
    onCategoryDelete,
    onEditCocktailAvailability,
    isDragAndDropDisabled,
    categoryId
  } = props

  const [categoryData] = useDocumentData(
    categoryId && doc(firestore, COLLECTIONS.MENU_CATEGORIES, categoryId)
  )

  const categoryItemsProps = useMemo(
    () => [categoryData?.itemsOrder || [], COLLECTIONS.MENU_ITEMS],
    [categoryData?.itemsOrder]
  )

  const [menuItems] = useListenDocuments(...categoryItemsProps)

  const tableListWrapperStyles = {
    height: 'calc(100vh - var(--drinks-page-height-to-negotiate))',
    userSelect: 'none'
  }

  return (
    <DragableList
      tableListWrapperStyles={tableListWrapperStyles}
      onCategoryDelete={onCategoryDelete}
      onCategoryEdit={onCategoryEdit}
      changeVisibility={changeVisibility}
      categoryVisibility={categoryVisibility}
      onUpdate={onUpdate}
      listItems={menuItems}
      categoryId={categoryId}
      disabled={isDragAndDropDisabled}
      renderItem={(othersItem, index, draggableProps) => (
        <MenuListItem
          categoryId={categoryId}
          onEditCocktailAvailability={onEditCocktailAvailability}
          onPriceChange={onPriceChange}
          tableProps={tableProps}
          isCreate={isCreate}
          deleteCocktail={deleteCocktail}
          editCocktail={editCocktail}
          index={index}
          onChange={onChange}
          key={othersItem?._id}
          draggableProps={draggableProps}
          {...othersItem}
        />
      )}
    />
  )
}

MenuDragableList.propTypes = {
  cocktails: PropTypes.array,
  onUpdate: PropTypes.func,
  onChange: PropTypes.func,
  deleteCocktail: PropTypes.func,
  editCocktail: PropTypes.func,
  isCreate: PropTypes.bool,
  tableProps: PropTypes.object,
  onPriceChange: PropTypes.func,
  changeVisibility: PropTypes.func,
  categoryVisibility: PropTypes.bool,
  onCategoryEdit: PropTypes.func,
  onCategoryDelete: PropTypes.func,
  onEditCocktailAvailability: PropTypes.func,
  isDragAndDropDisabled: PropTypes.bool,
  categoryId: PropTypes.string
}

export default memo(MenuDragableList)
