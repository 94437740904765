import { Redirect, Route, Switch } from 'react-router-dom'

import { BoilerplateLayout } from 'components'
import { CustomerProvider } from 'contexts/CustomerContext'
import PATHS from '../paths'
import { UserProvider } from 'contexts/UserContext'
import routes from './routes'

const App = () => {
  return (
    <UserProvider>
      <CustomerProvider>
        <BoilerplateLayout>
          <Switch>
            {routes.map((routeProps) => (
              <Route key={routeProps.key} {...routeProps} />
            ))}
            <Redirect to={PATHS.SERVICE.NOT_FOUND} />
          </Switch>
        </BoilerplateLayout>
      </CustomerProvider>
    </UserProvider>
  )
}

export default App
