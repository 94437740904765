import { COLLECTIONS } from '__constants__'

const getRelationshipCollectionName = (columnData) => {
  const { list, object, enumValue } = columnData || {}

  return list
    ? [COLLECTIONS.LIST_ITEMS, list, COLLECTIONS.LISTS, 'listItems']
    : object
    ? [COLLECTIONS.OBJECT_INSTANCES, object]
    : enumValue
    ? [COLLECTIONS.ENUM_INSTANCES, enumValue, COLLECTIONS.ENUMS, 'enumItems']
    : []
}

export default getRelationshipCollectionName
