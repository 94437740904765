import { useMemo } from 'react'

const useRecipeBaseActions = (value, onChange) => {
  const addNewFieldToRecipe = () => {
    try {
      if (value) {
        onChange([...value, {}])
      } else {
        onChange([{}])
      }
    } catch (err) {
      // eslint-disable-next-line no-console
      console.error(err)
    }
  }
  const removeFieldOfRecipe = (position) => {
    try {
      const updatedValue = value?.filter((_, index) => index !== position)
      onChange(updatedValue)
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error(error)
    }
  }
  const onChangeState = (newValue, index, field) => {
    try {
      const newRecipe = [...value]
      newRecipe[index][field] = newValue
      onChange(newRecipe)
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error(error)
    }
  }

  const isExistRecipe = useMemo(() => !!value?.length, [value])

  return {
    addNewFieldToRecipe,
    removeFieldOfRecipe,
    onChangeState,
    isExistRecipe
  }
}

export default useRecipeBaseActions
