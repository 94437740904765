import { Table } from 'antd'
import styled from 'styled-components'

const TableStyled = styled(Table)`
  & .ant-table-content::-webkit-scrollbar {
    height: 0.5rem;
    background: transparent;
  }
  & .ant-table-content::-webkit-scrollbar-thumb {
    height: 0.5rem;
    background: var(--scroll-thumb-color);
  }
  & .ant-table-content::-webkit-scrollbar-track {
    height: 0.5rem;
    background: transparent;
  }
`

export default TableStyled
