import { Box, Title } from '@qonsoll/react-design'
import { ButtonAccent, ButtonWithPopconfirm } from 'components'
import { COLLECTIONS, KEYS } from '__constants__'
import {
  useActionsProfileAdvancedForm,
  useInitializeProfileAdvancedForm
} from './hooks'

import { CustomerSimpleForm } from 'domains/Customer/components'
import { Form } from 'antd'
import PropTypes from 'prop-types'
import { UserSimpleForm } from 'domains/User/components'
import { doc } from 'firebase/firestore'
import { firestore } from 'services/firebase'
import { useDocumentDataOnce } from 'react-firebase-hooks/firestore'
import { useState } from 'react'
import { useTranslations } from 'contexts/Translation'

const ProfileAdvancedForm = (props) => {
  const { showTitle, initialData } = props

  const [companyAddress] = useDocumentDataOnce(
    initialData?.customer?.address &&
      doc(firestore, COLLECTIONS.ADDRESSES, initialData?.customer?.address)
  )

  const [isFormChanged, setIsFormChanged] = useState(false)
  /* Getting translations instance */
  const { t } = useTranslations()
  /* Initializing form instance with initial data */
  const { form, mainLanguage, setMainLanguage, mainCurrency, setMainCurrency } =
    useInitializeProfileAdvancedForm(initialData, companyAddress)
  /* Getting loading state and actions from hook */
  const { loading, onFinish, onReset } = useActionsProfileAdvancedForm({
    initialData,
    form,
    mainLanguage,
    mainCurrency,
    companyAddress
  })

  /* Handling enter key press */
  const handleKeyPress = (e) => {
    if (e.key === KEYS.ENTER) {
      e.preventDefault()
      //   onFinish()
    }
  }
  const onFormValuesChange = () => setIsFormChanged(true)
  return (
    <Form
      scrollToFirstError
      onValuesChange={onFormValuesChange}
      onKeyPress={handleKeyPress}
      onFinish={onFinish}
      layout="vertical"
      autoComplete="off"
      form={form}>
      {showTitle && (
        <Title level={4} mb={2}>
          {t('Profile edit')}
        </Title>
      )}
      <UserSimpleForm group={['user']} />
      <CustomerSimpleForm
        group={['customer']}
        mainLanguage={mainLanguage}
        setMainLanguage={setMainLanguage}
        mainCurrency={mainCurrency}
        setMainCurrency={setMainCurrency}
        description={companyAddress?.description}
      />
      <Box display="flex">
        <ButtonWithPopconfirm
          onReset={onReset}
          withPopconfirm={isFormChanged}
        />
        <ButtonAccent
          type="primary"
          loading={loading}
          onClick={() => form.submit()}>
          {t('Save')}
        </ButtonAccent>
      </Box>
    </Form>
  )
}

ProfileAdvancedForm.propTypes = {
  initialData: PropTypes.object,
  group: PropTypes.array,
  showTitle: PropTypes.bool
}

export default ProfileAdvancedForm
