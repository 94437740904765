import {
  createDocument,
  getId,
  updateDocument,
  updateParent
} from 'services/api/firebase'
import { useHistory, useParams } from 'react-router-dom'

import { COLLECTIONS } from '__constants__'
import { MenuType } from 'models'
import { message } from 'antd'
import { useGetErrorText } from 'hooks'
import { useState } from 'react'
import { useTranslations } from 'contexts/Translation'

const useActionsMenuTypeAdvancedForm = ({ initialData, form } = {}) => {
  /* State for loading */
  const [loading, setLoading] = useState(false)
  /* Getting translations instance */
  const { t } = useTranslations()
  /* Getting history instance */
  const history = useHistory()
  /* Getting params instance */
  const params = useParams()
  /* Getting history state */
  const historyState = history.location.state
  const getErrorText = useGetErrorText()
  /* Function for preparing values for saving */
  const prepareValues = async (values = {}, additionalValues = {}) => {
    /* Getting id */
    const menuTypeId =
      initialData?.['menuType']?._id || getId(COLLECTIONS.MENU_TYPES)

    /* Preparing menuType values */
    const preparedValues = {
      _id: menuTypeId,
      ...additionalValues,
      name: values?.['menuType']?.['name'] ?? null,
      value: values?.['menuType']?.['value'] ?? null
    }
    if (params.menuId) preparedValues['menuId'] = params.menuId
    return preparedValues
  }

  /* Saving form data */
  const saveForm = async (values, callback) => {
    try {
      // Prepare data to be saved
      const data = await prepareValues(values)

      // Updating parent references
      // if(params.menuId) await updateParentHasOneRelationship (params.menuId, COLLECTIONS.MENUS, 'menuType', data._id)
      if (historyState?.parent)
        await updateParent(historyState.parent, data._id)

      // Save data
      if (initialData) {
        await updateDocument(
          COLLECTIONS.MENU_TYPES,
          initialData.menuType?._id,
          data
        )
        message.success(t('MenuType successfully updated'))
      } else {
        await createDocument(COLLECTIONS.MENU_TYPES, data, data._id)
        message.success(t('MenuType successfully created'))
      }
      // Final callback
      callback?.()
    } catch (error) {
      throw new Error(getErrorText(error))
    }
  }

  /* Function for validating form */
  const validateForm = (values) => {
    try {
      // Prepare data to be validated
      const validationData = {
        name: values?.['menuType']?.['name'],
        value: values?.['menuType']?.['value']
      }
      MenuType.validationSchema.validateSync(validationData)
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error(error)
      throw new Error(t('MenuType validation error: ') + t(error.message))
    }
  }

  /* On finish callback */
  const onFinish = async () => {
    if (loading) return // Avoid multiple calls

    try {
      setLoading(true)
      // Get form values
      const formValues = form.getFieldsValue()
      // Validate fields
      validateForm(formValues)
      // Final callback
      const callback = () => history.goBack()
      // Save data
      await saveForm(formValues, callback)
    } catch (error) {
      setLoading(false)
      message.error(error.message)
    }
  }

  /* On cancel callback */
  const onReset = () => {
    form.resetFields()
    history.goBack()
  }

  return { onFinish, onReset, loading, saveForm, validateForm, prepareValues }
}

export default useActionsMenuTypeAdvancedForm
