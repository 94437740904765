import { Col, Container, Input, Row, Title } from '@qonsoll/react-design'

import PropTypes from 'prop-types'
import { VARIANT_TYPES } from '__constants__'
import { useTranslations } from 'contexts/Translation'

const NameStep = (props) => {
  const { value, setValue, stepNumber } = props

  /* Getting translations instance */
  const { t } = useTranslations()

  const descriptionName = VARIANT_TYPES[value?.variantType].toLowerCase()

  /* Handling changes */
  const handleChange = (event) =>
    setValue((prev) => ({ ...prev, name: event.target.value }))

  const titleTextComputed = `${t('Step')} ${stepNumber}. ${t(
    `${VARIANT_TYPES[value?.variantType]} name`
  )}`

  return (
    <Container mb="24px">
      <Title textAlign="center" variant="h4" mb="24px">
        {titleTextComputed}
      </Title>
      <Row noGutters h="center">
        <Col cw={12}>
          <Input
            value={value?.name}
            onChange={handleChange}
            placeholder={t(`Please enter your ${descriptionName} name`)}
          />
        </Col>
      </Row>
    </Container>
  )
}

NameStep.propTypes = {
  value: PropTypes.object,
  setValue: PropTypes.func,
  stepNumber: PropTypes.number
}

export default NameStep
