import { Col, Container, Row, Title } from '@qonsoll/react-design'
import { VARIANT_TYPES } from '__constants__'

import { PlacesAutocomplete } from 'domains/Location/components'
import PropTypes from 'prop-types'
import { Spinner } from 'components'
import { useTranslations } from 'contexts/Translation'

const AddressStep = (props) => {
  const {
    value,
    setValue,
    stepNumber,
    listCountries,
    isLoaded,
    getAddressInfo
  } = props
  /* Getting translations instance */
  const { t } = useTranslations()

  const descriptionName = VARIANT_TYPES[value?.variantType].toLowerCase()
  /* Handling changes */
  const handleLocationChange = async (value) => {
    const addressInfo = await getAddressInfo(value?.locationId)
    const { country } = addressInfo
    const selectedCountry = listCountries?.find(
      (item) => item?.internationalName === country
    )
    /* Setting the value of the country, currency and main language. */
    setValue((prev) => ({
      ...prev,
      country,
      currency: selectedCountry?.currency
        ? selectedCountry?.currency[0]?.code
        : 'USD',
      mainLanguage: selectedCountry?.languages
        ? selectedCountry?.languages[0]?.name
        : 'English',
      addressInfo: {
        ...addressInfo,
        locationId: value?.locationId,
        description: value?.description
      }
    }))
  }
  const titleTextComputed = `${t('Step')} ${stepNumber}. ${t(
    `${VARIANT_TYPES[value?.variantType]} address`
  )}`
  return (
    <Container mb="24px">
      <Title textAlign="center" variant="h4" mb="24px">
        {titleTextComputed}
      </Title>
      <Row noGutters h="center">
        <Col cw={12}>
          {!isLoaded ? (
            <Spinner />
          ) : (
            <PlacesAutocomplete
              placeholder={t(`Please enter ${descriptionName} address`)}
              onChange={handleLocationChange}
              value={value?.address ?? null}
            />
          )}
        </Col>
      </Row>
    </Container>
  )
}

AddressStep.propTypes = {
  value: PropTypes.object,
  setValue: PropTypes.func,
  stepNumber: PropTypes.number,
  listCountries: PropTypes.array,
  isLoaded: PropTypes.bool,
  getAddressInfo: PropTypes.func
}

export default AddressStep
