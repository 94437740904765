import { Col, Row } from '@qonsoll/react-design'

import { ObjectInstanceSimpleView } from 'domains/ObjectInstance/components'
import PropTypes from 'prop-types'

const ObjectInstanceList = (props) => {
  const {
    objectInstancesData,
    selectedLanguage,
    showForm,
    chooseObject,
    updateItemTypeInstanceWithObjectValue,
    objectFieldName
  } = props
  return (
    <Row negativeBlockMargin>
      {objectInstancesData?.map((item, index) => (
        <Col cw={6} key={`${index}-object-instance-tem`} my={2}>
          <ObjectInstanceSimpleView
            chooseObject={chooseObject}
            objectInstanceData={item}
            editMode
            onEdit={showForm}
            selectedLanguage={selectedLanguage}
            updateItemTypeInstanceWithObjectValue={
              updateItemTypeInstanceWithObjectValue
            }
            objectFieldName={objectFieldName}
          />
        </Col>
      ))}
    </Row>
  )
}

ObjectInstanceList.propTypes = {
  objectInstancesData: PropTypes.array,
  selectedLanguage: PropTypes.string,
  objectFieldName: PropTypes.string,
  showForm: PropTypes.func,
  chooseObject: PropTypes.func,
  updateItemTypeInstanceWithObjectValue: PropTypes.func
}

export default ObjectInstanceList
