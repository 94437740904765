import { Box, Col, Row, Text, Title } from '@qonsoll/react-design'
import PropTypes from 'prop-types'
import { useTranslations } from 'contexts/Translation'

const UserView = (props) => {
  const { user } = props

  /* Getting translations instance */
  const { t } = useTranslations()

  return (
    <Box cursor="pointer">
      {user?.firstName && (
        <Row
          noGutters
          justifyContent="center"
          alignItems="center"
          py="4px"
          mb={2}>
          <Col cw={12}>
            <Title
              level={5}
              overflow="hidden"
              textOverflow="ellipsis"
              whiteSpace="nowrap">
              {user?.firstName}
            </Title>
          </Col>
        </Row>
      )}
      {user?.lastName && (
        <Row
          noGutters
          justifyContent="center"
          alignItems="center"
          py="4px"
          mb={2}>
          <Col cw={12}>
            <Title
              level={5}
              overflow="hidden"
              textOverflow="ellipsis"
              whiteSpace="nowrap">
              {user?.lastName}
            </Title>
          </Col>
        </Row>
      )}
      {user?.phone && (
        <Row
          noGutters
          justifyContent="center"
          alignItems="center"
          py="4px"
          mb={2}>
          <Col>
            <Text>{t('Phone')}</Text>
          </Col>
          <Col cw="auto">
            <Text overflow="hidden" textOverflow="ellipsis" whiteSpace="nowrap">
              {user?.phone}
            </Text>
          </Col>
        </Row>
      )}
      {user?.email && (
        <Row noGutters justifyContent="center" alignItems="center" py="4px">
          <Col>
            <Text>{t('Email')}</Text>
          </Col>
          <Col cw="auto">
            <Text overflow="hidden" textOverflow="ellipsis" whiteSpace="nowrap">
              {user?.email}
            </Text>
          </Col>
        </Row>
      )}
    </Box>
  )
}

UserView.propTypes = {
  user: PropTypes.object
}

export default UserView
