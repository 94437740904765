import * as yup from 'yup'

import { attr, hasMany, model } from 'services/model'

const validationSchema = yup.object().shape({
  name: yup
    .string()
    .typeError('Field name has wrong type')
    .required('Field name is required'),
  logoUrl: yup
    .string()
    .typeError('Field logoUrl has wrong type')
    .default(null)
    .nullable(),
  restaurants: yup
    .array()
    .typeError('Field restaurants has wrong type')
    .default(null)
    .nullable(),
  contactPerson: yup
    .string()
    .typeError('Field contact person name wrong type')
    .required('Field contact person name is required')
    .nullable(),
  phoneNumber: yup
    .string()
    .typeError('Field contact phone number wrong type')
    .required('Field contact phone number is required')
    .nullable(),
  typeOfLicense: yup
    .string()
    .typeError('Field type of license wrong type')
    .nullable(),
  licenseNumber: yup
    .string()
    .typeError('Field license number wrong type')
    .nullable(),
  dateOfLicenseExpiration: yup
    .string()
    .typeError('Field date of license expiration wrong type')
    .nullable(),
  mainLanguage: yup
    .string()
    .typeError('Field main language has wrong type')
    .nullable(),
  additionalLanguage: yup
    .string()
    .typeError('Field additional language has wrong type')
    .nullable(),
  currency: yup.string().typeError('Field currency has wrong type').nullable(),
  billingType: yup
    .string()
    .typeError('Field billing type has wrong type')
    .nullable()
})

const Customer = model(
  'customer',
  {
    name: attr('string'),
    logoUrl: attr('string'),
    restaurants: hasMany('restaurant'),
    licenseNumber: attr('string'),
    typeOfLicense: attr('string'),
    dateOfLicenseExpiration: attr('string'),
    mainLanguage: attr('string'),
    additionalLanguage: attr('string'),
    currency: attr('string'),
    billingType: attr('string')
  },
  validationSchema
)

export default Customer
