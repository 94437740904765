import { generatePath, useHistory, useParams } from 'react-router-dom'
import { message, notification } from 'antd'
import { useMemo, useState, useCallback } from 'react'

import PATHS from 'pages/paths'
import {
  deleteDocument,
  getDocument,
  updateDocument
} from 'services/api/firebase'
import { useTranslations } from 'contexts/Translation'
import { COLLECTIONS } from '__constants__'
import { useEvent } from 'hooks'
import { transformArrayToObject } from 'helpers'

/* Path's */
const createPath = PATHS.AUTHENTICATED.ITEM_TYPE_INSTANCE_CREATE
const showPath = PATHS.AUTHENTICATED.ITEM_TYPE_INSTANCE_SHOW

const useMenuItemBaseActions = ({
  menuItemId,
  menuItem,
  actions,
  menuWithSelectedMenuItem
} = {}) => {
  const [loading, setLoading] = useState(false)
  /* Getting translations instance */
  const { t, language } = useTranslations()
  /* Getting history instance */
  const history = useHistory()
  const { itemTypeId } = useParams()

  /* Function to handle back click */
  const handleBack = useEvent(() => history.goBack())

  /* Handling opening of the show page */
  const handleOpen = useEvent(() => {
    const pathParams = { itemTypeId, menuItemId }
    const path = generatePath(showPath, pathParams)
    history.push(path)
  })

  /* Handling opening of the create page */
  const handleCreate = useEvent(() => {
    const pathParams = { itemTypeId }
    const path = generatePath(createPath, pathParams)
    history.push(path)
  })

  const handleDelete = useCallback(async () => {
    try {
      setLoading(true)

      if (menuWithSelectedMenuItem?.length) {
        const categoryMenusPromises = menuWithSelectedMenuItem?.map(
          ({ menuId }) => getDocument(COLLECTIONS.MENUS, menuId)
        )

        const categoryMenus = await Promise.all(categoryMenusPromises)

        const mergedData = menuWithSelectedMenuItem?.map((menuCategory) => {
          const menu = categoryMenus?.find(
            ({ _id }) => _id === menuCategory?.menuId
          )

          return { ...menuCategory, menu }
        })

        notification.error({
          message: t('Cannot delete the item menu'),
          description: `${t('This menu item is used in')}: ${mergedData?.reduce(
            (acc, item) => {
              const menuName =
                item?.menu?.name?.[language] || item?.menu?.name?.en

              const categoryName = item?.name?.[language] || item?.name?.en

              return acc + `${menuName} menu, ${categoryName} category`
            },
            ''
          )}`
        })
      } else {
        const { itemTypeId } = menuItem || {}
        const itemType = await getDocument(COLLECTIONS.ITEM_TYPES, itemTypeId)

        const { menuItems } = itemType || {}

        const updatedMenuItems = transformArrayToObject(
          Object.keys(menuItems || {}).filter((id) => id !== menuItemId)
        )

        await updateDocument(COLLECTIONS.ITEM_TYPES, itemTypeId, {
          menuItems: updatedMenuItems
        })
        await deleteDocument(COLLECTIONS.MENU_ITEMS, menuItemId)

        message.success(t('List item successfully deleted'))

        history.goBack()
      }
    } catch (error) {
      message.error(error.message)
    } finally {
      setLoading(false)
    }
  }, [history, language, menuItem, menuItemId, menuWithSelectedMenuItem, t])

  return useMemo(
    () => ({
      onBack: handleBack,
      onCreate: handleCreate,
      // onEdit: handleEdit,
      onDelete: handleDelete,
      onOpen: handleOpen,
      ...(actions || {}),
      createPath,
      // editPath,
      showPath,
      loading
    }),
    [actions, handleBack, handleCreate, handleDelete, handleOpen, loading]
  )
}

export default useMenuItemBaseActions
