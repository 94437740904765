import { collection, query, orderBy } from 'firebase/firestore'
import { useCollectionData } from 'react-firebase-hooks/firestore'
import { firestore } from 'services/firebase'
import { COLLECTIONS } from '__constants__'

const useGetLists = (props) => {
  const queryData = [
    collection(firestore, COLLECTIONS.LISTS),
    ...(props?.orderBy?.length ? [orderBy(...props.orderBy)] : [])
  ]
  return useCollectionData(query(...queryData))
}

export default useGetLists
