import { Col, Container, Row } from '@qonsoll/react-design'

import { ButtonAccent } from 'components'
import PropTypes from 'prop-types'
import { useCustomerWizard } from 'domains/Customer/hooks'
import { useTranslations } from 'contexts/Translation'

const CustomerWizard = (props) => {
  const { onFinish } = props

  const {
    currentStepComponent,
    isBackButtonVisible,
    onPrevStep,
    currentStepValue,
    onNextButtonClick,
    nextButtonText,
    loading
  } = useCustomerWizard(onFinish)

  /* Getting translations instance */
  const { t } = useTranslations()

  return (
    <Container
      display="flex"
      height="70vh"
      flexDirection="column"
      justifyContent="center">
      <Row noGutters>
        <Col cw={12}>{currentStepComponent}</Col>
      </Row>
      <Row noOuterGutters h="between">
        <Col cw="auto" h="left">
          {isBackButtonVisible && (
            <ButtonAccent onClick={onPrevStep} type="secondary">
              {t('Previous step')}
            </ButtonAccent>
          )}
        </Col>
        <Col cw="auto" h="right">
          <ButtonAccent
            type="primary"
            loading={loading}
            disabled={!currentStepValue}
            onClick={onNextButtonClick}>
            {nextButtonText}
          </ButtonAccent>
        </Col>
      </Row>
    </Container>
  )
}

CustomerWizard.propTypes = {
  onFinish: PropTypes.func
}

export default CustomerWizard
