import {
  AddressFilter,
  MediaObjectFilter,
  MenuFilter,
  OrderFilter,
  QRCodeFilter
} from './components'
import { Box, Title } from '@qonsoll/react-design'
import {
  StyledFilter,
  buttonProps,
  filterBackground,
  filterMaxHeight,
  iconStyles
} from './RestaurantFilter.styles'

import { ButtonAccent } from 'components'
import { CloseOutlined } from '@ant-design/icons'
import PropTypes from 'prop-types'
import { useTranslations } from 'contexts/Translation'

const RestaurantFilter = (props) => {
  const { filterVisibility, setFilterVisibility, filterData, setFilterData } =
    props

  /* Getting translations instance */
  const { t } = useTranslations()

  /* Closing filter */
  const handleClose = () => setFilterVisibility(false)

  return filterVisibility ? (
    <>
      <StyledFilter maxHeight={filterMaxHeight} background={filterBackground}>
        <Box display="flex" justifyContent="space-between" mt={3}>
          <Title mb={2} level={4}>
            {t('Filter')}
          </Title>
          <ButtonAccent
            {...buttonProps}
            onClick={handleClose}
            icon={<CloseOutlined style={iconStyles} />}
          />
        </Box>
        <AddressFilter filterData={filterData} setFilterData={setFilterData} />
        <MediaObjectFilter
          filterData={filterData}
          setFilterData={setFilterData}
        />
        <MenuFilter filterData={filterData} setFilterData={setFilterData} />
        <OrderFilter filterData={filterData} setFilterData={setFilterData} />
        <QRCodeFilter filterData={filterData} setFilterData={setFilterData} />
      </StyledFilter>
    </>
  ) : null
}

RestaurantFilter.propTypes = {
  filterVisibility: PropTypes.bool,
  setFilterVisibility: PropTypes.func,
  filterData: PropTypes.object,
  setFilterData: PropTypes.func
}

export default RestaurantFilter
