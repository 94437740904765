import { useEffect, useRef, useState } from 'react'
import {
  useGetItemTypeData,
  useGetItemTypeFieldData
} from 'domains/ItemType/hooks'
import { Grid } from 'antd'

import { useGetCategoryData } from 'domains/Category/hooks'
import { useLoading } from 'hooks'

const widthMap = {
  xs: '200px',
  sm: '300px',
  md: '400px',
  lg: '700px',
  xl: '800px',
  xxl: '1000px'
}

const useMenuItemsTableConfig = (categoryId) => {
  const [categoryData, categoryDataLoading] = useGetCategoryData(categoryId)

  const [itemTypeData, itemTypeDataLoading] = useGetItemTypeData(
    categoryData?.itemTypeId
  )

  const [dataTransformationLoading, setDataTransformationLoading] =
    useState(false)

  const [itemTypeFieldsData, itemTypeFieldsLoading, error] =
    useGetItemTypeFieldData(itemTypeData)

  const breakpoints = Grid.useBreakpoint()

  const [tableProps, setTableProps] = useState({
    headerTitles: [
      // NOT SURE or do i have to fix first column and always keep it reserved for name so hide it for now
      // { title: 'Item' },
      { title: 'Availability', withCounter: true },
      { title: 'Actions' }
    ],
    scrollableColumnWidth: [1, 2, 2, 2, 3, 2],
    scrollableContainerMinWidth: '1000px',
    fixedColCw: [3],
    scrollableColCw: [9]
  })
  // update header columns
  const combineHeaderTitles = (
    fieldValues,
    firstElement,
    secondToLastElement,
    lastElement
  ) => {
    const fieldValuesObjects = fieldValues.map((value) => ({ title: value }))

    return [
      // NOT SURE or do i have to fix first column and always keep it reserved for name so hide it for now
      // ...prefix,
      ...fieldValuesObjects
    ]
  }
  const headerTitlesRef = useRef(tableProps.headerTitles)

  // This way, the useEffect that updates the tableProps won't depend directly on tableProps.headerTitles and won't cause an infinite loop.
  useEffect(() => {
    headerTitlesRef.current = tableProps.headerTitles
  }, [tableProps.headerTitles])

  useEffect(() => {
    setTableProps((prev) => ({
      ...prev,
      scrollableContainerMinWidth: Object.keys(breakpoints || {}).reduce(
        (acc, breakpoint) => {
          if (breakpoints[breakpoint]) {
            return widthMap[breakpoint]
          } else return acc
        },
        '1000px'
      )
    }))
  }, [breakpoints])

  useEffect(() => {
    if (!itemTypeFieldsLoading && !error) {
      setDataTransformationLoading(true)
      const fieldValues = itemTypeFieldsData?.map(
        (document) => document?.label?.en
      )
      const updatedHeaderTitles = combineHeaderTitles(
        fieldValues
        // NOT SURE or do i have to fix 2 last columns (availability and actions) and always keep it reserved for name so hide it for now
        // headerTitlesRef.current[0]
        // headerTitlesRef.current[headerTitlesRef.current.length - 2],
        // headerTitlesRef.current[headerTitlesRef.current.length - 1]
      )
      setTableProps((prevTableProps) => ({
        ...prevTableProps,
        headerTitles: updatedHeaderTitles
      }))
      setDataTransformationLoading(false)
    }
  }, [itemTypeFieldsData, itemTypeFieldsLoading, error, categoryData])

  const loading = useLoading([
    categoryDataLoading,
    itemTypeDataLoading,
    itemTypeFieldsLoading,
    dataTransformationLoading
  ])

  return [tableProps, loading]
}

export default useMenuItemsTableConfig
