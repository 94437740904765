import { Col, Container, Row, Text, Title } from '@qonsoll/react-design'
import { Popconfirm, Tooltip } from 'antd'
import { TABLE_AVAILABILITIES, TABLE_AVAILABILITIES_KEYS } from '__constants__'

import { ButtonAccent } from 'components'
import { Icon } from '@qonsoll/icons'
import PropTypes from 'prop-types'
import { TableQRCodes } from 'domains/Table/components'
import printJS from 'print-js'
import { useGetTableQRCodes } from 'domains/Table/hooks'
import { useTableBaseActions } from 'domains/Table/hooks'
import { useTranslations } from 'contexts/Translation'

const TableAdvancedView = (props) => {
  const { table, zoneId, refresh, restaurant } = props

  /* Getting translations instance */
  const { t } = useTranslations()
  /* Getting menu category actions */
  const { onEdit, onDelete } = useTableBaseActions({
    tableId: table?._id,
    zoneId,
    refresh,
    table
  })
  // eslint-disable-next-line no-unused-vars
  const [tableQRCodes, loading, error, tableQRCodesRefresh] =
    useGetTableQRCodes(table)

  const colorAvailability =
    table?.available === TABLE_AVAILABILITIES_KEYS.INACTIVE
      ? 'var(--ql-color-accent4)'
      : 'var(--ql-color-accent1)'

  /* Print all qRCodes */
  const onPrintQrCode = (e) => {
    e.stopPropagation()
    const qrCodeUrls = tableQRCodes?.map((qrCode) => qrCode?.qrCodeUrl)
    printJS({
      printable: qrCodeUrls,
      type: 'image',
      documentTitle: '',
      imageStyle: 'margin-left:20%; margin-right:20%;'
    })
  }

  return (
    <Container>
      <Row noGutters mb={2} v="center">
        <Col display="flex" flexDirection="row" alignItems="baseline">
          <Title level={4} mr={2}>
            {table?.title}
          </Title>
          <Text variant="overline" color={colorAvailability}>
            {TABLE_AVAILABILITIES?.[table?.available]}
          </Text>
        </Col>
        <Col cw="auto" display="flex" flexDirection="row">
          <Tooltip title={t('Print Qrcodes')}>
            <ButtonAccent
              onClick={onPrintQrCode}
              type="text"
              icon={
                <Icon
                  name="PrinterFilled"
                  size={20}
                  fill="var(--ql-typography-text-color)"
                />
              }
              disabled={!table?.qRCodes}
              mr={2}
            />
          </Tooltip>
          <ButtonAccent
            type="text"
            mr={2}
            icon={<Icon name="Edit3Outlined" />}
            onClick={onEdit}
          />
          <Popconfirm
            title={`${t('Are you sure you want to remove table ')} ${
              table?.title
            }?`}
            okText={t('Yes')}
            onConfirm={onDelete}
            cancelText={t('No')}>
            <ButtonAccent
              icon={<Icon name="DeleteOutlined" />}
              danger
              type="text"
            />
          </Popconfirm>
        </Col>
      </Row>
      <TableQRCodes
        table={table}
        zoneId={zoneId}
        restaurant={restaurant}
        refresh={tableQRCodesRefresh}
        tableQRCodes={tableQRCodes}
      />
    </Container>
  )
}
TableAdvancedView.propTypes = {
  table: PropTypes.object,
  zoneId: PropTypes.string,
  refresh: PropTypes.func,
  restaurant: PropTypes.object
}

export default TableAdvancedView
