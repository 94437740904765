import { Box, Col, Input, Row } from '@qonsoll/react-design'
import PropTypes from 'prop-types'
import { useTranslations } from '@qonsoll/translation'

import { useValidateInput, useFormValidateInput } from 'hooks'

import { StyledFormItem, StyledInput } from './ItemTypeSimpleForm.styles'

const ItemTypeSimpleForm = (props) => {
  const { selectedLanguage, form } = props

  /* Getting translations instance */
  const { t } = useTranslations()
  /* Validate on space */
  const validateInput = useValidateInput()

  /* Show current language in the end of Input */
  const inputAddon = selectedLanguage?.toUpperCase()
  /* Path for validate input on English */
  const pathToEn = ['itemType', 'label', 'en']
  /* Validate on English Input */
  const validateEnglishLabel = useFormValidateInput({
    form,
    path: pathToEn
  })

  return (
    <Row noGutters>
      <Col
        cw={6}
        v="between"
        flexDirection="row"
        style={{ gap: '32px' }}
        display="flex"
        alignItems="flex-start">
        <Box display="flex" flex="1" flexDirection="row">
          <StyledFormItem
            label={t('Name')}
            name={['itemType', 'name']}
            rules={[
              {
                required: true,
                message: t('Please, enter item type name')
              },
              { validator: validateInput }
            ]}>
            <Input placeholder={t('Enter item type name')} />
          </StyledFormItem>
        </Box>
        <Box flex="1" pr={20}>
          <StyledFormItem
            label={t('Label')}
            name={['itemType', 'label', selectedLanguage]}
            rules={[
              {
                required: true,
                message: t('Please, enter item type label')
              },
              { validator: validateEnglishLabel }
            ]}>
            <StyledInput
              addonAfter={inputAddon}
              placeholder={t('Enter item type label')}
            />
          </StyledFormItem>
        </Box>
      </Col>
    </Row>
  )
}

ItemTypeSimpleForm.propTypes = {
  selectedLanguage: PropTypes.string,
  form: PropTypes.object
}

export default ItemTypeSimpleForm
