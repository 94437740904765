import { useMemo } from 'react'
import { Input, Switch } from '@qonsoll/react-design'
import { useTranslations } from '@qonsoll/translation'
import { InputNumber } from 'antd'
import pluralize from 'pluralize'

import { COLLECTIONS, LIST_FIELD_DATA_TYPES } from '__constants__'
import { ImageUploader, ReferenceSelect } from 'components'

import { ObjectInstanceListWithCreate } from 'domains/ObjectInstance/components/'
import { EnumSelect } from 'domains/Enum/components'

const useFormDataTypes = ({ inputAddon, selectedLanguage }) => {
  const { t, language } = useTranslations()

  const referenceData = useMemo(
    () => ({
      [COLLECTIONS.ENUMS]: (props) => <EnumSelect {...props} />,
      [COLLECTIONS.LISTS]: (props) => <ReferenceSelect {...props} />,
      [COLLECTIONS.OBJECTS]: (props) => (
        <ObjectInstanceListWithCreate {...props} />
      )
    }),
    []
  )

  return useMemo(
    () => ({
      [LIST_FIELD_DATA_TYPES.STRING]: (item) => (
        <Input
          addonAfter={item?.isLocalizationEnabled && inputAddon}
          placeholder={`${t('Please enter')} ${
            item?.label?.[language] ?? item?.label?.en ?? ''
          }`}
        />
      ),
      [LIST_FIELD_DATA_TYPES.NUMBER]: (item) => (
        <InputNumber
          decimalSeparator=","
          placeholder={t(
            `Please enter ${item?.label?.[language] ?? item?.label?.en ?? ''}`
          )}
        />
      ),
      [LIST_FIELD_DATA_TYPES.BOOLEAN]: () => <Switch />,
      [LIST_FIELD_DATA_TYPES.IMAGE]: () => <ImageUploader />,
      [LIST_FIELD_DATA_TYPES.HAS_MANY]: (item, itemProps, tabsProps) => {
        const { object, reference } = item
        const referenceName = pluralize(reference?.split('-')[0])
        let nextProps = {}
        if (referenceName === COLLECTIONS.OBJECTS) {
          nextProps = {
            selectedLanguage,
            objectId: object,
            itemProps,
            tabsProps
          }
        } else {
          nextProps = { item, selectedLanguage }
        }

        return referenceData?.[referenceName]
          ? referenceData?.[referenceName]({
              ...nextProps
            })
          : null
      },
      [LIST_FIELD_DATA_TYPES.HAS_ONE]: (item, itemProps, tabsProps) => {
        const { object, reference } = item
        const referenceName = pluralize(reference?.split('-')[0])
        let nextProps = {}
        if (referenceName === COLLECTIONS.OBJECTS) {
          nextProps = {
            selectedLanguage,
            objectId: object,
            itemProps,
            tabsProps
          }
        } else {
          nextProps = { item, selectedLanguage }
        }

        return referenceData?.[referenceName]
          ? referenceData?.[referenceName]({
              ...nextProps
            })
          : null
      }
    }),
    [inputAddon, language, referenceData, selectedLanguage, t]
  )
}

export default useFormDataTypes
