import {
  LayoutAside,
  LayoutHeader,
  Logo,
  BlackoutContent,
  ContentWrapper
} from './components'
import { Layout, LayoutSystemProvider } from '@qonsoll/react-design'

import { Back } from 'components'
import PropTypes from 'prop-types'
import { ThemeProvider } from 'styled-components'
import breakpoints from 'styles/breakpoints'
import { useEffect, useMemo, useState } from 'react'
import { Grid } from 'antd'
import { useUser } from 'domains/User/context'

const { useBreakpoint } = Grid

const BoilerplateLayout = ({ children }) => {
  const { user, loading } = useUser()
  const { lg } = useBreakpoint()

  /* If the user is authenticated, the component will render the children. Otherwise, it will render
  the fallback component. */
  const isAuthenticated = useMemo(
    () => user?.email && user?.emailVerified && !loading,
    [user?.email, user?.emailVerified, loading]
  )

  const isAsideVisible =
    Boolean(user?._companies?.length) && isAuthenticated && !loading

  // Set aside collapsed if there is no lg media size
  const [asideLeftCollapsed, setAsideLeftCollapsed] = useState(!lg)

  /* The layoutConfig is memoized to only re-render when isAuthenticated changes. */
  const layoutConfig = {
    isAsideLeft: isAsideVisible,
    asideLeftCollapseVariant: 'full',
    asideLeftCollapsed,
    asideLeftOuter: true,
    asideLeftOverlay: false
  }

  // Set aside collapsed if there is no lg media size
  useEffect(() => setAsideLeftCollapsed(!lg), [lg])

  return (
    <ThemeProvider theme={breakpoints}>
      {isAuthenticated ? (
        <LayoutSystemProvider {...layoutConfig}>
          <Layout
            asideLeft={
              <LayoutAside
                user={user}
                collapsed={asideLeftCollapsed}
                setCollapsed={setAsideLeftCollapsed}
              />
            }>
            <ContentWrapper asideLeftCollapsed={asideLeftCollapsed} lg={lg}>
              {children}
            </ContentWrapper>
            <BlackoutContent
              asideLeftCollapsed={asideLeftCollapsed}
              setAsideLeftCollapsed={setAsideLeftCollapsed}
              lg={lg}
            />
          </Layout>
        </LayoutSystemProvider>
      ) : (
        <LayoutSystemProvider {...layoutConfig}>
          <Layout header={<LayoutHeader left={<Back />} center={<Logo />} />}>
            {children}
          </Layout>
        </LayoutSystemProvider>
      )}
    </ThemeProvider>
  )
}

BoilerplateLayout.propTypes = {
  children: PropTypes.element
}

export default BoilerplateLayout
