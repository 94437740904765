// import {
//   COCKTAIL_AVAILABILITIES_KEYS,
//   COLLECTIONS,
//   MENU_TYPES_KEYS,
//   ROLES
// } from '__constants__'
// import { createDocument, updateDocument } from 'services/api/firebase'
import {
  //  doc, serverTimestamp,
  writeBatch
} from 'firebase/firestore'
import {
  useCallback
  // useMemo
} from 'react'

import { firestore } from 'services/firebase'
// import { useGetCocktails } from 'domains/Cocktail/hooks'

const useCreateCustomerCallback = () => {
  // const whereClauses = useMemo(
  //   () => ({
  //     where: [['_createdBy', '==', ROLES.ADMIN]]
  //   }),
  //   []
  // )
  // const [cocktails = []] = useGetCocktails(whereClauses)

  return useCallback(async ({ _id, name }) => {
    try {
      // const automaticMenuId = (
      //   await createDocument(COLLECTIONS.MENUS, {
      //     company: [_id],
      //     description: null,
      //     menuConfig: cocktails?.map(({ _id }) => ({ _id })),
      //     menuType: MENU_TYPES_KEYS.DRINK_TYPE,
      //     privacy: true,
      //     status: false,
      //     title: `${name} drinks menu`
      //   })
      // )?._id

      const batch = writeBatch(firestore)

      // const menuCocktailPath = `${COLLECTIONS.MENUS}/${automaticMenuId}/${COLLECTIONS.COCKTAILS}`

      // cocktails?.forEach((cocktail) => {
      //   if (cocktail?._createdAt) {
      //     batch?.set(doc(firestore, menuCocktailPath, cocktail?._id), {
      //       ...cocktail,
      //       price: 0,
      //       availability: COCKTAIL_AVAILABILITIES_KEYS.NO
      //     })
      //   } else {
      //     batch?.set(doc(firestore, menuCocktailPath, cocktail?._id), {
      //       ...cocktail,
      //       price: 0,
      //       _createdAt: serverTimestamp(),
      //       availability: COCKTAIL_AVAILABILITIES_KEYS.NO
      //     })
      //   }
      // })
      await batch?.commit()

      // await updateDocument(COLLECTIONS.CUSTOMERS, _id, { automaticMenuId })
      // return automaticMenuId
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error('ERROR during create automatic menu: ', error)
    }
  }, [])
}

export default useCreateCustomerCallback
