import { useTranslations } from 'contexts/Translation'

const useFormValidateInput = ({
  form,
  path = [],
  errText,
  fnActive,
  fnDisable
}) => {
  const { t } = useTranslations()

  const validateEnglishLabel = () => {
    const formData = form?.getFieldsValue()

    // Get the value at the specified path
    const getValueAtPath = (formData, path) => {
      let value = formData

      for (const key of path) {
        if (!value) {
          break
        }

        value = value?.[key]
      }

      return value
    }

    const enValue = getValueAtPath(formData, path)

    if (!enValue?.trim()) {
      fnActive && fnActive?.()
      return Promise.reject(
        new Error(t(errText ?? 'English label is required'))
      )
    } else {
      fnDisable && fnDisable?.()
      return Promise.resolve()
    }
  }

  return validateEnglishLabel
}

export default useFormValidateInput
