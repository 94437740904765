import { useState } from 'react'
import { collection, query, where } from 'firebase/firestore'
import { useCollectionData } from 'react-firebase-hooks/firestore'
import { firestore } from 'services/firebase'
import { message } from 'antd'
import { COLLECTIONS } from '__constants__'
import { useTranslations } from 'contexts/Translation'

const useTableBaseActions = ({ value, onChange }) => {
  const { t } = useTranslations()

  const [isAdding, setIsAdding] = useState(false)
  const [itemSelected, setItemSelected] = useState(null)
  const [menuItemsSelected, setMenuitemsSelected] = useState([])
  const [menuCategoryEdit, setMenuCategoryEdit] = useState(null)
  const [isEditing, setIsEditing] = useState(false)
  const [editedCocktailInitialData, setEditedCocktailInitialData] =
    useState(null)

  const [itemTypes] = useCollectionData(
    query(collection(firestore, COLLECTIONS.ITEM_TYPES))
  )

  const [menuItems] = useCollectionData(
    itemSelected &&
      query(
        collection(firestore, COLLECTIONS.MENU_ITEMS),
        where('itemTypeId', '==', itemSelected)
      )
  )

  const toggleAdd = () => setIsAdding((prev) => !prev)
  const toggleEdit = () => setIsEditing((prev) => !prev)

  const firstNameOfMenuCategory = (itemTypeId) => () => {
    try {
      const itemType = itemTypes?.find((item) => item?._id === itemTypeId)

      return itemType?.name
    } catch (error) {
      error?.message(`${t('Something wrong with name')}: ${error?.message}`)
    }
  }

  const onDeleteCategory = (itemTypeId) => () => {
    try {
      const updatedValue = value?.filter(
        (menuCategory) => menuCategory?.itemTypeId !== itemTypeId
      )
      onChange(updatedValue)
    } catch (error) {
      message?.error(
        `${t('Something went wrong during deletion')}: ${error?.message}`
      )
    }
  }

  const onChangeVisibilityEditing = (data) => () => {
    setMenuCategoryEdit(data)
    toggleEdit()
  }

  const handleCancelCategoryEdit = () => {
    setMenuCategoryEdit(null)
    toggleEdit()
  }

  const handleChangeCategoryEdit = ({ data, itemTypeId }) => {
    try {
      const updateValue = value?.map((menuCategory) =>
        menuCategory?.itemTypeId === itemTypeId
          ? { ...menuCategory, name: data?.name }
          : menuCategory
      )
      onChange(updateValue)
      setMenuCategoryEdit(null)
      toggleEdit()
    } catch (error) {
      message?.error(
        `${t('Something went wrong during change menu category name')}: ${
          error?.message
        }`
      )
    }
  }

  const onResetInitialData = () => {
    editedCocktailInitialData && setEditedCocktailInitialData(null)
    itemSelected && setItemSelected(null)
    menuItemsSelected && setMenuitemsSelected([])
  }

  const customOnCancel = () => {
    onResetInitialData()
    toggleAdd()
  }

  const onClearCategory = () => {
    setItemSelected(null)
    setMenuitemsSelected([])
  }

  const onChangeCategory = (data) => {
    setItemSelected(data)
    if (data) {
      const findMenuCategory = value?.find((item) => item?.itemTypeId === data)
      findMenuCategory?.itemsOrder &&
        setMenuitemsSelected(
          findMenuCategory?.itemsOrder?.map((item) => item?._id)
        )
    }
  }
  const handleMenuItemsSelectChange = (data) => {
    setMenuitemsSelected(data)
  }

  const handleSelectOptions = () => {
    try {
      const selectedItemType = itemTypes?.find(
        (item) => item?._id === itemSelected
      )
      const selectedMenuItems = menuItems?.filter((item) =>
        menuItemsSelected?.includes(item?._id)
      )

      const hasElementWithSelectedId = value?.some(
        (element) => element?.itemTypeId === selectedItemType?._id
      )

      if (hasElementWithSelectedId) {
        let updatedValue = value?.map((item) =>
          item?.itemTypeId === selectedItemType?._id
            ? {
                ...item,
                itemsOrder: [...selectedMenuItems]
              }
            : item
        )
        onChange(updatedValue)
      } else {
        const preparedValue = {
          name: { en: selectedItemType?.name, no: null },
          itemTypeId: selectedItemType?._id,
          itemsOrder: selectedMenuItems
        }
        onChange([...(value || []), preparedValue])
      }

      toggleAdd()
      onResetInitialData()
    } catch (error) {
      message.error(
        `${t('Something went wrong during selection')}: ${error.message}`
      )
    }
  }
  // Create new menu item
  const customSaveNewMenuItem = (data) => {
    try {
      const selectedItemType = itemTypes?.find(
        (item) => item?._id === itemSelected
      )
      const preparedValue = {
        name: { en: selectedItemType?.name, no: null },
        itemTypeId: selectedItemType?._id,
        itemsOrder: [data?.menuItem]
      }
      const hasElementWithSelectedId = value?.some(
        (element) => element?.itemTypeId === selectedItemType?._id
      )

      if (hasElementWithSelectedId) {
        const updatedValue = value?.map((item) =>
          item?.itemTypeId === selectedItemType?._id
            ? {
                ...item,
                itemsOrder: [...(item?.itemsOrder || []), data?.menuItem]
              }
            : item
        )

        onChange(updatedValue)
      } else {
        onChange([...value, preparedValue])
      }

      toggleAdd()
      onResetInitialData()
    } catch (error) {
      message.error(`${t('Error during saving new item')}: ${error?.message}`)
    }
  }

  return {
    isAdding,
    editedCocktailInitialData,
    toggleAdd,
    onResetInitialData,
    customOnCancel,
    itemSelected,
    setItemSelected,
    menuItemsSelected,
    setMenuitemsSelected,
    onClearCategory,

    onChangeCategory,
    handleMenuItemsSelectChange,
    handleSelectOptions,
    customSaveNewMenuItem,
    menuItems,
    itemTypes,

    isEditing,
    toggleEdit,
    onChangeVisibilityEditing,
    menuCategoryEdit,
    handleCancelCategoryEdit,
    handleChangeCategoryEdit,

    onDeleteCategory,
    firstNameOfMenuCategory
  }
}

export default useTableBaseActions
