import { collection, doc, query, where } from 'firebase/firestore'
import {
  useCollectionData,
  useDocumentData
} from 'react-firebase-hooks/firestore'

import { COLLECTIONS } from '__constants__'
import { firestore } from 'services/firebase'
import { useLoading } from 'hooks'
import { useMemo } from 'react'
import { useParams } from 'react-router-dom'

const useGetItemTypeInstanceInitialValues = (outerMenuTypeId) => {
  const { itemTypeId, menuItemId } = useParams()

  const usedItemTypeId = itemTypeId || outerMenuTypeId

  const [itemType, itemTypeLoading] = useDocumentData(
    usedItemTypeId &&
      doc(firestore, COLLECTIONS.ITEM_TYPES + '/' + usedItemTypeId)
  )

  const [itemTypeFields, itemTypeFieldsLoading] = useCollectionData(
    usedItemTypeId &&
      query(
        collection(firestore, COLLECTIONS.ITEM_TYPES_FIELDS),
        where('itemTypeId', '==', usedItemTypeId)
      )
  )

  const [menuItem, menuItemLoading] = useDocumentData(
    menuItemId && doc(firestore, COLLECTIONS.MENU_ITEMS, menuItemId)
  )

  /* Sorting the listFields by the order of the itemType.fieldsOrder */
  const itemTypeFieldsSorted = useMemo(() => {
    if (
      !itemTypeLoading &&
      !itemTypeFieldsLoading &&
      itemTypeFields &&
      itemType
    ) {
      const sortedListFields = Object?.values(itemType?.fieldsOrder)
        ?.map((id) => itemTypeFields?.find((field) => field?._id === id))
        ?.filter(Boolean)

      return sortedListFields
    }
    return []
  }, [itemType, itemTypeFields, itemTypeFieldsLoading, itemTypeLoading])

  const initialData = useMemo(
    () => ({
      itemType,
      itemTypeFieldsSorted,
      menuItem
    }),
    [itemType, itemTypeFieldsSorted, menuItem]
  )

  const loading = useLoading([
    !initialData,
    !itemTypeFieldsSorted,
    itemTypeLoading,
    itemTypeFieldsLoading,
    menuItemLoading
  ])
  return [initialData, loading]
}

export default useGetItemTypeInstanceInitialValues
