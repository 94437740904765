import { Col, PageWrapper, Row } from '@qonsoll/react-design'
import { useParams } from 'react-router-dom'
import PropTypes from 'prop-types'
import { useMemo } from 'react'

import { useTranslations } from 'contexts/Translation'
import { HeaderBreadcrumbs, PageActions, Spinner } from 'components'

import {
  useInteractiveMenuBaseActions,
  useGetInteractiveMenu
} from 'domains/InteractiveMenu/hooks'
import { InteractiveMenuAdvancedView } from 'domains/InteractiveMenu/components'

const InteractiveMenuShow = (props) => {
  const { withHeader = true } = props
  /* Getting translations instance */
  const { t } = useTranslations()
  /* Getting params from router */
  const { interactiveMenuId } = useParams()
  /* Fetching all data for 'Interactive Menu - Show page' */
  const [initialData, loading] = useGetInteractiveMenu(interactiveMenuId)

  const { onBack, onEdit, onDelete, isDeletionDisabled } =
    useInteractiveMenuBaseActions({
      interactiveMenu: initialData,
      interactiveMenuId
    })

  /* Configuring page header */
  const headingProps = useMemo(
    () => ({
      title: initialData?.interactiveMenuData?.name,
      marginBottom: 3,
      textAlign: 'left'
    }),
    [initialData]
  )

  return (
    <PageWrapper
      headingProps={headingProps}
      onBack={withHeader ? onBack : null}
      breadcrumbs={withHeader ? <HeaderBreadcrumbs /> : null}
      action={
        <PageActions
          actions={{
            edit: false,
            delete: false
          }}
          document={initialData?.interactiveMenuData}
          onEdit={onEdit}
          onDelete={onDelete}
          deleteBtnProps={{ withoutLabel: true, disabled: isDeletionDisabled }}
        />
      }>
      <Row noGutters>
        <Col cw={12}>
          {loading ? (
            <Spinner
              height="70vh"
              text={`${t('Interactive menu is loading')}...`}
            />
          ) : (
            <InteractiveMenuAdvancedView initialData={initialData} />
          )}
        </Col>
      </Row>
    </PageWrapper>
  )
}
InteractiveMenuShow.propTypes = {
  withHeader: PropTypes.bool
}
export default InteractiveMenuShow
