const convertTimestamp = (timestamp, separateSymbol = '/') => {
  let date = timestamp.toDate()
  let mm = date.getMonth() + 1
  let dd = date.getDate()
  let yyyy = date.getFullYear()

  return `${yyyy}${separateSymbol}${mm}${separateSymbol}${dd}`
}

export default convertTimestamp
